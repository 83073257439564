import * as React from "react";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from '@material-ui/icons/Delete';
import img from "./../../assets/img/standard_icon.png";
import http from 'axios';
// import http from './../../lib/http';

//styles
import "./CustomImageViewer.css"

export default function CustomImageViewer(props) {
	const onDownloadImage = (imagePath, format = "file") => {
		const ext = imagePath.split('.').pop().toLowerCase();		
		http({
			method: 'GET',
      url: imagePath,
      responseType: 'blob',
			headers: {
				'Accept': '*/*'
			}
		})
		  .then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `file.${ext}`);
				document.body.appendChild(link);
				link.click();
        link.parentNode.removeChild(link);
			});
	};

	const pathImage = (itemPath) =>
		!!props.showDefaultPdfIcon ? img : itemPath;

	return (
		<ImageList sx={{ width: 500, height: 450 }} cols={12} rowHeight={'auto'}
			className="custom__image__list__preview"
			style={{ gap: '.25rem', display: 'inline-flex', flexWrap: 'nowrap' }}
		>
			{ !!props.children ? props.children : null }
			{
				(props.itemData || []).length
				? props.itemData.map((item) => (
					<ImageListItem key={item.path} className="p-0 w-auto">
						<Box
							component="div"
							sx={{ background: `url(${pathImage(item.path)}) no-repeat center center`, backgroundSize: 'cover' }}
							className="image__list__preview__list__item"
							onClick={() => props.onClickImage(item.path)}
						>
							{!!props.showDowloadAction && parseInt(item.id) > 0 && (
								<Chip 
									onClick={() => onDownloadImage(item.path, item.format)}
									icon={<GetAppIcon className="ms-0" />}
									style={{ fontSize: '.75rem' }}
									size="small"
									color="primary"
									className="download_img_icon"
								/>
							)}
							<Chip
								onClick={() => props.onDeleteImage(item.path)}
								icon={<DeleteIcon fontSize="small" style={{ fontSize: '1rem' }} />}
								label=""
								className="delete_img_icon"
								color="secondary"
							/>
						</Box>
					</ImageListItem>
				))
				: null
			}
		</ImageList>
	);
}

export { CustomImageViewer };
