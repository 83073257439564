import createHttp from "../lib/http";

const StripeService = {};

StripeService.getSetting = async () => {
  const { data } = await createHttp.get("/api/get_stripe_configuration");
  return data;
}

StripeService.updateSetting = async (payload) => {
  const { data } = await createHttp.post("/api/save_stripe_configuration", payload);
  return data;
}

export default StripeService;