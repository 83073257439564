import React from 'react';
import { FormHelperText, makeStyles, FormControl, InputLabel, MenuItem, Select as SelectMui } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#86b7fe',
      },
      '&:hover fieldset': {
        borderColor: '#86b7fe',
      },
    },
    '& label.Mui-focused': {
      color: '#86b7fe',
    },
    '& .MuiInputBase-input': {
      backgroundColor: 'var(--bg-body)',
    }
  },
  textError: {
    fontSize: '.7rem',
    marginBlockStart: 0,
    marginInline: 0
  }
});

const Select = ({ label, state = '', setState, error = '', className = '', options = [], size, type = 'text', ...props }) => {
  const hasError = !!error;
  const classes = useStyles();

  const handleChange = (ev) => {
    if(setState && typeof setState == 'function')
      if(!props.disabled) setState(ev.target.value);
  }
  
  return (
    <FormControl  fullWidth variant="outlined" size="small" className={clsx(classes.root, className)}>
      <InputLabel>{label}</InputLabel>
      <SelectMui
        onChange={handleChange}
        value={state}
        className={className}
        disabled={props.disabled}
      >
        {Array.isArray(options) && options.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </SelectMui>
      {hasError && <FormHelperText className={classes.textError} error>{error}</FormHelperText>}
    </FormControl>
  );
}

export default Select;