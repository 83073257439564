export const typesAndValues = [
	{
		field_name: "id",
		type_field: "text_input",
		text_label: "Id",
		disabled: true,
		regexPattern: /^[A-Za-z0-9]*$/,
		maxLength: 15,
	},
	{
		field_name: "name",
		type_field: "text_input",
		text_label: "Name",
		disabled: false,
		regexPattern: /^[0-9A-Za-z\s\-]+$/,
		maxLength: 50,
		minLength: 1,
	},
	{
		field_name: "partype",
		type_field: "select_input",
		text_label: "Part Type",
		placeHolder: "select a part type",
		dontDefaultOption: false,
		values: [
			{ id: "PT", name: "Part" },
			{ id: "LB", name: "Labor" },
			{ id: "TR", name: "Tire" },
		],
		regexPattern: /^[a-z0-9]+$/i,
		selectCol: "col-12",
	},
	{
		field_name: "serial_number",
		type_field: "select_input",
		text_label: "Serial Number",
		placeHolder: "select a serial number",
		dontDefaultOption: false,
		values: [
			{ id: "true", name: "true" },
			{ id: "false", name: "false" },
		],
		regexPattern: /^[a-z0-9]+$/i,
		selectCol: "col-12",
	},
];

export const initData = {
	id: "",
	name: "",
	partype: "",
	serial_number: "",
};
