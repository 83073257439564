import React from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  calculator: {
    display: 'grid',
    inlineSize: '100%',
    gridTemplateColumns: '1fr 1fr 1fr 2fr',
    color: 'var(--bs-gray-700)',
    fontFamily: '\'Inter\', sans-serif',
    fontSize: '1.25rem',
    gap: '.2rem',
  },
  calculatorButton: {
    padding: '1rem',
    border: '1px solid var(--bs-gray-200)',
    borderRadius: '.15rem',
    userSelect: 'none',
    '&:hover': {
      background: 'var(--bs-gray-100)',
    },
  },
  calculatorButtonPay: {
    padding: '1rem',
    background: 'var(--bs-success)',
    color: 'white',
    border: '1px solid var(--bs-success)',
    borderRadius: '.15rem',
    userSelect: 'none',
    '&:hover': {
      background: '#208757',
    },
  },
  disabled: {
    background: 'var(--bs-gray-300)',
    borderColor: 'var(--bs-gray-400)',
    color: 'var(--bs-gray-500)',
    cursor: 'default',
    '&:hover': {
      background: 'var(--bs-gray-300)',
    },
  },
  calculatorButtonCancel: {
    padding: '1rem',
    background: '#ff6262',
    color: 'white',
    border: '1px solid #ff6262',
    borderRadius: '.15rem',
    userSelect: 'none',
    '&:hover': {
      background: '#d45151',
    },
  }
});

const Calculator = ({ state, setState, onSubmit, onCancel, disabledSubmit = false }) => {
  const classes = useStyles();
  const handleSpecialButtonPress = (action) => {
    switch (action) {
      case "clear":
        setState("");
        break;
      case "backspace":
        setState(String(state).slice(0, -1));
        break;
      case "pay":
        if (onSubmit && onSubmit instanceof Function)
          onSubmit();
        break;
      case "cancel":
        if (onCancel && onCancel instanceof Function)
          onCancel();
        break;
      default:
        const hasDot = String(state).indexOf(".") !== -1;
        const newState = hasDot ? state : `${state}.`;
        setState(newState);
        break;
    }
  };

  const handleClick = (keyword) => {
		if (isNaN(keyword)) {
			handleSpecialButtonPress(keyword);
		} else {
      setState(`${String(state)}${keyword}`);
		}
  };

	return (
    <div className={classes.calculator}>
      <div role='button' onClick={() => handleClick('1')} className={classes.calculatorButton}>1</div>
      <div role='button' onClick={() => handleClick('2')} className={classes.calculatorButton}>2</div>
      <div role='button' onClick={() => handleClick('3')} className={classes.calculatorButton}>3</div>
      <div role='button' onClick={() => handleClick('clear')} className={clsx(classes.calculatorButton, 'fs-semi-small')} >Clear</div>
      <div role='button' onClick={() => handleClick('4')} className={classes.calculatorButton}>4</div>
      <div role='button' onClick={() => handleClick('5')} className={classes.calculatorButton}>5</div>
      <div role='button' onClick={() => handleClick('6')} className={classes.calculatorButton}>6</div>
      <div role='button' onClick={() => handleClick('backspace')} className={clsx(classes.calculatorButton, 'fs-semi-small')} ><i className="fas fa-arrow-left"></i></div>
      <div role='button' onClick={() => handleClick('7')} className={classes.calculatorButton}>7</div>
      <div role='button' onClick={() => handleClick('8')} className={classes.calculatorButton}>8</div>
      <div role='button' onClick={() => handleClick('9')} className={classes.calculatorButton}>9</div>
      <div role='button'
        onClick={() => disabledSubmit ? 'javascript:;' : handleClick('pay')}
        className={clsx(classes.calculatorButtonPay, disabledSubmit && classes.disabled, 'fs-semi-small')}
      >
        <i className="fas fa-dollar-sign me-2"></i>Pay
      </div>
      <div role='button' onClick={() => handleClick('0')} className={classes.calculatorButton}>0</div>
      <div role='button' onClick={() => handleClick('.')} className={classes.calculatorButton}>.</div>
      <div role='button' onClick={() => handleClick('00')} className={classes.calculatorButton}>00</div>
      <div role='button' onClick={() => handleClick('cancel')} className={clsx(classes.calculatorButtonCancel, 'fs-semi-small')} >Cancel</div>
    </div>
  )
};

export default Calculator;
