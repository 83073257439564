import "./pageNotFound.css";
import { useHistory } from "react-router-dom";

function PageNotFound() {
	const history = useHistory();

	return (
		<>
			<div className="page-not-found-cointainer">
				<div className="page-not-found-image"></div>
				<div className="page-not-found-image-text-container">
					<div className="page-not-found-image-text-container-404">
						404
					</div>
					<div>
						We can not seem to find the page you are looking for.
					</div>
					<div>
						<div
							style={{ cursor: "pointer" }}
							className="balance-info-text"
							onClick={() => {
								history.push({
									pathname: "/",
								});
							}}
						>
							Go to main page
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export { PageNotFound };
