import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, FormGroup, FormLabel, } from 'react-bootstrap';
import MaterialUI from '../MaterialUI';

function ModalAddTax({show, onHandleClose, title, handleAction, isLoading}) {

    const [newTax, setNewTax] = useState({
        tax:0.1, 
        tax_name:"",
        only_for_group:0,
    });

    const [errors, setErrors] = useState({
        tax: "",
        tax_name: "",
    });

    const validateInputs = () => {
        let isValid = true;
        const newErrors = {};

        // Validar el campo de tax
        if (isNaN(newTax.tax) || newTax.tax === "" || parseFloat(newTax.tax) < 0.1 || parseFloat(newTax.tax) > 99.9) {
            newErrors.tax = "Tax rate must be a number between 0.1 and 99.9";
            isValid = false;
        }

        // Validar el campo de tax_name
        const tax_name_without_space = newTax.tax_name.trim();
        if (tax_name_without_space.length < 5 || tax_name_without_space.length > 30 || tax_name_without_space.trim() === "") {
            newErrors.tax_name = "Tax name must be between 5 and 30 characters long";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e, field) => {
        const { value } = e.target;
        const newErrors = { ...errors }; 
    
        if (field === "tax_name") {
            if (value.trim().length < 5 || value.trim().length > 30) {
                newErrors.tax_name = "Tax name must be between 5 and 30 characters long";
            } else {
                delete newErrors.tax_name; 
            }
        }
    
        if (field === "tax") {
            if (isNaN(value) || parseFloat(value) < 0.1 || parseFloat(value) > 99.9) {
                newErrors.tax = "Tax rate must be a number between 0.1 and 99.9";
            } else {
                delete newErrors.tax; 
            }
        }
    
        setErrors(newErrors);
    
        setNewTax({ ...newTax, [field]: value });
    };
    

    const handleSubmit = () => {
        if (validateInputs()) {
            handleAction(newTax);
        }
    };

    useEffect(() => {
        //clear form
        if(!show){
            setNewTax({
                tax:0.1, 
                tax_name:""
            });
            setErrors({
                tax: "",
                tax_name: ""
            });
        }
    },[show])

    //close modal
    useEffect(() => {
        //clear form
        if(!isLoading){
            onHandleClose();
        }
    },[isLoading])

   
   
    return (
		<React.Fragment>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={show}
				onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
			>
                <Modal.Header style={{ background: 'var(--login-blue)', color: '#fff' }}>
                    <Modal.Title as="h6" className='fw-bold'>{title}</Modal.Title>
                </Modal.Header>
				<Modal.Body>
					<Form autoComplete='off'>
                        <Form.Group className='mb-2' controlId='tax-name'>
                            <Form.Label className='mb-0'>Name</Form.Label>
                            <Form.Control 
                                maxLength={30}
                                onChange={(e) => handleChange(e, "tax_name")}
                                value={newTax.tax_name}
                                type="text"
                            />
                            {errors.tax_name && <Form.Text className='text-danger'>{errors.tax_name}</Form.Text>}
                        </Form.Group>
                        <Form.Group className='mb-2' controlId='tax-rate'>
                            <Form.Label className='mb-0'>Tax Rate %</Form.Label>
                            <Form.Control 
                                max={99.9}
                                value={newTax.tax}
                                onChange={(e) => handleChange(e, "tax")}
                                type="number"
                            />
                            {errors.tax && <Form.Text className='text-danger'>{errors.tax}</Form.Text>}
                        </Form.Group>
                        <Form.Group className='mb-4' controlId='tax-confirm'>
                            <Form.Check 
                                type="checkbox"
                                checked={newTax.only_for_group}
                                onChange={() => setNewTax({...newTax, only_for_group: !newTax.only_for_group})}
                                label="For tax group only"
                            />
                        </Form.Group>
                        <Form.Group className='mb-1 text-end'>
                            <MaterialUI.Button.Success className="me-1" onClick={handleSubmit}>
                                {isLoading ? "Loading ..." : "Create"}
                            </MaterialUI.Button.Success>
                            <MaterialUI.Button.Secondary onClick={onHandleClose}>
                                Cancel
                            </MaterialUI.Button.Secondary>
                        </Form.Group>
                    </Form>
				</Modal.Body>
			</Modal>
		</React.Fragment>
    );
}

export {ModalAddTax};