import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';


// components
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { CustomModal } from "../../components/CustomModal/CustomModal";

//custom hooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";

import {
	typesAndValues as typesAndValuesFields,
	initData as initDataFields,
} from "./initLaborForm";

import { setValueInArray } from "../../context/constants";
import MaterialUI from "../../components/MaterialUI";

function UpdateLaborForm() {
	const history = useHistory();
	const location = useLocation();

	const {
		showSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage
	} = useModalHook();

	const { isLoading, getListItem, getItem, updateItem } = useApiCustomRequest(
		manageResponseErrors,
		"labors",
		"Labors",
		manageResponse,
		manageSuccessfullMesssage,
		true,
	);

	const [initData, setInitData] = useState({});
	const [typesAndValues, setTypesAndValues] = useState([]);

	useEffect(async () => {
		let typesAndValuesFieldsCopy = [...typesAndValuesFields];
		let initDataFieldsCopy = { ...initDataFields };

		const infoItem = await getItem(
			location?.search?.split("=")[1],
			{},
			true,
			true,
		);

		if (infoItem.hasOwnProperty("error") && infoItem.error) {
			return history.push("/404");
		}

		console.log("info item", infoItem);

		const { name, sku, price, cost } = infoItem;

		const uom = await getListItem("uom", "Uom", {});

		if (uom.length > 0) {
			typesAndValuesFieldsCopy = setValueInArray(
				typesAndValuesFieldsCopy,
				"uom_id",
				"values",
				uom,
			);
		}

		initDataFieldsCopy = {
			...initDataFieldsCopy,
			name,
			sku,
			price,
			cost,
			uom_id: uom.length > 0 ? uom[0]?.id : "",
		};

		setInitData(initDataFieldsCopy);
		setTypesAndValues(typesAndValuesFieldsCopy);
	}, []);

	const onChangeFieldsInitDataPartNumber = (ev) => {
		setInitData({ ...ev });
	};

	const onHandleSubmit = (ev) => {};

	const buttonClick = () => {};

	//onSubmit
	const childFunc = useRef(null);
	const onCreateProduct = async () => {
		const errors = childFunc.current(initData);

		if (!(errors.length > 0)) {
			let request = {
				...initData,
			};

			let response = await updateItem(
				location?.search?.split("=")[1],
				request,
				"Labor",
				{},
				true,
				true,
			);

			if (response && response.hasOwnProperty("id")) {
				history.push("/labors_list");
			}
		}
	};

	const goBack = () => history.push('/labors_list');
	
	return (
		<>
			<div className="contenedor container-fluid">
				<div className="d-flex gap-2 align-items-center">
					<MaterialUI.Button.Dark onClick={goBack}>
						<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
					</MaterialUI.Button.Dark>
					<div className="container-title">
						<h1 className="container__title mb-0">{"Edit Labor"}</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-12">
						{typesAndValues.length > 0 &&
							Object.keys(initData).length > 0 && (
								<>
									<CustomForm
										onHandleFormClose={() => {}}
										onHandleSubmit={onHandleSubmit}
										typesAndValuesFields={typesAndValues}
										initData={initData}
										formTittle={""}
										dontShowCancelButton={true}
										id={null}
										getUrlPath={""}
										inputConf={{
											marginTop: "1",
											marginBottom: "0",
										}}
										buttonClick={buttonClick}
										onChangeFields={
											onChangeFieldsInitDataPartNumber
										}
										initOnChangeFields={
											onChangeFieldsInitDataPartNumber
										}
										dontShowSubmitButton={true}
										childFunc={childFunc}
										dontShowErrosinOnChange={true}
										getObjectInfoOnChange={() => {}}
										notifyChange={() => {}}
									/>
								</>
							)}
					</div>
					<div className="col-12 text-end">
						<MaterialUI.Button.Success onClick={onCreateProduct} className="mt-2">
							<SaveOutlinedIcon fontSize="small" className="me-2" />Update
						</MaterialUI.Button.Success>
					</div>
				</div>
			</div>

			<CustomModal
				show={isLoading}
				customTittleText={""}
				customMessageModal={""}
				onHandleClose={() => {}}
				isLoading={isLoading}
			/>

			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowModal(false)}
				onHandleSuccess={() => {}}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
			/>
		</>
	);
}

export { UpdateLaborForm };
