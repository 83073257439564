import { useEffect, useState, useRef, useDebugValue } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';


// components
import { CheckboxesGroup } from "../../components/Checkbox/CheckBoxGroup";
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomModal } from "../../components/CustomModal/CustomModal";

//custom hooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";

import { validateLength } from "../../context/constants";

// form configuration
import {
	typesAndValues as typesAndValuesFields,
	initData as initDataFiels,
	typesAndValuesRowTwo as typesAndValuesRowTwoFields,
	initDataRowTwo as initDataRowTwoFields,
	typesAndValuesMessagePackage as typesAndValuesMessagePackageFields,
	initDataMessagePackage as initDataMessagePackageFields,
} from "./initForm";
import MaterialUI from "../../components/MaterialUI";

function UpdatePackageForm() {
	const location = useLocation();
	const history = useHistory();

	const {
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
		showSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		cancelButtonText,
		sucessButtonText,
	} = useModalHook();

	const { isLoading, getListItem, updateItem, getItem } = useApiCustomRequest(
		manageResponseErrors,
		"packages",
		"Package",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		() => {},
	);

	const [typesAndValues, setTypesAndValues] = useState([]);
	const [typesAndValuesRowTwo, setTypesAndValuesRowTwo] = useState([]);
	const [typesAndValuesMessagePackage, setTypesAndValuesMessagePackage] =
		useState([]);

	const [initData, setInitData] = useState({});
	const [initDataRowTwo, setInitDataRowTwoFields] = useState({});
	const [initDataMessagePackage, setInitDataMessagePackage] = useState({});
	const [formValue, setFormValue] = useState({});
	const [initState, setInitState] = useState({
		select_all: false,
	});
	const [moduleArrayState, setModuleArrayState] = useState({});
	const [moduleArray, setModuleArray] = useState([]);
	const [moduleInPackage, setModuleInPackage] = useState([]);

	useEffect(async () => {
		let typesAndValuesFieldsCopy = [...typesAndValuesFields];
		let typesAndValuesRowTwoCopy = [...typesAndValuesRowTwoFields];
		let typesAndValuesMessagePackageFieldsCopy = [
			...typesAndValuesMessagePackageFields,
		];
		let initDataFielsCopy = { ...initDataFiels };
		let initDataRowTwoCopy = { ...initDataRowTwoFields };
		let initDataMessagePackageFieldsCopy = {
			...initDataMessagePackageFields,
		};

		const infoItem = await getItem(
			location?.search?.split("=")[1],
			{},
			true,
			true,
		);

		const {
			name,
			max_employees,
			max_storage_size,
			storage_unit,
			monthly_price,
			annual_price,
			is_private,
			is_recommended,
			description,
			module_in_package,
		} = infoItem;

		setModuleInPackage(module_in_package);

		initDataFielsCopy = {
			...initDataFielsCopy,
			name: name || "",
			max_employees: max_employees || "",
			max_storage_size: max_storage_size || "",
			storage_unit: storage_unit || "",
			monthly_price: monthly_price || "",
			annual_price: annual_price || "",
		};

		initDataRowTwoCopy = {
			...initDataRowTwoCopy,
			is_private: is_private === 1 ? true : false,
			is_recommended: is_recommended === 1 ? true : false,
		};

		initDataMessagePackageFieldsCopy = {
			...initDataMessagePackageFieldsCopy,
			description: description || "",
		};

		console.log("infoItem", infoItem);

		if (infoItem.hasOwnProperty("error") && infoItem.error) {
			history.push("/404");
		}

		setTypesAndValues(typesAndValuesFieldsCopy);
		setTypesAndValuesRowTwo(typesAndValuesRowTwoCopy);
		setTypesAndValuesMessagePackage(typesAndValuesMessagePackageFieldsCopy);
		setInitData(initDataFielsCopy);
		setInitDataRowTwoFields(initDataRowTwoCopy);
		setInitDataMessagePackage(initDataMessagePackageFieldsCopy);
	}, []);

	const buttonClick = () => {};

	const onChangeFieldsInitDataPartNumber = (ev) => {
		setInitData({ ...ev });
	};

	const onChangeFieldsInitDataRowTwo = (ev) => {
		setInitDataRowTwoFields({ ...ev });
	};

	const onChangeFieldsInitDataMessage = (ev) => {
		setInitDataMessagePackage({ ...ev });
	};

	const childFunc = useRef(null);
	const childFunc2 = useRef(null);
	const onHandleSubmit = async (typeSave) => {
		const errorsOne = childFunc.current(initData);
		const errorsTwo = childFunc2.current(initDataMessagePackage);

		if (!validateLength(errorsOne) && !validateLength(errorsTwo)) {
			const moduleArrayFilter = moduleArray
				.filter((module) => moduleArrayState[module.field_name])
				.map((filter) => [filter.id])
				.reduce((accumulator, value, index) => {
					return [...accumulator, ...value];
				}, []);

			let baseRequest = {
				name: initData.name,
				monthly_price: initData.monthly_price,
				annual_price: initData.annual_price,
				storage_unit: initData.storage_unit,
				is_private: initDataRowTwo.is_private ? 1 : 0,
				is_recommended: initDataRowTwo.is_recommended ? 1 : 0,
				currency_id: "1",
				module_in_package: moduleArrayFilter,
			};

			if (initDataMessagePackage.description) {
				baseRequest = {
					...baseRequest,
					...initDataMessagePackage,
				};
			}

			if (initData.max_employees) {
				baseRequest = {
					...baseRequest,
					max_employees: initData.max_employees,
				};
			}

			if (initData.max_storage_size) {
				baseRequest = {
					...baseRequest,
					max_storage_size: initData.max_storage_size,
				};
			}

			let response = await updateItem(
				location?.search?.split("=")[1],
				baseRequest,
				"Package",
				{},
				true,
				true,
			);

			if (response && response.hasOwnProperty("id")) {
				history.push({
					pathname: "/packages_list",
					search: ``,
				});
			}

			console.log("response", response);
		}
	};

	//all module checkbox

	useEffect(() => {
		setFormValue({
			field_name: "test",
			type_field: "checkbox_input",
			text_label: "",
			col: "col-3 col-md-3",
			checkBoxValues: {
				select_all: true,
			},
			mapChechBoxNames: {
				select_all: "Select All",
			},
		});

		setInitState({
			select_all: false,
		});
	}, []);

	const onCheckBoxGroupChangeForm = (ev) => {
		setInitState({ ...ev });
	};

	const [initDataLoaded, setInitDataLoaded] = useState(false);

	useEffect(() => {
		let mounted = true;
		getListItem("modules", "Modules", {})
			.then((response) => {
				console.log("response", response);
				if (mounted) {
					const stateNew = response
						.map((item) => ({
							[item.module_name]: false,
						}))
						.reduce((accumulator, value, index) => {
							return { ...accumulator, ...value };
						}, {});

					setModuleArrayState({ ...stateNew });
					setInitDataLoaded(true);

					const moduleArrayCopy = response.map((item) => ({
						id: item.id,
						field_name: item.module_name,
						type_field: "checkbox_input",
						text_label: "",
						col: "col-3 col-md-3",
						checkBoxValues: {
							[item.module_name]: false,
						},
						mapChechBoxNames: {
							[item.module_name]: item.description,
						},
					}));

					setModuleArray(moduleArrayCopy);
				}
			})
			.catch((error) => {});
		return () => (mounted = false);
	}, []);

	const onCheckBoxGroupChangeArray = (ev) => {
		const key = Object.keys(ev)[0];
		setModuleArrayState({
			...moduleArrayState,
			[key]: ev[key],
		});
	};

	//select all behavior
	useEffect(() => {
		let moduleArrayStateCopy = Object.keys(moduleArrayState)
			.map((key) => ({
				[key]: Object.values(initState)[0],
			}))
			.reduce((accumulator, value, index) => {
				return { ...accumulator, ...value };
			}, {});

		setModuleArrayState({ ...moduleArrayStateCopy });
	}, [initState]);

	useEffect(() => {
		let moduleArrayStateCopy = { ...moduleArrayState };

		if (moduleInPackage.length > 0 && initDataLoaded) {
			const stateNew = moduleInPackage
				.map((item) => ({
					[item.module_name]: true,
				}))
				.reduce((accumulator, value, index) => {
					return { ...accumulator, ...value };
				}, {});

			moduleArrayStateCopy = {
				...moduleArrayStateCopy,
				...stateNew,
			};

			let hasItemsInFalse = false;
			Object.values(moduleArrayStateCopy).map((item) => {
				if (!item) hasItemsInFalse = true;
			});

			if (!hasItemsInFalse) {
				setInitState({
					select_all: true,
				});
			}
		}
		setModuleArrayState({ ...moduleArrayStateCopy });
	}, [moduleInPackage, initDataLoaded]);

	const gotBack = () => history.push('/packages_list');

	return (
		<>
			<div className="contenedor container-fluid">
				<MaterialUI.Button.Dark className="mb-2" onClick={gotBack}>
					<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
				</MaterialUI.Button.Dark>
				<div className="container-title">
					<h1 className="container__title">
						{"Edit Package"} #{location?.search?.split("=")[1]}
					</h1>
				</div>
				<div className="row mt-1">
					<div className="col-12">
						{typesAndValues.length > 0 &&
							Object.keys(initData).length > 0 && (
								<CustomForm
									onHandleFormClose={() => {}}
									onHandleSubmit={onHandleSubmit}
									typesAndValuesFields={typesAndValues}
									initData={initData}
									formTittle={""}
									dontShowCancelButton={true}
									id={null}
									getUrlPath={""}
									inputConf={{
										marginTop: "1",
										marginBottom: "0",
									}}
									buttonClick={buttonClick}
									onChangeFields={
										onChangeFieldsInitDataPartNumber
									}
									initOnChangeFields={
										onChangeFieldsInitDataPartNumber
									}
									dontShowSubmitButton={true}
									childFunc={childFunc}
									dontShowErrosinOnChange={true}
									getObjectInfoOnChange={() => {}}
									notifyChange={() => {}}
								/>
							)}
					</div>
					<div className="col-12">
						{typesAndValuesRowTwo.length > 0 &&
							Object.keys(initDataRowTwo).length > 0 && (
								<CustomForm
									onHandleFormClose={() => {}}
									onHandleSubmit={onHandleSubmit}
									typesAndValuesFields={typesAndValuesRowTwo}
									initData={initDataRowTwo}
									formTittle={""}
									dontShowCancelButton={true}
									id={null}
									getUrlPath={""}
									inputConf={{
										marginTop: "1",
										marginBottom: "0",
									}}
									buttonClick={buttonClick}
									onChangeFields={
										onChangeFieldsInitDataRowTwo
									}
									initOnChangeFields={
										onChangeFieldsInitDataRowTwo
									}
									dontShowSubmitButton={true}
									dontShowErrosinOnChange={true}
									getObjectInfoOnChange={() => {}}
									notifyChange={() => {}}
								/>
							)}
					</div>
				</div>
				<hr />
				<div className="row mt-1 mb-2">
					<div className="col-12">
						<div className="d-flex justify-content-between align-items-center">
							<h6 className="sub__title mb-0">Select Modules</h6>
							{Object.keys(formValue).length > 0 &&
								Object.keys(initState).length > 0 && (
									<CheckboxesGroup
										formValue={formValue}
										onChangeEvent={onCheckBoxGroupChangeForm}
										formErrors={[]}
										inputConf={{
											marginTop: "1",
											marginBottom: "0",
										}}
										initState={initState}
									/>
								)}
						</div>
					</div>
				</div>
				<div className="row mt-1">
					{moduleArray.length > 0 &&
						moduleArray.map((module, index) => (
							<div className="col-md-3" key={index}>
								<CheckboxesGroup
									formValue={module}
									onChangeEvent={onCheckBoxGroupChangeArray}
									formErrors={[]}
									inputConf={{
										marginTop: "1",
										marginBottom: "0",
									}}
									initState={moduleArrayState}
								/>
							</div>
						))}
				</div>
				<div className="row mt-1">
					{typesAndValuesMessagePackage.length > 0 &&
						Object.keys(initDataMessagePackage).length > 0 && (
							<>
								<div className="col-12 col-md-10">
									<CustomForm
										onHandleFormClose={() => {}}
										onHandleSubmit={onHandleSubmit}
										typesAndValuesFields={
											typesAndValuesMessagePackage
										}
										initData={initDataMessagePackage}
										formTittle={""}
										dontShowCancelButton={true}
										id={null}
										getUrlPath={""}
										inputConf={{
											marginTop: "1",
											marginBottom: "0",
										}}
										buttonClick={buttonClick}
										onChangeFields={
											onChangeFieldsInitDataMessage
										}
										initOnChangeFields={
											onChangeFieldsInitDataMessage
										}
										dontShowSubmitButton={true}
										childFunc={childFunc2}
										dontShowErrosinOnChange={true}
										getObjectInfoOnChange={() => {}}
										notifyChange={() => {}}
									/>
								</div>
							</>
						)}
					<div className="col-12 col-md-2">
						<CustomButton
							buttonName={<><SaveOutlinedIcon fontSize="small" className="me-1" /> Update</>}
							buttonOnClick={onHandleSubmit}
							type={"success"}
							margins="mt-4 mb-3"
							disabled={false}
							className={"btn btn-sm w-100"}
						/>
					</div>
				</div>

				<CustomModal
					show={isLoading}
					customTittleText={""}
					customMessageModal={""}
					onHandleClose={() => {}}
					isLoading={isLoading}
					onHandleSuccess={() => {}}
				/>

				<CustomModal
					show={showModal}
					customTittleText={customTittleText}
					customMessageModal={customMessageModal}
					onHandleClose={() => setShowModal(false)}
					onHandleSuccess={() => {}}
					isLoading={isLoading}
					showSuccessButton={showSuccessButton}
					actionsFooterStyle={true}
					cancelText={cancelButtonText}
					successText={sucessButtonText}
				/>
			</div>
		</>
	);
}

export { UpdatePackageForm };
