import React, { useEffect } from 'react';
import { Modal, Button, Form, FormGroup } from 'react-bootstrap';
import WarningIcon from "../../assets/img/warning-icon.png";

function ModalDeleteTax({show, onHandleClose, title, bodyMessage, handleAction, isLoading}) {

    //close modal
    useEffect(() => {
        //clear form
        if(!isLoading){
            onHandleClose();
        }
    },[isLoading])

    return (
		<React.Fragment>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={show}
				onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
			>                    
				<Modal.Body>
					<Form style={{fontWeight:"bold", padding:"15px"}}>
                        <div className="d-flex mb-4">
                            <img src={WarningIcon} alt={"editIcon"} style={{height:"65px", width:"auto", margin:"auto"}}/>
                        </div>
                        <Modal.Title style={{textAlign:"center", fontWeight:"bold", margin:"auto"}}>{title}</Modal.Title>
                        <FormGroup>
                            <p style={{textAlign:"center", fontWeight:"bold", margin:"0 auto", color:"grey"}} className='mb-3'>
                                {bodyMessage}
                            </p>
                            <div  style={{display:"flex", justifyContent:"center", marginTop:"10px", gap:"10px"}}>
                                <Button 
                                    disabled={isLoading}
                                    onClick={handleAction}
                                    variant="danger" 
                                    style={{ width: "125px"}}
                                >
                                    {isLoading ? "Loading ..." : "Yes, delete"}
                                </Button>
                                <Button
                                    onClick={onHandleClose}
                                    variant="outline-secondary"
                                    style={{ width: "100px"}}
                                >
                                    Cancel
                                </Button>
                            </div>                       
                        </FormGroup>
                    </Form>

				</Modal.Body>
			</Modal>
		</React.Fragment>
    );
}

export {ModalDeleteTax};