import { useState } from 'react';

import { LaravelErrorsToObject } from '../utils/errors.laravel'
import Http from '../lib/http'


class Adapter {
  static response(data) {
    return data;
  }
}

const initialState = {
  id: "",
  symbol: "",
  name: "",
  type: "",
  system: "",
}

const useBrand = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [uom, setUom] = useState({ ...initialState });
  const [errors, setErrors] = useState({});
  
  const store = async (payload) => {
    try {
      setErrors({})
      setIsLoading(true);
      const { data: resp } = await Http.post(`/api/uom`, payload);
      const { data, errors, status, message } = resp;
      if(errors) {
        setErrors(LaravelErrorsToObject(errors));
        return
      }
      if(status) {
        setUom(() => Adapter.response(data));
        return data;
      } else {
        if(message) {
          setErrors((prev) => ({ ...prev, name:  `The name "${payload.name}" is already in use` }));
        }
      }
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    store,

    isLoading,
    setIsLoading,
    uom,
    setUom,
    errors,
    setErrors,
  }
}

export default useBrand;