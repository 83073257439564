import React from "react";
import style from "./style.css";

function Loader() {
	return (
		<div className="container-2">
			<div className="loader">
				<div className="loader-shap"></div>
				<div className="loader-shap"></div>
				<div className="loader-shap"></div>
			</div>
		</div>
	);
}

export { Loader };
