import { useState, useEffect, useRef, createContext } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { CustomSelect } from "../../components/customSelect/customSelect";
import { formValidations } from "./helpers";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CheckboxesGroup } from "../../components/Checkbox/CheckBoxGroup";
import { AsyncInput } from "../../components/AsyncInput/AsyncInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

//css
import "./customForm.css";

// custom Hooks
import { useApiRequest } from "../../customHooks/useApiRequest";
import { withStyles } from "@material-ui/core";
import MaterialUI from "../MaterialUI";

function CheckBoxGroupInput(props) {
	return (
		<>
			{!props.formValue.hidden && (
				<WrapperInput
					inputConf={props.inputConf}
					formValue={props.formValue}
					formErrors={props.formErrors}
				>
					<CheckboxesGroup
						formValue={props.formValue}
						onChangeEvent={props.onCheckBoxGroupChangeForm}
						formErrors={props.formErrors}
						inputConf={props.inputConf}
						initState={props.formValues}
					/>
				</WrapperInput>
			)}
		</>
	);
}

function Onlydisplay(props) {
	return (
		<div className="form-group col-1">
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "3px 1fr",
					gridColumnGap: "5px",
				}}
			>
				<label className="form-label">
					{props.formValue.text_label}
				</label>
				<div className="w-100 form-control custom-input">
					<p>{props.value}</p>
				</div>
			</div>
		</div>
	);
}

const PrimarySwitch = withStyles({
  switchBase: {
    color: "#0d6efd",
    '&$checked': {
      color: "#0b5ed7",
    },
    '&$checked + $track': {
      backgroundColor: "#0b5ed7",
    },
  },
  checked: {},
  track: {},
})(Switch);


function SwitchInput(props) {
	return (
		<>
			{!props.formValue.hidden && (
				<WrapperInput
					inputConf={props.inputConf}
					formValue={props.formValue}
					formErrors={props.formErrors}
				>
					<FormGroup row>
						<FormControlLabel
							control={
								<PrimarySwitch
									checked={props.value}
									onChange={props.handleChange}
									name={props.formValue.field_name}
									color="primary"
								/>
							}
							label={props.formValue.text_label}
						/>
					</FormGroup>
				</WrapperInput>
			)}
		</>
	);
}

function WrapperInput(props) {
	const errorsFinded =
		props.formErrors.filter(
			(error) => error.field_id === props.formValue.field_name,
		) || [];

	return (
		<>
			<div
				// className={`form-group ${!props?.inputConf?.marginTop ? "mt-2" : "mt-" + props?.inputConf?.marginTop }
					// ${!props?.inputConf?.marginBottom ? "mb-2" : "mb-" + props?.inputConf?.marginBottom}
					// ${!props?.inputConf?.col ? !props.formValue.col ? "col-6" : props.formValue.col : "col-" + props?.inputConf?.col}
					// ${!props?.inputConf?.colLarge ? !props.formValue.col ? "col-lg-6" : props.formValue.col : "col-lg-" + props?.inputConf?.colLarge}
					// ${!props?.inputConf?.colSmall
							// ? !props.formValue.col
								// ? "col-sm-12"
								// : props.formValue.col
							// : "col-sm-" + props?.inputConf?.colSmall}
					// ${props?.inputConf?.stringCol ? props?.inputConf?.stringCol : "" }
				// `}
				className={`form-group ${props?.inputConf?.className || `
					${!props?.inputConf?.marginTop ? " " : "mt-" + props?.inputConf?.marginTop }
					${!props?.inputConf?.marginBottom ? " " : "mb-" + props?.inputConf?.marginBottom}
					${!props?.inputConf?.col ? !props.formValue.col ? "col-6" : props.formValue.col : "col-" + props?.inputConf?.col}
					${!props?.inputConf?.colLarge ? !props.formValue.col ? "col-lg-6" : props.formValue.col : "col-lg-" + props?.inputConf?.colLarge}
					${!props?.inputConf?.colSmall
							? !props.formValue.col
								? "col-sm-12"
								: props.formValue.col
							: "col-sm-" + props?.inputConf?.colSmall}
					${props?.inputConf?.stringCol ? props?.inputConf?.stringCol : "" }
				`}`}
			>
				<div>
					{!props.formValue.dontSeeLabel && (
						<label
							style={{ gridColumn: "2" }}
							htmlFor={props.formValue.text_label}
							className={`form-label mb-0`}
						>
							{props.formValue.dotShowLabel ? (
								<span style={{ opacity: "0" }}>
									{props.formValue.text_label}
								</span>
							) : props.formValue.text_label }
							{props.formValue.required && (
								<span className="text-danger" style={{ fontSize: '1rem' }}>{" "}*</span>
							)}
						</label>
					)}
					{props.children}
					{errorsFinded.length > 0 && (
						<span style={{ gridColumn: "2", lineHeight: '1' }} className="text-danger d-inline-flex fs-small" >
							{errorsFinded[0].message}
						</span>
					)}
				</div>
			</div>
		</>
	);
}

function InputField(props) {
	const {nextSiblingConfig = {}, hasNextSibling = false, handlerSiblings = () => {}} = props;
	const inputRef = useRef(null);
	useEffect(() => {
		if (inputRef.current && props.formValue.autoFocus) {
			inputRef.current.focus();
		}
	}, []);

	return (
		<>
			{!props.formValue.hidden && (
				<WrapperInput
					inputConf={props.inputConf}
					formValue={props.formValue}
					formErrors={props.formErrors}
				>
					{/* onChange={props.onChangeForm} */}
					<div style={{ position: "relative", flexWrap: hasNextSibling ? 'nowrap' : 'wrap' }}>
						<input
							className="w-100 form-control custom-input"
							name={props.formValue.name}
							value={props.value}
							onChange={(e) =>
								props.onChangeForm(
									e.target.value,
									props.formValue.field_name,
								)
							}
							type={
								!props.formValue.type
									? "text"
									: props.formValue.type
							}
							placeholder={props.formValue.labelText}
							disabled={!!props.formValue.disabled}
							ref={inputRef}
							maxLength={
								props.formValue.maxLength
									? props.formValue.maxLength
									: "1000"
							}
							onKeyDown={
								!props.formValue.hasOwnProperty("onKeyDown")
									? () => {}
									: props.formValue.onKeyDown
							}
						></input>
						{hasNextSibling && (
							<MaterialUI.Button.Primary 
								disableElevation
								style={{ maxInlineSize: '100px !important', minWidth: 'auto'}}
								type="button"
								tabIndex="-1"
								onClick={() => handlerSiblings({
									field_name: props.filter.field_name,
									...nextSiblingConfig.data
								})}
							>
								{!!nextSiblingConfig.iconStart && <i className={nextSiblingConfig.iconStart}></i>}
								{!!nextSiblingConfig.text && <span className="px-1">{nextSiblingConfig.text}</span>}
								{!!nextSiblingConfig.iconEnd && <i className={nextSiblingConfig.iconEnd}></i>}
							</MaterialUI.Button.Primary>
						)}
						{!!props.formValue.showEyePassword && (
							<i
								className={`fas ${props.formValue.eyeIcon}`}
								style={{
									cursor: 'pointer',
									zIndex: '1000',
									position: 'absolute',
									right: '.5rem',
									top: 'calc(50% - .5rem)',
									color: '#b6b6b6',
								}}
								onClick={() =>
									props.formValue.onChangeTypeInput(
										props.formValue.type,
									)
								}
							></i>
						)}
					</div>
				</WrapperInput>
			)}
		</>
	);
}

function TextAreaField(props) {
	let value = "";

	if (
		props.formValue.hasOwnProperty("transformText") &&
		props.formValue.transformText
	) {
		const lines = props.value.split(",");
		lines.map((line) => (value += line + "\r\n"));
	} else {
		value = props.value;
	}

	return (
		<>
			<WrapperInput
				inputConf={props.inputConf}
				formValue={props.formValue}
				formErrors={props.formErrors}
			>
				<textarea
					className="form-control custom-text-area custom-text-area"
					rows={props.formValue.rows}
					cols={props.formValue.cols}
					placeholder={props.formValue.labelText}
					disabled={!!props.formValue.disabled}
					value={value}
					name={props.formValue.name}
					onChange={(e) =>
						props.onChangeForm(
							e.target.value,
							props.formValue.field_name,
						)
					}
					maxLength={
						props.formValue.maxLength
							? props.formValue.maxLength
							: "1000"
					}
				/>
				{/* value={props.value} */}
			</WrapperInput>
		</>
	);
}

function InputQuantityField(props) {
	const modifyValue = (type) => {
		let currentValue = props.value || 0;

		let newValue = 0;
		if (type == "rest") {
			newValue = currentValue - 1;
		}
		if (type == "add") {
			newValue = currentValue + 1;
		}

		const updateValue = newValue >= 0 ? newValue : currentValue;
		props.onChangeForm(updateValue, props.formValue.field_name);
	};

	const onChangeValue = (ev) => {
		if (!props.formValue.disabled) {
			props.onChangeForm(ev.target.value, props.formValue.field_name);
		}
	};

	return (
		<>
			<WrapperInput
				inputConf={props.inputConf}
				formValue={props.formValue}
				formErrors={props.formErrors}
			>
				<div className="input-group">
					<Button
						id="basic-addon1"
						onClick={() => modifyValue("rest")}
						variant="btn btn-sm btn-primary"
						disabled={props.formValue.disabled}
						className="ms-0"
						style={{ inlineSize: '40px' }}
					>
						<FontAwesomeIcon fontSize={12} icon={faMinus} />
					</Button>
					<Form.Control
						className="custom-input"
						style={{ textAlign: "center", minWidth: "60px" }}
						type="number"
						aria-label="Username"
						name={props.formValue.name}
						value={props.value}
						onChange={onChangeValue}
					/>
					<Button
						onClick={() => modifyValue("add")}
						id="basic-addon1"
						variant="btn btn-sm btn-primary"
						disabled={props.formValue.disabled}
						style={{ inlineSize: '40px' }}
					>
						<FontAwesomeIcon fontSize={12} icon={faPlus} />
					</Button>
				</div>
			</WrapperInput>
		</>
	);
}

function SelectField(props) {
	return (
		<>
			<WrapperInput
				inputConf={props.inputConf}
				formValue={props.formValue}
				formErrors={props.formErrors}
			>
				<CustomSelect
					className="w-100"
					field_id={props.formValue.field_name}
					selectTittle={""}
					defaultOptionText={props.formValue.placeHolder}
					itemArray={props.formValue.values}
					onChangeEvent={props.onChangeEvent}
					defaultValue={props.value}
					dontDefaultOption={props.formValue.dontDefaultOption}
					selectWidth={props?.formValue?.selectWidth || "100%"}
					selectCol={props.formValue.selectCol}
					disabled={props.formValue.disabled || false}
					selectClass={props.formValue.selectClass}
					hasNextSibling={!!Object.keys(props.nextSiblingConfig ?? {}).length}
					nextSiblingConfig={props.nextSiblingConfig}
					handlerSiblings={props.handlerSiblings}
				/>
			</WrapperInput>
		</>
	);
}

function WrapperAsyncInputField(props) {
	return (
		<>
			<WrapperInput
				inputConf={props.inputConf}
				formValue={props.formValue}
				formErrors={props.formErrors}
			>
				<AsyncInput
					selectCol={props.formValue.selectCol}
					filter={props.formValue}
					onChangeAsyncFilter={props.onChangeAsyncFilter}
					getObjectInfoOnChange={props.getObjectInfoOnChange || null}
					handleOnInputChange={props.handleOnInputChange}
					hasNextSibling={!!Object.keys(props.nextSiblingConfig ?? {}).length}
					nextSiblingConfig={props.nextSiblingConfig}
					handlerSiblings={props.handlerSiblings}
				/>
			</WrapperInput>
		</>
	);
}

function ButtonField(props) {
	return (
		<>
			<WrapperInput
				inputConf={props.inputConf}
				formValue={props.formValue}
				formErrors={props.formErrors}
			>
				{/* buttonOnClick={() => props.formValue.onclickHandler(props)}     */}
				<CustomButton
					buttonOnClick={() => props.onChangeEvent(props.formValue.field_name)}
					className={props.formValue.className || ""}
					buttonName={props.formValue.text_label}
					type={props.formValue.type}
					icon={props.formValue.icon}
					iconStyle={props.formValue.iconStyle}
					margins={props.formValue.margins}
					disabled={props.formValue.disabled}
					iconStart={props.formValue.iconStart}
					iconEnd={props.formValue.iconEnd}
				/>
			</WrapperInput>
		</>
	);
}

function ActionsForms(props) {
	if(props.dontShowCancelButton && props.dontShowSubmitButton) return null;
	let typeButton = !props.hasOwnProperty("typeButton") || !props.typeButton ? "primary" : props.typeButton;
	
	return (
		<>
			<div className="row d-flex flex-row justify-content-end mt-3">
				<div className={`d-flex justify-content-end gap-2 align-items-center`} style={{width: "100%"}}>
					{!props.dontShowCancelButton && (
						<Button 
							variant="secondary"
							onClick={props.onHandleFormClose}
							style={{ inlineSize: '90px' }}
							className="fs-small"
						>
							{!props.cancelStyleButtonText ? "Close" : props.cancelStyleButtonText}
						</Button>
					)}
					{!props.dontShowSubmitButton && (

						<Button
							className={`${props.successStyleButton ? props.successStyleButton : ""} fs-small`}
							variant={`${!props.successStyleButton ? typeButton : ""}`}
							onClick={props.onHandleFormSuccess}
							style={{ inlineSize: '90px' }}
						>
							{!props.successStyleButtonText ? "Save" : props.successStyleButtonText}
						</Button>
					)}
				</div>
			</div>
		</>
	);
}

const CustomFormContext = createContext();

function CustomForm(props) {
	const { getItem, isLoading, setIsLoading } = useApiRequest();

	const [formValues, setFormValues] = useState({ ...props.initData });
	const [formErrors, setFormErrors] = useState([]);
	const [listener, setListener] = useState(() => {});
	const [typesAndValuesFields, setTypesAndValuesFields] = useState([
		...props.typesAndValuesFields,
	]);

	useEffect(() => {
		setFormValues({ ...props.initData });
		setTypesAndValuesFields([...props.typesAndValuesFields]);
	}, [props.initData, props.typesAndValuesFields]);

	useEffect(async () => {
		if (props.dataToCharge && Object.keys(props.dataToCharge).length > 0) {
			setFormValues({ ...props.dataToCharge });
		}
		if ((props.id || props.urlParams) && props.getUrlPath) {
			setIsLoading(true);

			let { data } = await getItem(
				props.id,
				props.getUrlPath,
				props.urlParams || {},
			);

			if(props.hasOwnProperty("recordFound")) props.recordFound(data);

			if (props.hasOwnProperty("mapResponse")) {
				data = props.mapResponse(
					data,
					props.composedFields,
					props.dateFields,
				);
			}

			if (props.hasOwnProperty("initOnChangeFields")) {
				props.initOnChangeFields(data);
			}

			setIsLoading(false);

			Object.keys(data).map((key) => {
				data[key] =
					data[key] == null
						? ""
						: typeof data[key] == "boolean"
						? data[key].toString()
						: data[key];
			});

			setFormValues({ ...data });
		}
	}, []);

	const onChangeForm = (event, name) => {
		if (props.hasOwnProperty("notifyChange") && props.notifyChange) {
			props.notifyChange(true);
		}

		setState(name, event);
		const updatedFormErrors = formErrors.filter(error => error.field_id !== name);
		setFormErrors(updatedFormErrors);
	};

	const onSelectChangeForm = ({ field_id, value }) => {
		if (props.hasOwnProperty("notifyChange") && props.notifyChange) {
			props.notifyChange(true);
		}

		setState(field_id, value);
		const updatedFormErrors = formErrors.filter(error => error.field_id !== field_id);
		setFormErrors(updatedFormErrors);
	};
	
	const onCheckBoxGroupChangeForm = (checkState) => {
		if (props.hasOwnProperty("notifyChange") && props.notifyChange) {
			props.notifyChange(true);
		}

		let copy = {
			...formValues,
		};

		if (Object.keys(checkState).length > 0) {
			Object.keys(checkState).map((key) => {
				copy[key] = checkState[key];
			});
		}

		setFormValues({ ...copy });

		if (props.hasOwnProperty("onChangeFields")) {
			props.onChangeFields({
				...copy,
			});
		}
	};

	const onChangeAsyncFilter = (ev, target) => {
		if (props.hasOwnProperty("notifyChange") && props.notifyChange) {
			props.notifyChange(true);
		}

		setState(target, ev);
	};

	const handleChangeSwitch = (ev) => {
		setState(ev.target.name, ev.target.checked);
	};

	const setState = (key, value) => {
		const errors = formValidations(
			{ [key]: value },
			props.typesAndValuesFields,
			value,
		);

		if (!props.dontShowErrosinOnChange) {
			setFormErrors(errors);
		}

		setFormValues({
			...formValues,
			[key]: value,
		});

		if (props.hasOwnProperty("onChangeFields")) {
			props.onChangeFields({
				...formValues,
				[key]: value,
			});
		}
	};

	const onSubmit = (event) => {
		event.preventDefault();

		const errors = formValidations(
			formValues,
			props.typesAndValuesFields,
			null,
		);
		setFormErrors(errors);
		if (errors.length === 0) {
			props.onHandleSubmit(formValues);
		}
	};

	const getErrors = (value) => {
		const errors = formValidations(value, props.typesAndValuesFields, null);
		setFormErrors(errors);

		return errors;
	};

	useEffect(() => {
		if (props.hasOwnProperty("childFunc")) {
			props.childFunc.current = getErrors;
		}
	}, []);

	//handle changes when type in async input
	const handleOnInputChange = (ev) => {
		if (props.hasOwnProperty("handleOnInputChange")) {
			props.handleOnInputChange(ev);
		}
	};

	return (
		<>
			<CustomFormContext.Provider value={{ listener, setListener }}>
				{props.id && props.getUrlPath && !props.dontShowLoadModal && (
					<CustomModal
						show={isLoading}
						customMessageModal={""}
						onHandleClose={() => {}}
						isLoading={isLoading}
					/>
				)}
				<div className="">
					<div className="d-flex justify-content-start">
						<span className="text-center"> {props.formTittle} </span>
					</div>

					{/* props.typesAndValuesFields */}
					<form onSubmit={onSubmit} className="">
						<div className="row align-items-end" style={{ rowGap: '.15rem' }}>
							{typesAndValuesFields.length > 0 &&
								Object.keys(formValues).length > 0 &&
								typesAndValuesFields.map((formValue, index) => {
									const _inputConfig = {
										...(props.inputConf ?? {}),
										...(formValue.inputConf ?? {})
									};
									if (formValue.type_field === "text_input") {
										return (
											<InputField
												key={index}
												formValue={formValue}
												onChangeForm={onChangeForm}
												value={
													formValues[formValue.field_name]
												}
												formErrors={formErrors}
												inputConf={_inputConfig}
												nextSiblingConfig={formValue.nextSiblingConfig}
												handlerSiblings={props.handlerSiblings}
											/>
										);
									} else if (formValue.type_field === "text_quantity") {
										return (
											<InputQuantityField
												key={index}
												formValue={formValue}
												value={
													formValues[formValue.field_name]
												}
												onChangeForm={onChangeForm}
												formErrors={formErrors}
												inputConf={_inputConfig}
											/>
										);
									} else if (formValue.type_field === "select_input") {
										return (
											<SelectField
												key={index}
												formValue={formValue}
												value={
													formValues[formValue.field_name]
												}
												onChangeEvent={onSelectChangeForm}
												formErrors={formErrors}
												inputConf={_inputConfig}
												nextSiblingConfig={formValue.nextSiblingConfig}
												handlerSiblings={props.handlerSiblings}
											/>
										);
									} else if (formValue.type_field === "button") {
										return (
											<ButtonField
												key={index}
												formValue={formValue}
												onChangeEvent={props.buttonClick}
												formErrors={formErrors}
												inputConf={_inputConfig}
											/>
										);
									} else if (formValue.type_field === "checkBoxGroup") {
										return (
											<CheckboxesGroup
												key={index}
												formValue={formValue}
												onChangeEvent={
													onCheckBoxGroupChangeForm
												}
												formErrors={formErrors}
												inputConf={_inputConfig}
												initState={formValues}
											/>
										);
									} else if (formValue.type_field === "textArea") {
										return (
											<TextAreaField
												key={index}
												formValue={formValue}
												onChangeForm={onChangeForm}
												value={
													formValues[formValue.field_name]
												}
												formErrors={formErrors}
												inputConf={_inputConfig}
												nextSiblingConfig={formValue.nextSiblingConfig}
												handlerSiblings={props.handlerSiblings}
											/>
										);
									} else if (formValue.type_field === "searchInput") {
										if (formValue.disabled) {
											return (
												<InputField
													key={index}
													formValue={formValue}
													onChangeForm={onChangeForm}
													value={formValue.inputValue}
													formErrors={formErrors}
													inputConf={_inputConfig}
												/>
											);
										}
										return (
											<WrapperAsyncInputField
												key={index}
												formValue={formValue}
												value={formValues[formValue.field_name]}
												formErrors={formErrors}
												inputConf={_inputConfig}
												selectCol={formValue.col}
												filter={formValue}
												onChangeAsyncFilter={onChangeAsyncFilter}
												getObjectInfoOnChange={props.getObjectInfoOnChange || null}
												handleOnInputChange={handleOnInputChange}
												nextSiblingConfig={formValue.nextSiblingConfig}
												handlerSiblings={props.handlerSiblings}
											/>
										);
									} else if (formValue.type_field === "switch_input") {
										return (
											<SwitchInput
												key={index}
												formValue={formValue}
												onChangeForm={onChangeForm}
												value={
													formValues[formValue.field_name]
												}
												formErrors={formErrors}
												inputConf={_inputConfig}
												handleChange={handleChangeSwitch}
											/>
										);
									} else if (formValue.type_field === "checkbox_input") {
										return (
											<CheckBoxGroupInput
												key={index}
												formValue={formValue}
												onCheckBoxGroupChangeForm={
													onCheckBoxGroupChangeForm
												}
												formErrors={formErrors}
												inputConf={_inputConfig}
												formValues={formValues}
												value={
													formValues[formValue.field_name]
												}
											/>
										);
									}
								})}
						</div>
						{!!props.childrenBeforeActions && props.childrenBeforeActions}
						<ActionsForms
							onHandleFormClose={props.onHandleFormClose}
							onHandleFormSuccess={onSubmit}
							dontShowCancelButton={props.dontShowCancelButton}
							dontShowSubmitButton={props.dontShowSubmitButton}
							successStyleButton={props.successStyleButton}
							successStyleButtonText={props.successStyleButtonText}
							cancelStyleButtonText={props.cancelStyleButtonText}
							actionButonsContainerWidth={
								props.actionButonsContainerWidth
							}
							typeButton={props?.typeButton || null}
						/>
					</form>
				</div>
			</CustomFormContext.Provider>
		</>
	);
}

export { CustomForm, CheckBoxGroupInput, CustomFormContext };
