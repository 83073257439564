import React from "react";
import "./spinner.css";
import Spinner from "react-bootstrap/Spinner";

function SpinnerLoading() {
	return (
		<div className="spinner-container">
			<div className="spinner-content">
				<Spinner animation="border" role="status" size="lg"></Spinner>
			</div>
		</div>
	);
}

export { SpinnerLoading };
