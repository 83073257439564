import React from "react";
import ExpensiveForm2 from '../ExpensiveForm2';

import "./exponsiveNew.css";

function ExpensiveNew() {
	return (
		<article className="contenedor container-fluid">
			<ExpensiveForm2 />
		</article>
	);
}

export { ExpensiveNew };
