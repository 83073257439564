function CustomButton(props) {
	return (
			<button
				type="button"
				className={`   
                            ${props.className && props.className}
                            ${!props.className && "btn btn-sm"}
                            ${props.type && "btn-" + props.type}
                            ${!props.type && "btn-primary"}
                            ${props.margins && props.margins}
                            ${!props.margins && "mt-3 mb-3"}
                        `}
				onClick={() => props.buttonOnClick()}
				disabled={props.disabled} 
				style={{ ...props.style }} >
				{!props.dontShowIcon && (
					<i
						className={`${props.icon && props.icon}
                            ${!props.icon && ""}`}
							style={props.iconStyle ? props.iconStyle : { marginRight: "0px" }}
					></i>
				)}
				{!!props.iconStart && <i className={`${props.iconStart} me-2`}></i>}
				{!!props.buttonName && <span>{props.buttonName}</span> }
				{!!props.iconEnd && <i className={`${props.iconEnd} ms-2`}></i>}
			</button>
			
	);
}

export { CustomButton };
