import React from 'react';
import MaterialUI from '../../components/MaterialUI';

export const InvoiceEstimate = ({ loadingEstimate, estimate = {}, onClose }) => {
  const { client = {}, client_send_email, date_receipt, date_due, Balance, details = [] } = estimate;
  if(loadingEstimate) {
    return (
      <div className="row">
        <div className="col-12 text-center text-muted">
          <i className="fas fa-spinner fa-pulse fa-2x mb-2"></i>
          <span className='d-block fs-small mb-4'>Loading</span>
          <MaterialUI.Button.Dark onClick={onClose}>
            Close
          </MaterialUI.Button.Dark>
        </div>
      </div>
    )
  }
  if(!estimate.id) {
    return (
      <div className="row">
        <div className="col-12 text-center text-muted">
          <span className='d-block fs-fs-semi-small mb-0 fw-bold'>Estimate</span>
          <span className='d-block fs-small mb-4'>Not Found</span>
          <MaterialUI.Button.Dark onClick={onClose}>
            Close
          </MaterialUI.Button.Dark>
        </div>
      </div>
    )
  }
  return (
    <div className="row text-muted font-inter">
      <div className="col-12">
        <div className="col-12 fs-small mb-2">
          <div className="row">
            <div className="col-6 text-center">
              <p className="mb-0">
                <b className='d-block'>Estimate date</b>
                <span>{date_receipt ?? '-'}</span>
              </p>
            </div>
            <div className="col-6 text-center">
              <p className="mb-0">
                <b className='d-block'>Expiration date</b>
                <span>{date_due ?? '-'}</span>
              </p>
            </div>
          </div>
        </div>
        <div className='fs-small mb-2'>
          <b className='d-block'>Customer</b>
          <p className="mb-0 ps-2">{client.name ?? '-'}</p>
          <p className="mb-0 ps-2">{client_send_email ?? '-'}</p>
          {!!client.address_1 && <p className="mb-0 ps-2">{client.address_1 ?? '-'}</p>}
          {!!client.address_2 && <p className="mb-0 ps-2">{client.address_2 ?? '-'}</p>}
          {!!client.business_phone && <p className="mb-0 ps-2">{client.business_phone ?? '-'}</p>}
          <p className="mb-0 ps-2">
            {!!client.city && <span>{`${client.city}` ?? '-'}</span>}
            {!!client.state && <span>{` ${client.state}` ?? '-'}</span>}
            {!!client.postal_code && <span>{` ${client.postal_code}` ?? '-'}</span>}
          </p>
          {!!client.country && <p className="mb-0 ps-2">{client.country ?? '-'}</p>}
        </div>
        <div className='mb-2 text-end'>
          <strong className='d-block fs-small'>Balance Due</strong>
          <h2 className="mb-0">{Balance ?? '$ 0.00'}</h2>
        </div>
      </div>
      <div className="col-12">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th className='fs-semi-small px-1'>Item</th>
                <th className='fs-semi-small px-1'>Description</th>
                <th className='fs-semi-small px-1 text-center' style={{ inlineSize: '50px' }} >Qty</th>
                <th className='fs-semi-small px-1 text-end' style={{ inlineSize: '90px' }} >Rate</th>
                <th className='fs-semi-small px-1 text-end' style={{ inlineSize: '100px' }} >Amount</th>
              </tr>
            </thead>
            <tbody>
              {details.map((row) => (
                <tr key={row.id}>
                  <td className='fs-semi-small align-content-center px-1'>
                    <p className="mb-0 fs-small fw-bold text-muted">{row.item?.sku}</p>
                    <p className="mb-0">{row.item?.name}</p>
                  </td>
                  <td className='fs-semi-small align-content-center px-1'>{row.comment}</td>
                  <td className='fs-semi-small align-content-center px-1 text-center'>{Number(row.quantity).toFixed(2)}</td>
                  <td className='fs-semi-small align-content-center px-1 text-end'>{Number(row.price).toFixed(2)}</td>
                  <td className='fs-semi-small align-content-center px-1 text-end'>{(Number(row.quantity) * Number(row.price)).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-12 text-end">
        <MaterialUI.Button.Dark onClick={onClose}>
          Close
        </MaterialUI.Button.Dark>
      </div>
    </div>
  )
}
