import imglogo from "../../../assets/img/logo_t4i.png";
import ReactDOM from "react-dom";
import React, { useState } from "react";
import { useForm } from "./hooks/useForm";
import "./signup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Login from "../../../views/login/Login";
import { useHistory } from "react-router-dom";
import { CustomModal } from "../../../components/CustomModal/CustomModal";

const initialForm = {
	name: "",
	companyName: "",
	email: "",
	phone_number: "",
	password: "",
	confirmPassword: "",
	phone_ext: "+1",
};

const validationsForm = (form) => {
	let errors = {};

	const regexTextFiels = /^([a-zA-Z0-9 _-]+)$/;

	if (!regexTextFiels.test(form.name)) {
		errors.name = "name is invalid.";
	}

	if (!regexTextFiels.test(form.companyName)) {
		errors.companyName = "company name is invalid.";
	}

	if (!form.name.trim()) {
		errors.name = "name is required.";
	}

	if (!form.companyName.trim()) {
		errors.companyName = "company name is required.";
	}

	if (!form.email.trim()) {
		errors.email = "email is required.";
	}

	const regexEmail =
		/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

	if (!regexEmail.test(form.email)) {
		errors.email = "The email is invalid.";
	}

	const validFormNumber =
		/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

	if (!validFormNumber.test(form.phone_number)) {
		errors.phone_number = "phone number is invalid.";
	}

	if (!form.phone_number.trim()) {
		errors.phone_number = "phone number is required.";
	}

	if (form.phone_number.length < 10) {
		errors.phone_number = "phone number must be 10 digits.";
	}

	if (!form.password) {
		errors.password = "passwork is required.";
	}

	const validatePassword = new RegExp(
		"^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,20})",
	);

	if (!validatePassword.test(form.password)) {
		errors.password =
			"Password must be 8-20 characters, includes at least three of the four types: upper/lower letters, numbers or symbols.";
	}

	if (!form.confirmPassword) {
		errors.confirmPassword = "confirm password is required.";
	}

	if (form.confirmPassword != form.password) {
		errors.confirmPassword =
			"password are not equal to confirm password field.";
	}

	return errors;
};

let stylesError = {
	fontWeight: "bold",
	color: "#dc3545",
	fontSize: "13px",
	wordBreak: "break-all",
};

function Signup() {
	const history = useHistory();

	const {
		form,
		errors,
		handleChange,
		handleBlur,
		handleSubmit,
		showSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		cancelButtonText,
		sucessButtonText,
		isLoading,
	} = useForm(initialForm, validationsForm);

	const styles = {
		color: "#1F3656",
		textAlign: "center",
		fontSize: "15px",
	};

	const gotoLogin = () => {
		history.push("/");
	};

	const [hidePassword, setHidePassword] = useState(true);
	const [passwordType, setPasswordType] = useState("password");
	const togglePasswordIcon = () => {
		setHidePassword((lastState) => !lastState);
		if (passwordType === "password") {
			setPasswordType("text");
		} else {
			setPasswordType("password");
		}
	};

	return (
		<>
			<div className="login">
				<div className="form-container__signup">
					<div className="form-container_top">
						<img src={imglogo} alt="logo" className="logo" />
						<span
							className="mb-2"
							style={{
								color: "#1F3656",
								fontWeight: "bold",
								fontSize: "1.2rem",
							}}
						>
							Tech4Inventory
							<div
								className="information-content__signup"
								style={styles}
							>
								<p style={{ margin: "0px" }}>
									Alredy registered ?{" "}
									<span
										style={{
											fontWeight: "bold",
											color: "#EA5E20",
										}}
										onClick={gotoLogin}
									>
										Sign In
									</span>{" "}
								</p>
							</div>
						</span>
					</div>
					<form
						className="form form-container"
						onSubmit={handleSubmit}
					>
						<div className="theinputs" style={{ padding: "20px" }}>
							{/* name input */}
							<label className="label"> Business Name *</label>
							<input
								type="text"
								className="input"
								name="name"
								id="name"
								value={form.name}
								onBlur={handleBlur}
								onChange={handleChange}
								title="Enter your name"
								required
							/>
							{errors.name && (
								<p style={stylesError}>{errors.name}</p>
							)}
							{/* Company name */}
							<label className="label"> Name Contact *</label>
							<input
								type="text"
								className="input input-company-name"
								name="companyName"
								id="companyName"
								value={form.companyName}
								onBlur={handleBlur}
								onChange={handleChange}
								title="Enter your company name"
								required
							/>
							{errors.companyName && (
								<p style={stylesError}>{errors.companyName}</p>
							)}
							{/* Email input */}
							<label className="label"> Email *</label>
							<input
								type="email"
								className="input input-email__signup"
								name="email"
								id="email"
								value={form.email}
								onBlur={handleBlur}
								onChange={handleChange}
								title="Enter your email"
								required
							/>
							{errors.email && (
								<p style={stylesError}>{errors.email}</p>
							)}
							<label className="label"> Phone Number *</label>
							<div className="number-container">
								<select
									name="phone_ext"
									id="phone_ext"
									className="phone-number-ext"
									disabled={true}
									value={form.phone_ext}
									onChange={handleChange}
								>
									<option value="+1">+1</option>
								</select>
								<input
									type="text"
									className="input input-email__signup main-number"
									name="phone_number"
									id="phone_number"
									value={form.phone_number}
									onBlur={handleBlur}
									onChange={handleChange}
									title="Enter your phone number"
									required
								/>
							</div>
							{errors.phone_number && (
								<p style={stylesError}>{errors.phone_number}</p>
							)}
							{/* Password input */}
							<div className="field-container mt-2">
								<label className="label"> Password *</label>
								<input
									type={passwordType}
									className="input input-password"
									name="password"
									id="password"
									value={form.password}
									onBlur={handleBlur}
									onChange={handleChange}
									title="Enter your password"
									required
								/>
								{!hidePassword && (
									<i
										className="fas fa-eye-slash eye-icon"
										style={{ cursor: "pointer" }}
										onClick={togglePasswordIcon}
									></i>
								)}
								{hidePassword && (
									<i
										className="fas fa-eye eye-icon"
										style={{ cursor: "pointer" }}
										onClick={togglePasswordIcon}
									></i>
								)}
							</div>
							{errors.password && (
								<p style={stylesError}>{errors.password}</p>
							)}
							{/* Confirm password input */}
							<div className="field-container">
								<label className="label">
									{" "}
									Confirm password *
								</label>
								<input
									type={passwordType}
									className="input input-password"
									name="confirmPassword"
									id="confirmPassword"
									value={form.confirmPassword}
									onBlur={handleBlur}
									onChange={handleChange}
									title="Enter your password"
									required
								/>
								{!hidePassword && (
									<i
										className="fas fa-eye-slash eye-icon"
										style={{ cursor: "pointer" }}
										onClick={togglePasswordIcon}
									></i>
								)}
								{hidePassword && (
									<i
										className="fas fa-eye eye-icon"
										style={{ cursor: "pointer" }}
										onClick={togglePasswordIcon}
									></i>
								)}
							</div>
							{errors.confirmPassword && (
								<p style={stylesError}>
									{errors.confirmPassword}
								</p>
							)}
							<button className="primary-button signup-button mt-1 d-flex justify-content-center align-items-center">
								{isLoading && (
									<div
										className="spinner-border"
										role="status"
									>
										<span className="sr-only">
											Loading...
										</span>
									</div>
								)}
								{!isLoading && "SIGN UP"}
							</button>
						</div>
					</form>
				</div>
			</div>

			{/* <CustomModal
        show={isLoading}
        customTittleText={''}
        customMessageModal={''}
        onHandleClose={() => { }}
        isLoading={isLoading}
      /> */}

			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowModal(false)}
				onHandleSuccess={() => {}}
				isLoading={false}
				showSuccessButton={showSuccessButton}
				actionsFooterStyle={true}
				cancelText={cancelButtonText}
				successText={sucessButtonText}
			/>
		</>
	);
}

export { Signup };
