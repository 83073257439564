import React from "react";
import { Grid, CircularProgress, Box } from "@material-ui/core";

import AlertComponent from '../components/AlertComponent';
import MaterialUI from "../../../components/MaterialUI";

import { validateEmail } from '../../../utils/validations';
import useApiRequestSettingEmail from "../../../customHooks/useApiRequestSettingEmail";
import LogoEmail from "../../../assets/img/icon-email.png";

const FormEmail = () => {
	const {
		email,
		isLoading,
		error,
		setEmail,
		removeFromErrorByKey,
    addFromErrorByKey,
    updateEmail,
	} = useApiRequestSettingEmail();
  const [showAlert, setShowAlert] = React.useState(false);

	const handleClose = (_, reason) => {
		if (reason === 'clickaway') return;
    setShowAlert(false);
	};

	const handleSubmit = async (ev) => {
		ev.preventDefault();

    if(email.from_address && !validateEmail(email.from_address)) {
      addFromErrorByKey('from_address', 'This field has an invalid format.')
      return;
    }
    try {
			await updateEmail(email);
			setShowAlert(true);
		} catch (error) {}

	};

	const handleTypingChange = (key, value) => {
		removeFromErrorByKey(key);
		setEmail((state) => ({ ...state, [key]: value }));
	};

	if (isLoading) return <CircularProgress />;

	return (
    <Box p={2}>
      <AlertComponent 
				open={showAlert} 
        handleClose={handleClose} 
        severity="success" 
        message="It has been successfully updated"
			/>
      <div className="text-center">
        <figure className="py-5">
          <img src={LogoEmail} height={100} alt="Icon Email"/>{" "}
        </figure>
      </div>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <MaterialUI.Input 
							state={email.mail_driver}
							setState={(value) => handleTypingChange("mail_driver", value)}
							error={ error.mail_driver && error.mail_driver}
							label="Mail Driver"
						/>
          </Grid>
          <Grid item xs={12} md={4}>
            <MaterialUI.Input 
							state={email.host}
							setState={(value) => handleTypingChange("host", value)}
							error={ error.host && error.host}
							label="Host"
						/>
          </Grid>
          <Grid item xs={12} md={4}>
            <MaterialUI.Input 
							state={email.port}
							setState={(value) => handleTypingChange("port", value)}
							error={ error.port && error.port}
							label="Port"
						/>
          </Grid>
          <Grid item xs={12} md={4}>
            <MaterialUI.Input 
							state={email.username}
							setState={(value) => handleTypingChange("username", value)}
							error={ error.username && error.username}
							label="Username"
						/>
          </Grid>
          <Grid item xs={12} md={4}>
            <MaterialUI.Input 
              state={email.password}
              setState={(value) => handleTypingChange("password", value)}
              error={ error.password && error.password}
              label="Password"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MaterialUI.Input 
              state={email.encryption}
              setState={(value) => handleTypingChange("encryption", value)}
              error={ error.encryption && error.encryption}
              label="Encryption"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MaterialUI.Input 
              state={email.from_address}
              setState={(value) => handleTypingChange("from_address", value)}
              error={error.from_address && error.from_address}
              label="From Address"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MaterialUI.Input 
              state={email.from_name}
              setState={(value) => handleTypingChange("from_name", value)}
              error={error.from_name && error.from_name}
              label="From Name"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MaterialUI.Button.Success type="submit" fullWidth>
							Save
						</MaterialUI.Button.Success>
          </Grid>
        </Grid>
      </form>
    </Box>
	);
};

export default FormEmail;
