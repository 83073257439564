import { createContext } from "react";
import { usePayTerms } from "../customHooks/usePayTerms";

export const SaleContext = createContext();

export function SaleContextProvider({ children }) {
	const { fetchPayTerms, setPayTerms, payTerms } = usePayTerms({ requestAutomatically: false });

	const share = {
		fetchPayTerms, setPayTerms, payTerms,
	};
  
	return (
		<SaleContext.Provider value={share}>
			{children}
		</SaleContext.Provider>
	);
}
