import { useCallback, useEffect, useState } from 'react';
import EmailService from '../services/EmailService';

class AdapterEmail {
  static response(data) {
    let normalizedData = {};
    for (let key in data) {
      normalizedData[key] = data[key] || ''
    }
    return normalizedData;
  }
}

const initialState = {
  mail_driver: '',
  host: '',
  port: '',
  username: '',
  password: '',
  encryption: '',
  from_address: '',
  from_name: '',
}


const useApiRequestSettingEmail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState({ ...initialState });
  const [error, setError] = useState({});

  const fetchEmail = useCallback(async() => {
    try {
      setError({})
      setIsLoading(true);
      const { status, data } = await EmailService.getEmailSetting();
      if(status) setEmail(() => AdapterEmail.response(data));
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateEmail = useCallback(async(payload) => {
    try {
      setError({})
      setIsLoading(true);
      const resp = await EmailService.updateEmailSetting(payload);
      if(resp.status) setEmail(() => AdapterEmail.response(resp.data));
      else {
        setError(resp.msg);
        throw new Error("Error updating setting SMS Twilio");
      }
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const removeFromErrorByKey = (key) => {
    setError({
    ...error,
      [key]: ''
    });
  }

  const addFromErrorByKey = (key, newValue) => {
    setError({
    ...error,
      [key]: newValue
    });
  }

  useEffect(() => {
    fetchEmail();
  }, []);

  return {
    addFromErrorByKey,
    removeFromErrorByKey,
    updateEmail,
    fetchEmail,
    setEmail,

    isLoading,
    email,
    error
  }
}

export default useApiRequestSettingEmail;