import React from 'react'
import { Switch, Route, Link, useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Breadcrumbs, Grid, Typography, Box, Container, Menu, MenuItem } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';

import { AppContext } from '../../context/AppContext';
import useScreenWidth from '../../customHooks/useScreenWidth';

import ViewTax from './Tax';
import ViewPayment from './Payment';
import ViewEmail from './Email';
import ViewSMS from './Sms';
import ViewProduct from './Product';
import ViewSale from './Sale';
import ViewReward from './Reward';
import ViewSystem from './System';
import MaterialUI from '../../components/MaterialUI';

import './settings.css';

export const SettingRoutes = () => {
  return (
    <Switch>
      <Route path="/settings/tax" component={ViewTax} />
      <Route path="/settings/payment" component={ViewPayment} />
      <Route path="/settings/e-mail" component={ViewEmail} />
      <Route path="/settings/sms" component={ViewSMS} />
      <Route path="/settings/product" component={ViewProduct} />
      <Route path="/settings/sale" component={ViewSale} />
      <Route path="/settings/reward" component={ViewReward} />
      <Route path="/settings/system" component={ViewSystem} />
    </Switch>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'sticky',
    top: '0'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#0d6efd',
      color: 'white',
    }
  },
  linkParent: {
    fontSize: '20px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textDecoration: 'none',
    color: '#0d6efd'
  },
  linkActive: {
    backgroundColor: '#0d6efd',
    color: 'white',
  },
  boxContainer: {
    border: '1px solid var(--bs-gray-300)',
    borderRadius: '.3rem',
    minBlockSize: '31.25rem',
    padding: 0,
    overflow: 'hidden',
  }
}));

const routes = [
  { name: 'Tax', path: '/settings/tax' },
  // { name: 'Product', path: '/settings/product' },
  // { name: 'Sale', path: '/settings/sale' },
  { name: 'Payment', path: '/settings/payment' },
  // { name: 'System', path: '/settings/system' },
  { name: 'E-mail', path: '/settings/e-mail' },
  { name: 'SMS', path: '/settings/sms' },
  // { name: 'Reward', path: '/settings/reward' },
];

export const Settings = () => {
  const { changeNavigationVisible } = React.useContext(AppContext);
  const classes = useStyles();
  const location = useLocation();
  const pathname = location.pathname;
  const isMobile = useScreenWidth();
  
  const [currentPath, setCurrentPath] = React.useState(() => routes.find((route) => route.path === pathname)?.name ?? '-');

  React.useEffect(() => {
    const newPathName = routes.find((route) => route.path === pathname)?.name ?? '-';
    setCurrentPath(newPathName);
    setTimeout(() => {
      changeNavigationVisible(true);
    }, 400);
  }, [location.pathname])

    
  return (
    <Container maxWidth='xl' className='p-0 p-md-2'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box p={2} className='breadcrumb__title mb-2'>
            <Breadcrumbs>
              <Link color="inherit" to="/dashboard" className={classes.linkParent}>Settings</Link>
              <Typography color="textPrimary">{currentPath}</Typography>
            </Breadcrumbs>
          </Box>
        </Grid>
        <Grid item xs={12} md={2} className='menu__container'>
          <div className={classes.root}>
            { isMobile ? <MenuMobile /> : <MenuDesktop /> }
          </div>
        </Grid>
        <Grid item xs={12} md={10} className='mt-2'>
          <Box className={classes.boxContainer}>
            <SettingRoutes />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

function MenuDesktop() {
  const classes = useStyles();
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <Box p={2}>
      <p style={{ marginBottom: 0 }}>Menu</p>
      <List component="nav" className='menu__container__nav'>
        {
          routes.map(item => (
            <ListItem
              button
              key={item.name}
              className={`${classes.link} ${pathname === item.path ? classes.linkActive : ''}`}
              component={Link}
              to={item.path}
            >
              <ListItemText primary={item.name} />
            </ListItem>
          ))
        }
      </List>
    </Box>
  )
}

function MenuMobile() {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToPage = ({ path }) => {
    handleClose();
    history.push(path);
  }

  return (
    <div className='py-2'>
      <MaterialUI.Button.Primary aria-controls="simple-menu" aria-haspopup="true" type='button' onClick={handleClick}>
        <ListIcon className='me-2' /> Menu
      </MaterialUI.Button.Primary>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
      >
        { routes.map((item, index) => (
          <MenuItem style={{ minInlineSize: '200px' }} key={index} onClick={() => goToPage(item)}>{ item.name }</MenuItem>
        ))}
      </Menu>
    </div>
  );
}