import React, { useEffect, useState } from "react";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FaCaretDown, FaLongArrowAltRight } from "react-icons/fa";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import no_image from "../../assets/img/no_image.png";

import { ItemContext } from "../../components/ItemProviders";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import MenuItem from "@material-ui/core/MenuItem";
import "./item.css";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomSelect } from "../../components/customSelect/customSelect";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { CustomFilterComponent } from "../../components/CustomFiltersComponent/CustomFiltersComponent";

import {
	tableHeaders,
	dataFields,
	composedFields,
	parseFilters,
	tableHeadersToDataFieldsProducts as tableHeadersToDataFields,
	dataFieldsToOrderFilterProducts as dataFieldsToOrderFilter,
	tableHeadersToOrderFilterProducts as tableHeadersToOrderFilter,
} from "../../context/constants";

//custom Hooks
import { usePaginationHook } from "../../customHooks/usePaginationHook";
import { PaginationControlled } from "../../components/CustomPagination/CustomPaginacion";

//get items
const DELETE_TITTLE = "Are you sure you want to delete this item?";
const ERROR_MESSAGE = "An error has occured";


function AddItem() {
	const {
		app_conf,
		isLoading,
		setShowModalResponse,
		showModalResponse,
		stock_receipt,
		deleteItem,
		responseMessageError,
		successfullResponse,
		setResponseMessageError,
		showSuccessButton,
		setShowSuccessButton,
		table_add_configuration,
		itemFilters,
		setItemFilters,
		setSincronizedItems,
		itemPagination,
	} = React.useContext(ItemContext);

	const {
		filterInputChange,
		selectOnChange,
		onChangeNumberSelect,
		handlePagination,
		filtersDefinition,
		setFiltersDefition,
	} = usePaginationHook(setSincronizedItems, setItemFilters, itemFilters);

	const history = useHistory();

	const styles = {
		fontSize: "14px",
		fontWeight: "bold",
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const [currentId, setCurrentId] = useState(null);

	const [customTitleModal, setCustomTitleModal] =
		useState(DELETE_TITTLE);

	const onDelete = () => {
		setCustomTitleModal(`Are you sure you want to delete item #${currentId}?`);
		setAnchorEl(null);
		setShowModalResponse(true);
		setShowSuccessButton(true);
	};

	const onDeleteMobileTable = (currentId) => {
		setCurrentId(currentId);
		setCustomTitleModal(`Are you sure you want to delete item #${currentId}?`);
		setAnchorEl(null);
		setShowModalResponse(true);
		setShowSuccessButton(true);
	};

	const onEdit = () => {
		history.push({
			pathname: "/update_item_form",
			search: `?product_id=${currentId}`,
		});
	};

	const onHandleClose = () => {
		setShowModalResponse(false);
		setResponseMessageError("");
	};

	const onHandleSuccess = () => {
		deleteItem(currentId);
	};

	const onCloseModal = () => {
		setAnchorEl(null);
	};

	const onMenuActions = (event, id) => {
		setCurrentId(id);
		setAnchorEl(event.currentTarget);
	};

	// const customTitleModal = DELETE_TITTLE;
	const customMessageModal = !successfullResponse
		? responseMessageError
			? ERROR_MESSAGE + ": " + responseMessageError
			: ""
		: "";

	const {
		typesBrand,
		typesCategory,
		typesLocations,
		typesStatus,
		typesVendors,
		typesUom,
	} = parseFilters(table_add_configuration);

	const filterList = () => {
		setSincronizedItems(false);
	};

	const actionsDefinition = [
		{
			name: "Edit",
			func: onEdit,
			icon: faEdit,
		},
		{
			name: "Delete",
			func: onDelete,
			icon: faTrashAlt,
		},
	];

	const filters = [
		{
			field_id: "brand_id",
			selectTittle: "Brand",
			styles: styles,
			defaultOptionText: "Select a brand",
			itemArray: typesBrand,
			defaultValue: "",
		},
		{
			field_id: "category_id",
			selectTittle: "Category",
			styles: styles,
			defaultOptionText: "Select a category",
			itemArray: typesCategory,
			defaultValue: "",
		},
		{
			field_id: "vendor_id",
			selectTittle: "Vendors",
			styles: styles,
			defaultOptionText: "Select a vendor",
			itemArray: typesVendors,
			defaultValue: "",
		},
		{
			field_id: "location_id",
			selectTittle: "Location",
			styles: styles,
			defaultOptionText: "Select a location",
			itemArray: typesLocations,
			defaultValue: "",
		},
		{
			field_id: "active",
			selectTittle: "Product Status",
			styles: styles,
			defaultOptionText: "Select a status",
			itemArray: typesStatus,
			defaultValue: "",
		},
	];

	useEffect(() => {
		setFiltersDefition([...filters]);
	}, [
		typesBrand,
		typesCategory,
		typesUom,
		typesVendors,
		typesLocations,
		typesStatus,
	]);

	const delelefilterFromFilters = (currentFilters, filterId) => {
		const copyFilters = [...currentFilters];
		const hasFilter = copyFilters.findIndex(
			(filterItem) => filterItem.field_id === filterId,
		);
		if (hasFilter != -1) {
			copyFilters.splice(hasFilter, 1);
		}
		return copyFilters;
	};

	const onChangeOrderArrow = (
		dataFieldsToOrderFilter = [],
		tableHeadersToDataFields = [],
		orderField,
		typeOrder,
	) => {
		const orderFieldFilter =
			dataFieldsToOrderFilter[tableHeadersToDataFields[orderField]];

		const filters = [
			{
				field_id: "type_order",
				value: typeOrder,
			},
			{
				field_id: "field_order",
				value: orderFieldFilter,
			},
			{
				field_id: "page",
				value: 1,
			},
		];

		let copyFilters = delelefilterFromFilters(itemFilters, "type_order");
		copyFilters = delelefilterFromFilters(copyFilters, "field_order");
		copyFilters = delelefilterFromFilters(copyFilters, "page");

		copyFilters = [...copyFilters, ...filters];
		setItemFilters([...copyFilters]);
		setSincronizedItems(false);
	};

	const [optionListIndex, setOptionListIndex] = useState(null); // Estado para almacenar el índice del elemento con lista de opciones abierto

	const handleOptionListToggle = (index) => {
			setOptionListIndex(index === optionListIndex ? null : index); // Alternar la apertura y cierre de la lista de opciones
	};

	const [record, setRecord] = useState(undefined)

	return (
		<>
			<div className="modal fade" id="modalDetail" data-bs-backdrop="static" tabIndex="-1" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="modalDetailLabel">Details</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							{!!record && (
								<div className="table-responsive font-inter">
									<table className="table table-sm">
										<thead>
											<tr>
												<th style={{ minInlineSize: '120px' }}>Sku</th>
												<th style={{ minInlineSize: '120px' }}>UPC</th>
												<th style={{ minInlineSize: '200px' }}>Color</th>
												<th style={{ minInlineSize: '80px' }}>Size</th>
												<th style={{ minInlineSize: '50px' }}>Stock</th>
											</tr>
										</thead>
										<tbody>
											{!!record.item_variants.length ? record.item_variants.map((item, index) => (
												<tr key={index}>
													<td>{item.sku}</td>
													<td>{item.upc}</td>
													<td>{item.color}</td>
													<td>{item.size}</td>
													<td>{item.quantity ?? '0'}</td>
												</tr>
											)) : null}
										</tbody>
									</table>
								</div>
							)}
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary px-4" data-bs-dismiss="modal">
								<span className="fs-small">Close</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<CustomModal
				show={showModalResponse}
				customTittleText={customTitleModal}
				customMessageModal={customMessageModal}
				onHandleClose={onHandleClose}
				showSuccessButton={showSuccessButton}
				onHandleSuccess={onHandleSuccess}
				actionsFooterStyle={true}
				cancelText={"No"}
				successText={"Yes"}
			/>
			<div className="box container-fluid">
				<div className="container-title mb-3">
					<h1 className="container__title mb-0 py-1">{app_conf.title}</h1>
				</div>
				<CustomFilterComponent
					filtersDefinition={filtersDefinition}
					onClickfilter={filterList}
					filterInputChange={filterInputChange}
					onCreateNewItem={() => history.push("/create_item_form")}
					onChangePageEvent={onChangeNumberSelect}
					renderFilter={(filter, index) => (
						<CustomSelect
							key={index}
							field_id={filter.field_id}
							selectTittle={filter.selectTittle}
							styles={filter.styles}
							defaultOptionText={filter.defaultOptionText}
							itemArray={filter.itemArray}
							onChangeEvent={selectOnChange}
							selectWidth={"100%"}
							defaultValue={filter.defaultValue}
							selectCol={"col-12"}
						/>
					)}
				/>
				<div className="product-dataList-desktop-wrapper">
					<CustomDataList
						dontShowPerPageSelect={true}
						onChangePageEvent={onChangeNumberSelect}
						style={styles}
						isLoading={isLoading}
						dataList={stock_receipt}
						tableHeaders={tableHeaders}
						itemPagination={itemPagination}
						handlePagination={handlePagination}
						showOrderArrows={true}
						onChangeOrderArrow={onChangeOrderArrow}
						tableHeadersToDataFields={tableHeadersToDataFields}
						dataFieldsToOrderFilter={dataFieldsToOrderFilter}
						tableHeadersToOrderFilter={tableHeadersToOrderFilter}
						itemFilters={itemFilters}
						showLinearIndicator={true}
						showDataInLoading={true}
						render={(element, index) => (
							<CustomDataListItem
								key={index}
								dataFields={dataFields}
								// imageFields={imageFields}
								imageFields={[]}
								composedFields={composedFields}
								arrayFields={[]}
								element={element}
								index={index}
								no_image={no_image}
								renderActions={() => (
									<>
										<PositionedMenu
											title={"Actions"}
											anchorEl={anchorEl}
											setAnchorEl={setAnchorEl}
											handleClose={onCloseModal}
											handleClick={(event) =>
												onMenuActions(event, element.id)
											}
											actionsDefinition={
												actionsDefinition
											}
											renderActions={(action, index) => (
												<MenuItem
													sx={{
														boxShadow: "none",
													}}
													key={index}
													onClick={() =>
														action.func()
													}
												>
													<div
														className="items-option"
														style={{
															padding: "0 5px",
															color: "grey",
														}}
													>
														<FontAwesomeIcon
															style={{
																fontSize: '.8rem',
																margin: "4px",
																marginRight: "10px",
															}}
															icon={action.icon}
															className={
																action.name == "Edit" 
																	? "text-primary"
																	: action.name == "Delete"
																		? 'text-danger'
																		: 'inherit'
															}
														/>
														<p className="mb-0 fs-small">{action.name}</p>
													</div>
												</MenuItem>
											)}
										></PositionedMenu>
									</>
								)}
								renderComponentInField={(
									data,
									rowInfo,
									elementInfo,
								) => {
									if(rowInfo.field_name === "image_") {
										if (
											rowInfo &&
											rowInfo.hasOwnProperty("renderType") &&
											rowInfo.renderType === "imageAvatar"
										) {
											return (
												<div
													className={`align-middle text-center`}
													key={elementInfo?.name}
												>
													{/* key={dataField.field_name} */}
													<img
														style={{
															borderRadius: "50%",
														}}
														className="item-img"
														alt="No image"
														src={
															elementInfo?.images
																.length > 0
																? elementInfo
																		?.images[0]
																		?.path
																: no_image
														}
														onError={(ev) => ev.target.src = no_image}
													></img>
													<br />
												</div>
											);
										}
									} else if(rowInfo.field_name === "details") {
										return (
											<button type="button" onClick={() => setRecord(elementInfo)} className="btn btn-warning btn-sm" data-bs-toggle="modal" data-bs-target="#modalDetail">
												Details
											</button>
										)
									} 
								}}
							/>
						)}
					/>
				</div>
				<div className="product-dataList-mobile-wrapper" style={{ overflow:"hidden"}}>
					<div
					style={{ width:"100%", fontSize:"12.8px", border:"1px solid gray"}}
					>
						{
							stock_receipt.length > 0 && stock_receipt.map((productItem, index)=>(
							<div
							key={index}
							style={{
								display: "block",
								padding: "5px 10px",
								backgroundColor: index % 2 !== 0 ? "#f2f2f2" : "transparent",
							}}
						>
							<div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
								<img 
									src={productItem?.images[0]?.path}
									className="img-product"
								/>	
								<div>
									<strong>Product ID:{productItem?.id}</strong>
									<p>Sku: {productItem?.sku}</p>
									<p>Cost: {productItem?.cost}</p>
									<p>Category: {productItem?.category_id?.name}</p>
									<p>Status: {productItem?.active ? "Active" : "Disable"}</p>
								</div>
								<div
									style={{display:"flex", flexDirection:"column", alignItems:"flex-end", width: "40%"}}
								>
									<strong style={{ textAlign:"right"}}>Product Name:{productItem?.name}</strong>
									<p>Stock: {productItem?.total_loc_quantity}</p>
									<p>Upc: {productItem?.upc}</p>
									<p>Price: {productItem?.price}</p>
									<p>Brand: {productItem?.brand_id?.name}</p>
								</div>
							</div>
							<div
							style={{display:"flex", justifyContent:"flex-end", width:"100%", fontWeight:"bold", color:"#2767c4"}}
							>
								<small
								onClick={() => history.push(`/update_item_form?product_id=${productItem.id}`)}
								style={{borderRight:"1px solid lightgrey", padding:"0 5px"}}
								>Edit</small>

								<small
								onClick={() => onDeleteMobileTable(productItem.id)}
								style={{borderRight:"1px solid lightgrey", padding:"0 5px"}}
								>Delete</small>

								<small onClick={() => handleOptionListToggle(index)} style={{ padding: "0 5px", position:"relative" }}>
									<FaCaretDown size={"20px"} />
									{optionListIndex === index &&(
										<div style={{ right:"0", borderRadius:"6px", padding: "5px 15px", position:"absolute" , zIndex:"10", background:"white", color:"black", boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
										<p style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}><FaLongArrowAltRight size={"18px"} /> View</p>
										<p style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}><FaLongArrowAltRight size={"18px"} /> Copy</p>
										<p style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}><FaLongArrowAltRight size={"18px"} /> Print</p>
										</div>
									)}
								</small>
							</div>
						</div>
							))
						}
						
					</div>
				</div>
				<div className="mobile-pagination-wrapper">
					{stock_receipt.length > 0 && !isLoading ? (
							<PaginationControlled
								itemPagination={itemPagination}
								handlePagination={handlePagination}
							/>
					): <div style={{height:"85px"}}></div>
					}
				</div>
			</div>
		</>
	);
}

export { AddItem };
