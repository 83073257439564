import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, FormGroup, FormLabel, } from 'react-bootstrap';
import { AutoCompleteInputText } from '../AutoCompleteInputText/AutoCompleteInputText';
import MaterialUI from '../MaterialUI';

function ModalAddCustomerTax({show, onHandleClose, title, handleAction, isLoading, resStatus, resMsg, searchSuggestions, dataSuggestions}) {

    const [newCustomerTax, setNewCustomerTax] = useState({
        tax:0.1, 
        customer_tax_email:"",
    });

    const [errors, setErrors] = useState({
        tax: "",
        customer_tax_email: "",
    });

    const validateInputs = () => {
        let isValid = true;
        const newErrors = {};

        // Validar el campo de tax
        if (isNaN(newCustomerTax.tax) || newCustomerTax.tax === "" || parseFloat(newCustomerTax.tax) < 0.1 || parseFloat(newCustomerTax.tax) > 99.9) {
            newErrors.tax = "Tax rate must be a number between 0.1 and 99.9";
            isValid = false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const customer_tax_email_without_space = newCustomerTax.customer_tax_email.trim();
        if (!emailRegex.test(customer_tax_email_without_space)) {
            newErrors.customer_tax_email = "The customer email must be a valid email address.";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e, field) => {
        const { value } = e.target;
        const newErrors = { ...errors }; 

        if (field === "customer_tax_email") {
                delete newErrors.customer_tax_email; 
        }
    
        if (field === "tax") {
            if (isNaN(value) || parseFloat(value) < 0.1 || parseFloat(value) > 99.9) {
                newErrors.tax = "Tax rate must be a number between 0.1 and 99.9";
            } else {
                delete newErrors.tax; 
            }
        }
    
        setErrors(newErrors);
    
        setNewCustomerTax({ ...newCustomerTax, [field]: value });
    };
    

    const handleSubmit = () => {
        if (validateInputs()) {
            handleAction(newCustomerTax);
        }
    };

    useEffect(() => {
        //clear form
        if(show){
            setNewCustomerTax({
                tax:0.1, 
                customer_tax_email:""
            });
            setErrors({});
        }
    },[show])

    //close modal
   useEffect(() => {
        if(!isLoading){
            if(!resStatus && resMsg){
                setErrors({...errors, ["customer_tax_email"]: resMsg.customer_email});
            }else{
                onHandleClose();
            }
        }
    },[isLoading])
 
   
    return (
		<React.Fragment>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={show}
				onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
			>
                <Modal.Header style={{ background: 'var(--login-blue)', color: '#fff' }}>
                    <Modal.Title as="h6" className='fw-bold'>Add Customer Tax Rate</Modal.Title>
                </Modal.Header>
				<Modal.Body>
					<Form autoComplete='off'>
                        <FormGroup className='mb-2'>
                            <Form.Label className='mb-0'>Email</Form.Label>
                            <AutoCompleteInputText 
                             searchSuggestions={searchSuggestions}
                             dataSuggestions={dataSuggestions}
                             maxLength={30}
                             onChange={handleChange}
                             key_name={"customer_tax_email"}
                             value={newCustomerTax.customer_tax_email}
                             type={"email"}
                             isLoading={isLoading}
                            />
                            {errors.customer_tax_email && <Form.Text className='text-danger'>{errors.customer_tax_email}</Form.Text>}
                        </FormGroup>
                        <Form.Group className='mb-3' controlId='tax-rate'>
                            <Form.Label className='mb-0'>Tax Rate %</Form.Label>
                            <Form.Control 
                                max={99.9}
                                value={newCustomerTax.tax}
                                onChange={(e) => handleChange(e, "tax")}
                                type="number"
                            />
                            {errors.tax && <Form.Text className='text-danger'>{errors.tax}</Form.Text>}
                        </Form.Group>
                        <Form.Group className='text-end'>
                            <MaterialUI.Button.Success disabled={isLoading} onClick={handleSubmit} className='me-1'>
                                {isLoading ? "Loading ..." : "Create"}
                            </MaterialUI.Button.Success>
                            <MaterialUI.Button.Secondary onClick={onHandleClose}>
                                Cancel
                            </MaterialUI.Button.Secondary>
                        </Form.Group>
                    </Form>

				</Modal.Body>
			</Modal>
		</React.Fragment>
    );
}

export {ModalAddCustomerTax};