export default class Adapter {
  static responseWarehouseToAutocomplete(item) {
    return {
			id: item.id,
      label: item.name,
		};
  }
  static responseLocationToAutocomplete(item) {
    return {
			id: item.id,
      label: item.name,
      isDefault: item.default_location,
		};
  }
  static responseProductToAutocomplete(item) {
    console.log("🚀 ~ Adapter ~ responseProductToAutocomplete ~ item:", item)
    return {
			id: item.id,
      sku: item.sku,
      name: item.name
		};
  }
  static prepareRequestForDetailList(payload) {
    return {
      id: payload.id,
      productId: payload.item.id,
      productName: payload.item.name,
      productSKU: payload.item.sku,
      quantity: payload.quantity,
      locationId: payload.location.id,
      locationName: payload.location.name,
      invAmount: payload.inv,
      count: payload.adj,
      posted: payload.posted,
    }
  }
  
  static prepareForFormDetailList(form) {
    return {
      id: form.id,
      productId: form.item_id.id,
      productName: form.item_id.name,
      productSKU: form.item_id.sku,
      quantity: form.quantity,
      locationId: form.location_id.id,
      locationName: form.location_id.label,
      invAmount: 0,
      count: 0,
      posted: false,
    }
  }
  static prepareDetailListFromForm(item) {
    return {
      id: item.id,
      item_id: {
        id: item.productId,
        name: item.productName,
        sku: item.productSKU,
      },
      quantity: item.quantity,
      location_id: {
        id: item.locationId,
        label: item.locationName,
      } 
    }
  }
  static prepareDetailListFromProductToAutocomplete(item) {
    return {
      id: item.productId,
      sku: item.productSKU,
      name: item.productName,
    }
  }
  static prepareAdjustmentDetailToStore(item, adjustmentId) {
    return {
      adjustment_id: adjustmentId,
      item_id: item.productId,
      quantity: item.quantity,
      location_id: item.locationId,
    }
  }
}