import createHttp from "../lib/http";

const CashCounterService = {};

CashCounterService.fetchAll = async (params) => {
  const { data } = await createHttp.get("/api/get_cash_counts", { params });
  return data;
}

CashCounterService.create = async (payload) => {
  const { data } = await createHttp.post("/api/create_open_day", payload);
  return data;
}

CashCounterService.findOne = async (cashCounterId) => {
  const { data } = await createHttp.get(`/api/get_cash_count/${cashCounterId}`);
  return data;
}

CashCounterService.lastCreate = async () => {
  const { data } = await createHttp.get("/api/my_last_open_day");
  return data;
}

CashCounterService.lastRecord = async () => {
  const { data } = await createHttp.get("/api/retrieve_my_last_day_records");
  return data;
}

CashCounterService.close = async (payload) => {
  const { data } = await createHttp.post("/api/close_day", payload);
  return data;
}

CashCounterService.update = async (payload) => {
  const { data } = await createHttp.post("/api/update_cash_counter", payload);
  return data;
}

CashCounterService.destroy = async (payload) => {
  const { data } = await createHttp.post("/api/delete_cash_counter", payload);
  return data;
}

export default CashCounterService;