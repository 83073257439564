import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import AlertComponent from '../components/AlertComponent';

import useApiRequestSettingSmsTwilio from "../../../customHooks/useApiRequestSettingSmsTwilio";
import LogoTwilio from "../../../assets/img/logo-twilio.svg";
import MaterialUI from "../../../components/MaterialUI";

const messageError = "This field is required.";

const FormPaypal = () => {
	const {
		removeFromErrorByKey,
    update,
    setTwilio,
    twilio,
    isLoading,
		error,
	} = useApiRequestSettingSmsTwilio();
  const [showAlert, setShowAlert] = React.useState(false);

	const handleClose = (_, reason) => {
		if (reason === 'clickaway') return;
    setShowAlert(false);
	};
	
	const handleSubmit = async (ev) => {
		ev.preventDefault();
		try {
			await update(twilio);
			setShowAlert(true);
		} catch (error) {}
	};

	const handleTypingChange = (key, value) => {
		removeFromErrorByKey(key);
		setTwilio((state) => ({ ...state, [key]: value }));
	};

	if (isLoading) return <CircularProgress />;

	return (
		<div>
			<AlertComponent 
				open={showAlert} 
        handleClose={handleClose} 
        severity="success" 
        message="It has been successfully updated"
			/>
			<div className="text-center">
				<figure className="py-5">
					<img src={LogoTwilio} height={60} alt="Logo Twilio"/>{" "}
				</figure>
			</div>
			<form noValidate autoComplete="off" onSubmit={handleSubmit}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={4}>
						<MaterialUI.Input
							state={twilio.twilio_account_sid}
							setState={(value) => handleTypingChange("twilio_account_sid", value)}
							error={ error.twilio_account_sid && messageError}
							label="Twilio Account SID"
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<MaterialUI.Input
							state={twilio.twilio_auth_token}
							setState={(value) => handleTypingChange("twilio_auth_token", value)}
							error={ error.twilio_auth_token && messageError}
							label="Twilio Auth Token"
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<MaterialUI.Input
							state={twilio.twillio_from}
							setState={(value) => handleTypingChange("twillio_from", value)}
							error={ error.twillio_from && messageError}
							label="Twilio From"
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<MaterialUI.Select 
							options={[
								{ value: 1, label: "Enabled" },
                { value: 0, label: "Disabled" },
							]}
							state={twilio.status}
							setState={(value) => handleTypingChange("status", value)}
							label="Twilio Status"
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<MaterialUI.Button.Success type="submit" fullWidth>
							Save
						</MaterialUI.Button.Success>
					</Grid>
				</Grid>
			</form>
		</div>
	);
};

export default FormPaypal;
