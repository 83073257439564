import React from "react";
import Swal from "sweetalert2";
import SwalAlerts from '../utils/alerts'

import { useTransactions, Adapter as AdapterTransaction, InitState as InitStateTransaction } from "../customHooks/useTransactions";
import { useCashCounter, initState as initStateCashCounter } from '../customHooks/useCashCounter';

export const CashCounterContext = React.createContext();

export function CashCounterContextProvider({ children }) {
	const [loading, setLoading] = React.useState(false);
	const {
		onChangeTransaction,
		storeTransaction,
		updateTransaction,
		fetchTransactions,
		destroyTransaction,
		transactions,
		setTransaction,
		transaction,
		errors,
		setErrors: setErrorsTransactions,
	} = useTransactions();

	const {
		storeCashCounter,
		closeCashCounter,
		cashCounterLastOpen,
		setErrors: setCashCounterErrors,
		onChangeCashCounter,
		counterIsOpen,
		cashCounter,
		errors: cashCounterErrors,
		cashCounterForm,
		setCashCounterForm,
		restoreState: restoreStateCashCounter,
		isLoading: isLoadingCashCounter,
		setIsLoading: setIsLoadingCashCounter,
	} = useCashCounter();

	const cleanFormCashCounter = () => {
		setCashCounterErrors({});
		setCashCounterForm(initStateCashCounter.cashCounterForm)
	}
	
	const editTransaction = (_transaction) => {
		setTransaction(AdapterTransaction.fromRowToForm(_transaction));
	}

	const clearFormTransaction = () => {
		setErrorsTransactions({})
		setTransaction(InitStateTransaction.transaction());
	}
	
	const handleDestroyTransactionClick = async (_transaction) => {
		try {
			const { isConfirmed } = await Swal.fire(SwalAlerts.questionDestroy);
			if(!isConfirmed) return;
			await destroyTransaction(_transaction.id);
			Swal.fire(SwalAlerts.confirmationDestroy);
		} catch (error) {}
	}
	
	const share = {
		onChangeTransaction,
		storeTransaction,
		updateTransaction,
		fetchTransactions,
		editTransaction,
		handleDestroyTransactionClick,
		transactions,
		transaction,
		errors,
		setErrorsTransactions,

		clearFormTransaction,
		restoreStateCashCounter,
		storeCashCounter,
		closeCashCounter,
		setCashCounterErrors,
		onChangeCashCounter,
		cashCounterLastOpen,
		cashCounterForm,
		setCashCounterForm,
		counterIsOpen,
		cashCounter,
		cashCounterErrors,
		cleanFormCashCounter,
		
		loading,
		setLoading,
		
		isLoadingCashCounter,
		setIsLoadingCashCounter,
	};

	return (
		<CashCounterContext.Provider value={share}>
			{children}
		</CashCounterContext.Provider>
	);
}
