import { useState, useEffect, useMemo } from "react";

var _ = require("lodash");

function usePaginationHook(setSincronizedItems, setItemFilters, itemFilters) {
	const [filtersDefinition, setFiltersDefition] = useState([]);
	const [filtersTextDefinition, setFiltersTextDefition] = useState([]);
	const [mixFilter, setMixFilter] = useState([]);

	useEffect(() => {
		let filtersDefinitionCopy = [...filtersDefinition];
		itemFilters.map((item) => {
			const indexItem = filtersDefinitionCopy.findIndex(
				(filter) => filter.field_id === item.field_id,
			);

			if (indexItem != -1) {
				let newFilter = filtersDefinitionCopy[indexItem];
				newFilter.defaultValue = item.value;
			}
		});

		setFiltersDefition(filtersDefinitionCopy);

		// filters text

		let filtersTextDefinitionCopy = [...filtersTextDefinition];
		itemFilters.map((item) => {
			const indexItem = filtersTextDefinitionCopy.findIndex(
				(filter) => filter.field_id === item.field_id,
			);

			if (indexItem != -1) {
				let newFilter = filtersTextDefinitionCopy[indexItem];
				newFilter.value = item.value;
			}
		});

		setFiltersTextDefition(filtersTextDefinitionCopy);

		//mix filters
		let mixFilterCopy = [...mixFilter];
		itemFilters.map((item) => {
			const indexItem = mixFilterCopy.findIndex(
				(filter) => filter.field_id === item.field_id,
			);

			if (indexItem != -1) {
				let newFilter = mixFilterCopy[indexItem];
				newFilter.value = item.value;
				newFilter.defaultValue = item.value;
			}
		});

		setMixFilter(mixFilterCopy);
	}, [itemFilters]);

	// select page of pagination controller
	const handlePagination = (pageNumber) => {
		let copyFilters = delelefilterFromFilters(itemFilters, "page");

		setItemFilters([
			...copyFilters,
			{
				field_id: "page",
				value: pageNumber,
			},
		]);

		setSincronizedItems(false);
	};

	//select PerPage amount Input
	const setPageNumber = async (filter) => {
		let copyFilters = delelefilterFromFilters(itemFilters, filter.field_id);

		copyFilters = delelefilterFromFilters(copyFilters, "page");

		copyFilters = [
			...copyFilters,
			{
				field_id: "page",
				value: 1,
			},
		];

		setItemFilters([...copyFilters, filter]);
		setSincronizedItems(false);
	};

	const onChangeNumberSelect = useMemo(
		() => _.debounce(setPageNumber, 500),
		[itemFilters],
	);

	// selectFilterInput
	const selectOnChange = (filter) => {
		let copyFilters = delelefilterFromFilters(itemFilters, filter.field_id);
		copyFilters = delelefilterFromFilters(copyFilters, "page");

		copyFilters = [
			...copyFilters,
			{
				field_id: "page",
				value: 1,
			},
		];

		if (filter.field_id === "type_transaction") {
			// console.log(itemFilters[2]?.value)
			// console.log("filter.value", filter?.value)
			if(itemFilters[2]?.value === filter?.value && filter?.value !== "" ){
				localStorage.removeItem("type_transaction_value");
				filter.value ="";
			}else{
				localStorage.setItem("type_transaction_value", filter.value);
			}
		}

		setItemFilters([...copyFilters, filter]);
	};

	// search text input
	const eventHandler = async (value) => {
		let copyFilters = delelefilterFromFilters(itemFilters, "search_text");
		copyFilters = delelefilterFromFilters(copyFilters, "page");

		copyFilters = [
			...copyFilters,
			{
				field_id: "page",
				value: 1,
			},
		];

		if (value) {
			setItemFilters([
				...copyFilters,
				{
					field_id: "search_text",
					value: value,
				},
			]);
		} else {
			setItemFilters([...copyFilters]);
		}

		setSincronizedItems(false);
	};

	const filterInputChange = useMemo(
		() => _.debounce(eventHandler, 1000),
		[itemFilters],
	);

	const delelefilterFromFilters = (currentFilters, filterId) => {
		const copyFilters = [...currentFilters];
		const hasFilter = copyFilters.findIndex(
			(filterItem) => filterItem.field_id === filterId,
		);
		if (hasFilter != -1) {
			copyFilters.splice(hasFilter, 1);
		}
		return copyFilters;
	};

	const onChangeDateFilter = (value, field_id) => {
		selectOnChange({
			field_id: field_id,
			value: value,
		});
	};

	const onChangeAsyncFilter = (ev, target) => {
		let copyFilters = delelefilterFromFilters(itemFilters, target);
		copyFilters = delelefilterFromFilters(copyFilters, "page");

		copyFilters = [
			...copyFilters,
			{
				field_id: target,
				value: ev,
			},
		];

		setItemFilters([...copyFilters]);
	};

	const onChangeOrderArrow = (
		dataFieldsToOrderFilter = [],
		tableHeadersToDataFields = [],
		orderField,
		typeOrder,
	) => {
		const orderFieldFilter =
			dataFieldsToOrderFilter[tableHeadersToDataFields[orderField]];

		const filters = [
			{
				field_id: "type_order",
				value: typeOrder,
			},
			{
				field_id: "field_order",
				value: orderFieldFilter,
			},
			{
				field_id: "page",
				value: 1,
			},
		];

		let copyFilters = delelefilterFromFilters(itemFilters, "type_order");
		copyFilters = delelefilterFromFilters(copyFilters, "field_order");
		copyFilters = delelefilterFromFilters(copyFilters, "page");

		copyFilters = [...copyFilters, ...filters];
		setItemFilters([...copyFilters]);
		setSincronizedItems(false);

		// console.log('filters', filters);
	};

	return {
		filterInputChange,
		selectOnChange,
		onChangeNumberSelect,
		handlePagination,
		filtersDefinition,
		setFiltersDefition,
		filtersTextDefinition,
		setFiltersTextDefition,
		onChangeDateFilter,
		mixFilter,
		setMixFilter,
		onChangeAsyncFilter,
		onChangeOrderArrow,
	};
}

export { usePaginationHook };
