import { SweetColors } from "../utils/constants";

const questionDestroy = {
	title: "Are you sure?",
	text: "You won't be able to revert this!",
	icon: "warning",
	showCancelButton: true,
	confirmButtonColor: "#ff6262",
	cancelButtonColor: "#6c757d",
	confirmButtonText: "Yes, delete it!",
};

const questionContinue = (text = "You want to make this sale?") => ({
	title: "Are you sure?",
	icon: "question",
	text,
	showCancelButton: true,
	confirmButtonColor: SweetColors.confirmButtonColor,
	cancelButtonColor: "#6c757d",
	confirmButtonText: "Yes, continue!",
});

const confirmationDestroy = {
	title: "Deleted!",
	text: "Your registration has been deleted.",
	icon: "success",
};

const warning = (text = "Set Text warning") => ({
	icon: "warning",
	title: "Warning",
	text,
});

const success = {
	title: "Success!",
	text: "Successful process",
	icon: "success",
};

export default {
	questionContinue,
	questionDestroy,
	confirmationDestroy,
	warning,
	success,
};
