import createHttp from "../lib/http";

const ExpensiveCategoryService = {};

ExpensiveCategoryService.fetchAll = async (params) => {
  const { data } = await createHttp.get("/api/expense-category", { params });
  return data;
}

ExpensiveCategoryService.store = async (payload) => {
  try {
    const { data } = await createHttp.post("/api/expense-category", payload);
    return data;
  } catch (error) {}
}

export default ExpensiveCategoryService;