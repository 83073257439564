export const typesAndValues = [
	{
		field_name: "id",
		type_field: "text_input",
		text_label: "Id",
		disabled: true,
		regexPattern: /^[A-Za-z0-9]*$/,
		maxLength: 15,
	},
	{
		field_name: "name",
		type_field: "text_input",
		text_label: "Name",
		disabled: false,
		regexPattern: /^[0-9A-Za-z\s\/]+$/,
		maxLength: 50,
		minLength: 1,
	},
];

export const initData = {
	id: "",
	name: "",
};
