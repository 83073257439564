import "./CustomInput.css";

function CustomInput(props) {
	return (
		<>
			<div className="align-items-center" style={{minWidth:"180px", maxWidth:"100%", display:"flex", gap:"5px", marginRight:"10px"}}>
				{ !!props.labelText ? (
					<div style={{width:"40px"}}>
						<label htmlFor={props.inputId} className="col-form-label text-muted">
							{props.labelText}
						</label>
					</div>
				) : null }
				<input
					style={{minWidth:"140px", maxWidth:"100%",}}
					type="text"
					id={props.inputId}
					className="form-control form-control-sm"
					aria-describedby={props.describedByArea}
					maxLength="200"
					onChange={(event) =>
						props.inputOnChange(event.target.value)
					}
					autoComplete="off"
					placeholder={props?.spanText || ""}
				/>
				{/* <div className="col-auto">
              <span id={props.describedByArea} className="form-text">
                { props.spanText }
              </span>
            </div> */}
			</div>
		</>
	);
}

export { CustomInput };
