class Adapter {
  static responseSales(data = {}) {    
    return {
      id: data?.id,
      date: data?.date_receipt,
      type: data?.type,
      client: data?.client?.name,
      dueDate: data?.date_due,
      total_time: data?.total_time,
      status: data?.status,
      balance: data?.Balance,
      total: data?.total,
    };
  }
	static responseStockRequest(data = {}) {    
    return {
      id: data?.id,
			billNumber: data?.bill_number,
			dateDue: data?.date_due,
			dateReceipt: data?.date_receipt,
			description: data?.description,
			status: data?.status,
			type: data?.type,
			total: data?.total,
    };
  }
}

export default Adapter;