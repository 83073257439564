export const typesAndValues = [
	{
		field_name: "id",
		type_field: "text_input",
		text_label: "Id",
		disabled: true,
		regexPattern: /^[A-Za-z0-9]*$/,
		maxLength: 15,
		col: "col-12",
	},
	{
		field_name: "name",
		type_field: "text_input",
		text_label: "Name",
		disabled: false,
		regexPattern: /^[A-Za-z0-9."', ]*$/,
		maxLength: 100,
		minLength: 3,
		col: "col-12",
		autoFocus: true,
		required: true,
	},
	{
		field_name: "address_1",
		type_field: "text_input",
		text_label: "Address",
		disabled: false,
		regexPattern: /^[#.0-9a-zA-Z\s,-,',",@,;]+$/,
		maxLength: 100,
		// minLength: 3,
		col: "col-12",
		required: true,
	},
	{
		field_name: "address_2",
		type_field: "text_input",
		text_label: "Address 2",
		disabled: false,
		regexPattern: /^$|^[#.0-9a-zA-Z\s,-,',",@,;]+$/,
		maxLength: 100,
		col: "col-12",
		// dotShowLabel: true,
		// labelText: "< Address 2>",
		// dontSeeLabel: false,
	},
	{
		field_name: "city",
		type_field: "text_input",
		text_label: "City",
		disabled: false,
		regexPattern: /^[a-zA-Z\s]*$/,
		maxLength: 100,
		minLength: 3,
		col: "col-12 col-md-4 col-xl-3",
		required: true,
	},
	{
		field_name: "state",
		type_field: "text_input",
		text_label: "State",
		disabled: false,
		regexPattern: /^[a-zA-Z\s]*$/,
		maxLength: 4,
		minLength: 2,
		col: "col-12 col-md-4 col-xl-3",
		required: true,
	},
	{
		field_name: "postal_code",
		type_field: "text_input",
		text_label: "Zip Code",
		disabled: false,
		regexPattern: /^[0-9]*$/,
		maxLength: 5,
		minLength: 4,
		col: "col-12 col-md-4 col-xl-3",
		required: true,
	},
	{
		field_name: "country",
		type_field: "text_input",
		text_label: "Country",
		disabled: false,
		regexPattern: /^$|^[a-zA-Z\s]*$/,
		maxLength: 30,
		col: "col-12 col-md-4 col-xl-3",
	},
	{
		field_name: "business_phone",
		type_field: "text_input",
		text_label: "Phone Number",
		disabled: false,
		regexPattern: /^$|^[0-9,-]*$/,
		maxLength: 20,
		col: "col-12 col-md-4 col-xl-3",
	},
	{
		field_name: "business_phone_ext",
		type_field: "text_input",
		text_label: "Ext",
		disabled: false,
		regexPattern: /^$|^[0-9]*$/,
		maxLength: 5,
		col: "col-12 col-md-4 col-xl-3",
	},
	{
		field_name: "fax_phone",
		type_field: "text_input",
		text_label: "Fax",
		disabled: false,
		regexPattern: /^$|^[0-9]*$/,
		maxLength: 20,
		col: "col-12 col-md-4 col-xl-3",
	},
	{
		field_name: "language",
		type_field: "select_input",
		text_label: "Lenguage",
		placeHolder: "select a lenguage",
		dontDefaultOption: false,
		regexPattern: /^$|^[a-z0-9]+$/i,
		values: [
			{ id: "E", name: "English" },
			{ id: "S", name: "Spanish" },
		],
		selectCol: "col-12",
		selectWidth: "100%",
		// minLength: 1,
		col: "col-12 col-md-4 col-xl-3",
	},
	{
		field_name: "email",
		type_field: "text_input",
		text_label: "Email",
		disabled: false,
		regexPattern: /^$|[a-z0-9]+@[a-z0-9]+\.[a-z]{2,3}/,
		maxLength: 128,
		col: "col-12 col-md-4 col-xl-3",
	},
	{
		field_name: "website",
		type_field: "text_input",
		text_label: "Website",
		disabled: false,
		regexPattern:
			/^$|^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
		maxLength: 128,
		col: "col-12 col-md-4 col-xl-3",
	},
	{
		field_name: "contact",
		type_field: "text_input",
		text_label: "Contact",
		disabled: false,
		regexPattern: /^$|^[A-Za-z0-9.@'"; ]*$/,
		maxLength: 100,
		col: "col-12 col-md-4 col-xl-3",
	},
];

export const initData = {
	id: "",
	name: "",
	address_1: "",
	address2: "",
	city: "",
	state: "",
	postal_code: "",
	country: "United States",
	business_phone: "",
	business_phone_ext: "",
	ext: "",
	email: "",
	website: "",
	contact: "",
	language: "E",
	fax_phone: "",
	address_2: "",
};

export const typesAndValuesCreditInfo = [
	{
		field_name: "credit_status",
		type_field: "select_input",
		text_label: "Type Credit",
		placeHolder: "select a type credit",
		dontDefaultOption: false,
		regexPattern: /^$|^[a-z0-9]+$/i,
		values: [
			{ id: "CREDIT", name: "CREDIT" },
			{ id: "COLLECTION", name: "COLLECTION" },
			{ id: "DECLINED", name: "DECLINED" },
			{ id: "HOLD", name: "HOLD" },
			{ id: "NOCREDIT", name: "NOCREDIT" },
		],
		selectCol: "col-12",
		selectWidth: "100%",
		col: "col-12 col-md-4 col-xl-4",
	},
	{
		field_name: "credit_lmit",
		type_field: "text_input",
		text_label: "Credit Limit",
		disabled: false,
		regexPattern: /^$|^[0-9.]*$/,
		maxLength: 10,
		col: "col-12 col-md-4 col-xl-2",
		type: "number",
	},
	{
		field_name: "pay_terms",
		type_field: "text_input",
		text_label: "Term Description",
		disabled: false,
		regexPattern: /^$|^[A-Za-z0-9. ]*$/,
		maxLength: 20,
		col: "col-12 col-md-4 col-xl-4",
	},
	{
		field_name: "pay_days",
		type_field: "text_input",
		text_label: "Due Days",
		disabled: false,
		regexPattern: /^$|^[0-9.]*$/,
		maxLength: 10,
		type: "number",
		col: "col-12 col-md-4 col-xl-2",
	},
	{
		field_name: "comments",
		type_field: "textArea",
		text_label: "Notes",
		rows: "2",
		cols: "50",
		regexPattern: /^$|^[A-Za-z0-9."',@; ]*$/,
		col: "col-12",
		maxLength: 150,
	},
	{
		field_name: "test",
		type_field: "checkBoxGroup",
		text_label: "",
		col: "col-12",
		checkBoxValues: {
			is_active: true,
			is_rm_customer: false,
		},
		mapChechBoxNames: {
			is_active: "Active",
			is_rm_customer: "Allow WO",
		},
	},
];

export const initDataCreditInfo = {
	credit_status: "NOCREDIT",
	credit_lmit: "",
	pay_terms: "",
	pay_days: "0",
	is_active: true,
	is_rm_customer: false,
	comments: "",
};

export const buttonValues = [
	{
		field_name: "addNewItem",
		type_field: "button",
		text_label: "Add New Item",
		// type: 'success',
		type: "-",
		icon: "fas fa-plus",
		margins: "mt-0 mb-0",
		disabled: false,
		className: "form-control add-button",
		dotShowLabel: true,
		onclickHandler: () => {},
	},
];
