import { createContext } from "react";
import { useApiRequestWareHouse } from "../customHooks/useApiRequestWareHouse";
import { useApiRequestVendor } from "../customHooks/useApiRequestVendor";
import { useApiRequestTypePayment } from "../customHooks/useApiRequestTypePayment";
import { useApiRequestExpensive } from "../customHooks/useApiRequestExpensive";
import { useApiCustomRequest } from "../customHooks/useApiCustomRequest";

export const ExpensiveContext = createContext();

export function ExpensiveContextProvider({ children }) {
	const handler = (...params) => console.warn(...params);
	const { warehouse: warehouses, fetchWarehouses, setWareHouse: setWarehouses } = useApiRequestWareHouse(handler, false);
	const { filtered_data: vendors, fetchVendors, setFilteredData: setVendors } = useApiRequestVendor(handler, false);
	const { filtered_data: payments, fetchTypesPayment, setFilteredData: setPayments } = useApiRequestTypePayment(handler, false);
	const { fetchExpenseCategories, setExpensiveCategory: setExpenseCategories, expensiveCategory: expenseCategories } = useApiRequestExpensive(handler, false, false);
	const { filtered_data: paymentAccounts, setFilteredData: setPaymentAccounts, fetchResource: fetchPaymentAccounts } = useApiCustomRequest(handler, "payment-account", "Payment Account", handler, false, true);

	const share = {
		fetchWarehouses,
		setWarehouses,
		warehouses,

		fetchVendors,
		setVendors,
		vendors,

		fetchTypesPayment,
		setPayments,
		payments,

		fetchExpenseCategories,
		setExpenseCategories,
		expenseCategories,

		fetchPaymentAccounts,
		setPaymentAccounts,
		paymentAccounts,
	};

	return (
		<ExpensiveContext.Provider value={share}>
			{children}
		</ExpensiveContext.Provider>
	);
}
