import { useEffect } from "react";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';

import { useHistory } from "react-router-dom";

import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import { CustomForm } from "../../components/CustomForm/CustomForm";
import MenuItem from "@material-ui/core/MenuItem";
import no_image from "../../assets/img/no_image.png";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomInput } from "../../components/CustomInput/CustomInput";

//CustomHooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useMenuActionHook } from "../../customHooks/useMenuActionHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";

import { styles } from "../../context/constants";
import MaterialUI from "../MaterialUI";

function ListComponent(props) {
	const history = useHistory();

	//dataList Implementation
	const { anchorEl, setAnchorEl, onCloseModal, currentId, setCurrentId } =
		useMenuActionHook();

	const {
		showModal,
		setShowModal,
		customTittleText,
		setCustomTittleText,
		customMessageModal,
		showSuccessButton,
		manageResponseErrors,
		manageDeleteMessage,
		showCustomFormModal,
		setShowCustomFormModal,
		manageResponse,
		cancelButtonText,
		sucessButtonText,
		setCustomMessageModal,
	} = useModalHook();

	const {
		isLoading,
		filtered_data,
		setFilteredData,
		setSincronizedItems,
		deleteItem: deleteModel,
		updateItem,
		createItem,
	} = useApiCustomRequest(
		manageResponseErrors,
		props.apiUrl,
		props.model,
		manageResponse,
	);

	useEffect(() => {
		setSincronizedItems(false);
	}, [props.reload]);

	const deleteItem = async () => {
		setShowModal(false);
		return await deleteModel(currentId);
	};

	const onMenuActions = async (event, id) => {
		console.log({ id });
		setCurrentId(id);
		setAnchorEl(event.currentTarget);
	};

	const onDelete = (event) => {
		setAnchorEl(null);
		manageDeleteMessage(props.model);
		setCustomMessageModal(
			`Are you sure you want to delete item #${currentId}?`,
		);
		setShowModal(true);
	};

	const onEdit = () => {
		setCustomTittleText(`Edit ${props.model}`);
		setAnchorEl(null);
		setShowCustomFormModal(true);
	};

	const actionsDefinition = [
		{
			name: "Edit",
			func: onEdit,
			icon: faEdit,
		},
		{
			name: "Delete",
			func: onDelete,
			icon: faTrashAlt,
		},
	];

	const onCreateCategory = () => {
		setCustomTittleText(`Create ${props.model}`);
		setAnchorEl(null);
		setShowCustomFormModal(true);
		setCurrentId(null);
	};

	const mapRequest = (bodyInclude, bodyExt) => {
		let bodyRequest = {};
		Object.keys(bodyExt).map((key) => {
			if (bodyInclude.includes(key)) {
				let value = bodyExt[key];
				if(props.convertBooleanValues && value === 'true' || value === 'false') {
					bodyRequest[key] = JSON.parse(bodyExt[key]);
				} else {
					bodyRequest[key] = bodyExt[key];
				}
			}
		});
		return bodyRequest;
	};

	const onHandleSubmit = async (event) => {
		setShowCustomFormModal(false);
		const { id, ...bodyExt } = event;

		let mapBodyRequest = mapRequest(props.bodyInclude, bodyExt);

		if (currentId) {
			return await updateItem(id, mapBodyRequest);
		} else {
			return await createItem(mapBodyRequest);
		}
	};

	
	const filterInputChange = (ev) => {
		const value = ev;
		const key = props.keyToFind || "name";

		if (!value) {
			setSincronizedItems(false);
		}

		const updatedList = filtered_data.filter((item) => {
			return item[key].toLowerCase().search(value.toLowerCase()) !== -1;
		});

		setFilteredData([...updatedList]);
	};

	return (
		<>
			<div className="container-fluid contenedor">
				<CustomModal
					show={showModal}
					customTittleText={customTittleText}
					customMessageModal={customMessageModal}
					onHandleClose={() => setShowModal(false)}
					onHandleSuccess={deleteItem}
					isLoading={isLoading}
					showSuccessButton={showSuccessButton}
					actionsFooterStyle={true}
					cancelText={cancelButtonText}
					successText={sucessButtonText}
				/>

				<CustomModal
					show={showCustomFormModal}
					customTittleText={customTittleText}
					customMessageModal={customMessageModal}
					onHandleClose={() => setShowCustomFormModal(false)}
					onHandleSuccess={() => {}}
					isLoading={isLoading}
					showSuccessButton={showSuccessButton}
				>
					<CustomForm
						onHandleFormClose={() => setShowCustomFormModal(false)}
						location_list={[]}
						onHandleSubmit={onHandleSubmit}
						typesAndValuesFields={props.typesAndValues}
						initData={props.initData}
						formTittle={""}
						id={(currentId && currentId.toString()) || null}
						getUrlPath={`/api/${props.apiUrl}/`}
						successStyleButtonText={<><SaveOutlinedIcon fontSize="small" className="me-1" /> Save</>}
						typeButton={"success"}
					/>
				</CustomModal>

				<div className="container-title mb-3">
					<h1 className="container__title mb-0">{props.model}</h1>
				</div>
				<div
					className={` 
                        ${
							!!props.showSimpleInputTextFilter &&
							"d-flex justify-content-between"
						}
                        ${!props.showSimpleInputTextFilter && "row"}
                    `}
				>
					{!!props.showSimpleInputTextFilter && (
						<CustomInput
							inputId={"inputPassword6"}
							describedByArea={"passwordHelpInline"}
							spanText={"Enter a letter to filter in items"}
							labelText={"Filter"}
							inputOnChange={filterInputChange}
						/>
					)}
					{/* <div className='col-12'> */}
					<div
						className={`${!!props.showSimpleInputTextFilter && ""} ${!props.showSimpleInputTextFilter && "col-12"}`}
					>
						<MaterialUI.Button.Primary size="small" onClick={onCreateCategory}>
							<AddTwoToneIcon fontSize="small" className="me-1"/>Add
						</MaterialUI.Button.Primary>
					</div>
				</div>

				<CustomDataList
					onChangePageEvent={() => {}}
					style={styles}
					isLoading={isLoading}
					dataList={filtered_data}
					tableHeaders={props.tableHeaders}
					itemPagination={null}
					handlePagination={() => {}}
					dontShowPerPageSelect={true}
					showLinearIndicator={true}
					showDataInLoading={true}
					render={(element, index) => (
						<CustomDataListItem
							key={index}
							dataFields={props.dataFields}
							imageFields={[]}
							composedFields={[]}
							arrayFields={[]}
							element={element}
							index={index}
							no_image={no_image}
							renderActions={() => (
								<>
									<PositionedMenu
										title={"Actions"}
										anchorEl={anchorEl}
										setAnchorEl={setAnchorEl}
										handleClose={onCloseModal}
										handleClick={(event) =>
											onMenuActions(event, element.id)
										}
										actionsDefinition={
											actionsDefinition
										}
										renderActions={(action, index) => (
											<MenuItem
												sx={{
													boxShadow: "none",
												}}
												key={index}
												onClick={() =>
													action.func()
												}
											>
												<div
													className="items-option"
													style={{
														padding: "0 5px",
														color: "grey",
													}}
												>
													<FontAwesomeIcon
														style={{
															fontSize: '.8rem',
															margin: "4px",
															marginRight: "10px"
														}}
														className={
															action.name == "Edit" 
																? "text-primary"
																: action.name == "Delete"
																	? 'text-danger'
																	: 'inherit'
														}
														icon={action.icon}
													/>
													<p className="mb-0 fs-small">{action.name}</p>
												</div>
											</MenuItem>
										)}
									></PositionedMenu>
								</>
							)}
						/>
					)}
				/>
			</div>
		</>
	);
}

export { ListComponent };
