import React, { useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, makeStyles } from '@material-ui/core';
import { RemoveRounded, AddRounded, DeleteRounded } from '@material-ui/icons';
import { FaDropbox } from "react-icons/fa";
import clsx from 'clsx'

import { PosDesktopContext } from "../../context/PosDesktopContext";

import MaterialUI from '../../components/MaterialUI';

import NoImage from '../../assets/img/no-image.jpg';

const useStyles = makeStyles({
  table: {
		padding: 0,
    minWidth: 400,
  },
	container: {
		maxHeight: '58vh',
		height: '58vh',
	}
});

const PosDesktopCart = () => {
	const classes = useStyles();
  const { addQuantityToTheProductInTheShoppingCart, removeFromCart, cart, totalQuantity, totalAmount } = useContext(PosDesktopContext);
  
  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader className={clsx(classes.table, !totalQuantity && 'h-100' )} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell className="fs-small" align="left">Product</TableCell>
            <TableCell className="fs-small" align="center" style={{ width: '80px', paddingInline: '.75rem' }}>Unit. P.</TableCell>
            <TableCell className="fs-small" align="center" style={{ inlineSize: '80px' }}>Quantity</TableCell>
            <TableCell className="fs-small" align="center" style={{ width: '80px', paddingInline: '.75rem' }}>SubTotal</TableCell>
            <TableCell className="fs-small cart__column__remove" align="center"><DeleteRounded fontSize="small"/></TableCell>
          </TableRow>
        </TableHead>
        {cart.length ? (<>
          <TableBody className='no-bordered'>
            {cart.map((item) => (
              <TableRow key={item.id}>
                <TableCell className="px-1 fs-small" align="left" component="th" scope="row">
                  <div className='d-flex gap-2' style={{ minBlockSize: '50px', alignItems: 'center' }}>
                    <figure className='table__product__image'>
                      <img src={item.images.length ? item.images[0]['path'] : NoImage} alt={item.name} onError={(ev) => ev.target.src = NoImage} />
                    </figure>
                    <div className='w-100'>
                      <div>
                        <span className="fs-small text-capitalize">{String(item.name).toLowerCase()}</span>
                      </div>
                      <div className='d-flex flex-column flex-wrap' style={{ lineHeight: '1.1' }}>
                        <div className="card__pos__product__property text-uppercase">Sku: {item?.sku}</div>
                        {!!item.brand_id?.name && <span className="card__pos__product__property text-uppercase">Brand: {item.brand_id.name}</span>}
                        {!!item.category_id?.name && <span className="card__pos__product__property text-uppercase">Cat.: {item.category_id.name}</span>}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className="px-1 fs-small" align="center" component="th" scope="row">
                  <span className="fs-small text-capitalize">{Number(item.price).toFixed(2)}</span>
                </TableCell>
                <TableCell className="px-1 fs-small" align="center">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <MaterialUI.Button.Dark size="small" onClick={() => addQuantityToTheProductInTheShoppingCart(item.id, -1)} className="mw-auto p-1">
                      <RemoveRounded style={{ fontSize: '1rem' }} />
                    </MaterialUI.Button.Dark>
                    <span className="fs-small cart__counter">{item.quantity}</span>
                    <MaterialUI.Button.Dark size="small" onClick={() => addQuantityToTheProductInTheShoppingCart(item.id, 1)} className="mw-auto p-1">
                      <AddRounded style={{ fontSize: '1rem' }} />
                    </MaterialUI.Button.Dark>
                  </div>
                </TableCell>
                <TableCell className="px-1 fs-small" align="center">
                  <span className="fs-small">{Number(item.subTotal).toFixed(2)}</span>
                </TableCell>
                <TableCell align="center" className='cart__column__remove'>
                  <MaterialUI.Button.Danger size="small" className="mw-auto" onClick={() => removeFromCart(item.id)}>
                    <DeleteRounded fontSize="small"/>
                  </MaterialUI.Button.Danger>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter style={{ position: 'sticky', bottom: 0 }}>
            <TableRow>
              <TableCell colSpan={2} className='table__text_footer'>General information</TableCell>
              <TableCell className="fs-xs px-1 table__text_footer font-inter fw-bold" align="center">{totalQuantity}</TableCell>
              <TableCell className="fs-xs px-1 table__text_footer font-inter fw-bold" align="center">{Number(totalAmount).toFixed(2)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableFooter>
        </>) : (<>
          <TableBody>
            <TableRow>
              <TableCell colSpan={5} className='text-center text-muted py-5'>
                <FaDropbox size={80} className='mb-2' />
                <div className="text-center font-inter">
                  <span className="fs-small">Cart is empty</span>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </>)}
      </Table>
    </TableContainer>
  )
};

export default PosDesktopCart;