import React from "react";
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import { FaCashRegister, FaCog } from "react-icons/fa";
import { EditOutlined, DeleteOutlined } from '@material-ui/icons';
import Swal from 'sweetalert2'
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Pagination } from "@material-ui/lab";
import { Can } from '../../context/PermissionContext';

import SwalAlerts from '../../utils/alerts';
import { useCashCounter } from '../../customHooks/useCashCounter';

import MaterialUI from "../../components/MaterialUI";

const useStyles = makeStyles({
	table: {
		padding: 0,
		minWidth: 650,
	},
	paginationContainer: {
		'& .MuiPaginationItem-page.Mui-selected': {
      color: 'rgb(13 110 253)',
			backgroundColor: 'rgb(13 110 253 / 20%)',
			borderColor: 'rgb(13 110 253)'
    },
		'& .MuiPaginationItem-page': {
      borderRadius: '50%'
    },
	}
});

const CashCounterList = () => {
	const classes = useStyles();
	const history = useHistory();
	const {
		fetchAllCashCounter,
		setPage,
		destroyCashCounter,
		cashCounterLastOpen,
		counterIsOpen,
		cashCounters,
		pagination,
		page,
	} = useCashCounter();
	const [currentCashCounterId, setCurrentCashCounterId] = React.useState(undefined);
	const [params, setParams] = React.useState({ start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD') });
	const handleChange = (key) => (value) => setParams((prev) => ({ ...prev, [key]: value }));
	
	React.useEffect(() => {
		fetchAllCashCounter(params);
	}, [params, page]);

	const handleDestroyClick = async (record) => {
		if(currentCashCounterId === record.id) return;
		const { isConfirmed } = await Swal.fire(SwalAlerts.questionDestroy);
		if(!isConfirmed) return;
		await destroyCashCounter(record.id);
		fetchAllCashCounter(params);
		Swal.fire(SwalAlerts.confirmationDestroy);
	}
	
	React.useEffect(() =>{
		cashCounterLastOpen()
			.then((resp) => {
				if(resp) setCurrentCashCounterId(resp.id);
			})
	}, []);
	
	const handleRedirectClick = (cashCounterId, directRedirect = true) => {
		if(directRedirect) {
			history.push(`/cash-counter/${cashCounterId}`);
		}  else {
			history.push('/cash-counter');
		}
	}
	
	return (
		<Grid container spacing={1} justifyContent="center">
			<Grid item xs={12}>
				<Box className="d-flex align-items-center gap-2 text-muted mb-2">
					<FaCashRegister style={{ fontSize: '1.35rem' }} />
					<h5 className="container__title mb-0">Cash Counters</h5>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={1}>
					<Grid item xs={12} md={4} lg={3} xl={2}>
						<MaterialUI.Input
							type="date"
							label="Date Start"
							InputLabelProps={{ shrink: true }}
							state={params.start_date}
							setState={handleChange('start_date')}
						/>
					</Grid>
					<Grid item xs={12} md={4} lg={3} xl={2}>
						<MaterialUI.Input 
							type="date"
							label="Date End"
							InputLabelProps={{ shrink: true }}
							state={params.end_date}
							setState={handleChange('end_date')}
						/>
					</Grid>
					<Grid item xs={12} md={2} className="ms-auto">
						<MaterialUI.Button.Primary className="w-100 fs-small" onClick={() => handleRedirectClick(currentCashCounterId, false)}>
							{!counterIsOpen && <i className="fas fa-plus me-2"></i>}{!!counterIsOpen ? 'View' : 'Open'} cash counter
						</MaterialUI.Button.Primary>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Paper>
					<TableContainer>
						<Table className={classes.table} size="small">
							<TableHead>
								<TableRow>
									<TableCell className="px-1 fs-small" align="center" scope="row" style={{ inlineSize: '100px', minInlineSize: '100px' }}><FaCog fontSize={16} className="me-2" />Actions</TableCell>
									<TableCell className="px-1 fs-small" align="center" scope="row" style={{ inlineSize: '150px' }}>User</TableCell>
									<TableCell className="px-1 fs-small" align="center" scope="row" style={{ inlineSize: '120px', minInlineSize: '120px'}}>Opening Amount</TableCell>
									<TableCell className="px-1 fs-small" scope="row" style={{ minInlineSize: '120px' }}>Opening Observation</TableCell>
									<TableCell className="px-1 fs-small" align="center" scope="row" style={{ inlineSize: '150px', minInlineSize: '120px'}}>Closing Amount</TableCell>
									<TableCell className="px-1 fs-small" scope="row" style={{ minInlineSize: '120px' }}>Closing Observation</TableCell>
									<TableCell className="px-1 fs-small" align="center" scope="row" style={{ inlineSize: '150px', minInlineSize: '150px' }}>Open</TableCell>
									<TableCell className="px-1 fs-small" align="center" scope="row" style={{ inlineSize: '150px', minInlineSize: '150px' }}>Close</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!!cashCounters.length && cashCounters.map((record, index) => (
									<TableRow key={index}>
										<TableCell className="px-1 fs-small" align="center" scope="row">
											<Can >
												<MaterialUI.Button.Primary tooltip="Edit" size="small" className="mw-auto me-1" onClick={() => handleRedirectClick(record.id, !(record.id === currentCashCounterId))}>
													<EditOutlined fontSize="small" />
												</MaterialUI.Button.Primary>
											</Can>
											<Can>
												<MaterialUI.Button.Danger tooltip="Delete" disabled={record.id === currentCashCounterId} size="small" className="mw-auto" onClick={() => handleDestroyClick(record)}>
													<DeleteOutlined fontSize="small" />
												</MaterialUI.Button.Danger>
											</Can>
										</TableCell>
										<TableCell className="px-1 fs-small" align="center" scope="row">{record?.opening_user?.name ?? '-'}</TableCell>
										<TableCell className="px-1 fs-small" align="center" scope="row">$ {record.opening_amount}</TableCell>
										<TableCell className="px-1 fs-small" scope="row">{record.opening_note}</TableCell>
										<TableCell className="px-1 fs-small" align="center" scope="row">$ {record.close_amount}</TableCell>
										<TableCell className="px-1 fs-small" scope="row">{record.close_note ?? '-'}</TableCell>
										<TableCell className="px-1 fs-small" align="center" scope="row">{record.date_open}</TableCell>
										<TableCell className="px-1 fs-small" align="center" scope="row">{record.date_close ?? '-'}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Grid>
			{((pagination.total_pages || 1) > 1) && (
				<Grid item xs={12}>
					<div className={classes.paginationContainer}>
						<Pagination count={pagination.total_pages} page={page} onChange={(_, value) => setPage(value)} variant="outlined" shape="rounded" color="primary" />
					</div>
				</Grid>
			)}
		</Grid>
	);
};

export default CashCounterList;
