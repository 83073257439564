import * as React from "react";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const theme = createTheme({
	overrides: {
		MuiFormGroup: {
			root: {
				display: "flex",
				flexWrap: "wrap",
				flexDirection: "row",
			},
		},
		MuiCheckbox: {
			colorSecondary: {
				Mui: {
					checked: "blue",
				},
			},
		},
		MuiIconButton: {
			colorSecondary: {
				color: "blue",
			},
		},
	},
	breakpoints: {
    values: {
			xs: 0,
      sm: 640,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const GreenCheckbox = withStyles({
	root: {
		color: "#0D6EFD",
		"&$checked": {
			color: "#0D6EFD",
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

function CheckboxesGroup(props) {
	let initInnerState = {};
	Object.keys(props.formValue.checkBoxValues).map((key) => {
		initInnerState[key] = props.initState[key];
	});

	const [state, setState] = React.useState({ ...initInnerState });

	React.useEffect(() => {
		let initInnerState = {};
		Object.keys(props.formValue.checkBoxValues).map((key) => {
			initInnerState[key] = props.initState[key];
		});
		setState({ ...initInnerState });
	}, [props]);

	const handleChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.checked,
		});

		props.onChangeEvent({
			...state,
			[event.target.name]: event.target.checked,
		});
	};
	
	return (
		<Box>
			{/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
			<FormControl component="fieldset" variant="standard">
				<ThemeProvider theme={theme}>
					<FormGroup>
						{Object.keys(props.formValue.checkBoxValues).map(
							(key, index) => (
								<FormControlLabel
									key={index}
									control={
										<GreenCheckbox
											checked={state[key]}
											onChange={handleChange}
											name={key.toString()}
											
										/>
									}
									label={
										props.formValue.mapChechBoxNames[key] ?
										<span style={{ fontSize: '.9rem'}}>
											{props.formValue.mapChechBoxNames[key]}
										</span> : null
									}
								/>
							),
						)}
					</FormGroup>
				</ThemeProvider>
			</FormControl>
			{/* <FormHelperText>Be careful</FormHelperText> */}
		</Box>
	);
}

export { CheckboxesGroup };
