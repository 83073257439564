import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import "./user_profile.css";
import photo from "../../assets/img/photo.jpg";
import createHttp from "../../lib/http";
import {
	userClientHTTP,
	companyClientHTTP,
} from "../../lib/tech4Inventory.client";

function UserProfile() {
	const style = {
		background: "transparent",
	};

	let company_first_state = {
		website: "",
		address: "",
		city: "",
		state: "",
		zipcode: "",
		tax: "",
	};

	let profile_first_state = {
		name: "",
		email: "",
		phone_number: "",
		company: company_first_state,
	};

	const [profile, setProfile] = useState([profile_first_state]);
	const [in_response, setInResponse] = useState(false);
	const [preview_image, setImagePreview] = React.useState(photo);
	const [file_image, setFileImage] = useState(null);

	const setNewStatus = (e, field, company, type) => {
		let get_data = [...profile];

		console.log();

		switch (type) {
			case "number":
				replaceNumber(e);
				break;

			default:
				break;
		}

		if (company) {
			get_data[0].company[field] = e.target.value;
		} else {
			get_data[0][field] = e.target.value;
		}

		setProfile(get_data);
	};

	/*FUNCIONES */

	function replaceNumber(event) {
		let new_value = event.target.value;
		event.target.value = new_value.replace(/[^0-9]/g, "");
		return event;
	}

	/*FUNCIONES */

	const previewImage = (e) => {
		if (e.target.files.length > 0) {
			let preview = URL.createObjectURL(e.target.files[0]);
			setImagePreview(preview);
			setFileImage(e.target.files[0]);
		} else {
			setFileImage(null);
			setImagePreview(photo);
		}
	};

	const sendInfoDataUpdate = () => {
		if (in_response) {
			return;
		}

		setInResponse(true);

		userClientHTTP
			.updateUser({ name: profile[0].name || "" })
			.then(() => {})
			.catch(() => {});

		let get_company_id =
			profile[0].company.id !== undefined ? profile[0].company.id : false;

		if (!get_company_id) {
			setInResponse(false);
			return;
		}

		let company_data = new FormData();

		company_data.append("website", profile[0].company.website);
		company_data.append("address", profile[0].company.address);
		company_data.append("city", profile[0].company.city);
		company_data.append("state", profile[0].company.state);
		company_data.append("zipcode", profile[0].company.zipcode);
		company_data.append("tax", profile[0].company.tax);
		company_data.append("contact_phone", profile[0].company.contact_phone);
		company_data.append("_method", "PUT");

		if (file_image) {
			company_data.append("logo", file_image);
		}

		companyClientHTTP
			.updateCompany(company_data, get_company_id)
			.then(() => {
				setInResponse(false);
			})
			.catch(() => {
				setInResponse(false);
			});
	};

	useEffect(() => {
		createHttp
			.get("api/my-user")
			.then((response) => {
				const logo = response.data.data.company.logo;
				setImagePreview(logo);
				setProfile([response.data.data]);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<Layout>
			<div className="content-profile">
				<div className="content-profile__title">
					<h1 className="profile__title">Profile</h1>
				</div>
				<form className="content-form mt-3">
					<div className="content-inputs__profile">
						<div className="row">
							<h5 style={{ color: "grey" }} className="mb-4">
								Change password
							</h5>
							<div className="input-group mb-3">
								<span
									className="input-group-text"
									style={style}
								>
									<i className="fas fa-lock"></i>
								</span>
								<input
									type="text"
									className="form-control"
									placeholder="Current password"
								/>
							</div>
							<div className="input-group mb-3">
								<span
									className="input-group-text"
									style={style}
								>
									<i className="fas fa-lock"></i>
								</span>
								<input
									type="text"
									className="form-control"
									placeholder="New password"
								/>
							</div>
							<div className="input-group mb-3">
								<span
									className="input-group-text"
									style={style}
								>
									<i className="fas fa-lock"></i>
								</span>
								<input
									type="text"
									className="form-control"
									placeholder="Confirm new password"
								/>
							</div>
							<div className="d-flex flex-row-reverse bd-highlight">
								<button className="btn btn-primary">
									Change password
								</button>
							</div>
						</div>
						<div className="row">
							<div className="mt-2">
								<h5 style={{ color: "grey" }}>Edit profile</h5>
							</div>
							<div className="content-input__profile col-12 col-xl-4 mb-3">
								<label className="form-label">Name</label>
								<input
									onChange={(e) =>
										setNewStatus(e, "name", false, "text")
									}
									value={
										profile[0].name !== null
											? profile[0].name
											: ""
									}
									type="text"
									className="form-control input-profile"
								/>
							</div>
							<div className="content-input__profile col-12 col-xl-4 mb-3">
								<label className="form-label">Email</label>
								<input
									value={
										profile[0].email !== null
											? profile[0].email
											: ""
									}
									type="text"
									className="form-control input-profile"
									disabled
								/>
							</div>
							<div className="content-input__profile col-12 col-xl-4 mb-3">
								<label className="form-label">Website</label>
								<input
									type="text"
									value={
										profile[0].company.website !== null
											? profile[0].company.website
											: ""
									}
									className="form-control input-profile"
									onChange={(e) =>
										setNewStatus(e, "website", true, "text")
									}
								/>
							</div>
						</div>
						<div className="row">
							<div className="content-input__profile col-12 col-xl-6 col-sm-6 col-md-6 mb-3">
								<label className="form-label">Address</label>
								<input
									type="text"
									className="form-control input-profile"
									onChange={(e) =>
										setNewStatus(e, "address", true, "text")
									}
									value={
										profile[0].company.address !== null
											? profile[0].company.address
											: ""
									}
								/>
							</div>
							<div className="content-input__profile col-12 col-xl-2 col-sm-6 col-md-6 mb-3">
								<label className="form-label">City</label>
								<input
									type="text"
									className="form-control input-profile"
									value={
										profile[0].company.city !== null
											? profile[0].company.city
											: ""
									}
									onChange={(e) =>
										setNewStatus(e, "city", true, "text")
									}
								/>
							</div>
							<div className="content-input__profile col-12 col-xl-2 col-sm-6 col-md-6 mb-3">
								<label className="form-label">State</label>
								<input
									type="text"
									className="form-control input-profile"
									value={
										profile[0].company.state !== null
											? profile[0].company.state
											: ""
									}
									onChange={(e) =>
										setNewStatus(e, "state", true, "text")
									}
								/>
							</div>
							<div className="content-input__profile col-12 col-xl-2 col-sm-6 col-md-6 mb-3">
								<label className="form-label">Zip Code</label>
								<input
									type="text"
									className="form-control input-profile"
									value={
										profile[0].company.zipcode !== null
											? profile[0].company.zipcode
											: ""
									}
									onChange={(e) =>
										setNewStatus(
											e,
											"zipcode",
											true,
											"number",
										)
									}
								/>
							</div>
						</div>
						<div className="row">
							<div className="content-input__profile col-12 col-lg-6 mb-3">
								<label className="form-label">
									Phone Number
								</label>
								<input
									type="text"
									className="form-control input-profile"
									value={
										profile[0]?.company?.contact_phone !==
										null
											? profile[0]?.company?.contact_phone
											: ""
									}
									onChange={(e) =>
										setNewStatus(
											e,
											"contact_phone",
											true,
											"number",
										)
									}
								/>
							</div>
							<div className="content-input__profile col-12 col-lg-6 col-xl-2 mb-3">
								<label className="form-label">Tax</label>
								<input
									type="text"
									className="form-control input-profile"
									value={
										profile[0].company.tax !== null
											? profile[0].company.tax
											: ""
									}
									onChange={(e) =>
										setNewStatus(e, "tax", true, "number")
									}
								/>
							</div>
						</div>
					</div>
					<div className="content-logo__profile">
						<div className="content-logo__image">
							<label for="input_file_profile">
								<label className="form-label">LOGO</label>
								<div
									className="image-container"
									style={{
										height: "150px",
										border: "1px solid grey",
										margin: "auto",
									}}
									for="input_file_profile"
								>
									<input
										id="input_file_profile"
										type={"file"}
										onChange={(e) => previewImage(e)}
										hidden={true}
									></input>
									<img src={preview_image} alt=""></img>
								</div>
							</label>
						</div>
						<button
							disabled={in_response}
							onClick={() => sendInfoDataUpdate()}
							type="button"
							className="btn button-submit mt-5"
						>
							Save
						</button>
					</div>
				</form>
			</div>
		</Layout>
	);
}

export { UserProfile };
