import createHttp from "../lib/http";

const PaymentService = {};

PaymentService.getEmailSetting = async () => {
  const { data } = await createHttp.get("/api/get_email_configuration");
  return data;
}

PaymentService.updateEmailSetting = async (payload) => {
  const { data } = await createHttp.post("/api/save_email_configuration", payload);
  return data;
}

export default PaymentService;