import React from "react";
import { Modal, Button } from "react-bootstrap";
import { TextInput } from "../text_input";
import { ImageInput } from "../image_input";

function ModalDelete(props) {
	return (
		<React.Fragment>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered={props.centered === false ? false : true}
				show={props.show}
				onHide={props.onHandleClose}
				size={props.size ? props.size : null}
			>
				<Modal.Header>
					<Modal.Title>Delete {props.app_conf.title} </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="container-fluid">
						<div className="d-flex justify-content-center">
							<span className="text-center">
								{" "}
								{props.app_conf.msg
									? props.app_conf.msg
									: ""}{" "}
							</span>
						</div>

						<br></br>

						<div className="d-flex justify-content-center">
							<div className="m-2">
								<Button
									onClick={props.onConfirmDelete}
									variant="outline-success"
									size="sm"
								>
									Delete
								</Button>
							</div>
							<div className="m-2">
								<Button
									onClick={props.onHandleClose}
									variant="outline-danger"
									size="sm"
								>
									No
								</Button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);

	/*
			 <Button variant="secondary" onClick={ props.onHandleClose}>
											Close
									</Button>
	 onClick={ props.onHandleClose}
	*/
}

export { ModalDelete };
