export const typesAndValues = [
	{
		field_name: "id",
		type_field: "text_input",
		text_label: "Id",
		disabled: true,
		regexPattern: /^[A-Za-z0-9]*$/,
		maxLength: 15,
	},
	{
		field_name: "name",
		type_field: "text_input",
		text_label: "Name",
		disabled: false,
		regexPattern: /^[0-9A-Za-z\s\-]+$/,
		maxLength: 50,
		// minLength: 1
	},
	{
		field_name: "description",
		type_field: "text_input",
		text_label: "Description",
		disabled: false,
		regexPattern: /^[0-9A-Za-z\s\-]+$/,
		maxLength: 50,
		// minLength: 1
	},
	{
		field_name: "status",
		type_field: "select_input",
		text_label: "Status",
		placeHolder: "select a status",
		dontDefaultOption: true,
		regexPattern: /^[a-zA-Z0-9_ ]+$/i,
		values: [
			{ id: "active", name: "Active" },
			{ id: "inactive", name: "Inactive" },
		],
		selectCol: "col-12",
		selectWidth: "100%",
		// minLength: 1,
		col: "col-12 col-md-6",
	},
];

export const initData = {
	id: "",
	name: "",
	description: "",
	status: "active",
};
