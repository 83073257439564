import React, { useEffect, useState } from "react";
//styles
import "./Tax.css";

//components
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { Table, Form } from "react-bootstrap";
import { PaginationControlled } from "../../../components/CustomPagination/CustomPaginacion";
import { ModalAddTax } from "../../../components/ModalAddTax";
import { ModalEditTax } from "../../../components/ModalEditTax";
import { ModalDeleteTax } from "../../../components/ModalDeleteTax";
import { ModalAddTaxGroup } from "../../../components/ModalAddTaxGroup";
import { ModalEditTaxGroup } from "../../../components/ModalEditTaxGroup";
import MaterialUI from '../../../components/MaterialUI';

import { ModalAddCustomerTax } from "../../../components/ModalAddCustomerTax";
import { ModalEditCustomerTax } from "../../../components/ModalEditCustomerTax";

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

//customHooks
import { useModalHook } from "../../../customHooks/useModalHook";
import { useApiRequestTax } from "../../../customHooks/useApiRequestTax";
import { useApiRequestCustomerTax } from "../../../customHooks/useApiRequestCustomerTax";
import { useApiRequestTaxGroup } from "../../../customHooks/useApiRequestTaxGroup";

function Tax() {
	const {
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
	} = useModalHook();

	const {
		isLoading,
		filtered_data,
		dataPagination: dataSalesTaxesPagination,
		deleteItem: deleteResourse,
		addItem: addTax,
		updateItem: updateTax,
		getSalesTaxesPaginated,
		getTaxSearchSuggestions,
		taxSuggestions,
	} = useApiRequestTax(
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
	);

	const {
		isLoading: TaxGroup_isLoading,
		filtered_data: filtered_TaxGroup_data,
		dataPagination: dataTaxGroupPagination,
		deleteItem:deleteTaxGroupResourse,
		addItem: addTaxGroup,
		updateItem: updateTaxGroup,
		getTaxGroupPaginated,
		responseStatus:responseStatusTaxGroup,
		responseMsg:responseMsgTaxGroup,
	} = useApiRequestTaxGroup(
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
	);
	
	const {
		isLoading: customer_isLoading,
		filtered_data: filtered_customer_data,
		dataPagination: dataCustomersTaxesPagination,
		deleteItem:deleteCustomerTaxResourse,
		addItem: addCustomerTax,
		updateItem: updateCustomerTax,
		getCustomerTaxesPaginated,
		responseStatus,
		responseMsg,
		getCustomerSearchSuggestions,
		customerEmailSuggestions,
	} = useApiRequestCustomerTax(
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
	);

	// --------------------------- sales taxes ---------------------------
	const [salesTaxesData, setSalesTaxesData] = useState([]);
	const [salesTaxesPaginationConfig, setSalesTaxesPaginationConfig] = useState({
		page:1,
		search:"",
		paginate:25
	});

	const [prevSalesTaxesPaginationConfig, setPrevSalesTaxesPaginationConfig] = useState(salesTaxesPaginationConfig);

  useEffect(() => {
    if (
      salesTaxesPaginationConfig.page !== prevSalesTaxesPaginationConfig.page ||
      salesTaxesPaginationConfig.search !== prevSalesTaxesPaginationConfig.search ||
      salesTaxesPaginationConfig.paginate !== prevSalesTaxesPaginationConfig.paginate
    ) {
      getSalesTaxesPaginated(salesTaxesPaginationConfig);
      setPrevSalesTaxesPaginationConfig(salesTaxesPaginationConfig); // Actualiza el estado previo
    }
  }, [salesTaxesPaginationConfig]);

	const handlePaginateSalesTaxesChange = (event) => {
		const newPaginateValue = parseInt(event.target.value);
		setSalesTaxesPaginationConfig({
			page:1,
			search:"",
			paginate:newPaginateValue
		});
	};

	const handlePageSalesTaxes = (page) => {
		setSalesTaxesPaginationConfig(prevState => ({
		  ...prevState,
		  page: page
		}));
	};

	const [typingTimeout, setTypingTimeout] = useState(0);

	const handleSalesTaxesSearchChange = (event) => {
	  const newSearchValue = event.target.value;
	  clearTimeout(typingTimeout); // Limpiar el temporizador anterior
	  const newTimeout = setTimeout(() => {
		setSalesTaxesPaginationConfig(prevState => ({
		  ...prevState,
		  search: newSearchValue
		}));
	  }, 250); // Esperar un segundo antes de actualizar el estado
	  setTypingTimeout(newTimeout);
	};

	// ------------------------ Group tax -----------------------------
	const [taxGroupData, setTaxGroupData] = useState([]);
	const [taxGroupPaginationConfig, setTaxGroupPaginationConfig] = useState({
		page:1,
		search:"",
		paginate:25
	});

	const [prevTaxGroupPaginationConfig, setPrevTaxGroupPaginationConfig] = useState(taxGroupPaginationConfig);

  useEffect(() => {
    if (
      taxGroupPaginationConfig.page !== prevTaxGroupPaginationConfig.page ||
      taxGroupPaginationConfig.search !== prevTaxGroupPaginationConfig.search ||
      taxGroupPaginationConfig.paginate !== prevTaxGroupPaginationConfig.paginate
    ) {
      getTaxGroupPaginated(taxGroupPaginationConfig);
      setPrevTaxGroupPaginationConfig(taxGroupPaginationConfig); // Actualiza el estado previo
    }
  }, [taxGroupPaginationConfig]);

	const handlePaginateTaxGroupChange = (event) => {
		const newPaginateValue = parseInt(event.target.value);
		setTaxGroupPaginationConfig({
			page:1,
			search:"",
			paginate: newPaginateValue
		});
	};

	const handlePageTaxGroup = (page) => {
		setTaxGroupPaginationConfig(prevState => ({
		  ...prevState,
		  page: page
		}));
	};

	const handleTaxGroupSearchChange = (event) => {
	  const newSearchValue = event.target.value;
	  clearTimeout(typingTimeout); // Limpiar el temporizador anterior
	  const newTimeout = setTimeout(() => {
		setTaxGroupPaginationConfig(prevState => ({
		  ...prevState,
		  search: newSearchValue
		}));
	  }, 250); // Esperar un segundo antes de actualizar el estado
	  setTypingTimeout(newTimeout);
	};

	// ------------------------ customer taxes -----------------------------
	const [customerTaxesData, setCustomerTaxesData] = useState([]);
	const [customersTaxesPaginationConfig, setCustomersTaxesPaginationConfig] = useState({
		page:1,
		search:"",
		paginate:25
	});

	const [prevCustomersTaxesPaginationConfig, setPrevCustomersTaxesPaginationConfig] = useState(customersTaxesPaginationConfig);

  useEffect(() => {
    if (
      customersTaxesPaginationConfig.page !== prevCustomersTaxesPaginationConfig.page ||
      customersTaxesPaginationConfig.search !== prevCustomersTaxesPaginationConfig.search ||
      customersTaxesPaginationConfig.paginate !== prevCustomersTaxesPaginationConfig.paginate
    ) {
      getCustomerTaxesPaginated(customersTaxesPaginationConfig);
      setPrevCustomersTaxesPaginationConfig(customersTaxesPaginationConfig); // Actualiza el estado previo
    }
  }, [customersTaxesPaginationConfig]);

	const handlePaginateCustomerTaxesChange = (event) => {
		const newPaginateValue = parseInt(event.target.value);
		setCustomersTaxesPaginationConfig({
			page:1,
			search:"",
			paginate: newPaginateValue
		});
	};

	const handlePageCustomersTaxes = (page) => {
		setCustomersTaxesPaginationConfig(prevState => ({
		  ...prevState,
		  page: page
		}));
	};

	// const [typingTimeout, setTypingTimeout] = useState(0);

	const handleCustomerTaxesSearchChange = (event) => {
	  const newSearchValue = event.target.value;
	  clearTimeout(typingTimeout); // Limpiar el temporizador anterior
	  const newTimeout = setTimeout(() => {
		setCustomersTaxesPaginationConfig(prevState => ({
		  ...prevState,
		  search: newSearchValue
		}));
	  }, 250); // Esperar un segundo antes de actualizar el estado
	  setTypingTimeout(newTimeout);
	};




	useEffect(() => {
		setSalesTaxesData(filtered_data);
		setCustomerTaxesData(filtered_customer_data);
		setTaxGroupData(filtered_TaxGroup_data);
	},[filtered_data, filtered_customer_data, filtered_TaxGroup_data]);

	const [sortBy, setSortBy] = useState(null);
	const [sortOrder, setSortOrder] = useState("asc");

	const handleSort = (columnName) => {
		if (sortBy === columnName) {
			setSortOrder(sortOrder === "asc" ? "desc" : "asc");
		} else {
			setSortBy(columnName);
			setSortOrder("asc");
		}
	};

	const sortedData = (data) => {
        return [...data].sort((a, b) => {
            if (sortBy) {
                const comparison = a[sortBy] - b[sortBy];
                return sortOrder === "asc" ? comparison : -comparison;
            }
            return 0;
        });
    };

	// modal
	const [showAddTaxModal, setShowAddTaxModal] = useState(false);
	const [showEditTaxModal, setShowEditTaxModal] = useState(false);
	const [showDeleteTaxModal, setShowDeleteTaxModal] = useState(false);

	const [showAddCustomerTaxModal, setShowAddCustomerTaxModal] = useState(false);
	const [showEditCustomerTaxModal, setShowEditCustomerTaxModal] = useState(false);
	const [showDeleteCustomerTaxModal, setShowDeleteCustomerTaxModal] = useState(false);

	const [showAddTaxGroupModal, setShowAddTaxGroupModal] = useState(false);
	const [showEditTaxGroupModal, setShowEditTaxGroupModal] = useState(false);
	const [showDeleteTaxGroupModal, setShowDeleteTaxGroupModal] = useState(false);




	const [urlResourseIsGoingToBeDeleted, setUrlResourseIsGoingToBeDeleted] = useState("");
	const [resourseIsGoingToBeEdited, setResourseIsGoingToBeEdited] = useState({});
	const [currentSale, setCurrentSale] = useState({});
	const [currentCustomer, setCurrentCustomer] = useState({});
	const [currentTaxGroup, setCurrentTaxGroup] = useState({});

	// taxes
	const handleDeleteTax = (resourseUrl) =>{
		setShowDeleteTaxModal(true);
		setUrlResourseIsGoingToBeDeleted(resourseUrl)
	}

	const handleEditTax = (item) =>{
		setShowEditTaxModal(true);
		// console.log(item)
		setResourseIsGoingToBeEdited(item)
		setCurrentSale(item)
	}

	//taxes group
	const handleEditTaxGroup = (item) =>{
		setShowEditTaxGroupModal(true);
		// console.log(item)
		setResourseIsGoingToBeEdited(item)
		setCurrentTaxGroup(item);
	}

	const handleDeleteTaxGroup = (resourseUrl) =>{
		setShowDeleteTaxGroupModal(true);
		setUrlResourseIsGoingToBeDeleted(resourseUrl)
	}

	//customer tax
	const handleEditCustomerTax = (item) =>{
		setShowEditCustomerTaxModal(true);
		// console.log(item)
		setResourseIsGoingToBeEdited(item)
		setCurrentCustomer(item)
	}

	const handleDeleteCustomerTax = (resourseUrl) =>{
		setShowDeleteCustomerTaxModal(true);
		setUrlResourseIsGoingToBeDeleted(resourseUrl)
	}

	return (
		<div className="tax_page">
			<section className="p-2 py-3">
				<h3 className="tax_section_title_mobile text-start">Sales Taxes</h3>
				<div className="tax_section_header">
					<MaterialUI.Button.Primary onClick={() => setShowAddTaxModal(true)} size="small">
						<AddIcon fontSize="small" className="me-2"/>ADD
					</MaterialUI.Button.Primary>
					<h3 className="tax_section_title">Sales Taxes</h3>
					<div className="Search_show_entries_container">
						<input type="text" className="form-control form-control-sm" placeholder="Search..."  onChange={(e) => handleSalesTaxesSearchChange(e)} />
						Show
						<select className="form-select form-select-sm w-25" style={{ height: 'auto' }} onChange={handlePaginateSalesTaxesChange} value={salesTaxesPaginationConfig.paginate}>
							<option value="10">10</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="150">150</option>
							<option value="200">200</option>
							<option value="250">250</option>
						</select>
						entries
					</div>
				</div>

				<Table bordered className="table table-container">
					<thead>
						<tr>
							<th onClick={() => handleSort("name")}>
								<div style={{ display: "flex", justifyContent: "space-between" }}>
									<span className="fs-small">Name</span>
									<div className="rowsIcon">
										<FaLongArrowAltUp
											style={{ marginRight: "-5px" }}
										/>
										<FaLongArrowAltDown />
									</div>
								</div>
							</th>              
							<th className="column-tax-rate" onClick={() => handleSort("taxRate")}>
								<div style={{ display: "flex", justifyContent: "space-between" }}>
									<span className="fs-small">Tax Rate %</span>
									<div className="rowsIcon">
										<FaLongArrowAltUp
											style={{ marginRight: "-5px" }}
										/>
										<FaLongArrowAltDown />
									</div>
								</div>
							</th>
							<th className="text-center" style={{ inlineSize: '102px' }}>
								<span className="fs-small">Actions</span>
							</th>
						</tr>
					</thead>
					<tbody>
						{salesTaxesData.length > 0 && sortedData(salesTaxesData).map((item) => (
							<tr key={item.id}>
								<td className="align-middle"><span className="fs-small text-capitalize">{item.tax_name}</span></td>
								<td className="align-middle"><span className="fs-small">{item.tax}</span></td>
								<td className="align-middle text-center">
									<MaterialUI.Button.Primary size="small" className="mw-auto me-1" onClick={() => handleEditTax(item)}>
										<EditIcon fontSize="small" />
									</MaterialUI.Button.Primary>
									<MaterialUI.Button.Danger size="small" className="mw-auto" onClick={() => handleDeleteTax(`api/delete_taxes/${item.id}`)}>
										<DeleteIcon fontSize="small" />
									</MaterialUI.Button.Danger>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				<div className="d-flex justify-content-between pagination-container">
					<small>Showing 1 to {dataSalesTaxesPagination.total_pages} of {salesTaxesPaginationConfig.paginate} entries</small>
					{dataSalesTaxesPagination && !isLoading ? (
							<PaginationControlled
								itemPagination={dataSalesTaxesPagination}
								handlePagination={handlePageSalesTaxes}
							/>
					): <div style={{height:"85px"}}></div>
					}
				</div>
			</section>
			{/* ---------------------------------------------- GROUP TAX ------------------------------------ */}
			<section className="section-2 p-2 py-3" >
				<h3 className="tax_section_title_mobile text-start">Group Taxes</h3>
				<div className="tax_section_header">
					<MaterialUI.Button.Primary onClick={() => setShowAddTaxGroupModal(true)} size="small">
						<AddIcon fontSize="small" className="me-2"/>ADD
					</MaterialUI.Button.Primary>
					<h3 className="tax_section_title">Group Taxes</h3>
					<div className="Search_show_entries_container">
						<input type="text" className="form-control form-control-sm" placeholder="Search..." onChange={(e) => handleTaxGroupSearchChange(e)}/>
						Show
						<select className="form-select form-select-sm w-25" style={{ height: 'auto' }} onChange={handlePaginateTaxGroupChange} value={taxGroupPaginationConfig.paginate}>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="150">150</option>
							<option value="200">200</option>
							<option value="250">250</option>
						</select>
						entries
					</div>
				</div>

				<Table bordered className="table table-container">
					<thead>
						<tr>
							<th onClick={() => handleSort("name")}>
								<div className="d-flex justify-content-between">
									<span className="fs-small">Name</span>
									<div className="rowsIcon">
										<FaLongArrowAltUp
											style={{ marginRight: "-5px" }}
										/>
										<FaLongArrowAltDown />
									</div>
								</div>
							</th>          
							<th onClick={() => handleSort("taxRate")}>
								<div className="d-flex justify-content-between">
									<span className="fs-small">Sub Taxes</span>
									<div className="rowsIcon">
										<FaLongArrowAltUp
											style={{ marginRight: "-5px" }}
										/>
										<FaLongArrowAltDown />
									</div>
								</div>
							</th>
							<th className="column-tax-rate" onClick={() => handleSort("taxRate")}>
								<div className="d-flex justify-content-between">
									<span className="fs-small">Tax Rate %</span>
									<div className="rowsIcon">
										<FaLongArrowAltUp
											style={{ marginRight: "-5px" }}
										/>
										<FaLongArrowAltDown />
									</div>
								</div>
							</th>
							<th className="text-center" style={{ inlineSize: '102px' }}>
								<span className="fs-small">Actions</span>
							</th>
						</tr>
					</thead>
					<tbody>
						{taxGroupData.length > 0 && sortedData(taxGroupData).map((item) => (
							<tr key={item.id}>
								<td className="align-middle text-capitalize">
									<span className="fs-small">{item.tax_name}</span>
								</td>
								<td className="align-middle text-capitalize">
									<span className="fs-small">{item.taxes?.map((taxItem)=> taxItem.tax_name).join(", ")}</span>
								</td>
								<td className="align-middle">
									<span className="fs-small">
										{item.taxes?.reduce((accumulator, taxItem) => (parseFloat(accumulator) + parseFloat(taxItem.tax)), 0).toFixed(2)}
									</span>
								</td>
								<td className="align-middle text-center">
									<MaterialUI.Button.Primary size="small" className="mw-auto me-1" onClick={() => handleEditTaxGroup(item)}>
										<EditIcon fontSize="small" />
									</MaterialUI.Button.Primary>
									<MaterialUI.Button.Danger size="small" className="mw-auto" onClick={() => handleDeleteTaxGroup(`api/delete_taxes_group/${item.id}`)}>
										<DeleteIcon fontSize="small" />
									</MaterialUI.Button.Danger>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				<div className="d-flex justify-content-between pagination-container">
					<small>Showing 1 to {dataTaxGroupPagination.total_pages} of {taxGroupPaginationConfig.paginate} entries</small>
					{dataTaxGroupPagination && !isLoading ? (
							<PaginationControlled
								itemPagination={dataTaxGroupPagination}
								handlePagination={handlePageTaxGroup}
							/>
					): <div style={{height:"85px"}}></div>
				}
				</div>
			</section>

			<section className="p-2 py-3">
				<h3 className="tax_section_title_mobile text-start">Customer Settings</h3>
				<div className="tax_section_header">
					<MaterialUI.Button.Primary onClick={() => setShowAddCustomerTaxModal(true)} size="small">
						<AddIcon fontSize="small" className="me-2"/>ADD
					</MaterialUI.Button.Primary>
					<h3 className="tax_section_title">Customer Settings</h3>
					<div className="Search_show_entries_container">
						<input type="text" className="form-control form-control-sm" placeholder="Search..."  onChange={(e) => handleCustomerTaxesSearchChange(e)} />
						Show
						<select className="form-select form-select-sm w-25" style={{ height: 'auto' }} onChange={handlePaginateCustomerTaxesChange} value={salesTaxesPaginationConfig.paginate}>
						<option value="25">25</option>
						<option value="50">50</option>
						<option value="100">100</option>
						<option value="150">150</option>
						<option value="200">200</option>
						<option value="250">250</option>
						</select>
						entries
					</div>
				</div>

				<Table bordered>
					<thead>
						<tr>
							<th onClick={() => handleSort("name")}>
								<div className="d-flex justify-content-between">
									<span className="fs-small">Customer E-mail</span>
									<div className="rowsIcon">
										<FaLongArrowAltUp
											style={{ marginRight: "-5px" }}
										/>
										<FaLongArrowAltDown />
									</div>
								</div>
							</th>
                            
							<th className="column-tax-rate" onClick={() => handleSort("taxRate")}>
								<div className="d-flex justify-content-between">
									<span className="fs-small">Tax Rate %</span>
									<div className="rowsIcon">
										<FaLongArrowAltUp
											style={{ marginRight: "-5px" }}
										/>
										<FaLongArrowAltDown />
									</div>
								</div>
							</th>
							<th className="text-center" style={{ inlineSize: '102px' }}>
								<span className="fs-small">Actions</span>
							</th>
						</tr>
					</thead>	
					<tbody>
						{customerTaxesData.length > 0 && sortedData(customerTaxesData).map((item) => (
							<tr key={item.id}>
								<td className="align-middle">
									<span className="fs-small">{item.customer_email}</span>
								</td>
								<td className="align-middle">
									<span className="fs-small">{item.tax}</span>
								</td>
								<td className="align-middle text-center">
									<MaterialUI.Button.Primary size="small" className="mw-auto me-1" onClick={() => handleEditCustomerTax(item)}>
										<EditIcon fontSize="small" />
									</MaterialUI.Button.Primary>
									<MaterialUI.Button.Danger size="small" className="mw-auto" onClick={() => handleDeleteCustomerTax(`api/delete_taxes_customer/${item.id}`)}>
										<DeleteIcon fontSize="small" />
									</MaterialUI.Button.Danger>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				<div className="d-flex justify-content-between pagination-container">
				<small>Showing 1 to {dataCustomersTaxesPagination.total_pages} of {customersTaxesPaginationConfig.paginate} entries</small>
					{dataCustomersTaxesPagination && !isLoading && (
							<PaginationControlled
								itemPagination={dataCustomersTaxesPagination}
								handlePagination={handlePageCustomersTaxes}
							/>
					)}
				</div>
			</section>

			<section className="general_settings mb-3 rounded-3">
				<h3>General Settings</h3>
				<Form style={{display:"flex", backgroundColor:"white", padding:"3px 8px", borderRadius:"4px"}}>
					<Form.Check 
						type="checkbox"
						id="custom-checkbox"
					/>
					<p>Enable automatic tax to all available products?</p>
				</Form>
			</section>

			<ModalAddTax
				title={"Add Tax Rates"}
				onHandleClose={() => setShowAddTaxModal(false)}
				show={showAddTaxModal}
				handleAction={addTax}
				isLoading={isLoading}
			/>
			<ModalEditTax
				title={"Edit Tax Rates:"}
				onHandleClose={() => setShowEditTaxModal(false)}
				show={showEditTaxModal}
				handleAction={updateTax}
				tax={currentSale}
				isLoading={isLoading}
				/>
			<ModalDeleteTax
				title={"Are you sure?"}
				bodyMessage={"This record will be permanently deleted."}
				onHandleClose={() => setShowDeleteTaxModal(false)}
				show={showDeleteTaxModal}
				handleAction={()=>deleteResourse(urlResourseIsGoingToBeDeleted)}
				isLoading={isLoading}
			/>

			{/* ------- tax group modals --------- */}
			<ModalAddTaxGroup
				title={"Add Tax Group"}
				onHandleClose={() => setShowAddTaxGroupModal(false)}
				show={showAddTaxGroupModal}
				handleAction={addTaxGroup}
				isLoading={TaxGroup_isLoading}
				resStatus={responseStatusTaxGroup}
				resMsg={responseMsgTaxGroup}
				searchSuggestions={getTaxSearchSuggestions}
				dataSuggestions={taxSuggestions}
			/>
			<ModalEditTaxGroup
				title={"Edit Tax Group"}
				onHandleClose={() => setShowEditTaxGroupModal(false)}
				show={showEditTaxGroupModal}
				handleAction={updateTaxGroup}
				taxGroup={currentTaxGroup}
				isLoading={TaxGroup_isLoading}
				resStatus={responseStatusTaxGroup}
				resMsg={responseMsgTaxGroup}
				searchSuggestions={getTaxSearchSuggestions}
				dataSuggestions={taxSuggestions}
			/>
			<ModalDeleteTax
				title={"Are you sure?"}
				bodyMessage={"This record will be permanently deleted."}
				onHandleClose={() => setShowDeleteTaxGroupModal(false)}
				show={showDeleteTaxGroupModal}
				handleAction={()=>deleteTaxGroupResourse(urlResourseIsGoingToBeDeleted)}
				isLoading={TaxGroup_isLoading}
			/>
			{/* ------- customer modals --------- */}
			<ModalAddCustomerTax
				title={"Add Tax Rates"}
				onHandleClose={() => setShowAddCustomerTaxModal(false)}
				show={showAddCustomerTaxModal}
				handleAction={addCustomerTax}
				isLoading={customer_isLoading}
				resStatus={responseStatus}
				resMsg={responseMsg}
				searchSuggestions={getCustomerSearchSuggestions}
				dataSuggestions={customerEmailSuggestions}
			/>

			<ModalEditCustomerTax
				title={"Edit Tax Rates"}
				onHandleClose={() => setShowEditCustomerTaxModal(false)}
				show={showEditCustomerTaxModal}
				handleAction={updateCustomerTax}
				customer_tax={currentCustomer}
				isLoading={customer_isLoading}
				resStatus={responseStatus}
				resMsg={responseMsg}
			/>

			<ModalDeleteTax
				title={"Are you sure?"}
				bodyMessage={"This record will be permanently deleted."}
				onHandleClose={() => setShowDeleteCustomerTaxModal(false)}
				show={showDeleteCustomerTaxModal}
				handleAction={()=>deleteCustomerTaxResourse(urlResourseIsGoingToBeDeleted)}
				isLoading={customer_isLoading}
			/>

		</div>
	);
}

export default Tax;
