import moment from "moment";

const today = moment().format("YYYY-MM-DD");

export const expenseForm = {
	id: "",
	warehouse_id: null,
	vendor_id: null,
	expense_category_id: null,
	number: "",
	amount: "",
	date: today,
	description: "",
};

export const paymentForm = {
	type_payment_id: null,
	payment_account_id: null,
	date_paid: today,
	payment_info: "",
}

export default {
  expenseForm,
	paymentForm,
}
