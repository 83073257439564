import { useState, useEffect } from "react";
import createHttp from "../lib/http";
import { amountPerPagePagination } from "../context/constants";

var _ = require("lodash");

function useApiRequestInvoice(
	manageResponseErrors,
	manageResponse,
	manageSuccessfullMesssage,
	mapResponse = false,
	mapFunction = (data) => data,
) {
	const [isLoading, setIsLoading] = useState(false);
	const [sincronizedItems, setSincronizedItems] = useState(false);
	const [filtered_data, setFilteredData] = useState([]);
	const [user, setUser] = useState([]);
	const [itemFilters, setItemFilters] = useState([
		{ field_id: "page", value: "1" },
		{ field_id: "per_page", value: amountPerPagePagination },
	]);
	const [filtersDefinition, setFiltersDefinition] = useState([]);
	const [itemPagination, setItemPagination] = useState([]);
 
	useEffect(async () => {
		setIsLoading(true);

		let params = {};
		itemFilters
			.filter((filter) => filter.value)
			.map((filter) => (params[filter.field_id] = filter.value));

		const storedValue = localStorage.getItem("type_transaction_value");
		if (storedValue) {
			params.type_transaction = storedValue;
		}
		// console.log(params)

		if (!sincronizedItems) {
			await createHttp
				.get("/api/invoices", { params })
				.then((response) => {
					if (response.data.status) {
						setUser(response.data.data);
						if (mapResponse) {
							let data = mapFunction(response.data.data);
							setFilteredData(data);
						} else {
							setFilteredData(response.data.data);
						}
						if (response.data.hasOwnProperty("pagination")) {
							setItemPagination(response.data.pagination);
						} else {
							manageResponseErrors(
								"An error has occurred",
								"invoice",
								"listing",
							);
						}
					}
					setSincronizedItems(true);
					setIsLoading(false);
				})
				.catch((response) => {
					setSincronizedItems(true);
					console.log("error", response);
					setIsLoading(false);
					manageResponseErrors(
						"An error has occurred",
						"invoice",
						"listing",
					);
				});
		}
		setIsLoading(false);
	}, [sincronizedItems]);

	const deleteItem = (id) => {
		setIsLoading(true);
		createHttp
			.delete("api/invoices/" + id)
			.then((res) => {
				if (!res.data.status) {
					console.log("error");
					return;
				} else {
					setSincronizedItems(false);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
			});
	};

	return {
		isLoading,
		setIsLoading,
		sincronizedItems,
		setSincronizedItems,
		filtered_data,
		user,
		setFilteredData,
		setUser,
		deleteItem,
		filtersDefinition,
		setFiltersDefinition,
		itemFilters,
		setItemFilters,
		itemPagination,
	};
}

export { useApiRequestInvoice };
