import React from "react";
import { Grid, Box } from "@material-ui/core";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

import useApiRequestExpensiveCategory from "../../customHooks/useApiRequestExpensiveCategory";

import AlertComponent from '../../components/Alert';
import Material from "../../components/MaterialUI";

const ExpensiveCategoryForm = ({ handleBehavior }) => {
	const { isLoading, errors, expensiveCategory, store, setExpensiveCategory, setErrors } = useApiRequestExpensiveCategory();
  const [showAlert, setShowAlert] = React.useState(false);

	const handleClose = (_, reason) => {
		if (reason === 'clickaway') return;
    setShowAlert(false);
	};

	const handleSubmit = async (ev) => {
		ev.preventDefault();
    if(isLoading) return;
    if(!expensiveCategory.name || !(/\S/.test(expensiveCategory.name)))
      return setErrors({ name: 'This field is required.' });

    try {
			const resp = await store(expensiveCategory);
			setShowAlert(true);
      if(handleBehavior && typeof handleBehavior === 'function')
        handleBehavior(resp);
		} catch (error) {}
	};

	const handleChange = (key, value) => {
    setErrors({ name: '' });
		setExpensiveCategory((state) => ({ ...state, [key]: value }));
	};

	return (
    <Box p={2}>
      <AlertComponent 
				open={showAlert} 
        handleClose={handleClose} 
        severity="success" 
        message="It has been successfully updated"
			/>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Material.Input 
              label={'Name'}
              state={expensiveCategory.name}
              setState={(value) => handleChange('name', value)}
              error={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Material.Button.Success
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
            >
              <SaveOutlinedIcon fontSize="small" className="me-2" />
              { isLoading ? 'Saving...' : 'Save' }
            </Material.Button.Success>
          </Grid>
        </Grid>
      </form>
    </Box>
	);
};

export default ExpensiveCategoryForm;
