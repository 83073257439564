import { AlternateEmail } from "@material-ui/icons";
import { useState } from "react";
import createHttp from "../../../../lib/http";
import { useModalHook } from "../../../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../../../customHooks/useApiCustomRequest";
import { useHistory, useLocation } from "react-router-dom";

import { useAuthentication } from "../../../../customHooks/useAuthentication";

const sucessfullResponseStandard = {
	status: true,
	message: "Code is valid! your email was verified",
};

const signUpEmailErrorResponse = {
	status: false,
	message: "The email already in use",
};

export const useForm = (initalForm, validateForm, typeRequest = "signUp") => {
	const history = useHistory();
	const location = useLocation();

	const { saveItemInLocalStorage } = useAuthentication();

	const {
		showSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		cancelButtonText,
		sucessButtonText,
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
	} = useModalHook();

	const { isLoading, createItem, createItemDetail } = useApiCustomRequest(
		manageResponseErrors,
		"register",
		"User",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		() => {},
	);

	const [form, setForm] = useState(initalForm);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [response, setResponse] = useState(null);
	const [manageResponseMessage, setManageResponseMessage] = useState({
		status: true,
		message: "",
		showRedirectButton: false,
	});

	const handleChange = (e) => {
		const { name, value } = e.target;

		setForm({
			...form,
			[name]: value,
		});
	};

	const handleBlur = (e) => {
		handleChange(e);
		// setErrors(validateForm(form));
	};

	const handleSubmit = async (
		e,
		url = "",
		reSend = false,
		requestData = {},
	) => {
		e.preventDefault();

		if (isLoading) return;

		let newErrors = [];

		if (!reSend) {
			newErrors = validateForm(form);
		}

		setErrors(newErrors);

		if (Object.keys(newErrors).length === 0) {
			if (typeRequest === "signUp") {
				const { phone_ext, phone_number, ...extForm } = form;

				let requestBody = {
					...extForm,
					phone_number: phone_ext + phone_number,
					email_send: true,
					sms_send: false,
				};

				let response = await createItemDetail(
					requestBody,
					"User",
					"register",
					true,
					"creating",
					true,
					true,
				);

				let errors = {};

				if (
					response.status === signUpEmailErrorResponse.status &&
					response.message &&
					signUpEmailErrorResponse.message
				) {
					errors.email = response.message;
					setErrors(errors);
				}

				if (
					response &&
					response.hasOwnProperty("status") &&
					response.status
				) {
					saveItemInLocalStorage("item_validation_info", {
						email: form.email,
						phoneNumber: phone_ext + phone_number,
					});

					history.push({
						pathname: "/validation_selection",
						search: ``,
					});
				}
			}

			if (typeRequest === "ValidateCode") {
				let requestUrl = reSend ? url : "validate_code";

				let requestBody = {};

				if (!reSend) {
					requestBody = {
						...form,
						...requestData,
					};
				} else {
					requestBody = {
						...requestData,
					};
				}

				let response = await createItemDetail(
					requestBody,
					"Code",
					requestUrl,
					true,
					"validating",
					true,
					true,
				);

				if (
					response.status === sucessfullResponseStandard.status &&
					response.message === sucessfullResponseStandard.message
				) {
					return history.push("/");
				}

				setManageResponseMessage({
					...response,
					showRedirectButton: !reSend ? true : false,
				});
			}

			if (typeRequest === "SendCode") {
				let requestBody = {
					...requestData,
				};

				let response = await createItemDetail(
					requestBody,
					"Code",
					url,
					true,
					"validating",
					true,
					true,
				);

				setManageResponseMessage({ ...response });

				if (
					response &&
					response.hasOwnProperty("status") &&
					response.status
				) {
					history.push({
						pathname: "/validation_code",
						search: ``,
					});
				}

				console.log("send code", response);
			}
		} else {
			return;
		}
	};
	return {
		form,
		errors,
		loading,
		response,
		handleChange,
		handleBlur,
		handleSubmit,
		showSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		cancelButtonText,
		sucessButtonText,
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
		isLoading,
		manageResponseMessage,
	};
};
