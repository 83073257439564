import { blockInvalidChar } from "../../context/constants";

export const typesAndValues = [
	{
		field_name: "current_password",
		type_field: "text_input",
		text_label: "Password",
		disabled: false,
		regexPattern: /^(?!\s*$).+/,
		maxLength: 20,
		col: "col-12 col-md-4",
		dotShowLabel: true,
		labelText: "Current password",
		dontSeeLabel: true,
		type: "password",
		showEyePassword: true,
		eyeIcon: "fa-eye",
		closeEyeIcon: "fa-eye-slash",
	},
	{
		field_name: "new_password",
		type_field: "text_input",
		text_label: "New password",
		disabled: false,
		regexPattern:
			/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
		maxLength: 20,
		col: "col-12 col-md-4",
		validationMessage:
			"New password must be 8-20 characters, includes at least three of the four types: upper/lower letters, numbers or symbols.",
		dotShowLabel: true,
		labelText: "New password",
		dontSeeLabel: true,
		type: "password",
		showEyePassword: true,
		eyeIcon: "fa-eye",
		closeEyeIcon: "fa-eye-slash",
	},
	{
		field_name: "confirm_password",
		type_field: "text_input",
		text_label: "Confirm new password",
		disabled: false,
		regexPattern:
			/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
		maxLength: 20,
		col: "col-12 col-md-4",
		validationMessage:
			"Confirm new password must be 8-20 characters, includes at least three of the four types: upper/lower letters, numbers or symbols.",
		dotShowLabel: true,
		labelText: "Confirm new password",
		dontSeeLabel: true,
		isEqualTo: "new_password",
		type: "password",
		showEyePassword: true,
		eyeIcon: "fa-eye",
		closeEyeIcon: "fa-eye-slash",
	},
];

export const initData = {
	current_password: "",
	new_password: "",
	confirm_password: "",
};

export const typesAndValuesCompany = [
	{
		field_name: "name",
		type_field: "text_input",
		text_label: "Name",
		disabled: false,
		regexPattern: /^([a-zA-Z0-9 _-]+)$/,
		maxLength: 50,
		col: "col-12 col-md-4",
		autoFocus: true,
		required: true,
	},
	{
		field_name: "email",
		type_field: "text_input",
		text_label: "Email",
		disabled: false,
		regexPattern: /^$|[a-z0-9]+@[a-z0-9]+\.[a-z]{2,3}/,
		maxLength: 128,
		col: "col-12 col-md-4",
		disabled: true,
	},
	{
		field_name: "website",
		type_field: "text_input",
		text_label: "Website",
		disabled: false,
		regexPattern:
			/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
		maxLength: 128,
		col: "col-12 col-md-4",
	},
	{
		field_name: "address",
		type_field: "text_input",
		text_label: "Address",
		disabled: false,
		regexPattern: /^[#.0-9a-zA-Z\s,-,',",@,;]+$/,
		maxLength: 100,
		// minLength: 3,
		col: "col-12 col-md-6",
		required: false,
	},
	{
		field_name: "city",
		type_field: "text_input",
		text_label: "City",
		disabled: false,
		regexPattern:
			/^(?:[A-Za-z]{2,}(?:(\.\s|'s\s|\s?-\s?|\s)?(?=[A-Za-z]+))){1,2}(?:[A-Za-z]+)?$/,
		maxLength: 100,
		// minLength: 3,
		col: "col-4 col-md-2",
		required: true,
	},
	{
		field_name: "state",
		type_field: "text_input",
		text_label: "State",
		disabled: false,
		regexPattern: /^([a-zA-Z0-9 _-]+)$/,
		maxLength: 4,
		// minLength: 2,
		col: "col-4 col-md-2",
		required: true,
	},
	{
		field_name: "zipcode",
		type_field: "text_input",
		text_label: "Postal Code",
		disabled: false,
		regexPattern: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
		maxLength: 5,
		col: "col-4 col-md-2",
		required: true,
	},
	{
		field_name: "contact_phone",
		type_field: "text_input",
		text_label: "Phone Number",
		disabled: false,
		regexPattern:
			/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
		maxLength: 15,
		col: "col-12 col-md-6",
	},
	{
		field_name: "tax",
		type_field: "text_input",
		text_label: "Tax",
		disabled: false,
		regexPattern: /^[0-9]*\.?[0-9]*$/,
		maxLength: 10,
		minLength: 1,
		col: "col-3 col-md-2",
		required: true,
		type: "number",
		onKeyDown: blockInvalidChar,
	},
];

export const initDataCompany = {
	name: "",
	email: "",
	contact_phone: "",
	address: "",
	zipcode: "",
	tax: "",
	city: "",
	state: "",
	website: "",
};
