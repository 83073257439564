export const typesAndValues = [
	{
		field_name: "id",
		type_field: "text_input",
		text_label: "Id",
		disabled: true,
		regexPattern: /^[A-Za-z0-9]*$/,
		maxLength: 15,
	},
	{
		field_name: "name",
		type_field: "text_input",
		text_label: "Name",
		disabled: false,
		regexPattern: /^[A-Za-z0-9. ]*$/,
		maxLength: 255,
		minLength: 3,
	},
	{
		field_name: "address",
		type_field: "text_input",
		text_label: "Address",
		disabled: false,
		regexPattern: /^[#.0-9a-zA-Z\s,-]+$/,
		maxLength: 255,
		minLength: 3,
	},
	{
		field_name: "address2",
		type_field: "text_input",
		text_label: "Address2",
		disabled: false,
		regexPattern: /^$|^[#.0-9a-zA-Z\s,-]+$/,
		maxLength: 255,
	},
	{
		field_name: "city",
		type_field: "text_input",
		text_label: "City",
		disabled: false,
		regexPattern: /^[a-zA-Z\s]*$/,
		maxLength: 255,
		minLength: 3,
	},
	{
		field_name: "state",
		type_field: "text_input",
		text_label: "State",
		disabled: false,
		regexPattern: /^[a-zA-Z\s]*$/,
		maxLength: 20,
		minLength: 2,
	},
	{
		field_name: "zip_code",
		type_field: "text_input",
		text_label: "Zip Code",
		disabled: false,
		regexPattern: /^[0-9]*$/,
		maxLength: 5,
		minLength: 5,
	},
	{
		field_name: "country",
		type_field: "text_input",
		text_label: "Country",
		disabled: false,
		regexPattern: /^[a-zA-Z\s]*$/,
		maxLength: 20,
		minLength: 5,
	},
	{
		field_name: "phone_number",
		type_field: "text_input",
		text_label: "Phone Number",
		disabled: false,
		regexPattern: /^$|^[0-9]*$/,
		maxLength: 25,
	},
	{
		field_name: "fax",
		type_field: "text_input",
		text_label: "Fax",
		disabled: false,
		regexPattern: /^([0-9]*$)|$/,
		maxLength: 25
	},
	{
		field_name: "email",
		type_field: "text_input",
		text_label: "Email",
		disabled: false,
		regexPattern: /^$|[a-z0-9]+@[a-z0-9]+\.[a-z]{2,3}/,
		maxLength: 255,
	},
	{
		field_name: "website",
		type_field: "text_input",
		text_label: "Website",
		disabled: false,
		regexPattern: /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?|$/,
		maxLength: 255,
	},
	// {
	//     field_name: 'contact',
	//     type_field: 'text_input',
	//     text_label: 'Contact',
	//     disabled: false,
	//     regexPattern: /^$|^[A-Za-z0-9. ]*$/,
	//     maxLength: 255,
	//     minLength: 0
	// },
	// {
	// 	field_name: "date_since",
	// 	type_field: "text_input",
	// 	text_label: "Vendor Since",
	// 	disabled: true,
	// 	minLength: 0,
	// },
];

export const initData = {
	id: "",
	name: "",
	address: "",
	address2: "",
	city: "",
	state: "",
	zip_code: "",
	country: "",
	phone_number: "",
	fax: "",
	email: "",
	website: "",
	date_since: "",
	type_payment_id: null
};

export const BEHAVIOR = {
	NORMAL: 'NORMAL',
	NO_REDIRECT: 'NO_REDIRECT',
}
