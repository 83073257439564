import { filter } from "lodash";
import { useState, useEffect, useCallback } from "react";
import createHttp from "../lib/http";

var _ = require("lodash");

function useApiRequestVendor(manageResponseErrors, requestVendorList = true) {
	const [isLoading, setIsLoading] = useState(false);
	const [sincronizedItems, setSincronizedItems] = useState(false);
	const [filtered_data, setFilteredData] = useState([]);
	
	const fetchVendors = useCallback(async (_sincronizedItems = sincronizedItems) => {
		if(_sincronizedItems) return;
		setIsLoading(true);
		await createHttp
			.get("/api/vendors")
			.then((response) => {
				if (response.data.status) {
					setFilteredData(response.data.data);
				} else {
					manageResponseErrors(
						"An error has occurred",
						"Vendor",
						"listing",
					);
				}
				setSincronizedItems(true);
				setIsLoading(false);
			})
			.catch((response) => {
				setSincronizedItems(true);
				setIsLoading(false);
				manageResponseErrors(
					"An error has occurred",
					"Vendor",
					"listing",
				);
			});
		setIsLoading(false);
	}, [sincronizedItems]);

	const fetchVendor = async (id) => {
		return await createHttp
			.get(`/api/vendors/${id}`)
			.then(({ data }) => {
				if (data.status)
					return data.data
			})
			.catch((err) => {
				throw err;
			});
	}

	useEffect(async () => {
		if (requestVendorList) fetchVendors()
	}, []);

	const getWareHouse = async (wareHouseId) => {
		return await createHttp
			.get("api/warehouse/" + wareHouseId)
			.then((response) => response.data)
			.catch((err) => ({ data: [] }));
	};

	const deleteVendor = async (use_item_id) => {
		await createHttp
			.delete("/api/vendors" + "/" + use_item_id, { id: use_item_id })
			.then((res) => {
				if (res.data.status) {
					setSincronizedItems(false);
					fetchVendors(false);
				} else {
					manageResponseErrors(
						res.data.message,
						"Vendor",
						"deleting",
					);
				}
			})
			.catch((error) => {
				manageResponseErrors(
					"An error has occurred",
					"Vendor",
					"deleting",
				);
			});
	};

	const updateVendor = async (use_item_id, data) => {
		return await createHttp.put("/api/vendors" + "/" + use_item_id, data);
	};

	const createVendor = async (data) => {
		let form = new FormData();
		Object.keys(data)
			.filter((key) => data[key])
			.map((key) => {
				form.append(key, data[key]);
			});

		return await createHttp.post("/api/vendors", form);
	};

	return {
		fetchVendor,

		isLoading,
		setIsLoading,
		sincronizedItems,
		setSincronizedItems,
		deleteVendor,
		filtered_data,
		setFilteredData,
		updateVendor,
		createVendor,

		fetchVendors,
	};
}

export { useApiRequestVendor };
