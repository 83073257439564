import { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import "../InvoiceForm.css";

// components
import { CustomForm } from "../../../components/CustomForm/CustomForm";
import { TabsComponent } from "../../../components/TabsComponent/TabsComponent";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import { CustomDataList } from "../../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../../components/CustomDataListItem/CustomDataListItem";
import no_image from "../../../assets/img/no_image.png";
import editLogo from "../../../assets/img/edit.jpg";
import adjunt from "../../../assets/img/adjunt.png";
import Box from "@material-ui/core/Box";
import { CustomButton } from "../../../components/CustomButton/CustomButton";
import { CustomImageViewer } from "../../../components/CustomImageViewer/CustomImageViewer";

//custom hooks
import { useModalHook } from "../../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../../customHooks/useApiCustomRequest";
import { useMenuActionHook } from "../../../customHooks/useMenuActionHook";
import { useFileManager } from "../../../customHooks/useFileManager";

// form configuration
import {
	typesAndValues as typesAndValuesFields,
	initData as initDataFields,
	typesAndValuesPartNumber as typesAndValuesPartNumberFields,
	initDataPartNumber as initDataPartNumberFields,
	typesAndValuesLabor as typesAndValuesLaborFields,
	initDataLabor as initDataLaborFields,
	typesAndValuesMessageOnInvoice as typesAndValuesMessageOnInvoiceFields,
	initDataMessageOnInvoice as initDataMessageOnInvoiceFields,
	typesAndValuesSaveDevice as typesAndValuesSaveDeviceFields,
	initDataSaveDevice as initDataSaveDeviceFields,
	typesAndValuesUpdatePart,
	initDataUpdatePart,
	typesAndValuesUpdateLabor as typesAndValuesUpdateLaborFields,
	PanelNames,
} from "./initFormWorkOrder";

import {
	styles,
	tableHeadersWorkOrderPartNumber as tableHeaders,
	dataFieldsWorkOrderPartNumber as dataFields,
	tableHeadersWorkOrderLaborCode as tableHeadersLaborCode,
	dataFieldsWorkOrderLaborCode as dataFieldsLaborCode,
	validateLength,
	formatDate,
} from "../../../context/constants";
import PDFModal from "../../../components/ModalPdf";

import {
	typesAndValues as typesAndValuesNewLaborFields,
	initData as initDataNewLaborFields,
} from "../../ProductForm/initLaborForm";

import {
	typesAndValues as typesAndValuesTypeDevice,
	initData as initDataTypeDevice,
} from "../../../formsDefinitions/typeDevice";

import { useApirequestStockReceipt } from "../../../customHooks/useApirequestStockReceipt";
import { useApiRequestItems } from "../../../customHooks/useApiRequestItems";
import { ModalAddItem } from "../../../components/modal_item_add";
import { CustomSideModal } from "../../../components/CustomSideModal/CustomSideModal";
import Panel from "../../../components/Panel";
import { CostumerForm } from "../../costumer/CostumerForm";
import MaterialUI from "../../../components/MaterialUI";

const imageFormats = ["image/png", "image/jpg", "image/jpeg"];

function WorkOrderFormUpdate(props) {
	const location = useLocation();
	const history = useHistory();

	const [goToMainPage, setGoToMainPage] = useState(false);

	const { anchorEl, setAnchorEl, onCloseModal, currentId, setCurrentId } =
		useMenuActionHook();

	const {
		setCustomMessageModal,
		showSuccessButton,
		setShowSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		setCustomTittleText,
		customMessageModal,
		manageResponseErrors,
		customCurrentItem,
		setCustomCurrentItem,
		manageResponse,
		manageSuccessfullMesssage,
		manageDeleteMessage,
		cancelButtonText,
		setCancelButtonText,
		sucessButtonText,
		setSucessButtonText,
		showCustomFormModal,
		setShowCustomFormModal
	} = useModalHook();

	const {
		isLoading,
		setIsLoading,
		getListItem,
		createItem,
		getItem,
		updateItem,
		updateModel,
		deleteItemByUrl,
		getFile,
		createItemDetail,
		createImageItem,
		deleteImageItem,
	} = useApiCustomRequest(
		manageResponseErrors,
		"work-orders",
		"Work Order",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		setGoToMainPage,
	);

	const {
		isLoading: typeDevice_isLoading,
		createItem: typeDevice_createItem,
		sincronizedItems: sincronizedItems_devices
	} = useApiCustomRequest(
		manageResponseErrors,
		"type-devices",
		"Device Types",
		manageResponse,
	);

	const {
		documentsImageArray,
		setDocumentsImageArray,
		imageIdsArray,
		setImageIdsArray,
		itemImageToAdd,
		setItemImageToAdd,
		setFileImage,
		deleteImage,
		openFileImage,
		onDeleteImage,
		isImage,
		setIsImage,
	} = useFileManager(
		setShowModal,
		customCurrentItem,
		setCustomCurrentItem,
		setCustomTittleText,
		setCustomMessageModal,
		setShowSuccessButton,
	);

	const [show, setShow] = useState(false);
	const [showUpdate, setShowUpdate] = useState(false);
	const [showUpdateLabor, setShowUpdateLabor] = useState(false);
	const [changeInForm, setChangeInForm] = useState(false);
	const [initData, setInitData] = useState({});
	const [initDataPartNumber, setInitDataPartNumber] = useState({});
	const [initDataLabor, setInitDataLabor] = useState({});
	const [initDataMessageOnInvoice, setInitDataMessageOnInvoice] = useState(
		{},
	);
	const [initDataSaveDevice, setInitDataSaveDevice] = useState({});
	const [typesAndValues, setTypesAndValues] = useState([]);
	const [typesAndValuesPartNumber, setTypesAndValuesPartNumber] = useState(
		[],
	);
	const [typesAndValuesLabor, setTypesAndValuesLabor] = useState([]);
	const [typesAndValuesMessageOnInvoice, setTypesAndValuesMessageOnInvoice] =
		useState([]);
	const [typesAndValuesSaveDevice, setTypesAndValuesSaveDevice] = useState(
		[],
	);
	const [typesAndValuesUpdateLabor, setTypesAndValuesUpdateLabor] = useState(
		[],
	);
	const [currentTabValue, setCurrentTabValue] = useState(0);
	const [doRequest, setDoRequest] = useState(false);
	const [sincronizeData, setSincronizeData] = useState(true);
	const [laborSubTotal, setLaborSubTotal] = useState(Number(0.0).toFixed(2));
	const [partSubTotal, setPartSubTotal] = useState(Number(0.0).toFixed(2));

	const [partNumberList, setPartNumberList] = useState([]);

	const [laborCodeList, setLaborCodeList] = useState([]);

	const userData = JSON.parse(localStorage.getItem("user_data"));
	// device fields
	useEffect(async () => {
		let initDataSaveDeviceCopy = { ...initDataSaveDeviceFields };
		let typesAndValuesSaveDeviceCopy = [...typesAndValuesSaveDeviceFields];

		let deviceTypes = await getListItem("type-devices", "Device Types", {});

		typesAndValuesSaveDeviceCopy = setValueInArray(
			typesAndValuesSaveDeviceCopy,
			"type_resource_id",
			"values",
			deviceTypes,
		);

		setInitDataSaveDevice(initDataSaveDeviceCopy);
		setTypesAndValuesSaveDevice(typesAndValuesSaveDeviceCopy);
	}, []);

	//work order fields
	useEffect(async () => {
		let initDataCopy = { ...initDataFields };
		let initDataPartNumberCopy = { ...initDataPartNumberFields };
		let initDataLaborCopy = { ...initDataLaborFields };
		let initDataMessageOnInvoiceCopy = {
			...initDataMessageOnInvoiceFields,
		};
		let initDataSaveDeviceCopy = { ...initDataSaveDeviceFields };
		let typesAndValuesCopy = [...typesAndValuesFields];
		let typesAndValuesPartNumberCopy = [...typesAndValuesPartNumberFields];
		let typesAndValuesLaborCopy = [...typesAndValuesLaborFields];
		let typesAndValuesMessageOnInvoiceCopy = [
			...typesAndValuesMessageOnInvoiceFields,
		];
		let typesAndValuesSaveDeviceCopy = [...typesAndValuesSaveDeviceFields];
		let typesAndValuesUpdateLaborCopy = [
			...typesAndValuesUpdateLaborFields,
		];

		const infoItem = await getItem(
			location?.search?.split("=")[1],
			{},
			true,
			true,
		);

		if (infoItem.hasOwnProperty("error") && infoItem.error) {
			history.push("/404");
		}

		const { name: clientName, id: clientId, devices } = infoItem.client;

		const {
			date_due,
			date_receipt,
			notes: description,
			message,
			resource_id,
			warehouse_id,
			parts,
			labors,
			total_labors,
			total_parts,
			documents,
			images,
		} = infoItem;

		let mapFiles = [];
		let mapImages = [];

		if (documents && documents.length > 0) {
			mapFiles = documents.map((item) => ({
				id: item.id,
				path: item.url,
				format: item.extension,
			}));
		}
		if (images && images.length > 0) {
			mapImages = images.map((item) => ({
				id: item.id,
				path: item.url,
				format: item.extension,
			}));
		}

		let complexFiles = [...mapFiles, ...mapImages];
		setDocumentsImageArray(complexFiles);

		setLaborSubTotal(Number(total_labors).toFixed(2));
		setPartSubTotal(Number(total_parts).toFixed(2));

		let mapParts = parts.map((part) => ({
			id: part?.id,
			part_number: part?.part?.sku || "",
			name: part?.part?.sku || "",
			description: part?.comment || "",
			quantity: part?.quantity,
			rate: part?.price || "",
			amount: part?.quantity * part?.price,
			item_id: part?.part?.id || "",
		}));

		setPartNumberList(mapParts || []);

		let mapLabors = labors.map((labor) => ({
			id: labor?.id,
			labor_code: labor?.labor?.sku || "",
			name: labor?.labor?.name || "",
			item_id: labor?.labor?.id || "",
			end_time: labor?.end_date || "",
			start_time: labor?.start_date || "",
			employee_id: labor?.employee?.id || "",
			worker: labor?.employee?.name || "",
			actual_hours: labor?.hours || "",
			rate: labor?.price || "",
			amount: labor?.total || "",
			quantity: 1,
		}));

		setLaborCodeList(mapLabors || []);

		const { id: employeeId } = infoItem?.employee || { id: "" };

		const { id: workOrderTypeId } = infoItem?.type_work_order || { id: "" };

		let typeWorkOrders = await getListItem(
			"type-work-orders",
			"Type work order",
			{},
		);
		const wareHouse = await getListItem("warehouse", "Warehouse", {});
		const profiles = await getListItem("profiles", "Type work order", {});

		typesAndValuesCopy = setValueInArray(
			typesAndValuesCopy,
			"client_id",
			"inputValue",
			`${clientName}`,
		);
		typesAndValuesCopy = setValueInArray(
			typesAndValuesCopy,
			"client_id",
			"doRequest",
			false,
		);
		typesAndValuesCopy = setValueInArray(
			typesAndValuesCopy,
			"client_id",
			"dontInitData",
			true,
		);

		typesAndValuesCopy = setValueInArray(
			typesAndValuesCopy,
			"resource_id",
			"values",
			devices || [],
		);
		typesAndValuesCopy = setValueInArray(
			typesAndValuesCopy,
			"type_work_order_id",
			"values",
			typeWorkOrders,
		);

		initDataSaveDeviceCopy = {
			...initDataSaveDeviceCopy,
			customer_id: clientId,
		};

		typesAndValuesSaveDeviceCopy = setValueInArray(
			typesAndValuesSaveDeviceCopy,
			"customer_id",
			"inputValue",
			`${clientName}`,
		);
		typesAndValuesSaveDeviceCopy = setValueInArray(
			typesAndValuesSaveDeviceCopy,
			"customer_id",
			"doRequest",
			false,
		);
		typesAndValuesSaveDeviceCopy = setValueInArray(
			typesAndValuesSaveDeviceCopy,
			"customer_id",
			"dontInitData",
			true,
		);

		if (typeWorkOrders.length > 0) {
			typesAndValuesCopy = setValueInArray(
				typesAndValuesCopy,
				"type_work_order_id",
				"values",
				typeWorkOrders,
			);
		}

		if (wareHouse?.length > 0 && profiles?.length > 0) {
			typesAndValuesCopy = setValueInArray(
				typesAndValuesCopy,
				"warehouse_id",
				"values",
				wareHouse,
			);
			typesAndValuesCopy = setValueInArray(
				typesAndValuesCopy,
				"employee_id",
				"values",
				profiles,
			);
			typesAndValuesUpdateLaborCopy = setValueInArray(
				typesAndValuesUpdateLaborCopy,
				"employee_id",
				"values",
				profiles,
			);
		}

		initDataCopy = {
			...initDataCopy,
			client_id: clientId || "",
			type_work_order_id: workOrderTypeId || "",
			date_receipt: formatDate(
				`${date_receipt.split("/")[2]}-${date_receipt.split("/")[1]}-${
					date_receipt.split("/")[0]
				}`,
			),
			due_date: formatDate(
				`${date_due.split("/")[2]}-${date_due.split("/")[1]}-${
					date_due.split("/")[0]
				}`,
			),
			notes: description || "",
			resource_id: resource_id || "",
			warehouse_id: warehouse_id || "",
			employee_id: employeeId || "",
		};

		initDataMessageOnInvoiceCopy = {
			...initDataMessageOnInvoiceCopy,
			message: message || "",
		};

		if (
			location.hasOwnProperty("data") &&
			location?.data.hasOwnProperty("customerData") &&
			Object.keys(location.data.customerData).length > 0
		) {
			const { id, name } = location.data.customerData;

			initDataCopy = {
				...initDataCopy,
				client_id: id,
			};

			typesAndValuesCopy = setValueInArray(
				typesAndValuesCopy,
				"client_id",
				"inputValue",
				`${name}`,
			);
			typesAndValuesCopy = setValueInArray(
				typesAndValuesCopy,
				"client_id",
				"doRequest",
				false,
			);
			typesAndValuesCopy = setValueInArray(
				typesAndValuesCopy,
				"client_id",
				"dontInitData",
				true,
			);

			initDataSaveDeviceCopy = {
				...initDataSaveDeviceCopy,
				customer_id: id,
			};

			typesAndValuesSaveDeviceCopy = setValueInArray(
				typesAndValuesSaveDeviceCopy,
				"customer_id",
				"inputValue",
				`${name}`,
			);
			typesAndValuesSaveDeviceCopy = setValueInArray(
				typesAndValuesSaveDeviceCopy,
				"customer_id",
				"doRequest",
				false,
			);
			typesAndValuesSaveDeviceCopy = setValueInArray(
				typesAndValuesSaveDeviceCopy,
				"customer_id",
				"dontInitData",
				true,
			);
		}

		setInitData(initDataCopy);
		setInitDataPartNumber(initDataPartNumberCopy);
		setInitDataLabor(initDataLaborCopy);
		setInitDataMessageOnInvoice(initDataMessageOnInvoiceCopy);
		setInitDataSaveDevice(initDataSaveDeviceCopy);
		setTypesAndValues(typesAndValuesCopy);
		setTypesAndValuesPartNumber(typesAndValuesPartNumberCopy);
		setTypesAndValuesLabor(typesAndValuesLaborCopy);
		setTypesAndValuesMessageOnInvoice(typesAndValuesMessageOnInvoiceCopy);
		setTypesAndValuesSaveDevice(typesAndValuesSaveDeviceCopy);
		setTypesAndValuesUpdateLabor(typesAndValuesUpdateLaborCopy);
	}, [location?.search?.split("=")[1]]);

	useEffect(async () => {
		if (doRequest && !sincronizeData) {
			const infoItem = await getItem(
				location?.search?.split("=")[1],
				{},
				true,
				true,
			);

			const { parts, labors, total_labors, total_parts } = infoItem;

			setLaborSubTotal(Number(total_labors).toFixed(2));
			setPartSubTotal(Number(total_parts).toFixed(2));

			let mapParts = parts.map((part) => ({
				id: part?.id,
				part_number: part?.part?.sku || "",
				name: part?.part?.sku || "",
				description: part?.comment || "",
				quantity: part?.quantity || "",
				rate: part?.price || "",
				amount: part.quantity * part.price,
				item_id: part?.part.id || "",
			}));

			setPartNumberList(mapParts || []);

			let mapLabors = labors.map((labor) => ({
				id: labor?.id,
				labor_code: labor?.labor?.sku || "",
				name: labor?.labor?.name || "",
				item_id: labor?.labor?.id || "",
				end_time: labor?.end_date || "",
				start_time: labor?.start_date || "",
				employee_id: labor?.employee?.id || "",
				worker: labor?.employee?.name || "",
				actual_hours: labor?.hours || "",
				rate: labor?.price || "",
				amount: labor?.total || "",
				quantity: 1,
			}));

			setLaborCodeList(mapLabors || []);

			setSincronizeData(true);
		}
	}, [sincronizeData]);

	const setValueInArray = (array, fieldId, elementToUpdate, value) => {
		const arrayCopy = [...array];
		const idFieldIndex = arrayCopy.findIndex(
			(item) => item.field_name === fieldId,
		);
		let item = arrayCopy[idFieldIndex];
		item[`${elementToUpdate}`] = value;
		arrayCopy[idFieldIndex] = item;
		return arrayCopy;
	};

	const onChangeFieldsInitData = (ev) => {
		setInitData({ ...ev });
	};

	const onChangeFieldsInitDataPartNumber = async (event) => {
		setInitDataPartNumber({ ...event });
	};

	const onChangeFieldsInitDataLaborCode = async (event) => {
		setInitDataLabor({ ...event });
	};

	const onChangeFieldsInitDataMessageOnInvoice = async (event) => {
		setInitDataMessageOnInvoice({ ...event });
	};

	const childFunc = useRef(null);
	const childFunc2 = useRef(null);
	const childFunc3 = useRef(null);
	const childFunc4 = useRef(null);

	useEffect(async () => {
		let typesAndValuesSaveDeviceCopy = [...typesAndValuesSaveDeviceFields];
		let deviceTypes = await getListItem("type-devices", "Device Types", {});

		typesAndValuesSaveDeviceCopy = setValueInArray(
			typesAndValuesSaveDeviceCopy,
			"type_resource_id",
			"values",
			deviceTypes,
		);
		setTypesAndValuesSaveDevice(typesAndValuesSaveDeviceCopy);
	}, [sincronizedItems_devices]);

	const mapRequest = (bodyInclude, bodyExt) => {
		let bodyRequest = {};
		Object.keys(bodyExt).map((key) => {
			if (bodyInclude.includes(key)) {
				bodyRequest[key] = bodyExt[key];
			}
		});
		return bodyRequest;
	};

	const onHandleCreateTypeDevice = async (event) => {
		setShowCustomFormModal(false);
		const { id, ...bodyExt } = event;

		let mapBodyRequest = mapRequest(["name"], bodyExt);
		
		return await typeDevice_createItem(mapBodyRequest);
	}

	const onHandleSubmit = async (typeSave) => {
		//test errors form
		const errorsOne = childFunc.current(initData);
		const errorsTwo = childFunc2.current(initDataMessageOnInvoice);

		let errorsFour = [];
		let errorsThree = [];

		if (typeSave === "Add") {
			if (currentTabValue === 0) {
				errorsFour = childFunc4.current(initDataPartNumber);
			}
			if (currentTabValue === 1) {
				errorsThree = childFunc3.current(initDataLabor);
			}
		}

		if (
			((typeSave === "save" || typeSave === "saveAndSend") &&
				!validateLength(errorsOne) &&
				!validateLength(errorsTwo)) ||
			(typeSave === "Add" &&
				!validateLength(errorsOne) &&
				!validateLength(errorsTwo) &&
				!validateLength(errorsFour) &&
				!validateLength(errorsThree))
		) {
			let baseRequest = {
				...initData,
				...initDataMessageOnInvoice,
			};

			if (typeSave === "Add" && currentTabValue === 0) {
				baseRequest = {
					...baseRequest,
					...initDataPartNumber,
				};
			}

			if (typeSave === "Add" && currentTabValue === 1) {
				baseRequest = {
					...baseRequest,
					...initDataLabor,
				};
			}

			if (typeSave === "saveAndSend") {
				baseRequest = {
					...baseRequest,
					save_and_send: 1,
				};
			}

			let response = await updateItem(
				location?.search?.split("=")[1],
				baseRequest,
				"Work Order",
				{},
				true,
				true,
			);

			if (response && response.hasOwnProperty("id")) {
				let typesAndValuesPartNumberValuesCopy = [
					...typesAndValuesPartNumber,
				];
				let typesAndValuesLaborCopy = [...typesAndValuesLabor];
				let initDataPartNumberCopy = { ...initDataPartNumber };
				let initDataLaborCopy = { ...initDataLabor };

				typesAndValuesPartNumberValuesCopy = setValueInArray(
					typesAndValuesPartNumberValuesCopy,
					"item_id",
					"inputValue",
					"",
				);
				typesAndValuesPartNumberValuesCopy = setValueInArray(
					typesAndValuesPartNumberValuesCopy,
					"item_id",
					"doRequest",
					false,
				);
				initDataPartNumberCopy.item_id = "";

				typesAndValuesLaborCopy = setValueInArray(
					typesAndValuesLaborCopy,
					"item_id",
					"inputValue",
					"",
				);
				typesAndValuesLaborCopy = setValueInArray(
					typesAndValuesLaborCopy,
					"item_id",
					"doRequest",
					false,
				);
				initDataLaborCopy.item_id = "";

				setTypesAndValuesPartNumber([]);
				setTypesAndValuesPartNumber(typesAndValuesPartNumberValuesCopy);
				setInitDataPartNumber(initDataPartNumberCopy);

				setTypesAndValuesLabor([]);
				setTypesAndValuesLabor(typesAndValuesLaborCopy);
				setInitDataLabor(initDataLaborCopy);

				setDoRequest(true);
				setSincronizeData(false);
			}

			//addFiles
			const addResponses = await Promise.all(
				itemImageToAdd.map(async (fileItem) => {
					console.log("fileItem", fileItem);
					return await createImageItem(
						"work-orders-documents",
						location?.search?.split("=")[1],
						fileItem,
						"Work Order Document",
						imageFormats.includes(fileItem.type) ? "image" : "file",
						"invoice_id",
					);
				}),
			);

			console.log("addResponses", addResponses);

			//delete files
			const deleteResponses = await Promise.all(
				imageIdsArray
					.filter((id) => parseInt(id))
					.map(async (id) => {
						return await deleteImageItem(
							"work-orders-documents",
							id,
						);
					}),
			);

			console.log("deleteResponses", deleteResponses);
		}
	};

	const buttonClick = (field_id, typeSave = "Add") => {
		if (isLoading) {
			return;
		}

		if (field_id === "createCustomer") {
			history.push({
				pathname: `/costumer_form`,
				data: {
					redirectTo: `/work_order_form_update`,
					work_order_id: location?.search?.split("=")[1],
					keyName: "work_order_id",
				},
			});
		}

		if (
			field_id === "Save" ||
			field_id === "onSubmit" ||
			field_id === "saveAndSend"
		) {
			onHandleSubmit(typeSave);
		}

		if (field_id === "addDevice") {
			setShow(true);
		}
	};

	
	const onChangeSearchInputCustomer = (ev) => {
		let initDataSaveDeviceCopy = { ...initDataSaveDevice };
		let initDataCopy = { ...initData };
		let typesAndValuesSaveDeviceCopy = [...typesAndValuesSaveDevice];

		let typesAndValuesCopy = [...typesAndValues];

		const { name, id, devices } = ev;

		initDataSaveDeviceCopy = {
			...initDataSaveDeviceCopy,
			customer_id: id,
		};

		if (devices && devices.length > 0) {
			initDataCopy = {
				...initDataCopy,
				resource_id: devices[0]?.id || "",
				client_id: id,
			};
		}

		initDataCopy = {
			...initDataCopy,
			client_id: id,
		};

		typesAndValuesSaveDeviceCopy = setValueInArray(
			typesAndValuesSaveDeviceCopy,
			"customer_id",
			"inputValue",
			`${name}`,
		);
		typesAndValuesSaveDeviceCopy = setValueInArray(
			typesAndValuesSaveDeviceCopy,
			"customer_id",
			"doRequest",
			false,
		);
		typesAndValuesSaveDeviceCopy = setValueInArray(
			typesAndValuesSaveDeviceCopy,
			"customer_id",
			"dontInitData",
			true,
		);

		typesAndValuesCopy = setValueInArray(
			typesAndValuesCopy,
			"resource_id",
			"values",
			devices,
		);

		setInitDataSaveDevice(initDataSaveDeviceCopy);
		setTypesAndValuesSaveDevice(typesAndValuesSaveDeviceCopy);

		setTypesAndValues(typesAndValuesCopy);
		setInitData(initDataCopy);
	};

	const onDelete = (event) => {
		setCustomCurrentItem(event.id);
		manageDeleteMessage("Work order part");
		setCustomMessageModal(
			`Are you sure you want to delete the item ${event.part_number} ?`,
		);
		setShowModal(true);
	};

	const onDeleteWorkOrder = (event) => {
		setCustomCurrentItem(event.id);
		manageDeleteMessage("Labor item");
		setCustomMessageModal(
			`Are you sure you want to delete the labor ${event.name} ?`,
		);
		setShowModal(true);
	};

	const prepareDetailToUpdate = (
		index,
		type = "part",
		dataField = null,
		element = null,
	) => {
		if (type === "part") {
			const item = partNumberList.findIndex(
				(itemData) => itemData.id == element.id,
			);
			setCurrentId(item);
			setShowUpdate(true);
		}
		if (type === "labor") {
			const item = laborCodeList.findIndex(
				(itemData) => itemData.id == element.id,
			);
			setCurrentId(item);
			setShowUpdateLabor(true);
		}
	};

	function b64EncodeUnicode(str) {
		return btoa(
			encodeURIComponent(str).replace(
				/%([0-9A-F]{2})/g,
				function (match, p1) {
					return String.fromCharCode("0x" + p1);
				},
			),
		);
	}

	//generador de pdf
	const [PDFModalIsOpen, setPDFModalIsOpen] = useState(false); 
	const [PDFUrl, setPDFUrl] = useState(false); 

	const printPreview = async () => {
		setIsLoading(true);
		setPDFModalIsOpen(true);
		const url = `work-orders/${location?.search?.split("=")[1]}/generate`;
		fetch(`${process.env.REACT_APP_API_URL}api/${url}`, {
			method: "GET",
			mode: "cors",
			headers: new Headers({
				Accept: "application/octet-stream",
				Authorization:
					"Bearer " +
					(localStorage.jwt_token !== undefined
						? JSON.parse(localStorage.jwt_token)
						: ""),
			}),
		})
			.then((response) => response.blob())
			.then((blob) => {
				var fileURL = window.URL.createObjectURL(blob);
				setPDFUrl(fileURL);
			}).finally(()=>{
				setIsLoading(false);
			});
	};

	const cancelInvoice = () => {
		if (changeInForm) {
			setShowModal(true);
			setCustomTittleText(`Leave work order creating`);
			setCustomMessageModal(`Do you want to leave without saving?`);
			setShowSuccessButton(true);
		} else {
			history.push("/invoice");
		}
	};

	const onHandleResponseModal = () => {
		setShowModal(false);
	};

	const onHandleDelete = async () => {
		setShowModal(false);

		if (isImage) {
			deleteImage();
			setIsImage(false);
		} else {
			if (currentTabValue === 0) {
				await deleteItemByUrl(
					customCurrentItem,
					"work-orders-parts",
					true,
				);
			}
			if (currentTabValue === 1) {
				await deleteItemByUrl(
					customCurrentItem,
					"work-orders-labors",
					true,
				);
			}

			setDoRequest(true);
			setCustomCurrentItem(false);
			setSincronizeData(false);
		}
	};

	const tabOneContent = () => {
		return (
			<>
				<div className="row mt-1">
					{typesAndValuesPartNumber.length > 0 &&
						Object.keys(initDataPartNumber).length > 0 && (
							<div className="col-12">
								<CustomForm
									onHandleFormClose={() => {}}
									onHandleSubmit={onHandleSubmit}
									typesAndValuesFields={typesAndValuesPartNumber}
									initData={initDataPartNumber}
									formTittle={""}
									dontShowCancelButton={true}
									id={null}
									getUrlPath={""}
									inputConf={{ marginTop: "1", marginBottom: "0" }}
									buttonClick={buttonClick}
									onChangeFields={onChangeFieldsInitDataPartNumber}
									initOnChangeFields={onChangeFieldsInitDataPartNumber}
									dontShowSubmitButton={true}
									childFunc={childFunc4}
									dontShowErrosinOnChange={true}
									getObjectInfoOnChange={() => {}}
									notifyChange={() => {}}
									handlerSiblings={handlerSiblings}
								/>
							</div>
						)}
				</div>
				<CustomDataList
					onChangePageEvent={() => {}}
					style={styles}
					isLoading={isLoading}
					dataList={partNumberList}
					tableHeaders={tableHeaders}
					itemPagination={null}
					handlePagination={() => {}}
					dontShowPerPageSelect={true}
					showLinearIndicator={true}
					showDataInLoading={true}
					render={(element, index) => (
						<CustomDataListItem
							key={index}
							dataFields={dataFields}
							imageFields={[]}
							composedFields={[]}
							arrayFields={[]}
							element={element}
							index={index}
							no_image={no_image}
							dontShowActions={true}
							showActionsButtons={true}
							deleteRowTable={onDelete}
							renderActions={() => <></>}
							renderComponentInField={(data, dataField, element) => (
								<>
									<MaterialUI.Button.Primary 
										className="mw-auto px-2 me-2"
										onClick={() => prepareDetailToUpdate(data, "part", dataField, element)}
									>
										<i className="fas fa-edit fs-small"></i>
									</MaterialUI.Button.Primary>
									{data}
								</>
							)}
						/>
					)}
				/>
			</>
		);
	};

	const tabTwoContent = () => {
		return (
			<>
				<div className="row mt-1">
					{typesAndValuesLabor.length > 0 &&
						Object.keys(initDataLabor).length > 0 && (
							<div className="col-12">
								<CustomForm
									onHandleFormClose={() => {}}
									onHandleSubmit={onHandleSubmit}
									typesAndValuesFields={typesAndValuesLabor}
									initData={initDataLabor}
									formTittle={""}
									dontShowCancelButton={true}
									id={null}
									getUrlPath={""}
									inputConf={{ marginTop: "1", marginBottom: "0" }}
									buttonClick={buttonClick}
									onChangeFields={onChangeFieldsInitDataLaborCode}
									initOnChangeFields={onChangeFieldsInitDataLaborCode}
									dontShowSubmitButton={true}
									childFunc={childFunc3}
									dontShowErrosinOnChange={true}
									getObjectInfoOnChange={() => {}}
									notifyChange={() => setChangeInForm(true)}
									handlerSiblings={handlerSiblings}
								/>
							</div>
						)}
				</div>
				<CustomDataList
					onChangePageEvent={() => {}}
					style={styles}
					isLoading={isLoading}
					dataList={laborCodeList}
					tableHeaders={tableHeadersLaborCode}
					itemPagination={null}
					handlePagination={() => {}}
					dontShowPerPageSelect={true}
					showLinearIndicator={true}
					showDataInLoading={true}
					render={(element, index) => (
						<CustomDataListItem
							key={index}
							dataFields={dataFieldsLaborCode}
							imageFields={[]}
							composedFields={[]}
							arrayFields={[]}
							element={element}
							index={index}
							no_image={no_image}
							dontShowActions={true}
							showActionsButtons={true}
							deleteRowTable={onDeleteWorkOrder}
							renderActions={() => <></>}
							renderComponentInField={(data, dataField, element) => (
								<>
									<MaterialUI.Button.Primary 
										className="mw-auto px-2 me-2"
										onClick={() => prepareDetailToUpdate(data, "labor", dataField, element)}
									>
										<i className="fas fa-edit fs-small"></i>
									</MaterialUI.Button.Primary>
									{data}
								</>
							)}
						/>
					)}
				/>
				<div className="row mt-1">
					<div className="col-12 col-md-6"></div>
					<div
						className="col-12 col-md-5"
						style={{
							padding: "0 0 0 0",
							display: "grid",
							gridTemplateColumns:
								"repeat(auto-fit, minmax(100px, 1fr) minmax(30px, 0.15fr)",
						}}
					>
						<div
							className=""
							style={{
								display: "grid",
								gridTemplateColumns: "1fr 0.3fr",
								gridTemplateRows: "1fr",
							}}
						>
							<div
								className="letter"
								style={{
									display: "grid",
									justifyContent: "flex-end",
								}}
							>
								Subtotal
							</div>
							<div
								className="letter"
								style={{
									display: "grid",
									justifyContent: "flex-end",
								}}
							>
								$ {laborSubTotal}
							</div>
						</div>
						<div></div>
					</div>
				</div>
			</>
		);
	};

	const onHandleSaveDevice = async (ev) => {
		let initDataCopy = { ...initData };
		let typesAndValuesCopy = [...typesAndValues];

		const response = await createItemDetail(ev, "Device", "devices", true);

		setShow(false);

		if (response && Object.keys(response).length > 0) {
			const { devices } = response.customer;

			typesAndValuesCopy = setValueInArray(
				typesAndValuesCopy,
				"resource_id",
				"values",
				devices,
			);

			const currentItemIndex = devices.findIndex(
				(device) => device.name === ev.name,
			);

			initDataCopy = {
				...initDataCopy,
				resource_id: devices[currentItemIndex].id,
			};
		}

		setTypesAndValues(typesAndValuesCopy);
		setInitData(initDataCopy);
	};

	const handleCreateTypeDevice = () => {
		// console.log("hola")
		setCustomTittleText(`Create Type Device`);
		setAnchorEl(null);
		setShowCustomFormModal(true);
		setCurrentId(null);
	};

	const updateInvoiceDetail = async (ev) => {
		setShowUpdate(false);

		let mapRequest = {
			item_id: ev.item_id,
			price: Number(ev.rate).toFixed(2),
			quantity: ev.quantity,
			comment: ev.description,
		};

		let response = await updateModel(
			ev.id,
			mapRequest,
			"Work Order Part",
			{},
			"work-orders-parts",
			true,
		);

		if (response && response.hasOwnProperty("id")) {
			setDoRequest(true);
			setSincronizeData(false);
		}
	};

	const updateLaborDetail = async (ev) => {
		setShowUpdateLabor(false);

		let mapRequest = {
			invoice_id: location?.search?.split("=")[1],
			item_id: ev.item_id,
			price: Number(ev.rate).toFixed(2),
			quantity: ev.quantity,
			comment: ev.description,
			employee_id: parseInt(ev.employee_id),
			start_date: ev.start_time,
			end_date: ev.end_time,
		};

		let response = await updateModel(
			ev.id,
			mapRequest,
			"Work Order Labor",
			{},
			"work-orders-labors",
			true,
		);

		if (response && response.hasOwnProperty("id")) {
			setDoRequest(true);
			setSincronizeData(false);
		}
	};

	const {
		showCustomFormModal : labor_showCustomFormModal,
		setShowCustomFormModal : labor_setShowCustomFormModal,
	} = useModalHook();

	const {
		showCustomFormModal : parts_showCustomFormModal,
		setShowCustomFormModal : parts_setShowCustomFormModal,
	} = useModalHook();

	const {
		isLoading: labor_isLoading,
		success,
		setSuccess,
		// getListItem,
		// getItem,
		// updateItem,
		// updateModel,
		// deleteItemByUrl,
		// getFile,
		createItem: createLabor,
	} = useApiCustomRequest(
		manageResponseErrors,
		"labors",
		"Labors",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		setGoToMainPage,
	);

	const onChangeFieldsInitDataNewLaborFields = (ev) => {
		setInitDataNewLaborFields_copy({ ...ev });
	};

	const handleCreateLaborOrPart = () => {
		// alert(currentTabValue)
		if(currentTabValue === 0){ //parts
			parts_setShowCustomFormModal(true);
		}else if(currentTabValue === 1){ // labor
			labor_setShowCustomFormModal(true);
		}
	}

	useEffect(() => {
		if(success){
			labor_setShowCustomFormModal(false);
			setSuccess(false);
		}
	},[success])

	//onCreateLabor
	const onCreateLabor = async () => {
		// console.log(initDataNewLaborFields_copy)
		if (initDataNewLaborFields_copy.sku && typeof initDataNewLaborFields_copy.sku === 'string') {
			initDataNewLaborFields_copy.sku = initDataNewLaborFields_copy.sku.trim() || ''; 
		}
	
		const errors = childFunc.current(initDataNewLaborFields_copy);
		console.log(errors)
	
		if (!(errors.length > 0)) {
			let request = {
				...initDataNewLaborFields_copy,
			};
	
			let response = await createLabor(
				request,
				"Product",
				{},
				true,
				false,
			);
	
			if (response && response.hasOwnProperty("id")) {
				// history.push("/labors_list");
			}
		}
	};

	const [initDataNewLaborFields_copy, setInitDataNewLaborFields_copy] = useState(null);
	const [typesAndValuesNewLaborFields_copy, setTypesAndValuesNewLaborFields_copy] = useState(null);
	useEffect(async () => {
		let typesAndValuesFieldsCopy = [...typesAndValuesNewLaborFields];
		let initDataFieldsCopy = { ...initDataNewLaborFields };

		const uom = await getListItem("uom", "Uom", {});

		if (uom.length > 0) {
			typesAndValuesFieldsCopy = setValueInArray(
				typesAndValuesFieldsCopy,
				"uom_id",
				"values",
				uom,
			);
		}

		initDataFieldsCopy = {
			...initDataFieldsCopy,
			uom_id: uom.length > 0 ? uom[0]?.id : "",
		};

		setInitDataNewLaborFields_copy(initDataFieldsCopy);
		setTypesAndValuesNewLaborFields_copy(typesAndValuesFieldsCopy);
	}, []);

	//create parts

	const [modal_add_conf, setModalAddConf] = useState({
		title: "Add new Item",
		message: "",
	});

	const {
		getDocuments,
		deleteDocuments,
		addDocuments,
		isLoading:stock_isLoading,
		setIsLoading:stock_setIsLoading,
		addImages,
		stock_receipt_details,
		setStockReceiptDetails,
		getDataFromUser,
		setStockReceiptId,
		setSincronizedItems: setSincronizedItemsList,
		receipt_stock_number,
		setReceiptStockNumber,
		bill_number,
		setBillNumber,
		vendor_id,
		setVendorId,
		vendor_name,
		setVendorName,
		date,
		setDate,
		date_due,
		setDateDue,
		description,
		setDescription,
		payment,
		setPayment,
		quantity,
		setQuantity,
		cost,
		setCost,
		location_id,
		setLocationId,
		location_name,
		setLocationName,
		notes,
		setNotes,
		atached_document,
		setAtachedDocument,
		total_receipt,
		setTotalReceipt,
		tax_receipt,
		setTaxReceipt,
		vendors,
		setVendors,
		location_list,
		setLocationList,
		insertVendor,
		payment_number,
		setPaymentNumber,
		part_number_id,
		setPartNumberId,
		part_number_name,
		setPartNumberName,
		disabledActions,
		setDisabledActions,
		updateStockReceiptItemPostStatus,
		updateStockReceiptPostStatus,
		stockReceiptStatus,
		skuNumber,
		setSkuNumber,
		saveStockReceiptModel,
		saveStockReceiptItem,
		updateStockReceiptItem,
		currentStockReceiptCost
	} = useApirequestStockReceipt(
		setCustomTittleText,
		setCustomMessageModal,
		showSuccessButton,
		setShowModal,
	);

	const {
		filteredData: item_list,
		setSincronizedItems,
		getItemList,
		itemsNotFound,
		getSkuItemInformation,
	} = useApiRequestItems(
		stock_setIsLoading,
		setPaymentNumber,
		setPartNumberId,
		setPartNumberName,
		setPartNumberId,
		setPartNumberName,
	);

	const [template_errors, setTemplateErrors] = useState([]);
	const [template_errors_all, setTemplateErrorsAll] = useState([]);
	
	function validateFields() {
		setTemplateErrors([]);

		//validamos que existan espacios vacios

		let nedd_to_validate = false;

		if (skuNumber.toString().trim() !== "") {
			nedd_to_validate = true;
		}
		if (location_name.toString().trim() !== "") {
			nedd_to_validate = true;
		}
		if (parseFloat(cost) > 0 || cost.toString().trim() !== "") {
			nedd_to_validate = true;
		}

		if (!nedd_to_validate) {
			return false;
		}

		let falidate_fields_errors = [];

		// if(part_number_id.toString().trim() === '' ){

		// 		falidate_fields_errors.push({
		// 				'field_id' : 'part_number_id',
		// 				'message'  : 'Part Number need to be valid'
		// 		});

		// }

		if (skuNumber.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "part_number_name",
				message: "Part Number need to be valid",
			});
		}

		if (item_list.length > 0) {
			let exists_item = item_list.find(
				(element) => element.name === part_number_name.toString(),
			);

			if (exists_item === undefined) {
				falidate_fields_errors.push({
					field_id: "part_number_name",
					message: "Part number entered not exists",
				});
			}
		}

		if (quantity <= 0) {
			falidate_fields_errors.push({
				field_id: "quantity",
				message: "Quantity need to be greater than 0",
			});
		}

		if (parseFloat(cost) <= 0 || cost.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "cost",
				message: "Cost need to be greater than 0",
			});
		}

		if (falidate_fields_errors.length > 0) {
			setTemplateErrors(falidate_fields_errors);

			return false;
		}

		return true;
	}

	function addNewStockReceiptDetail(
		itemId,
		itemName,
		validateField = true,
		currentCost = null,
	) {
		if (validateField) {
			if (!validateFields()) {
				return false;
			}

			let exists_same_item = stock_receipt_details.find(
				(element) =>
					element?.item_id === itemId &&
					element?.location_id === location_id &&
					element?.cost === cost &&
					element?.quantity === quantity,
			);

			if (exists_same_item) {
				console.log("exists_same_item", exists_same_item);

				manageResponseErrors(
					"The item is already added",
					"Stock Receipt Items",
					"updating",
				);

				return false;
			}
		}
	}

	const createNewStockReceipt = async (
		dataId,
		dataName,
		stockReceiptId,
		errorCreating = false,
		currentCost = null,
		validateField = true,
	) => {
		let newStockReceiptItem = addNewStockReceiptDetail(
			dataId,
			dataName,
			validateField,
			currentCost,
		);
	}

	const handleCloseAdd = async (currentSku, currentCost) => {

		if (skuNumber || currentSku) {
			// setIsLoading(true);
			const { data } = await getSkuItemInformation(
				skuNumber ? skuNumber : currentSku,
			);

			if (!(Object.keys(data).length === 0)) {
				await createNewStockReceipt(
					data.id,
					data.name,
					receipt_stock_number,
					false,
					currentCost,
					false, //validate fields
				);
				setSincronizedItemsList(false);
				parts_setShowCustomFormModal(false);

			}
			stock_setIsLoading(false);
		}
	};

	const getElementWhenAddNewItem = () => {
		stock_setIsLoading(true);
		setSincronizedItems(false);
	};

	const [showPanelCustomer, setShowPanelCustomer] = useState(false);
  const toggleShowPanelCustomer = () => setShowPanelCustomer((state) => !state);
	const handleResponseCreateCustomer = (customer) => {
		onChangeSearchInputCustomer(customer);
		toggleShowPanelCustomer();
	}
	const handlerSiblings = (prop = {}) => {
		switch (prop.panel) {
			case PanelNames.PANEL_CUSTOMER:
				toggleShowPanelCustomer();
				break;
			case PanelNames.PANEL_PRODUCT:
				parts_setShowCustomFormModal(true);
				break;
			case PanelNames.PANEL_DEVICE:
				setShow(true);
				break;
			case PanelNames.PANEL_LABOR:
				labor_setShowCustomFormModal(true);
				break;
			case PanelNames.PANEL_TYPE_DEVICE:
				handleCreateTypeDevice();
				break;
			default:
				break;
		}
	}

	return (
		<>
			<Panel open={showPanelCustomer} anchor="right" togglePanel={toggleShowPanelCustomer} headerTitle="New Client">
        <CostumerForm handleResponse={handleResponseCreateCustomer} />
      </Panel>
			 	
			<CustomModal
				show={showCustomFormModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowCustomFormModal(false)}
				onHandleSuccess={() => {}}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
			>
				<CustomForm
					onHandleFormClose={() => setShowCustomFormModal(false)}
					location_list={[]}
					onHandleSubmit={onHandleCreateTypeDevice}
					typesAndValuesFields={typesAndValuesTypeDevice}
					initData={initDataTypeDevice}
					formTittle={""}
					id={null}
					getUrlPath={`api/type-devices/`}
					successStyleButtonText={"Save"}
					typeButton={"success"}
					handlerSiblings={handlerSiblings}
				/>
			</CustomModal>
			<div
				className="contenedor container-fluid"
				style={{ position: "relative" }}
			>
				<div className="container-title mb-2">
					<h1 className="container__title mb-0">
						{props.actionTittle + " " + props.model} #{" "}
						{location?.search?.split("=")[1]}
					</h1>
					<div className="action-buttons-container">
						<div className="save-buttton">
							<div className="save-buttton">
								<CustomButton
									style={{ paddingInline: '1rem', fontSize: '13px' }}
									buttonName={"Save"}
									buttonOnClick={() => buttonClick("Save", "save")}
									type={"success"}
									icon={""}
									disabled={false}
									margins={"mt-0 mb-0"}
								/>
							</div>
						</div>
						<div className="send-button">
							<CustomButton
								style={{ paddingInline: '1rem', fontSize: '13px' }}
								buttonName={"Save and send"}
								buttonOnClick={() => buttonClick("Save", "saveAndSend")}
								type={"success"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
							/>
						</div>
						<div className="print-button">
							<CustomButton
								style={{ paddingInline: '1rem', fontSize: '13px' }}
								buttonName={"Print or Preview"}
								buttonOnClick={printPreview}
								type={"secondary"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
							/>
						</div>
						<div className="cancel-button">
							<CustomButton
								style={{ paddingInline: '1rem', fontSize: '13px' }}
								buttonName={"Cancel"}
								buttonOnClick={cancelInvoice}
								type={"secondary"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
							/>
						</div>
					</div>
				</div>

				<div className="row mb-2">
					{typesAndValues.length > 0 &&
						Object.keys(initData).length > 0 && (
							<>
								<div className="col-12">
									<CustomForm
										onHandleFormClose={() => {}}
										onHandleSubmit={onHandleSubmit}
										typesAndValuesFields={typesAndValues}
										initData={initData}
										formTittle={""}
										dontShowCancelButton={true}
										id={null}
										getUrlPath={""}
										inputConf={{
											marginTop: "1",
											marginBottom: "0",
											stringCol:"col-6 col-md-4 col-lg-2"
										}}
										buttonClick={buttonClick}
										onChangeFields={onChangeFieldsInitData}
										initOnChangeFields={onChangeFieldsInitData}
										dontShowSubmitButton={true}
										childFunc={childFunc}
										dontShowErrosinOnChange={true}
										getObjectInfoOnChange={onChangeSearchInputCustomer}
										notifyChange={() =>setChangeInForm(true)}
										handlerSiblings={handlerSiblings}
									/>
								</div>
							</>
						)}
				</div>
				<div className="row">
					<TabsComponent
						renderTabOne={tabOneContent}
						renderTabTwo={tabTwoContent}
						onChangeTab={(value) => setCurrentTabValue(value)}
					/>
				</div>
				{typesAndValuesMessageOnInvoice.length > 0 &&
					Object.keys(initDataMessageOnInvoice).length > 0 && (
						<div className="row">
							<div className="col-12 col-md-3">
								<CustomForm
									onHandleFormClose={() => {}}
									onHandleSubmit={onHandleSubmit}
									typesAndValuesFields={typesAndValuesMessageOnInvoice}
									initData={initDataMessageOnInvoice}
									formTittle={""}
									dontShowCancelButton={true}
									id={null}
									getUrlPath={""}
									inputConf={{
										marginTop: "1",
										marginBottom: "0",
										stringCol: "col-12 col-sm-11 col-md-12",
									}}
									buttonClick={buttonClick}
									onChangeFields={onChangeFieldsInitDataMessageOnInvoice}
									initOnChangeFields={onChangeFieldsInitDataMessageOnInvoice}
									dontShowSubmitButton={true}
									childFunc={childFunc2}
									dontShowErrosinOnChange={true}
									notifyChange={() => setChangeInForm(true)}
								/>
							</div>
							<div className="col-12 col-md-6">
								<div className='mx-0 mx-md-1 w-100'>
									<div className="content-notes"><label className="form-label mb-0">Files</label></div>
									<Box sx={{ overflow: "auto" }} className="p-1 border scroll_customized rounded-1">
										<CustomImageViewer
											itemData={documentsImageArray}
											onClickImage={() => {}}
											onDeleteImage={onDeleteImage}
											showDefaultPdfIcon={true}
											showDowloadAction={true}
										>
											<div 
												className="user-select-none p-3 rounded-2 d-flex flex-column align-items-center justify-content-center"
												style={{ width: '95px', height: 'auto', border: '2px dashed #3f51b5', backgroundColor: 'rgb(63 81 181 / 15%)', color: '#3f51b5', cursor: 'pointer' }}
												onClick={() => openFileImage()}
											>
												<i className="fas fa-plus mb-2 fs-small"></i>
												<p className="mb-0" style={{ fontSize: '12px', lineHeight: '1', textAlign: 'center' }}>Attach documents</p>
												<input
													hidden
													id="file_input_doc_input"
													type="file"
													onChange={(e) => setFileImage(e)}
													accept="image/*,.pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx"
													multiple
												/>
											</div>
										</CustomImageViewer>
									</Box>
								</div>
							</div>
							<div className="col-12 col-md-3">
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
										gap:"10px"
									}}
								>
									<div className="letter">Subtotal</div>
									<div className="letter">$ {partSubTotal}</div>
								</div>
							</div>
						</div>
					)}

				<CustomModal
					show={showModal}
					customTittleText={customTittleText}
					customMessageModal={customMessageModal}
					onHandleClose={onHandleResponseModal}
					onHandleSuccess={() => !customCurrentItem ? history.push("/invoice") : onHandleDelete()}
					isLoading={isLoading}
					showSuccessButton={showSuccessButton}
					actionsFooterStyle={true}
					cancelText={cancelButtonText}
					successText={sucessButtonText}
				/>

				{/* modal form Device */}
				<CustomSideModal
					show={show}
					customTittleText={"New Device"}
					customMessageModal={""}
					onHandleClose={() => setShow(false)}
					onHandleSuccess={() => {}}
					isLoading={false}
					showSuccessButton={true}
					size={"lg"}
				>
					<CustomForm
						onHandleFormClose={() => setShow(false)}
						location_list={[]}
						onHandleSubmit={onHandleSaveDevice}
						typesAndValuesFields={typesAndValuesSaveDevice}
						initData={initDataSaveDevice}
						formTittle={""}
						actionsFooterStyle={true}
						cancelStyleButtonText={"Cancel"}
						successStyleButtonText={"Save"}
						actionButonsContainerWidth={"100%"}
						buttonClick={handleCreateTypeDevice}
						inputConf={{ stringCol:"col-12 col-md-6" }}
						getObjectInfoOnChange={onChangeSearchInputCustomer}
					/>
				</CustomSideModal>

				{/* modal form update Part */}
				<CustomModal
					show={showUpdate}
					customTittleText={""}
					customMessageModal={""}
					onHandleClose={() => setShowUpdate(false)}
					onHandleSuccess={() => {}}
					isLoading={false}
					showSuccessButton={true}
				>
					<CustomForm
						onHandleFormClose={() => setShowUpdate(false)}
						location_list={[]}
						onHandleSubmit={updateInvoiceDetail}
						typesAndValuesFields={typesAndValuesUpdatePart}
						initData={partNumberList[currentId]}
						formTittle={""}
					/>
				</CustomModal>

				{/* modal form update Labor */}
				<CustomModal
					show={showUpdateLabor}
					customTittleText={""}
					customMessageModal={""}
					onHandleClose={() => setShowUpdateLabor(false)}
					onHandleSuccess={() => {}}
					isLoading={false}
					showSuccessButton={true}
				>
					<CustomForm
						onHandleFormClose={() => setShowUpdateLabor(false)}
						location_list={[]}
						onHandleSubmit={updateLaborDetail}
						typesAndValuesFields={typesAndValuesUpdateLabor}
						initData={laborCodeList[currentId]}
						formTittle={""}
					/>
				</CustomModal>
				
				{/* create labor modal */}
				<CustomSideModal
					size="lg"
					show={labor_showCustomFormModal}
					customTittleText={"Create New Labor"}
					customMessageModal={customMessageModal}
					onHandleClose={() => labor_setShowCustomFormModal(false)}
					onHandleSuccess={() => {}}
					isLoading={isLoading}
					showSuccessButton={showSuccessButton}
				>
					<CustomForm
						onHandleFormClose={() => labor_setShowCustomFormModal(false)}
						location_list={[]}
						onHandleSubmit={onCreateLabor}
						typesAndValuesFields={typesAndValuesNewLaborFields_copy}
						initData={initDataNewLaborFields_copy}
						formTittle={""}
						id={null}
						getUrlPath={`api/Labors/`}
						successStyleButtonText={"Save"}
						typeButton={"success"}
						inputConf={{
							stringCol:"col-12 col-sm-6"
						}}
						onChangeFields={
							onChangeFieldsInitDataNewLaborFields
						}
						
					/>
				</CustomSideModal>

				{/* create parts modal */}
				<ModalAddItem
					handleCloseAdd={() => parts_setShowCustomFormModal(false)}
					getElementWhenAddNewItem={getElementWhenAddNewItem}
					modal_conf={modal_add_conf}
					onHandleClose={handleCloseAdd}
					show={parts_showCustomFormModal}
					skuNumber={skuNumber}
				/>
			</div>
			<PDFModal title={"WorkOrder PDF"} isOpen={PDFModalIsOpen} closeModal={() => setPDFModalIsOpen(false)} pdfUrl={PDFUrl} isLoading={isLoading}/>
		</>
	);
}

export { WorkOrderFormUpdate };
