export default class NumberFormat {
  static currency(amount = 0, options = {}) {
    const {
      locale = "en-US",
      style = "currency",
      currency = "USD",
      minimumFractionDigits = 2,
    } = options;
		const format = new Intl.NumberFormat(locale, {minimumFractionDigits, style, currency});
		return format.format(amount);
  }
}