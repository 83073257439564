import React, { useContext } from 'react'
import { PosDesktopContext } from '../../context/PosDesktopContext';
import PosDesktopPaymentForm from './PosDesktopPaymentForm';
import ReceiptOptions from '../Receipt/ReceiptOptions';

const PosDesktopVoucherOptions = () => {
  const { resetState, setShowVoucherOptions, showFormPayment, currentReceipt, isReceiptRegister } = useContext(PosDesktopContext);

  const resetView = () => {
    resetState();
    setShowVoucherOptions(false);
  }

  return (
    <>
      {showFormPayment ? (
        <PosDesktopPaymentForm />
      ) : (
        <ReceiptOptions 
          onDone={resetView}
          onSendMail={() => {}}
          onSendMessage={() => {}}
          receipt={currentReceipt}
          registered={isReceiptRegister}
        />
      )}
    </>
  )
}

export default PosDesktopVoucherOptions