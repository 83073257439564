import React from 'react'
import { Grid } from '@material-ui/core'

import { CashCounterContext } from '../../context/CashCounterContext';
import MaterialUI from '../../components/MaterialUI'
import InputFormat from '../../utils/input.format';

const options = [
  { label: "Cash", value: true },
  { label: "Other", value: false },
];

const CashCounterFormMovement = ({ onSuccess, onClose }) => {
  const { onChangeTransaction, storeTransaction, updateTransaction, transaction, errors, loading, setLoading } = React.useContext(CashCounterContext);

  const handleCancelClick = () => {
    if(onClose && onClose instanceof Function)
      onClose();
  }

  const handleSubmit = async () => {
    if(loading) return;
    try {
      setLoading(true);
      const callback = transaction.cash_count_transaction_id ? updateTransaction : storeTransaction;
      const resp = await callback();
      if(resp.status && onSuccess && onSuccess instanceof Function) {
        onSuccess(resp.msg);
      }
    } catch (error) {}
    finally {
      setLoading(false);
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <MaterialUI.Select
          label={"Type"}
          options={options}
          state={transaction.cash_in}
          setState={onChangeTransaction('cash_in')}
          error={errors.cash_in}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialUI.Input
          type='number'
          label={"Amount"}
          state={transaction.amount}
          setState={onChangeTransaction('amount')}
          formatter={InputFormat.limitDecimalPlaces()}
          error={errors.amount}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialUI.Input
          rows={3}
          multiline
          label={"Description"}
          state={transaction.note}
          setState={onChangeTransaction('note')}
          error={errors.note}
        />
      </Grid>
      <Grid item xs={12} className="text-end">
        <MaterialUI.Button.Secondary
          className="fs-small me-2"
          style={{ inlineSize: '100px' }}
          onClick={handleCancelClick}
        >
          Cancel
        </MaterialUI.Button.Secondary>
        <MaterialUI.Button.Success
          className="fs-small"
          style={{ inlineSize: '100px' }}
          onClick={handleSubmit}
          disabled={loading}
        >
          <i className="fas fa-paper-plane me-2"></i>{!!transaction.cash_count_transaction_id ? 'Update' : 'Save'}
        </MaterialUI.Button.Success>
      </Grid>
    </Grid>
  )
}

export default CashCounterFormMovement;