import React from 'react'
import { PermissionContext } from '../../context/PermissionContext';

export const Can = ({ user, children }) => {
  const [isAllowed, setIsAllowed] = React.useState(false);
  const { permissions } = React.useContext(PermissionContext);
  
  React.useEffect(() => {
    setIsAllowed(true);
  }, []);

  if(!isAllowed) return null;
  return children;
}
