import React from "react";
import AutocompleteMiu from "@material-ui/lab/Autocomplete";
import { FormGroup, FormHelperText, makeStyles } from "@material-ui/core";
import MaterialUI from ".";

const useStyles = makeStyles({
  textError: {
    fontSize: '.7rem',
    marginBlockStart: 0
  }
});

export default function Autocomplete({
	label,
	optionsList = [],
	state,
	error,
	setState,
	keyName,
	children,
	...others
}) {
  const classes = useStyles();
	const handleChange = (_, newValue) => setState(newValue);
	const hasError = !!error;

	return (
		<div className="w-100">
			{!!children ? (
				<FormGroup className="flex-row flex-nowrap">
					<AutocompleteMiu
						{...others}
						fullWidth
						size="small"
						renderInput={(params) => (
							<MaterialUI.Input
								{...params}
								label={label}
								variant="outlined"
							/>
						)}
						options={optionsList}
						getOptionLabel={(option) => option?.label ?? ""}
						getOptionSelected={(option, value) => option?.id === value?.id}
						value={state}
						onChange={handleChange}
					/>
					{children}
				</FormGroup>
			) : (
				<AutocompleteMiu
					{...others}
					fullWidth
					size="small"
					renderInput={(params) => (
						<MaterialUI.Input
							{...params}
							label={label}
							variant="outlined"
						/>
					)}
					options={optionsList}
					getOptionLabel={(option) => option.label}
					getOptionSelected={(option, value) =>
						option.id === value.id
					}
					value={state}
					onChange={handleChange}
				/>
			)}
			{hasError && <FormHelperText className={classes.textError} error>{error}</FormHelperText>}
		</div>
	);
}
