import { useState, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import { CustomForm } from "../../components/CustomForm/CustomForm";
import MenuItem from "@material-ui/core/MenuItem";
import no_image from "../../assets/img/no_image.png";
import {
	faTrashAlt,
	faEdit,
	faTrashRestore,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomFilterComponent } from "../../components/CustomFiltersComponent/CustomFiltersComponent";
import {
	CustomSelect,
	CustomInputFilter,
} from "../../components/customSelect/customSelect";

//CustomHooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useMenuActionHook } from "../../customHooks/useMenuActionHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";
import { usePaginationHook } from "../../customHooks/usePaginationHook";

import {
	styles,
	blockInvalidChar,
	setValueInArray,
} from "../../context/constants";

//style
import "./costumerTable.css";
import { FaCaretDown, FaLongArrowAltRight } from "react-icons/fa";
import { Button, List, ListItem, ListItemText, Menu } from "@material-ui/core";

function ListComponentWithPagination(props) {
	const history = useHistory();
	const location = useLocation();

	//dataList Implementation
	const {
		anchorEl,
		setAnchorEl,
		onCloseModal,
		currentId,
		setCurrentId,
		currentElement,
		setCurrentElement,
	} = useMenuActionHook();

	const {
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		showSuccessButton,
		manageResponseErrors,
		manageDeleteMessage,
		manageResponse,
		setCustomMessageModal,
		cancelButtonText,
		sucessButtonText,
	} = useModalHook();

	const {
		isLoading,
		filtered_data,
		setSincronizedItems,
		deleteItem: deleteModel,
		itemFilters,
		setItemFilters,
		itemPagination,
		getListItem,
		createItemDetail,
	} = useApiCustomRequest(
		manageResponseErrors,
		props.apiUrl,
		props.model,
		manageResponse,
		false,
		false,
		false,
		props.parseData,
		props.parseFunction,
		false,
		props.initParams,
	);

	const {
		filterInputChange,
		selectOnChange,
		onChangeNumberSelect,
		handlePagination,
		filtersDefinition,
		setFiltersDefition,
		onChangeDateFilter,
		onChangeOrderArrow,
	} = usePaginationHook(setSincronizedItems, setItemFilters, itemFilters);

	useEffect(() => {
		setSincronizedItems(false);
	}, [props.reload]);

	const [showChangePackageModal, setShowChangePackageModal] = useState(false);

	//handlers dataList
	const deleteItem = async () => {
		setShowModal(false);
		return await deleteModel(currentId);
	};

	const onMenuActions = async (event, id) => {
		setCurrentId(id);
		setAnchorEl(event.currentTarget);
	};

	const onDelete = (event) => {
		setAnchorEl(null);
		manageDeleteMessage(props.model);
		setCustomMessageModal(
			`Are you sure you want to delete item #${currentId}?`,
		);
		setShowModal(true);
	};

	const onDeleteMobileTable = (currentId) => {
		setCurrentId(currentId);
		setAnchorEl(null);
		manageDeleteMessage(props.model);
		setCustomMessageModal(
			`Are you sure you want to delete item #${currentId}?`,
		);
		setShowModal(true);
	};

	const onEdit = () => {
		setAnchorEl(null);

		let redirectObject = {};

		redirectObject = {
			pathname: `${props.pathname}`,
			search: `?${props.pathSearchId}=${currentId}`,
		};

		if (
			props.hasOwnProperty("typeRedirect") &&
			props?.typeRedirect === "User"
		) {
			redirectObject = {
				pathname: `${props.pathname}` + currentElement.id,
				state: { user: currentElement, titulo: "Edit User" },
			};
		}

		history.push(redirectObject);
	};

	const onRestore = async () => {
		setAnchorEl(null);
		await getListItem(`restore_company/${currentId}`, "Company", {});
		setSincronizedItems(false);
	};

	let actionsDefinition = [
		{
			name: "Edit",
			func: onEdit,
			icon: faEdit,
		},
		{
			name: "Delete",
			func: onDelete,
			icon: faTrashAlt,
		},
	];

	if (
		props.hasOwnProperty("isCompanyList") &&
		props?.isCompanyList &&
		props.hasOwnProperty("isDeleteCompanies") &&
		props?.isDeleteCompanies
	) {
		actionsDefinition = [
			{
				name: "Restore Company",
				func: onRestore,
				icon: faTrashRestore,
			},
		];
	}

	useEffect(() => {
		setFiltersDefition([...(props.filtersArray || [])]);
	}, []);

	const [
		changePackageTypesAndValuesFields,
		setChangePackageTypesAndValuesFields,
	] = useState([]);
	const changePackageTypesAndValuesFieldsInit = [
		{
			field_name: "package_id",
			type_field: "select_input",
			text_label: "Package",
			placeHolder: "select a package",
			dontDefaultOption: true,
			regexPattern: /^[a-z0-9]+$/i,
			values: [{ id: "1", name: "package test" }],
			selectCol: "col-12",
			selectWidth: "100%",
			// minLength: 1,
			col: "col-12 col-md-12",
			required: true,
		},
		{
			field_name: "amount",
			type_field: "text_input",
			text_label: "Amount",
			disabled: false,
			regexPattern: /^[0-9]*\.?[0-9]*$/,
			maxLength: 10,
			minLength: 1,
			col: "col-6 col-md-6",
			type: "number",
			onKeyDown: blockInvalidChar,
			required: true,
		},
		{
			field_name: "package_type",
			type_field: "select_input",
			text_label: "Package Type",
			placeHolder: "select a package type",
			dontDefaultOption: true,
			regexPattern: /^[a-z0-9]+$/i,
			values: [
				{ id: "monthly", name: "Monthly" },
				{ id: "annual", name: "Annual" },
			],
			selectCol: "col-12",
			selectWidth: "100%",
			// minLength: 1,
			col: "col-6 col-md-6",
			required: true,
		},
		{
			field_name: "pay_date",
			type_field: "text_input",
			text_label: "Pay Date",
			disabled: false,
			regexPattern: /^\d{4}[./-]\d{2}[./-]\d{2}$/,
			type: "date",
			col: "col-6 col-md-6",
			required: true,
		},
		{
			field_name: "next_pay_date",
			type_field: "text_input",
			text_label: "Next Pay Date",
			disabled: false,
			regexPattern: /^\d{4}[./-]\d{2}[./-]\d{2}$/,
			type: "date",
			col: "col-6 col-md-6",
			dateGraterThan: "pay_date",
			required: true,
		},
		{
			field_name: "payment_method_id",
			type_field: "select_input",
			text_label: "Choose Payment Method",
			placeHolder: "select a payment method one",
			dontDefaultOption: true,
			regexPattern: /^$|^[a-z0-9]+$/i,
			values: [],
			selectCol: "col-12",
			selectWidth: "100%",
			// minLength: 1,
			col: "col-12 col-md-12",
			required: true,
		},
		{
			field_name: "addPaymentMethod",
			type_field: "button",
			text_label: "Method",
			type: "success",
			icon: "fa fa-plus",
			margins: "mt-0 mb-2",
			disabled: false,
			className: "",
			dotShowLabel: true,
			onclickHandler: () => {},
			col: "col-3 col-md-3",
		},
	];

	const [changePackageInitData, setChangePackageInitData] = useState({});
	const changePackageInitDataInit = {
		package_id: "",
		amount: "",
		package_type: "monthly",
		pay_date: "",
		next_pay_date: "",
		payment_method_id: "",
	};

	const onChangeFieldsInitDataChangePackage = (ev) => {
		setChangePackageInitData({ ...ev });
	};

	useEffect(async () => {
		if (props.hasOwnProperty("isCompanyList") && props?.isCompanyList) {
			let changePackageTypesAndValuesFieldsInitCopy = [
				...changePackageTypesAndValuesFieldsInit,
			];
			let changePackageInitDataInitCopy = {
				...changePackageInitDataInit,
			};

			let packages = await getListItem("packages", "Packages", {});
			let typePayment = await getListItem(
				"type-payment",
				"Type Payment",
				{},
			);

			changePackageTypesAndValuesFieldsInitCopy = setValueInArray(
				changePackageTypesAndValuesFieldsInitCopy,
				"package_id",
				"values",
				packages || [],
			);
			changePackageTypesAndValuesFieldsInitCopy = setValueInArray(
				changePackageTypesAndValuesFieldsInitCopy,
				"payment_method_id",
				"values",
				typePayment || [],
			);

			setChangePackageTypesAndValuesFields(
				changePackageTypesAndValuesFieldsInitCopy,
			);
			setChangePackageInitData(changePackageInitDataInitCopy);
		}
	}, [props]);

	const [currentCompany, setCurrentCompany] = useState(null);
	const [currentPackage, setCurrentPackage] = useState(null);

	useEffect(async () => {
		let changePackageTypesAndValuesFieldsInitCopy = [
			...changePackageTypesAndValuesFields,
		];
		let changePackageInitDataInitCopy = { ...changePackageInitData };

		let offlinePaymentMethods = [];
		if (currentCompany) {
			offlinePaymentMethods = await getListItem(
				`offline-payment-methods`,
				"Payment Methods",
				{
					company_id: currentCompany,
				},
			);

			changePackageTypesAndValuesFieldsInitCopy = setValueInArray(
				changePackageTypesAndValuesFieldsInitCopy,
				"payment_method_id",
				"values",
				offlinePaymentMethods || [],
			);
		}

		setCurrentCompany(null);

		changePackageInitDataInitCopy = {
			...changePackageInitDataInitCopy,
			package_id: currentPackage ? currentPackage : "",
		};

		if (currentElement) {
			const { last_invoice } = currentElement;

			if (last_invoice) {
				const {
					next_pay_date,
					offline_method_id,
					pay_date,
					amount,
					package_type,
				} = last_invoice;

				changePackageInitDataInitCopy = {
					...changePackageInitDataInitCopy,
					next_pay_date: next_pay_date.split(" ")[0] || "",
					payment_method_id:
						offline_method_id ||
						(offlinePaymentMethods.length > 0
							? offlinePaymentMethods[0]?.id
							: ""),
					pay_date: pay_date.split(" ")[0] || "",
					amount: amount || "",
					package_type: package_type || "",
				};
			}
		}

		setChangePackageTypesAndValuesFields(
			changePackageTypesAndValuesFieldsInitCopy,
		);
		setChangePackageInitData(changePackageInitDataInitCopy);
	}, [currentCompany, currentPackage]);

	const onSubmitChangePackage = async (ev) => {
		if (isLoading) return;

		let baseRequest = {
			...ev,
			company_id: currentId,
		};

		const response = await createItemDetail(
			baseRequest,
			"Change Package",
			"change_package",
			true,
			"changing",
			true,
		);

		if (response && Object.keys(response).length > 0) {
			setCurrentId(null);
			setShowChangePackageModal(false);
			setSincronizedItems(false);
		}
	};

	//payment method modal
	const [showCreatePaymentMethodModal, setShowCreatePaymentMethodModal] =
		useState(false);
	const [sincronizedPaymentMethod, setSincronizedPaymentMethod] =
		useState(true);
	const [paymentCreatedId, setPaymentCreatedId] = useState(null);

	const buttonClick = (field_id, typeSave = "Add") => {
		if (field_id === "addPaymentMethod") {
			setShowCreatePaymentMethodModal(true);
		}
	};

	const paymentPaymentTypesAndValuesFieldsInit = [
		{
			field_name: "name",
			type_field: "text_input",
			text_label: "Name",
			disabled: false,
			regexPattern: /^[0-9A-Za-z\s\-]+$/,
			maxLength: 50,
			// minLength: 1
		},
		{
			field_name: "description",
			type_field: "text_input",
			text_label: "Description",
			disabled: false,
			regexPattern: /^[0-9A-Za-z\s\-]+$/,
			maxLength: 50,
			// minLength: 1
		},
		{
			field_name: "status",
			type_field: "select_input",
			text_label: "Status",
			placeHolder: "select a status",
			dontDefaultOption: true,
			regexPattern: /^[a-zA-Z0-9_ ]+$/i,
			values: [
				{ id: "active", name: "Active" },
				{ id: "inactive", name: "Inactive" },
			],
			selectCol: "col-12",
			selectWidth: "100%",
			// minLength: 1,
			col: "col-12 col-md-6",
		},
	];

	const paymentPaymentInitData = {
		id: "",
		name: "",
		description: "",
		status: "active",
	};

	const onSubmitCreatePayment = async (ev) => {
		if (isLoading) return;

		const response = await createItemDetail(
			ev,
			"Offline Payment Methods",
			"offline-payment-methods",
			true,
			"creating",
			false,
		);

		if (response && Object.keys(response).length > 0) {
			setPaymentCreatedId(response?.id);
			setSincronizedPaymentMethod(false);
			setShowCreatePaymentMethodModal(false);
		}
	};

	useEffect(async () => {
		if (!sincronizedPaymentMethod) {
			let changePackageTypesAndValuesFieldsInitCopy = [
				...changePackageTypesAndValuesFields,
			];
			let changePackageInitDataInitCopy = { ...changePackageInitData };

			const offlinePaymentMethods = await getListItem(
				`offline-payment-methods`,
				"Payment Methods",
				{
					company_id: currentCompany,
				},
			);

			changePackageTypesAndValuesFieldsInitCopy = setValueInArray(
				changePackageTypesAndValuesFieldsInitCopy,
				"payment_method_id",
				"values",
				offlinePaymentMethods || [],
			);

			changePackageInitDataInitCopy = {
				...changePackageInitDataInitCopy,
				payment_method_id: paymentCreatedId,
			};

			setChangePackageTypesAndValuesFields(
				changePackageTypesAndValuesFieldsInitCopy,
			);
			setChangePackageInitData(changePackageInitDataInitCopy);
			setSincronizedPaymentMethod(true);
		}
	}, [sincronizedPaymentMethod]);

	const [optionListIndex, setOptionListIndex] = useState(null); // Estado para almacenar el índice del elemento con lista de opciones abierto

    const handleOptionListToggle = (index) => {
        setOptionListIndex(index === optionListIndex ? null : index); // Alternar la apertura y cierre de la lista de opciones
    };
	
	const [openItem, setOpenItem] = useState(null);
	const [anchorOpenItem, setAnchorOpenItem] = useState(null);

  const handleClick = (event, id) => {
		setOpenItem(openItem === id ? null : id);
		setAnchorOpenItem(event.currentTarget)
  };

  const handleClose = () => {
		setAnchorOpenItem(null);
    setOpenItem(null);
  };

	return (
		<>
			<div className="container-fluid contenedor">
				<CustomModal
					show={showModal}
					customTittleText={customTittleText}
					customMessageModal={customMessageModal}
					onHandleClose={() => setShowModal(false)}
					onHandleSuccess={deleteItem}
					isLoading={isLoading}
					showSuccessButton={showSuccessButton}
					actionsFooterStyle={true}
					cancelText={cancelButtonText}
					successText={sucessButtonText}
				/>
				<div className="container-title mb-4">
					<h1 className="container__title mb-0">{props.model}</h1>
				</div>
				<CustomFilterComponent
					filtersDefinition={filtersDefinition}
					filtersTextDefinition={[]}
					onClickfilter={() => setSincronizedItems(false)}
					filterInputChange={filterInputChange}
					onCreateNewItem={() => history.push(props.onCreateUrl)}
					onChangePageEvent={onChangeNumberSelect}
					showDeletedButton={
						location?.pathname === "/company_list"
					}
					renderFilter={(filter, index) => (
						<CustomSelect
							key={index}
							field_id={filter.field_id}
							selectTittle={filter.selectTittle}
							styles={filter.styles}
							defaultOptionText={filter.defaultOptionText}
							itemArray={filter.itemArray}
							onChangeEvent={selectOnChange}
							selectWidth={"100%"}
							defaultValue={filter.defaultValue}
						/>
					)}
					renderFilterText={(filter, index) => (
						<CustomInputFilter
							key={index}
							filter={filter}
							onChangeDateFilter={onChangeDateFilter}
						/>
					)}
				/>
				<div className={` mt-3 ${props.model === "Costumers" && "costumer-table-desktop"}`}>
					<CustomDataList
						onChangePageEvent={onChangeNumberSelect}
						style={styles}
						isLoading={isLoading}
						dataList={filtered_data}
						tableHeaders={props.tableHeaders}
						itemPagination={itemPagination}
						handlePagination={handlePagination}
						dontShowPerPageSelect={true}
						onChangeOrderArrow={onChangeOrderArrow}
						tableHeadersToDataFields={
							props.tableHeadersToDataFields
						}
						dataFieldsToOrderFilter={props.dataFieldsToOrderFilter}
						tableHeadersToOrderFilter={
							props.tableHeadersToOrderFilter
						}
						showOrderArrows={props.showOrderArrows}
						itemFilters={itemFilters}
						showLinearIndicator={true}
						showDataInLoading={true}
						render={(element, index) => (
							<CustomDataListItem
								key={index}
								dataFields={props.dataFields}
								imageFields={[]}
								composedFields={[]}
								arrayFields={[]}
								element={element}
								index={index}
								no_image={no_image}
								renderActions={() => (
									<>
										<PositionedMenu
											title={"Actions"}
											anchorEl={anchorEl}
											setAnchorEl={setAnchorEl}
											handleClose={onCloseModal}
											handleClick={(event) => {
												setCurrentElement(element);
												onMenuActions(
													event,
													element.id,
												);
											}}
											actionsDefinition={
												actionsDefinition
											}
											renderActions={(action, index) => (
												<MenuItem
													sx={{
														boxShadow: "none",
													}}
													key={index}
													onClick={() =>
														action.func()
													}
												>
													<div
														className="items-option"
														style={{
															padding: "0 5px",
															color: "grey",
														}}
													>
														<FontAwesomeIcon
															style={{
																fontSize: '.8rem',
																margin: "4px",
																marginRight: "10px",
															}}
															className={
																action.name == "Edit" 
																	? "text-primary"
																	: action.name == "Delete"
																		? 'text-danger'
																		: 'inherit'
															}
															icon={action.icon}
														/>
														<p className="mb-0 fs-small">{action.name}</p>
													</div>
												</MenuItem>
											)}
										></PositionedMenu>
									</>
								)}
								renderComponentInField={(
									data,
									rowInfo,
									elementInfo,
								) => {
									if (rowInfo.field_name === "modules") {
										const { module_in_package } = elementInfo;
										return (
											<div>
												<Button size="small" variant="outlined"  onClick={(event) => handleClick(event, elementInfo.id)} style={{ textTransform: 'inherit' }}>View All</Button>
												<Menu
													anchorEl={anchorOpenItem}
													keepMounted
													open={Boolean(anchorOpenItem && openItem === elementInfo.id)}
													onClose={handleClose}
												>
													{ module_in_package && module_in_package.length > 0 && (
														<List dense onClick={handleClose}>
															{module_in_package.map((module, index) => (
																<ListItem className="py-0" key={index}>
																	<ListItemText className="fs-small" primary={module.description} />
																</ListItem>
															))}
														</List>
													)}
																{/* <MenuItem key={index} disabled  className="fs-small">{ module.description}</MenuItem> */}
												</Menu>
											</div>
										);
									}

									if (rowInfo.field_name === "detail") {
										return (
											<>
												<ul
													style={{
														color: "black",
														paddingLeft: "0",
													}}
												>
													{elementInfo?.verified?.hasOwnProperty(
														"verified",
													) &&
														elementInfo?.verified
															?.verified && (
															<li>
																Verified:{" "}
																<i
																	className="fas fa-check-circle"
																	style={{
																		color: "#198754",
																	}}
																></i>
															</li>
														)}
													{(!elementInfo?.verified ||
														(elementInfo?.verified?.hasOwnProperty(
															"verified",
														) &&
															!elementInfo
																?.verified
																?.verified)) && (
														<li>
															Verified:{" "}
															<i
																className="far fa-times"
																style={{
																	color: "red",
																}}
															></i>
														</li>
													)}
													<li>
														Register date:{" "}
														{elementInfo?.date_registration ||
															""}
													</li>
													<li>
														Total Users:{" "}
														{elementInfo?.total_users ||
															""}
													</li>
												</ul>
											</>
										);
									}

									if (rowInfo.field_name === "package_name") {
										return (
											<div>
												<div>
													{elementInfo.package_name}
												</div>
												{location?.pathname ===
													"/company_list" && (
													<div className="save-buttton">
														<CustomButton
															buttonName={
																"Change"
															}
															buttonOnClick={() => {
																setCurrentElement(
																	elementInfo,
																);
																setCurrentCompany(
																	elementInfo?.id,
																);
																setShowChangePackageModal(
																	true,
																);
																setCurrentPackage(
																	elementInfo
																		?.package
																		?.id,
																);
																setCurrentId(
																	elementInfo?.id,
																);
															}}
															type={"success"}
															icon={""}
															disabled={false}
															margins={
																"mt-0 mb-0"
															}
														/>
													</div>
												)}
											</div>
										);
									}
								}}
							/>
						)}
					/>
				</div>
				{props.model === "Costumers" && (
					<div className="my-3 costumer-dataList-mobile-wrapper">
						<table
							style={{ width:"100%"}}
						>
							<tbody>
								{ filtered_data.length > 0 && filtered_data.map((costumerItem, index)=>(
									<tr key={index}>
										<td style={{ padding: '10px 7px' }}>
											<div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
												<div>
													<strong>Name:{costumerItem.name}</strong>
													<p>Email: {costumerItem.email}</p>
													<p>Status: {costumerItem.status}</p>
												</div>
												<div
													style={{display:"flex", flexDirection:"column", alignItems:"flex-end"}}
												>
													<strong>Phone Number:{costumerItem.fax_phone}</strong>
													<p>Address: {costumerItem.address_1}</p>
												</div>
											</div>
											<div
											style={{display:"flex", justifyContent:"flex-end", width:"100%", fontWeight:"bold", color:"#2767c4"}}
											>
												<small
												onClick={() => history.push(`/costumer_form_update?costumer_id=${costumerItem.id}`)}
												style={{borderRight:"1px solid lightgrey", padding:"0 5px"}}
												>Edit</small>

												<small
												onClick={() => onDeleteMobileTable(costumerItem.id)}
												style={{borderRight:"1px solid lightgrey", padding:"0 5px"}}
												>Delete</small>

												<small 
												onClick={() => handleOptionListToggle(index)} 
												style={{ padding: "0 5px", position:"relative" }}>
													<FaCaretDown size={"20px"} />
													{optionListIndex === index &&(
														<div style={{ right:"0", borderRadius:"6px", padding: "5px 15px", position:"absolute" , zIndex:"10", background:"white", color:"black", boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
														<p style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}><FaLongArrowAltRight size={"18px"} /> View</p>
														<p style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}><FaLongArrowAltRight size={"18px"} /> Copy</p>
														<p style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}><FaLongArrowAltRight size={"18px"} /> Print</p>
														</div>
													)}
												</small>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
				{/* <div className="mt-3 mobile-pagination-wrapper">
					{filtered_data.length > 0 && !isLoading ? (
							<PaginationControlled
								itemPagination={itemPagination}
								handlePagination={handlePagination}
							/>
					): <div style={{height:"85px"}}></div>
					}
				</div> */}
			</div>

			<CustomModal
				show={showChangePackageModal}
				customTittleText={"Change Package"}
				customMessageModal={""}
				onHandleClose={() => setShowChangePackageModal(false)}
				onHandleSuccess={() => {}}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
			>
				<CustomForm
					onHandleFormClose={() => setShowChangePackageModal(false)}
					location_list={[]}
					onHandleSubmit={onSubmitChangePackage}
					typesAndValuesFields={changePackageTypesAndValuesFields}
					initData={changePackageInitData}
					formTittle={""}
					id={null}
					getUrlPath={``}
					actionsFooterStyle={true}
					cancelStyleButtonText={"Back"}
					successStyleButtonText={"Update"}
					actionButonsContainerWidth={"100%"}
					buttonClick={buttonClick}
					onChangeFields={onChangeFieldsInitDataChangePackage}
					initOnChangeFields={onChangeFieldsInitDataChangePackage}
				/>
			</CustomModal>

			<CustomModal
				show={showCreatePaymentMethodModal}
				customTittleText={"Create Payment Method"}
				customMessageModal={""}
				onHandleClose={() => setShowCreatePaymentMethodModal(false)}
				onHandleSuccess={() => {}}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
			>
				<CustomForm
					onHandleFormClose={() =>
						setShowCreatePaymentMethodModal(false)
					}
					location_list={[]}
					onHandleSubmit={onSubmitCreatePayment}
					typesAndValuesFields={
						paymentPaymentTypesAndValuesFieldsInit
					}
					initData={paymentPaymentInitData}
					formTittle={""}
					id={null}
					getUrlPath={``}
					actionsFooterStyle={true}
					cancelStyleButtonText={"Back"}
					successStyleButtonText={"Create"}
					actionButonsContainerWidth={"100%"}
					buttonClick={buttonClick}
				/>
			</CustomModal>
		</>
	);
}

export { ListComponentWithPagination };
