import { useState, useEffect } from "react";
import createHttp from "../lib/http";

var _ = require("lodash");

function useApiRequest() {
	const [isLoading, setIsLoading] = useState(false);

	const getItem = async (itemId, urlPath, params = {}) => {
		return await createHttp
			.get(urlPath + (itemId ? itemId : ""), { params })
			.then((response) => response.data)
			.catch((err) => ({ data: [] }));
	};

	const modifyItem = async (itemId, urlPath, data) => {
		return await createHttp
			.put(urlPath + itemId, data)
			.then((response) => {
				if (response.status) {
					return response.data;
				} else {
					return response;
				}
			})
			.catch((err) => ({ data: [] }));
	};

	const createItem = async (urlPath, data) => {
		return await createHttp
			.post(urlPath, data)
			.then((response) => {
				if (response.status) {
					return response.data;
				} else {
					return response;
				}
			})
			.catch((err) => ({ data: [] }));
	};

	return {
		getItem,
		isLoading,
		setIsLoading,
		modifyItem,
		createItem,
	};
}

export { useApiRequest };
