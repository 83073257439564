export const PanelNames = {
	PANEL_CUSTOMER: 'PANEL_CUSTOMER',
	PANEL_PRODUCT: 'PANEL_PRODUCT',
	PANEL_DEVICE: 'PANEL_DEVICE',
	PANEL_LABOR: 'PANEL_LABOR',
	PANEL_TYPE_DEVICE: 'PANEL_TYPE_DEVICE',
}

export const typesAndValues = [
	{
		field_name: "client_id",
		type_field: "searchInput",
		text_label: "Customer",
		disabled: false,
		regexPattern: /^\d+$/,
		field_id: "client_id",
		labelText: "",
		defaultOptionText: "Select a customer",
		col: "col-8 col-md-3",
		typeFilter: "searchInput",
		apiRequest: "clients",
		queryParam: "search_text",
		defaultValue: "",
		selectCol: "col-12 d-flex align-items-start",
		inputValue: "",
		doRequest: false,
		dontInitData: false,
		queryParam2: "withdevice",
		queryValue2: "1",
		initOptions: [],
		inputConf: {
			className: 'col-12 col-md-4'
		},
		nextSiblingConfig: {
			iconStart: 'fas fa-plus',
			data: {
				panel: PanelNames.PANEL_CUSTOMER
			}
		},
	},
	{
		field_name: "type_work_order_id",
		type_field: "select_input",
		text_label: "Type",
		placeHolder: "select a type",
		dontDefaultOption: false,
		values: [],
		selectCol: "col-12",
		regexPattern: /^[a-z0-9]+$/i,
		col: "col-12 col-md-3",
	},
	{
		field_name: "date_receipt",
		type_field: "text_input",
		text_label: "Start Date",
		disabled: false,
		regexPattern: /^\d{4}[./-]\d{2}[./-]\d{2}$/,
		type: "date",
		col: "col-6 col-md-2",
	},
	{
		field_name: "due_date",
		type_field: "text_input",
		text_label: "End Date",
		disabled: false,
		regexPattern: /^\d{4}[./-]\d{2}[./-]\d{2}$/,
		type: "date",
		col: "col-6 col-md-2",
		dateGraterThan: "date_receipt",
	},
	{
		field_name: "notes",
		type_field: "text_input",
		text_label: "Description",
		disabled: false,
		regexPattern: /^$|^[A-Za-z0-9."',@; ]*$/,
		maxLength: 255,
		col: "col-12 col-md-4",
	},
	{
		field_name: "resource_id",
		type_field: "select_input",
		text_label: "Device",
		placeHolder: "select a device",
		dontDefaultOption: false,
		values: [],
		selectCol: "col-12",
		regexPattern: /^[a-z0-9]+$/i,
		col: "col-6 col-md-2",
		inputConf: {
			className: 'col-12 col-md-3'
		},
		nextSiblingConfig: {
			iconStart: 'fas fa-plus',
			data: {
				panel: PanelNames.PANEL_DEVICE
			}
		},
	},
	{
		field_name: "warehouse_id",
		type_field: "select_input",
		text_label: "Warehouse",
		placeHolder: "select a warehouse",
		dontDefaultOption: false,
		values: [{ id: "1", name: "warehouse" }],
		selectCol: "col-12",
		regexPattern: /^[a-z0-9]+$/i,
		col: "col-6 col-md-2",
	},
	{
		field_name: "employee_id",
		type_field: "select_input",
		text_label: "Employee Assign",
		placeHolder: "select a employee",
		dontDefaultOption: false,
		values: [{ id: "1", name: "employee one" }],
		selectCol: "col-12",
		regexPattern: /^[a-z0-9]+$/i,
		col: "col-6 col-md-2",
	},
];

export const initData = {
	client_id: "",
	type_work_order_id: "",
	date_receipt: "",
	due_date: "",
	notes: "",
	resource_id: "",
	warehouse_id: "",
	employee_id: "",
	// item_id: ''
};

export const typesAndValuesPartNumber = [
	{
		field_name: "item_id",
		type_field: "searchInput",
		text_label: "Part Number",
		disabled: false,
		regexPattern: /^\d+$/,
		field_id: "item_id",
		labelText: "",
		defaultOptionText: "Scan Part number",
		col: "col-6 col-md-7",
		typeFilter: "searchInput",
		apiRequest: "items",
		queryParam: "search_text",
		defaultValue: "",
		selectCol: "col-12 d-flex align-items-start",
		inputValue: "",
		doRequest: false,
		dontInitData: false,
		initOptions: [],
		inputConf: {
			className: 'col-12 col-md-6'
		},
		nextSiblingConfig: {
			iconStart: 'fas fa-plus',
			data: {
				panel: PanelNames.PANEL_PRODUCT
			}
		},
	},
	{
		field_name: "onSubmit",
		type_field: "button",
		text_label: "Add to list",
		type: "success",
		icon: "",
		margins: "mb-0",
		disabled: false,
		className: "col-12 btn btn-dark",
		iconStart: 'fas fa-plus',
		dotShowLabel: true,
		onclickHandler: () => {},
		col: "col-6 col-md-2",
	},
];

export const initDataPartNumber = {
	item_id: "",
};

export const typesAndValuesLabor = [
	{
		field_name: "item_id",
		type_field: "searchInput",
		text_label: "Labor Code",
		disabled: false,
		regexPattern: /^\d+$/,
		field_id: "item_id",
		labelText: "",
		defaultOptionText: "Entry labor code",
		col: "col-6 col-md-7",
		typeFilter: "searchInput",
		apiRequest: "items",
		queryParam: "search_text",
		defaultValue: "",
		selectCol: "col-12 d-flex align-items-start",
		inputValue: "",
		doRequest: false,
		dontInitData: false,
		queryParam2: "get_labors",
		queryValue2: "1",
		initOptions: [],
		inputConf: {
			className: 'col-12 col-md-6'
		},
		nextSiblingConfig: {
			iconStart: 'fas fa-plus',
			data: {
				panel: PanelNames.PANEL_LABOR
			}
		},
	},
	{
		field_name: "onSubmit",
		type_field: "button",
		text_label: "Add to list",
		type: "success",
		icon: "",
		margins: "mb-0",
		disabled: false,
		className: "col-12 btn btn-dark",
		dotShowLabel: true,
		onclickHandler: () => {},
		iconStart: 'fas fa-plus',
		col: "col-6 col-md-2",
	},
];

export const initDataLabor = {
	item_id: "",
};

export const typesAndValuesMessageOnInvoice = [
	{
		field_name: "message",
		type_field: "textArea",
		text_label: "Work Order Notes",
		rows: "2",
		// regexPattern: /^$|^[A-Za-z0-9."',@; ]*$/,
		col: "col-12 col-md-6",
		maxLength: 150,
		transformText: false,
		disabled: false,
	},
];

export const initDataMessageOnInvoice = {
	message: "",
};

export const typesAndValuesSaveDevice = [
	{
		field_name: "customer_id",
		type_field: "searchInput",
		text_label: "Customer",
		disabled: false,
		regexPattern: /^\d+$/,
		field_id: "customer_id",
		labelText: "",
		defaultOptionText: "Select a customer",
		col: "col-4",
		typeFilter: "searchInput",
		apiRequest: "clients",
		queryParam: "search_text",
		defaultValue: "",
		selectCol: "col-12 d-flex align-items-start",
		inputValue: "",
		doRequest: false,
		dontInitData: false,
	},
	{
		field_name: "name",
		type_field: "text_input",
		text_label: "Name",
		disabled: false,
		regexPattern: /^[A-Za-z0-9."', ]*$/,
		maxLength: 250,
		minLength: 1,
		col: "col-4",
		autoFocus: false,
		required: false,
	},
	{
		field_name: "type_resource_id",
		type_field: "select_input",
		text_label: "Type Device",
		placeHolder: "select a type device",
		dontDefaultOption: false,
		values: [],
		selectCol: "col-12",
		regexPattern: /^[a-z0-9]+$/i,
		col: "col-4",
		initOptions: [],
		inputConf: {
			className: 'col-12'
		},
		nextSiblingConfig: {
			iconStart: 'fas fa-plus',
			data: {
				panel: PanelNames.PANEL_TYPE_DEVICE
			}
		},
	},
	{
		field_name: "brand",
		type_field: "text_input",
		text_label: "Brand",
		disabled: false,
		regexPattern: /^[A-Za-z0-9."', ]*$/,
		maxLength: 255,
		minLength: 3,
		col: "col-4",
		autoFocus: false,
		required: false,
	},
	{
		field_name: "model",
		type_field: "text_input",
		text_label: "Model",
		disabled: false,
		regexPattern: /^[A-Za-z0-9."', ]*$/,
		maxLength: 250,
		minLength: 1,
		col: "col-4",
		autoFocus: false,
		required: false,
	},
	{
		field_name: "year",
		type_field: "text_input",
		text_label: "Year",
		disabled: false,
		regexPattern: /^\d+$/,
		maxLength: 4,
		minLength: 4,
		col: "col-4",
		autoFocus: false,
		required: false,
	},
	{
		field_name: "date_warranty",
		type_field: "text_input",
		text_label: "Date Warranty",
		disabled: false,
		regexPattern: /^\d{4}[./-]\d{2}[./-]\d{2}$/,
		type: "date",
		col: "col-4",
	},
	{
		field_name: "user1",
		type_field: "text_input",
		text_label: "Serial Number",
		disabled: false,
		regexPattern: /^[A-Za-z0-9."', ]*$/,
		maxLength: 255,
		minLength: 3,
		col: "col-4",
		autoFocus: false,
		required: false,
	},
];

export const initDataSaveDevice = {
	customer_id: "",
	name: "",
	type_resource_id: "",
	brand: "",
	model: "",
	year: "",
	date_warranty: "",
	user1: "",
};

export const typesAndValuesUpdatePart = [
	{
		field_name: "id",
		type_field: "text_input",
		text_label: "Id",
		disabled: true,
		regexPattern: /^[A-Za-z0-9]*$/,
		maxLength: 15,
		hidden: true,
	},
	{
		field_name: "part_number",
		type_field: "text_input",
		text_label: "Part Number",
		disabled: true,
		regexPattern: /^[A-Za-z0-9]*$/,
		maxLength: 15,
		col: "col-4",
	},
	{
		field_name: "rate",
		type_field: "text_input",
		text_label: "Rate",
		disabled: false,
		regexPattern: /^[0-9]*\.?[0-9]*$/,
		maxLength: 100,
		minLength: 1,
		col: "col-4",
		required: false,
		type: "number",
	},
	{
		field_name: "quantity",
		type_field: "text_quantity",
		text_label: "Quantity",
		regexPattern: /^[0-9]*$/,
		minLength: 1,
		col: "col-4",
	},
	{
		field_name: "description",
		type_field: "text_input",
		text_label: "Description",
		disabled: false,
		regexPattern: /^$|^[A-Za-z0-9.@'"; ]*$/,
		maxLength: 255,
		col: "col-12",
	},
];

export const initDataUpdatePart = {
	part_number: "",
	rate: "",
	quantity: "",
	description: "",
};

export const typesAndValuesUpdateLabor = [
	{
		field_name: "rate",
		type_field: "text_input",
		text_label: "Rate",
		disabled: false,
		regexPattern: /^[0-9]*\.?[0-9]*$/,
		maxLength: 100,
		minLength: 1,
		col: "col-4",
		required: false,
		type: "number",
		inputConf: {
			className: 'col-12 col-md-6'
		},
	},
	{
		field_name: "quantity",
		type_field: "text_quantity",
		text_label: "Quantity",
		regexPattern: /^[0-9]*$/,
		minLength: 1,
		col: "col-4",
		inputConf: {
			className: 'col-12 col-md-6'
		},
	},
	// {
	//     field_name: 'comment',
	//     type_field: 'text_input',
	//     text_label: 'Description',
	//     disabled: false,
	//     regexPattern: /^$|^[A-Za-z0-9.@'"; ]*$/,
	//     maxLength: 255,
	//     col: 'col-12'
	// },
	{
		field_name: "employee_id",
		type_field: "select_input",
		text_label: "Employee Assign",
		placeHolder: "select a employee",
		dontDefaultOption: false,
		values: [{ id: "1", name: "employee one" }],
		selectCol: "col-12",
		regexPattern: /^[a-z0-9]+$/i,
		col: "col-4 col-md-4",
		inputConf: {
			className: 'col-12'
		},
	},
	{
		field_name: "start_time",
		type_field: "text_input",
		text_label: "Start Date",
		disabled: false,
		// regexPattern: /^(\d{4})\.(\d{2})\.(\d{2}) (\d{2}):(\d{2}):(\d{2})$/,
		type: "datetime-local",
		col: "col-6 col-md-6",
		inputConf: {
			className: 'col-6'
		},
	},
	{
		field_name: "end_time",
		type_field: "text_input",
		text_label: "End Date",
		disabled: false,
		// regexPattern: /^(\d{4})\.(\d{2})\.(\d{2}) (\d{2}):(\d{2}):(\d{2})$/,
		type: "datetime-local",
		dateGraterThan: "start_time",
		col: "col-6 col-md-6",
		inputConf: {
			className: 'col-6'
		},
	},
];
