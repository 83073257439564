import imglogo from "../../../assets/img/logo_t4i.png";
import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import { useForm } from "./hooks/useForm";
import "./signup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Login from "../../../views/login/Login";
import { useHistory } from "react-router-dom";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import { useAuthentication } from "../../../customHooks/useAuthentication";

const initialForm = {
	code: "",
};

const validationsForm = (form) => {
	let errors = {};

	if (!(form.code.trim().length > 0)) {
		errors.code = "code is invalid";
	}

	return errors;
};

function ValidationMethodSelection() {
	const history = useHistory();

	const { getInfoFromLocalStorage } = useAuthentication();

	const [infoUser, setInfoUser] = useState({
		email: "",
		phoneNumber: "",
	});

	const [typeValidation, setTypeValidation] = useState("email");

	const onValueChangeRadio = (ev) => {
		setTypeValidation(ev);
	};

	useEffect(() => {
		let infoUser = getInfoFromLocalStorage("item_validation_info");
		if (!infoUser || !(Object.keys(infoUser).length > 0))
			history.push("/404");
		setInfoUser({ ...infoUser });
	}, []);

	const {
		form,
		errors,
		handleChange,
		handleBlur,
		handleSubmit,
		showSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		cancelButtonText,
		sucessButtonText,
		isLoading,
		manageResponseMessage,
	} = useForm(initialForm, validationsForm, "SendCode");

	return (
		<>
			<div className="login">
				<div className="form-container__signup">
					<div className="form-container_top">
						<img src={imglogo} alt="logo" className="logo" />
					</div>
					<form
						className="form form-container"
						onSubmit={handleSubmit}
					>
						<div className="theinputs" style={{ padding: "20px" }}>
							<label
								className="label"
								style={{ textAlign: "center" }}
							>
								{" "}
								Send to Authentication Code By
							</label>
							<label className="form-control-radio label">
								<input
									className="styled-type-radio"
									type="radio"
									value="email"
									name="radio"
									checked={typeValidation === "email"}
									onChange={() => onValueChangeRadio("email")}
								/>
								<label className="label">
									<div>Email</div>
									<div className="data-direction">
										{infoUser.email}
									</div>
								</label>
							</label>

							<label className="form-control-radio label">
								<input
									className="styled-type-radio"
									type="radio"
									value="sms"
									name="radio"
									checked={typeValidation === "sms"}
									onChange={() => onValueChangeRadio("sms")}
								/>
								<label className="label">
									<div>Sms</div>
									<div className="data-direction">
										{infoUser.phoneNumber}
									</div>
								</label>
							</label>

							<button
								type="button"
								className="primary-button signup-button mt-1 d-flex justify-content-center align-items-center"
								onClick={(e) =>
									handleSubmit(e, "send_code", true, {
										email: infoUser.email,
										phone_number: infoUser.phoneNumber,
										type: typeValidation,
									})
								}
							>
								{isLoading && (
									<div
										className="spinner-border"
										role="status"
									>
										<span className="sr-only">
											Loading...
										</span>
									</div>
								)}
								{!isLoading && "SEND"}
							</button>
						</div>
					</form>

					{manageResponseMessage.message.length > 0 && (
						<div
							className="succefull-message mt-1"
							style={{ maxWidth: "340px" }}
						>
							<p
								className="succefull-message-text"
								style={{ color: "var(--bs-danger)" }}
							>
								{manageResponseMessage.message}
							</p>
						</div>
					)}
				</div>
			</div>

			{/* <CustomModal
                show={isLoading}
                customTittleText={''}
                customMessageModal={''}
                onHandleClose={() => { }}
                isLoading={isLoading}
            /> */}

			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowModal(false)}
				onHandleSuccess={() => {}}
				isLoading={false}
				showSuccessButton={showSuccessButton}
				actionsFooterStyle={true}
				cancelText={cancelButtonText}
				successText={sucessButtonText}
			/>
		</>
	);
}

export { ValidationMethodSelection };
