import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import Swal from 'sweetalert2'

// components
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { TabsComponent } from "../../components/TabsComponent/TabsComponent";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { CustomSelect } from "../../components/customSelect/customSelect";
import { CustomImageViewer } from "../../components/CustomImageViewer/CustomImageViewer";
import no_image from "../../assets/img/no_image.png";
import { ImageInput } from "../../components/image_input";
import img from "../../assets/img/upload_image.jpg";
import Box from "@material-ui/core/Box";
import Panel from '../../components/Panel'
import CategoryForm from '../categories/CategoryForm'
import BrandForm from '../brand/BrandForm'
import UomForm from '../uoms/UomForm'
import NumberFormat from '../../utils/number.format'

//custom hooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";

import {
	typesAndValues as typesAndValuesFields,
	initData as initDataFields,
	PanelNames,
} from "./initForm";

import {
	styles,
	tableHeadersWorkOrderLocationTable as tableHeaders,
	dataFieldsWorkOrderLocationTable as dataFields,
	tableHeadersWorkOrderVendorsTable as tableHeadersVendorList,
	dataFieldsWorkOrderVendorsTable as dataFieldsVendorList,
	blockInvalidChar,
	setValueInArray,
} from "../../context/constants";
import MaterialUI from "../../components/MaterialUI";

import "./ProductForm.css";
import ColorForm from "../Colors/ColorForm";
import { useItemsVariants } from "../../customHooks/useItemsVariants";
import { debounce } from "lodash";
import { VendorForm } from "../Vendor/vendorForm/Form";
import { ExpensiveContextProvider } from "../../context/ExpensiveContext";

const initFilters = {
	location_id: "",
	warehouse_id: "",
	sku: "",
	upc: "",
	color: "",
	size: "",
}

const initStateProductDetail = ({ item_id = "", location_id = "", warehouse_id = "" }) => ({
	id: crypto.randomUUID(),
	sku: "",
	upc: "",
	color: "",
	size: "",
	min_stock: 0,
	max_stock: 0,
	cost: 0,
	price: "",
	item_id,
	location_id,
	warehouse_id,
})

const ProductDetailFilters = ({ onFilters, locations, warehouses }) => {
	const [filters, setFilters] = useState({ ...initFilters })
	const handleChange = (key) => (newValue) => (setFilters((prev) => ({ ...prev, [key]: newValue })))
	useEffect(() => {
		const emitUpdate = debounce(() => (onFilters && onFilters instanceof Function) && onFilters(filters), 400)
		emitUpdate()
		return () => emitUpdate.cancel()
	}, [filters])
	return (
		<tr>
			<td style={{ padding: '2px' }}>
				<select className="form-select form-select-sm" value={filters.location_id} onChange={(ev) => handleChange('location_id')(ev.target.value)}>
					<option value="">All</option>
					{!!locations.length && locations.map((c, index) => (<option key={index} value={c.id}>{c.name}</option>))}
				</select>
			</td>
			<td style={{ padding: '2px' }}>
				<select className="form-select form-select-sm" value={filters.warehouse_id} onChange={(ev) => handleChange('warehouse_id')(ev.target.value)}>
					<option value="">All</option>
					{!!warehouses.length && warehouses.map((c, index) => (<option key={index} value={c.id}>{c.name}</option>))}
				</select>
			</td>
			<td style={{ padding: '2px' }}>
				<input type="text" tabIndex="-1" placeholder="Search" className="form-control form-control-sm w-100" value={filters.sku} onChange={(ev) => handleChange('sku')(ev.target.value)} />
			</td>
			<td style={{ padding: '2px' }}>
				<input type="text" tabIndex="-1" placeholder="Search" className="form-control form-control-sm w-100" value={filters.upc} onChange={(ev) => handleChange('upc')(ev.target.value.toLocaleUpperCase())} />
			</td>
			<td style={{ padding: '2px' }}>
				<input type="text" tabIndex="-1" placeholder="Search" className="form-control form-control-sm w-100" value={filters.color} onChange={(ev) => handleChange('color')(ev.target.value.toLocaleUpperCase())} />
			</td>
			<td style={{ padding: '2px' }}>
				<input type="text" tabIndex="-1" placeholder="Search" className="form-control form-control-sm w-100" value={filters.size} onChange={(ev) => handleChange('size')(ev.target.value.toLocaleUpperCase())} />
			</td>
			<td style={{ padding: '2px' }} colSpan={5}></td>
		</tr>
	)
}

export const ProductDetail = ({ hideHeader = false, onUpdate, items = [], setItems, storeInline: storeInlineId = 0, locations = [], warehouses = [] }) => {
	const [form, setForm] = useState({});
	const { store, update, destroy: destroyItemVariant } = useItemsVariants()
	const [loadingForm, setLoadingForm] = useState(false)
	const [indexEdited, setIndexEdited] = useState(-1)
	const $firstInputForm = useRef(null)
	
	const [errors, setErrors] = useState({});
	const onChangeForm = (key) => value => {
		setForm((prev) => ({ ...prev, [key]: value }))
		setErrors((prev) => {
			const newErrors = {...prev}
			if(newErrors[key]) {
				delete newErrors[key]
			}
			return newErrors
		})
	}

	useEffect(() => {
		if(!!warehouses.length && !!locations.length) {
			resetForm()
		}
	}, [locations, warehouses])
	
	const isValid = () => {
		const _errors = {}
		if(!form.sku.trim().length) _errors.sku = "This field is required"
		if(!form.upc.trim().length) _errors.upc = "This field is required"
		if(!String(form.location_id).trim().length) _errors.location_id = "This field is required"
		if(!String(form.warehouse_id).trim().length) _errors.warehouse_id = "This field is required"
		setErrors(_errors)
		return !Object.keys(_errors).length
	}
	
	const updateList = async () => {
		try {
			setLoadingForm(true)
			setErrors({})
			if(!isValid()) return
			let payload = {...form}
			const exists = items.findIndex(c => c.id == form.id) != -1
			if(!exists) {
				if(storeInlineId) {
					delete payload.id
					const { data } = await store(payload)
					payload = data
				}
				setItems((prev) => {
					const newList = [...prev]
					newList.push(payload)
					return newList
				})
				$firstInputForm.current?.focus()
			} else {
				if(storeInlineId) {
					const { id, ...formData } = payload
					const { data } = await update(id, formData)
					payload = data
				}
				setItems((prev) => {
					const newList = [...prev]
					const index = newList.findIndex((c) => !!form.id && c.id == form.id)
					if(index !== -1) newList[index] = payload
					return newList
				})
			}
			resetForm()
			if(onUpdate && onUpdate instanceof Function) onUpdate(items)
		} catch (error) {
		} finally {
			setLoadingForm(false)
		}
	}

	const onEdit = (index) => {
		const record = items[index]
		setIndexEdited(index)
		setForm({
			...record,
			location_id: record.location_id || '',
			warehouse_id: record.warehouse_id || '',
		})
	}

	const destroy = async (index) => {
		try {
			const data = items[index]
			if(!!data.id) {
				const { isConfirmed } = await Swal.fire({
					title: "Are you sure?",
					text: "You won't be able to revert this!",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#041731",
					cancelButtonColor: "#FD6461",
					confirmButtonText: "Yes, delete it!"
				})
				if(!isConfirmed) return
				if(!!storeInlineId) await destroyItemVariant(data.id)
				Swal.fire({
					title: "Deleted!",
					text: "Your record has been deleted.",
					icon: "success"
				});
			}
			setItems((prev) => {
				const newList = [...prev]
				newList.splice(index, 1)
				return newList
			})
		} catch (error) {
			
		}
	}

	const resetForm = () => {
		setIndexEdited(-1)
		setForm(initStateProductDetail({
			item_id: storeInlineId,
			location_id: locations.find(c => Boolean(c.default_location))?.id ?? '',
			warehouse_id: warehouses[0]?.id ?? ''
		}))
	}

	const [filters, setFilters] = useState({ ...initFilters })
	const fieldsFill = Object.values(filters).filter(Boolean)
	const itemsFiltered = !fieldsFill.length ? items : items.filter(applyFilters)
	function applyFilters(item) {
		const results = Object.entries(filters).filter(c => Boolean(c[1])).map(([key, value]) => {
			if(key === 'location_id' || key === 'warehouse_id') {
				return item[key] == value
			} else {
				return String(item[key]).toLocaleUpperCase().includes(String(value).toLocaleUpperCase())
			}
		})
		return results.every(Boolean)
	}

	const locationsIndexed = !locations.length ? {} : locations.reduce((acc, item) => {
		if(!acc[item.id]) acc[item.id] = item
		return acc
	}, {})

	const warehousesIndexed = !warehouses.length ? {} : warehouses.reduce((acc, item) => {
		if(!acc[item.id]) acc[item.id] = item
		return acc
	}, {})

	if(!items && !setItems || !Object.keys(form).length) return null
	
	return (
		<>
			<h6 className="sub__title mb-0 mt-3 text-primary">Form Variants</h6>
			<div className="row">
				<div className={`col-12`}>
					<div className="row align-items-end gap-0">
						<div className={`${!hideHeader ? 'col-6 col-md-2' : 'col-4'}`}>
							<label htmlFor="detail_location">Location</label>
							<select id="detail_location" className="form-select" value={form.location_id} onChange={(ev) => onChangeForm('location_id')(ev.target.value)}>
								<option value="">Select</option>
								{locations.map((item, index) => (
									<option key={index} value={item.id}>{item.name}</option>
								))}
							</select>
							{!!errors.location_id && <span className="fs-small text-danger">{errors.location_id}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-2' : 'col-4'}`}>
							<label htmlFor="detail_warehouse">Warehouse</label>
							<select id="detail_warehouse" className="form-select" value={form.warehouse_id} onChange={(ev) => onChangeForm('warehouse_id')(ev.target.value)}>
								<option value="">Select</option>
								{warehouses.map((item, index) => (
									<option key={index} value={item.id}>{item.name}</option>
								))}
							</select>
							{!!errors.warehouse_id && <span className="fs-small text-danger">{errors.warehouse_id}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-2' : 'col-4'}`}>
							<label htmlFor="detail_sku">Sku</label>
							<input type="text" ref={$firstInputForm} autoComplete="off" id="detail_sku" className={`form-control`} value={form.sku} onChange={(ev) => onChangeForm('sku')(ev.target.value)} />
							{!!errors.sku && <span className="fs-small text-danger">{errors.sku}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-2' : 'col-4'}`}>
							<label htmlFor="detail_upc">Upc</label>
							<input type="text" autoComplete="off" id="detail_upc" className={`form-control`} value={form.upc} onChange={(ev) => onChangeForm('upc')(ev.target.value)} />
							{!!errors.upc && <span className="fs-small text-danger">{errors.upc}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-2' : 'col-4'}`}>
							<label htmlFor="detail_color">Color</label>
							<input type="text" autoComplete="off" id="detail_color" className={`form-control`} value={form.color} onChange={(ev) => onChangeForm('color')(ev.target.value.toLocaleUpperCase())} />
							{!!errors.color && <span className="fs-small text-danger">{errors.color}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-1' : 'col-4'}`}>
							<label htmlFor="detail_size">Size</label>
							<input type="text" autoComplete="off" id="detail_size" className={`form-control`} value={form.size} onChange={(ev) => onChangeForm('size')(ev.target.value.toLocaleUpperCase())} />
							{!!errors.size && <span className="fs-small text-danger">{errors.size}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-1' : 'col-4'}`}>
							<label htmlFor="detail_cost">Cost</label>
							<input type="text" autoComplete="off" id="detail_cost" className={`form-control`} value={form.cost} onChange={(ev) => onChangeForm('cost')(ev.target.value.toLocaleUpperCase())} />
							{!!errors.cost && <span className="fs-small text-danger">{errors.cost}</span>}
						</div>
						<div className={`${!hideHeader ? 'col-6 col-md-1' : 'col-4'}`}>
							<label htmlFor="detail_price">Price</label>
							<input type="text" autoComplete="off" id="detail_price" className={`form-control`} value={form.price} onChange={(ev) => onChangeForm('price')(ev.target.value.toLocaleUpperCase())} />
							{!!errors.price && <span className="fs-small text-danger">{errors.price}</span>}
						</div>
						{indexEdited !== -1 && (<>
							<div className={`${!hideHeader ? 'col-6 col-md-1' : 'col-md-4'}`}>
								<div>
									<label htmlFor="detail_min_stock">Min Stock</label>
									<input type="text" autoComplete="off" id="detail_min_stock" className={`form-control`} value={form.min_stock} onChange={(ev) => onChangeForm('min_stock')(ev.target.value)}/>
									{!!errors.min_stock && <span className="fs-small text-danger">{errors.min_stock}</span>}
								</div>
							</div>
							<div className={`${!hideHeader ? 'col-6 col-md-1' : 'col-md-4'}`}>
								<div>
									<label htmlFor="detail_max_stock">Max Stock</label>
									<input type="text" autoComplete="off" id="detail_max_stock" className={`form-control`} value={form.max_stock} onChange={(ev) => onChangeForm('max_stock')(ev.target.value)}/>
									{!!errors.max_stock && <span className="fs-small text-danger">{errors.max_stock}</span>}
								</div>
							</div>
						</>)}
						<div className={`${!hideHeader ? `col-6 col-md-2` : 'col-4'}`}>
							<div className="form-group mt-2 mt-md-0 text-end">
								<div className="d-flex w-full gap-1">
									<MaterialUI.Button.Darkblue style={{ display: 'block', paddingInline: 0, inlineSize: '100%' }} onClick={updateList} disabled={loadingForm}>
										<span className="inline-block text-xs ps-1">{indexEdited !== -1 ? 'Update Item' : 'Add Item'}</span>
									</MaterialUI.Button.Darkblue>
									{indexEdited !== -1 && (
										<MaterialUI.Button.Danger style={{ display: 'block', paddingInline: 0, inlineSize: '100%', maxWidth: '70px !important', padding: '0px' }} onClick={resetForm} disabled={loadingForm}>
											<span className="inline-block text-xs ps-1">Cancel</span>
										</MaterialUI.Button.Danger>
									)}
								</div>
								{(!!errors.sku && !!errors.upc && !!errors.color && !!errors.size && !!errors.quantity) && <span>&nbsp;</span>}
							</div>
						</div>
					</div>
				</div>
				<div className={`col-12 mt-2 font-inter`}>
					<div className="table-responsive">
						<table className="table table-sm">
							<thead>
								<tr>
									<th style={{ minInlineSize: '100px' }}>Location</th>
									<th style={{ minInlineSize: '100px' }}>Warehouse</th>
									<th style={{ minInlineSize: '130px', inlineSize: '130px', maxInlineSize: '130px' }}>SKU</th>
									<th style={{ minInlineSize: '130px', inlineSize: '130px', maxInlineSize: '130px' }}>UPC</th>
									<th style={{ minInlineSize: '100px' }}>Color</th>
									<th style={{ minInlineSize: '100px' }}>Size</th>
									<th style={{ minInlineSize: '100px' }}>Cost</th>
									<th style={{ minInlineSize: '100px' }}>Price</th>
									<th style={{ minInlineSize: '80px', inlineSize: '80px', maxInlineSize: '80px' }}>Min Stock</th>
									<th style={{ minInlineSize: '80px', inlineSize: '80px', maxInlineSize: '80px' }}>Max Stock</th>
									<th style={{ inlineSize: '70px' }} className="text-center">Actions</th>
								</tr>
							</thead>
							<tbody>
								<ProductDetailFilters onFilters={setFilters} locations={locations} warehouses={warehouses} />
								{!!itemsFiltered.length ? itemsFiltered.map((item, index) => (
									<tr key={index} className={`align-middle ${form.id == item.id ? 'bg-primary bg-opacity-25' : ''}`}>
										<td style={{ fontSize: '12px' }}>{locationsIndexed[item.location_id]?.name || '-'}</td>
										<td style={{ fontSize: '12px' }}>{warehousesIndexed[item.warehouse_id]?.name || '-'}</td>
										<td style={{ fontSize: '12px' }}>{item.sku}</td>
										<td style={{ fontSize: '12px' }}>{item.upc}</td>
										<td style={{ fontSize: '12px' }}>{item.color}</td>
										<td style={{ fontSize: '12px' }}>{item.size}</td>
										<td style={{ fontSize: '12px' }}>{NumberFormat.currency(Number(item.cost || '0'))}</td>
										<td style={{ fontSize: '12px' }}>{NumberFormat.currency(Number(item.price || '0'))}</td>
										<td style={{ fontSize: '12px' }}>{item.min_stock ?? '0'}</td>
										<td style={{ fontSize: '12px' }}>{item.max_stock ?? '0'}</td>
										<td>
											<div className="d-flex gap-1">
												<MaterialUI.Button.Warning tabIndex="-1" style={{ minWidth: '40px', paddingInline: '8px' }} onClick={() => onEdit(index)} disabled={loadingForm || indexEdited !== -1}>
													<i className="fas fa-edit"></i>
												</MaterialUI.Button.Warning>
												<MaterialUI.Button.Danger tabIndex="-1" style={{ minWidth: '40px', paddingInline: '8px' }} onClick={() => destroy(index)} disabled={loadingForm || indexEdited !== -1}>
													<i className="fas fa-trash"></i>
												</MaterialUI.Button.Danger>
											</div>
										</td>
									</tr>
								)) : null}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

function ProductForm(props) {
	const history = useHistory();

	const {
		showSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
	} = useModalHook();

	const { isLoading, storeItem: createProduct, getListItemWithCache } = useApiCustomRequest(manageResponseErrors, "items", "Item", manageResponse, manageSuccessfullMesssage, true);
	const [initData, setInitData] = useState({});
	const [typesAndValues, setTypesAndValues] = useState([]);
	const [locations, setLocations] = useState([]);
	const [warehousesList, setWarehouseList] = useState([]);
	const [items, setItems] = useState([]);

	useEffect(async () => {
		let fields = [...typesAndValuesFields];
		let initDataFieldsCopy = { ...initDataFields };
		let params = { without_data: !props.withoutData ? 0 : 1 };
		const resources = await getListItemWithCache("get_resource_data", "Resources", params );
		const { brands, categories, locations, warehouses, vendors } = resources;
		setLocations(locations)
		setWarehouseList(warehouses)
		if (!!brands?.length) fields = setValueInArray(fields, "brand_id", "values", brands)
		if (!!categories?.length) fields = setValueInArray(fields, "category_id", "values", categories)
		if (!!vendors?.length) fields = setValueInArray(fields, "vendor_id", "values", vendors)
		initDataFieldsCopy = {
			...initDataFieldsCopy,
			brand_id: !!brands?.length ? brands[0]?.id : "",
			category_id: !!categories?.length ? categories[0]?.id : "",
			vendor_id: !!vendors?.length ? vendors[0]?.id : "",
			sku: props.hasOwnProperty("skuNumber") && props?.skuNumber ? props?.skuNumber : "",
		};
		setInitData(initDataFieldsCopy);
		setTypesAndValues(fields);
	}, []);

	const onChangeFieldsInitDataPartNumber = (ev) => setInitData({ ...ev })
	const onHandleSubmit = (ev) => {};
	const buttonClick = () => {};

	// image input function
	const [preview_image, setImagePreview] = useState(img);
	const [itemImageToAdd, setItemImageToAdd] = useState([]);
	const [itemImageToRemove, setItemImageToRemove] = useState([]);
	const [itemImageArray, setItemImageArray] = useState([]);
	const [currentImageToDelete, setCurrentImageToDelete] = useState(null);
	const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);

	const setItemImageToUpdate = (event, index) => {
		let preview = URL.createObjectURL(event.target.files[0]);
		setImagePreview(preview);
	};

	const onClickImage = (imgUrl, deleteImage = false) => {
		setImagePreview(imgUrl);
	};

	const viewDeleteImageModal = (imgUrl) => {
		setCurrentImageToDelete(imgUrl);
		setShowDeleteImageModal(true);
	};

	const hideDeleteImageModal = () => {
		setShowDeleteImageModal(false);
		setCurrentImageToDelete(null);
	};

	const deleteImageConfirm = () => {
		onDeleteImage(currentImageToDelete);
		setShowDeleteImageModal(false);
		setCurrentImageToDelete(null);
	};

	const onDeleteImage = (imgUrl) => {
		let deletedPath = "";

		const copyImages = [...itemImageArray];

		const copyImagesFiles = [...itemImageToAdd];

		const imageIndex = copyImages.findIndex((image) => image.path === imgUrl);

		const imageFileIndex = copyImagesFiles.findIndex((file) => file.name === copyImages[imageIndex].id);

		if (imageFileIndex >= 0) {
			copyImagesFiles.splice(imageFileIndex, 1);
		}
		setItemImageToAdd([...copyImagesFiles]);

		if (imageIndex >= 0) {
			deletedPath = copyImages[imageIndex].path;
			setItemImageToRemove([ ...itemImageToRemove, copyImages[imageIndex] ]);
			copyImages.splice(imageIndex, 1);
		}

		setItemImageArray([...copyImages]);
		if (preview_image === deletedPath) {
			setImagePreview(copyImages[0]?.path || img);
		}
	};

	const transformArrayToObject = (array) => array.reduce((accumulator, value) => ({ ...accumulator, ...value }), {})

	//onSubmit
	const childFunc = useRef(null);
	const onCreateProduct = async () => {
		if (isLoading) return;
		const errors = childFunc.current(initData);
		if(!!errors.length) return
		let imagesArray = itemImageToAdd.map((itemFile, index) => {
			if (itemFile instanceof File) {
				return {[`images[${index}]`]: itemFile};
			}
		});
		const barcodes = items.map(({id, item_id, ...structuredCleaned}) => structuredCleaned)
		const request = { ...initData, ...transformArrayToObject(imagesArray), barcodes }
		debugger
		let response = await createProduct(request, "Product", {}, true, false);
		if (response && response.hasOwnProperty("id")) {
			if (!props.dontReloadToMainPage) history.push("/product_list");
			if (props.hasOwnProperty("onResponse")) props.onResponse(response)
			if (props.hasOwnProperty("onHandleCancelButton")) props.onHandleCancelButton(initData.sku, initData.cost);
		}
	};

	const goBack = () => history.push('/product_list');

	// 👉 Siblings
	const handleSiblings = (prop = {}) => {
		switch (prop.panel) {
			case PanelNames.PANEL_CATEGORY:
				togglePanel('category')
				break;
			case PanelNames.PANEL_BRAND:
				togglePanel('brand')				
				break;
			case PanelNames.PANEL_COLOR:
				togglePanel('colors')				
				break;
			case PanelNames.PANEL_UOM:
				togglePanel('uom')
				break;
			default:
				togglePanel(PanelNames.PANEL_VENDOR)
				break;
		}
	}
	
	// 👉 Panels
	const [panels, setPanels] = useState({ category: false, brand: false, uom: false, colors: false, vendor: false });
  const togglePanel = (panel) => setPanels((prev) => ({ ...prev, [panel]: !prev[panel] }));

	const addRecordToCollection = (field_name, newRecord) => {
		const index = typesAndValues.findIndex((c) => c.field_name === field_name);
		if(index === -1) return;
		setTypesAndValues((prev) => {
			prev[index]['values'] = [...prev[index]['values'], newRecord];
			return prev;
		});
		setInitData((prev) => ({ ...prev, [field_name]: newRecord.id }));
	}
	
	const captureResponseCategory = (newRecord) => {
    if(!newRecord) return;
		addRecordToCollection('category_id', newRecord);
		togglePanel('category');
  }

	const captureResponseBrand = (newRecord) => {
    if(!newRecord) return;
		addRecordToCollection('brand_id', newRecord);
		togglePanel('brand');
  }

	const captureResponseUom = (newRecord) => {
    if(!newRecord) return;
		addRecordToCollection('uom_id', newRecord);
		togglePanel('uom');
  }

	const captureResponseVendor = ({ data }) => {
    const vendor = data.data;
    if(!vendor) return;
		addRecordToCollection('vendor_id', vendor);
		togglePanel(PanelNames.PANEL_VENDOR);
  }
	
	return (
		<>
			<ExpensiveContextProvider>
				<Panel headerTitle="Create Category" open={panels.category} togglePanel={() => togglePanel('category')}>
					<CategoryForm handleBehavior={captureResponseCategory}/>
				</Panel>
				<Panel headerTitle="Create Brand" open={panels.brand} togglePanel={() => togglePanel('brand')}>
					<BrandForm handleBehavior={captureResponseBrand}/>
				</Panel>
				<Panel headerTitle="Create Size" open={panels.uom} togglePanel={() => togglePanel('uom')}>
					<UomForm handleBehavior={captureResponseUom}/>
				</Panel>
				<Panel headerTitle="Create Color" open={panels.colors} togglePanel={() => togglePanel('colors')}>
					<ColorForm />
				</Panel>
				<Panel headerTitle="Create Vendor" open={panels.vendor} togglePanel={() => togglePanel(PanelNames.PANEL_VENDOR)}>
					<VendorForm behavior="NO_REDIRECT" handleBehavior={captureResponseVendor} />
				</Panel>
				<div className={`contenedor container-fluid`}>
					{!props.hideHeader ? (
						<div className="d-flex gap-2 align-items-center">
							<MaterialUI.Button.Dark onClick={() => !!props.handleCloseAdd ? props.handleCloseAdd() : goBack()}>
								<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
							</MaterialUI.Button.Dark>
							<div className="container-title">
								<h1 className="container__title mb-0">{"Create Product"}</h1>
							</div>
							<MaterialUI.Button.Success onClick={onCreateProduct}>
								<SaveOutlinedIcon fontSize="small" className="me-1" /> Save
							</MaterialUI.Button.Success>
						</div>
					) : !props.showCancelButton ? (<div className="d-flex gap-2 align-items-center">
						<MaterialUI.Button.Dark onClick={() => !!props.handleCloseAdd ? props.handleCloseAdd() : goBack()}>
							<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
						</MaterialUI.Button.Dark>
						<div className="container-title">
							<h1 className="container__title mb-0">{"Create Product"}</h1>
						</div>
					</div>) : null }
					<div className="row mb-2">
						<div className={ !props.hideHeader ? `col-12 col-lg-10` : 'col-12'}>
							{typesAndValues.length > 0 &&
								Object.keys(initData).length > 0 && (
									<>
										<CustomForm
											onHandleFormClose={() => {}}
											onHandleSubmit={onHandleSubmit}
											typesAndValuesFields={typesAndValues}
											initData={initData}
											formTittle={""}
											dontShowCancelButton={true}
											id={null}
											getUrlPath={""}
											inputConf={{
												marginTop: "1",
												marginBottom: "0",
												stringCol:"col-6 col-sm-6"
											}}
											buttonClick={buttonClick}
											onChangeFields={onChangeFieldsInitDataPartNumber}
											initOnChangeFields={onChangeFieldsInitDataPartNumber}
											dontShowSubmitButton={true}
											childFunc={childFunc}
											dontShowErrosinOnChange={true}
											getObjectInfoOnChange={() => {}}
											notifyChange={() => {}}
											handlerSiblings={handleSiblings}
										/>
									</>
								)}
							<ProductDetail hideHeader={props.hideHeader} setItems={setItems} items={items} locations={locations} warehouses={warehousesList} />
						</div>
						<div className={ !props.hideHeader ? `col-12 col-lg-2` : 'col-12'}>
							<ImageInput
								from_form={true}
								className="mt-2 mb-2"
								element_index={0}
								onElementChange={setItemImageToUpdate}
								element={{}}
								isEdition={false}
								preview_image={preview_image}
								setImagePreview={setImagePreview}
								setItemImageToAdd={setItemImageToAdd}
								itemImageToAdd={itemImageToAdd}
								setItemImageArray={setItemImageArray}
								itemImageArray={itemImageArray}
							/>
							{ itemImageArray.length && !props.hideHeader ? (
								<Box
									sx={{
										overflowX: "auto",
										overflowY: "hidden",
										height: "150px",
									}}
								>
									<CustomImageViewer
										itemData={itemImageArray}
										onClickImage={onClickImage}
										onDeleteImage={viewDeleteImageModal}
									/>
								</Box>
							) : null }
						</div>
					</div>
				</div>
				<CustomModal
					show={showDeleteImageModal}
					customTittleText={"Delete image"}
					customMessageModal={"Are you sure you want to delete this image?"}
					onHandleClose={hideDeleteImageModal}
					onHandleSuccess={deleteImageConfirm}
					isLoading={isLoading}
					showSuccessButton={true}
				/>
				<CustomModal
					show={isLoading}
					customTittleText={""}
					customMessageModal={""}
					onHandleClose={() => {}}
					isLoading={isLoading}
				/>
				<CustomModal
					show={showModal}
					customTittleText={customTittleText}
					customMessageModal={customMessageModal}
					onHandleClose={() => setShowModal(false)}
					onHandleSuccess={() => {}}
					isLoading={isLoading}
					showSuccessButton={showSuccessButton}
				/>
			</ExpensiveContextProvider>
		</>
	);
}

export { ProductForm };
