import React from 'react';
import {DialogContent, DialogTitle, Slide, Dialog, makeStyles} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(({
  title: {
    padding: '1rem 1rem .5rem',
    '& > .MuiTypography-root': {
      fontSize: '1rem',
      fontWeight: '600',
      color: 'var(--bs-gray-dark)',
      fontFamily: 'Inter, sans-serif'
    }
  },
  content: {
    padding: '.5rem 1rem 1rem'
  }
}));

export default function DialogComponent({ children, title, open = false, onClose, fullWidth = false, onActive, style, ...restProps }) {
  const classes = useStyles();

  React.useEffect(() => {
    if(open) {
      if(onActive && onActive instanceof Function) {
        onActive();
      }
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => "backdropClick"}
        fullWidth={fullWidth}
        style={{ '--bg-body': '#fff', zIndex: '1500', ...style }}
        {...restProps}
      >
        {!!title && <DialogTitle className={classes.title}>{title}</DialogTitle>}
        {!!children && <DialogContent className={classes.content}>{children(onClose)}</DialogContent>}
      </Dialog>
    </>
  );
}
