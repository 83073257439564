import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import PlaylistAddTwoToneIcon from '@material-ui/icons/PlaylistAddTwoTone';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Box, ButtonGroup, FormGroup, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import moment from 'moment';

import QueryParams from '../../../utils/queryParams';
import { 
	findRoleInUser,
	POSTED_STATE,
	ENTRY_STATE,
	COMPLETE_STATE,
	CANCEL_STATE
} from "../../../context/constants";
import Adapter from './adjustment.adapter';

import MaterialUI from "../../../components/MaterialUI";
import Panel from "../../../components/Panel";
import { ProductForm } from "../../ProductForm/ProductForm";
import AlertAction from '../../../components/AlertAction';

import { useModalHook } from "../../../customHooks/useModalHook";
import { useApiRequestWareHouse } from "../../../customHooks/useApiRequestWareHouse";
import { useApiCustomRequest } from "../../../customHooks/useApiCustomRequest";
import { useAdjustment } from '../../../customHooks/useAdjustment';

import initState from './initState';
import AlertComponent from "../../../components/Alert";

const useStyles = makeStyles({
  table: {
		padding: 0,
    minWidth: 650,
  },
	container: {
		maxHeight: 450
	}
});

const rowHeaders = ['Actions', 'ID', 'Name', 'SKU', 'Location', 'Adj Quantity', 'Inv Amount', 'Count', 'Posted', 'Change P. Status'];

const messages = {
	addToList: "Successfully added to the list",
	updateToList: "Successfully updated to the list",
	removeToList: "Successfully removed from the list",
}

function AdjustmentForm() {
	const classes = useStyles();
	const userData = JSON.parse(localStorage.getItem("user_data"));
	const inputQuantityRef = React.useRef(null);
	const { search } = useLocation();
	const { store, update, storeDetail, find, changeStateDetail, destroyDetail, updateDetail } = useAdjustment();	
	const history = useHistory();
	const [record, setRecord] = useState(undefined);
	const [loadingDetails, setLoadingDetails] = React.useState(false);
  const [errors, setErrors] =  React.useState({});
	const [locations, setLocations] =  React.useState([]);
	const [details, setDetails] = React.useState([]);
	const [adjustment, setAdjustment] = React.useState({ ...initState.adjustmentForm });
	const [adjustmentDetail, setAdjustmentDetail] = React.useState({ ...initState.adjustmentDetailForm });
	const [openModalProduct, setOpenModalProduct] = React.useState(false);
	const [optionsListUpdate, setOptionsListUpdate] = React.useState([]);
  const handleToggleModalProduct = () => setOpenModalProduct(state => !state);
	const [updateItemToCreate, setUpdateItemToCreate] = React.useState(undefined);
	const [currentStatus, setCurrentStatus] = React.useState("");
	const [goToMainPage, setGoToMainPage] = React.useState(false);

	const handleChange = (typeForm, key, value) => {
    if(typeForm == 'adjustmentForm') {
			setAdjustment(state => ({ ...state, [key]: value }));
		} else if(typeForm == 'adjustmentDetailForm') {
			setAdjustmentDetail(state => ({ ...state, [key]: value }));
			if(key === 'item_id' && value)
				inputQuantityRef.current.focus()
		}
    setErrors((prev) => ({
      ...prev,
      [key]: ''
    }));
  }

	const { manageResponseErrors, manageResponse, manageSuccessfullMesssage } = useModalHook();
	const { setSincronizedItems, setIsLoading, getListItem, createItemDetail } = useApiCustomRequest(
		manageResponseErrors,
		"adjustments",
		"Adjustment",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		setGoToMainPage,
	);

	const { warehouse } = useApiRequestWareHouse(manageResponseErrors);
	const warehouseList = React.useMemo(() => {
    if (!Array.isArray(warehouse)) return [];
		return warehouse.map(Adapter.responseWarehouseToAutocomplete);
	}, [warehouse]);
	
	const fetchLocations = useCallback(async() => {
		const _locations = await getListItem("locations", "Locations", {})
		setLocations(() => _locations.map(Adapter.responseLocationToAutocomplete));
	}, []);

	useEffect(() => {
		const { adjustment_id } = QueryParams.toObject(search);
		if (adjustment_id) {
			find(adjustment_id)
				.then(({ status, data }) => {
					if (!status) return
					setAdjustment(() => ({
						id: data.id,
						warehouse: Adapter.responseWarehouseToAutocomplete(data.warehouse),
						postdate: moment(data.postdate).format('YYYY-MM-DD'),
						status: data.status,
					}));
					if(data.details.length) {
						const _details = data.details.map(Adapter.prepareRequestForDetailList);
						setDetails(_details);
					}
				});
    } else {
			setAdjustment((prev) => ({
				...prev,
				warehouse: Adapter.responseWarehouseToAutocomplete(userData.warehouse)
			}));
		}
	}, [search]);	

	useEffect(() => {
		const { id } = QueryParams.toObject(search);
		if(!id) {
			const _location = locations.find(c => c.isDefault);
			if(_location) {
				setAdjustmentDetail((prev) => ({
          ...prev,
          location_id: _location,
        }));
			}
		}
	}, [locations]);

	useEffect(() => {
		fetchLocations();
	}, []);

	const [initDataAdjustment] = useState({});
	const [show_add, setShowAdd] = React.useState(false);

	const getElementWhenAddNewItem = () => {
		setIsLoading(true);
		setSincronizedItems(false);
	};

	const handleSubmit = async () => {
		try {
			const payloadAdjustment = {
				id: adjustment.id,
				warehouse: adjustment.warehouse.id,
				postdate: adjustment.postdate,
			};
			if(payloadAdjustment.id) {
				await update(payloadAdjustment);
			} else {
				const { status, data: stored } = await store({
					warehouse: payloadAdjustment.warehouse,
					postdate: payloadAdjustment.postdate,
				});
				if(status && details.length) {
					const payloadsAdjustmentDetail = details.map(item => Adapter.prepareAdjustmentDetailToStore(item, stored.id));
					await Promise.all(payloadsAdjustmentDetail.map(storeDetail))
				}
			}
			gotBack();
		}
		catch (error) {}
		finally {}
	}

	const handleCloseAdd = async (...payload) => {};

	const validateFormDetail = () => {
		const errors = {};
		if (!adjustmentDetail.location_id) {
      errors.location_id = 'This field is required';
    }
		if (!adjustmentDetail.item_id) {
      errors.item_id = 'This field is required';
    }
		if (!adjustmentDetail.quantity) {
      errors.quantity = 'This field is required';
    }
		const isValid = Object.keys(errors).length === 0;
		return { isValid, errors }
	}
	
	const handleAddItemToDetailsClick = async () => {
		const { isValid, errors : _errors } = validateFormDetail();
		if(!isValid) return setErrors(_errors);
		const item = Adapter.prepareForFormDetailList(adjustmentDetail);
		if(adjustment.id) {
			if(!item.id) {
				const index = details.findIndex(c => c.productId === item.productId);
				if(index !== -1) return cleanFormDetail();
				const payload = Adapter.prepareAdjustmentDetailToStore(item, adjustment.id);
				const { status, data } = await storeDetail(payload);
				if(status) addItemToDetails(Adapter.prepareRequestForDetailList(data), item.productId);
			} else {
				const { adjustment_id, ...payload } = Adapter.prepareAdjustmentDetailToStore(item);
				const { status, data } = await updateDetail({ id: item.id, ...payload });
				if(status) updateListDetails(Adapter.prepareRequestForDetailList(data));
			}
		} else {
			addItemToDetails(item, item.productId);
			if(updateItemToCreate) setUpdateItemToCreate(undefined);
		}
		cleanFormDetail()
	}

	const addItemToDetails = (newItem, productId) => {
		setDetails((prev) => {
			const copy = [...prev];
			const index = prev.findIndex(c => c.productId === productId);
			if(index === -1) {
				setAlertMessage(messages.addToList);
				setShowAlert(true);
				return [...prev, newItem];
			}
			setAlertMessage(messages.updateToList);
			setShowAlert(true);
			copy.splice(index, 1, newItem);
			return copy;
		});
	};

	const removeItem = async (row, index) => {
		if(row.id) return setRecord(row)
		setAlertMessage(messages.removeToList);
		setShowAlert(true);
		setDetails((prev) => prev.filter((_, i) => i !== index));
	};

	const handleChangeStateClick = async (row) => {
		try {
			setLoadingDetails(true);
			const { status } = await changeStateDetail({ id: row.id, posted: !row.posted });
			if(!status) return;
			row.posted = !row.posted;
			updateListDetails(row);
		} catch (error) {
		} finally {
			setLoadingDetails(false);
		}
	};

	const updateListDetails = (currentRow) => {
		setDetails((prev) => {
			const rowIndex = prev.findIndex(item => item.id === currentRow.id);
			const copyPrev = [...prev];
			copyPrev.splice(rowIndex, 1, currentRow);
			return copyPrev;
		});
	}

	const handleDestroyDetail = async (id) => {
		try {
			setLoadingDetails(true);
      const { status } = await destroyDetail(id);
      return status;
    } catch (error) {
		} finally {
			setLoadingDetails(false);
		}
	}

	const handlerAlertDestroyDetail = async (action) => {
		if(action === 'cancel') return setRecord(undefined);
		try {
			const status = await handleDestroyDetail(record.id);
			if(!status) return;
			setRecord(undefined);
			setAlertMessage(messages.removeToList);
			setDetails((prev) => {
				const copyPrev = [...prev];
				const index = copyPrev.findIndex(item => item.id === record.id);
				copyPrev.splice(index, 1);
				return copyPrev;
			});
		} catch (error) {
		} finally {}
	}

	const editItem = async (row) => {
		setErrors({});
		setAdjustmentDetail(initState.adjustmentDetailForm);
		await setOptionsListUpdate([Adapter.prepareDetailListFromProductToAutocomplete(row)]);
		setAdjustmentDetail(Adapter.prepareDetailListFromForm(row));
		if(!row.id) {
			setUpdateItemToCreate(row.productId)
		}
	};
	
	const cleanFormDetail = () => {
		setAdjustmentDetail((prev) => ({
			...prev,
			id: initState.adjustmentDetailForm.item_id,
			item_id: initState.adjustmentDetailForm.item_id,
			quantity: initState.adjustmentDetailForm.quantity,
		}));
		if(updateItemToCreate) setUpdateItemToCreate(undefined);
	};
	
	const isUpdate = useCallback(() => !!adjustmentDetail.id, [adjustmentDetail]);
	const gotBack = () => history.push('/adjustment');

	const handleOnResponse = async (response) => {
		setAdjustmentDetail((prev) => ({ ...prev, item_id: null }));
		const adapted = Adapter.responseProductToAutocomplete(response);
		await setOptionsListUpdate([adapted]);
		setAdjustmentDetail((prev) => ({
			...prev,
			item_id: adapted
		}));
		handleToggleModalProduct();
	}

	const [alertMessage, setAlertMessage] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
	const handleCloseAlert = (_, reason) => {
		if (reason === 'clickaway') return;
    setShowAlert(false);
	};

	const updateAdjustmentPostStatus = async (id, status) => {
		setShowAlert(false);
		const response = await createItemDetail(
			{ status },
			"Change Status Adjustments",
			"change_status/adjustments/" + id,
			true,
		);
		setAdjustment((prev) => ({ ...prev, status: response.status }));
		setAlertMessage('It has been updated correctly');
		setShowAlert(true);
	};

	return (
		<>
			<AlertComponent
				open={showAlert} 
        handleClose={handleCloseAlert} 
        severity="success" 
        message={alertMessage}
			/>
			<AlertAction 
				icon={'question'}
				title={"Are you sure?"}
				message={'This action will permanently delete the record.'}
				handler={handlerAlertDestroyDetail}
				render={!!record}
			/>
			<Panel open={openModalProduct} anchor="right" togglePanel={handleToggleModalProduct} headerTitle="Create Product" pSize="lg">
				<ProductForm
					dontReloadToMainPage={true}
					showCancelButton={true}
					onHandleCancelButton={handleCloseAdd}
					onResponse={handleOnResponse}
					getElementWhenAddNewItem={getElementWhenAddNewItem}
					skuNumber={initDataAdjustment.item_id || null}
					handleCloseAdd={() => setShowAdd(false)}
					dontShowTabs={true}
					withoutData={true}
					hideHeader
				/>
			</Panel>
			<article className="contenedor container-fluid">
				<Grid container>
					<Grid item xs={12}>
						<div className="d-flex gap-2 align-items-center mb-3">
							<MaterialUI.Button.Dark onClick={gotBack}>
								<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
							</MaterialUI.Button.Dark>
							<div className="container-title">
								<h1 className="container__title mb-0">{ !!adjustment.id ? 'Update' : 'Create'} Adjustment</h1>
							</div>
						</div>
					</Grid>
				</Grid>
				<Grid component={'form'} onSubmit={(ev) => ev.preventDefault()} noValidate autoComplete='off' container spacing={1}>
					{ !!adjustment.id && (
						<Grid item xs={12} className="text-center">
							<p className="mb-1 fs-small">State: <strong className="fs-small">{adjustment.status ?? '-'}</strong></p>
							{/* <ButtonGroup disableElevation> */}
							<div className="btn-group">
								<MaterialUI.Button.Warning 
									onClick={handleSubmit}
									disabled={ adjustment.status === POSTED_STATE || adjustment.status === CANCEL_STATE }
								>
									<span className="fs-small">Save</span>
								</MaterialUI.Button.Warning>
								<MaterialUI.Button.Primary onClick={() => updateAdjustmentPostStatus(adjustment.id, ENTRY_STATE)}>
									<span className="fs-small">{ENTRY_STATE}</span>
								</MaterialUI.Button.Primary>
								<MaterialUI.Button.Success
									onClick={ () => updateAdjustmentPostStatus(adjustment.id, COMPLETE_STATE) }
									disabled={ adjustment.status === POSTED_STATE || adjustment.status === CANCEL_STATE }
								>
									<span className="fs-small">{COMPLETE_STATE}</span>
								</MaterialUI.Button.Success>
								<MaterialUI.Button.Dark onClick={() => updateAdjustmentPostStatus(adjustment.id, POSTED_STATE)}>
									<span className="fs-small">{POSTED_STATE}</span>
								</MaterialUI.Button.Dark>
								<MaterialUI.Button.Danger onClick={() => updateAdjustmentPostStatus(adjustment.id, CANCEL_STATE)}>
									<span className="fs-small">{CANCEL_STATE}</span>
								</MaterialUI.Button.Danger>
							</div>
							{/* </ButtonGroup> */}
						</Grid>
					)}
					{ !!adjustment.id ? (
						<Grid item xs={12} md={6} lg={4}>
							<MaterialUI.Input label="ID" InputLabelProps={{ shrink: true }} state={adjustment.id} disabled />
						</Grid>
					) : null }
					<Grid item xs={12} md={6} lg={4}>
						<MaterialUI.Autocomplete
							label="Warehouse"
							optionsList={warehouseList}
							disabled={findRoleInUser(userData)}
							state={adjustment.warehouse}
							setState={(value) => handleChange('adjustmentForm', 'warehouse', value)}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<MaterialUI.Input
							type="date"
							label="Post Date"
							state={adjustment.postdate}
							setState={(value) => handleChange('adjustmentForm', 'postdate', value)}
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
					<Grid item xs={12} className="pb-0">
						<h6 className="sub__title mb-0">Form Details</h6>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<FormGroup className='flex-row flex-nowrap'>
							<MaterialUI.AutocompleteAsync
								uri={'api/items'}
								params={['search_text']}
								label="Search by SKU or Name"
								keyName={'name'}
								state={adjustmentDetail.item_id}
								setState={(value) => handleChange('adjustmentDetailForm', 'item_id', value)}
								error={errors.item_id}
								className={!adjustmentDetail.id ? 'roundedSides' : ''}
								adapter={Adapter.responseProductToAutocomplete}
								responseAdaptar={(response) => response.data}
								getOptionSelected={(option, value) => option?.id == value?.id}
								getOptionLabel={(option) => (`${option.sku} - ${option.name}`) ?? ''}
								initOptions={optionsListUpdate}
								disabled={!!adjustmentDetail.id || !!updateItemToCreate}
							>
								{(!adjustmentDetail.id || !updateItemToCreate) && (
									<MaterialUI.Button.Primary type={'button'} className="roundedSides" size='small' onClick={handleToggleModalProduct}>
										<AddCircleOutlineSharpIcon fontSize='small' />
									</MaterialUI.Button.Primary>
								)}
							</MaterialUI.AutocompleteAsync>
						</FormGroup>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<MaterialUI.Autocomplete
							label="Location"
							optionsList={locations}
							state={adjustmentDetail.location_id}
							setState={(value) => handleChange('adjustmentDetailForm', 'location_id', value)}
							error={errors.location_id}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={2}>
						<MaterialUI.Input
							type="number"
							min="0"
							label="Quantity"
							state={adjustmentDetail.quantity}
							setState={(value) => handleChange('adjustmentDetailForm', 'quantity', value)}
							error={errors.quantity}
							inputRef={inputQuantityRef}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={2}>
						<Box display="flex" className="gap-1" justifyContent="flex-end">
							{ isUpdate() ? (
								<>
									<MaterialUI.Button.Primary type={"button"} title="Update" fullWidth onClick={handleAddItemToDetailsClick}>
										Update
									</MaterialUI.Button.Primary>
									<MaterialUI.Button.Danger type={"button"} title="Cancel" fullWidth onClick={cleanFormDetail}>
										Cancel
									</MaterialUI.Button.Danger>
								</>
							) : (
								<>
									<MaterialUI.Button.Dark type={"button"} title={!!updateItemToCreate ? 'Update' : 'Add to list'} fullWidth onClick={handleAddItemToDetailsClick}>
										{ !!updateItemToCreate ? "Update" : <><AddCircleOutlineIcon className="pe-1"/> Add to List</>}
									</MaterialUI.Button.Dark>
									{ !!updateItemToCreate && (
										<MaterialUI.Button.Danger type={"button"} title="Cancel" fullWidth onClick={cleanFormDetail}>
											Cancel
										</MaterialUI.Button.Danger>
									)}
								</>
							)}
						</Box>
					</Grid>
					<Grid item xs={12} className="pb-0">
						<h6 className="sub__title mb-0">List Details</h6>
					</Grid>
					<Grid item xs={12}>
						<Paper>
							<TableContainer className={classes.container}>
								<Table stickyHeader className={classes.table} aria-label="simple table" size="small">
									<TableHead>
										<TableRow>
											{rowHeaders.map((row, index) => {
												if(!adjustment.id && index >= 6) return null; 
												return <TableCell key={index} className="fs-small" align="center">{row}</TableCell>;
											})}
										</TableRow>
									</TableHead>
									<TableBody>
										{ !!details.length ? details.map((row, index) => (
											<TableRow key={index}>
												<TableCell align="center" scope="row" style={{ inlineSize: '130px', minInlineSize: '130px'}}>
													<MaterialUI.Button.Primary type="button" size="small" className="mw-auto me-1" disabled={row.posted || isUpdate() || !!updateItemToCreate} onClick={() => editItem(row)}>
														<EditIcon fontSize="small"/>
													</MaterialUI.Button.Primary>
													<MaterialUI.Button.Danger type="button" size="small" className="mw-auto" disabled={row.posted || isUpdate() || !!updateItemToCreate} onClick={() => removeItem(row, index)}>
														<DeleteIcon fontSize="small"/>
													</MaterialUI.Button.Danger>
												</TableCell>
												<TableCell className="px-1 fs-small" align="center">{row?.productId}</TableCell>
												<TableCell className="px-1 fs-small" align="center">{row?.productName}</TableCell>
												<TableCell className="px-1 fs-small" align="center">{row?.productSKU}</TableCell>
												<TableCell className="px-1 fs-small" align="center">{row?.locationName}</TableCell>
												<TableCell className="px-1 fs-small" align="center">{row?.quantity}</TableCell>
												{ !!adjustment.id && !!row.id && (
													<>
														<TableCell className="px-1 fs-small" align="center">{row?.invAmount}</TableCell>
														<TableCell className="px-1 fs-small" align="center">{row?.count}</TableCell>
														<TableCell className="px-1 fs-small" align="center">{row?.posted ? 'Posted' : 'Unposted'}</TableCell>
														<TableCell className="px-1 fs-small" align="center">
															{row?.posted ? (
																<MaterialUI.Button.Danger type="button" disabled={loadingDetails || isUpdate()} onClick={() => handleChangeStateClick(row)} size="small" style={{ minWidth: '100px' }}>
																	<RemoveCircleIcon fontSize="small" className="pe-1"/> Unposted
																</MaterialUI.Button.Danger>
															) : (
																<MaterialUI.Button.Success type="button" disabled={loadingDetails || isUpdate()} onClick={() => handleChangeStateClick(row)} size="small" style={{ minWidth: '100px' }}>
																	<CheckCircleIcon fontSize="small" className="pe-1"/> Posted
																</MaterialUI.Button.Success>
															)}
														</TableCell>
													</>
												)}
											</TableRow>
										)) : (
											<TableRow>
												<TableCell colSpan={rowHeaders.length} align="center">
													<Box className="p-3">
														<PlaylistAddTwoToneIcon fontSize="large" className="d-block m-auto mb-2"/>
														Empty list
													</Box>
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</Grid>
					<Grid item xs={12} className='text-end'>
						<MaterialUI.Button.Success type={'button'} onClick={handleSubmit} >
							<SaveOutlinedIcon fontSize="small" className="me-2" /> {!!adjustment.id ? 'Update' : 'Save'}
						</MaterialUI.Button.Success>
					</Grid>		
				</Grid>
			</article>
		</>
	);
}

export { AdjustmentForm };
