import { useCallback, useEffect, useState } from 'react';
import TwilioService from '../services/TwilioService';

const initialState = {
  twilio_account_sid: "",
  twilio_auth_token: "",
  twillio_from: "",
  status: 0,
}

class AdapterTwilio {
  static response(data) {
    return {
      ...data,
      status: +data.status
    }
  }
}

const useApiRequestSettingSmsTwilio = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [twilio, setTwilio] = useState({ ...initialState });
  const [error, setError] = useState({});

  const fetchData = useCallback(async() => {
    try {
      setError({})
      setIsLoading(true);
      const { status, data } = await TwilioService.getTwilioSetting();
      if(status) setTwilio(() => AdapterTwilio.response(data));
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  const update = useCallback(async(payload) => {
    try {
      setError({})
      setIsLoading(true);
      const resp = await TwilioService.updateTwilioSetting(payload);
      if(resp.status) setTwilio(() => AdapterTwilio.response(resp.data));
      else {
        setError(resp.msg);
        throw new Error("Error updating setting SMS Twilio");
      }
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const removeFromErrorByKey = (key) => {
    setError({
    ...error,
      [key]: ''
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return {
    removeFromErrorByKey,
    update,
    fetchData,
    setTwilio,

    isLoading,
    twilio,
    error
  }
}

export default useApiRequestSettingSmsTwilio;