import { appLocalStorage } from "../../utils/localStorage";
import {
	INITIAL_LOCAL_STORAGE_APP_STATE,
	LOCAL_STORAGE_APP_CONTEXT_DATA_VAR_NAME,
} from "../../utils/constants";

const reducerDynamic = (data, newData) => {
	if (newData === null) {
		appLocalStorage.removeItem(LOCAL_STORAGE_APP_CONTEXT_DATA_VAR_NAME);
		return INITIAL_LOCAL_STORAGE_APP_STATE;
	}
	return { ...data, ...newData };
};

export default reducerDynamic;
