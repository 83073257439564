import "./userManagment.css";
import React from "react";
import { useHistory } from "react-router-dom";
import "../../components/table_crud/style.css";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomFilterComponent } from "../../components/CustomFiltersComponent/CustomFiltersComponent";
import { CustomSelect } from "../../components/customSelect/customSelect";

//Custom Hooks
import { useMenuActionHook } from "../../customHooks/useMenuActionHook";
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiRequestUser } from "../../customHooks/useApiRequestUser";

import MenuItem from "@material-ui/core/MenuItem";

import {
	received,
	complete,
	posted,
	styles,
	tableHeadersUsers as tableHeaders,
	dataFieldsUsers as dataFields,
	composedFieldsUsers as composedFields,
	arrayFieldsUsers as arrayFields,
} from "../../context/constants";

function UserManagment() {
	const {
		anchorEl,
		setAnchorEl,
		onMenuActions,
		onCloseModal,
		currentElement,
	} = useMenuActionHook();

	const { showModal, setShowModal } = useModalHook();

	const {
		isLoading,
		filtered_data,
		deleteUser,
		filtersDefinition,
		onChangeDateFilter,
		filterInputChange,
		setSincronizedItems,
	} = useApiRequestUser();

	const prepareToUpdate = (_i) => {
		setAnchorEl(null);
		history.push({
			pathname: "/profiles/" + currentElement.id,
			state: { user: currentElement, titulo: "Edit User" },
		});
		setAnchorEl(null);
	};

	const prepareToDelete = () => {
		setShowModal(true);
		setAnchorEl(null);
	};

	const actionsDefinition = [
		{
			name: "Edit",
			func: prepareToUpdate,
		},
		{
			name: "Delete",
			func: prepareToDelete,
		},
	];

	const history = useHistory();

	return (
		<div className="container-fluid contenedor">
			<div className="container-title mb-3">
				<h1 className="container__title">User Management</h1>
			</div>
			<CustomFilterComponent
				filtersDefinition={filtersDefinition}
				onClickfilter={() => setSincronizedItems(false)}
				filterInputChange={filterInputChange}
				onCreateNewItem={() => history.push("/profiles")}
				showDateFilters={true}
				onChangeDateFilter={onChangeDateFilter}
				dontShowPerPageSelect={true}
				styles={styles}
				onChangePageEvent={() => {}}
				renderFilter={(filter, index) => (
					<CustomSelect
						key={index}
						field_id={filter.field_id}
						selectTittle={filter.selectTittle}
						styles={filter.styles}
						defaultOptionText={filter.defaultOptionText}
						itemArray={filter.itemArray}
						onChangeEvent={filter.func}
					/>
				)}
			/>

			<div className="">
				<CustomDataList
					isLoading={isLoading}
					dataList={filtered_data}
					tableHeaders={tableHeaders}
					dontShowPerPageSelect={true}
					render={(element, index) => (
						<CustomDataListItem
							key={index}
							dataFields={dataFields}
							imageFields={[]}
							composedFields={composedFields}
							arrayFields={arrayFields}
							element={element}
							index={index}
							no_image={null}
							renderActions={() => (
								<>
									<PositionedMenu
										title={"Actions"}
										anchorEl={anchorEl}
										setAnchorEl={setAnchorEl}
										handleClose={onCloseModal}
										handleClick={(event) =>
											onMenuActions(event, element)
										}
										actionsDefinition={actionsDefinition}
										renderActions={(action, index) => (
											<MenuItem
												key={index}
												onClick={() => action.func()}
											>
												<div
													className="items-option"
													style={{
														padding: "0 5px",
														color: "grey",
													}}
												>
													<FontAwesomeIcon
														style={{
															margin: "4px",
															marginRight: "10px",
															fontSize: "12px",
														}}
														icon={faTrash}
													/>
													<p>{action.name}</p>
													<hr />
												</div>
											</MenuItem>
										)}
									></PositionedMenu>
								</>
							)}
							posted={posted}
							complete={complete}
							received={received}
						/>
					)}
				/>
			</div>
			<CustomModal
				show={showModal}
				customTittleText={"Delete User"}
				customMessageModal={""}
				onHandleClose={() => setShowModal(false)}
				showSuccessButton={true}
				onHandleSuccess={() => deleteUser(currentElement?.id)}
			/>
		</div>
	);
}

export { UserManagment };
