import HttpClient from "./newHttp";
import axios from "axios";

export const resourcesHTTP = new (class InventoryClient extends HttpClient {
	constructor() {
		super("api/get_resource_data", 300000);
	}

	async getResources(params = {}) {
		const { data } = await this.http.get("", { params });
		return data;
	}
})();

export const customClientHTTP = new (class InventoryClient extends HttpClient {
	constructor() {
		super("", 300000);
	}

	async getList(url, params = {}) {
		const { data } = await this.http.get(url, { params });
		return data;
	}
})();

export const userClientHTTP = new (class InventoryClient extends HttpClient {
	constructor() {
		super("/api/my-user", 300000);
	}

	async updateUser(requestData) {
		const { data } = await axios.put(
			(process.env.REACT_APP_API_URL + "/api/my-user").replace(
				"m//",
				"m/",
			),
			new URLSearchParams({ ...requestData }),
			{
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
					Authorization:
						"Bearer " +
						(localStorage.jwt_token !== undefined
							? JSON.parse(localStorage.jwt_token)
							: ""),
				},
			},
		);
		return data;
	}
})();

export const companyClientHTTP = new (class InventoryClient extends HttpClient {
	constructor() {
		super("/api/companies/", 300000);
	}

	async updateCompany(formData, id) {
		const { data } = await this.http.post("" + id, formData);
		return data;
	}
})();
