import { ContactSupportOutlined } from "@material-ui/icons";
import React from "react";
import "./style.css";

function CheckBoxInput({ element, element_index, onElementChange, className }) {
	const [array_elements, setArrayElements] = React.useState([]);

	const [filter_input, setFilterInput] = React.useState("");

	const [selected_values, setSelectedValues] = React.useState(
		element.value || [],
	);

	React.useEffect(() => {
		setArrayElements([...element.array_values]);
	}, [element]);

	const filterItems = (event) => {
		setFilterInput(event.target.value);

		let array = element.array_values;

		array = array.filter((element_array, index) => {
			if (
				element_array.name
					.toLowerCase()
					.includes(event.target.value.toLowerCase())
			) {
				return element_array;
			}
		});

		setArrayElements(array);
	};

	const pressValues = (e, values) => {
		let array = [...element.value];

		if (e.target.checked) {
			let new_value = { id: values };
			array.push(new_value);
		}

		if (!e.target.checked) {
			let index = array.findIndex((element) => element.id === values);
			array.splice(index, 1);
		}

		setSelectedValues(array);
	};

	React.useEffect(() => {
		onElementChange(selected_values, element_index);
	}, [selected_values]);

	const returnChecked = (value) => {
		let exists = element.value.find((item) => item.id === value);

		if (exists) {
			return true;
		}

		return false;
	};

	return (
		<div
			className={
				className !== undefined ? className : "form-group mt-2 mb-2"
			}
		>
			<label htmlFor={element.field_id}>
				{" "}
				{element.field_name}{" "}
				<strong> {element.required && "*"} </strong>{" "}
			</label>

			<input
				className="form-control"
				onChange={(e) => filterItems(e)}
				value={filter_input}
				type={"text"}
			/>

			<div className="card">
				<div className="container container-for-values">
					{array_elements.map((element_2, index) => (
						<div key={index} className="form-check">
							<input
								onClick={(e) => pressValues(e, element_2.id)}
								onChange={(e) => pressValues(e, element_2.id)}
								className="form-check-input"
								type="checkbox"
								value={element_2.id}
								id={element_2.id + element_2.name}
								checked={returnChecked(element_2.id)}
							/>
							<label
								className="form-check-label"
								htmlFor={element_2.id + element_2.name}
							>
								{element_2.name}
							</label>
						</div>
					))}
				</div>

				<span className="text-danger text-end me-3">
					{" "}
					{element.required &&
						element.have_error &&
						"Required Field"}{" "}
					&nbsp;{" "}
				</span>
			</div>
		</div>
	);
}

export { CheckBoxInput };
