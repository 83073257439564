import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";

import { propTypes } from "react-bootstrap/esm/Image";
import { styled } from "@material-ui/core/styles";

const BootstrapButton = styled(Button)({
	color: "#fff",
	backgroundColor: "#0d6efd",
	"&:hover": { backgroundColor: "#0b5ed7" }
});

function PositionedMenu(props) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		if(props.setAnchorEl && props.setAnchorEl instanceof Function) {
			props.setAnchorEl(event.currentTarget);
		}
		props.handleClick(event);
  };
	
	const closeLocalMenu = () => {
		setAnchorEl(null);
	}

	const handleCloseLocal = () => {
		setAnchorEl(null);
		if(props.handleClose && props.handleClose instanceof Function) {
			props.handleClose(closeLocalMenu);
		}
	};

	const interceptorFunc = (item) => ({
		...item,
		func: () => {
			handleCloseLocal();
			item.func();
		}
	})

	return (
		<div className="text-center">
			<BootstrapButton
				variant="contained"
				size="small"
				disableRipple
				id="demo-positioned-button"
				aria-controls={open ? "demo-positioned-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				disabled={
					!props.mainButtonDisabled ? false : props.mainButtonDisabled
				}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "nowrap",
						alignContent: "center",
						alignItems: "center",
						gap: "5px",
						textTransform: 'capitalize'
					}}
				>
					{props.title} <i className="fas fa-chevron-down"></i>
				</div>
			</BootstrapButton>
			<Menu
				elevation={1}
				sx={{ boxShadow: "none" }}
				id="demo-positioned-menu"
				aria-labelledby="demo-positioned-button"
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseLocal}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
			>
				{props.actionsDefinition.map(interceptorFunc).map(props.renderActions)}
			</Menu>
		</div>
	);
}

export { PositionedMenu };
