import { useEffect, useState, useRef, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import "../InvoiceForm.css";

// components
import { CustomSideModal } from "../../../components/CustomSideModal/CustomSideModal";
import { CustomForm } from "../../../components/CustomForm/CustomForm";
import { TabsComponent } from "../../../components/TabsComponent/TabsComponent";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import { CustomDataList } from "../../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../../components/CustomDataListItem/CustomDataListItem";
import no_image from "../../../assets/img/no_image.png";
import editLogo from "../../../assets/img/edit.jpg";
import adjunt from "../../../assets/img/adjunt.png";
import { CustomButton } from "../../../components/CustomButton/CustomButton";

//custom hooks
import { useModalHook } from "../../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../../customHooks/useApiCustomRequest";
import { useMenuActionHook } from "../../../customHooks/useMenuActionHook";
import { useApiRequestWareHouse } from "../../../customHooks/useApiRequestWareHouse";
import { useApiRequestUser } from "../../../customHooks/useApiRequestUser";

// form configuration
import {
	typesAndValues as typesAndValuesFields,
	initData as initDataFields,
	typesAndValuesPartNumber as typesAndValuesPartNumberFields,
	initDataPartNumber as initDataPartNumberFields,
	typesAndValuesLabor as typesAndValuesLaborFields,
	initDataLabor as initDataLaborFields,
	typesAndValuesMessageOnInvoice as typesAndValuesMessageOnInvoiceFields,
	initDataMessageOnInvoice as initDataMessageOnInvoiceFields,
	typesAndValuesSaveDevice as typesAndValuesSaveDeviceFields,
	initDataSaveDevice as initDataSaveDeviceFields,
	PanelNames,
} from "./initFormWorkOrder";

import {
	typesAndValues as typesAndValuesTypeDevice,
	initData as initDataTypeDevice,
} from "../../../formsDefinitions/typeDevice";


import {
	styles,
	tableHeadersWorkOrderPartNumber as tableHeaders,
	dataFieldsWorkOrderPartNumber as dataFields,
	tableHeadersWorkOrderLaborCode as tableHeadersLaborCode,
	dataFieldsWorkOrderLaborCode as dataFieldsLaborCode,
	validateLength,
} from "../../../context/constants";
import { colors } from "@material-ui/core";
 
import {
	typesAndValues as typesAndValuesNewLaborFields,
	initData as initDataNewLaborFields,
} from "../../ProductForm/initLaborForm";
import { ModalAddItem } from "../../../components/modal_item_add";
import { useApirequestStockReceipt } from "../../../customHooks/useApirequestStockReceipt";
import { useApiRequestItems } from "../../../customHooks/useApiRequestItems";
import Panel from "../../../components/Panel";
import { CostumerForm } from "../../costumer/CostumerForm";
import { AppContext } from "../../../context/AppContext";

function WorkOrderForm(props) {
	const location = useLocation();
	const history = useHistory();
  const { changeNavigationVisible } = useContext(AppContext);

	useEffect(() => {
    setTimeout(() => {
      changeNavigationVisible(true);
    }, 250);
  }, []);

	const [goToMainPage, setGoToMainPage] = useState(false);

	const { anchorEl, setAnchorEl, onCloseModal, currentId, setCurrentId } =
		useMenuActionHook();

	const {
		setCustomMessageModal,
		showSuccessButton,
		setShowSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		setCustomTittleText,
		customMessageModal,
		manageResponseErrors,
		customCurrentItem,
		setCustomCurrentItem,
		manageResponse,
		manageSuccessfullMesssage,
		manageDeleteMessage,
		cancelButtonText,
		setCancelButtonText,
		sucessButtonText,
		setSucessButtonText,
		showCustomFormModal,
		setShowCustomFormModal
	} = useModalHook();

	const {
		showCustomFormModal : labor_showCustomFormModal,
		setShowCustomFormModal : labor_setShowCustomFormModal,
	} = useModalHook();

	const {
		showCustomFormModal : parts_showCustomFormModal,
		setShowCustomFormModal : parts_setShowCustomFormModal,
	} = useModalHook();

	const {
		isLoading: labor_isLoading,
		success,
		setSuccess,
		// getListItem,
		// getItem,
		// updateItem,
		// updateModel,
		// deleteItemByUrl,
		// getFile,
		createItem: createLabor,
	} = useApiCustomRequest(
		manageResponseErrors,
		"labors",
		"Labors",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		setGoToMainPage,
	);

	const {
		isLoading,
		getListItem,
		createItem,
		getItem,
		updateItem,
		updateModel,
		deleteItemByUrl,
		getFile,
		createItemDetail,
	} = useApiCustomRequest(
		manageResponseErrors,
		"work-orders",
		"Work Order",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		setGoToMainPage,
	);

	const {
		isLoading: typeDevice_isLoading,
		createItem: typeDevice_createItem,
		sincronizedItems : sincronizedItems_devices
	} = useApiCustomRequest(
		manageResponseErrors,
		"type-devices",
		"Device Types",
		manageResponse,
	);

	const [show, setShow] = useState(false);
	const [changeInForm, setChangeInForm] = useState(false);
	const [initData, setInitData] = useState({});
	const [initDataPartNumber, setInitDataPartNumber] = useState({});
	const [initDataLabor, setInitDataLabor] = useState({});
	const [initDataMessageOnInvoice, setInitDataMessageOnInvoice] = useState(
		{},
	);
	const [initDataSaveDevice, setInitDataSaveDevice] = useState({});
	const [typesAndValues, setTypesAndValues] = useState([]);
	const [typesAndValuesPartNumber, setTypesAndValuesPartNumber] = useState(
		[],
	);
	const [typesAndValuesLabor, setTypesAndValuesLabor] = useState([]);
	const [typesAndValuesMessageOnInvoice, setTypesAndValuesMessageOnInvoice] =
		useState([]);
	const [typesAndValuesSaveDevice, setTypesAndValuesSaveDevice] = useState(
		[],
	);
	const [currentTabValue, setCurrentTabValue] = useState(0);

	const [partNumberList, setPartNumberList] = useState([]);

	const [laborCodeList, setLaborCodeList] = useState([]);

	const userData = JSON.parse(localStorage.getItem("user_data"));
	// device fields
	useEffect(async () => {
		let initDataSaveDeviceCopy = { ...initDataSaveDeviceFields };
		let typesAndValuesSaveDeviceCopy = [...typesAndValuesSaveDeviceFields];

		let deviceTypes = await getListItem("type-devices", "Device Types", {});

		typesAndValuesSaveDeviceCopy = setValueInArray(
			typesAndValuesSaveDeviceCopy,
			"type_resource_id",
			"values",
			deviceTypes,
		);

		setInitDataSaveDevice(initDataSaveDeviceCopy);
		setTypesAndValuesSaveDevice(typesAndValuesSaveDeviceCopy);
	}, []);

	//work order fields
	useEffect(async () => {
		let initDataCopy = { ...initDataFields };
		let initDataPartNumberCopy = { ...initDataPartNumberFields };
		let initDataLaborCopy = { ...initDataLaborFields };
		let initDataMessageOnInvoiceCopy = {
			...initDataMessageOnInvoiceFields,
		};
		let initDataSaveDeviceCopy = { ...initDataSaveDeviceFields };
		let typesAndValuesCopy = [...typesAndValuesFields];
		let typesAndValuesPartNumberCopy = [...typesAndValuesPartNumberFields];
		let typesAndValuesLaborCopy = [...typesAndValuesLaborFields];
		let typesAndValuesMessageOnInvoiceCopy = [
			...typesAndValuesMessageOnInvoiceFields,
		];
		let typesAndValuesSaveDeviceCopy = [...typesAndValuesSaveDeviceFields];

		let typeWorkOrders = await getListItem(
			"type-work-orders",
			"Type work order",
			{},
		);

		typesAndValuesCopy = setValueInArray(
			typesAndValuesCopy,
			"client_id",
			"doRequest",
			false,
		);
		typesAndValuesCopy = setValueInArray(
			typesAndValuesCopy,
			"client_id",
			"inputValue",
			``,
		);

		if (
			location.hasOwnProperty("data") &&
			location?.data.hasOwnProperty("customerData") &&
			Object.keys(location.data.customerData).length > 0
		) {
			const { id, name } = location.data.customerData;

			initDataCopy = {
				...initDataCopy,
				client_id: id,
			};

			typesAndValuesCopy = setValueInArray(
				typesAndValuesCopy,
				"client_id",
				"inputValue",
				`${name}`,
			);
			typesAndValuesCopy = setValueInArray(
				typesAndValuesCopy,
				"client_id",
				"doRequest",
				false,
			);
			typesAndValuesCopy = setValueInArray(
				typesAndValuesCopy,
				"client_id",
				"dontInitData",
				true,
			);

			initDataSaveDeviceCopy = {
				...initDataSaveDeviceCopy,
				customer_id: id,
			};

			typesAndValuesSaveDeviceCopy = setValueInArray(
				typesAndValuesSaveDeviceCopy,
				"customer_id",
				"inputValue",
				`${name}`,
			);
			typesAndValuesSaveDeviceCopy = setValueInArray(
				typesAndValuesSaveDeviceCopy,
				"customer_id",
				"doRequest",
				false,
			);
			typesAndValuesSaveDeviceCopy = setValueInArray(
				typesAndValuesSaveDeviceCopy,
				"customer_id",
				"dontInitData",
				true,
			);
		}

		if (typeWorkOrders.length > 0) {
			const inHouseItem = typeWorkOrders.findIndex(
				(item) => item.name === "In House",
			);
			typesAndValuesCopy = setValueInArray(
				typesAndValuesCopy,
				"type_work_order_id",
				"values",
				typeWorkOrders,
			);
			initDataCopy = {
				...initDataCopy,
				type_work_order_id:
					inHouseItem >= 0 ? typeWorkOrders[inHouseItem].id : "",
			};
		}

		initDataCopy = {
			...initDataCopy,
			date_receipt: new Date().toLocaleDateString("en-CA", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}),
		};

		const wareHouse = await getListItem("warehouse", "Warehouse", {});
		const profiles = await getListItem("profiles", "Type work order", {});

		if (wareHouse?.length > 0 && profiles?.length > 0) {
			typesAndValuesCopy = setValueInArray(
				typesAndValuesCopy,
				"warehouse_id",
				"values",
				wareHouse,
			);
			typesAndValuesCopy = setValueInArray(
				typesAndValuesCopy,
				"employee_id",
				"values",
				profiles,
			);

			initDataCopy = {
				...initDataCopy,
				warehouse_id: userData?.warehouse?.id || "",
				employee_id: userData.id || "",
			};
		}

		setInitData(initDataCopy);
		setInitDataPartNumber(initDataPartNumberCopy);
		setInitDataLabor(initDataLaborCopy);
		setInitDataSaveDevice(initDataSaveDeviceCopy);
		setInitDataMessageOnInvoice(initDataMessageOnInvoiceCopy);
		setTypesAndValues(typesAndValuesCopy);
		setTypesAndValuesPartNumber(typesAndValuesPartNumberCopy);
		setTypesAndValuesLabor(typesAndValuesLaborCopy);
		setTypesAndValuesMessageOnInvoice(typesAndValuesMessageOnInvoiceCopy);
		setTypesAndValuesSaveDevice(typesAndValuesSaveDeviceCopy);
	}, []);

	const setValueInArray = (array, fieldId, elementToUpdate, value) => {
		const arrayCopy = [...array];
		const idFieldIndex = arrayCopy.findIndex(
			(item) => item.field_name === fieldId,
		);
		let item = arrayCopy[idFieldIndex];
		item[`${elementToUpdate}`] = value;
		arrayCopy[idFieldIndex] = item;
		return arrayCopy;
	};

	const onChangeFieldsInitData = (ev) => {
		setInitData({ ...ev });
	};

	const onChangeFieldsInitDataPartNumber = async (event) => {
		setInitDataPartNumber({ ...event });
	};

	const onChangeFieldsInitDataLaborCode = async (event) => {
		setInitDataLabor({ ...event });
	};

	const onChangeFieldsInitDataMessageOnInvoice = async (event) => {
		setInitDataMessageOnInvoice({ ...event });
	};

	const childFunc = useRef(null);
	const childFunc2 = useRef(null);
	const childFunc3 = useRef(null);
	const childFunc4 = useRef(null);

	useEffect(async () => {
		let typesAndValuesSaveDeviceCopy = [...typesAndValuesSaveDeviceFields];
		let deviceTypes = await getListItem("type-devices", "Device Types", {});

		typesAndValuesSaveDeviceCopy = setValueInArray(
			typesAndValuesSaveDeviceCopy,
			"type_resource_id",
			"values",
			deviceTypes,
		);
		setTypesAndValuesSaveDevice(typesAndValuesSaveDeviceCopy);
	}, [sincronizedItems_devices]);

	const mapRequest = (bodyInclude, bodyExt) => {
		let bodyRequest = {};
		Object.keys(bodyExt).map((key) => {
			if (bodyInclude.includes(key)) {
				bodyRequest[key] = bodyExt[key];
			}
		});
		return bodyRequest;
	};

	const onHandleCreateTypeDevice = async (event) => {
		setShowCustomFormModal(false);
		const { id, ...bodyExt } = event;

		let mapBodyRequest = mapRequest(["name"], bodyExt);
		
		return await typeDevice_createItem(mapBodyRequest);
	}

	const onHandleSubmit = async (typeSave) => {
		if (isLoading) return;

		//test errors form
		const errorsOne = childFunc.current(initData);
		const errorsTwo = childFunc2.current(initDataMessageOnInvoice);

		let errorsFour = [];
		let errorsThree = [];

		if (typeSave === "Add") {
			if (currentTabValue === 0) {
				errorsFour = childFunc4.current(initDataPartNumber);
			}
			if (currentTabValue === 1) {
				errorsThree = childFunc3.current(initDataLabor);
			}
		}

		if (
			(typeSave === "save" &&
				!validateLength(errorsOne) &&
				!validateLength(errorsTwo)) ||
			(typeSave === "Add" &&
				!validateLength(errorsOne) &&
				!validateLength(errorsTwo) &&
				!validateLength(errorsFour) &&
				!validateLength(errorsThree))
		) {
			let baseRequest = {
				...initData,
				...initDataMessageOnInvoice,
			};

			if (typeSave === "Add" && currentTabValue === 0) {
				baseRequest = {
					...baseRequest,
					...initDataPartNumber,
				};
			}

			if (typeSave === "Add" && currentTabValue === 1) {
				baseRequest = {
					...baseRequest,
					...initDataLabor,
				};
			}

			console.log("pass request", baseRequest);
			let response = await createItem(
				baseRequest,
				"Work Order",
				{},
				true,
			);

			if (response && response.hasOwnProperty("id")) {
				history.push({
					pathname: "/work_order_form_update",
					search: `?work_order_id=${response.id}`,
				});
			}

			// let boy
		}
	};

	const buttonClick = (field_id, typeSave = "Add") => {
		if (isLoading) {
			return;
		}

		if (field_id === "createCustomer") {
			history.push({
				pathname: `/costumer_form`,
				data: { redirectTo: "/work_order_form" },
			});
		}

		if (field_id === "Save" || field_id === "onSubmit") {
			onHandleSubmit(typeSave);
		}

		if (field_id === "addDevice") {
			setShow(true);
		}
	};

	
	const onChangeSearchInputCustomer = (ev) => {
		let initDataSaveDeviceCopy = { ...initDataSaveDevice };
		let initDataCopy = { ...initData };
		let typesAndValuesSaveDeviceCopy = [...typesAndValuesSaveDevice];

		let typesAndValuesCopy = [...typesAndValues];

		const { name, id, devices } = ev;

		initDataSaveDeviceCopy = {
			...initDataSaveDeviceCopy,
			customer_id: id,
		};

		if (devices && devices.length > 0) {
			initDataCopy = {
				...initDataCopy,
				resource_id: devices[0]?.id || "",
				client_id: id,
			};
		}

		initDataCopy = {
			...initDataCopy,
			client_id: id,
		};

		typesAndValuesSaveDeviceCopy = setValueInArray(
			typesAndValuesSaveDeviceCopy,
			"customer_id",
			"inputValue",
			`${name}`,
		);
		typesAndValuesSaveDeviceCopy = setValueInArray(
			typesAndValuesSaveDeviceCopy,
			"customer_id",
			"doRequest",
			false,
		);
		typesAndValuesSaveDeviceCopy = setValueInArray(
			typesAndValuesSaveDeviceCopy,
			"customer_id",
			"dontInitData",
			true,
		);

		typesAndValuesCopy = setValueInArray(
			typesAndValuesCopy,
			"resource_id",
			"values",
			devices,
		);

		setInitDataSaveDevice(initDataSaveDeviceCopy);
		setTypesAndValuesSaveDevice(typesAndValuesSaveDeviceCopy);

		setTypesAndValues(typesAndValuesCopy);
		setInitData(initDataCopy);
	};

	const onDelete = () => {};

	const prepareDetailToUpdate = () => {};

	const printPreview = async () => {};

	const cancelInvoice = () => {
		if (changeInForm) {
			setShowModal(true);
			setCustomTittleText(`Leave work order creating`);
			setCustomMessageModal(`Do you want to leave without saving?`);
			setShowSuccessButton(true);
		} else {
			history.push("/invoice");
		}
	};

	const onHandleResponseModal = () => {
		setShowModal(false);
	};

	const onHandleDelete = async () => {
		setShowModal(false);
	};

	const tabOneContent = () => {
		return (
			<>
				<div className="row mt-1">
					{typesAndValuesPartNumber.length > 0 &&
						Object.keys(initDataPartNumber).length > 0 && (
							<>
								<div className="col-12">
									<CustomForm
										onHandleFormClose={() => {}}
										onHandleSubmit={onHandleSubmit}
										typesAndValuesFields={typesAndValuesPartNumber}
										initData={initDataPartNumber}
										formTittle={""}
										dontShowCancelButton={true}
										id={null}
										getUrlPath={""}
										inputConf={{ marginTop: "1", marginBottom: "0" }}
										buttonClick={buttonClick}
										onChangeFields={onChangeFieldsInitDataPartNumber}
										initOnChangeFields={onChangeFieldsInitDataPartNumber}
										dontShowSubmitButton={true}
										childFunc={childFunc4}
										dontShowErrosinOnChange={true}
										getObjectInfoOnChange={() => {}}
										notifyChange={() => {}}
										handlerSiblings={handlerSiblings}
									/>
								</div>
							</>
						)}
				</div>
				<CustomDataList
					onChangePageEvent={() => {}}
					style={styles}
					isLoading={isLoading}
					dataList={partNumberList}
					tableHeaders={tableHeaders}
					itemPagination={null}
					handlePagination={() => {}}
					dontShowPerPageSelect={true}
					showLinearIndicator={true}
					showDataInLoading={true}
					render={(element, index) => (
						<CustomDataListItem
							key={index}
							dataFields={dataFields}
							imageFields={[]}
							composedFields={[]}
							arrayFields={[]}
							element={element}
							index={index}
							no_image={no_image}
							dontShowActions={true}
							showActionsButtons={true}
							deleteRowTable={onDelete}
							renderActions={() => <></>}
							renderComponentInField={(data) => (
								<>
									{data}
									<img
										className={``}
										onClick={() =>
											prepareDetailToUpdate(data)
										}
										style={{
											height:"35px",
											width:"35px",
										}}
										src={editLogo}
										alt=""
									/>
								</>
							)}
						/>
					)}
				/>
			</>
		);
	};

	const tabTwoContent = () => {
		return (
			<>
				<div className="row mt-1">
					{typesAndValuesLabor.length > 0 &&
						Object.keys(initDataLabor).length > 0 && (
							<>
								<div className="col-12">
									<CustomForm
										onHandleFormClose={() => {}}
										onHandleSubmit={onHandleSubmit}
										typesAndValuesFields={typesAndValuesLabor}
										initData={initDataLabor}
										formTittle={""}
										dontShowCancelButton={true}
										id={null}
										getUrlPath={""}
										inputConf={{marginTop: "1", marginBottom: "0"}}
										buttonClick={buttonClick}
										onChangeFields={onChangeFieldsInitDataLaborCode}
										initOnChangeFields={onChangeFieldsInitDataLaborCode}
										dontShowSubmitButton={true}
										childFunc={childFunc3}
										dontShowErrosinOnChange={true}
										getObjectInfoOnChange={() => {}}
										notifyChange={() => setChangeInForm(true)}
										handlerSiblings={handlerSiblings}
									/>
								</div>
							</>
						)}
				</div>
				<CustomDataList
					onChangePageEvent={() => {}}
					style={styles}
					isLoading={isLoading}
					dataList={laborCodeList}
					tableHeaders={tableHeadersLaborCode}
					itemPagination={null}
					handlePagination={() => {}}
					dontShowPerPageSelect={true}
					showLinearIndicator={true}
					showDataInLoading={true}
					render={(element, index) => (
						<CustomDataListItem
							key={index}
							dataFields={dataFieldsLaborCode}
							imageFields={[]}
							composedFields={[]}
							arrayFields={[]}
							element={element}
							index={index}
							no_image={no_image}
							dontShowActions={true}
							showActionsButtons={true}
							deleteRowTable={onDelete}
							renderActions={() => <></>}
							renderComponentInField={(data) => (
								<>
									{data}
									<img
										className={``}
										onClick={() =>
											prepareDetailToUpdate(data)
										}
										style={{
											height:"35px",
											width:"35px",
										}}
										src={editLogo}
										alt=""
									/>
								</>
							)}
						/>
					)}
				/>
			</>
		);
	};

	const onHandleSaveDevice = async (ev) => {
		let initDataCopy = { ...initData };
		let typesAndValuesCopy = [...typesAndValues];

		const response = await createItemDetail(ev, "Device", "devices", true);

		setShow(false);

		if (response && Object.keys(response).length > 0) {
			const { devices } = response.customer;

			typesAndValuesCopy = setValueInArray(
				typesAndValuesCopy,
				"resource_id",
				"values",
				devices,
			);

			const currentItemIndex = devices.findIndex(
				(device) => device.name === ev.name,
			);

			initDataCopy = {
				...initDataCopy,
				resource_id: devices[currentItemIndex].id,
			};
		}

		setTypesAndValues(typesAndValuesCopy);
		setInitData(initDataCopy);
	};

	const onChangeFieldsInitDataNewLaborFields = (ev) => {
		setInitDataNewLaborFields_copy({ ...ev });
	};

	const handleCreateTypeDevice = () => {
		// console.log("hola")
		setCustomTittleText(`Create Type Device`);
		setAnchorEl(null);
		setShowCustomFormModal(true);
		setCurrentId(null);
	};

	useEffect(() => {
		if(success){
			labor_setShowCustomFormModal(false);
			setSuccess(false);
		}
	},[success])

	const onCreateLabor = async () => {
		// console.log(initDataNewLaborFields_copy)
		if (initDataNewLaborFields_copy.sku && typeof initDataNewLaborFields_copy.sku === 'string') {
			initDataNewLaborFields_copy.sku = initDataNewLaborFields_copy.sku.trim() || ''; 
		}
	
		const errors = childFunc.current(initDataNewLaborFields_copy);
		console.log(errors)
	
		if (!(errors.length > 0)) {
			let request = {
				...initDataNewLaborFields_copy,
			};
	
			let response = await createLabor(
				request,
				"Product",
				{},
				true,
				false,
			);
	
			if (response && response.hasOwnProperty("id")) {
				// history.push("/labors_list");
			}
		}
	};

	const [initDataNewLaborFields_copy, setInitDataNewLaborFields_copy] = useState(null);
	const [typesAndValuesNewLaborFields_copy, setTypesAndValuesNewLaborFields_copy] = useState(null);
	useEffect(async () => {
		let typesAndValuesFieldsCopy = [...typesAndValuesNewLaborFields];
		let initDataFieldsCopy = { ...initDataNewLaborFields };

		const uom = await getListItem("uom", "Uom", {});

		if (uom.length > 0) {
			typesAndValuesFieldsCopy = setValueInArray(
				typesAndValuesFieldsCopy,
				"uom_id",
				"values",
				uom,
			);
		}

		initDataFieldsCopy = {
			...initDataFieldsCopy,
			uom_id: uom.length > 0 ? uom[0]?.id : "",
		};

		setInitDataNewLaborFields_copy(initDataFieldsCopy);
		setTypesAndValuesNewLaborFields_copy(typesAndValuesFieldsCopy);
	}, []);

	//create parts

	const [modal_add_conf, setModalAddConf] = useState({
		title: "Add new Item",
		message: "",
	});

	const {
		getDocuments,
		deleteDocuments,
		addDocuments,
		isLoading:stock_isLoading,
		setIsLoading:stock_setIsLoading,
		addImages,
		stock_receipt_details,
		setStockReceiptDetails,
		getDataFromUser,
		setStockReceiptId,
		setSincronizedItems: setSincronizedItemsList,
		receipt_stock_number,
		setReceiptStockNumber,
		bill_number,
		setBillNumber,
		vendor_id,
		setVendorId,
		vendor_name,
		setVendorName,
		date,
		setDate,
		date_due,
		setDateDue,
		description,
		setDescription,
		payment,
		setPayment,
		quantity,
		setQuantity,
		cost,
		setCost,
		location_id,
		setLocationId,
		location_name,
		setLocationName,
		notes,
		setNotes,
		atached_document,
		setAtachedDocument,
		total_receipt,
		setTotalReceipt,
		tax_receipt,
		setTaxReceipt,
		vendors,
		setVendors,
		location_list,
		setLocationList,
		insertVendor,
		payment_number,
		setPaymentNumber,
		part_number_id,
		setPartNumberId,
		part_number_name,
		setPartNumberName,
		disabledActions,
		setDisabledActions,
		updateStockReceiptItemPostStatus,
		updateStockReceiptPostStatus,
		stockReceiptStatus,
		skuNumber,
		setSkuNumber,
		saveStockReceiptModel,
		saveStockReceiptItem,
		updateStockReceiptItem,
		currentStockReceiptCost
	} = useApirequestStockReceipt(
		setCustomTittleText,
		setCustomMessageModal,
		showSuccessButton,
		setShowModal,
	);

	const {
		filteredData: item_list,
		setSincronizedItems,
		getItemList,
		itemsNotFound,
		getSkuItemInformation,
	} = useApiRequestItems(
		stock_setIsLoading,
		setPaymentNumber,
		setPartNumberId,
		setPartNumberName,
		setPartNumberId,
		setPartNumberName,
	);

	const [template_errors, setTemplateErrors] = useState([]);
	const [template_errors_all, setTemplateErrorsAll] = useState([]);
	
	function validateFields() {
		setTemplateErrors([]);

		//validamos que existan espacios vacios

		let nedd_to_validate = false;

		if (skuNumber.toString().trim() !== "") {
			nedd_to_validate = true;
		}
		if (location_name.toString().trim() !== "") {
			nedd_to_validate = true;
		}
		if (parseFloat(cost) > 0 || cost.toString().trim() !== "") {
			nedd_to_validate = true;
		}

		if (!nedd_to_validate) {
			return false;
		}

		let falidate_fields_errors = [];

		// if(part_number_id.toString().trim() === '' ){

		// 		falidate_fields_errors.push({
		// 				'field_id' : 'part_number_id',
		// 				'message'  : 'Part Number need to be valid'
		// 		});

		// }

		if (skuNumber.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "part_number_name",
				message: "Part Number need to be valid",
			});
		}

		if (item_list.length > 0) {
			let exists_item = item_list.find(
				(element) => element.name === part_number_name.toString(),
			);

			if (exists_item === undefined) {
				falidate_fields_errors.push({
					field_id: "part_number_name",
					message: "Part number entered not exists",
				});
			}
		}

		if (quantity <= 0) {
			falidate_fields_errors.push({
				field_id: "quantity",
				message: "Quantity need to be greater than 0",
			});
		}

		if (parseFloat(cost) <= 0 || cost.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "cost",
				message: "Cost need to be greater than 0",
			});
		}

		if (falidate_fields_errors.length > 0) {
			setTemplateErrors(falidate_fields_errors);

			return false;
		}

		return true;
	}

	function addNewStockReceiptDetail(
		itemId,
		itemName,
		validateField = true,
		currentCost = null,
	) {
		if (validateField) {
			if (!validateFields()) {
				return false;
			}

			let exists_same_item = stock_receipt_details.find(
				(element) =>
					element?.item_id === itemId &&
					element?.location_id === location_id &&
					element?.cost === cost &&
					element?.quantity === quantity,
			);

			if (exists_same_item) {
				console.log("exists_same_item", exists_same_item);

				manageResponseErrors(
					"The item is already added",
					"Stock Receipt Items",
					"updating",
				);

				return false;
			}
		}
	}

	const createNewStockReceipt = async (
		dataId,
		dataName,
		stockReceiptId,
		errorCreating = false,
		currentCost = null,
		validateField = true,
	) => {
		let newStockReceiptItem = addNewStockReceiptDetail(
			dataId,
			dataName,
			validateField,
			currentCost,
		);
	}

	const handleCloseAdd = async (currentSku, currentCost) => {

		if (skuNumber || currentSku) {
			// setIsLoading(true);
			const { data } = await getSkuItemInformation(
				skuNumber ? skuNumber : currentSku,
			);

			if (!(Object.keys(data).length === 0)) {
				await createNewStockReceipt(
					data.id,
					data.name,
					receipt_stock_number,
					false,
					currentCost,
					false, //validate fields
				);
				setSincronizedItemsList(false);
				parts_setShowCustomFormModal(false);

			}
			stock_setIsLoading(false);
		}
	};

	const getElementWhenAddNewItem = () => {
		stock_setIsLoading(true);
		setSincronizedItems(false);
	};

	const [showPanelCustomer, setShowPanelCustomer] = useState(false);
  const toggleShowPanelCustomer = () => setShowPanelCustomer((state) => !state);
	const handleResponseCreateCustomer = (customer) => {
		onChangeSearchInputCustomer(customer);
		toggleShowPanelCustomer();
	}
	const handlerSiblings = (prop = {}) => {
		console.log("TCL: handleSiblings -> prop", prop)
		switch (prop.panel) {
			case PanelNames.PANEL_CUSTOMER:
				toggleShowPanelCustomer();
				break;
			case PanelNames.PANEL_PRODUCT:
				parts_setShowCustomFormModal(true);
				break;
			case PanelNames.PANEL_DEVICE:
				setShow(true);
				break;
			case PanelNames.PANEL_LABOR:
				labor_setShowCustomFormModal(true);
				break;
			case PanelNames.PANEL_TYPE_DEVICE:
				handleCreateTypeDevice();
				break;
			default:
				break;
		}
	}
	
	return (
		<>
			<Panel open={showPanelCustomer} anchor="right" togglePanel={toggleShowPanelCustomer} headerTitle="New Client" pSize="lg">
        <CostumerForm handleResponse={handleResponseCreateCustomer} />
      </Panel>

			<CustomModal
				show={showCustomFormModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowCustomFormModal(false)}
				onHandleSuccess={() => {}}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
			>
				<CustomForm
					onHandleFormClose={() => setShowCustomFormModal(false)}
					location_list={[]}
					onHandleSubmit={onHandleCreateTypeDevice}
					typesAndValuesFields={typesAndValuesTypeDevice}
					initData={initDataTypeDevice}
					formTittle={""}
					id={null}
					getUrlPath={`api/type-devices/`}
					successStyleButtonText={"Save"}
					typeButton={"success"}
					
				/>
			</CustomModal>

			{/* create labor modal */}
			<CustomSideModal
				size="lg"
				show={labor_showCustomFormModal}
				customTittleText={"Create New Labor"}
				customMessageModal={customMessageModal}
				onHandleClose={() => labor_setShowCustomFormModal(false)}
				onHandleSuccess={() => {}}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
			>
				<CustomForm
					onHandleFormClose={() => labor_setShowCustomFormModal(false)}
					location_list={[]}
					onHandleSubmit={onCreateLabor}
					typesAndValuesFields={typesAndValuesNewLaborFields_copy}
					initData={initDataNewLaborFields_copy}
					formTittle={""}
					id={null}
					getUrlPath={`api/Labors/`}
					successStyleButtonText={"Save"}
					typeButton={"success"}
					inputConf={{
						stringCol:"col-12 col-sm-6 col-md-4"
					}}
					onChangeFields={
						onChangeFieldsInitDataNewLaborFields
					}
					
				/>
			</CustomSideModal>
			{/* create parts modal */}
			<ModalAddItem
				handleCloseAdd={() => parts_setShowCustomFormModal(false)}
				getElementWhenAddNewItem={getElementWhenAddNewItem}
				modal_conf={modal_add_conf}
				onHandleClose={handleCloseAdd}
				show={parts_showCustomFormModal}
				skuNumber={skuNumber}
			></ModalAddItem>
			<div
				className="contenedor container-fluid"
				style={{ position: "relative" }}
			>
				<div className="container-title mb-2">
					<h1 className="container__title mb-0">
						{props.actionTittle + " " + props.model}
					</h1>
					<div className="action-buttons-container">
						<div className="save-buttton">
							<div className="save-buttton">
								<CustomButton
									style={{ paddingInline: '1rem', fontSize: '13px' }}
									buttonName={"Save"}
									buttonOnClick={() =>
										buttonClick("Save", "save")
									}
									type={"success"}
									icon={""}
									disabled={false}
									margins={"mt-0 mb-0"}
								/>
							</div>
						</div>
						<div className="cancel-button">
							<CustomButton
								style={{ paddingInline: '1rem', fontSize: '13px' }}
								buttonName={"Cancel"}
								buttonOnClick={cancelInvoice}
								type={"secondary"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
							/>
						</div>
					</div>
				</div>

				<div className="row mt-1 mb-2">
					{typesAndValues.length > 0 &&
						Object.keys(initData).length > 0 && (
							<>
								<div className="col-12">
									<CustomForm
										onHandleFormClose={() => {}}
										onHandleSubmit={onHandleSubmit}
										typesAndValuesFields={typesAndValues}
										initData={initData}
										formTittle={""}
										dontShowCancelButton={true}
										id={null}
										getUrlPath={""}
										inputConf={{
											marginTop: "1",
											marginBottom: "0",
											stringCol:"col-6 col-md-4 col-lg-2"
										}}
										buttonClick={buttonClick}
										onChangeFields={onChangeFieldsInitData}
										initOnChangeFields={onChangeFieldsInitData}
										dontShowSubmitButton={true}
										childFunc={childFunc}
										dontShowErrosinOnChange={true}
										getObjectInfoOnChange={onChangeSearchInputCustomer}
										notifyChange={() => setChangeInForm(true)}
										handlerSiblings={handlerSiblings}
									/>
								</div>
							</>
						)}
				</div>
				<TabsComponent
					renderTabOne={tabOneContent}
					renderTabTwo={tabTwoContent}
					onChangeTab={(value) => setCurrentTabValue(value)}
				/>
				{typesAndValuesMessageOnInvoice.length > 0 &&
					Object.keys(initDataMessageOnInvoice).length > 0 && (
						<div className="message_box_and_subtotal-section_container">
							<div className="message_invoice_attach_document_container">
								<CustomForm
									onHandleFormClose={() => {}}
									onHandleSubmit={onHandleSubmit}
									typesAndValuesFields={typesAndValuesMessageOnInvoice}
									initData={initDataMessageOnInvoice}
									formTittle={""}
									dontShowCancelButton={true}
									id={null}
									getUrlPath={""}
									inputConf={{ marginTop: "1", marginBottom: "0", stringCol: "col-12 col-sm-11 col-md-12" }}
									buttonClick={buttonClick}
									onChangeFields={onChangeFieldsInitDataMessageOnInvoice}
									initOnChangeFields={onChangeFieldsInitDataMessageOnInvoice}
									dontShowSubmitButton={true}
									childFunc={childFunc2}
									dontShowErrosinOnChange={true}
									notifyChange={() => setChangeInForm(true)}
								/>
							</div>
							<div>
								<div className="amount-table">
									<div className="letter amount-table-subtotal">
										Subtotal
									</div>
									<div className="letter amount-table-item amount-table-subtotal-value">
										$ 0.00
									</div>
								</div>
							</div>
						</div>
					)}
				<div style={{height:"75px"}}></div>
				<CustomModal
					show={showModal}
					customTittleText={customTittleText}
					customMessageModal={customMessageModal}
					onHandleClose={onHandleResponseModal}
					onHandleSuccess={onHandleDelete}
					isLoading={isLoading}
					showSuccessButton={showSuccessButton}
					actionsFooterStyle={true}
					cancelText={cancelButtonText}
					successText={sucessButtonText}
				/>

				<CustomSideModal
					show={show}
					customTittleText={"New device"}
					customMessageModal={""}
					onHandleClose={() => setShow(false)}
					onHandleSuccess={() => {}}
					isLoading={false}
					showSuccessButton={true}
					size={"lg"}
				>
					<CustomForm
						onHandleFormClose={() => setShow(false)}
						location_list={[]}
						onHandleSubmit={onHandleSaveDevice}
						typesAndValuesFields={typesAndValuesSaveDevice}
						initData={initDataSaveDevice}
						formTittle={""}
						actionsFooterStyle={true}
						cancelStyleButtonText={"Cancel"}
						successStyleButtonText={"Save"}
						actionButonsContainerWidth={"100%"}
						buttonClick={handleCreateTypeDevice}
						inputConf={{stringCol:"col-12 col-md-6"}}
						getObjectInfoOnChange={onChangeSearchInputCustomer}
						handlerSiblings={handlerSiblings}
					/>
				</CustomSideModal>
			</div>
		</>
	);
}

export { WorkOrderForm };
