// import { blockInvalidChar } from "../../context/constants";

export const PanelNames = {
	PANEL_BRAND: 'PANEL_BRAND',
	PANEL_CATEGORY: 'PANEL_CATEGORY',
	PANEL_UOM: 'PANEL_UOM',
	PANEL_COLOR: 'PANEL_COLOR',
	PANEL_VENDOR: 'vendor',
}

export const typesAndValues = [
	{
		field_name: "name",
		type_field: "text_input",
		text_label: "Name",
		disabled: false,
		regexPattern: /^[a-zA-Z0-9 /\,@#_-]+$/,
		maxLength: 100,
		// minLength: 3,
		col: "col-12",
		required: true,
	},
	{
		field_name: "brand_id",
		type_field: "select_input",
		text_label: "Brand",
		placeHolder: "select a brand",
		dontDefaultOption: false,
		regexPattern: /^[a-z0-9]+$/i,
		values: [],
		selectCol: "col-12",
		selectWidth: "100%",
		// minLength: 1,
		col: "col-4",
		required: true,
		nextSiblingConfig: {
			iconStart: 'fas fa-plus',
			data: {
				panel: PanelNames.PANEL_BRAND
			}
		},
	},
	{
		field_name: "part_code",
		type_field: "text_input",
		text_label: "Style",
		disabled: false,
		regexPattern: /^[a-zA-Z0-9 /@#_-]+$/,
		maxLength: 100,
		// minLength: 3,
		col: "col-12",
		required: true,
	},
	{
		field_name: "category_id",
		type_field: "select_input",
		text_label: "Category",
		placeHolder: "select a category",
		dontDefaultOption: false,
		values: [],
		selectCol: "col-12",
		selectWidth: "100%",
		// minLength: 1,
		col: "col-4",
		// required: true,
		nextSiblingConfig: {
			iconStart: 'fas fa-plus',
			data: {
				panel: PanelNames.PANEL_CATEGORY
			}
		},
	},
	// {
	// 	field_name: "uom_id",
	// 	type_field: "select_input",
	// 	text_label: "Uom",
	// 	placeHolder: "select and uom",
	// 	dontDefaultOption: false,
	// 	regexPattern: /^[a-z0-9]+$/i,
	// 	values: [],
	// 	selectCol: "col-12",
	// 	selectWidth: "100%",
	// 	col: "col-4",
	// 	required: true,
	// 	nextSiblingConfig: {
	// 		iconStart: 'fas fa-plus',
	// 		data: {
	// 			panel: PanelNames.PANEL_UOM
	// 		}
	// 	},
	// },
	// {
	// 	field_name: "cost",
	// 	type_field: "text_input",
	// 	text_label: "Cost",
	// 	disabled: false,
	// 	regexPattern: /^[0-9]*\.?[0-9]*$/,
	// 	maxLength: 10,
	// 	// minLength: 0,
	// 	col: "col-4",
	// 	// required: true,
	// 	type: "number",
	// 	onKeyDown: blockInvalidChar,
	// },
	// {
	// 	field_name: "price",
	// 	type_field: "text_input",
	// 	text_label: "Price",
	// 	disabled: false,
	// 	regexPattern: /^[0-9]*\.?[0-9]*$/,
	// 	maxLength: 10,
	// 	minLength: 1,
	// 	col: "col-4",
	// 	required: true,
	// 	type: "number",
	// 	onKeyDown: blockInvalidChar,
	// },
	{
		field_name: "active",
		type_field: "select_input",
		text_label: "Status",
		placeHolder: "select a status",
		dontDefaultOption: false,
		regexPattern: /^[a-z0-9]+$/i,
		values: [
			{ id: true, name: "Active" },
			{ id: false, name: "Inactive" },
		],
		selectCol: "col-12",
		selectWidth: "100%",
		// minLength: 1,
		col: "col-4",
		required: true,
	},
	{
		field_name: "vendor_id",
		type_field: "select_input",
		text_label: "Vendor",
		placeHolder: "Select",
		dontDefaultOption: false,
		values: [],
		selectCol: "col-12",
		selectWidth: "100%",
		// minLength: 1,
		col: "col-4",
		// required: true,
		nextSiblingConfig: {
			iconStart: 'fas fa-plus',
			data: {
				panel: PanelNames.PANEL_VENDOR
			}
		},
	},
	// {
	// 	field_name: "sku",
	// 	type_field: "text_input",
	// 	text_label: "SKU",
	// 	disabled: false,
	// 	regexPattern: /^([a-zA-Z0-9 _-]+)$/,
	// 	maxLength: 25,
	// 	col: "col-6",
	// 	required: true,
	// },
	// {
	// 	field_name: "upc",
	// 	type_field: "text_input",
	// 	text_label: "UPC",
	// 	disabled: false,
	// 	regexPattern: /^([a-zA-Z0-9 _-]+)$/,
	// 	maxLength: 25,
	// 	col: "col-6",
	// 	required: true,
	// },
];

export const initData = {
	name: "",
	brand_id: "",
	category_id: "",
	part_code: "",
	cost: "0",
	price: "",
	active: true,
};
