import React, { useContext } from 'react'
import clsx from 'clsx';
import { HiShoppingCart } from "react-icons/hi";

import { PosDesktopContext } from "../../context/PosDesktopContext";

import NoImage from '../../assets/img/no-image.jpg';

const PosDesktopCardProduct = (product) => {
  const { productsId, addToCart } = useContext(PosDesktopContext);
  const { id, name, sku, images, price, brand_id, category_id } = product;
  return (
    <>
      <div onClick={() => addToCart(product)} className={clsx('card__pos__product', productsId.has(id) && 'active')} role="button">
        <figure className="card__pos__product__img">
          <img src={ images.length ? images[0]['path'] : NoImage } alt={name} onError={(ev) => ev.target.src = NoImage} />
        </figure>
        <div className="card__pos__product__information">
          <div className="card__pos__product__description">
            <div className="card__pos__product__name">
              {String(name).toLowerCase()}
            </div>
            <div style={{ lineHeight: '1.1', marginBlockStart: '.25rem' }}>
              <div className="card__pos__product__code">Sku: {sku}</div>
              {!!brand_id?.name && (
                <div className="card__pos__product__brand">
                  <span className="card__pos__product__property">
                    Brand: {String(brand_id.name).toUpperCase()}
                  </span>
                </div>
              )}
              {!!category_id?.name && (
                <div className="card__pos__product__brand">
                  <span className="card__pos__product__property">
                    Category: {String(category_id.name).toUpperCase()}
                  </span>
                </div>
              )}
            </div>
            <div className="card__pos__product__price">${Number(price).toFixed(2)}</div>
          </div>
        </div>
        <div className="card__pos__product__icon">
          <HiShoppingCart />
        </div>
      </div>
    </>
  )
}

export default PosDesktopCardProduct