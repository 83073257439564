import React, { useContext, useEffect, useState } from 'react'
import { Box, Card, CardContent, Divider, Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import debounce from 'lodash/debounce';
import { HiShoppingBag } from "react-icons/hi";
import AppsIcon from '@material-ui/icons/Apps';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import Swal from "sweetalert2";

import { AppContext } from '../../context/AppContext';
import { PosDesktopContext, MIN_PER_PAGE } from '../../context/PosDesktopContext';
import { CashCounterContext } from '../../context/CashCounterContext';
import SwalAlerts from '../../utils/alerts';

import PosDesktopListProducts from './PosDesktopListProducts';
import PosDesktopCartDetail from './PosDesktopCartDetail';
import MaterialUI from '../../components/MaterialUI';
import Dialog from '../../components/Dialog';
import CashCounterForm from '../CashCounter/CashCounterForm';

import './PostDesktop.style.css';

const useStyles = makeStyles(() => ({
  card: {
    minBlockSize: 'calc(100vh - 16vh)',
    position: 'relative',
  },
  cardContent: {
    display: 'flex',
    blockSize: '100%',
    flexDirection: 'column',
  },
  blocked: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 90%)',
    top: 0,
    left: 0,
    display: 'grid',
    placeContent: 'center',
    inlineSize: '100%',
    blockSize: '100%',
    zIndex: 1000,
  }
}));

const searchTypes = [
  { label: 'SKU (Direct to Cart)', value: 'SKU' },
  { label: 'Name', value: 'NAME' }
]

const Lock = ({ blocked }) => {
  const classes = useStyles();
  if(!blocked) return null;
  return (
    <div className={classes.blocked}>
      <div className="text-muted text-center">
        <i className="fas fa-lock fa-6x mb-2"></i>
        <p className="mb-0 fw-bold">Locked Screen</p>
        <p className="fs-small">You need to open a cash counter</p>
      </div>
    </div>
  )
}

const PosDesktop = () => {
  const classes = useStyles();
  const history = useHistory();
  const {changeNavigationVisible} = useContext(AppContext);
  const {setSearch, setSearchType, searchType, _search, _setSearch, perPage, togglePerPage, fetchTypesPayment, fetchHoldReceipts} = useContext(PosDesktopContext);
  const {cashCounterLastOpen, counterIsOpen, cashCounter, restoreStateCashCounter, isLoadingCashCounter} = useContext(CashCounterContext);
  
  useEffect(() => {
    setTimeout(() => {
      changeNavigationVisible(true);
    }, 250);
  }, [])
  
  useEffect(() => {
    const debouncedSave = debounce(() => setSearch(_search), 250);
    debouncedSave();
    return () => { debouncedSave.cancel() }
  }, [_search, searchType, setSearch]);

  const [showModals, setShowModals] = useState({ cashCounter: false });
  const handleToggleModalClick = (currentModal) => () => {
    if(isLoadingCashCounter) return;
		setShowModals((prev) => ({
			...prev,
			[currentModal]: !prev[currentModal],
		}));
	}

  useEffect(() => {
    cashCounterLastOpen()
    fetchTypesPayment();
    fetchHoldReceipts();
  }, []);
  
  const handlerOnSuccess = (onClose) => () => {
    cashCounterLastOpen();
    restoreStateCashCounter();
    onClose();
    Swal.fire(SwalAlerts.success);
  }
  
  const FormCashCounter = (onClose) => {
    return <CashCounterForm onClose={onClose} onSuccess={handlerOnSuccess(onClose)}/>;
  }
  
  return (
    <div style={{ '--bg-body': '#fff' }}>
      <Grid className='template-dashboard post__container' container spacing={2}>
        <Grid item xs={12}>
          <div className="d-flex gap-2 align-items-center">
            <div className='d-flex gap-2 align-items-center'>
              <HiShoppingBag fontSize={28} className='color__orange'/>
              <h5 className='mb-0'>POS</h5>
            </div>
            {!counterIsOpen ? (
              <MaterialUI.Button.Primary disabled={isLoadingCashCounter} className="fs-small" onClick={handleToggleModalClick('cashCounter')}>
                Open Cash Counter
              </MaterialUI.Button.Primary>
            ) : (
              <MaterialUI.Button.Dark tooltip="See cash counter details" disabled={isLoadingCashCounter} className="fs-small" onClick={() => history.push('/cash-counter')}>
                Cash open {cashCounter.date_open}
              </MaterialUI.Button.Dark>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card className={classes.card}>
            <Lock blocked={!counterIsOpen} />
            <CardContent className={classes.cardContent}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <MaterialUI.Select 
                    options={searchTypes}
                    label="Type Search"
                    state={searchType}
                    setState={setSearchType}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MaterialUI.Input 
                    label={searchType !== 'SKU' ? 'Search Product' : 'Enter SKU'}
                    state={_search}
                    InputLabelProps={{ shrink: true }}
                    setState={_setSearch}
                  />
                </Grid>
                <Grid item xs={12} md={3} className='text-end'>
                  <MaterialUI.Button.Warning tooltip={'Close Cash Counter'} type={'button'} className="mw-auto me-1" style={{ blockSize: '35.9722px' }} onClick={handleToggleModalClick('cashCounter')}>
                    <i className="fas fa-cash-register px-1" style={{ fontSize: '1.1rem' }}></i>
                  </MaterialUI.Button.Warning>
                  <MaterialUI.Button.Dark tooltip={Number(perPage) === MIN_PER_PAGE ? 'Change 4 columns' : 'Change 3 columns'} type={'button'} className="mw-auto" onClick={togglePerPage}>
                    {Number(perPage) === MIN_PER_PAGE ? <AppsIcon /> : <ViewComfyIcon />}
                  </MaterialUI.Button.Dark>
                </Grid>
                <Grid item xs={12} className='pt-2 pb-3'>
                  <Divider />
                </Grid>
              </Grid>
              <Box style={{ blockSize: '100%', position: 'relative' }}>
                <PosDesktopListProducts />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <PosDesktopCartDetail className={classes.card}>
            <Lock blocked={!counterIsOpen} />
          </PosDesktopCartDetail>
        </Grid>
      </Grid>
      {showModals.cashCounter && (
        <Dialog
          open={showModals.cashCounter}
          title={`${counterIsOpen ? 'Close' : 'Open'} Cash Counter`}
          onClose={handleToggleModalClick('cashCounter')}
          fullWidth={true}
          maxWidth="xs"
          children={FormCashCounter}
          disableEscapeKeyDown={true}
        />
      )}
    </div>
  )
}

export default PosDesktop