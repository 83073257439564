import { blockInvalidChar } from "../../context/constants";

export const typesAndValues = [
	{
		field_name: "name",
		type_field: "text_input",
		text_label: "Company Name",
		disabled: false,
		regexPattern: /^[A-Za-z0-9."', ]*$/,
		maxLength: 50,
		minLength: 1,
		col: "col-12 col-md-4",
		autoFocus: true,
		required: true,
	},
	{
		field_name: "contact_email",
		type_field: "text_input",
		text_label: "Company Email",
		disabled: false,
		regexPattern: /^$|[a-z0-9]+@[a-z0-9]+\.[a-z]{2,3}/,
		maxLength: 128,
		col: "col-12 col-md-4",
	},
	{
		field_name: "contact_phone",
		type_field: "text_input",
		text_label: "Company Phone",
		disabled: false,
		regexPattern:
			/^$|^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
		maxLength: 15,
		col: "col-12 col-md-4",
	},
	{
		field_name: "address",
		type_field: "text_input",
		text_label: "Address",
		disabled: false,
		regexPattern: /^$|^[#.0-9a-zA-Z\s,-,',",@,;]+$/,
		maxLength: 100,
		// minLength: 3,
		col: "col-12 col-md-6",
		required: false,
	},
	{
		field_name: "currency_id",
		type_field: "select_input",
		text_label: "Default Currency",
		placeHolder: "select a default currency",
		dontDefaultOption: false,
		regexPattern: /^$|^[a-z0-9]+$/i,
		values: [
			{ id: "1", name: "$ (USD)" },
			{ id: "2", name: "£ (GBP)" },
			{ id: "3", name: "€ (EUR)" },
			{ id: "4", name: "₹ (INR)" },
		],
		selectCol: "col-12",
		selectWidth: "100%",
		// minLength: 1,
		col: "col-12 col-md-3",
		required: false,
	},
	{
		field_name: "timezone",
		type_field: "select_input",
		text_label: "Default Time Zone",
		placeHolder: "select a default time zone",
		dontDefaultOption: true,
		regexPattern: /^$|^[a-zA-Z0-9\/_]+$/,
		values: [],
		selectCol: "col-12",
		selectWidth: "100%",
		// minLength: 1,
		col: "col-12 col-md-3",
		required: false,
	},
	{
		field_name: "status",
		type_field: "select_input",
		text_label: "Status",
		placeHolder: "select a status",
		dontDefaultOption: true,
		regexPattern: /^[a-zA-Z0-9_ ]+$/i,
		values: [
			{ id: "active", name: "Active" },
			{ id: "inactive", name: "Inactive" },
			{ id: "licence_expired", name: "Licence Expired" },
		],
		selectCol: "col-12",
		selectWidth: "100%",
		// minLength: 1,
		col: "col-12 col-md-4",
		required: true,
	},
	{
		field_name: "locale",
		type_field: "select_input",
		text_label: "Change Language",
		placeHolder: "select a language",
		dontDefaultOption: true,
		regexPattern: /^$|^[a-z0-9]+$/i,
		values: [
			{ id: "English", name: "English" },
			{ id: "Spanish", name: "Spanish" },
		],
		selectCol: "col-12",
		selectWidth: "100%",
		// minLength: 1,
		col: "col-12 col-md-4",
		required: false,
	},
];

export const initData = {
	name: "",
	contact_email: "",
	contact_phone: "",
	status: "active",
	address: "",
	currency_id: "",
	timezone: "",
	locale: "English",
};

export const typesAndValuesRowTwo = [
	{
		field_name: "city",
		type_field: "text_input",
		text_label: "City",
		disabled: false,
		regexPattern: /^$|^[a-zA-Z\s]*$/,
		col: "col-12 col-md-4",
		required: false,
	},
	{
		field_name: "state",
		type_field: "text_input",
		text_label: "State",
		disabled: false,
		regexPattern: /^$|^[a-zA-Z\s]*$/,
		col: "col-12 col-md-4",
		required: false,
	},
	{
		field_name: "zipcode",
		type_field: "text_input",
		text_label: "Zip Code",
		disabled: false,
		regexPattern: /^$|^[0-9]*$/,
		maxLength: 5,
		col: "col-12 col-md-4",
		required: false,
	},
	{
		field_name: "verified",
		dontSeeLabel: true,
		type_field: "checkbox_input",
		text_label: "",
		col: "col-12 col-md-12",
		checkBoxValues: {
			verified: true,
		},
		mapChechBoxNames: {
			verified: "Verified",
		},
	},
	{
		field_name: "admin_email",
		type_field: "text_input",
		text_label: "Admin Email",
		disabled: false,
		regexPattern: /^$|[a-z0-9]+@[a-z0-9]+\.[a-z]{2,3}/,
		maxLength: 128,
		col: "col-12 col-md-6",
	},
	{
		field_name: "password",
		type_field: "text_input",
		text_label: "Password",
		disabled: false,
		regexPattern:
			/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
		maxLength: 250,
		col: "col-12 col-md-6",
		validationMessage:
			"Password must be 8-20 characters, includes at least three of the four types: upper/lower letters, numbers or symbols.",
	},
];

export const initDataRowTwo = {
	city: "",
	state: "",
	zipcode: "",
	verified: false,
	admin_email: "",
	password: "",
};

export const typesAndValuesMessagePackage = [];

export const initDataMessagePackage = {};
