import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

// components
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import MenuItem from "@material-ui/core/MenuItem";
import no_image from "../../assets/img/no_image.png";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import "./InvoiceForm.css";
// 👉 Modal Estimate
import Dialog from '../../components/Dialog';
import { InvoiceEstimate } from '../Invoice/InvoiceEstimate';
import Http from '../../lib/http';

// custom Hooks
// import { useApiRequest } from '../../../customHooks/useApiRequest'
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";
import { useMenuActionHook } from "../../customHooks/useMenuActionHook";
// form configuration
import {
	typesAndValues,
	typesAndValuesUpdateDetailInvoice,
	typesAndValuesMessageOnInvoice,
	initData as initDataValues,
	initDataMessageOnInvoice as initDataMessageOnInvoiceValues,
	initDataBillingAddress as initDataBillingAddressValues,
	typesAndValuesPartNumber as typesAndValuesPartNumberAux,
	initDataPartNumber as initDataPartNumberValues,
	PanelNames,
} from "./initForm";


import {
	styles,
	tableHeadersInvoiceDetail as tableHeaders,
	dataFieldsInvoiceDetail as dataFields,
	formatDate,
	blockInvalidChar,
	INVOICE_ENTRY_STATE,
	INVOICE_PAID_STATE,
	INVOICE_COMPLETE_STATE,
	INVOICE_PENDING_STATE,
	addDaysToCurrentDay,
} from "../../context/constants";
import PDFModal from "../../components/ModalPdf";
import { ModalAddItem } from "../../components/modal_item_add";
import { useApirequestStockReceipt } from "../../customHooks/useApirequestStockReceipt";
import { useApiRequestItems } from "../../customHooks/useApiRequestItems";
import { useFileManager } from "../../customHooks/useFileManager";
import CustomImageViewer from "../../components/CustomImageViewer/CustomImageViewer";
import { Box } from "@material-ui/core";
import Panel from "../../components/Panel";
import { CostumerForm } from "../costumer/CostumerForm";
import MaterialUI from "../../components/MaterialUI";
import { AppContext } from "../../context/AppContext";

var _ = require("lodash");

let typesAndValuesPartNumberValues = _.cloneDeep(typesAndValuesPartNumberAux);

const convertValue = (str) => {
	return str.replace(",", "");
};

const calculate = (str) => {
	return parseFloat(convertValue(str)).toFixed(2);
};

const imageFormats = ["image/png", "image/jpg", "image/jpeg"];

function InvoiceFormUpdate(props) {
	// data the user

	const userData = JSON.parse(localStorage.getItem("user_data"));
	const location = useLocation();
	const history = useHistory();

	const [goToMainPage, setGoToMainPage] = React.useState(false);
	const [typeDiscount, setTypeDiscount] = React.useState("percentage");
	const [saleTax, setSaleTax] = React.useState("1");
  const { changeNavigationVisible } = React.useContext(AppContext);
	// 👉 Estimate
	const [showEstimate, setShowEstimate] = React.useState(false);
	const [loadingEstimate, setLoadingEstimate] = React.useState(false);
	const [currentEstimate, setCurrentEstimate] = React.useState({});
	const fetchEstimate = async (id) => {
		try {
			if(loadingEstimate) return;
			setShowEstimate(true);
			setLoadingEstimate(true);
			const { data } = await Http.get(`/api/estimates/${id}`);
			if(data.status) {
				setCurrentEstimate(data.data);
			}
		} catch (error) {}
		finally {
			setLoadingEstimate(false);
		}
	}
	// 👉 Others
	React.useEffect(() => {
    setTimeout(() => {
      changeNavigationVisible(true);
    }, 250);
  }, []);
	
	const SaleTaxOnChange = (ev) => {
		setSaleTax(ev.target.value);
		const _formAmountValues = { ...formAmountValues }
		setFormAmountValues(_formAmountValues);
		const { deposit, discount_total, total, tax_por, total_payments } = _formAmountValues;
		let newData = {
			..._formAmountValues,
		};
		// no sale tax
		// tax port to 0 and balance changes
		if (ev.target.value === "2") {
			// newData.tax_por = 0.00;
			newData.tax_amount = 0.0;
			let dist = parseFloat(discount_total) || 0.0;
			let newBalance = calculateBalance(
				total,
				dist,
				deposit,
				0.0,
				total_payments || 0.0,
			);
			newData.balance = newBalance;
		} else {
			let dist = parseFloat(discount_total) || 0.0;
			let newTotal = parseFloat(total) - parseFloat(dist);
			let tax_new = calculateTax(tax_por ?? userData.company.tax, newTotal);
			newData.tax_amount = parseFloat(tax_new);
			let newBalance = calculateBalance(
				total,
				dist,
				deposit,
				tax_new,
				total_payments || 0.0,
			);
			newData.balance = newBalance;
		}
		newData = {
			...newData,
		};

		setFormAmountValues({
			...newData,
		});
	};
	const TypeDiscounthandleChange = (ev) => {
		setTypeDiscount(ev.target.value);
		const {
			discount_percentage,
			deposit,
			discount_total,
			balance,
			total,
			tax,
			tax_por,
			total_payments,
		} = formAmountValues;

		let newData = {
			...formAmountValues,
		};

		if (ev.target.value === "monto") {
			// take discount percentage like monto
			let dist = parseFloat(discount_percentage) || 0.0;
			// calculate tax
			let newTotal = parseFloat(total) - parseFloat(dist);
			let tax_new = calculateTax(tax_por, newTotal);

			// calculate the balance
			let newBalance = calculateBalance(
				total,
				dist,
				deposit,
				tax_new,
				total_payments || 0.0,
			);
			// update the variable
			newData.tax_amount = parseFloat(
				saleTax === "2" ? 0.0 : tax_new,
			).toFixed(2);
			newData.discount_total = parseFloat(dist).toFixed(2);
			newData.balance = newBalance;
		} else {
			// percentage
			let percentage = parseFloat(discount_percentage) || 0.0;
			if (percentage === 0) {
				return;
			}
			let discTotal = (percentage * parseFloat(total)) / 100;
			let newTotal = parseFloat(total) - discTotal;
			let tax_new = calculateTax(tax_por, newTotal);
			newData.tax_amount = parseFloat(
				saleTax === "2" ? 0.0 : tax_new,
			).toFixed(2);
			newData.discount_total = parseFloat(discTotal).toFixed(2);
			let newBalance = calculateBalance(
				total,
				discTotal,
				deposit,
				tax_new,
				total_payments || 0.0,
			);

			newData.balance = newBalance;
		}
		newData = {
			...newData,
			balance: Number(newData.balance).toFixed(2),
		};

		setFormAmountValues({
			...newData,
			[ev.target.name]: ev.target.value,
		});
	};

	const { anchorEl, setAnchorEl, onCloseModal, currentId, setCurrentId } =
		useMenuActionHook();

	const {
		setCustomMessageModal,
		showSuccessButton,
		setShowSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		setCustomTittleText,
		customMessageModal,
		manageResponseErrors,
		customCurrentItem,
		setCustomCurrentItem,
		manageResponse,
		manageSuccessfullMesssage,
		manageDeleteMessage,
	} = useModalHook();

	const {
		setCustomMessageModal:files_setCustomMessageModal,
		showSuccessButton:files_showSuccessButton,
		setShowSuccessButton:files_setShowSuccessButton,
		showModal:files_showModal,
		setShowModal:files_setShowModal,
		customTittleText:files_customTittleText,
		setCustomTittleText:files_setCustomTittleText,
		customMessageModal:files_customMessageModal,
		manageResponseErrors:files_manageResponseErrors,
		customCurrentItem:files_customCurrentItem,
		setCustomCurrentItem:files_setCustomCurrentItem,
		manageResponse:files_manageResponse,
		manageSuccessfullMesssage:files_manageSuccessfullMesssage,
		manageDeleteMessage:files_manageDeleteMessage,
	} = useModalHook();

	const {
		getDocuments,
		deleteDocuments,
		addDocuments,
		isLoading:stock_isLoading,
		setIsLoading:stock_setIsLoading,
		addImages,
		stock_receipt_details,
		setStockReceiptDetails,
		getDataFromUser,
		setStockReceiptId,
		setSincronizedItems: setSincronizedItemsList,
		receipt_stock_number,
		setReceiptStockNumber,
		bill_number,
		setBillNumber,
		vendor_id,
		setVendorId,
		vendor_name,
		setVendorName,
		date,
		setDate,
		date_due,
		setDateDue,
		description,
		setDescription,
		payment,
		setPayment,
		quantity,
		setQuantity,
		cost,
		setCost,
		location_id,
		setLocationId,
		location_name,
		setLocationName,
		notes,
		setNotes,
		atached_document,
		setAtachedDocument,
		total_receipt,
		setTotalReceipt,
		tax_receipt,
		setTaxReceipt,
		vendors,
		setVendors,
		location_list,
		setLocationList,
		insertVendor,
		payment_number,
		setPaymentNumber,
		part_number_id,
		setPartNumberId,
		part_number_name,
		setPartNumberName,
		disabledActions,
		setDisabledActions,
		updateStockReceiptItemPostStatus,
		updateStockReceiptPostStatus,
		stockReceiptStatus,
		skuNumber,
		setSkuNumber,
		saveStockReceiptModel,
		saveStockReceiptItem,
		updateStockReceiptItem,
		currentStockReceiptCost,
	} = useApirequestStockReceipt(
		setCustomTittleText,
		setCustomMessageModal,
		showSuccessButton,
		setShowModal,
	);

	const {
		documentsImageArray,
		setDocumentsImageArray,
		imageIdsArray,
		setImageIdsArray,
		itemImageToAdd,
		setItemImageToAdd,
		setFileImage,
		deleteImage,
		openFileImage,
		onDeleteImage,
		isImage,
		setIsImage,
	} = useFileManager(
		files_setShowModal,
		files_customCurrentItem,
		files_setCustomCurrentItem,
		files_setCustomTittleText,
		files_setCustomMessageModal,
		files_setShowSuccessButton,
	);

	const {
		filteredData: item_list,
		setSincronizedItems,
		getItemList,
		itemsNotFound,
		getSkuItemInformation,
	} = useApiRequestItems(
		stock_setIsLoading,
		setPaymentNumber,
		setPartNumberId,
		setPartNumberName,
		setPartNumberId,
		setPartNumberName,
	);

	const [modal_add_conf] = React.useState({
		title: "New Item",
		message: "",
	});

	const {
		isLoading,
		setIsLoading,
		getListItem,
		getItem,
		updateItem,
		updateModel,
		deleteItemByUrl,
		getFile,
		createImageItem,
		deleteImageItem,
	} = useApiCustomRequest(
		manageResponseErrors,
		"invoices",
		"Invoice",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		setGoToMainPage,
	);

	const onHandleResponseModal = () => {
		quitNewStylesInModal();
		setShowModal(false);
		if (goToMainPage) {
		}
	};
	// Delete the items.
	const onHandleDelete = async () => {
		quitNewStylesInModal();
		setShowModal(false);
		await deleteItemByUrl(customCurrentItem, "invoice-details", true);
		setDoRequest(true);
		setCustomCurrentItem(false);
		setSincronizeData(false);
	};

	const files_onHandleResponseModal = () => {
		quitNewStylesInModal();
		files_setShowModal(false);
	};

	const onHandleDeleteFile = async () => {
		quitNewStylesInModal();
		files_setShowModal(false);
		const file_id = documentsImageArray.find(file => file.path === files_customCurrentItem).id;
		const res = await deleteItemByUrl(file_id, "documents", true);
		if (res) {
			setDocumentsImageArray(prevArray => {
			  return prevArray.filter(file => file.id !== file_id);
			});
		}
		setDoRequest(true);
		files_setCustomCurrentItem(false);
		setSincronizeData(false);
	};

	const [formAmountValues, setFormAmountValues] = React.useState({
		discount_percentage: 0.0,
		deposit: 0.0,
		discount_total: 0.0,
		balance: 0.0,
		total: 0.0,
		total_payments: 0.0,
		tax_por: userData.company.tax,
		tax_amount: 0.0,
	});
	const [typesAndValuesFields, setTypesAndValuesFields] = React.useState([]);
	const [typesAndValuesPartNumber, setTypesAndValuesPartNumber] = React.useState([]);
	const [initData, setInitData] = React.useState({});
	const [initDataMessageOnInvoice, setInitDataMessageOnInvoice] = React.useState({});
	const [initDataBillingAddress, setInitDataBillingAddress] = React.useState(
		{},
	);
	const [initDataPartNumber, setInitDataPartNumber] = React.useState({});
	const [invoiceItems, setInvoiceItems] = React.useState([]);
	const [sincronizeData, setSincronizeData] = React.useState(false);
	const [changeInForm, setChangeInForm] = React.useState(false);
	const [actionsFooterStyle, setActionsFooterStyle] = React.useState(false);
	const [cancelText, setCancelText] = React.useState(false);
	const [successText, setSuccessText] = React.useState(false);
	const [showPaymentModal, setShowPaymentModal] = React.useState(false);
	const [invoiceStatus, setInvoiceStatus] = React.useState("");
	const [paymentsArray, setPaymentsArray] = React.useState([]);
	const [customerInfo, setCustomerInfo] = React.useState({});
	const [payTerms, setPayTerms] = useState([]);
	const [currentSkuFromInput, setCurrentSkuFromInput] = useState("");
	const [estimateId, setEstimateId] = useState();

	// this is initial
	useEffect(async () => {
		let fieldsAndValuesCopy = [...typesAndValues];
		let initDataValuesCopy = { ...initDataValues };
		let initDataMessageOnInvoiceValuesCopy = {
			...initDataMessageOnInvoiceValues,
		};
		let typesAndValuesPartNumberValuesCopy = [
			...typesAndValuesPartNumberValues,
		];

		if (location?.search?.split("=")[1]) {
			let currentItem = location?.search?.split("=")[1];

			const infoItem = await getItem(
				location?.search?.split("=")[1],
				{},
				true,
				true,
			);

			if (infoItem.hasOwnProperty("error") && infoItem.error) {
				history.push("/404");
			}

			setSaleTax(infoItem.tax_exempt ? "2" : "1");
			let termValues = await getListItem("pay-terms", "Pay terms", {});
			setPayTerms(termValues);

			const {
				client_id,
				client_send_email,
				pay_term_id,
				date_receipt,
				date_due,
				message,
				discount_percentage,
				discount_total,
				deposit,
				Balance,
				total,
				type_discount,
				details,
				status,
				payments,
				tax,
				tax_por,
				tax_exempt,
				total_payments,
				tax_amount,
				documents,
				estimate_id,
				// images,
			} = infoItem;
			setEstimateId(estimate_id);
			const {
				name,
				city,
				state,
				postal_code,
				country,
				address_1,
				address_2,
			} = infoItem.client;

			let mapFiles = [];
			let mapImages = [];

			if (documents && documents.length > 0) {
				mapFiles = documents.map((item) => ({
					id: item.id,
					path: item.url,
					format: item.extension,
				}));
			}
			// if (images && images.length > 0) {
			// 	mapImages = images.map((item) => ({
			// 		id: item.id,
			// 		path: item.url,
			// 		format: item.extension,
			// 	}));
			// }

		let complexFiles = [...mapFiles, ...mapImages];
		setDocumentsImageArray(complexFiles);
			// part number
			const part_numberId = typesAndValuesPartNumberValues.findIndex(
				(item) => item.field_name === "item_id",
			);
			let part_number_ = typesAndValuesPartNumberValues[part_numberId];

			// button submit
			let btnsubmit =
				typesAndValuesPartNumberValues[
					typesAndValuesPartNumberValues.findIndex(
						(item) => item.field_name === "onSubmit",
					)
				];
			btnsubmit.disabled = status == INVOICE_PAID_STATE;

			setCustomerInfo(infoItem.client);
			setTypeDiscount(type_discount);
			setInvoiceStatus(status);
			setPaymentsArray(payments || []);

			setTimeout(() => {
				const initDataBillingAddressCopy = {
					...initDataBillingAddressValues,
				};
				const newMessage = `${name},${address_1},${address_2},${city} ${state} ${postal_code},${country}`;
				initDataBillingAddressCopy.comments = newMessage;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
			}, 0);

			const idFieldIndex = fieldsAndValuesCopy.findIndex(
				(item) => item.field_name === "client_id",
			);
			let fieldId = fieldsAndValuesCopy[idFieldIndex];
			fieldId.inputValue = `${name}`;
			fieldId.doRequest = false;
			fieldId.dontInitData = true;
			fieldId.disabled = status == INVOICE_PAID_STATE;

			const idFieldPayTermsIndex = fieldsAndValuesCopy.findIndex(
				(item) => item.field_name === "pay_term_id",
			);
			let pyayTerms = fieldsAndValuesCopy[idFieldPayTermsIndex];
			pyayTerms.values = termValues;
			pyayTerms.disabled = status == INVOICE_PAID_STATE;

			//

			initDataValuesCopy = {
				...initDataValuesCopy,
				client_id: client_id || "",
				client_send_email: client_send_email || "",
				pay_term_id: pay_term_id || "",
				date_receipt: formatDate(
					`${date_receipt.split("/")[2]}-${
						date_receipt.split("/")[1]
					}-${date_receipt.split("/")[0]}`,
				),
				due_date: formatDate(
					`${date_due.split("/")[2]}-${date_due.split("/")[1]}-${
						date_due.split("/")[0]
					}`,
				),
			};

			initDataMessageOnInvoiceValuesCopy = {
				...initDataMessageOnInvoiceValuesCopy,
				message: message || "",
			};
			let total_to_tax = parseFloat(calculate(total.split(" ")[1])) - parseFloat(discount_total);
			let caclTax = calculateTax(tax_por ?? userData.company.tax, total_to_tax);

			let caclBalance = calculateBalance(
				calculate(total.split(" ")[1]),
				parseFloat(discount_total),
				calculate(deposit.split(" ")[1]),
				caclTax,
				total_payments,
			);

			setFormAmountValues({
				discount_percentage: parseFloat(discount_percentage).toFixed(2),
				discount_total: parseFloat(discount_total).toFixed(2),
				deposit: calculate(deposit.split(" ")[1]),
				balance: caclBalance,
				total: calculate(total.split(" ")[1]),
				tax:
					tax == null
						? parseFloat(caclTax).toFixed(2)
						: calculate(tax.split(" ")[1]),
				tax_por: Number(tax_por) || Number(userData?.company?.tax),
				tax_amount: parseFloat(tax_amount || 0.0).toFixed(2),
				total_payments:
					typeof total_payments === "string" ||
					total_payments instanceof String
						? calculate(total_payments)
						: Number(total_payments),
			});

			const detailsMap = details.map((item) => ({
				id: item.id,
				part_number: item.item.sku,
				item_id: item.item.id,
				name: item.item.name,
				description: item.comment,
				quantity: item.quantity,
				rate: item.price,
				amount: item.quantity * item.price,
			}));

			setInvoiceItems([...detailsMap]);

			//get customer info from redirect
			if (
				location.hasOwnProperty("data") &&
				location?.data.hasOwnProperty("customerData") &&
				Object.keys(location.data.customerData).length > 0
			) {
				const {
					id,
					name,
					address_1,
					address_2,
					city,
					state,
					postal_code,
					country,
					email,
				} = location.data.customerData;

				initDataValuesCopy = {
					...initDataValuesCopy,
					client_id: id,
					client_send_email: email,
				};

				const idFieldIndex = fieldsAndValuesCopy.findIndex(
					(item) => item.field_name === "client_id",
				);
				let fieldId = fieldsAndValuesCopy[idFieldIndex];
				fieldId.inputValue = `${name}`;
				fieldId.doRequest = false;
				fieldId.dontInitData = true;
				fieldId.disabled = status != INVOICE_ENTRY_STATE;
				setTimeout(() => {
					const initDataBillingAddressCopy = {
						...initDataBillingAddressValues,
					};
					const newMessage = `${name},${address_1},${address_2},${city} ${state} ${postal_code},${country}`;
					initDataBillingAddressCopy.comments = newMessage;
					setInitDataBillingAddress({
						...initDataBillingAddressCopy,
					});
				}, 0);
			}

			typesAndValuesPartNumberValuesCopy[
				typesAndValuesPartNumberValuesCopy.findIndex(
					(item) => item.field_name === "item_id",
				)
			].regexPattern = /^$|^\d+$/;

			// create Customer
			const idFieldIndexD = fieldsAndValuesCopy.findIndex(
				(item) => item.field_name === "createCustomer",
			);
			// let btnCreateCustomerId = fieldsAndValuesCopy[idFieldIndexD];
			// btnCreateCustomerId?.disabled = status == INVOICE_PAID_STATE;

			// client email
			const idCustomerEmailId = fieldsAndValuesCopy.findIndex(
				(item) => item.field_name === "client_send_email",
			);
			let customerEmail = fieldsAndValuesCopy[idCustomerEmailId];
			customerEmail.disabled = status == INVOICE_PAID_STATE;

			// invoice date
			const invoiceDateId = fieldsAndValuesCopy.findIndex(
				(item) => item.field_name === "date_receipt",
			);
			let invoiceDate = fieldsAndValuesCopy[invoiceDateId];
			invoiceDate.disabled = status == INVOICE_PAID_STATE;

			// invoice due
			const invoicedueId = fieldsAndValuesCopy.findIndex(
				(item) => item.field_name === "due_date",
			);
			let invoiceDue = fieldsAndValuesCopy[invoicedueId];
			invoiceDue.disabled = status == INVOICE_PAID_STATE;
			part_number_.disabled = status == INVOICE_PAID_STATE;

			setInitData({ ...initDataValuesCopy });
			setTypesAndValuesFields(fieldsAndValuesCopy);
			setInitDataMessageOnInvoice({ ...initDataMessageOnInvoiceValuesCopy });
			setTypesAndValuesPartNumber([ ...typesAndValuesPartNumberValuesCopy ]);
			setSincronizeData(true);
		}
	}, [location?.search?.split("=")[1]]);

	const [doRequest, setDoRequest] = React.useState(false);
	useEffect(async () => {
		if (location?.search?.split("=")[1] && doRequest && !sincronizeData) {
			const infoItem = await getItem(
				location?.search?.split("=")[1],
				{},
				true,
			);

			const {
				details,
				discount_percentage,
				discount_total,
				deposit,
				tax_por,
				tax_amount,
				type_discount,
				tax_exempt,
				Balance,
				total,
			} = infoItem;

			let tax_exempt_ = tax_exempt || false;
			setSaleTax(tax_exempt_ == false ? "1" : "2");
			setTypeDiscount(type_discount);

			let recalculateFormValues = {
				...formAmountValues,
				discount_total: parseFloat(discount_total).toFixed(2),
				balance: calculate(Balance.split(" ")[1]),
				total: calculate(total.split(" ")[1]),
				tax_amount:
					(tax_amount || 0.0) > 0
						? parseFloat(tax_amount || 0.0).toFixed(2)
						: 0.0,
				tax_por: Number(tax_por) || Number(userData?.company?.tax),
				deposit: calculate(deposit.split(" ")[1]),
				discount_percentage: parseFloat(discount_percentage).toFixed(2),
			};

			recalculateAfterChange(recalculateFormValues);

			const detailsMap = details.map((item) => ({
				id: item.id,
				part_number: item.item.sku,
				item_id: item.item.id,
				name: item.item.name,
				description: item.comment,
				quantity: item.quantity,
				rate: item.price,
				amount: item.quantity * item.price,
			}));

			setInvoiceItems([...detailsMap]);
			setSincronizeData(true);
		}
	}, [sincronizeData]);

	const onChangeFormAmountValues = (ev) => {
		let regex = /^[0-9]*\.?[0-9]*$/;
		if (!regex.test(ev.target.value)) {
			return;
		}
		if (
			Number(ev.target.value) > 200 &&
			ev.target.name === "discount_percentage" &&
			typeDiscount === "percentage"
		) {
			return;
		}

		const {
			discount_percentage,
			deposit,
			discount_total,
			balance,
			total,
			tax,
			tax_por,
			total_payments,
		} = formAmountValues;

		let newData = {
			...formAmountValues,
		};

		let currentDiscountPercentage = discount_percentage || 0.0;
		let currentDeposit = deposit || 0.0;
		var totalDiscount_new = 0.0;
		if (ev.target.name === "discount_percentage") {
			currentDiscountPercentage = parseFloat(ev.target.value);
		}
		if (ev.target.name === "deposit") {
			currentDeposit = parseFloat(ev.target.value);
		}

		if (
			parseFloat(total) > 0 &&
			parseFloat(currentDiscountPercentage) > 0
		) {
			if (typeDiscount === "percentage") {
				totalDiscount_new =
					(parseFloat(total) *
						parseFloat(currentDiscountPercentage)) /
					100;
				newData.discount_total = totalDiscount_new.toFixed(2);
			} else {
				newData.discount_total = parseFloat(
					currentDiscountPercentage,
				).toFixed(2);
				totalDiscount_new = parseFloat(currentDiscountPercentage);
			}
		} else newData.discount_total = parseFloat("0.00").toFixed(2);

		if (parseFloat(total) > 0) {
			let result = calculateTax(
				tax_por,
				parseFloat(total) - totalDiscount_new,
			);
			newData.tax_amount = parseFloat(
				saleTax === "2" ? 0.0 : result,
			).toFixed(2);
			newData.balance = calculateBalance(
				total,
				totalDiscount_new,
				currentDeposit || 0.0,
				saleTax === "2" ? 0.0 : result,
				total_payments || 0.0,
			);
		} else {
			newData.balance = total - currentDeposit;
		}

		newData = {
			...newData,
			balance: Number(newData.balance).toFixed(2),
		};
		setFormAmountValues({
			...newData,
			[ev.target.name]: ev.target.value,
		});
	};

	// Calculate Tax
	const calculateTax = (tax, balance) => {
		var porcentaje = parseFloat(balance * tax);

		porcentaje = parseFloat(porcentaje / 100);

		return parseFloat(porcentaje).toFixed(2);
	};
	const calculateBalance = (
		subtotal,
		discount_total,
		deposit,
		tax_amount,
		total_paid,
	) => {
		let result =
			parseFloat(parseFloat(subtotal) + parseFloat(tax_amount)) -
			parseFloat(
				parseFloat(discount_total) +
					parseFloat(deposit === "" ? 0.0 : deposit) +
					parseFloat(total_paid),
			);

		return parseFloat(result).toFixed(2);
	};

	const recalculateAfterChange = (values) => {
		const {
			discount_percentage,
			discount_total,
			deposit,
			balance,
			total,
			tax_por,
			tax_amount,
			total_payments,
		} = values;
		let newData = {
			...values,
		};
		newData.tax_por = tax_por;
		newData.tax_amount = tax_amount;
		newData.balance = balance;
		newData.deposit = deposit;
		newData.total_payments = total_payments;
		newData.discount_total = discount_total;

		// if (parseFloat(total) > 0 && parseFloat(discount_percentage) > 0) {
		//   newData.discount_total = (
		//     parseFloat(total / 100) * parseFloat(discount_percentage)
		//   ).toFixed(2);
		// }

		// if (parseFloat(discount_percentage) && parseFloat(deposit)) {

		//   const calc1 = parseFloat(discount_percentage * total).toFixed(2);
		//   const calc2 = parseFloat(calc1 / 100).toFixed(2);
		//   const calc3 = parseFloat(calc2) + parseFloat(deposit);
		//   newData.balance = total - calc3;
		// }

		// if (parseFloat(discount_percentage) && !parseFloat(deposit)) {
		//   const calc1 = parseFloat(discount_percentage * total).toFixed(2);
		//   const calc2 = parseFloat(calc1 / 100).toFixed(2);
		//   const calc3 = parseFloat(calc2).toFixed(2);
		//   newData.balance = total - calc3;
		// }

		// if (!parseFloat(discount_percentage) && parseFloat(deposit)) {
		//   const calc3 = parseFloat(deposit).toFixed(2);
		//   newData.balance = total - calc3;
		// }

		// if (!parseFloat(discount_percentage) && !parseFloat(deposit)) {
		//   newData.balance = total;
		// }

		// newData = {
		//   ...newData,
		//   balance: Number(newData.balance).toFixed(2),
		//   total: Number(total).toFixed(2)
		// };
		setFormAmountValues(newData);
	};

	const onChangeFieldsInitDataMessageOnInvoice = async (event) => {
		setInitDataMessageOnInvoice({ ...event });
	};

	useEffect(() => {
		const initDataBillingAddressCopy = { ...initDataBillingAddressValues };
		setInitDataBillingAddress({ ...initDataBillingAddressCopy });
	}, []);

	const onChangeSearchInputCustomer = (ev) => {
		let initDataCopy = { ...initData };
		let fieldsAndValuesCopy = [...typesAndValuesFields];

		setInitDataBillingAddress({});
		const initDataBillingAddressCopy = { ...initDataBillingAddressValues };
		if (ev) {
			setTimeout(() => {
				const {
					name,
					address_1,
					address_2,
					city,
					state,
					postal_code,
					country,
					email,
					id,
				} = ev;

				initDataCopy = {
					...initDataCopy,
					initOptions: [ev],
					client_send_email: email,
					client_id: id,
					inputValue: ``,
					doRequest: false,
					dontInitData: true,
					inputValue: `${name}`,
				};

				const idFieldIndex = fieldsAndValuesCopy.findIndex(
					(item) => item.field_name === "client_id",
				);
				let fieldId = fieldsAndValuesCopy[idFieldIndex];
				fieldId.inputValue = ``;
				fieldId.doRequest = false;
				fieldId.dontInitData = true;
				fieldId.inputValue = `${name}`;
				fieldId.disabled = true;

				const newMessage = `${name},${address_1},${address_2},${city} ${state} ${postal_code},${country}`;
				initDataBillingAddressCopy.comments = newMessage;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
				setInitData({ ...initDataCopy });
				setTypesAndValuesFields([...fieldsAndValuesCopy]);
			}, 0);
		}

		if (!ev) {
			setTimeout(() => {
				initDataBillingAddressCopy.comments =
					initDataBillingAddressValues.comments;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
			}, 1);
		}
	};

	const onChangeFieldsInitDataPartNumber = async (event) => {
		if (
			event.item_id == null ||
			event.item_id.length === 0 ||
			event.item_id == undefined
		) {
			// setInitDataPartNumber({});
			return;
		}
		setInitDataPartNumber({ ...event });
	};

	useEffect(() => {
		onHandleSubmit("Add");
	}, [initDataPartNumber]);

	useEffect(() => {
		const initDataPartNumberValuesCopy = { ...initDataPartNumberValues };
		setInitDataPartNumber({ ...initDataPartNumberValuesCopy });
		// setTypesAndValuesPartNumber
	}, []);
	
	const [evaluateDates, setEvaluateDates] = useState(true);
	const onChangeFieldsInitData = async (event) => {
		setInitData({ ...event });
		setEvaluateDates(true);
	};

	const validateLength = (array) => {
		return array.length > 0;
	};

	const childFunc = useRef(null);
	const childFunc2 = useRef(null);
	const childFunc3 = useRef(null);
	const onHandleSubmit = async (event) => {
		if (isLoading) return;

		if (
			event === "Add" &&
			!initDataPartNumber.item_id &&
			!currentSkuFromInput
		) {
			return;
		}

		const initDataPartNumberCopy = { ...initDataPartNumber };
		const errorsOne = childFunc.current(initData);
		const errorsTwo = childFunc2.current(initDataMessageOnInvoice);
		const errorsThree = childFunc3.current(initDataPartNumber);

		if (
			!validateLength(errorsOne) &&
			!validateLength(errorsTwo) &&
			!validateLength(errorsThree)
		) {
			let formAmountValuesCopy = {
				discount_percentage: formAmountValues.discount_percentage || 0.0,
				deposit: formAmountValues.deposit || 0.0,
				// discount_total: formAmountValues.discount_total || 0.0,
				balance: formAmountValues.balance || 0.0,
				total: formAmountValues.total || 0.0,
				tax_amount: formAmountValues.tax_amount || 0.0,
				tax_por: formAmountValues.tax_por || 0.0,
				type_discount: typeDiscount || "percentage",
				tax_exempt: saleTax === "1" ? false : true,
			};

			// if(saleTax === "2") formAmountValuesCopy.tax_por = "0.0";

			let bodyRequest = {
				id: location?.search?.split("=")[1],
				...initData,
				...initDataMessageOnInvoice,
				...formAmountValuesCopy,
				type: "Invoice",
			};

			let finalRequest = {
				...bodyRequest,
			};

			if (initDataPartNumber.item_id) {
				setCurrentSkuFromInput("");
				finalRequest = {
					...finalRequest,
					...initDataPartNumber,
				};
			}

			if (!initDataPartNumber.item_id && currentSkuFromInput) {
				finalRequest = {
					...finalRequest,
					sku: currentSkuFromInput,
				};
			}

			if (event === "saveAndSend") {
				finalRequest = {
					...finalRequest,
					save_and_send: 1,
				};
			}
			let response = await updateItem(
				location?.search?.split("=")[1],
				finalRequest,
				"Invoice",
				{},
				true,
				true,
			);

			if (response && response.hasOwnProperty("id")) {
				setTimeout(() => {
					let typesAndValuesPartNumberValuesCopy = _.cloneDeep(
						typesAndValuesPartNumberValues,
					);
					let item =
						typesAndValuesPartNumberValuesCopy[
							typesAndValuesPartNumberValuesCopy.findIndex(
								(item) => item.field_name === "item_id",
							)
						];

					item.regexPattern = /^$|^\d+$/;
					item.inputValue = "";
					item.doRequest = false;
					item.dontInitData = true;
					typesAndValuesPartNumberValuesCopy[
						typesAndValuesPartNumberValuesCopy.findIndex(
							(item) => item.field_name === "item_id",
						)
					] = item;

					setTypesAndValuesPartNumber([]);
					setTypesAndValuesPartNumber(
						typesAndValuesPartNumberValuesCopy,
					);

					initDataPartNumberCopy.item_id = "";

					setInitDataPartNumber({ ...initDataPartNumberCopy });
				}, 0);

				setDoRequest(true);
				setSincronizeData(false);
			}

			//addFiles
			await Promise.all(
				itemImageToAdd.map(async (fileItem) => {
					return await createImageItem(
						"documents",
						location?.search?.split("=")[1],
						fileItem,
						"Invoice",
						imageFormats.includes(fileItem.type) ? "image" : "file",
						"invoice_id",
					);
				}),
			);

			//delete files
			await Promise.all(
				imageIdsArray
					.filter((id) => parseInt(id))
					.map(async (id) => {
						return await deleteImageItem(
							"documents",
							id,
						);
					}),
			);
		}
	};

	//redirect module
	const buttonClick = (field_id, typeSave = "Add") => {
		// /costumer_form
		if (field_id === "createCustomer") {
			history.push({
				pathname: `/costumer_form`,
				data: {
					redirectTo: `/invoice_form_update`,
					invoice_id: location?.search?.split("=")[1],
				},
			});
		}
		if (field_id === "onSubmit") {
			onHandleSubmit(typeSave);
		}
		if (field_id === "Save") {
			onHandleSubmit(typeSave);
		}
	};

	const onDelete = (event) => {
		setNewStylesInModal();
		setCustomCurrentItem(event.id);
		manageDeleteMessage("Invoice Detail");
		setCustomMessageModal(
			`Are you sure you want to delete the item ${event.part_number} ?`,
		);
		setShowModal(true);
	};

	const onEdit = () => {};

	const actionsDefinition = [
		{
			name: "Edit",
			func: onEdit,
		},
		{
			name: "Delete",
			func: onDelete,
		},
	];

	//adjustement detail implementation
	const onMenuActions = async (event, element) => {};

	const [show, setShow] = React.useState(false);
	const prepareDetailToUpdate = (index) => {
		const item = invoiceItems.findIndex(
			(itemData) => itemData.part_number == index,
		);
		setCurrentId(item);
		setShow(true);
	};

	const updateInvoiceDetail = async (ev) => {
		setShow(false);
		
		let mapRequest = {
			item_id: ev.item_id,
			price: ev.rate,
			quantity: ev.quantity,
			comment: ev.description,
		};

		let response = await updateModel(
			ev.id,
			mapRequest,
			"Invoice Detail",
			{},
			"invoice-details",
			true,
		);

		if (response && response.hasOwnProperty("id")) {
			setDoRequest(true);
			setSincronizeData(false);
		}
	};

	const setNewStylesInModal = () => {
		setActionsFooterStyle(true);
		setCancelText("No");
		setSuccessText("Yes");
	};

	const quitNewStylesInModal = () => {
		setActionsFooterStyle(false);
		setCancelText("");
		setSuccessText("");
	};

	const cancelInvoice = () => {
		setNewStylesInModal();
		if (changeInForm) {
			setShowModal(true);
			setCustomTittleText(`Leave invoice creating`);
			setCustomMessageModal(`Do you want to leave without saving?`);
			setShowSuccessButton(true);
		} else {
			history.push("/invoice");
		}
	};

	//generador de pdf
	const [PDFModalIsOpen, setPDFModalIsOpen] = useState(false); 
	const [PDFUrl, setPDFUrl] = useState(false); 

	const printPreview = async () => {
		setIsLoading(true);
		setPDFModalIsOpen(true);
		const url = `invoices/${location?.search?.split("=")[1]}/generate`;
		fetch(`${process.env.REACT_APP_API_URL}api/${url}`, {
			method: "GET",
			// mode: "cors",
			headers: new Headers({
				Accept: "application/octet-stream",
				Authorization:
					"Bearer " +
					(localStorage.jwt_token !== undefined
						? JSON.parse(localStorage.jwt_token)
						: ""),
			}),
		})
			.then((response) => response.blob())
			.then((blob) => {
				var file = window.URL.createObjectURL(blob);
				// window.location.assign(file);
				setPDFUrl(file);
			})
			.finally(()=>{
				setIsLoading(false);
			});
	};

	const createReceivedPayment = () => {
		let redirectObject = {
			pathname: "/payment_form",
			data: {
				customerData: { ...customerInfo },
				invoice_id: location?.search?.split("=")[1],
			},
		};
		history.push(redirectObject);
	};

	const [currentPayTermId, setCurrentPayTermId] = useState("4");
	const [currentDateReceipt, setCurrentDateReceipt] = useState(
		new Date().toLocaleDateString("en-CA", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		}),
	);

	useEffect(() => {
		if (evaluateDates) {
			let initDataCopy = { ...initData };
			if (Object.keys(initData).length > 0) {
				const { pay_term_id, date_receipt } = initData;
				if (
					pay_term_id !== currentPayTermId ||
					date_receipt !== currentDateReceipt
				) {
					const daysToAdd = payTerms[payTerms.findIndex((term) => term.id == pay_term_id)]?.days;
					const dateReceipt = new Date(date_receipt);
					initDataCopy = {
						...initDataCopy,
						due_date: addDaysToCurrentDay(dateReceipt, daysToAdd),
					};
					setCurrentPayTermId(pay_term_id);
					setCurrentDateReceipt(date_receipt);
				}
			}
			setInitData(initDataCopy);
			setEvaluateDates(false);
		}
	}, [evaluateDates]);

	const handleOnInputChange = (ev) => {
		setCurrentSkuFromInput(ev);
	};

	const [show_add, setShowAdd] = useState(false);

	const handleShowAdd = () => setShowAdd(true);
	
	function validateFields() {
		//validamos que existan espacios vacios

		let nedd_to_validate = false;

		if (skuNumber.toString().trim() !== "") {
			nedd_to_validate = true;
		}
		if (location_name.toString().trim() !== "") {
			nedd_to_validate = true;
		}
		if (parseFloat(cost) > 0 || cost.toString().trim() !== "") {
			nedd_to_validate = true;
		}

		if (!nedd_to_validate) {
			return false;
		}

		let falidate_fields_errors = [];

		// if(part_number_id.toString().trim() === '' ){

		// 		falidate_fields_errors.push({
		// 				'field_id' : 'part_number_id',
		// 				'message'  : 'Part Number need to be valid'
		// 		});

		// }

		if (skuNumber.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "part_number_name",
				message: "Part Number need to be valid",
			});
		}

		if (item_list.length > 0) {
			let exists_item = item_list.find(
				(element) => element.name === part_number_name.toString(),
			);

			if (exists_item === undefined) {
				falidate_fields_errors.push({
					field_id: "part_number_name",
					message: "Part number entered not exists",
				});
			}
		}

		if (quantity <= 0) {
			falidate_fields_errors.push({
				field_id: "quantity",
				message: "Quantity need to be greater than 0",
			});
		}

		if (parseFloat(cost) <= 0 || cost.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "cost",
				message: "Cost need to be greater than 0",
			});
		}

		if (falidate_fields_errors.length > 0) {
			return false;
		}

		return true;
	}

	function addNewStockReceiptDetail(
		itemId,
		itemName,
		validateField = true,
		currentCost = null,
	) {
		if (validateField) {
			if (!validateFields()) {
				return false;
			}

			let exists_same_item = stock_receipt_details.find(
				(element) =>
					element?.item_id === itemId &&
					element?.location_id === location_id &&
					element?.cost === cost &&
					element?.quantity === quantity,
			);

			if (exists_same_item) {
				manageResponseErrors(
					"The item is already added",
					"Stock Receipt Items",
					"updating",
				);

				return false;
			}
		}
	}

	const createNewStockReceipt = async (
		dataId,
		dataName,
		stockReceiptId,
		errorCreating = false,
		currentCost = null,
		validateField = true,
	) => {
		let newStockReceiptItem = addNewStockReceiptDetail(
			dataId,
			dataName,
			validateField,
			currentCost,
		);
	}

	const handleCloseAdd = async (currentSku, currentCost) => {
		setShowAdd(false);

		if (skuNumber || currentSku) {
			// setIsLoading(true);
			const { data } = await getSkuItemInformation(
				skuNumber ? skuNumber : currentSku,
			);

			if (!(Object.keys(data).length === 0)) {
				await createNewStockReceipt(
					data.id,
					data.name,
					receipt_stock_number,
					false,
					currentCost,
					false, //validate fields
				);
				setSincronizedItemsList(false);
			}
			stock_setIsLoading(false);
		}
	};

	const getElementWhenAddNewItem = () => {
		stock_setIsLoading(true);
		setSincronizedItems(false);
	};

	const [showPanelCustomer, setShowPanelCustomer] = useState(false);
  const toggleShowPanelCustomer = () => setShowPanelCustomer((state) => !state);
	const handleResponseCreateCustomer = (customer) => {
		onChangeSearchInputCustomer(customer);
		toggleShowPanelCustomer();
	}
	const handleSiblings = (prop = {}) => {
		switch (prop.panel) {
			case PanelNames.PANEL_CUSTOMER:
				toggleShowPanelCustomer();
				break;
			case PanelNames.PANEL_PRODUCT:
				handleShowAdd()
				break;
			default:
				break;
		}
	}

	const closeShowEstimate = () => {
		setShowEstimate(false);
		setCurrentEstimate({});
	}

	return (
		<>
			<Dialog
				open={showEstimate}
				onClose={closeShowEstimate}
				title={`Estimate ${!!currentEstimate.id ? `#${currentEstimate.id}` : ''}`}
				fullWidth
				children={(onClose) => <InvoiceEstimate estimate={currentEstimate} onClose={onClose} loadingEstimate={loadingEstimate} />}
			/>
			<Panel open={showPanelCustomer} anchor="right" togglePanel={toggleShowPanelCustomer} headerTitle="New Client">
        <CostumerForm handleResponse={handleResponseCreateCustomer} />
      </Panel>
			<div
				className=""
				style={{ position: "relative" }}
			>
				<div className="container-title mb-2">
					<h1 className="container__title mb-0">
						{props.actionTittle + " Invoice"} #{" "}
						{location?.search?.split("=")[1]}
					</h1>
					<div className="action-buttons-container">
						
						<div className="print-button">
							<CustomButton
								style={{ paddingInline: '1rem', fontSize: '13px' }}
								buttonName={"Print or Preview"}
								buttonOnClick={printPreview}
								type={"secondary"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
							/>
						</div>
						<div className="cancel-button">
							<CustomButton
								style={{ paddingInline: '1rem', fontSize: '13px' }}
								buttonName={"Cancel"}
								buttonOnClick={cancelInvoice}
								type={"secondary"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
							/>
						</div>

						<div className="save-buttton">
							<div className="save-buttton">
								<CustomButton
									style={{ paddingInline: '1rem', fontSize: '13px' }}
									buttonName={"Save"}
									buttonOnClick={() => buttonClick("Save", "save")}
									type={"success"}
									icon={""}
									disabled={false}
									margins={"mt-0 mb-0"}
								/>
							</div>
						</div>
						<div className="send-button">
							<CustomButton
								style={{ paddingInline: '1rem', fontSize: '13px' }}
								buttonName={"Save and send"}
								buttonOnClick={() => buttonClick("Save", "saveAndSend")}
								type={"success"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
							/>
						</div>
					</div>
				</div>

				<div className="row mt-1">
					{typesAndValuesFields.length > 0 &&
						Object.keys(initData).length > 0 && (
							<div className="col-12">
								<CustomForm
									onHandleFormClose={() => {}}
									onHandleSubmit={onHandleSubmit}
									typesAndValuesFields={typesAndValuesFields}
									initData={initData}
									formTittle={""}
									dontShowCancelButton={true}
									id={null}
									getUrlPath={""}
									inputConf={{
										marginTop: "1",
										marginBottom: "0",
										stringCol:"col-12 col-md-4 col-lg-2"
									}}
									buttonClick={buttonClick}
									onChangeFields={onChangeFieldsInitData}
									initOnChangeFields={onChangeFieldsInitData}
									dontShowSubmitButton={true}
									childFunc={childFunc}
									dontShowErrosinOnChange={true}
									getObjectInfoOnChange={
										onChangeSearchInputCustomer
									}
									notifyChange={() => setChangeInForm(true)}
									handlerSiblings={handleSiblings}
								/>
							</div>
						)}
				</div>
				
				<div className="row mt-1 justify-content-between">
					<div className="col-12 col-md-4">
						<div className="billing_address_container">
							<label>Billing Address</label>
							<address
								className="address-container border-lightgray"
								style={{
									height:
										Object.keys(initDataBillingAddress).length >
											0 &&
										initDataBillingAddress.comments.length > 0
											? "auto"
											: "131px",
								}}
							>
								{Object.keys(initDataBillingAddress).length > 0 &&
									initDataBillingAddress.comments.length > 0 &&
									initDataBillingAddress.comments
										.split(",")
										.map((comment, key) => (
											<React.Fragment key={key}>
												{comment}
												<br />
											</React.Fragment>
										))}
							</address>
						</div>
					</div>
					<div className="balance-table-container col-12 col-md-6">
						<div
							className={`balance-modal-info pt-2 ${showPaymentModal && "show-modal"}`}
							onMouseLeave={() => { setShowPaymentModal(false) }}
						>
							<div className="balance-modal-info-table">
								<div className="balance-modal-info-table-headers">
									<div className="balance-modal-info-table-headers-tittle">
										Date
									</div>
									<div className="balance-modal-info-table-headers-tittle">
										Amount applied
									</div>
									<div className="balance-modal-info-table-headers-tittle">
										Payment no.
									</div>
								</div>
								<div className="balance-modal-info-table-data">
									{paymentsArray.map((payment, index) => (
										<React.Fragment key={index}>
											<div className="balance-modal-info-table-data-row">
												<div className="balance-modal-info-table-data-row-item balance-modal-date-item">
													{
														payment.payment_info
															.payment_date
													}
												</div>
												<div className="balance-modal-info-table-data-row-item">
													${" "}
													{Number(
														payment.payment_info
															.total_payment,
													).toFixed(2)}
												</div>
												<div className="balance-modal-info-table-data-row-item">
													{payment.id}
												</div>
											</div>
										</React.Fragment>
									))}
								</div>
							</div>
						</div>

						<div className="balance-table">
							<div className="balance-table-content">
								{invoiceStatus === INVOICE_ENTRY_STATE && (
									<>
										<div className="balance-table-balance-due pt-2">
											<label>Balance Due</label>
										</div>
										<div className="balance-table-balance-total">
											<span>
												$ {formAmountValues.balance}
											</span>
										</div>
										<div className="balance-button">
											<CustomButton
												className={
													"balance-button-pay rounded-1"
												}
												buttonName={
													"Received Payment"
												}
												buttonOnClick={
													createReceivedPayment
												}
												type={"-"}
												icon={""}
												disabled={false}
												margins={"mt-0 mb-0"}
											/>
										</div>
									</>
								)}
								{invoiceStatus === INVOICE_PAID_STATE && (
									<>
										<div className="balance-table-balance-due">
											<label>Payment status</label>
										</div>
										<div className="balance-table-balance-total">
											<span>
												{invoiceStatus.toUpperCase()}
											</span>
										</div>
										<div
											className="balance-info-text"
											onMouseEnter={() => {
												setShowPaymentModal(true);
											}}
										>
											{paymentsArray.length > 0 && (
												<>
													Last payment made ($
													{Number(
														paymentsArray[
															paymentsArray.length -
																1
														].payment_info
															.total_payment,
													).toFixed(2)}
													)
												</>
											)}
										</div>
									</>
								)}
								{(invoiceStatus === INVOICE_COMPLETE_STATE || invoiceStatus === INVOICE_PENDING_STATE) && (
									<>
										<div className="balance-table-balance-due">
											<label>Payment status</label>
										</div>
										<div
											className={`balance-table-balance-total ${
												invoiceStatus ===
													INVOICE_COMPLETE_STATE &&
												"complete-text"
											}`}
										>
											<span>
												{invoiceStatus.toUpperCase()}
											</span>
										</div>
									</>
								)}
							</div>
						</div>
						{!!estimateId && (
							<div className="text-end">
								<span role="button" className="fs-small text-success text-decoration-underline" onClick={() => fetchEstimate(estimateId)}>
									View Estimate
								</span>
							</div>
						)}
					</div>
				</div>
				
				{typesAndValuesPartNumber.length > 0 &&
					Object.keys(initDataPartNumber).length > 0 && (
						<div className="">
							<CustomForm
								onHandleFormClose={() => {}}
								onHandleSubmit={onHandleSubmit}
								typesAndValuesFields={typesAndValuesPartNumber}
								initData={initDataPartNumber}
								formTittle={""}
								dontShowCancelButton={true}
								id={null}
								getUrlPath={""}
								inputConf={{
									marginTop: "1",
									marginBottom: "0",
									stringCol:"col-12 col-md-3"
								}}
								buttonClick={buttonClick}
								onChangeFields={onChangeFieldsInitDataPartNumber}
								initOnChangeFields={onChangeFieldsInitDataPartNumber}
								dontShowSubmitButton={true}
								dontShowErrosinOnChange={true}
								childFunc={childFunc3}
								getObjectInfoOnChange={() => {}}
								notifyChange={() => setChangeInForm(true)}
								handleOnInputChange={handleOnInputChange}
								handlerSiblings={handleSiblings}
							/>
						</div>
					)}
				</div>

				<div className="row">
					<div className="col-12 col-md-8">
						<div className="mt-1">
							<CustomDataList
								onChangePageEvent={() => {}}
								style={styles}
								isLoading={isLoading}
								dataList={invoiceItems}
								tableHeaders={tableHeaders}
								itemPagination={null}
								handlePagination={() => {}}
								dontShowPerPageSelect={true}
								showLinearIndicator={true}
								showDataInLoading={true}
								render={(element, index) => (
									<CustomDataListItem
										key={index}
										dataFields={dataFields}
										imageFields={[]}
										composedFields={[]}
										arrayFields={[]}
										element={element}
										index={index}
										no_image={no_image}
										dontShowActions={true}
										showActionsButtons={invoiceStatus != INVOICE_PAID_STATE}
										deleteRowTable={onDelete}
										updateRowTable={() => {}}
										renderActions={() => (
											<>
												<PositionedMenu
													title={"Actions"}
													anchorEl={anchorEl}
													setAnchorEl={setAnchorEl}
													handleClose={onCloseModal}
													handleClick={(event) =>
														onMenuActions(event, element)
													}
													actionsDefinition={
														actionsDefinition
													}
													renderActions={(action, index) => (
														<MenuItem
															sx={{
																boxShadow: "none",
															}}
															key={index}
															onClick={() =>
																action.func()
															}
														>
															<div
																className="items-option"
																style={{
																	padding: "0 5px",
																	color: "grey",
																}}
															>
																<FontAwesomeIcon
																	style={{
																		margin: "4px",
																		marginRight: "10px",
																		fontSize: "12px",
																	}}
																	icon={faTrash}
																/>
																<p>{action.name}</p>
																<hr />
															</div>
														</MenuItem>
													)}
												></PositionedMenu>
											</>
										)}
										renderComponentInField={(data) => (
											<>
												<MaterialUI.Button.Primary className="mw-auto px-2 me-1" onClick={() => prepareDetailToUpdate(data)}>
													<FontAwesomeIcon fontSize={14} icon={faEdit}/>
												</MaterialUI.Button.Primary>
												<span className="me-1">{ data }</span>
											</>
										)}
									/>
								)}
							/>
						</div>
					</div>
					<div className="col-12 col-md-4">
						{typesAndValuesMessageOnInvoice.length > 0 &&
							Object.keys(initDataMessageOnInvoice).length > 0 && (<>
								<div className="row">
									<div className="col-12">
										<div style={{ background: "", display: "grid", justifyContent: "flex-end" }} className="align-items-center">
											<div className="amount-table">
												<div className="letter amount-table-subtotal fs-small">
													Subtotal
												</div>
												<div className="letter amount-table-item amount-table-subtotal-value fs-small font-inter">
													$ {formAmountValues.total}
												</div>
												<div className="letter amount-table-discount">
													<select
														name="typeDiscount"
														value={typeDiscount}
														className="form-select form-select-sm"
														disabled={invoiceStatus === INVOICE_PAID_STATE }
														onChange={TypeDiscounthandleChange}
													>
														<option value={"percentage"}>Discount percent</option>
														<option value={"monto"}>Discount value</option>
													</select>
												</div>
												<input
													style={{ textAlign: "right" }}
													name="discount_percentage"
													onChange={onChangeFormAmountValues}
													value={formAmountValues.discount_percentage}
													disabled={invoiceStatus === INVOICE_PAID_STATE}
													className="form-control form-control-sm input-type-number h-75 align-self-center"
													onKeyDown={blockInvalidChar}
												/>
												<div className="letter amount-table-item fs-small font-inter">
													${" "}
													{(formAmountValues.discount_total ||
														0) == 0
														? ""
														: "-"}
													{formAmountValues.discount_total ||
														0}
												</div>
												<div className="letter total-taxable-table-item fs-small">
													Total taxable : ${" "}
													<span className="font-inter">
														{(formAmountValues.tax_por || 0.0)>
														0
															? parseFloat(formAmountValues.total - formAmountValues.discount_total).toFixed(2)
															: "0.00"}
													</span>
												</div>
												<div className="sale_tax-table letter">
													<span className="fs-small d-block text-end">Sales tax</span>
													<select
														name="sales tax"
														value={saleTax}
														onChange={SaleTaxOnChange}
														className="form-select form-select-sm"
														disabled={invoiceStatus === INVOICE_PAID_STATE}
													>
														<option value={"1"}>{formAmountValues.tax_por} %</option>
														<option value={"2"}>No tax</option>
													</select>
												</div>
												<div className="letter amount-table-item amount-table-balance-due-value fs-small font-inter">
													$ {parseFloat(formAmountValues.tax_amount).toFixed(2)}
												</div>
												<div className="letter amount-table-deposit fs-small">Deposit</div>
												<input
													style={{ textAlign: "right" }}
													name="deposit"
													onChange={onChangeFormAmountValues}
													value={formAmountValues.deposit}
													className="form-control form-control-sm custom-input input-type-number amount-table-deposit-value h-75 align-self-center"
													onKeyDown={blockInvalidChar}
													disabled={invoiceStatus === INVOICE_PAID_STATE}
												/>

												{invoiceStatus ===
													INVOICE_PAID_STATE && (
													<>
														<div className="letter amount-table-balance-due fs-small p-0">Amount received</div>
														<div className="letter amount-table-item amount-table-balance-due-value fs-small font-inter">
															$ {formAmountValues.total_payments}
														</div>
													</>
												)}
												<div className="letter amount-table-balance-due fs-small p-0">Balance due</div>
												<div className="letter amount-table-item amount-table-balance-due-value fs-small font-inter">
													$ {formAmountValues.balance}
												</div>
											</div>
										</div>
									</div>
									<div className="col-12">
										<CustomForm
											onHandleFormClose={() => {}}
											onHandleSubmit={onHandleSubmit}
											typesAndValuesFields={
												typesAndValuesMessageOnInvoice
											}
											initData={initDataMessageOnInvoice}
											formTittle={""}
											dontShowCancelButton={true}
											id={null}
											getUrlPath={""}
											inputConf={{
												marginTop: "1",
												marginBottom: "0",
												stringCol: "col-12",
											}}
											buttonClick={buttonClick}
											onChangeFields={onChangeFieldsInitDataMessageOnInvoice}
											initOnChangeFields={onChangeFieldsInitDataMessageOnInvoice}
											dontShowSubmitButton={true}
											childFunc={childFunc2}
											dontShowErrosinOnChange={true}
											notifyChange={() => setChangeInForm(true)}
										/>
									</div>
									<div className="col-12">
										<div className='mx-0 mx-md-1 w-100'>
											<div className="content-notes col-12"><label className="form-label mb-0">Files</label></div>
											<Box sx={{ overflow: "auto" }} className="p-1 border scroll_customized rounded-1">
												<CustomImageViewer
													itemData={documentsImageArray}
													onClickImage={() => {}}
													onDeleteImage={onDeleteImage}
													showDefaultPdfIcon={true}
													showDowloadAction={true}
												>
													<div className="user-select-none p-3 rounded-2 d-flex flex-column align-items-center justify-content-center" style={{ width: '95px', height: 'auto', border: '2px dashed #3f51b5', backgroundColor: 'rgb(63 81 181 / 15%)', color: '#3f51b5', cursor: 'pointer' }} onClick={() => openFileImage()}>
														<FontAwesomeIcon fontSize={14} icon={faPlus} className='mb-2'/>
														<p className="mb-0" style={{ fontSize: '12px', lineHeight: '1', textAlign: 'center' }}>Attach documents</p>
														<input
															hidden
															id="file_input_doc_input"
															type="file"
															onChange={(e) => setFileImage(e)}
															accept="image/*,.pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx"
															multiple
														/>
													</div>
												</CustomImageViewer>
											</Box>
										</div>
									</div>
								</div>
							</>)}
					</div>
				</div>

				<div className="fixed-bottom action-buttons-wrapper">
					<div className="action-buttons-container">
						<div className="print-button">
							<CustomButton
								buttonName={"Print or Preview"}
								buttonOnClick={printPreview}
								type={"secondary"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
								style={{ paddingInline: '1rem', fontSize: '13px' }}
							/>
						</div>

						<div className="cancel-button">
							<CustomButton
								buttonName={"Cancel"}
								buttonOnClick={cancelInvoice}
								type={"secondary"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
								style={{ paddingInline: '1rem', fontSize: '13px' }}
							/>
						</div>
						
						<div className="save-buttton">
							<div className="save-buttton">
								<CustomButton
									buttonName={"Save"}
									buttonOnClick={() =>
										buttonClick("Save", "save")
									}
									type={"success"}
									icon={""}
									disabled={false}
									margins={"mt-0 mb-0"}
									style={{ paddingInline: '1rem', fontSize: '13px' }}
								/>
							</div>
						</div>
						<div className="send-button">
							<CustomButton
								buttonName={"Save and send"}
								buttonOnClick={() =>
									buttonClick("Save", "saveAndSend")
								}
								type={"success"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
								style={{ paddingInline: '1rem', fontSize: '13px' }}
							/>
						</div>
					</div>
				</div>
											
			

			{/* loading modal */}
			{/* <CustomModal
                show={isLoading}
                customTittleText={''}
                customMessageModal={''}
                onHandleClose={() => { }}
                isLoading={isLoading}
            /> */}

			{/* response modal */}
			{/* <CustomModal
                show={showModal}
                customTittleText={customTittleText}
                customMessageModal={customMessageModal}
                onHandleClose={onHandleResponseModal}
                onHandleSuccess={() => { !customCurrentItem ? history.push('/invoice') : onHandleDelete() }}
                isLoading={isLoading}
                showSuccessButton={showSuccessButton}
            /> */}
			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={onHandleResponseModal}
				onHandleSuccess={() => {
					!customCurrentItem
						? history.push("/invoice")
						: onHandleDelete();
				}}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
				actionsFooterStyle={actionsFooterStyle}
				cancelText={cancelText}
				successText={successText}
			/>

			<CustomModal
				show={files_showModal}
				customTittleText={files_customTittleText}
				customMessageModal={files_customMessageModal}
				onHandleClose={files_onHandleResponseModal}
				onHandleSuccess={() => onHandleDeleteFile()}
				isLoading={isLoading}
				showSuccessButton={files_showSuccessButton}
				actionsFooterStyle={actionsFooterStyle}
				cancelText={cancelText}
				successText={successText}
			/>

			{/* modal form */}
			<CustomModal
				show={show}
				customTittleText={""}
				customMessageModal={""}
				onHandleClose={() => setShow(false)}
				onHandleSuccess={() => {}}
				isLoading={false}
				showSuccessButton={true}
			>
				<CustomForm
					onHandleFormClose={() => setShow(false)}
					location_list={[]}
					onHandleSubmit={updateInvoiceDetail}
					typesAndValuesFields={typesAndValuesUpdateDetailInvoice}
					initData={invoiceItems[currentId]}
					formTittle={""}
				/>
			</CustomModal>

			<ModalAddItem
				handleCloseAdd={() => setShowAdd(false)}
				getElementWhenAddNewItem={getElementWhenAddNewItem}
				modal_conf={modal_add_conf}
				onHandleClose={handleCloseAdd}
				show={show_add}
				skuNumber={skuNumber}
			></ModalAddItem>

			<PDFModal title={"Invoice PDF"} isOpen={PDFModalIsOpen} closeModal={() => setPDFModalIsOpen(false)} pdfUrl={PDFUrl} isLoading={isLoading}/>
		</>
	);
}

export { InvoiceFormUpdate };
