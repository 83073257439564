import { useState, useCallback } from "react";
import Service from '../services/AdjustmentService';

function useAdjustment() {
	const [isLoading, setIsLoading] = useState(false);

	const find = useCallback(async (id) => {
		try {
      setIsLoading(true);
      const resp = await Service.findById(id);
      return resp;
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
	}, []);
	
	const store = async (payload) => {
		try {
			setIsLoading(true);
			const resp = await Service.store(payload);
			return resp;
		} catch (error) {
			throw error;
		} finally {
			setIsLoading(false);
		}
	}

	const update = async ({ id, ...payload }) => {
		try {
			setIsLoading(true);
			const resp = await Service.update(id, payload);
			return resp;
		} catch (error) {
			throw error;
		} finally {
			setIsLoading(false);
		}
	}

	const storeDetail = async (payload) => {
		try {
			setIsLoading(true);
			const resp = await Service.storeDetail(payload);
			return resp;
		} catch (error) {
			throw error;
		} finally {
			setIsLoading(false);
		}
	}

	const updateDetail = async ({ id, ...payload }) => {
		try {
			const resp = await Service.updateDetail(id, payload);
			return resp;
		} catch (error) {
			throw error;
		}
	}
	
	const changeStateDetail = async (payload) => {
		try {
			const resp = await Service.updateStateDetail(payload);
			return resp;
		} catch (error) {
			throw error;
		}
	}

	const destroyDetail = async (id) => {
		try {
			const resp = await Service.destroyDetail(id);
			return resp;
		} catch (error) {
			throw error;
		}
	}

	return {
		setIsLoading,
		find,
		store,
		storeDetail,
		changeStateDetail,
		destroyDetail,
		updateDetail,
		update,
		
		isLoading,
	};
}

export { useAdjustment };
