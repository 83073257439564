import React, { useContext } from "react";
import { FaBars } from "react-icons/fa";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { AppContext } from "../../context/AppContext";
import { useAuthentication } from "../../customHooks/useAuthentication";
import { FiUser, FiLogOut, FiBellOff, FiMaximize, FiMinimize } from "react-icons/fi";
import { Divider, ListItem, ListItemAvatar, ListItemText, IconButton, MenuItem, Menu, Avatar } from "@material-ui/core";
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';

import AvatarDefault from '../../assets/img/avatar-default.png';
import './header.style.css';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
		background: "transparent",
	},
	title: {
		flexGrow: 1,
	},
}));

const Header = () => {
	const { navigationExpanded, changeNavigationVisible } = useContext(AppContext);
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorElNotifications, setAnchorElNotifications] = React.useState(null);
	const open = Boolean(anchorEl);
	const openNotifications = Boolean(anchorElNotifications);
	const history = useHistory();
	const location = useLocation();
	
	const [user] = React.useState(() => {
		if(!localStorage.user_data) return {};
		try {
			const data = JSON.parse(localStorage.user_data);
			return data;
		} catch (error) {
			return {};
		}
	});

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuNotifications = (event) => {
		setAnchorElNotifications(event.currentTarget);
	};

	const handleCloseNotifications = () => {
		setAnchorElNotifications(null);
	};

	const { setCurrentToken, deleteItemFromLocalStorage } = useAuthentication();

	const logout = () => {
		const rootElement = document.getElementById("root");

		localStorage.setItem("jwt_token", null);
		localStorage.setItem("login_count", null);
		localStorage.setItem("user_data", null);
		setCurrentToken(null);
		deleteItemFromLocalStorage("user_data");
		history.push("/");
	};

	const intl = useIntl();

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [isFullScreen, setFullScreen] = React.useState(false);
	const toggleFullScreen = () => {
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
		} else {
			if (document.exitFullscreen)
				document.exitFullscreen()
		}
		setFullScreen(!isFullScreen);
	};

	const isPos = location.pathname == '/pos';

	return (
		<header>
			<div className={classes.root}>
				<div className="topbar">
					<div className="d-flex align-items-center">
						<div className="toggle" onClick={() => changeNavigationVisible(!navigationExpanded)}>
							<FaBars />
						</div>
						{user?.warehouse?.name && (
							<div className="warehouse__name">
								{user?.warehouse?.name}
							</div>
						)}
					</div>
					<div style={{ width: "100%" }}>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								alignItems: 'center',
								gap: '.8rem'
							}}
						>
							{isPos && (
								<div>
									<IconButton
										onClick={toggleFullScreen}
										color="inherit"
										className="p-1"
									>
										{ isFullScreen ? <FiMinimize /> : <FiMaximize /> }
									</IconButton>
								</div>
							)}
							<div className="d-flex" style={{ inlineSize: '28px' }}>
								<IconButton
									onClick={handleMenuNotifications}
									color="inherit"
									className="p-0 position-relative"
								>

									<i className="fas fa-bell"></i>
									<span className="notifications__count">{3}</span>
								</IconButton>
								<Menu
									id="menu-appbar"
									anchorEl={anchorElNotifications}
									getContentAnchorEl={null}
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									transformOrigin={{ vertical: "top", horizontal: "right" }}
									open={openNotifications}
									onClose={handleCloseNotifications}
								>
									{/* <ListItem className="text-center" style={{ inlineSize: '250px', justifyContent: 'center', flexDirection: 'column' }}>
										<FiBellOff size="22" style={{ color: 'rgba(0, 0, 0, 0.54)' }} className="mb-1" />
										<ListItemText secondary={'No notifications found'} />
									</ListItem> */}
									<ListItem style={{ inlineSize: '250px' }}>
										<ListItemAvatar>
											<Avatar>
												<ImageIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText className="fs-small" primary="Photos" secondary="Jan 9, 2014" />
									</ListItem>
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<WorkIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary="Work" secondary="Jan 7, 2014" />
									</ListItem>
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<BeachAccessIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary="Vacation" secondary="July 20, 2014" />
									</ListItem>
									{/* <MenuItem>
										<p>No notifications found</p>
									</MenuItem> */}
								</Menu>
							</div>
							<div>
								<IconButton
									aria-haspopup="true"
									onClick={handleMenu}
									color="inherit"
									className="p-0"
								>
									<figure className="avatar__container">
										<img src={AvatarDefault} alt="Avatar" />	
									</figure>
								</IconButton>
								<Menu
									id="menu-appbar"
									anchorEl={anchorEl}
									getContentAnchorEl={null}
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									transformOrigin={{ vertical: "top", horizontal: "right" }}
									open={open}
									onClose={handleClose}
								>
									<ListItem style={{ inlineSize: '200px' }}>
										<ListItemAvatar>
											<figure className="avatar__container">
												<img src={AvatarDefault} alt="Avatar" />	
											</figure>
										</ListItemAvatar>
										<ListItemText primary={user.name} secondary={user.super_admin ? 'Super Admin' : 'Admin'} />
									</ListItem>
									<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }} className="mb-2"/>
									<MenuItem onClick={() => history.push('/update_profile')}>
										<FiUser className="me-2"/><span className="font-inter fs-small">My profile</span>
									</MenuItem>
									<MenuItem onClick={() => logout()}>
										<FiLogOut className="me-2"/><span className="font-inter fs-small">Logout</span>
									</MenuItem>
								</Menu>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
