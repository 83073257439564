export default class Adapter {
  static responseVendorToAutocomplete(vendor) {
    return {
      id: vendor?.id,
      label: vendor?.name,
    };
  }
  static responseProductToAutocomplete(product) {
    return {
			id: product?.id,
      sku: product?.sku,
      name: product?.name
		};
  }
  static responseProductSearchByAutocomplete(response) {
    return response.data
  }
  static responseProductWithSkuToOptions(data = {}) {
    const adapted = data?.data?.flatMap(Adapter.#normalizeProductToProductSku)
    return adapted
  }
  static productDetailToForm(payload) {
    delete payload?.item?.item_variants
    const item_variant = payload.item_variant
    const productInformation = payload?.item
    const data = {
      ...item_variant,
      quantity: payload.quantity,
      cost: payload.cost,
      label: `${item_variant.sku} | ${productInformation.name}`,
      item_variant_id: item_variant?.id,
      productInformation
    }
    return data
  }
  static #normalizeProductToProductSku(product) {
    const { item_variants, ...productInformation } = product
    return item_variants.map((c) => ({
      ...c,
      label: `${c.sku} | ${productInformation.name}`,
      item_variant_id: c.id,
      productInformation
    }))
  }
  
}