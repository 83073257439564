import "../expensive.css";

import ExpensiveForm2 from '../ExpensiveForm2';

function ExpensiveForm() {
	return (
		<article className="contenedor container-fluid">
			<ExpensiveForm2 />
		</article>
	);
}

export { ExpensiveForm };
