import imglogo from "../../../assets/img/logo_t4i.png";
import ReactDOM from "react-dom";
import React, { useState } from "react";
import { useForm } from "./hooks/useForm";
import "./signup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Login from "../../../views/login/Login";
import { useHistory } from "react-router-dom";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import { useAuthentication } from "../../../customHooks/useAuthentication";

import { blockInvalidChar } from "../../../context/constants";

const initialForm = {
	code: "",
};

const validationsForm = (form) => {
	let errors = {};

	if (!(form.code.trim().length > 0)) {
		errors.code = "code is invalid";
	}

	return errors;
};

let stylesError = {
	fontWeight: "bold",
	color: "#dc3545",
	fontSize: "13px",
	wordBreak: "break-all",
};

function ValidationCode() {
	const history = useHistory();

	const { getInfoFromLocalStorage } = useAuthentication();

	const {
		form,
		errors,
		handleChange,
		handleBlur,
		handleSubmit,
		showSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		cancelButtonText,
		sucessButtonText,
		isLoading,
		manageResponseMessage,
	} = useForm(initialForm, validationsForm, "ValidateCode");

	const userInfo = getInfoFromLocalStorage("item_validation_info");
	if (!userInfo || !(Object.keys(userInfo).length > 0)) history.push("/404");

	const styles = {
		color: "#1F3656",
		textAlign: "center",
		fontSize: "15px",
	};

	const gotoLogin = () => {
		history.push("/");
	};

	return (
		<>
			<div className="login">
				<div className="form-container__signup">
					<div className="form-container_top">
						<img src={imglogo} alt="logo" className="logo" />
						<span
							className="mb-2"
							style={{
								color: "#1F3656",
								fontWeight: "bold",
								fontSize: "1.2rem",
							}}
						>
							Tech4Inventory
							<div
								className="information-content__signup"
								style={styles}
							>
								<p style={{ margin: "0px" }}>
									Please enter your validation code.
								</p>
							</div>
						</span>
					</div>
					<form
						className="form form-container"
						onSubmit={handleSubmit}
					>
						<div className="theinputs" style={{ padding: "20px" }}>
							<input
								type="number"
								className="input custom-input"
								name="code"
								id="code"
								value={form.code}
								onBlur={handleBlur}
								onChange={handleChange}
								title="Enter your validation code"
								required
								onKeyDown={blockInvalidChar}
							/>
							{errors.code && (
								<p style={stylesError}>{errors.code}</p>
							)}
							{manageResponseMessage.message.length > 0 && (
								<p style={stylesError}>
									{manageResponseMessage.message}
								</p>
							)}
							<button
								type="button"
								onClick={(e) =>
									handleSubmit(e, "validate_code", false, {
										email: userInfo.email,
									})
								}
								className="primary-button signup-button mt-1 d-flex justify-content-center align-items-center"
							>
								{isLoading && (
									<div
										className="spinner-border"
										role="status"
									>
										<span className="sr-only">
											Loading...
										</span>
									</div>
								)}
								{!isLoading && "VALIDATE CODE"}
							</button>
							<button
								type="button"
								className="primary-button signup-button mt-1 d-flex justify-content-center align-items-center"
								onClick={(e) =>
									handleSubmit(e, "resend_code", true, {
										email: userInfo.email,
									})
								}
							>
								{isLoading && (
									<div
										className="spinner-border"
										role="status"
									>
										<span className="sr-only">
											Loading...
										</span>
									</div>
								)}
								{!isLoading && "RE-SEND CODE"}
							</button>
						</div>
					</form>

					{manageResponseMessage.status &&
						manageResponseMessage.message.length > 0 &&
						manageResponseMessage.showRedirectButton && (
							<div className="succefull-message mt-1">
								<p className="succefull-message-text">
									{manageResponseMessage.message}
									<a
										className="succefull-message-text-ancla"
										onClick={() => history.push("/")}
									>
										{" "}
										go to login.
									</a>
								</p>
							</div>
						)}
				</div>
			</div>

			{/* <CustomModal
        show={isLoading}
        customTittleText={''}
        customMessageModal={''}
        onHandleClose={() => { }}
        isLoading={isLoading}
      /> */}

			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowModal(false)}
				onHandleSuccess={() => {}}
				isLoading={false}
				showSuccessButton={showSuccessButton}
				actionsFooterStyle={true}
				cancelText={cancelButtonText}
				successText={sucessButtonText}
			/>
		</>
	);
}

export { ValidationCode };
