import React from "react";
import img from "./../../assets/img/upload_image.jpg";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core";

const FabPrimary = withStyles({
	root: {
    backgroundColor: '#0d6efd',
		'&:hover': {
      backgroundColor: '#0b5ed7',
    },
  },
})(Fab);

function ImageInput({
	element,
	element_index,
	onElementChange,
	from_form,
	preview_image,
	setImagePreview,
	setItemImageToAdd,
	itemImageToAdd,
	setItemImageArray,
	itemImageArray,
}) {
	const inputRefOne = React.useRef(null);
	const inputRefTwo = React.useRef(null);
	
	const previewImage = (e, element_index) => {

		if (e.target.files.length > 0) {
			let preview = URL.createObjectURL(e.target.files[0]);
			setItemImageToAdd([...itemImageToAdd, e.target.files[0]]);
			setItemImageArray([
				...itemImageArray,
				{
					id: e.target.files[0].name,
					path: preview,
				},
			]);
			setImagePreview(preview);
			onElementChange(e, element_index);
			inputRefOne.current.value = "";
			inputRefTwo.current.value = "";
		} else {
			setImagePreview(img);
		}
	};

	return (
		<div>
			<div hidden={from_form !== undefined ? false : true}>
				<div className="d-flex justify-content-center mt-3">
					<img
						src={preview_image}
						style={{
							border: "1px solid #cccccc",
							width: "200px",
							height: "200px",
							cursor: "default",
							objectFit: 'cover',
							borderRadius: '0.2rem'
						}}
					></img>
				</div>

				<div className="d-flex justify-content-center mt-1">
					<label>
						<input
							ref={inputRefOne}
							hidden={false}
							onChange={(e) => previewImage(e, element_index)}
							accept="image/png, image/gif, image/jpeg"
							type="file"
							className={
								"form-control " +
								(element.required &&
									element.have_error &&
									"is-invalid")
							}
							id={element.field_id}
							name={element.field_id}
							placeholder={"ENTER " + element.field_name}
							maxLength={element.length}
							style={{ display: "none" }}
						></input>
						<FabPrimary tabIndex="-1" color="primary" size="small" component="span" aria-label="add">
							<AddIcon fontSize="small"/>
						</FabPrimary>
					</label>
					<span className="text-danger float-end">
						{" "}
						<strong>
							{" "}
							{element.required &&
								element.have_error &&
								"Required Field"}{" "}
							&nbsp;{" "}
						</strong>{" "}
					</span>
					{/*<label htmlFor={element.field_id}  > { element.field_name } <strong> {  (element.required) && '*' } </strong> </label>   */}
				</div>
			</div>
			<div
				className="form-group mt-2 mb-2"
				hidden={from_form !== undefined ? true : false}
			>
				<label htmlFor={element.field_id}>
					{" "}
					{element.field_name}{" "}
					<strong> {element.required && "*"} </strong>{" "}
				</label>
				<input
				  ref={inputRefTwo}
					onChange={(e) => previewImage(e, element_index)}
					accept="image/png, image/gif, image/jpeg"
					type="file"
					className={
						"form-control " +
						(element.required && element.have_error && "is-invalid")
					}
					id={element.field_id}
					placeholder={"ENTER " + element.field_name}
					maxLength={element.length}
				></input>
				<span className="text-danger float-end">
					{" "}
					{element.required &&
						element.have_error &&
						"Required Field"}{" "}
					&nbsp;{" "}
				</span>

				<div className="d-flex justify-content-center mt-3">
					<img src={preview_image} width={100} height={100}></img>
				</div>
			</div>
		</div>
	);
}

export { ImageInput };
