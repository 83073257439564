import { FormControl, FormLabel, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #CED4DA',
    borderRadius: '4px',
    position: 'relative',
    inlineSize: '100%',
    padding: '1.2rem .8rem',
  },
  legend: {
    position: 'absolute',
    top: '-.5rem',
    backgroundColor: '#fff',
    display: 'inline-block',
    inlineSize: 'max-content',
    paddingInline: '.55rem',
  }
}));

const Fieldset = ({ label, children }) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset" className={classes.root}>
      {!!label && <FormLabel component="legend" className={classes.legend}>{label}</FormLabel>}
      {children}
    </FormControl>
  )
}

export default Fieldset