import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

// components
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import MenuItem from "@material-ui/core/MenuItem";
import no_image from "../../assets/img/no_image.png";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import "./InvoiceForm.css";

// custom Hooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";
import { useMenuActionHook } from "../../customHooks/useMenuActionHook";
import { useApiRequestTypePayment } from "../../customHooks/useApiRequestTypePayment";

// form configuration
import {
	typesAndValues,
	typesAndValuesMessageOnInvoice,
	initData as initDataValues,
	initDataMessageOnInvoice as initDataMessageOnInvoiceValues,
	initDataBillingAddress as initDataBillingAddressValues,
	typesAndValuesPartNumber,
	initDataPartNumber as initDataPartNumberValues,
	typesAndValuesFindInvoice,
	initDataFindInvoice,
	PanelNames,
} from "./initFormPayment";

import {
	styles,
	tableHeadersReceivePaymentDetail as tableHeaders,
	dataFieldsReceivePaymentDetail as dataFields,
	blockInvalidChar,
	calculate,
} from "../../context/constants";
import { useFileManager } from "../../customHooks/useFileManager";
import { Box } from "@material-ui/core";
import CustomImageViewer from "../../components/CustomImageViewer/CustomImageViewer";
import MaterialUI from "../../components/MaterialUI";


const imageFormats = ["image/png", "image/jpg", "image/jpeg"];

function PaymentForm(props) {
	const location = useLocation();
	const history = useHistory();

	const [invoiceId, setInvoiveId] = useState(null);

	const { anchorEl, setAnchorEl, onCloseModal, setCurrentId } = useMenuActionHook();

	const {
		setCustomMessageModal,
		showSuccessButton,
		setShowSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		setCustomTittleText,
		customMessageModal,
		manageResponseErrors,
		customCurrentItem,
		setCustomCurrentItem,
		manageResponse,
		manageSuccessfullMesssage,
	} = useModalHook();

	const {
		setCustomMessageModal:files_setCustomMessageModal,
		showSuccessButton:files_showSuccessButton,
		setShowSuccessButton:files_setShowSuccessButton,
		showModal:files_showModal,
		setShowModal:files_setShowModal,
		customTittleText:files_customTittleText,
		setCustomTittleText:files_setCustomTittleText,
		customMessageModal:files_customMessageModal,
		customCurrentItem:files_customCurrentItem,
		setCustomCurrentItem:files_setCustomCurrentItem,
	} = useModalHook();

	const {
		documentsImageArray,
		setDocumentsImageArray,
		imageIdsArray,
		itemImageToAdd,
		setFileImage,
		openFileImage,
		onDeleteImage,
	} = useFileManager(
		files_setShowModal,
		files_customCurrentItem,
		files_setCustomCurrentItem,
		files_setCustomTittleText,
		files_setCustomMessageModal,
		files_setShowSuccessButton,
	);

	const {
		deleteItemByUrl,
		createImageItem,
		deleteImageItem,
	} = useApiCustomRequest(
		manageResponseErrors,
		"invoices",
		"Invoice",
		manageResponse,
		manageSuccessfullMesssage,
		true,
	);
	const [actionsFooterStyle, setActionsFooterStyle] = useState(false);
	const [cancelText, setCancelText] = useState(false);
	const [successText, setSuccessText] = useState(false);
	const onHandleDeleteFile = async () => {
		quitNewStylesInModal();
		files_setShowModal(false);
		console.log(documentsImageArray)
		const file_id = documentsImageArray.find(file => file.path === files_customCurrentItem).id;
		console.log(file_id)
		const res = await deleteItemByUrl(file_id, "documents", true);
		if (res) {
			setDocumentsImageArray(prevArray => {
			  return prevArray.filter(file => file.id !== file_id);
			});
		}
		files_setCustomCurrentItem(false);
	};

	const files_onHandleResponseModal = () => {
		quitNewStylesInModal();
		files_setShowModal(false);
	};


	const { filtered_data: typePayments } = useApiRequestTypePayment(
		manageResponseErrors,
		true,
		manageSuccessfullMesssage,
	);

	const setGoToMainPageRedirect = (redirect) => {
		if (redirect) {
			setTimeout(() => {
				console.log("customCurrentItem", customCurrentItem);
				if (
					customCurrentItem &&
					customCurrentItem.hasOwnProperty("id")
				) {
					history.push({
						pathname: "/invoice_form_update",
						search: `?invoice_id=${customCurrentItem.id}`,
					});
				} else {
					history.push("/invoice");
				}
			}, 500);
		}
	};

	const { isLoading, createItem, getListItem, getItemByUrl } =
		useApiCustomRequest(
			manageResponseErrors,
			"payments",
			"Payment",
			manageResponse,
			manageSuccessfullMesssage,
			true,
			// setGoToMainPage
			setGoToMainPageRedirect,
			false,
			() => {},
			setCustomCurrentItem,
		);

	const onHandleResponseModal = () => {
		setShowModal(false);
	};

	const onHandleResponseModalSuccess = () => {
		setShowModal(false);
		history.push("/invoice");
	};

	const [formAmountValues, setFormAmountValues] = React.useState({
		discount_percentage: 0.0,
		deposit: 0.0,
		discount_total: 0.0,
	});

	const [typesAndValuesFields, setTypesAndValuesFields] = React.useState([]);
	const [initData, setInitData] = React.useState({});
	const [changeInForm, setChangeInForm] = React.useState(false);
	const [testData, setTestData] = React.useState([]);
	const [amountToApply, setAmountToApply] = React.useState(Number(0).toFixed(2));
	const [noSelectedInvoiceMessage, setNoSelectedInvoiceMessage] = React.useState(null);
	const [initDataMessageOnInvoice, setInitDataMessageOnInvoice] = React.useState({});

	const onChangeFieldsInitDataMessageOnInvoice = async (event) => {
		setInitDataMessageOnInvoice({ ...event });
	};

	useEffect(() => {
		const initDataMessageOnInvoiceValuesCopy = {
			...initDataMessageOnInvoiceValues,
		};
		setInitDataMessageOnInvoice({ ...initDataMessageOnInvoiceValuesCopy });
	}, []);

	const [initDataBillingAddress, setInitDataBillingAddress] = React.useState({});

	useEffect(() => {
		const initDataBillingAddressCopy = { ...initDataBillingAddressValues };
		setInitDataBillingAddress({ ...initDataBillingAddressCopy });
	}, []);

	const onChangeSearchInputCustomer = (ev) => {
		let initDataCopy = { ...initData };
		let fieldsAndValuesCopy = [...typesAndValuesFields];

		setInitDataBillingAddress({});

		const initDataBillingAddressCopy = { ...initDataBillingAddressValues };
		if (ev) {
			setTimeout(() => {
				const {
					name,
					address_1,
					address_2,
					city,
					state,
					postal_code,
					country,
					email,
					id,
				} = ev;

				initDataCopy = {
					...initDataCopy,
					client_send_email: email,
					customer_id: id,
				};

				const idFieldIndex = fieldsAndValuesCopy.findIndex(
					(item) => item.field_name === "customer_id",
				);
				let fieldId = fieldsAndValuesCopy[idFieldIndex];
				fieldId.inputValue = ``;
				fieldId.doRequest = false;
				fieldId.dontInitData = true;
				fieldId.inputValue = `${name}`;

				const newMessage = `${name},${address_1},${address_2},${city} ${state} ${postal_code},${country}`;
				initDataBillingAddressCopy.comments = newMessage;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
				setInitData({ ...initDataCopy });
				setTypesAndValuesFields([...fieldsAndValuesCopy]);
				getInvoiceToPay(id);
			}, 0);
		}

		if (!ev) {
			setTimeout(() => {
				initDataBillingAddressCopy.comments =
					initDataBillingAddressValues.comments;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
			}, 1);
		}
	};
	//->

	//->
	const [initDataPartNumber, setInitDataPartNumber] = React.useState({});

	const onChangeFieldsInitDataPartNumber = async (event) => {
		setInitDataPartNumber({ ...event });
	};

	useEffect(() => {
		const initDataPartNumberValuesCopy = { ...initDataPartNumberValues };
		setInitDataPartNumber({ ...initDataPartNumberValuesCopy });
	}, []);

	const onChangeFieldsInitData = async (event) => {
		setInitData({ ...event });
	};

	const validateLength = (array) => {
		return array.length > 0;
	};

	const childFunc = useRef(null);
	const childFunc2 = useRef(null);
	const childFunc3 = useRef(null);
	const onHandleSubmit = async (event) => {
		if (isLoading) {
			return;
		}

		if (event === "Add" && !initDataPartNumber.item_id) {
			return;
		}

		const errorsOne = childFunc.current(initData);
		const errorsTwo = childFunc2.current(initDataMessageOnInvoice);
		const errorsThree = childFunc3.current(initDataPartNumber);

		if (
			!validateLength(errorsOne) &&
			!validateLength(errorsTwo) &&
			!validateLength(errorsThree)
		) {
			if (!(testData.filter((item) => item.checked).length > 0)) {
				setNoSelectedInvoiceMessage(true);
				return;
			} else {
				setNoSelectedInvoiceMessage(null);
			}

			let bodyRequest = {
				...initData,
				...initDataMessageOnInvoice,
				// ...initDataPartNumber,
				...formAmountValues,
				// type: 'Estimate'
			};

			console.log("bodyRequest", bodyRequest);

			const {
				customer_id,
				type_payment_id,
				payment_date,
				reference_number,
				memo,
			} = bodyRequest;

			const arrayRequest = testData
				.filter(
					(item) =>
						item.checked && parseFloat(item.total_payments) > 0,
				)
				.map((item) => ({
					customer_id,
					type_payment_id,
					payment_date,
					reference_number,
					memo,
					invoice_id: item.id,
					total_payment: calculate(item.total_payments),
				}));

			const responses = await Promise.all(
				arrayRequest.map(async (request) => {
					return await createItem(request, "Payment", {}, true, true);
				}),
			);

			responses.map((response) => {
				if (response.hasOwnProperty("error") && response.error) {
					manageResponseErrors(
						"An error has occurred",
						"Payments",
						"creating",
					);
				}
			});

			getInvoiceToPay(customer_id, 1, false);

			//addFiles
			const addResponses = await Promise.all(
				itemImageToAdd.map(async (fileItem) => {
					console.log("fileItem", fileItem);
					return await createImageItem(
						"documents",
						invoiceId,
						fileItem,
						"Invoice",
						imageFormats.includes(fileItem.type) ? "image" : "file",
						"invoice_id",
					);
				}),
			);

			console.log("addResponses", addResponses);

			//delete files
			const deleteResponses = await Promise.all(
				imageIdsArray
					.filter((id) => parseInt(id))
					.map(async (id) => {
						return await deleteImageItem(
							"documents",
							id,
						);
					}),
			);

			console.log("deleteResponses", deleteResponses);
		}
	};

	useEffect(() => {
		let initDataValuesCopy = { ...initDataValues };

		if (
			location.hasOwnProperty("data") &&
			location?.data.hasOwnProperty("customerData") &&
			Object.keys(location.data.customerData).length > 0
		) {
			const {
				id,
				name,
				address_1,
				address_2,
				city,
				state,
				postal_code,
				country,
				email,
			} = location.data.customerData;

			initDataValuesCopy = {
				...initDataValuesCopy,
				customer_id: id,
				payment_date: new Date().toLocaleDateString("en-CA", {
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
				}),
				client_send_email: email,
			};

			getInvoiceToPay(id);


			setInitDataBillingAddress({});

			setTimeout(() => {
				const initDataBillingAddressCopy = {
					...initDataBillingAddressValues,
				};
				const newMessage = `${name},${address_1},${address_2},${city} ${state} ${postal_code},${country}`;
				initDataBillingAddressCopy.comments = newMessage;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
			}, 1);
		}

		initDataValuesCopy = {
			...initDataValuesCopy,
			payment_date: new Date().toLocaleDateString("en-CA", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}),
		};

		setInitData({ ...initDataValuesCopy });
	}, []);

	useEffect(async () => {
		let fieldsAndValuesCopy = [...typesAndValues];

		const idFieldIndex = fieldsAndValuesCopy.findIndex(
			(item) => item.field_name === "customer_id",
		);
		let fieldId = fieldsAndValuesCopy[idFieldIndex];
		fieldId.inputValue = ``;
		fieldId.doRequest = false;

		if (
			location.hasOwnProperty("data") &&
			location?.data.hasOwnProperty("customerData") &&
			Object.keys(location.data.customerData).length > 0
		) {
			const { name } = location.data.customerData;
			fieldId.inputValue = `${name}`;
			fieldId.dontInitData = true;
		}

		if (typePayments?.length > 0) {
			fieldsAndValuesCopy[
				fieldsAndValuesCopy.findIndex(
					(item) => item.field_name === "type_payment_id",
				)
			].values = typePayments;
		}

		setTypesAndValuesFields([...fieldsAndValuesCopy]);
	}, [typePayments]);

	//redirect module
	const buttonClick = async (field_id, typeSave = "Add") => {
		if (field_id === "findByInvoice") {
			if (isLoading) return;
			setShow(true);
		}
		if (field_id === "Save") {
			onHandleSubmit(typeSave);
		}
	};

	const onDelete = (event) => {};

	const onEdit = () => {};

	const actionsDefinition = [
		{
			name: "Edit",
			func: onEdit,
		},
		{
			name: "Delete",
			func: onDelete,
		},
	];

	//adjustement detail implementation
	const onMenuActions = async (event, element) => {};

	const [show, setShow] = React.useState(false);
	const prepareDetailToUpdate = (index) => {
		const item = testData.findIndex(
			(itemData) => itemData.part_number == index,
		);
		setCurrentId(item);
		setShow(true);
	};

	const quitNewStylesInModal = () => {
		setActionsFooterStyle(false);
		setCancelText("");
		setSuccessText("");
	};

	const cancelInvoice = () => {
		if (changeInForm) {
			setShowModal(true);
			setCustomTittleText(`Leave payment creating`);
			setCustomMessageModal(`Do you want to leave without saving?`);
			setShowSuccessButton(true);
		} else {
			history.push("/invoice");
		}
	};

	const onHandleGetInvoice = async (ev) => {
		const initDataBillingAddressCopy = { ...initDataBillingAddressValues };
		let fieldsAndValuesCopy = [...typesAndValuesFields];
		let initDataValuesCopy = { ...initData };
		const { invoice_no } = ev;
		if (invoice_no) {
			let currentInvoice = await getItemByUrl("invoices", invoice_no, {});
			console.log("currentInvoice", currentInvoice);
			if (currentInvoice) {
				setShow(false);

				const {
					name,
					address_1,
					address_2,
					city,
					state,
					postal_code,
					country,
					id,
				} = currentInvoice.client;

				const idFieldIndex = fieldsAndValuesCopy.findIndex(
					(item) => item.field_name === "customer_id",
				);
				let fieldId = fieldsAndValuesCopy[idFieldIndex];
				fieldId.inputValue = ``;
				fieldId.doRequest = false;
				fieldId.dontInitData = true;
				fieldId.inputValue = `${name}`;

				initDataValuesCopy = {
					...initData,
					customer_id: id || "",
					payment_date: new Date().toLocaleDateString("en-CA", {
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
					}),
				};

				const newMessage = `${name},${address_1},${address_2},${city} ${state} ${postal_code},${country}`;
				initDataBillingAddressCopy.comments = newMessage;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
				setTypesAndValuesFields([]);
				setTypesAndValuesFields([...fieldsAndValuesCopy]);
				setInitData({ ...initDataValuesCopy });

				//get invoices to pay
				getInvoiceToPay(id);
			}
		}
	};

	const calculateIfChecked = (item, checked) => {
		const value1 =
			typeof item.total_payments === "string" ||
			item.total_payments instanceof String
				? calculate(item.total_payments)
				: Number(item.total_payments).toFixed(2);

		const value2 = calculate(item.Balance.split(" ")[1]);

		return checked ? value2 : value1;
	};

	const getInvoiceToPay = async (
		clientId,
		onlyNotPaid = 1,
		takeDefaultInvoice = true,
	) => {
		let params = {
			client_id: clientId,
			only_not_paid: onlyNotPaid,
		};

		let invoices = await getListItem("invoices", "Invoices", params);

		let fromInvoiceId = null;

		if (takeDefaultInvoice) {
			if (
				location.hasOwnProperty("data") &&
				location?.data.hasOwnProperty("invoice_id")
			) {
				fromInvoiceId = parseInt(location?.data.invoice_id);
				setInvoiveId(fromInvoiceId);

			}
		}

		let invoicesMap = invoices.map((item) => ({
			...item,
			checked: fromInvoiceId && item.id === fromInvoiceId ? true : false,
			message:
				item.message && item.message.length > 35
					? item.message.slice(0, 36) + "..."
					: item.message,
			total_payments: calculateIfChecked(
				item,
				fromInvoiceId && item.id === fromInvoiceId,
			),
		}));

		setTestData([]);
		setTestData([...invoicesMap]);
	};

	const mapProperty = {
		part_number: "checked",
		total_payments: "total_payments",
	};

	const updatePayments = (value, rowInfo, elementInfo) => {
		let regex = /^[0-9]*\.?[0-9]*$/;

		if (rowInfo.field_name === "total_payments" && !regex.test(value)) {
			return;
		}

		const invoicesCopy = [...testData];
		const elementIndex = invoicesCopy.findIndex(
			(item) => item.id === elementInfo.id,
		);
		invoicesCopy[elementIndex][mapProperty[rowInfo.field_name]] = value;

		if (rowInfo.field_name === "part_number") {
			invoicesCopy[elementIndex]["total_payments"] = calculateIfChecked(
				elementInfo,
				value,
			);
		}

		setTestData([...invoicesCopy]);
	};

	useEffect(() => {
		let result = testData
			.filter((item) => item.checked)
			.map((item) =>
				parseFloat(item.total_payments)
					? parseFloat(item.total_payments).toFixed(2)
					: Number(0).toFixed(2),
			)
			.reduce((prev, curr) => Number(prev) + Number(curr), 0);
		setAmountToApply(result.toFixed(2) || Number(0).toFixed(2));
	}, [testData]);

	const handlerSiblings = (prop = {}) => {
		switch (prop.panel) {
			case PanelNames.PANEL_SEARCH_CUSTOMER:
				setShow(true);
				break;
			default:
				break;
		}
	}

	return (
		<>
			<div
				className="contenedor container-fluid"
				style={{ position: "relative" }}
			>
				<div className="container-title">
					<h1 className="container__title">
						{props.actionTittle + " " + props.model}
					</h1>
					<div
						className="action-buttons-container"
						style={{ gridTemplateColumns: "120px 1fr 1fr 1fr" }}
					>
						<div className="save-buttton">
							<CustomButton
								style={{ paddingInline: '1rem', fontSize: '13px' }}
								buttonName={"Save and new"}
								buttonOnClick={() => buttonClick("Save", "save")}
								type={"success"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
							/>
						</div>
						<div></div>
						<div></div>
						<div className="cancel-button">
							<CustomButton
								style={{ paddingInline: '1rem', fontSize: '13px' }}
								buttonName={"Cancel"}
								buttonOnClick={cancelInvoice}
								type={"secondary"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
							/>
						</div>
					</div>
				</div>
				<div className="row mt-1">
					{typesAndValuesFields.length > 0 &&
						Object.keys(initData).length > 0 && (
							<>
								<div className="col-12">
									<CustomForm
										onHandleFormClose={() => {}}
										onHandleSubmit={onHandleSubmit}
										typesAndValuesFields={typesAndValuesFields}
										initData={initData}
										formTittle={""}
										dontShowCancelButton={true}
										id={null}
										getUrlPath={""}
										inputConf={{
											marginTop: "1",
											marginBottom: "0",
											stringCol:"col-12 col-md-4"
										}}
										buttonClick={buttonClick}
										onChangeFields={onChangeFieldsInitData}
										initOnChangeFields={onChangeFieldsInitData}
										dontShowSubmitButton={true}
										childFunc={childFunc}
										dontShowErrosinOnChange={true}
										getObjectInfoOnChange={onChangeSearchInputCustomer}
										notifyChange={() => setChangeInForm(true)}
										handlerSiblings={handlerSiblings}
									/>
								</div>
							</>
						)}
				</div>
				<div className="row justify-content-between">
					<div className="col-12 col-md-4">
						<label>Billing Address</label>
						<address
							className="address-container border-lightgray"
							style={{
								height:
									Object.keys(initDataBillingAddress).length > 0 
									&& initDataBillingAddress.comments.length > 0 ? "auto" : "80px",
							}}
						>
							{Object.keys(initDataBillingAddress).length > 0 &&
								initDataBillingAddress.comments.length > 0 &&
								initDataBillingAddress.comments
									.split(",")
									.map((comment, key) => (
										<React.Fragment key={key}>
											{comment}
											<br />
										</React.Fragment>
									))}
						</address>
					</div>
					<div className="col-12 col-md-4">
						<div className="balance-table">
							<label className="balance-table-balance-due">
								Amount Received
							</label>
							<div className="balance-table-balance-total">
								<span>$ {amountToApply}</span>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<CustomForm
							onHandleFormClose={() => {}}
							onHandleSubmit={onHandleSubmit}
							typesAndValuesFields={typesAndValuesPartNumber}
							initData={initDataPartNumber}
							formTittle={""}
							dontShowCancelButton={true}
							id={null}
							getUrlPath={""}
							inputConf={{
								marginTop: "1",
								marginBottom: "0",
							}}
							buttonClick={buttonClick}
							onChangeFields={onChangeFieldsInitDataPartNumber}
							initOnChangeFields={onChangeFieldsInitDataPartNumber}
							dontShowSubmitButton={true}
							dontShowErrosinOnChange={true}
							childFunc={childFunc3}
							getObjectInfoOnChange={() => {}}
							notifyChange={() => setChangeInForm(true)}
						/>
					</div>
				</div>

				{noSelectedInvoiceMessage && (
					<div className="row" style={{ padding: "0 0 0 10px" }}>
						<span style={{ gridColumn: "2" }} className="text-danger float-end">
							You must select an invoice to make the payment
						</span>
					</div>
				)}

				<CustomDataList
					onChangePageEvent={() => {}}
					style={styles}
					isLoading={isLoading}
					dataList={testData}
					tableHeaders={tableHeaders}
					itemPagination={null}
					handlePagination={() => {}}
					dontShowPerPageSelect={true}
					showLinearIndicator={true}
					showDataInLoading={true}
					render={(element, index) => (
						<CustomDataListItem
							key={index}
							dataFields={dataFields}
							imageFields={[]}
							composedFields={[]}
							arrayFields={[]}
							element={element}
							index={index}
							no_image={no_image}
							dontShowActions={true}
							showActionsButtons={false}
							deleteRowTable={() => {}}
							renderActions={() => (
								<>
									<PositionedMenu
										title={"Actions"}
										anchorEl={anchorEl}
										setAnchorEl={setAnchorEl}
										handleClose={onCloseModal}
										handleClick={(event) => onMenuActions(event, element)}
										actionsDefinition={actionsDefinition}
										renderActions={(action, index) => (
											<MenuItem sx={{boxShadow: "none"}} key={index} onClick={action.func}>
												<div className="items-option" style={{ padding: "0 5px", color: "grey" }}>
													<FontAwesomeIcon style={{ margin: "4px", marginRight: "10px", fontSize: "12px" }} icon={faTrash}/>
													<p>{action.name}</p>
												</div>
											</MenuItem>
										)}
									></PositionedMenu>
								</>
							)}
							renderComponentInField={(
								data,
								rowInfo,
								elementInfo,
							) => {
								if (
									rowInfo &&
									rowInfo.hasOwnProperty("renderType") &&
									rowInfo.renderType === "input"
								) {
									return (
										<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
											<input
												style={{gridColumn: "1 / 4" }}
												className="form-control form-control-sm custom-input-payment input-type-number"
												name={"nameTest"}
												value={elementInfo.total_payments}
												onChange={(e) => updatePayments(e.target.value, rowInfo, elementInfo)}
												type={"text"}
												placeholder={""}
												disabled={false}
												onKeyDown={blockInvalidChar}
											/>
										</div>
									);
								}
								if (rowInfo && rowInfo.hasOwnProperty("renderType") && rowInfo.renderType === "checkBox") {
									return (
										<label className="container-checkbox">
											<input
												type="checkbox"
												defaultChecked={elementInfo.checked}
												onChange={() => updatePayments(!elementInfo.checked, rowInfo, elementInfo)}
											/>
											<span className="checkmark"></span>
										</label>
									);
								}
								return (
									<MaterialUI.Button.Primary className="mw-auto px-2 me-1" onClick={() => prepareDetailToUpdate(data)}>
										<i className="fas fa-edit fs-small"></i>
									</MaterialUI.Button.Primary>
								);
							}}
						/>
					)}
				/>

				{typesAndValuesFields.length > 0 &&
					Object.keys(initData).length > 0 && (
						<div className="row mb-3">
							<div className="col-12 col-md-3">
								<CustomForm
									onHandleFormClose={() => {}}
									onHandleSubmit={onHandleSubmit}
									typesAndValuesFields={typesAndValuesMessageOnInvoice}
									initData={initDataMessageOnInvoice}
									formTittle={""}
									dontShowCancelButton={true}
									id={null}
									getUrlPath={""}
									inputConf={{
										marginTop: "1",
										marginBottom: "0",
										stringCol: "col-12 col-sm-11 col-md-12",
									}}
									buttonClick={buttonClick}
									onChangeFields={onChangeFieldsInitDataMessageOnInvoice}
									initOnChangeFields={onChangeFieldsInitDataMessageOnInvoice}
									dontShowSubmitButton={true}
									childFunc={childFunc2}
									dontShowErrosinOnChange={true}
									notifyChange={() => setChangeInForm(true)}
								/>
							</div>
							<div className="col-12 col-md-6">
								<div className='mx-0 mx-md-1 w-100'>
									<div className="content-notes"><label className="form-label mb-0">Files</label></div>
									<Box sx={{ overflow: "auto" }} className="p-1 border scroll_customized rounded-1">
										<CustomImageViewer
											itemData={documentsImageArray}
											onClickImage={() => {}}
											onDeleteImage={onDeleteImage}
											showDefaultPdfIcon={true}
											showDowloadAction={true}
										>
											<div 
												className="user-select-none p-3 rounded-2 d-flex flex-column align-items-center justify-content-center"
												style={{ width: '95px', height: 'auto', border: '2px dashed #3f51b5', backgroundColor: 'rgb(63 81 181 / 15%)', color: '#3f51b5', cursor: 'pointer' }}
												onClick={() => openFileImage()}
											>
												<i className="fas fa-plus mb-2 fs-small"></i>
												<p className="mb-0" style={{ fontSize: '12px', lineHeight: '1', textAlign: 'center' }}>Attach documents</p>
												<input
													hidden
													id="file_input_doc_input"
													type="file"
													onChange={(e) => setFileImage(e)}
													accept="image/*,.pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx"
													multiple
												/>
											</div>
										</CustomImageViewer>
									</Box>
								</div>
							</div>
							<div className="col-12 col-md-3">
								<div style={{ display: "grid", justifyContent: "flex-end" }}>
									<div className="d-flex justify-content-end gap-3">
										<div className="letter amount-table-subtotal amount-subtotal-payment">Amount to Apply</div>
										<div className="letter amount-table-item amount-table-subtotal-value">$ {amountToApply}</div>
									</div>
									<div style={{ background: "" }} className="">
										.
									</div>
								</div>
							</div>
						</div>
					)}
			</div>

			{/* fileModal */}
			<CustomModal
				show={files_showModal}
				customTittleText={files_customTittleText}
				customMessageModal={files_customMessageModal}
				onHandleClose={files_onHandleResponseModal}
				onHandleSuccess={() => onHandleDeleteFile()}
				isLoading={isLoading}
				showSuccessButton={files_showSuccessButton}
				actionsFooterStyle={actionsFooterStyle}
				cancelText={cancelText}
				successText={successText}
			/>

			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={onHandleResponseModal}
				onHandleSuccess={onHandleResponseModalSuccess}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
				actionsFooterStyle={true}
				cancelText={"no"}
				successText={"yes"}
			/>

			{/* modal form */}
			<CustomModal
				show={show}
				size="sm"
				customTittleText={""}
				customMessageModal={""}
				onHandleClose={() => setShow(false)}
				onHandleSuccess={() => {}}
				isLoading={false}
				showSuccessButton={true}
			>
				<CustomForm
					onHandleFormClose={() => setShow(false)}
					location_list={[]}
					onHandleSubmit={onHandleGetInvoice}
					typesAndValuesFields={typesAndValuesFindInvoice}
					initData={initDataFindInvoice}
					inputConf="col-12"
					successStyleButtonText={<><i className="fas fa-search me-1"></i> Search</>}
					formTittle={""}
				/>
			</CustomModal>
		</>
	);
}

export { PaymentForm };
