function LaravelErrorsToObject(payload) {
	let errors = {};
	for (let key in payload) {
		if (payload.hasOwnProperty(key) && Array.isArray(payload[key])) {
			errors[key] = payload[key][0];
		}
	}
	return errors;
}

export { LaravelErrorsToObject };
