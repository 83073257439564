import React, { createContext, useState, useEffect, useMemo } from "react";
import Swal from "sweetalert2";

import { useApiRequestTypePayment } from "../customHooks/useApiRequestTypePayment";
import Http from '../lib/http'
import SwalAlerts from '../utils/alerts'

import { useProducts } from '../customHooks/useProducts';
import { useReceipts, Adapter as AdapterReceipts } from '../customHooks/useReceipts';
import { useApiRequestCategories } from "../customHooks/useApiRequestCategories";

import BeepCashRegister01 from '../assets/media/beep-cash-register-01.mp3'

export class InitState {
  static defaultPaymentId = "";
  static creditId = 1;
  static cashId = 3;

  static get holdOrder() {
    return {};
  }
  
  static formPayment() {
    return {
      id: crypto.randomUUID(),
      type_payment_id: this.defaultPaymentId,
      total_payment: "",
      ref: React.createRef(),
    };
  }
}

export const PosDesktopContext = createContext();
export const MIN_PER_PAGE = 21;
export const MAX_PER_PAGE = 28;

export function PosDesktopContextProvider({ children }) {
  const {
    isLoading: loadingProducts,
    fetchProductBySKU,
    fetchProducts,
    pagination,
    products,
    setPage,
    page,
  } = useProducts();
  const {
    // 👉 Hold Receipts
    fetchHoldReceipts,
    storeReceipt,
    incrementHoldReceiptsTotal,
    setHoldReceiptsPagination,
    destroyReceipt,
    setHoldReceiptErrors,
    holdReceipts,
    holdReceiptsPagination,
    loadingHoldReceipts,
    loadingReceipt,
    holdReceiptErrors,
    errors,
    setErrors,
    refundItems,
    // 👉 Receipts
    fetchReceipts,
    loadingReceipts,
    receiptsPagination,
    receipts,
  } = useReceipts();
	const {
    filtered_data: payments,
    setFilteredData: setPayments,
    fetchTypesPayment
  } = useApiRequestTypePayment(() => {}, false);
  const [cart, setCart] = useState([]);
  const [receiptId, setReceiptId] = useState(undefined);
  const [initStateClients, setInitStateClients] = useState([]);
  const [note, setNote] = useState("");
  const [productsId, setProductsId] = useState(new Set());
  const [searchType, setSearchType] = useState(() => localStorage.settingsPOSSearchType || 'NAME');
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(() => localStorage.settingsPOSPerPage || MIN_PER_PAGE);
  const [_search, _setSearch] = useState("");
  const [client, setClient] = useState(null);
  const [billNumber, setBillNumber] = useState("");
  const [sendingForm, setSendingForm] = useState(false);
  const [showVoucherOptions, setShowVoucherOptions] = useState(false);
  const [showPanelHoldOrders, setShowPanelHoldOrders] = useState(false);
  const [holdOrders, setHoldOrders] = useState([]);
  const [holdOrder, setHoldOrder] = useState(InitState.holdOrder);
  const toggleShowHoldOrders = () => setShowPanelHoldOrders((state) => !state);
  const [showFormPayment, setShowFormPayment] = useState(false);
  const [dynamicForm, setDynamicForm] = useState([InitState.formPayment()]);
  const [isReceiptRegister, setIsReceiptRegister] = useState({});
  // 👉 Receipts
  const [currentReceipt, setCurrentReceipt] = useState({});
  const [showPanelReceipts, setShowPanelReceipts] = useState(false);
  // 👉 Categories
  const {filtered_data: categories} = useApiRequestCategories(() => {});
  const [categoryFilter, setCategoryFilter] = useState();  
  // 👉 Refound
  const [showRefoundForm, setShowRefoundForm] = useState(false);
  const [currentRefound, setCurrentRefound] = useState({});
  const [loadingRefoundForm, setLoadingRefoundForm] = useState(false);
  const [restockItems, setRestockItems] = useState(false);
  const [refundReason, setRefundReason] = useState("");
  const [refoundForm, setRefoundForm] = useState([]);

  const handleSetRefoundFormClick = (details) => {
    setRefoundForm(details.map((item) => ({
      ...item.item,
      quantity: item.quantity,
      refoundQuantity: 0,
      subTotal: item.quantity * item.price,
    })));
  }
  const addQuantityToTheProductInTheRefoundForm = (productId, _quantity) => {
    const index = refoundForm.findIndex(item => item.id === productId);
    const refoundQuantity = Number(refoundForm[index]['refoundQuantity']) + _quantity;
    if(refoundQuantity > Number(refoundForm[index]['quantity'])) return;
    if(refoundQuantity >= 0) {
      setRefoundForm((prev) => {
        return prev.map((item) => {
          if(item.id === productId)
            return {
              ...item,
              refoundQuantity
            };
          return item;
        });
      });
    }
  }
  const cleanFormRefound = () => {
    setCurrentRefound({});
    setRestockItems(false);
    setRefundReason("");
    setRefoundForm([]);
  }
  const handleRefundItemsClick = async () => {
    try {
      setLoadingRefoundForm(true);
      const items = refoundForm.filter((c) => c.refoundQuantity).map((c) => ({
        item_id: c.id,
        quantity: c.refoundQuantity,
        refund_quantity: restockItems ? c.refoundQuantity : 0,
        cost: c.price,
        note: refundReason || 'Refund',
      }));
      return await refundItems({ items, receipt_id: currentRefound.id });
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      setLoadingRefoundForm(false);
    }
  }
  
  // 👉 Others
  useEffect(() => {
    if(payments.length) {
      const cash = payments.find((c) => String(c.name).toLowerCase() === 'cash');
      if(cash) {
        InitState.defaultPaymentId = cash.id;
        setDynamicForm([InitState.formPayment()]);
      }
    }
  }, [payments])
  
	const [audio] = useState(() => {
    const _audio = new Audio(BeepCashRegister01);
    _audio.volume = 0.05;
    return _audio;
  });

  const addQuantityToTheProductInTheShoppingCart = (productId, _quantity) => {
    const index = cart.findIndex(item => item.id === productId);
    const quantity = Number(cart[index]['quantity']) + _quantity;
    if(quantity <= 0) {
      removeFromCart(productId);
    } else {
      setCart((prev) => {
        return prev.map((item) => {
          if(item.id === productId)
            return {
              ...item,
              quantity,
              subTotal: item.price * quantity,
            };
          return item;
        });
      });
    }
  }

  const removeFromCart = (productId) => {
    setCart((prev) => {
      setProductsId((prev) => {
        prev.delete(productId);
        return prev;
      });
      return prev.filter((item) => item.id !== productId);
    })
  }

  const removeFromHoldOrders = async (receiptId) => {
    const { isConfirmed } = await Swal.fire(SwalAlerts.questionDestroy);
    if(!isConfirmed) return;
    try {
      await destroyReceipt(receiptId);
      setHoldOrder(InitState.holdOrder);
      fetchHoldReceipts();
      Swal.fire(SwalAlerts.confirmationDestroy);
    } catch (error) {}
  }
  
  const setCartFromHoldOrder = (id) => {
    const item = holdReceipts.find((c) => c.id === id);
    if(item) {
      setReceiptId(item.id);
      setHoldOrder(InitState.holdOrder);
      setCart(item.details.map(AdapterReceipts.holdOrderDetailsToCart));
      if(item.client) {
        setInitStateClients([item.client]);
        setClient(item.client);
      }
      toggleShowHoldOrders();
    }
  }
  
  const addToCart = (product) => {
    audio.currentTime = 0;
    audio.play();
    if(productsId.has(product.id)) {
      addQuantityToTheProductInTheShoppingCart(product.id, 1);
    } else {
      setProductsId((prev) => { prev.add(product.id); return prev; });
      const newItem = { ...product, quantity: 1, subTotal: product.price }
      setCart((prev) => [newItem, ...prev]);
    }
  }

  const clearCart = () => {
    setProductsId((prev) => {
      prev.clear();
      return prev;
    })
    setCart([]);
    setReceiptId(undefined);
  }

  const togglePerPage = () => {
    const newPerPage = Number(perPage) === MIN_PER_PAGE ? MAX_PER_PAGE : MIN_PER_PAGE;
    setPerPage(newPerPage);
    localStorage.settingsPOSPerPage = newPerPage;
  }

  const resetState = () => {
    clearCart();
    setIsReceiptRegister(false);
    setClient(null);
    setBillNumber("");
    setNote("");
    setDynamicForm({});
    setCurrentReceipt({});
    setHoldReceiptErrors({});
  }
  
  const handleStoreReceiptClick = async (is_on_hold = false, _note = note) => {
    if(loadingReceipt) return;
    try {
      const payload = {
        client_id: (client ?? {}).id,
        items: cart.map((c) => ({
          item_id: c.id,
          quantity: c.quantity,
          cost: c.price,
        })),
        payments_arr: dynamicForm.map((c) => ({
          type_payment_id: c.type_payment_id,
          total_payment: c.total_payment,
        })),
        note: _note,
      }
      if(is_on_hold) {
        delete payload.payments_arr;
        delete payload.note;
        payload.is_on_hold = 1;
        payload.reference_name = _note;
      }
      if(receiptId) payload.receipt_id = receiptId;
      const { status, data = {} } = await storeReceipt(payload);
      if(status && String(data?.status).toLowerCase() !== 'hold') {
        setCurrentReceipt(data);
        setIsReceiptRegister(true);
        setBillNumber(data.bill_number);
        setShowVoucherOptions(true);
        setShowFormPayment(false);
      }
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  const findReceipt = async (search) => Http.post(`/api/search_by_BLT`, { search });
  
  const processSale = () => {
    setShowFormPayment(true);
    setShowVoucherOptions(true);
    setHoldReceiptErrors({});
  }

  const closeProcessSale = () => {
    setIsReceiptRegister(false);
    setShowFormPayment(false);
    setShowVoucherOptions(false);
  }
  
  useEffect(() => {
    const fetchAllProducts = async () => {
      try {
        if(searchType === 'SKU' && search) {
          const params = { search_text: search }
          const { data } = await fetchProductBySKU({ params });
          const [resp] = data;
          if(
            resp && Object.keys(resp).length &&
            searchType === 'SKU' && /\S/.test(search) &&
            String(resp.sku).toLowerCase() === String(search).toLowerCase()
          ) {
            addToCart(resp)
            setTimeout(() => { _setSearch("") }, 150);
          }
        } else {
          const params = { per_page: perPage, search_text: search, category_id: categoryFilter?.id ?? '' };
          const { _pagination } = await fetchProducts({ params });
          if(_pagination.current_page > _pagination.total_pages) {
            setPage(1);
          }
        } 
      } catch (error) {
        console.error(error);
      }
    }
    fetchAllProducts();
  }, [perPage, page, search, searchType, categoryFilter])
  
  useEffect(() => {
    if(searchType !== localStorage.settingsPOSSearchType) {
      localStorage.settingsPOSSearchType = searchType;
    }
  }, [searchType]);
  
  const totalQuantity = useMemo(() => cart.reduce((acc, item) => acc + Number(item.quantity), 0), [cart]);
  const totalAmount = useMemo(() => cart.reduce((acc, item) => acc + item.subTotal, 0), [cart]);

  const share = {
    togglePerPage,
    addToCart,
    setSearch,
    addQuantityToTheProductInTheShoppingCart,
    removeFromCart,
    resetState,
    setPage,
    setPerPage,
    setSearchType,
    _setSearch,
    setClient,
    clearCart,
    setShowVoucherOptions,
    handleStoreReceiptClick,
    toggleShowHoldOrders,
    showPanelHoldOrders,
    totalQuantity,
    totalAmount,
    search, 
    loadingProducts,
    products,
    pagination,
    productsId,
    cart,
    searchType,
    _search,
    client,
    perPage,
    showVoucherOptions,
    billNumber,
    // 👉 Hold Orders
    holdOrder, setHoldOrder,
    holdOrders, setHoldOrders,
    showFormPayment, setShowFormPayment,
    removeFromHoldOrders,
    setCartFromHoldOrder,
    processSale,
    // 👉 Form Payment
    payments, setPayments,
    sendingForm, setSendingForm,
    note, setNote,
    errors, setErrors,
    dynamicForm, setDynamicForm,
    currentReceipt, setCurrentReceipt,
    fetchTypesPayment,
    closeProcessSale,
    findReceipt,
    // 👉 Hold Receipts
    fetchHoldReceipts,
    incrementHoldReceiptsTotal,
    setHoldReceiptsPagination,
    holdReceipts,
    loadingHoldReceipts,
    holdReceiptsPagination,
    isReceiptRegister,
    setIsReceiptRegister,
    holdReceiptErrors,
    setHoldReceiptErrors,
    receiptId,
    // 👉 Client
    initStateClients,
    setInitStateClients,
    // 👉 Refound
    refoundForm,
    setRefoundForm,
    addQuantityToTheProductInTheRefoundForm,
    handleSetRefoundFormClick,
    showRefoundForm,
    setShowRefoundForm,
    restockItems,
    setRestockItems,
    refundReason,
    setRefundReason,
    cleanFormRefound,
    loadingRefoundForm,
    setLoadingRefoundForm,
    handleRefundItemsClick,
    currentRefound,
    setCurrentRefound,
    showPanelReceipts,
    setShowPanelReceipts,
    // 👉 Categories
    categories,
    categoryFilter,
    setCategoryFilter,
    // 👉 Receipts
    fetchReceipts,
    loadingReceipts,
    receiptsPagination,
    receipts,
  };
  
	return (
		<PosDesktopContext.Provider value={share}>
			{children}
		</PosDesktopContext.Provider>
	);
}
