import React, { StrictMode } from "react";
//import "../App.css";
import imglogo from "../../../assets/img/logo_t4i.png";
import icon_mail from "../../../assets/icons/email.svg";
import ReactDOM from "react-dom";
import App from "../../../components/App";
// import "../login.css";
import { useHistory } from "react-router-dom";

function Email_been_send() {
	const history = useHistory();

	return (
		<div className="login">
			<div className="form-container">
				<img src={imglogo} alt="logo" className="logo" />

				<h1 className="title">Email has been send!</h1>
				<p className="subtitle">
					Please check your inbox for instructions on how to reset the
					password
				</p>
				<div className="email-image">
					<img src={icon_mail} alt="email" />
				</div>
				<button
					className="primary-button login-button"
					onClick={() => history.push("/")}
				>
					Login
				</button>
				<p className="reset">
					<span>Didn't receive the email?</span>
					<a href="#"> Resend</a>
				</p>
			</div>
		</div>
	);
}

export { Email_been_send };
