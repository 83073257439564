import { blockInvalidChar } from "../../context/constants";

export const typesAndValues = [
	{
		field_name: "sku",
		type_field: "text_input",
		text_label: "Code",
		disabled: false,
		regexPattern: /^([a-zA-Z0-9 _-]+)$/,
		maxLength: 255,
		// minLength: 3,
		col: "col-6",
		required: true,
		inputConf: {
			className: 'col-12 col-md-6'
		},
	},
	{
		field_name: "name",
		type_field: "text_input",
		text_label: "Name",
		disabled: false,
		regexPattern: /^([a-zA-Z0-9 _-]+)$/,
		maxLength: 255,
		// minLength: 3,
		col: "col-6",
		required: true,
		inputConf: {
			className: 'col-12 col-md-6'
		},
	},
	{
		field_name: "uom_id",
		type_field: "select_input",
		text_label: "Uom",
		placeHolder: "select and uom",
		dontDefaultOption: false,
		regexPattern: /^[a-z0-9]+$/i,
		values: [],
		selectCol: "col-12",
		selectWidth: "100%",
		// minLength: 1,
		col: "col-4",
		required: true,
		inputConf: {
			className: 'col-12'
		},
	},
	{
		field_name: "cost",
		type_field: "text_input",
		text_label: "Labor Rate",
		disabled: false,
		regexPattern: /^[0-9]*\.?[0-9]*$/,
		maxLength: 10,
		minLength: 1,
		col: "col-4",
		required: true,
		type: "number",
		onKeyDown: blockInvalidChar,
		inputConf: {
			className: 'col-12 col-md-6'
		},
	},
	{
		field_name: "price",
		type_field: "text_input",
		text_label: "Labor Estimate",
		disabled: false,
		regexPattern: /^[0-9]*\.?[0-9]*$/,
		maxLength: 10,
		minLength: 1,
		col: "col-4",
		required: true,
		type: "number",
		onKeyDown: blockInvalidChar,
		inputConf: {
			className: 'col-12 col-md-6'
		},
	},
];

export const initData = {
	sku: "",
	name: "",
	uom_id: "",
	cost: "",
	price: "",
};
