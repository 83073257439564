import createHttp from "../lib/http";

const TwilioService = {};

TwilioService.getTwilioSetting = async () => {
  const { data } = await createHttp.get("/api/get_twilio_configuration");
  return data;
}

TwilioService.updateTwilioSetting = async (payload) => {
  const { data } = await createHttp.post("/api/save_twilio_configuration", payload);
  return data;
}

export default TwilioService;