import { useCallback, useState } from 'react';
import PaymentAccountService from '../services/PaymentAccountService';

class Adapter {
  static response(data) {
    return data;
  }
}

const initialState = {
  id: '',
  account: '',
}

const usePaymentAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [paymentAccount, setPaymentAccount] = useState({ ...initialState });
  
  const store = useCallback(async(payload) => {
    try {
      setErrors({})
      setIsLoading(true);
      const resp = await PaymentAccountService.store(payload);
      if(resp.status) {
        setPaymentAccount(() => Adapter.response(resp.data));
        return resp.data;
      } else {
        setErrors({ name: `The name "${payload.name}" is already in use.` });
        throw new Error("Error store payment account.");
      }
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    paymentAccount,
    errors,

    store,
    setErrors,
    setPaymentAccount,
  }
}

export default usePaymentAccount;