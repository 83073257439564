import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import AddAPhotoRoundedIcon from '@material-ui/icons/AddAPhotoRounded';

import img from "../../assets/img/upload_image.jpg";
import "./companies.css";

// components
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomModal } from "../../components/CustomModal/CustomModal";

//custom hooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";
import { useAuthentication } from "../../customHooks/useAuthentication";

import { validateLength, setValueInArray } from "../../context/constants";

// form configuration
import {
	typesAndValues as typesAndValuesFields,
	initData as initDataFiels,
	typesAndValuesRowTwo as typesAndValuesRowTwoFields,
	initDataRowTwo as initDataRowTwoFields,
	typesAndValuesMessagePackage as typesAndValuesMessagePackageFields,
	initDataMessagePackage as initDataMessagePackageFields,
} from "./initForm";
import MaterialUI from "../../components/MaterialUI";

function CreateCompanyForm() {
	const history = useHistory();

	const {
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
		showSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		cancelButtonText,
		sucessButtonText,
	} = useModalHook();

	const { isLoading, getListItem, createItem } = useApiCustomRequest(
		manageResponseErrors,
		"companies",
		"Company",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		() => {},
	);

	const { userInfo } = useAuthentication();

	const [itemImageToAdd, setItemImageToAdd] = useState(null);
	const [preview_image, setImagePreview] = useState(img);
	const [typesAndValues, setTypesAndValues] = useState([]);
	const [typesAndValuesRowTwo, setTypesAndValuesRowTwo] = useState([]);

	const [initData, setInitData] = useState({});
	const [initDataRowTwo, setInitDataRowTwoFields] = useState({});

	useEffect(async () => {
		let typesAndValuesFieldsCopy = [...typesAndValuesFields];
		let typesAndValuesRowTwoCopy = [...typesAndValuesRowTwoFields];
		let initDataFielsCopy = { ...initDataFiels };
		let initDataRowTwoCopy = { ...initDataRowTwoFields };

		const currencies = await getListItem("currency", "Currencies", {});
		const timezones = await getListItem("timezones", "Timezones", {});

		const timezonesMap = timezones.map((timezone) => ({
			id: timezone,
			name: timezone,
		}));

		const curreciesMap = currencies.map((currency) => ({
			...currency,
			name: currency?.money_identificator + " " + `(${currency.name})`,
		}));

		if (curreciesMap.length > 0) {
			typesAndValuesFieldsCopy = setValueInArray(
				typesAndValuesFieldsCopy,
				"currency_id",
				"values",
				curreciesMap,
			);
		}
		if (timezonesMap.length > 0) {
			typesAndValuesFieldsCopy = setValueInArray(
				typesAndValuesFieldsCopy,
				"timezone",
				"values",
				timezonesMap,
			);
		}

		initDataFielsCopy = {
			...initDataFielsCopy,
			currency_id: curreciesMap.length > 0 ? curreciesMap[0]?.id : "",
			timezone: "America/Chicago",
		};

		setTypesAndValues(typesAndValuesFieldsCopy);
		setTypesAndValuesRowTwo(typesAndValuesRowTwoCopy);
		setInitData(initDataFielsCopy);
		setInitDataRowTwoFields(initDataRowTwoCopy);
	}, []);

	const buttonClick = () => {};

	const onChangeFieldsInitDataPartNumber = (ev) => {
		setInitData({ ...ev });
	};

	const onChangeFieldsInitDataRowTwo = (ev) => {
		setInitDataRowTwoFields({ ...ev });
	};

	const childFunc = useRef(null);
	const childFunc2 = useRef(null);
	const onHandleSubmit = async (typeSave) => {
		const errorsOne = childFunc.current(initData);
		const errorsTwo = childFunc2.current(initDataRowTwo);

		if (!validateLength(errorsOne) && !validateLength(errorsTwo)) {
			let { verified, ...extInitDataRowTwo } = initDataRowTwo;
			let baseRequest = {
				...initData,
				...extInitDataRowTwo,
				package_id: 1,
				user_id: userInfo?.id,
				package_type: "monthly",
				verified: verified ? 1 : 0,
			};

			if (itemImageToAdd) {
				baseRequest = {
					...baseRequest,
					logo: itemImageToAdd,
				};
			}

			let response = await createItem(baseRequest, "Company", {}, true);

			if (response && response.hasOwnProperty("id")) {
				history.push({
					pathname: "/company_list",
					search: ``,
				});
			}
		}
	};

	const setItemImageToUpdate = (event, index) => {
		let preview = URL.createObjectURL(event.target.files[0]);
		setItemImageToAdd(event.target.files[0]);
		console.log(preview);
		setImagePreview(preview);
	};

	const gotBack = () => history.push('/company_list');

	return (
		<>
			<div className="contenedor container-fluid">
				<MaterialUI.Button.Dark className="mb-2" onClick={gotBack}>
					<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
				</MaterialUI.Button.Dark>
				<div className="container-title mb-3">
					<h1 className="container__title mb-0">New Company</h1>
					<div className="action-buttons-container" style={{ padding: "6px" }}>
						<div className="save-buttton">
							<CustomButton
								buttonName={<><SaveOutlinedIcon fontSize="small" className="me-1" /> Save</>}
								buttonOnClick={onHandleSubmit}
								type={"success"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
							/>
						</div>
					</div>
				</div>
				<div className="row mt-1">
					<div className="col-12 col-md-9 mb-3">
						{typesAndValues.length > 0 &&
							Object.keys(initData).length > 0 && (
								<CustomForm
									onHandleFormClose={() => {}}
									onHandleSubmit={onHandleSubmit}
									typesAndValuesFields={typesAndValues}
									initData={initData}
									formTittle={""}
									dontShowCancelButton={true}
									id={null}
									getUrlPath={""}
									inputConf={{
										marginTop: "1",
										marginBottom: "0",
									}}
									buttonClick={buttonClick}
									onChangeFields={
										onChangeFieldsInitDataPartNumber
									}
									initOnChangeFields={
										onChangeFieldsInitDataPartNumber
									}
									dontShowSubmitButton={true}
									childFunc={childFunc}
									dontShowErrosinOnChange={true}
									getObjectInfoOnChange={() => {}}
									notifyChange={() => {}}
								/>
							)}
						<hr />
						<h6 className="sub__title mb-2">Location and verification</h6>
						{typesAndValuesRowTwo.length > 0 &&
							Object.keys(initDataRowTwo).length > 0 && (
								<CustomForm
									onHandleFormClose={() => {}}
									onHandleSubmit={onHandleSubmit}
									typesAndValuesFields={typesAndValuesRowTwo}
									initData={initDataRowTwo}
									formTittle={""}
									dontShowCancelButton={true}
									id={null}
									getUrlPath={""}
									inputConf={{
										marginTop: "1",
										marginBottom: "0",
									}}
									buttonClick={buttonClick}
									onChangeFields={
										onChangeFieldsInitDataRowTwo
									}
									initOnChangeFields={
										onChangeFieldsInitDataRowTwo
									}
									dontShowSubmitButton={true}
									dontShowErrosinOnChange={true}
									getObjectInfoOnChange={() => {}}
									notifyChange={() => {}}
									childFunc={childFunc2}
								/>
							)}
					</div>
					<div className="col-12 col-md-3">
						<div className="viewer-container form-group">
							<div className="viewer-title">Logo</div>
							<div className="preview__image__container">
								<img
									className="preview__image"
									style={{backgroundImage: `url('${preview_image}')`}}
								></img>
								<CustomButton
									buttonName={<AddAPhotoRoundedIcon fontSize="small" />}
									buttonOnClick={() => {
										document
											.getElementById(
												"viewer-image-element",
											)
											.click();
									}}
									type={"primary"}
									dontShowIcon
									disabled={false}
									className={"btn btn-sm"}
									style={{
										borderRadius: '50%',
										inlineSize: '40px',
										blockSize: '40px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										position: 'absolute',
										bottom: '-2rem',
										left: '0',
										right: '0',
										marginInline: 'auto'
									}}
								/>
							</div>
							<input
								hidden={false}
								onChange={setItemImageToUpdate}
								accept="image/png, image/gif, image/jpeg"
								type="file"
								id={"viewer-image-element"}
								name={"viewer-image-element"}
								style={{ display: "none" }}
							></input>
						</div>
					</div>
				</div>
				<div className="row d-flex flex-row justify-content-center mt-4 mt-md-0">
					<div className="col-auto">
						<p
							style={{
								paddingLeft: "9px",
								color: "#747373",
								fontWeight: "700",
								fontSize: "14px",
							}}
						>
							Admin will login using this password. (Leave blank
							to keep current password)
						</p>
					</div>
				</div>

				<CustomModal
					show={isLoading}
					customTittleText={""}
					customMessageModal={""}
					onHandleClose={() => {}}
					isLoading={isLoading}
					onHandleSuccess={() => {}}
				/>

				<CustomModal
					show={showModal}
					customTittleText={customTittleText}
					customMessageModal={customMessageModal}
					onHandleClose={() => setShowModal(false)}
					onHandleSuccess={() => {}}
					isLoading={isLoading}
					showSuccessButton={showSuccessButton}
					actionsFooterStyle={true}
					cancelText={cancelButtonText}
					successText={sucessButtonText}
				/>
			</div>
		</>
	);
}

export { CreateCompanyForm };
