const typesAndValuesFields = [
	{
		field_name: "name",
		type_field: "text_input",
		text_label: "Name",
		disabled: false,
		regexPattern: /^[A-Za-z0-9]*$/,
		maxLength: 25,
		minLength: 2,
	},
	{
		field_name: "email",
		type_field: "text_input",
		text_label: "Email",
		disabled: false,
		regexPattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		maxLength: 255,
	},
	{
		field_name: "phone_number",
		type_field: "text_input",
		text_label: "Phone Number",
		disabled: false,
		regexPattern: /^[0-9]*$/,
		maxLength: 25,
	},
	{
		field_name: "address",
		type_field: "text_input",
		text_label: "Address",
		disabled: false,
		regexPattern: /^$|^[#.0-9a-zA-Z\s,-]+$/,
		maxLength: 255,
	},
	{
		field_name: "city",
		type_field: "text_input",
		text_label: "City",
		disabled: false,
		regexPattern: /^[a-zA-Z\s]*$/,
		maxLength: 255,
	},
	{
		field_name: "state",
		type_field: "text_input",
		text_label: "State",
		disabled: false,
		regexPattern: /^[a-zA-Z\s]*$/,
		maxLength: 20,
	},
	{
		field_name: "zip_code",
		type_field: "text_input",
		text_label: "Zip Code",
		disabled: false,
		regexPattern: /^[0-9]*$/,
		maxLength: 5,
	},
];

const initData = {
	company_id: "",
	email: "",
	id: "",
	name: "",
	phone_number: "",
	address: "",
	city: "",
	state: "",
	zip_code: "",
};

export { typesAndValuesFields, initData };
