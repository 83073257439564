import { Modal, Button } from "react-bootstrap";
import { ActivityIndidicator } from "../../components/CircularProgres/CircularProgress";

//css
import "./CustomSideModal.css";
import { useEffect, useState } from "react";

function CustomSideModal(props) {
    const [active, setActive] = useState(false);

    useEffect(() => {
			if (props.show) {
				setActive(true);
			} else {
				setActive(false);
			}
    }, [props.show]);
	return (
		<div style={{ zIndex: "1040" }}>
			<Modal
				size={props.size ? props.size :""}
				show={props.show}
				onHide={props.onHandleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className={`modal-create-product-wrapper ${active ? 'active' : ''}`}
			>
				{!!props.customTittleText && (
					<Modal.Header>
						<Modal.Title as="h6" className="fw-bold">{props.customTittleText}</Modal.Title>
						<button className="btn-close fs-small" onClick={props.onHandleClose}></button>
					</Modal.Header>
				)}
				<Modal.Body>
					<div className="children_modal_wrapper">{!!props.children && props.children}</div>
					{(!!props.customMessageModal && !props.children) && <p>{props.customMessageModal}</p>}
					{(!!props.isLoading && !props.children) && <ActivityIndidicator />}
				</Modal.Body>
				<Modal.Footer className={props?.actionsFooterStyle && "action_styles"}>
					{(!props.isLoading && !props.children) && (
						<Button variant="secondary" onClick={props.onHandleClose}>
							{props.cancelText ? props.cancelText : "Close"}
						</Button>
					)}
					{!!props.showSuccessButton && !props.children && (
						<Button variant="success" onClick={props.onHandleSuccess}>
							{props.successText ? props.successText : "Accept"}
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export { CustomSideModal };
