import React from "react";
import imglogo from "../../assets/img/logo_t4i.png";
import createHttp from "../../lib/http";
import "./login.css";
import { DEMO_USER } from "../../utils/constants";

import { Loader } from "../../components/loader";
import { useHistory } from "react-router-dom";
import { useAuthentication } from "../../customHooks/useAuthentication";
// import { PermissionContext } from "../../context/PermissionContext";

function Login() {
  // const { permissions } = React.useContext(PermissionContext);
	const [login_data, setLoginData] = React.useState({
		email: "",
		password: "",
	});
	const [in_response, setInResponse] = React.useState(false);
	const [total_errors, setTotalErrors] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(false);

	const [isLoadingPage, setLoadingPage] = React.useState(true);
	let history = useHistory();

	const { setCurrentToken, deleteItemFromLocalStorage } = useAuthentication();

	deleteItemFromLocalStorage("item_validation_info");

	const gotoSignUp = () => {
		history.push("/sign_up");
	};

	const validateEmail = (email) => {
		let re =
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};

	const handleEmailChange = (event) => {
		let new_objet = login_data;
		new_objet.email = event.target.value;
		setLoginData(new_objet);
	};

	const handlePasswordChange = (event) => {
		let new_objet = login_data;
		new_objet.password = event.target.value;
		setLoginData(new_objet);
	};

	const addNewError = (error) => {
		setTotalErrors((total_errors) => [...total_errors, error]);
	};

	const haveErrors = (field) => {
		let have_errors = total_errors.find((item) => {
			if (item.name === field) return item;
		});

		if (have_errors !== undefined) {
			return true;
		}

		return false;
	};

	const returnErrorMsg = (field) => {
		let have_errors = total_errors.find((item) => {
			if (item.name === field) {
				return item;
			}
		});

		if (have_errors !== undefined) {
			return have_errors.message;
		}
	};

	const DemoLogin = (e) => {
		const headers = {
			"Content-Type": "application/json, application/x-www-form-urlencoded",
			Accept: "application/json",
			"X-CSRF-TOKEN": "jmYydI75VCrZfAQIDrrjb6074EwpPSs47Y4au8Yl7AE=",
		};

		let formData = new FormData();
		formData.append("email", DEMO_USER);
		formData.append("password", "@4114RPrp!!");

		setIsLoading(true);
		createHttp
			.post("api/login", formData, { headers: headers })
			.then((res) => {
				if (res.data.status) {
					localStorage.setItem("jwt_token", JSON.stringify(res.data.access_token));
					setCurrentToken(JSON.stringify(res.data.access_token));
					localStorage.setItem("user_data", JSON.stringify(res.data.user));
					loginAction(res.data.access_token);
				}
				if (!res.data.status) {
					addNewError({ name: "email", message: res.data.message });
					addNewError({ name: "password", message: res.data.message });
				}

				setInResponse(false);
				setIsLoading(false);
			})
			.catch((err) => {
				addNewError({ name: "email", message: "SERVER ERROR" });
				addNewError({ name: "password", message: "SERVER ERROR" });
				setInResponse(false);
				setIsLoading(false);
			});
	};

	const sendLogin = () => {
		if (in_response) return;

		setInResponse(true);
		setTotalErrors([]);

		let errors = 0;

		if (!validateEmail(login_data.email)) {
			addNewError({ name: "email", message: "Enter a valid email" });
			errors++;
		}

		if (login_data.password.trim().length < 6) {
			addNewError({ name: "password", message: "Password cannot be lower than 6 characters" });
			errors++;
		}

		if (errors > 0) {
			setInResponse(false);
			return;
		}

		let formData = new FormData();
		formData.append("email", login_data.email);
		formData.append("password", login_data.password);

		setIsLoading(true);
		createHttp
			.post("api/login", formData)
			.then((res) => {
				if (res.data.status) {
					localStorage.setItem("jwt_token", JSON.stringify(res.data.access_token));
					localStorage.setItem("user_data", JSON.stringify(res.data.user));
					setCurrentToken(JSON.stringify(res.data.access_token));
					loginAction(res.data.access_token);
				}
				if (!res.data.status) {
					addNewError({ name: "email", message: res.data.message });
					addNewError({
						name: "password",
						message: res.data.message,
					});
				}

				setInResponse(false);
				setIsLoading(false);
			})
			.catch((err) => {
				addNewError({ name: "email", message: "SERVER ERROR" });
				addNewError({ name: "password", message: "SERVER ERROR" });
				setInResponse(false);
				setIsLoading(false);
			});
	};

	const gotoForgetPassword = () => {
		history.push("/forgot_password");
	};

	const loginAction = (user) => {
		let have_logins = JSON.parse(localStorage.getItem("login_count"));

		if (have_logins !== undefined || have_logins !== null) {
			if (have_logins <= 0) {
				window.history.pushState([], "Dashboard", "dashboard");
				window.location.reload();
				localStorage.setItem("login_count", JSON.stringify(1));
				return;
			}
		}

		history.push("/dashboard");
	};
	
	React.useEffect(() => {
		setTimeout(() => {
			var get_token = JSON.parse(localStorage.getItem("jwt_token"));

			if (get_token !== null) {
				setLoadingPage(true);
				createHttp
					.get("api/user")
					.then((res) => {
						if (res.data.status) {
							loginAction(
								JSON.parse(localStorage.getItem("jwt_token")),
							);
						}

						if (!res.data.status) {
							setLoadingPage(false);
						}
					})
					.catch((res) => {
						setLoadingPage(false);
					});
			} else {
				setLoadingPage(false);
			}
		}, 0);
	}, []);

	return (
		<>
			{isLoadingPage && <Loader />}
			{!isLoadingPage && (
				<div className="login">
					<div hidden={isLoadingPage} className="form-container">
						<div className="form-container_top">
							<img src={imglogo} alt="logo" className="logo" />
							<span
								className="mb-2"
								style={{
									color: "#1F3656",
									fontWeight: "bold",
									fontSize: "1.2rem",
								}}
							>
								Tech4Inventory
							</span>
						</div>
						<div className="form">
							<div className="theinputs">
								{/* email */}
								<label htmlFor="userName" className="label">
									{" "}
									Email{" "}
								</label>
								<input
									type="text"
									className={
										"input input-email " +
										(haveErrors("email")
											? "is-invalid"
											: "")
									}
									name="userName"
									id="userName"
									placeholder="sample@tech4tms.com"
									onChange={handleEmailChange}
								/>
								<p className="message_error">
									<span>
										{" "}
										{haveErrors("email")
											? returnErrorMsg("email")
											: ""}{" "}
									</span>
								</p>
								{/* password */}
								<label htmlFor="userPassword" className="label">
									{" "}
									Password{" "}
								</label>
								<input
									type="password"
									className={
										"input input-password " +
										(haveErrors("password")
											? "is-invalid"
											: "")
									}
									name="userPassword"
									id="userPassword"
									placeholder="∗∗∗∗∗∗∗∗∗∗"
									onChange={handlePasswordChange}
								/>
								<p className="message_error">
									<span>
										{" "}
										{haveErrors("password")
											? returnErrorMsg("password")
											: ""}{" "}
									</span>
								</p>
								<button
									disabled={in_response}
									className="primary-button login-button d-flex justify-content-center align-items-center"
									onClick={() => sendLogin()}
								>
									{isLoading && (
										<div
											className="spinner-border"
											role="status"
										>
											<span className="sr-only">
												Loading...
											</span>
										</div>
									)}
									{!isLoading && "Log In"}
								</button>
								<a
									onClick={gotoForgetPassword}
									className="forgot-password"
								>
									Forgot my password
								</a>
							</div>

							<button
								className="secondary-button demo-button mt-4"
								onClick={() => DemoLogin()}
							>
								Demo
							</button>
							<button
								className="secondary-button demo-button"
								onClick={gotoSignUp}
							>
								Sign up
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Login;
