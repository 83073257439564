import { useState } from 'react';

import { LaravelErrorsToObject } from '../utils/errors.laravel'
import Http from '../lib/http'

const initialState = {
  serial_number: "",
  name: "",
  partype: "",
}

const useProductCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState({ ...initialState });
  const [errors, setErrors] = useState({});
  
  const store = async (payload) => {
    try {
      setErrors({})
      setIsLoading(true);
      const { data: resp } = await Http.post(`/api/categories`, payload);
      const { data, status, message, msg } = resp;
      if(status) {
        // setCategory(() => Adapter.response(data));
        return data;
      } else {
        if(message) {
          setErrors((prev) => ({ ...prev, name:  `The name "${payload.name}" is already in use` }));
        } else {
          setErrors(LaravelErrorsToObject(msg));
        }
      }
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const uploadImageCategory = async (payload) => {
		try {
			await Http.post("/api/categories_change_img", payload);
		} catch (error) {
			throw error;
		}
	};

  return {
    store,
    uploadImageCategory,

    isLoading,
    setIsLoading,
    category,
    setCategory,
    errors,
    setErrors,
  }
}

export default useProductCategory;