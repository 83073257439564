import React from "react";
import { Modal, Button } from "react-bootstrap";
import { TextInput } from "../text_input";
import { ImageInput } from "../image_input";
import { ArrayInput } from "../array_input";

function ModalAdd(props) {
	const setItemToUpdate = (event, index) => {
		let new_array = [...props.table_add_configuration];
		new_array[index].value = event.target.value;
		props.updateAddConfiguration(new_array);
	};

	const setItemNumberToUpdate = (event, index) => {
		setItemToUpdate(replaceLetters(event), index);
	};

	const setItemImageToUpdate = (event, index) => {
		if (event.target.files.length > 0) {
			let new_array = [...props.table_add_configuration];
			new_array[index].value = event.target.files[0];
			props.updateAddConfiguration(new_array);
		}
	};

	const verifyErrors = () => {
		let new_array = [...props.table_add_configuration];

		let cont = 0;
		let have_changes = 0;

		for (let index = 0; index < new_array.length; index++) {
			if (
				(new_array[index].type === "text" ||
					new_array[index].type === "number" ||
					new_array[index].type === "array") &&
				new_array[index].required &&
				new_array[index].value.trim() === ""
			) {
				new_array[index].have_error = true;
				cont++;
				have_changes++;
			} else if (
				(new_array[index].type === "text" ||
					new_array[index].type === "number" ||
					new_array[index].type === "array") &&
				new_array[index].required &&
				new_array[index].have_error &&
				new_array[index].value.trim() !== ""
			) {
				new_array[index].have_error = false;
				have_changes++;
			}

			if (
				new_array[index].type === "img" &&
				new_array[index].required &&
				new_array[index].value === ""
			) {
				new_array[index].have_error = true;
				cont++;
				have_changes++;
			} else if (
				new_array[index].type === "img" &&
				new_array[index].required &&
				new_array[index].value !== ""
			) {
				new_array[index].have_error = false;
				have_changes++;
			}
		}

		if (cont > 0) {
			props.updateAddConfiguration(new_array);
			return false;
		}

		if (have_changes > 0) {
			props.updateAddConfiguration(new_array);
		}

		return true;
	};

	const sendAddRequest = () => {
		if (verifyErrors()) {
			props.onAddItem();
		}
		console.log("TERMINO");
	};

	function replaceLetters(event) {
		let new_value = event.target.value;
		event.target.value = new_value.replace(/[^\d]/g, "");
		return event;
	}

	return (
		<React.Fragment>
			<Modal size="lg" show={props.show} onHide={props.onHandleClose}>
				<Modal.Header>
					<Modal.Title> Add {props.app_conf.title} </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{props.table_add_configuration.map((element, index) => {
						if (element.type === "text") {
							return (
								<TextInput
									element_index={index}
									onElementChange={setItemToUpdate}
									key={index}
									element={element}
								/>
							);
						}

						if (element.type === "number") {
							return (
								<TextInput
									element_index={index}
									onElementChange={setItemNumberToUpdate}
									key={index}
									element={element}
								/>
							);
						}

						if (element.type === "img") {
							return (
								<ImageInput
									element_index={index}
									onElementChange={setItemImageToUpdate}
									key={index}
									element={element}
								/>
							);
						}

						if (element.type === "array") {
							return (
								<ArrayInput
									element_index={index}
									onElementChange={setItemToUpdate}
									key={index}
									element={element}
								/>
							);
						}
					})}
				</Modal.Body>
				<Modal.Footer>
					<Button
						onClick={sendAddRequest}
						variant="outline-success"
						size="sm"
					>
						Add
					</Button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	);

	/*
			 <Button variant="secondary" onClick={ props.onHandleClose}>
											Close
									</Button>
	 onClick={ props.onHandleClose}
	*/
}

export { ModalAdd };
