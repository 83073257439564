import React, { useCallback, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl';
import { Grid, Box, FormGroup } from '@material-ui/core';
import { useHistory, useLocation } from "react-router-dom";
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { NewWareHouse as WarehouseForm } from '../../WareHouse/newWareHouse';

import initState from './initState';
import rules from './rules';
import Adapter from './adapter'
import { validateFields } from '../../../utils/validations';

import Material from '../../../components/MaterialUI';
import { useModalHook } from '../../../customHooks/useModalHook';
import { useApiRequestExpensive } from '../../../customHooks/useApiRequestExpensive';
import { useApiCustomRequest } from '../../../customHooks/useApiCustomRequest';

import { useFileManager } from '../../../customHooks/useFileManager';
import CustomImageViewer from '../../../components/CustomImageViewer/CustomImageViewer';
import Panel from '../../../components/Panel';
import { VendorForm, BEHAVIOR } from '../../Vendor/vendorForm/Form';
import ExpensiveCategoryForm from '../../ExpensiveCategory/ExpensiveCategoryForm';
import { CustomModal } from '../../../components/CustomModal/CustomModal';
import { ExpensiveContext } from '../../../context/ExpensiveContext';
import PaymentAccountForm from '../../PaymentAccount/PaymentAccountForm';
import PaymentMethodForm from '../../PaymentMethod/PaymentMethodForm';

const ExpensiveForm = () => {
  const { 
    fetchVendors,
    fetchWarehouses,
    fetchTypesPayment,
    fetchExpenseCategories,
    fetchPaymentAccounts,

    warehouses,
    vendors,
    payments,
    expenseCategories,
    paymentAccounts,
    
    setExpenseCategories,
    setVendors,
    setWarehouses,
    setPayments,
    setPaymentAccounts,
  } = useContext(ExpensiveContext);
  const { formatMessage: trans } = useIntl();
  const [ expense, setExpense ] = React.useState({ ...initState.expenseForm });
  const [ payment, setPayment ] = React.useState({ ...initState.paymentForm });
  const [ errors, setErrors ] =  React.useState({});
  
	const history = useHistory();
	const location = useLocation();

  const expenseId = React.useMemo(() => location?.search?.split("=")[1], [location]);

  const {
		customCurrentItem,
    showModal,
    customTittleText,
    customMessageModal,
    successfullRequest,
    showSuccessButton,
    manageResponseErrors,
    setShowModal,
		setCustomCurrentItem,
		setCustomTittleText,
		setCustomMessageModal,
		setShowSuccessButton,
  } = useModalHook();

  const {
    isLoading,
    createExpense,
    getExpense,
    getExpensePayment,
    updateExpense,
  } = useApiRequestExpensive(manageResponseErrors, false, false);
	const handler = (...params) => console.warn(...params);
  const {
    getListItem,
    createImageItem,
    deleteImageItem,
  } = useApiCustomRequest(manageResponseErrors, "payment-account", "Payment Account", handler, false, true);

  const {
		documentsImageArray,
    imageIdsArray,
    itemImageToAdd,
		setFileImage,
		openFileImage,
		onDeleteImage,
    deleteImage,
    setDocumentsImageArray,
	} = useFileManager(
		setShowModal,
		customCurrentItem,
		setCustomCurrentItem,
		setCustomTittleText,
		setCustomMessageModal,
		setShowSuccessButton,
	);

  const fetchExpense = useCallback(async () => {
    if(expenseId) {
      const fileList = await getListItem(
        "expense-documents",
        "Expense Document",
        {
          expense_id: location?.search?.split("=")[1],
        },
      );
      if (fileList && fileList.length > 0) {
        const mapImages = fileList.map((item) => ({
          id: item.id,
          path: item.url,
        }));
        setDocumentsImageArray(mapImages);
      }
    }
  }, [expenseId]);

  useEffect(() => {
    fetchExpense();
    fetchVendors();
    fetchWarehouses();
    fetchTypesPayment();
    fetchExpenseCategories();
    fetchPaymentAccounts();
	}, [expenseId]);

  const warehouseList = React.useMemo(() => {
    if (!Array.isArray(warehouses)) return [];
		return warehouses.map(Adapter.responseWarehouseToAutocomplete);
	}, [warehouses]);
  
  const vendorList = React.useMemo(() => {
    if (!Array.isArray(vendors)) return [];
		return vendors.map(Adapter.responseVendorToAutocomplete);
	}, [vendors]);

  const categoryList = React.useMemo(() => {
    if (!Array.isArray(expenseCategories)) return [];
		return expenseCategories.map(Adapter.responseCategoryToAutocomplete);
	}, [expenseCategories]);

  const paymentList = React.useMemo(() => {
    if (!Array.isArray(payments)) return [];
		return payments.map(Adapter.responsePaymentToAutocomplete);
	}, [payments]);
  
  const paymentAccountList = React.useMemo(() => {
    if (!Array.isArray(paymentAccounts)) return [];
		return paymentAccounts.map(Adapter.responsePaymentAccountToAutocomplete);
	}, [paymentAccounts]);  
  
  const handleChange = (typeForm, key, value) => {
    if(typeForm == 'expenseForm') {
      setExpense(state => ({ ...state, [key]: value }));
    } else if(typeForm == 'paymentForm') {
      setPayment(state => ({ ...state, [key]: value }));
    }
    setErrors((prev) => ({
      ...prev,
      [key]: ''
    }));
  }
  
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setErrors({})
    
    const payloadExpense = Adapter.preparePayloadExpenseToSend(expense);
    const payloadPayment = Adapter.preparePayloadPaymentToSend(payment);
    const fields = { ...payloadExpense, ...payloadPayment };
    const { isValid, errors } = validateFields({ fields, rules });
    setErrors(errors)
    if (!isValid) return;
    
    try {
      if(expenseId) {
        const { id, ...restExpense} = payloadExpense;
        const resp = await updateExpense(expenseId, restExpense, payloadPayment);
        if (
          resp &&
          resp.hasOwnProperty("expensePayment") &&
          resp.hasOwnProperty("expenseResponse")
        ) {
          history.push("/expensive");
        }
        await Promise.all(
          itemImageToAdd.map(async (fileItem) => {
            return await createImageItem(
              "expense-documents",
              id,
              fileItem,
              "Expense Document",
            );
          }),
        );

        await Promise.all(
          imageIdsArray
            .filter((id) => parseInt(id))
            .map(async (id) => {
              return await deleteImageItem("expense-documents", id);
            }),
        );
      } else {
        const expensiveId = await createExpense(payloadExpense, payloadPayment);
        if (parseInt(expensiveId) > 0)
          history.push('/expensive');
      }
    } catch (error) {}
  }

  const fetchDataInit = useCallback(async () => {
    try {
      const _expense = await getExpense(expenseId, true);
      setExpense(Adapter.requestPayloadExpense(_expense));
      const [ _payment ] = await getExpensePayment(expenseId, true);
      setPayment(Adapter.requestPayloadPayment(_payment));
    }
    catch (error) {}
  }, []);

  useEffect(() => {
    if(expenseId) fetchDataInit();
  }, [expenseId]);
  
	const [openModalVendor, setOpenModalVendor] = React.useState(false);
  const handleToggleModalVendor = () => setOpenModalVendor(state => !state);

  const [openModalCategoryExpensive, setOpenModalCategoryExpensive] = React.useState(false);
  const handleToggleModalCategoryExpensive = () => setOpenModalCategoryExpensive(state => !state);

  const [panels, setPanels] = React.useState({
    paymentMethod: false,
    paymentAccount: false,
    warehouse: false,
  });

  const togglePanel = (panel) => {
		setPanels((prev) => ({ ...prev, [panel]: !prev[panel] }));
  };

  const captureResponseVendorModal = ({ data }) => {
    const vendor = data.data;
    if(!vendor) return;
    setVendors((prev) => [...prev, vendor]);
    setExpense((prev) => ({ ...prev, vendor_id: Adapter.responseVendorToAutocomplete(vendor) }))
    handleToggleModalVendor(); 
  }

  const captureResponseExpensiveCategoryModal = (payload) => {
    if(!payload) return;
    setExpenseCategories((prev) => [...prev, payload ]);
    setExpense((prev) => ({ ...prev, expense_category_id: Adapter.responseCategoryToAutocomplete(payload) }))
    handleToggleModalCategoryExpensive();
  }

  const captureResponseWarehouseModal = (payload) => {
    if(!payload) return;
    const { status, data } = payload;
    if(status) {
      setWarehouses((prev) => [...prev, data]);
      setExpense((prev) => ({ ...prev, warehouse_id: Adapter.responseWarehouseToAutocomplete(data) }))
      togglePanel('warehouse');
    }
  }
  
  const captureResponsePaymentMethodModal = (newPaymentMethod) => {
		if (!newPaymentMethod) return;
		setPayments((prev) => [...prev, newPaymentMethod]);
		setPayment((prev) => ({ ...prev, type_payment_id: Adapter.responsePaymentToAutocomplete(newPaymentMethod) }));
    togglePanel('paymentMethod');
  }

  const captureResponsePaymentAccountModal = (payload) => {
		if (!payload) return;
    setPaymentAccounts((prev) => [...prev, payload ]);
    setPayment((prev) => ({...prev, payment_account_id: Adapter.responsePaymentAccountToAutocomplete(payload) }))
    togglePanel('paymentAccount');
  }
  
	const gotBack = () => history.push('/expensive');

  return (
    <>
      <CustomModal
        show={isLoading}
        customTittleText={""}
        customMessageModal={""}
        onHandleClose={() => {}}
        isLoading={isLoading}
        onHandleSuccess={() => deleteImage()}
      />
      <CustomModal
        show={showModal}
        customTittleText={customTittleText}
        customMessageModal={customMessageModal}
        onHandleClose={() =>
          !successfullRequest
            ? setShowModal(false)
            : history.push("/expensive")
        }
        onHandleSuccess={() => deleteImage()}
        isLoading={isLoading}
        showSuccessButton={showSuccessButton}
      />
      <Panel open={openModalVendor} anchor="right" togglePanel={handleToggleModalVendor} headerTitle="Create Vendor" >
				<VendorForm behavior={BEHAVIOR.NO_REDIRECT} handleBehavior={captureResponseVendorModal} />
			</Panel>
      <Panel open={openModalCategoryExpensive} anchor="right" togglePanel={handleToggleModalCategoryExpensive} headerTitle="Create Category Expensive" >
        <ExpensiveCategoryForm handleBehavior={captureResponseExpensiveCategoryModal}/>
			</Panel>
      <Panel open={panels.warehouse} anchor="right" togglePanel={() => togglePanel('warehouse')} headerTitle="Create Warehouse">
        <WarehouseForm handleBehavior={captureResponseWarehouseModal} />
			</Panel>
      <Panel open={panels.paymentMethod} anchor="right" togglePanel={() => togglePanel('paymentMethod')} headerTitle="Create Payment Method">
        <PaymentMethodForm handleBehavior={captureResponsePaymentMethodModal} />
      </Panel>
      <Panel open={panels.paymentAccount} anchor="right" togglePanel={() => togglePanel('paymentAccount')} headerTitle="Create Payment Account">
        <PaymentAccountForm handleBehavior={captureResponsePaymentAccountModal} />
      </Panel>
      <Grid className='mb-4' component={'form'} onSubmit={handleSubmit} noValidate autoComplete='off' container spacing={1}>
        <Grid item xs={12}>
          <div className="d-flex gap-2 align-items-center">
            <Material.Button.Dark onClick={gotBack}>
              <ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
            </Material.Button.Dark>
            <div className="container-title">
              <h1 className="container__title mb-0 py-2">
                { trans({ id: expenseId ? 'edit' : 'create' }) } { trans({ id: 'expensive' }) }
              </h1>
            </div>
          </div>
        </Grid>
        { expenseId ? (
            <Grid item xs={12} md={6} lg={4}>
              <Material.Input label={'ID'} state={expense.id} disabled />
            </Grid>
          ) : null
        }
        <Grid item xs={12} md={6} lg={4}>
          <Material.Input 
            label={'Reference No.'}
            state={expense.number}
            setState={(value) => handleChange('expenseForm', 'number', value)}
            error={errors.number}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormGroup className='flex-row flex-nowrap'>
            <Material.Autocomplete 
              label="Warehouse"
              optionsList={warehouseList}
              state={expense.warehouse_id}
              setState={(value) => handleChange('expenseForm', 'warehouse_id', value)}
              error={errors.warehouse_id}
              className="roundedSides"
            >
              <Material.Button.Primary type={'button'} disableElevation className="roundedSides" size='small' onClick={() => togglePanel('warehouse')}>
                <AddCircleOutlineSharpIcon fontSize='small' />
              </Material.Button.Primary>
            </Material.Autocomplete>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormGroup className='flex-row flex-nowrap'>
            <Material.Autocomplete 
              label="Vendor"
              optionsList={vendorList}
              state={expense.vendor_id}
              setState={(value) => handleChange('expenseForm', 'vendor_id', value)}
              error={errors.vendor_id}
              className="roundedSides"
            >
              <Material.Button.Primary type={'button'} disableElevation className="roundedSides" size='small' onClick={handleToggleModalVendor}>
                <AddCircleOutlineSharpIcon fontSize='small' />
              </Material.Button.Primary>
            </Material.Autocomplete>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Material.Autocomplete 
            label="Category Expensive"
            optionsList={categoryList}
            state={expense.expense_category_id}
            setState={(value) => handleChange('expenseForm', 'expense_category_id', value)}
            error={errors.expense_category_id}
            className="roundedSides"
          >
            <Material.Button.Primary type={'button'} disableElevation className="roundedSides" size='small' onClick={handleToggleModalCategoryExpensive}>
              <AddCircleOutlineSharpIcon fontSize='small' />
            </Material.Button.Primary>
          </Material.Autocomplete>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Material.Input 
            type="number"
            label={'Total amount'}
            state={expense.amount}
            setState={(value) => handleChange('expenseForm', 'amount', value)}
            error={errors.amount}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Material.Input 
            type="date"
            label={'Date'}
            InputLabelProps={{ shrink: true }}
            state={expense.date}
            setState={(value) => handleChange('expenseForm', 'date', value)}
            error={errors.date}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4} className='mb-2'>
          <Material.Input 
            label={'Comments'}
            state={expense.description}
            setState={(value) => handleChange('expenseForm', 'description', value)}
            error={errors.description}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="container-title">
            <h1 className="container__subtitle mb-0 py-2">
              { trans({ id: expenseId ? 'edit' : 'create' }) } { trans({ id: 'payment' }) }
            </h1>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Material.Input 
            type="date"
            InputLabelProps={{ shrink: true }}
            label={'Paid on'}
            state={payment.date_paid}
            setState={(value) => handleChange('paymentForm', 'date_paid', value)}
            error={errors.date_paid}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormGroup className='flex-row flex-nowrap'>
            <Material.Autocomplete 
              label="Payment Method"
              optionsList={paymentList}
              state={payment.type_payment_id}
              setState={(value) => handleChange('paymentForm', 'type_payment_id', value)}
              error={errors.type_payment_id}
              className="roundedSides"
            >
              <Material.Button.Primary type={'button'} disableElevation className="roundedSides" size='small' onClick={() => togglePanel('paymentMethod')}>
                <AddCircleOutlineSharpIcon fontSize='small' />
              </Material.Button.Primary>
            </Material.Autocomplete>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormGroup className='flex-row flex-nowrap'>
            <Material.Autocomplete 
              label="Payment Account"
              optionsList={paymentAccountList}
              state={payment.payment_account_id}
              setState={(value) => handleChange('paymentForm', 'payment_account_id', value)}
              error={errors.payment_account_id}
              className="roundedSides"
            >
              <Material.Button.Primary type={'button'} disableElevation className="roundedSides" size='small' onClick={() => togglePanel('paymentAccount')}>
                <AddCircleOutlineSharpIcon fontSize='small' />
              </Material.Button.Primary>
            </Material.Autocomplete>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Material.Input 
            label={'Reference payment'}
            state={payment.payment_info}
            setState={(value) => handleChange('paymentForm', 'payment_info', value)}
          />
        </Grid>
        { expenseId ? (
          <Grid item xs={12} md={10} className='pt-0'>
            <div className="content-notes col-12"><label className="form-label mb-0">Files</label></div>
            <Box sx={{ overflow: "auto" }} className="p-1 border scroll_customized">
              <CustomImageViewer
                itemData={documentsImageArray}
                onClickImage={() => {}}
                onDeleteImage={onDeleteImage}
                showDefaultPdfIcon={true}
                showDowloadAction={true}
              >
                <div className="user-select-none p-3 rounded-2 d-flex flex-column align-items-center justify-content-center" style={{ width: '95px', height: 'auto', border: '2px dashed #3f51b5', backgroundColor: 'rgb(63 81 181 / 15%)', color: '#3f51b5', cursor: 'pointer' }} onClick={() => openFileImage()}>
                  <AddRoundedIcon className='mb-2' />
                  <p className="mb-0" style={{ fontSize: '12px', lineHeight: '1', textAlign: 'center' }}>Attach documents</p>
                  <input
                    hidden
                    id="file_input_doc_input"
                    type="file"
                    onChange={(e) => setFileImage(e)}
                    accept="application/pdf"
                    multiple
                  />
                </div>
              </CustomImageViewer>
            </Box>
          </Grid>
        ) : null }
        <Grid item xs={12} md={expenseId ? 2 : 12} className='text-end'>
          <Material.Button.Success type={'submit'} disabled={isLoading}>
            <SaveOutlinedIcon fontSize="small" className="me-2" /> {expenseId ? 'Update' : 'Save'}
          </Material.Button.Success>
        </Grid>
      </Grid>
    </>
  )
}


export default ExpensiveForm