import { useState } from "react";

function useAuthentication() {
	const getInfoFromLocalStorage = (localStoragaItem) => {
		const data = JSON.parse(localStorage.getItem(localStoragaItem));
		if (!data) return [];
		return data;
	};

	const saveItemInLocalStorage = (itemName, newItem) => {
		const stringItemInfo = JSON.stringify(newItem);
		localStorage.setItem(itemName, stringItemInfo);
	};

	const deleteItemFromLocalStorage = (itemName) => {
		localStorage.removeItem(itemName);
	};

	const [currentToken, setCurrentToken] = useState(
		JSON.parse(localStorage.getItem("jwt_token")),
	);
	const [userInfo, setUserInfo] = useState(
		getInfoFromLocalStorage("user_data"),
	);

	return {
		setCurrentToken,
		currentToken,
		userInfo,
		saveItemInLocalStorage,
		deleteItemFromLocalStorage,
		getInfoFromLocalStorage,
	};
}

export { useAuthentication };
