export const PanelNames = {
	PANEL_SEARCH_CUSTOMER: 'PANEL_SEARCH_CUSTOMER',
}

export const typesAndValues = [
	{
		field_name: "customer_id",
		type_field: "searchInput",
		text_label: "Customer",
		disabled: false,
		regexPattern: /^\d+$/,
		field_id: "customer_id",
		labelText: "",
		defaultOptionText: "Select a customer",
		col: "col-2",
		typeFilter: "searchInput",
		apiRequest: "clients",
		queryParam: "search_text",
		defaultValue: "",
		selectCol: "col-12 d-flex align-items-start",
		inputValue: "",
		doRequest: false,
		dontInitData: false,
		initOptions: [],
		inputConf: {
			className: 'col-12 col-md-4'
		},
		nextSiblingConfig: {
			iconStart: 'fas fa-search',
			data: {
				panel: PanelNames.PANEL_SEARCH_CUSTOMER
			}
		},
	},
	{
		field_name: "payment_date",
		type_field: "text_input",
		text_label: "Payment Date",
		disabled: false,
		regexPattern: /^\d{4}[./-]\d{2}[./-]\d{2}$/,
		type: "date",
		inputConf: {
			className: 'col-12 col-md-4'
		},
	},
	{
		field_name: "type_payment_id",
		type_field: "select_input",
		text_label: "Payment Method",
		placeHolder: "Select a payment method...",
		dontDefaultOption: false,
		values: [{ id: "1", name: "Cash" }],
		selectCol: "col-12",
		regexPattern: /^[a-z0-9]+$/i,
		inputConf: {
			className: 'col-12 col-md-4'
		},
	},
	{
		field_name: "reference_number",
		type_field: "text_input",
		text_label: "Reference No",
		disabled: false,
		// regexPattern: /^\d+$/,
		maxLength: 30,
		minLength: 1,
		require: true,
		col: "col-2",
	},
	{
		field_name: "account_id",
		type_field: "select_input",
		text_label: "Deposit to",
		placeHolder: "Select a deposit to...",
		// placeHolder: "Business Account",
		dontDefaultOption: false,
		values: [{ id: "1", name: "Account 1" }],
		selectCol: "col-12",
		regexPattern: /^[a-z0-9]+$/i,
		col: "col-2",
	},
];

export const initData = {
	customer_id: "",
	payment_date: "",
	type_payment_id: "",
	reference_number: "",
	account_id: "",
};

export const typesAndValuesPartNumber = [];

export const initDataPartNumber = {
	item_id: "",
};

export const typesAndValuesBillingAddress = [
	{
		field_name: "comments",
		type_field: "textArea",
		text_label: "Billing Address",
		rows: "2",
		// regexPattern: /^$|^[A-Za-z0-9."',@; ]*$/,
		col: "col-6",
		// maxLength: 150,
		transformText: true,
		disabled: true,
	},
];

export const initDataBillingAddress = {
	comments: "",
};

export const typesAndValuesMessageOnInvoice = [
	{
		field_name: "memo",
		type_field: "textArea",
		text_label: "Memo",
		rows: "3",
		// regexPattern: /^$|^[A-Za-z0-9."',@; ]*$/,
		col: "col-6",
		maxLength: 150,
		transformText: false,
		disabled: false,
	},
];

export const initDataMessageOnInvoice = {
	memo: "",
};

export const typesAndValuesUpdateDetailInvoice = [
	{
		field_name: "id",
		type_field: "text_input",
		text_label: "Id",
		disabled: true,
		regexPattern: /^[A-Za-z0-9]*$/,
		maxLength: 15,
		hidden: true,
	},
	{
		field_name: "part_number",
		type_field: "text_input",
		text_label: "Part Number",
		disabled: true,
		regexPattern: /^[A-Za-z0-9]*$/,
		maxLength: 15,
		col: "col-4",
	},
	{
		field_name: "rate",
		type_field: "text_input",
		text_label: "Rate",
		disabled: false,
		regexPattern: /^$|^[#.0-9a-zA-Z\s,-,',",@,;]+$/,
		maxLength: 100,
		// minLength: 3,
		col: "col-4",
		required: false,
		type: "number",
	},
	{
		field_name: "quantity",
		type_field: "text_quantity",
		text_label: "Quantity",
		regexPattern: /^$|^[0-9]*$/,
		col: "col-4",
	},
	{
		field_name: "description",
		type_field: "text_input",
		text_label: "Description",
		disabled: false,
		regexPattern: /^$|^[A-Za-z0-9.@'"; ]*$/,
		maxLength: 100,
		col: "col-12",
	},
];

export const initDataUpdateDetailInvoice = {
	part_number: "",
	rate: "",
	quantity: "",
	description: "",
};

export const typesAndValuesFindInvoice = [
	{
		field_name: "invoice_no",
		type_field: "text_input",
		text_label: "Invoice no.",
		disabled: false,
		regexPattern: /^[A-Za-z0-9]*$/,
		maxLength: 30,
		minLength: 1,
		col: "col-12",
		autoFocus: true,
		inputConf: {
			className: 'col-12'
		},
	},
];

export const initDataFindInvoice = {
	invoice_no: "",
};
