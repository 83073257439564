import React, { StrictMode } from "react";
//import "../App.css";
import imglogo from "../../../assets/img/logo_t4i.png";
import icon_mail from "../../../assets/icons/email.svg";
import ReactDOM from "react-dom";
import App from "../../../components/App";
// import "../login.css";
import "./ForgetPassword.css";
import { Email_been_send } from "../email_send/Email_been_send";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Login from "../../../views/login/Login";
import { useHistory } from "react-router-dom";

const styles = {
	color: "#1F3656",
	textAlign: "center",
	fontSize: "15px",
};

function ForgetPassword() {
	const history = useHistory();

	const gotoLogin = () => {
		history.push("/");
	};

	const gotoEmailBeenSend = () => {
		history.push("/forgetpassword");
	};

	return (
		<div className="login">
			<div className="form-container__forget-password">
				<div className="form-container_top">
					<img src={imglogo} alt="logo" className="logo" />
					<span
						className="mb-2"
						style={{
							color: "#1F3656",
							fontWeight: "bold",
							fontSize: "1.2rem",
						}}
					>
						Tech4Inventory
						<div
							className="information-content__signup"
							style={styles}
						>
							<p style={{ margin: "0px" }}>
								Alredy registered ?{" "}
								<span
									style={{
										fontWeight: "bold",
										color: "#EA5E20",
									}}
									onClick={gotoLogin}
								>
									Sign In
								</span>{" "}
							</p>
						</div>
					</span>
				</div>
				<form className="form">
					<div className="theinputs" style={{ padding: "20px" }}>
						{/* Email input */}
						<label className="label"> Email </label>
						<input
							type="text"
							className="input input-email"
							name="emailForget"
							id="emailForget"
							title="Enter your email"
						/>
						<button
							className="primary-button signup-button mt-1"
							onClick={gotoEmailBeenSend}
						>
							CHANGE PASSWORD
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export { ForgetPassword };
