import "./expensive.css";
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaCaretDown } from "react-icons/fa";
import { FaLongArrowAltRight } from "react-icons/fa";

//data list components
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import MenuItem from "@material-ui/core/MenuItem";
import no_image from "../../assets/img/no_image.png";
import { CustomFilterComponent } from "../../components/CustomFiltersComponent/CustomFiltersComponent";
import {
	CustomSelect,
	CustomInputFilter,
} from "../../components/customSelect/customSelect";

//CustomHooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiRequestExpensive } from "../../customHooks/useApiRequestExpensive";
import { useMenuActionHook } from "../../customHooks/useMenuActionHook";
import { usePaginationHook } from "../../customHooks/usePaginationHook";

import {
	styles,
	tableHeadersExpensive as tableHeaders,
	dataFieldsExpensive as dataFields,
	tableHeadersToDataFieldsExpense as tableHeadersToDataFields,
	dataFieldsToOrderFilterExpense as dataFieldsToOrderFilter,
	tableHeadersToOrderFilterExpense as tableHeadersToOrderFilter,
} from "../../context/constants";
import { PaginationControlled } from "../../components/CustomPagination/CustomPaginacion";
import { ExpensiveContext } from "../../context/ExpensiveContext";
import { useMediaQuery } from "@material-ui/core";

function Expensive() {
	const history = useHistory();
	const isMobile = useMediaQuery('(max-width:480px)');

  const {
		fetchWarehouses,
		fetchExpenseCategories,
		warehouses: warehouse,
		expenseCategories: expensiveCategory
	} = useContext(ExpensiveContext);

	const {
		anchorEl,
		setAnchorEl,
		onCloseModal,
		currentId,
		setCurrentId,
		currentElement,
		setCurrentElement,
	} = useMenuActionHook();

	const {
		showModal,
		setShowModal,
		customTittleText,
		setCustomTittleText,
		customMessageModal,
		showSuccessButton,
		manageResponseErrors,
		manageDeleteMessage,
		setCustomMessageModal,
		cancelButtonText,
		sucessButtonText,
	} = useModalHook();

	const {
		isLoading,
		setSincronizedItems,
		filtered_data: expense,
		deleteExpenses,
		itemFilters,
		setItemFilters,
		itemPagination,
	} = useApiRequestExpensive(manageResponseErrors, true, false);

	const {
		filterInputChange,
		selectOnChange,
		onChangeNumberSelect,
		handlePagination,
		filtersDefinition,
		setFiltersDefition,
		filtersTextDefinition,
		setFiltersTextDefition,
		onChangeOrderArrow,
	} = usePaginationHook(setSincronizedItems, setItemFilters, itemFilters);

	const onChangeDateFilter = (value, field_id) => {
		selectOnChange({
			field_id: field_id,
			value: value,
		});
	};

	//handlers dataList
	const deleteItem = async () => {
		setShowModal(false);
		return await deleteExpenses(currentId);
	};

	const onDeleteFromMobileTable = (currentId) => {
		setCurrentId(currentId);
		setAnchorEl(null);
		manageDeleteMessage("Expensive");
		setCustomMessageModal(
			`Are you sure you want to delete item #${currentId}?`,
		);
		setShowModal(true);
	};

	const onMenuActions = async (event, element) => {
		setCurrentId(element.id);
		setCurrentElement(element);
		setAnchorEl(event.currentTarget);
	};

	const onDelete = (event) => {
		setAnchorEl(null);
		manageDeleteMessage("Expensive");
		setCustomMessageModal(
			`Are you sure you want to delete item #${currentId}?`,
		);
		setShowModal(true);
	};

	const onEdit = () => {
		setCustomTittleText("Edit Expensive");
		setAnchorEl(null);
		history.push({
			pathname: "/expensive_detail/",
			search: `?expensive_id=${currentElement.id}`,
		});
		// setShowCustomFormModal(true);
	};

	const actionsDefinition = [
		{
			name: "Edit",
			func: onEdit,
			icon: faEdit,
		},
		{
			name: "Delete",
			func: onDelete,
			icon: faTrashAlt,
		},
	];

	//filter Implementation
	const filters = [
		{
			field_id: "category_exponsive_id",
			selectTittle: "Categories Expensive",
			styles: styles,
			defaultOptionText: "Select a categories expensive",
			itemArray: expensiveCategory,
			defaultValue: "",
		},
		{
			field_id: "warehouse_id",
			selectTittle: "Warehouse",
			styles: styles,
			defaultOptionText: "Select a warehouse",
			itemArray: warehouse,
			defaultValue: "",
		},
		{
			field_id: "status",
			selectTittle: "Expensive Status",
			styles: styles,
			defaultOptionText: "Select a expensive status",
			itemArray: [
				{ id: "active", name: "Active" },
				{ id: "inactive", name: "Inactive" },
			],
			defaultValue: "",
		},
	];

	const filtersText = [
		{
			field_id: "date_from",
			field_name: "date_from",
			labelText: "Date",
			value: "",
			type: "date",
		},
		{
			field_id: "amount",
			field_name: "amount",
			labelText: "Amount",
			value: "",
			placeholder: "Select an Amount",
			type: "number",
		},
	];

	useEffect(() => {
		setFiltersTextDefition(filtersText);
	}, []);

	useEffect(() => {
		setFiltersDefition([...filters]);
	}, [warehouse, expensiveCategory]);

    const [optionListIndex, setOptionListIndex] = useState(null); // Estado para almacenar el índice del elemento con lista de opciones abierto

    const handleOptionListToggle = (index) => {
        setOptionListIndex(index === optionListIndex ? null : index); // Alternar la apertura y cierre de la lista de opciones
    };
	const loadFilterData = () => {
		fetchWarehouses();
		fetchExpenseCategories();
	};
	
	return (
		<div className="container-fluid contenedor">
			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowModal(false)}
				onHandleSuccess={deleteItem}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
				actionsFooterStyle={true}
				cancelText={cancelButtonText}
				successText={sucessButtonText}
			/>

			<div className="container-title mb-2">
				<h1 className="container__title mb-0">Expensive</h1>
			</div>
			<div className="row">
				<CustomFilterComponent
					filtersDefinition={filtersDefinition}
					filtersTextDefinition={filtersTextDefinition}
					onClickfilter={() => setSincronizedItems(false)}
					filterInputChange={filterInputChange}
					onCreateNewItem={() => history.push("/create_expensive")}
					onChangePageEvent={onChangeNumberSelect}
					onHandleOpenDropdown={loadFilterData}
					renderFilter={(filter, index) => (
						<CustomSelect
							key={index}
							field_id={filter.field_id}
							selectTittle={filter.selectTittle}
							styles={filter.styles}
							defaultOptionText={filter.defaultOptionText}
							itemArray={filter.itemArray}
							onChangeEvent={selectOnChange}
							selectWidth={"100%"}
							defaultValue={filter.defaultValue}
						/>
					)}
					renderFilterText={(filter, index) => (
						<CustomInputFilter
							key={index}
							filter={filter}
							onChangeDateFilter={onChangeDateFilter}
						/>
					)}
				/>
			</div>
			{
				!isMobile ? (
					<div className="row expensive-dataList-desktop-wrapper">
					<div className="col-12">
						<CustomDataList
							onChangePageEvent={onChangeNumberSelect}
							style={styles}
							isLoading={isLoading}
							dataList={expense}
							tableHeaders={tableHeaders}
							itemPagination={itemPagination}
							handlePagination={handlePagination}
							dontShowPerPageSelect={true}
							showOrderArrows={true}
							onChangeOrderArrow={onChangeOrderArrow}
							tableHeadersToDataFields={tableHeadersToDataFields}
							dataFieldsToOrderFilter={dataFieldsToOrderFilter}
							tableHeadersToOrderFilter={tableHeadersToOrderFilter}
							itemFilters={itemFilters}
							showLinearIndicator={true}
							showDataInLoading={true}
							render={(element, index) => (
								<CustomDataListItem
									key={index}
									dataFields={dataFields}
									imageFields={[]}
									composedFields={[]}
									arrayFields={[]}
									element={element}
									index={index}
									no_image={no_image}
									renderActions={() => (
										<>
											<PositionedMenu
												title={"Actions"}
												anchorEl={anchorEl}
												setAnchorEl={setAnchorEl}
												handleClose={onCloseModal}
												handleClick={(event) =>
													onMenuActions(event, element)
												}
												actionsDefinition={actionsDefinition}
												renderActions={(action, index) => (
													<MenuItem
														sx={{
															boxShadow: "none",
														}}
														key={index}
														onClick={() => action.func()}
													>
														<div
															className="items-option"
															style={{
																padding: "0 5px",
																color: "grey",
															}}
														>
															<FontAwesomeIcon
																style={{
																	fontSize: '.8rem',
																	margin: "4px",
																	marginRight: "10px",
																}}
																icon={action.icon}
																className={
																	action.name == "Edit" 
																		? "text-primary"
																		: action.name == "Delete"
																			? 'text-danger'
																			: 'inherit'
																}
															/>
															<p className="mb-0 fs-small">{action.name}</p>
														</div>
													</MenuItem>
												)}
											></PositionedMenu>
										</>
									)}
								/>
							)}
						/>
					</div>
					</div>
				) : (
					<div className="row">
						<div className="col-12">
							<div className="expensive-dataList-mobile-wrapper">
								<div className="table-responsive">
									<table className="table mb-0">
										<tbody className="table__mobile">
											{
												expense.length > 0 && expense.map((expenseItem, index)=>(
												<tr
													key={index}
													style={{ padding: "5px 10px" }}
												>
													<td style={{ fontSize: '0.75rem' }}>
														<div style={{ display: 'grid', gridTemplateColumns: '30% 65%', justifyContent: 'space-between' }}>
															<div>
																<strong>ID: {expenseItem.id}</strong>
																<p>Number: {expenseItem.number}</p>
																<p>Date: {expenseItem.date}</p>
															</div>
															<div
																style={{display:"flex", flexDirection:"column", alignItems:"flex-end"}}
															>
																<strong className="mobile__text__description">Description: {expenseItem.description}</strong>
																<p>Amount: {expenseItem.amount}</p>
																<p>Status: <span className="text-capitalize">{expenseItem.status}</span></p>
															</div>
														</div>
														<div style={{display:"flex", justifyContent:"flex-end", width:"100%", fontWeight:"bold", color:"#0D6EFD", alignItems: 'center'}}>
															<small
																onClick={() => history.push(`/expensive_detail/?expensive_id=${expenseItem.id}`)}
																style={{ borderRight:"1px solid lightgrey", padding:"0 5px" }}
															>
																Edit
															</small>
															<small
																onClick={() => onDeleteFromMobileTable(expenseItem.id)}
																style={{ borderRight:"1px solid lightgrey", padding:"0 5px" }}
															>
																Delete
															</small>
															<small onClick={() => handleOptionListToggle(index)} style={{ padding: "0 5px", position:"relative" }}>
																<FaCaretDown size={"20px"}/>
																{optionListIndex === index &&(
																	<div style={{ right:"0", borderRadius:"6px", padding: "5px 15px", position:"absolute" , zIndex:"10", background:"white", color:"black", boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
																		<p style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}><FaLongArrowAltRight size={"18px"} /> View</p>
																		<p style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}><FaLongArrowAltRight size={"18px"} /> Copy</p>
																		<p style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}><FaLongArrowAltRight size={"18px"} /> Print</p>
																	</div>
																)}
															</small>
														</div>
													</td>
												</tr>
												))
											}
										</tbody>
									</table>
								</div>
							</div>
							<div className="mobile-pagination-wrapper">
								{expense.length > 0 && !isLoading ? (
										<PaginationControlled
											itemPagination={itemPagination}
											handlePagination={handlePagination}
											customClasses="padding-child"
										/>
								) : <div style={{height:"85px"}}></div>}
							</div>
						</div>
					</div>
				)
			}
			
		</div>
	);
}

export { Expensive };
