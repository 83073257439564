import { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';

import TabPanel from '../../../components/TabPanel';
import FormTwilio from './FormTwilio';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    padding: 0,
  },
  tabs: {
    borderBottom: '1px solid var(--bs-gray-300)',
    padding: 0,
  },
  tabPanel: {
    minBlockSize: '500px'
  }
}));

const tabs = ['Twilio', 'Clickatell', 'Nexmo', 'More Gateaway'];

export default function ViewSMS() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className={classes.tabs}
      >
        { tabs.map((tab, index) => ( <Tab key={index} label={tab} {...a11yProps(index)} /> )) }
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabPanel} dir={theme.direction}>
        <FormTwilio />
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabPanel} dir={theme.direction}>
        Component
      </TabPanel>
    </div>
  );
}
