import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";



function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AlertComponent({
	open,
	handleClose,
	severity,
	message,
}) {
	const [audio] = React.useState(new Audio('/media/success.mp3'));
	
	React.useEffect(() => {
		if(open) {
			audio.volume = 0.05;
			audio.play();
		}
	}, [open, audio])
	
	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<Alert onClose={handleClose} severity={severity}>
				{message}
			</Alert>
		</Snackbar>
	);
}
