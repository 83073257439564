import createHttp from "../lib/http";
import { useState, useEffect, useCallback } from "react";

function useApiRequestTypePayment(
	manageResponseErrors,
	requestVendorList = true
) { 
	const [isLoading, setIsLoading] = useState(false);
	const [sincronizedItems, setSincronizedItems] = useState(false);
	const [filtered_data, setFilteredData] = useState([]);

	const fetchTypesPayment = useCallback(() => {
		if(sincronizedItems) return;
		setIsLoading(true);
		let params = {};
		createHttp
			.get("api/type-payment", { params })
			.then((response) => {
				if (response.data.status) {
					setFilteredData(response.data.data);
				} else {
					manageResponseErrors(
						response.data.message,
						"Expensive",
						"deleting",
					);
				}
				setSincronizedItems(true);
				setIsLoading(false);
			})
			.catch((response) => {
				setIsLoading(false);
				manageResponseErrors(
					"An error has occurred",
					"Expensive",
					"deleting",
				);
			});
	}, [sincronizedItems]);
	
	useEffect(() => {
		if(requestVendorList) fetchTypesPayment()
	}, []);

	return {
		isLoading,
		setIsLoading,
		sincronizedItems,
		setSincronizedItems,
		filtered_data,
		setFilteredData,
		fetchTypesPayment,
	};
}

export { useApiRequestTypePayment };
