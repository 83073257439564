export default class InputFormat {
	static limitDecimalPlaces(options = { decimals: 2, maxLength: 7 }) {
		return (value) => {
			value = String(value);
			const maxLength = !!options.decimals ? options.maxLength + 3 : options.maxLength;
			if(value.length > maxLength) return value.slice(0, -1);
			let seen = false;
			if (isNaN(value))
				return value
					.replace(/[.]/g, () => {
						if (seen) return "";
						seen = true;
						return ".";
					})
					.replace(/[^0-9.]/g, "");
			if (value.indexOf(".") == -1) return value;
			if (value.length - value.indexOf(".") > options.decimals) value = Number(value).toFixed(options.decimals);
			return value;
		};
	}
}
