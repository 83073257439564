import React from "react";
import Layout from "../../../components/Layout/Layout";
import { TableCrud } from "../../../components/table_crud";

const items = [
	{ name: "Volume", value: "Volume" },
	{ name: "Area", value: "Area" },
	{ name: "Quantity", value: "Quantity" },
	{ name: "Mass", value: "Mass" },
	{ name: "Length", value: "Length" },
];

const locations = [
	{ name: "Imperial", value: "Imperial" },
	{ name: "Metric", value: "Metric" },
	{ name: "Both", value: "Both" },
];

const app_conf = {
	title: "Products Inventory",
};

const create_table_add_configuration = [
	{
		field_id: "item_id",
		field_name: "Item",
		type: "text",
		length: 100,
		value: "",
		required: true,
		have_error: false,
		update_field: true,
		array_values: items,
	},
	{
		field_id: "location_id",
		field_name: "Location",
		type: "text",
		length: 100,
		value: "",
		required: true,
		have_error: false,
		update_field: true,
		array_values: locations,
	},
	{
		field_id: "quantity",
		field_name: "Quantity",
		type: "number",
		length: 100,
		value: "",
		required: true,
		have_error: false,
		update_field: true,
	},
];

const table_id_configuration = [
	//{ field_id : 'id' , field_name : 'Id', type : 'text', search : false },
	{ field_id: "name", field_name: "Name", type: "text", search: true },
];

const api_configuration = {
	get_all_data: "/api/item_locations",
	store_item: "/api/item_locations",
	delete_item: "/api/item_locations",
	update_item: "/api/item_locations",
	get_item: "/api/item_locations",
};

//get items
function ItemInventory() {
	const token_api = React.useState(
		JSON.parse(localStorage.getItem("inventory_token_access")),
	);

	const [table_add_configuration, updateAddConfiguration] = React.useState(
		create_table_add_configuration,
	);

	function setFistState(array) {
		updateAddConfiguration(array);
	}

	return (
		<Layout>
			<div className="box container-fluid">
				<div className="container-title mb-3">
					<h1 className="container__title">{app_conf.title}</h1>
				</div>
				<TableCrud
					table_id_configuration={table_id_configuration}
					api_configuration={api_configuration}
					token={token_api[0]}
					table_add_configuration={table_add_configuration}
					updateAddConfiguration={updateAddConfiguration}
					setFistState={setFistState}
					table_first_conf={create_table_add_configuration}
					app_conf={app_conf}
				/>
			</div>
		</Layout>
	);
}

export { ItemInventory };
