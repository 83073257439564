import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import Box from "@material-ui/core/Box";

// components
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { TabsComponent } from "../../components/TabsComponent/TabsComponent";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { CustomSelect } from "../../components/customSelect/customSelect";
import { CustomImageViewer } from "../../components/CustomImageViewer/CustomImageViewer";
import no_image from "../../assets/img/no_image.png";
import { ImageInput } from "../../components/image_input";
import img from "../../assets/img/upload_image.jpg";

import Panel from '../../components/Panel'
import CategoryForm from '../categories/CategoryForm'
import BrandForm from '../brand/BrandForm'
import UomForm from '../uoms/UomForm'

//custom hooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";

import {
	typesAndValues as typesAndValuesFields,
	initData as initDataFields,
	PanelNames,
} from "./initForm";

import {
	styles,
	tableHeadersWorkOrderLocationTable as tableHeaders,
	dataFieldsWorkOrderLocationTable as dataFields,
	tableHeadersWorkOrderVendorsTable as tableHeadersVendorList,
	dataFieldsWorkOrderVendorsTable as dataFieldsVendorList,
	blockInvalidChar,
	setValueInArray,
	calculate,
} from "../../context/constants";
import MaterialUI from "../../components/MaterialUI";
import { ProductDetail } from "./ProductForm";
import ColorForm from "../Colors/ColorForm";
import { ExpensiveContextProvider } from "../../context/ExpensiveContext";
import { VendorForm } from "../Vendor/vendorForm/Form";

function UpdateProductForm() {
	const history = useHistory();
	const location = useLocation();

	const {
		showSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
	} = useModalHook();

	const {
		isLoading,
		getItem,
		updateItemForm,
		createItemDetail,
		getListItemWithCache,
	} = useApiCustomRequest(
		manageResponseErrors,
		"items",
		"Item",
		manageResponse,
		manageSuccessfullMesssage,
		true,
	);

	const [initData, setInitData] = useState({});
	const [typesAndValues, setTypesAndValues] = useState([]);
	const [locationsList, setLocationsList] = useState([]);
	const [initialLocationItem, setInitialLocationItem] = useState({});
	const [addItemToList, setAddItemToList] = useState(false);
	const [warehouseValue, setWarehouseValue] = useState([]);
	const [locationsValue, setLocationsValue] = useState([]);
	const [vendorsList, setVendorsList] = useState([]);
	const [sizes, setSizes] = useState([]);
	const [locationsFromApi, setLocationsFromApi] = useState([]);
	const [preview_image, setImagePreview] = useState(img);
	const [itemImageToAdd, setItemImageToAdd] = useState([]);
	const [itemImageToRemove, setItemImageToRemove] = useState([]);
	const [itemImageArray, setItemImageArray] = useState([]);
	const [currentImageToDelete, setCurrentImageToDelete] = useState(null);
	const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
	const [lastStatusItem, setLastStatusItem] = useState(false);
	const [locationQuantity, setLocationQuantity] = useState(false);
	const [reloadData, setReloadData] = useState(true);
	const [itemsVariants, setItemsVariants] = useState([]);
	const [locations, setLocations] = useState([]);
	const [warehousesList, setWarehouseList] = useState([]);

	useEffect(async () => {
		if (reloadData) {
			let typesAndValuesFieldsCopy = [...typesAndValuesFields];
			let initDataFieldsCopy = { ...initDataFields };
			let initId = location?.search?.split("=")[1];
			const infoItem = await getItem(location?.search?.split("=")[1], {}, true, true);
			if (!initId || (infoItem.hasOwnProperty("error") && infoItem.error)) {
				return history.push("/404");
			}
			const {
				name,
				cost,
				price,
				locations,
				images,
				active,
				total_loc_quantity,
				item_variants,
				part_code,
			} = infoItem;
			if(!!item_variants?.length) setItemsVariants(item_variants)
			const { id: brand_id } = infoItem?.brand_id || { id: null };
			const { id: category_id } = infoItem?.category_id || { id: null };
			const { id: vendor_id } = infoItem?.vendor_id || { id: null };
			const resources = await getListItemWithCache("get_resource_data", "Resources", {});
			const {
				brands,
				categories,
				uoms: uom,
				locations: allLocations,
				warehouses,
				vendors: allVendors,
			} = resources;
			setSizes(uom)
			setLocations(allLocations)
			setWarehouseList(warehouses)
			if (allLocations.length > 0) {
				setLocationsValue(allLocations);
			}
			if (warehouses.length > 0) {
				setWarehouseValue(warehouses);
			}
			if (brands.length > 0) {
				typesAndValuesFieldsCopy = setValueInArray(
					typesAndValuesFieldsCopy,
					"brand_id",
					"values",
					brands,
				);
			}
			if (!!categories.length) {
				typesAndValuesFieldsCopy = setValueInArray(typesAndValuesFieldsCopy, "category_id", "values", categories);
			}
			if (!!allVendors.length) {
				typesAndValuesFieldsCopy = setValueInArray(typesAndValuesFieldsCopy, "vendor_id", "values", allVendors);
			}

			initDataFieldsCopy = {
				name,
				part_code: part_code || '',
				cost,
				price,
				// sku,
				// upc,
				brand_id: brand_id || "",
				category_id: category_id || "",
				vendor_id: vendor_id || "",
				// uom_id: uom_id || "",
				active,
			};

			setLastStatusItem(active);
			setLocationsFromApi(locations || []);
			setInitData(initDataFieldsCopy);
			setItemImageArray(images || []);
			if (images && images.length > 0) {
				setImagePreview(images[0]?.path);
			}
			setTypesAndValues(typesAndValuesFieldsCopy);
			setLocationQuantity(Number(total_loc_quantity).toFixed(2));
			setReloadData(false);
		}
	}, [location?.search?.split("=")[1], reloadData]);

	const recordId = location?.search?.split("=")[1]

	const onChangeFieldsInitDataPartNumber = (ev) => {
		setInitData({ ...ev });
	};

	const onHandleSubmit = () => {};
	const buttonClick = () => {};

	// image input function
	const setItemImageToUpdate = (event, index) => {
		let preview = URL.createObjectURL(event.target.files[0]);
		setImagePreview(preview);
	};

	const onClickImage = (imgUrl, deleteImage = false) => {
		setImagePreview(imgUrl);
	};

	const viewDeleteImageModal = (imgUrl) => {
		setCurrentImageToDelete(imgUrl);
		setShowDeleteImageModal(true);
	};

	const hideDeleteImageModal = () => {
		setShowDeleteImageModal(false);
		setCurrentImageToDelete(null);
	};

	const deleteImageConfirm = () => {
		onDeleteImage(currentImageToDelete);
		setShowDeleteImageModal(false);
		setCurrentImageToDelete(null);
	};

	const onDeleteImage = (imgUrl) => {
		let deletedPath = "";

		const copyImages = [...itemImageArray];

		const copyImagesFiles = [...itemImageToAdd];

		const imageIndex = copyImages.findIndex(
			(image) => image.path === imgUrl,
		);

		const imageFileIndex = copyImagesFiles.findIndex(
			(file) => file.name === copyImages[imageIndex].id,
		);

		if (imageFileIndex >= 0) {
			copyImagesFiles.splice(imageFileIndex, 1);
		}
		setItemImageToAdd([...copyImagesFiles]);

		if (imageIndex >= 0) {
			deletedPath = copyImages[imageIndex].path;
			setItemImageToRemove([
				...itemImageToRemove,
				copyImages[imageIndex],
			]);
			copyImages.splice(imageIndex, 1);
		}

		setItemImageArray([...copyImages]);
		if (preview_image === deletedPath) {
			setImagePreview(copyImages[0]?.path || img);
		}
	};

	const transformArrayToObject = (array) => array.reduce((accumulator, value) => ({ ...accumulator, ...value }), {})

	//onSubmit
	const childFunc = useRef(null);
	const onCreateProduct = async () => {
		if (isLoading) return;
		const errors = childFunc.current(initData);
		if (!(errors.length > 0)) {
			let imagesArray = itemImageToAdd.map((itemFile, index) => {
				if (itemFile instanceof File) {
					return {
						[`new_images[${index}]`]: itemFile,
					};
				}
			});

			let itemImageToRemoveMap = itemImageToRemove.map(
				(imageItem, index) => ({
					[`removed_images[${index}]`]: imageItem.id.toString(),
				}),
			);

			const { active, ...extData } = initData;

			let request = {
				...extData,
				...transformArrayToObject(imagesArray),
				...transformArrayToObject(itemImageToRemoveMap),
				_method: "PUT",
			};

			let response = await updateItemForm(
				request,
				"Product",
				{},
				true,
				false,
				location?.search?.split("=")[1],
				null,
				true,
			);

			if (active != lastStatusItem) {
				await createItemDetail(
					{},
					"Product",
					`items/${location?.search?.split("=")[1]}/toggleStatus`,
					true,
				);
			}
			if (
				response &&
				response.hasOwnProperty("warraning") &&
				response.warraning
			) {
				manageResponseErrors(
					response?.warraning || "",
					"Product",
					"updating",
					true,
					"Warning",
				);
				setReloadData(true);
			}

			if (
				response &&
				response.data.id &&
				response.hasOwnProperty("warraning") &&
				!response.warraning
			) {
				history.push("/product_list");
			}
		}
	};

	const gotBack = () => history.push('/product_list');

	// 👉 Siblings
	const handleSiblings = (prop = {}) => {
		switch (prop.panel) {
			case PanelNames.PANEL_CATEGORY:
				togglePanel('category')
				break;
			case PanelNames.PANEL_BRAND:
				togglePanel('brand')				
				break;
			case PanelNames.PANEL_COLOR:
				togglePanel('colors')				
				break;
			case PanelNames.PANEL_UOM:
				togglePanel('uom')
			default:
				togglePanel(PanelNames.PANEL_VENDOR)
				break;
		}
	}
	
	// 👉 Panels
	const [panels, setPanels] = useState({category: false, brand: false, uom: false, colors: false});
  const togglePanel = (panel) => setPanels((prev) => ({ ...prev, [panel]: !prev[panel] }));

	const addRecordToCollection = (field_name, newRecord) => {
		const index = typesAndValues.findIndex((c) => c.field_name === field_name);
		if(index === -1) return;
		setTypesAndValues((prev) => {
			prev[index]['values'] = [...prev[index]['values'], newRecord];
			return prev;
		});
		setInitData((prev) => ({ ...prev, [field_name]: newRecord.id }));
	}
	
	const captureResponseCategory = (newRecord) => {
    if(!newRecord) return;
		addRecordToCollection('category_id', newRecord);
		togglePanel('category');
  }

	const captureResponseBrand = (newRecord) => {
    if(!newRecord) return;
		addRecordToCollection('brand_id', newRecord);
		togglePanel('brand');
  }

	const captureResponseUom = (newRecord) => {
    if(!newRecord) return;
		addRecordToCollection('uom_id', newRecord);
		togglePanel('uom');
  }

	const captureResponseVendor = ({ data }) => {
    const vendor = data.data;
    if(!vendor) return;
		addRecordToCollection('vendor_id', vendor);
		togglePanel(PanelNames.PANEL_VENDOR);
  }

	return (
		<>
		<ExpensiveContextProvider>
			<Panel headerTitle="Create Category" open={panels.category} togglePanel={() => togglePanel('category')}>
				<CategoryForm handleBehavior={captureResponseCategory}/>
			</Panel>
			<Panel headerTitle="Create Brand" open={panels.brand} togglePanel={() => togglePanel('brand')}>
				<BrandForm handleBehavior={captureResponseBrand}/>
			</Panel>
			<Panel headerTitle="Create Size" open={panels.uom} togglePanel={() => togglePanel('uom')}>
				<UomForm handleBehavior={captureResponseUom}/>
			</Panel>
			<Panel headerTitle="Create Color" open={panels.colors} togglePanel={() => togglePanel('colors')}>
				<ColorForm />
			</Panel>
			<Panel headerTitle="Create Vendor" open={panels.vendor} togglePanel={() => togglePanel(PanelNames.PANEL_VENDOR)}>
				<VendorForm behavior="NO_REDIRECT" handleBehavior={captureResponseVendor} />
			</Panel>
			<div className="contenedor container-fluid">
				<div className="d-flex gap-2 align-items-center">
					<MaterialUI.Button.Dark onClick={gotBack}>
						<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
					</MaterialUI.Button.Dark>
					<div className="container-title">
						<h1 className="container__title mb-0">{`Edit Product #${location?.search?.split("=")[1]}`}</h1>
					</div>
					<div className="action-buttons-container">
						<div className="save-buttton">
							<MaterialUI.Button.Success onClick={onCreateProduct}>
								<SaveOutlinedIcon fontSize="small" className="me-1" /> Save
							</MaterialUI.Button.Success>
						</div>
					</div>
				</div>
				<div className="row mb-2">
					<div className="col-12 col-12 col-lg-10">
						{typesAndValues.length > 0 &&
							Object.keys(initData).length > 0 && (
								<>
									<CustomForm
										onHandleFormClose={() => {}}
										onHandleSubmit={onHandleSubmit}
										typesAndValuesFields={typesAndValues}
										initData={initData}
										formTittle={""}
										dontShowCancelButton={true}
										id={null}
										getUrlPath={""}
										inputConf={{
											marginTop: "1",
											marginBottom: "0",
											stringCol: "col-12 col-sm-6"
										}}
										buttonClick={buttonClick}
										onChangeFields={onChangeFieldsInitDataPartNumber}
										initOnChangeFields={onChangeFieldsInitDataPartNumber}
										dontShowSubmitButton={true}
										childFunc={childFunc}
										dontShowErrosinOnChange={true}
										getObjectInfoOnChange={() => {}}
										notifyChange={() => {}}
										handlerSiblings={handleSiblings}
									/>
								</>
							)}
						<ProductDetail onUpdate={() => {}} items={itemsVariants} setItems={setItemsVariants} storeInline={Number(recordId)} locations={locations} warehouses={warehousesList} />
					</div>
					<div className="col-12 col-md-2">
						<ImageInput
							from_form={true}
							className="form-group mt-2 mb-2 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
							element_index={0}
							onElementChange={setItemImageToUpdate}
							element={{}}
							isEdition={false}
							preview_image={preview_image}
							setImagePreview={setImagePreview}
							setItemImageToAdd={setItemImageToAdd}
							itemImageToAdd={itemImageToAdd}
							setItemImageArray={setItemImageArray}
							itemImageArray={itemImageArray}
						/>
						{!!itemImageArray.length && (
							<Box
								sx={{
									overflowX: "auto",
									overflowY: "hidden",
									height: "150px",
								}}
							>
								<CustomImageViewer
									itemData={itemImageArray}
									onClickImage={onClickImage}
									onDeleteImage={viewDeleteImageModal}
								/>
							</Box>
						)}
					</div>
				</div>
			</div>
			<CustomModal
				show={showDeleteImageModal}
				customTittleText={"Delete image"}
				customMessageModal={
					"Are you sure you want to delete this image?"
				}
				onHandleClose={hideDeleteImageModal}
				onHandleSuccess={deleteImageConfirm}
				isLoading={isLoading}
				showSuccessButton={true}
			/>
			<CustomModal
				show={isLoading}
				customTittleText={""}
				customMessageModal={""}
				onHandleClose={() => {}}
				isLoading={isLoading}
			/>
			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowModal(false)}
				onHandleSuccess={() => {}}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
			/>
		</ExpensiveContextProvider>
		</>
	);
}

export { UpdateProductForm };
