import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { Card, Col } from "react-bootstrap";
import { CustomSelect } from "../../components/customSelect/customSelect";
import { amountPerPagePagination } from "../../context/constants";
import { ControlledOpenSelect } from "../../components/ButtonSelect/ButtonSelect";
import Button from "@material-ui/core/Button";
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import clsx from 'clsx';

import { useMenuActionHook } from "../../customHooks/useMenuActionHook";

import "./customFilterComponent.css";
import { useMediaQuery } from "@material-ui/core";

import { styled } from "@material-ui/core/styles";
import MaterialUI from "../MaterialUI";

const ButtonPrimary = styled(Button)({
	color: "#fff",
	backgroundColor: "#0d6efd",
	"&:hover": { backgroundColor: "#0b5ed7" }
});


function CustomFilterComponent(props) {
	const history = useHistory();
	const isMobile = useMediaQuery('(max-width:600px)');


	const {
		anchorEl,
		setAnchorEl,
		onMenuActions,
		onCloseModal,
		currentElement,
	} = useMenuActionHook();

	const [changeNumberPageSelect, setChangeNumberPageSelect] = useState({
		field_id: "per_page",
		selectTittle: "",
		styles: props.styles,
		defaultOptionText: "",
		itemArray: [
			// { id: '2', name: '2' },
			{ id: "10", name: "10" },
			{ id: amountPerPagePagination, name: amountPerPagePagination },
			// { id: '25', name: '25' },
			{ id: "50", name: "50" },
			{ id: "75", name: "75" },
			{ id: "100", name: "100" },
			{ id: "200", name: "200" },
		],
		defaultValue: amountPerPagePagination,
	});

	const onChangeSelectPage = (event) => {
		setChangeNumberPageSelect({
			...changeNumberPageSelect,
			defaultValue: event.value,
		});
		props.onChangePageEvent(event);
	};

	const [storedValue, setStoredValue] = useState();
	useEffect(() => {
        setStoredValue(localStorage.getItem("type_transaction_value"));
    }, [localStorage.getItem("type_transaction_value")]);

	return (
		<>
			{(props.filtersDefinition.length > 0 ||
				props.hasOwnProperty("mixFilter")) && (
					<Col xs="12" className="mb-3">
						<Card.Body className="border rounded-1 mb-0">
							<div
								data-bs-toggle="collapse"
								href="#collapseExample"
								role="button"
								aria-expanded="false"
								aria-controls="collapseExample"
								style={{ display: "flex", flexDirection: "row" }}
								className="align-items-center gap-2"
								onClick={props.onHandleOpenDropdown}
							>
								<i
									className="fas fa-filter text-muted"
									style={{ cursor: "pointer" }}
								></i>
								<h5 className="text-muted mb-0">Filters</h5>
							</div>
							<div className="collapse" id="collapseExample">
								<hr />
								<div className="row">
									{!!props.showDateFilters && (
										<>
											<div className="col-12 col-sm-6 col-lg-4 select-filter mb-3" style={{ textAlign: 'start' }}>
												<label
													className="form-label mb-0"
													style={props.styles}
												>
													Start date
												</label>
												<input
													type="date"
													className="form-control"
													onChange={(event) =>
														props.onChangeDateFilter(
															event.target.value,
															"date_from",
														)
													}
												/>
											</div>
											<div className="col-12 col-sm-6 col-lg-4 select-filter mb-3" style={{ textAlign: 'start' }}>
												<label
													className="form-label mb-0"
													style={props.styles}
												>
													End date
												</label>
												<input
													type="date"
													className="form-control"
													onChange={(event) =>
														props.onChangeDateFilter(
															event.target.value,
															"date_until",
														)
													}
												/>
											</div>
										</>
									)}

									{props.filtersDefinition.map((filter, index) => (
											<div key={index} className="col-12 col-sm-6 col-lg-4 select-filter mb-3" style={{ textAlign: 'start' }}>
											{props.renderFilter(filter)}
											</div>
										))}

									{props.filtersTextDefinition &&
										props.filtersTextDefinition.map((filterText, index) => (
										<div key={index} className="col-12 col-sm-6 col-lg-4 select-filter mb-3" style={{ textAlign: 'start' }}>
											{props.renderFilterText(filterText)}
										</div>
										))}
								</div>

								<div className="row">
								{props.hasOwnProperty("mixFilter") &&
									props.mixFilter
									.slice(0, 2)
									.map((filter, index) => (
										<div key={index} className="col-12 col-sm-6 col-lg-4 select-filter mb-3" style={{ textAlign: 'start' }}>
										{filter.typeFilter === "select" ? 
											props.renderFilter(filter, index) :
											props.renderFilterText(filter, index)}
										</div>
									))}
								</div>
								<div className="row">
								{props.hasOwnProperty("mixFilter") &&
									props.mixFilter
									.slice(2)
									.map((filter, index) => (
										<div key={index} className="col-12 col-sm-6 col-lg-4 select-filter mb-3" style={{ textAlign: 'start' }}>
										{filter.typeFilter === "select" ? 
											props.renderFilter(filter, index) :
											filter.typeFilter === "input" ?
											props.renderFilterText(filter, index) :
											props.renderSearchInputText(filter, index)}
										</div>
									))}
								</div>


								<div className="btn-wrapper">
									<MaterialUI.Button.Primary size="small" onClick={props.onClickfilter}>
										<span className="text-white fs-semi-small">
											<SearchTwoToneIcon fontSize="small" className="me-1" />Filter
										</span>
									</MaterialUI.Button.Primary>
								</div>
							</div>
						</Card.Body>
					</Col>
			)}

			<div
				className="filters_options_container"
			>
				<div className="filters__options__search__and__per__page">
					{!props.dontShowPerPageSelect && (
						<div>
							<div
								className="input-group"
								style={{ flexWrap: "nowrap", alignItems: "center" }}
							>
								<div
									className="input-group-text py-0 text-muted"
									id="btnGroupAddon"
									style={{
										border: "none",
										background: "none",
										paddingLeft: "0",
									}}
								>
									Show
								</div>
								<CustomSelect
									field_id={changeNumberPageSelect.field_id}
									selectTittle={
										changeNumberPageSelect.selectTittle
									}
									styles={changeNumberPageSelect.styles}
									defaultOptionText={
										changeNumberPageSelect.defaultOptionText
									}
									itemArray={changeNumberPageSelect.itemArray}
									onChangeEvent={onChangeSelectPage}
									selectWidth={"min-content"}
									selectCol={"col-none"}
									defaultValue={
										changeNumberPageSelect.defaultValue
									}
									dontDefaultOption={true}
									selectClass="form-select form-select-sm"
								/>
								<div
									className="input-group-text entries-elemt text__entries text-muted"
									id="btnGroupAddon"
									style={{ border: "none", background: "none " }}
								>
									Entries
								</div>
							</div>
						</div>
					)}
					<div className="custom_input" >
						<CustomInput
							inputId={"inputPassword6"}
							describedByArea={"passwordHelpInline"}
							spanText={"Enter a letter to filter"}
							labelText={ !isMobile ? "Filter" : null}
							inputOnChange={props.filterInputChange}
						/>
					</div>
				</div>
				{props.hasOwnProperty("showDeletedButton") &&
					props?.showDeletedButton && (
						<div style={{ padding: "0 50px" }}>
							<button
								className="btn btn-sm btn-secondary mt-1 mb-0"
								onClick={() =>
									history.push("/company_list_deleted")
								}
							>
								Deleted
							</button>
						</div>
					)}
				{/* test -> filter buttons */}
				<div className="showFilterButtons_and_showSelectButton-container">
					
					{props.showFilterButtons && (
						<div
							className="showFilterButtons_containers"
						>
							<CustomButton
								buttonName={"Invoice"}
								buttonOnClick={() => {
									props.selectOnChange({
										field_id: "type_transaction",
										value: "invoice",
									});
									props.setSincronizedItems(false);
								}}
								type={"-"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
								className={clsx('btn btn-sm lh-0 rounded-pill px-2', storedValue === "invoice" ? 'btn-primary' : 'btn-outline-primary')}
							/>
							<CustomButton
								buttonName={"Estimate"}
								buttonOnClick={() => {
									props.selectOnChange({
										field_id: "type_transaction",
										value: "estimate",
									});
									props.setSincronizedItems(false);
								}}
								type={"-"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
								className={clsx('btn btn-sm lh-0 rounded-pill px-2', storedValue === "estimate" ? 'btn-primary' : 'btn-outline-primary')}
							/>
							<CustomButton
								buttonName={"Work Order"}
								buttonOnClick={() => {
									props.selectOnChange({
										field_id: "type_transaction",
										value: "work order",
									});
									props.setSincronizedItems(false);
								}}
								type={"-"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
								className={clsx('btn btn-sm lh-0 rounded-pill px-2', storedValue === "work order" ? 'btn-primary' : 'btn-outline-primary')}
							/>
							<CustomButton
								buttonName={"Payment"}
								buttonOnClick={() => {
									props.selectOnChange({
										field_id: "type_transaction",
										value: "payment",
									});
									props.setSincronizedItems(false);
								}}
								type={"-"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
								className={clsx('btn btn-sm lh-0 rounded-pill px-2', storedValue === "payment" ? 'btn-primary' : 'btn-outline-primary')}
							/>
						</div>
					)}

					{!!props.showSelectButton && (
								<div className="float-end mt-1 mb-0 align-self-end">
									<ControlledOpenSelect
										actionsDefinition={[
											{
												name: "Invoice",
												urlRedirect: "/invoice_form",
											},
											{
												name: "Estimate",
												urlRedirect: "/estimate_form",
											},
											{
												name: "Work Order",
												urlRedirect: "/work_order_form",
											},
											{
												name: "Payment",
												urlRedirect: "/payment_form",
											},
										]}
										anchorEl={anchorEl}
										setAnchorEl={setAnchorEl}
										handleClose={onCloseModal}
										handleClick={(event) =>
											onMenuActions(event, null)
										}
										title={"Create Transaction"}
									/>
								</div>
					)}
				</div>

					{!props.dontShowCreateButton && (
						<div
							className="btn_add_container ms-0"
						>
							<ButtonPrimary 
								variant="contained"
								size="small"
								onClick={props.onCreateNewItem}
								style={{ textTransform: 'capitalize' }}
							>
								<i
									className="fas fa-plus"
									style={{ marginRight: "5px" }}
								></i>
								Add
							</ButtonPrimary>
						</div>
					)}
			</div>
		</>
	);
}

export { CustomFilterComponent };
