import React from "react";
import { ItemContext } from "../ItemProviders";
import { TextInput } from "../text_input";
import { ImageInput } from "../image_input";
import { ArrayInput } from "../array_input";
import Layout from "../../components/Layout/Layout";
import { CheckBoxInput } from "../checkbox_input";
import { ActivityIndidicator } from "../../components/CircularProgres/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { useHistory, useLocation } from "react-router-dom";
import { CustomImageViewer } from "../../components/CustomImageViewer/CustomImageViewer";
import Box from "@material-ui/core/Box";
import img from "./../../assets/img/upload_image.jpg";

const SUCCESSFULL_MESSAGE = (action = "created") => {
	return `Item has been ${action} successfully`;
};

const CREATE_OR_UPDATE_MESSAGE = (action = "ADD") => {
	return `${action} Product`;
};

const ERROR_MESSAGE = "An error has occured";

function FormAddElements(props) {
	const history = useHistory();
	const location = useLocation();

	const {
		table_add_configuration,
		updateAddConfiguration,
		verifyErrors,
		storeNewItem,
		isLoading,
		successfullResponse,
		showModalResponse,
		setShowModalResponse,
		responseMessageError,
		setSincronizedItems,
		create_table_add_configuration,
		getItem,
		updateItem,
		setItemImageArray,
		itemImageArray,
		setItemImageToRemove,
		itemImageToRemove,
		itemImageToAdd,
		setItemImageToAdd,
		updateTable,
		setSkuNumber,
	} = React.useContext(ItemContext);

	const [preview_image, setImagePreview] = React.useState(img);
	const [showDeleteImageModal, setShowDeleteImageModal] =
		React.useState(false);

	function replaceLetters(event) {
		let new_value = event.target.value;
		event.target.value = new_value.replace(/[^\d]/g, "");
		return event;
	}

	function replaceLettersInTextField(event) {
		let new_value = event.target.value;
		const regex = /[^A-Za-z0-9]+/g;
		event.target.value = new_value.replace(regex, "");
		return event;
	}

	const setItemToUpdate = (event, index) => {
		let new_array = [...table_add_configuration];
		new_array[index].value = event.target.value;
		if (event.target.value) {
			new_array[index].have_error = false;
		} else {
			new_array[index].have_error = true;
		}
		// updateAddConfiguration(new_array);
		updateTable(new_array);
	};

	const setItemToUpdateCheckBox = (current_array, index) => {
		let new_array = [...table_add_configuration];

		if (new_array[index].value !== undefined) {
			new_array[index].value = current_array;
			new_array[index].have_error = false;
			// updateAddConfiguration(new_array);
			updateTable(new_array);
		} else {
			new_array[index].have_error = true;
		}
	};

	const setItemNumberToUpdate = (event, index) => {
		setItemToUpdate(replaceLetters(event), index);
	};

	const setItemTextToUpdate = (event, index) => {
		setItemToUpdate(replaceLettersInTextField(event), index);
	};

	const setItemImageToUpdate = (event, index) => {
		if (event.target.files.length > 0) {
			let new_array = [...table_add_configuration];
			new_array[index].value = event.target.files[0];
			new_array[index].have_error = false;
			// updateAddConfiguration(new_array);
			updateTable(new_array);
		}
	};

	const sendAddRequest = async () => {
		if (verifyErrors()) {
			const currentSku =
				table_add_configuration[
					table_add_configuration.findIndex(
						(field) => field.field_id === "sku",
					)
				].value;

			const currentCost =
				table_add_configuration[
					table_add_configuration.findIndex(
						(field) => field.field_id === "cost",
					)
				].value;

			if (
				location.search.split("=")[1] &&
				location.search.split("=")[0] === "?product_id"
			) {
				updateItem(location.search.split("=")[1]);
			} else {
				await storeNewItem();
			}
			setSincronizedItems(false);

			returnToStockReceiptModule(currentSku, currentCost);
		}
	};

	const returnToStockReceiptModule = (
		currentSku = null,
		currentCost = null,
	) => {
		if (props.hasOwnProperty("onHandleCancelButton")) {
			props.onHandleCancelButton(currentSku, currentCost);
		}
	};

	const customTitleModal = successfullResponse ? "" : ERROR_MESSAGE;
	const customMessageModal = successfullResponse
		? SUCCESSFULL_MESSAGE(
				location.search.split("=")[1] ? "updated" : "created",
		  )
		: responseMessageError;

	const onHandleClose = () => {
		setShowModalResponse(false);
		setSincronizedItems(false);
		if (!props.dontReloadToMainPage) {
			!!successfullResponse
				? history.push("/add_item")
				: setShowModalResponse(false);
		}
	};

	const [isEdition, setIsEdition] = React.useState(false);

	React.useEffect(async () => {
		if (location.search.split("=")[1]) {
			if (location.search.split("=")[0] === "?product_id") {
				getItem(location.search.split("=")[1]);
				setIsEdition(true);
			}
		} else {
			if (props.skuNumber) setSkuNumber(props.skuNumber);

			updateTable(create_table_add_configuration);
			setItemImageToRemove([]);
			setItemImageToAdd([]);
			setItemImageArray([]);
		}
	}, []);

	const onClickImage = (imgUrl, deleteImage = false) => {
		setImagePreview(imgUrl);
	};

	const onDeleteImage = (imgUrl) => {
		let deletedPath = "";

		const copyImages = [...itemImageArray];

		const copyImagesFiles = [...itemImageToAdd];

		const imageIndex = copyImages.findIndex(
			(image) => image.path === imgUrl,
		);

		const imageFileIndex = copyImagesFiles.findIndex(
			(file) => file.name === copyImages[imageIndex].id,
		);

		if (imageFileIndex >= 0) {
			copyImagesFiles.splice(imageFileIndex, 1);
		}
		setItemImageToAdd([...copyImagesFiles]);

		if (imageIndex >= 0) {
			deletedPath = copyImages[imageIndex].path;
			setItemImageToRemove([
				...itemImageToRemove,
				copyImages[imageIndex],
			]);
			copyImages.splice(imageIndex, 1);
		}

		setItemImageArray([...copyImages]);
		if (preview_image === deletedPath) {
			setImagePreview(copyImages[0]?.path || img);
		}
	};

	const [currentImageToDelete, setCurrentImageToDelete] =
		React.useState(null);

	const viewDeleteImageModal = (imgUrl) => {
		setCurrentImageToDelete(imgUrl);
		setShowDeleteImageModal(true);
	};

	const hideDeleteImageModal = () => {
		setShowDeleteImageModal(false);
		setCurrentImageToDelete(null);
	};

	const deleteImageConfirm = () => {
		onDeleteImage(currentImageToDelete);
		setShowDeleteImageModal(false);
		setCurrentImageToDelete(null);
	};

	return (
		<>
			{/* <Layout> */}
			<CustomModal
				show={showDeleteImageModal}
				customTittleText={"Delete image"}
				customMessageModal={
					"Are you sure you want to delete this image?"
				}
				onHandleClose={hideDeleteImageModal}
				onHandleSuccess={deleteImageConfirm}
				isLoading={isLoading}
				showSuccessButton={true}
			/>
			{/* loadingModal */}
			<CustomModal
				show={isLoading}
				customTittleText={""}
				customMessageModal={""}
				onHandleClose={() => {}}
				isLoading={isLoading}
			/>
			{/* Response service Modal */}
			<CustomModal
				show={showModalResponse}
				customTittleText={customTitleModal}
				customMessageModal={customMessageModal}
				onHandleClose={onHandleClose}
			/>
			<div className={`container-fluid ${isLoading && "add-display"}`}>
				<div className="d-flex justify-content-center">
					<div className="card col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-7 ms-2 me-2">
						<div className="card-header">
							<h3>
								{" "}
								{CREATE_OR_UPDATE_MESSAGE(
									location.search.split("=")[1]
										? "Update"
										: "Add",
								)}{" "}
							</h3>
						</div>

						<div className="card-body">
							<div className="row">
								{table_add_configuration.map(
									(element, index) => {
										if (element.type === "text") {
											return (
												<TextInput
													className="form-group mt-2 mb-2 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
													element_index={index}
													onElementChange={
														setItemTextToUpdate
													}
													key={index}
													element={element}
												/>
											);
										}

										if (element.type === "number") {
											return (
												<TextInput
													className="form-group mt-2 mb-2 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
													element_index={index}
													onElementChange={
														setItemNumberToUpdate
													}
													key={index}
													element={element}
												/>
											);
										}

										if (element.type === "array") {
											return (
												<ArrayInput
													className="form-group mt-2 mb-2 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
													element_index={index}
													onElementChange={
														setItemToUpdate
													}
													key={index}
													element={element}
												/>
											);
										}

										if (element.type === "array_checkbox") {
											return (
												<CheckBoxInput
													className="form-group mt-2 mb-2 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
													element_index={index}
													onElementChange={
														setItemToUpdateCheckBox
													}
													key={index}
													element={element}
												/>
											);
										}
									},
								)}
							</div>
						</div>
					</div>
					<div
						hidden={
							table_add_configuration.find(
								(element) => element.type === "img",
							) !== undefined
								? false
								: true
						}
						className="card col-12 col-sm-12 col-md-12 col-lg-4 col-xl-5 col-xxl-5 ms-2 me-2"
					>
						<div className="card-header">
							<h3> Image </h3>
						</div>

						<div className="card-body">
							<div className="row">
								{table_add_configuration.map(
									(element, index) => {
										if (element.type === "img") {
											return (
												<ImageInput
													from_form={true}
													className="form-group mt-2 mb-2 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
													element_index={index}
													onElementChange={
														setItemImageToUpdate
													}
													key={index}
													element={element}
													isEdition={isEdition}
													preview_image={
														preview_image
													}
													setImagePreview={
														setImagePreview
													}
													setItemImageToAdd={
														setItemImageToAdd
													}
													itemImageToAdd={
														itemImageToAdd
													}
													setItemImageArray={
														setItemImageArray
													}
													itemImageArray={
														itemImageArray
													}
												/>
											);
										}
									},
								)}
							</div>
							<Box sx={{ overflow: "scroll", height: "450px" }}>
								<CustomImageViewer
									itemData={itemImageArray}
									onClickImage={onClickImage}
									onDeleteImage={viewDeleteImageModal}
								/>
								{/* onDeleteImage={onDeleteImage} */}
							</Box>
						</div>
					</div>
				</div>
				<div className="float-end">
					{!!props.showCancelButton && (
						<button
							onClick={props.handleCloseAdd}
							className="btn btn-outline-success mr-3 mt-3"
						>
							Cancel
						</button>
					)}
					<button
						onClick={sendAddRequest}
						className="btn btn-outline-success ml-3 mt-3"
					>
						Save
					</button>
				</div>
			</div>
			{/* </Layout> */}
		</>
	);
}

function FormAdd() {
	return (
		<Layout>
			<FormAddElements />
		</Layout>
	);
}

export { FormAdd, FormAddElements };
