import React from "react";
import { Box, Grid } from "@material-ui/core";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

import AlertComponent from "../../components/Alert";
import Material from "../../components/MaterialUI";
import useColors from "./hooks/useColors";


const ColorForm = ({ handleBehavior }) => {
	const { color, setColor, store, setErrors, errors, isLoading } = useColors();
	const [showAlert, setShowAlert] = React.useState(false);
  const [palletColor, setPalletColor] = useColor("#561ecb");

	const handleClose = (_, reason) => {
		if (reason === "clickaway") return;
		setShowAlert(false);
	};

	const handleChange = (key, value) => {
		setErrors((prev) => ({ ...prev, [key]: "" }));
		setColor((state) => ({ ...state, [key]: value }));
	};

  const isValid = () => {
    const _errors = {}
    if(!color.name.trim().length) _errors.name = "This field is required"
    setErrors(_errors)
    return !Object.keys(_errors).length
  }
  
	const handleSubmit = async (ev) => {
		ev.preventDefault();
		if (isLoading || !isValid()) return;
		try {
			const resp = await store(color);
			if (resp) setShowAlert(true);
			if (handleBehavior && typeof handleBehavior === "function")
				handleBehavior(resp);
		} catch (error) {}
	};

	return (
		<Box p={2}>
			<AlertComponent
				open={showAlert}
				handleClose={handleClose}
				severity="success"
				message="It has been successfully created"
			/>
			<form noValidate autoComplete="off" onSubmit={handleSubmit}>
				<Grid container spacing={3} justifyContent="center">
					<Grid item xs={12}>
						<Material.Input
							label={"Name"}
							state={color.name}
							setState={(value) => handleChange("name", value)}
							error={errors.name}
						/>
					</Grid>
					<Grid item xs={12}>
            <ColorPicker color={palletColor} hideInput={["rgb", "hsv"]} onChange={setPalletColor} />
          </Grid>
					<Grid item xs={12}>
						<Material.Button.Success
							type="submit"
							fullWidth
							variant="contained"
							disabled={isLoading}
						>
							<SaveOutlinedIcon
								fontSize="small"
								className="me-2"
							/>
							{isLoading ? "Saving..." : "Save"}
						</Material.Button.Success>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default ColorForm;
