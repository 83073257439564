import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import clsx from 'clsx';

import MaterialUI from '../MaterialUI';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    placeContent: 'center',
    inlineSize: '100vw',
    blockSize: '100vh',
    position: 'fixed',
    left: '0',
    top: '0',
    background: 'rgb(0 0 0 / 20%)',
    zIndex: '1099',
    fontFamily: '\'Inter\''
  },
  alert: {
    inlineSize: '450px',
    background: '#fff',
    padding: '1.5rem 1.25rem',
    borderRadius: '.3rem',
    boxShadow: '0 0 3px rgb(255 255 255 / 50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    color: 'rgb(73 80 87)',
    [theme.breakpoints.down('xs')]: {
      inlineSize: '98%',
    }
  },
  icon: { fontSize: '6rem', marginBottom: '.75rem' },
  iconQuestion: { color: 'rgb(13 202 240)' },
  iconSuccess: { color: 'rgb(32 201 151)' },
  iconAlert: { color: 'rgb(255 193 7)' },
}));

const AlertActions = ({
  render,
  icon,
  title,
  message,
  handler,
  destroy,
  buttonTextConfirm = 'Confirm'
}) => {
  const classes = useStyles();

  const handleConfirm = () => {
    if(handler && typeof handler === 'function') handler('confirm');
  };

  const handleCancel = () => {
    if(handler && typeof handler === 'function') handler('cancel');
  };

  if(!render) return null;
  return (
    <div className={classes.root}>
      <div className={classes.alert}>
        {icon === 'question' && <HelpOutlineIcon className={clsx(classes.icon, classes.iconQuestion)} />}
        {icon === 'success' && <CheckCircleOutlineIcon className={clsx(classes.icon, classes.iconSuccess)} />}
        {icon === 'alert' && <ReportProblemRoundedIcon className={clsx(classes.icon, classes.iconAlert)} />}
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" className='mb-4 text-center'>
          { !!title && <h3 className='mb-2'>{title}</h3>}
          { !!message && <div className='lh-1'>{message}</div>}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" className='gap-2'>
          {/* <MaterialUI.Button.Primary onClick={handleConfirm}>OK</MaterialUI.Button.Primary> */}
          {destroy ? (<>
            <MaterialUI.Button.Danger onClick={handleConfirm}>{buttonTextConfirm}</MaterialUI.Button.Danger>
            <MaterialUI.Button.Secondary onClick={handleCancel}>Cancel</MaterialUI.Button.Secondary>
          </>) : (<>
            <MaterialUI.Button.Primary onClick={handleConfirm}>{buttonTextConfirm}</MaterialUI.Button.Primary>
            <MaterialUI.Button.Danger onClick={handleCancel}>Cancel</MaterialUI.Button.Danger>
          </>)}
        </Box>
      </div>
    </div>
  );
};

export default AlertActions;
