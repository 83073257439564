import { appLocalStorage } from "../utils/localStorage";
import { LOCAL_STORAGE_APP_CONTEXT_DATA_VAR_NAME } from "../utils/constants";

export const addDaysToCurrentDay = (currentDate, daysToAdd) => {
	var copiedDate = new Date(currentDate.getTime());
	return new Date(
		copiedDate.setDate(copiedDate.getDate() + daysToAdd),
	).toLocaleDateString("en-CA", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
	});
};

export const setValueInArray = (array, fieldId, elementToUpdate, value) => {
	const arrayCopy = [...array];
	const idFieldIndex = arrayCopy.findIndex(
		(item) => item.field_name === fieldId,
	);
	let item = arrayCopy[idFieldIndex];
	item[`${elementToUpdate}`] = value;
	arrayCopy[idFieldIndex] = item;
	return arrayCopy;
};

export const validateLength = (array) => {
	return array.length > 0;
};

export const blockInvalidChar = (e) =>
	["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const LOCAL_STORAGE_APP_STATE = appLocalStorage.getValue(
	LOCAL_STORAGE_APP_CONTEXT_DATA_VAR_NAME,
);

export const USER_INITIAL_STATE = {
	userId: "",
};

export const convertValue = (str) => {
	return str.replace(",", "");
};

export const calculate = (str) => {
	return parseFloat(convertValue(str)).toFixed(2);
};

export const formatDate = (date) => {
	return new Date(date).toLocaleDateString("en-CA", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		timeZone: "UTC",
	});
};

export const formatDate2 = (
	date,
	type = "en-CA",
	options = {
		year: "numeric",
		month: "long",
		day: "numeric",
	},
) => new Date(date).toLocaleDateString(type, options);

export const mapResponse = (response, composFields, dateFields) => {
	let object = {};
	Object.keys(response).map((key) => {
		if (Object.keys(composFields).includes(key)) {
			object[composFields[key]] = response[key]?.id.toString();
		} else if (Object.keys(dateFields).includes(key)) {
			console.log("response[key]", response[key]);
			object[dateFields[key]] = formatDate(response[key]);
		} else {
			object[key] = response[key];
		}
	});
	return object;
};

export const mapPaymentResponse = (response, composFields, dateFields) => {
	let object = {};
	const [data] = response;
	if (data) {
		Object.keys(data).map((key) => {
			if (Object.keys(dateFields).includes(key)) {
				object[dateFields[key]] = formatDate(data[key]);
			} else {
				object[key] = data[key];
			}
		});
	}
	return object;
};

export const mapRequest = (bodyInclude, bodyExt) => {
	let bodyRequest = {};
	Object.keys(bodyExt).map((key) => {
		if (bodyInclude.includes(key)) {
			bodyRequest[key] = bodyExt[key];
		}
	});
	return bodyRequest;
};

export const defineColorStatus = (stockReceiptStatus) => {
	if (stockReceiptStatus === ENTRY_STATE) return "#007bff";
	if (stockReceiptStatus === COMPLETE_STATE) return "#28a745";
	if (stockReceiptStatus === CANCEL_STATE) return "#dc3545";
	if (stockReceiptStatus === POSTED_STATE) return "#6c757d";
	if (stockReceiptStatus === INVOICE_PAID_STATE) return "#6c757d";
	if (stockReceiptStatus === ESTIMATE_PENDING_STATE) return "#6c757d";
	if (stockReceiptStatus === WORK_ORDER_CLOSE_STATE) return "#6c757d";
	if (stockReceiptStatus === WORK_ORDER_OPEN_STATE) return "#007bff";
};

export const enabledState = "enabled";
export const disabledState = "disabled";
export const amountPerPagePagination = "25";

export const ENTRY_STATE = "Entry";
export const COMPLETE_STATE = "Complete";
export const CANCEL_STATE = "Cancel";
export const POSTED_STATE = "Posted";
export const POSTED_ITEM_STATE = "Posted";
export const UNPOSTED_ITEM_STATE = "Unposted";

export const INVOICE_ENTRY_STATE = "Entry";
export const INVOICE_PAID_STATE = "Paid";
export const INVOICE_COMPLETE_STATE = "Complete";
export const INVOICE_PENDING_STATE = "Pending";

export const ESTIMATE_ENTRY_STATE = "Entry";
export const ESTIMATE_PENDING_STATE = "Pending";
export const ESTIMATE_COMPLETE_STATE = "Complete";

export const WORK_ORDER_CLOSE_STATE = "Close";
export const WORK_ORDER_OPEN_STATE = "Open";

export const regexLettersAndNumber = /^([a-zA-Z0-9 _-]+)$/;
export const regexLettersAndNumberAndEmptySpace = /^[A-Za-z0-9 ]*$/;
export const regexNumbers = /^\d+$/;

export const findRoleInUser = (userData) => {
	let roleFinded = false;
	if (userData && userData.hasOwnProperty("roles")) {
		userData.roles.map((rol) => {
			if (rol.name === "admin") {
				roleFinded = true;
			}
		});
	}

	return roleFinded;
};

const centered = { justifyContent: 'center' }

export const tableHeaders = [
	{ name: "#", width: "50px", style: { ...centered } },
	{ name: "Imagen", width: "110px", style: { ...centered } },
	{ name: "Product Name", showOrderActions: true },
	// { name: "Sku", width: "100px" },
	{ name: "Stock", width: "90px" },
	{ name: "Details", width: "100px" },
	{ name: "Cost", showOrderActions: true, width: "100px" },
	{ name: "Price", showOrderActions: true, width: "100px" },
	{ name: "Status", width: "90px" },
	{ name: "Category", showOrderActions: true, width: "120px" },
	{ name: "Brand", showOrderActions: true, width: "120px" },
	{ name: "Actions", width: "100px", style: { ...centered } },
];

export const dataFields = [
	{ field_name: "id", style: { textAlign: 'center' } },
	{
		field_name: "image_",
		renderComponent: true,
		renderType: "imageAvatar",
	},
	{ field_name: "name" },
	// { field_name: "sku" },
	{ field_name: "total_loc_quantity" },
	{
		field_name: "details",
		renderComponent: true,
	},
	{ field_name: "cost" },
	{ field_name: "price" },
	{ field_name: "active" },
];

export const imageFields = [{ field_name: "images" }];

export const composedFields = [
	{ field_name: "category_id" },
	{ field_name: "brand_id" },
];

export const tableHeadersToDataFieldsProducts = {
	"Product Name": "name",
	Cost: "cost",
	Price: "price",
	Category: "category_id",
	Brand: "brand_id",
};

export const dataFieldsToOrderFilterProducts = {
	name: "name",
	cost: "cost",
	price: "price",
	category_id: "category",
	brand_id: "brand",
};

export const tableHeadersToOrderFilterProducts = {
	name: "Product Name",
	cost: "Cost",
	price: "Price",
	category: "Category",
	brand: "Brand",
};

const mapObject = {
	typesVendors: "vendors",
	typesLocations: "locations",
	typesStatus: "active",
	typesUom: "uom_id",
	typesCategory: "category_id",
	typesBrand: "brand_id",
	typesWare: "brand_id",
};

export const parseFilters = (table_add_configuration) => {
	let filterObject = {};

	Object.keys(mapObject).map((objectItem, index) => {
		filterObject[objectItem] = table_add_configuration.find(
			(tableItem) => tableItem.field_id === mapObject[objectItem],
		).array_values;
	});

	return filterObject;
};

export const received = {
	border: "none",
	background: "#4D7BF3",
	padding: "5px",
	borderRadius: "10px",
	color: "white",
	fontWeight: "bold",
	fontSize: ".8rem",
};

export const complete = {
	border: "none",
	background: "#12DE3F",
	padding: "5px",
	borderRadius: "10px",
	color: "white",
	fontWeight: "bold",
	fontSize: ".8rem",
};

export const posted = {
	border: "none",
	background: "#F34D4D",
	padding: "5px",
	borderRadius: "10px",
	color: "white",
	fontWeight: "bold",
	fontSize: ".8rem",
	minWidth: "67px",
};

export const styles = {
	fontSize: "14px",
	fontWeight: "bold",
};

export const tableHeadersStockReceipt = [
	{ name: "Bill Number", width: "150px", showOrderActions: true },
	{ name: "Date Due", width: "150px", showOrderActions: true },
	{ name: "Date Receipt", width: "150px", showOrderActions: true },
	{ name: "Description" },
	{ name: "Status", width: "100px", showOrderActions: true },
	{ name: "Type", width: "100px", showOrderActions: true },
	{ name: "Total", width: "100px", showOrderActions: true },
	{ name: "Actions", width: "100px", style: { ...centered } },
];

export const dataFieldsStockReceipt = [
	{ field_name: "bill_number" },
	{ field_name: "date_due" },
	{ field_name: "date_receipt" },
	{ field_name: "description" },
	{ field_name: "status" },
	{ field_name: "type",  style: { textAlign: 'center' }},
	{ field_name: "total",  style: { textAlign: 'center' }},
];

export const tableHeadersToDataFieldsStockReceipt = {
	"Bill Number": "bill_number",
	"Date Due": "date_due",
	"Date Receipt": "date_receipt",
	Status: "status",
	Type: "type",
	Total: "total",
};

export const dataFieldsToOrderFilterEStockReceipt = {
	bill_number: "bill_number",
	date_due: "date_due",
	date_receipt: "date_receipt",
	status: "status",
	type: "type",
	total: "total",
};

export const tableHeadersToOrderFilterStockReceipt = {
	bill_number: "Bill Number",
	date_due: "Date Due",
	date_receipt: "Date Receipt",
	status: "Status",
	type: "Type",
	total: "Total",
};

export const composedFieldsStockReceipt = [
	// { field_name: 'warehouse' },
];

export const tableHeadersUsers = [
	{ name: "Id" },
	{ name: "Name", showOrderActions: true },
	{ name: "Email", showOrderActions: true },
	{ name: "Phone", showOrderActions: true },
	{ name: "Status", showOrderActions: true },
	{ name: "Wharehouse" },
	{ name: "Company" },
	{ name: "Role" },
	{ name: "Actions" },
];

export const dataFieldsUsers = [
	{ field_name: "id" },
	{ field_name: "name" },
	{ field_name: "email" },
	{ field_name: "phone_number" },
	{ field_name: "status" },
	{ field_name: "warehouse_name" },
	{ field_name: "company_name" },
	{ field_name: "roles_name" },
];

export const composedFieldsUsers = [
	{ field_name: "warehouse" },
	{ field_name: "company" },
];

export const arrayFieldsUsers = [{ field_name: "roles", position_array: 0 }];

export const tableHeadersToDataFieldsUserManagment = {
	Name: "name",
	Email: "email",
	Phone: "phone_number",
	Status: "status",
};

export const dataFieldsToOrderFilterUserManagment = {
	name: "name",
	email: "email",
	phone_number: "phone_number",
	status: "status",
};

export const tableHeadersToOrderFilterUserManagment = {
	name: "Name",
	email: "Email",
	phone_number: "Phone",
	status: "Status",
};

//Ajustment
export const tableHeadersAjustment = [
	{ name: "#" },
	{ name: "Status", showOrderActions: true },
	{ name: "Posted", showOrderActions: true },
	{ name: "Post Date", showOrderActions: true },
	{ name: "Warehouse", showOrderActions: true },
	{ name: "User ID", showOrderActions: true },
	{ name: "Actions" },
];

export const dataFieldsAjustment = [
	{ field_name: "id" },
	{ field_name: "status" },
	{ field_name: "tx_posted" },
	{ field_name: "postdate" },
];

export const tableHeadersToDataFieldsAdjustment = {
	Status: "status",
	Posted: "tx_posted",
	"Post Date": "postdate",
	Warehouse: "warehouse",
	"User ID": "user",
};

export const dataFieldsToOrderFilterAdjustment = {
	status: "status",
	tx_posted: "tx_posted",
	postdate: "postdate",
	warehouse: "warehouse_id",
	user: "user_id",
};

export const tableHeadersToOrderFilterAdjustment = {
	status: "Status",
	tx_posted: "Posted",
	postdate: "Post Date",
	warehouse_id: "Warehouse",
	user_id: "User ID",
};

export const composedFieldsAjustment = [
	{ field_name: "warehouse" },
	{ field_name: "user" },
];

export const arrayFieldsAjustment = [];
//Ajustment

//Invoice
export const tableHeadersInvoice = [
	{ name: "Id", showOrderActions: true, width: "50px" },
	{ name: "Date", showOrderActions: true, width: "11%" },
	{ name: "Type", showOrderActions: true, width: "11%" },
	{ name: "Client", showOrderActions: true },
	{ name: "Due date", showOrderActions: true, width: "11%" },
	{ name: "Balance", showOrderActions: true, width: "11%" },
	{ name: "Total", showOrderActions: true, width: "11%" },
	{ name: "Status", showOrderActions: true, width: "11%" },
	{ name: "Actions", showOrderActions: false, width: "100px", style: { justifyContent: 'center' } },
];

export const dataFieldsInvoice = [
	{ field_name: "id" },
	{ field_name: "date_receipt" },
	{ field_name: "type" },
	{ field_name: "client" },
	{ field_name: "date_due" },
	{ field_name: "Balance" },
	{ field_name: "total" },
	{ field_name: "status" },
];

export const tableHeadersToDataFields = {
	Id: "id",
	Date: "date_receipt",
	Type: "type",
	Client: "client",
	"Due date": "date_due",
	Balance: "Balance",
	Total: "total",
	Status: "status",
};

export const dataFieldsToOrderFilter = {
	id: "id",
	date_receipt: "date",
	type: "type",
	client: "client",
	date_due: "due_date",
	Balance: "balance",
	total: "total",
	status: "status",
};

export const tableHeadersToOrderFilter = {
	id: "Id",
	date: "Date",
	type: "Type",
	client: "Client",
	due_date: "Due date",
	balance: "Balance",
	total: "Total",
	status: "Status",
};

export const composedFieldsInvoice = [];

export const arrayFieldsInvoice = [];
//Invoice

//Categories
export const tableHeadersCategories = [
	{ name: "Id" },
	{ name: "Name" },
	{ name: "Part Type" },
	{ name: "Serial Number" },
	{ name: "Actions", style: { justifyContent: 'center' } },
];

export const dataFieldsCategories = [
	{ field_name: "id" },
	{ field_name: "name" },
	{ field_name: "partype" },
	{ field_name: "serial_number" },
];

//Warehouse
export const tableHeadersWareHouse = [
	{ name: "Id" },
	{ name: "Name" },
	{ name: "Phone Number" },
	{ name: "Address" },
	{ name: "City" },
	{ name: "State" },
	{ name: "Zip Code" },
	{ name: "Actions", style: { justifyContent: 'center' } },
];

export const dataFieldsWareHouse = [
	{ field_name: "id" },
	{ field_name: "name" },
	{ field_name: "phone_number" },
	{ field_name: "address" },
	{ field_name: "city" },
	{ field_name: "state" },
	{ field_name: "zip_code" },
];

//Vendors
export const tableHeadersVendors = [
	{ name: "Id", width: "2%" },
	{ name: "Name" },
	{ name: "Address" },
	{ name: "City", width: "120px" },
	{ name: "State", width: "60px" },
	{ name: "Zip Code", width: "60px" },
	{ name: "Country" },
	{ name: "Phone Number", width: "120px" },
	{ name: "Email", width: "120px" },
	{ name: "Vendor Since", width: "100px" },
	{ name: "Actions", width: "100px" },
];

export const dataFieldsVendors = [
	{ field_name: "id" },
	{ field_name: "name" },
	{ field_name: "address" },
	{ field_name: "city" },
	{ field_name: "state" },
	{ field_name: "zip_code" },
	{ field_name: "country" },
	{ field_name: "phone_number" },
	{ field_name: "email" },
	{ field_name: "date_since" },
];

//Vendors
export const tableHeadersExpensive = [
	{ name: "Id" },
	{ name: "Number", showOrderActions: true },
	{ name: "Date", showOrderActions: true },
	{ name: "Description" },
	{ name: "Amount", showOrderActions: true },
	{ name: "Status", showOrderActions: true },
	{ name: "Actions" },
];

export const dataFieldsExpensive = [
	{ field_name: "id" },
	{ field_name: "number" },
	{ field_name: "date" },
	{ field_name: "description" },
	{ field_name: "amount" },
	{ field_name: "status" },
];

export const tableHeadersToDataFieldsExpense = {
	Number: "number",
	Date: "date",
	Amount: "amount",
	Status: "status",
};

export const dataFieldsToOrderFilterExpense = {
	number: "number",
	date: "date",
	amount: "amount",
	status: "status",
};

export const tableHeadersToOrderFilterExpense = {
	number: "Number",
	date: "Date",
	amount: "Amount",
	status: "Status",
};

//PaymentAccount
export const tableHeadersPaymentAccount = [
	{ name: "Id" },
	{ name: "Account" },
	{ name: "Actions" },
];

export const dataFieldsPaymentAccount = [
	{ field_name: "id" },
	{ field_name: "account" },
];

//Offline payment method
export const tableHeadersPaymentMethods = [
	{ name: "Id" },
	{ name: "Name" },
	{ name: "Description" },
	{ name: "Actions" },
];

export const dataFieldsPaymentMethods = [
	{ field_name: "id" },
	{ field_name: "name" },
	{ field_name: "description" },
];

//TypePayment
export const tableHeadersTypePayment = [
	{ name: "Id" },
	{ name: "Name" },
	{ name: "Status" },
	{ name: "Actions" },
];

export const dataFieldsTypePayment = [
	{ field_name: "id" },
	{ field_name: "name" },
	{ field_name: "active" },
];

//Roles
export const tableHeadersRoles = [
	{ name: "Id" },
	{ name: "Display Name" },
	{ name: "Description" },
	{ name: "Actions" },
];

export const dataFieldsRoles = [
	{ field_name: "id" },
	{ field_name: "display_name" },
	{ field_name: "description" },
];

//Brand
export const tableHeadersBrands = [{ name: "Name" }, { name: "Actions", style: { justifyContent: 'center' } }];

export const dataFieldsBrands = [{ field_name: "name" }];

//type device
export const tableHeadersTypeDevice = [{ name: "Name" }, { name: "Actions" }];

export const dataFieldsTypeDevice = [{ field_name: "name" }];

//Location
export const tableHeadersLocations = [{ name: "Name" }, { name: "Actions" }];

export const dataFieldsLocations = [{ field_name: "name" }];

//Location
export const tableHeadersUom = [
	{ name: "Symbol" },
	{ name: "Name" },
	{ name: "Type" },
	{ name: "System" },
	{ name: "Actions", style: { justifyContent: 'center' } },
];

export const dataFieldsUom = [
	{ field_name: "symbol" },
	{ field_name: "name" },
	{ field_name: "type" },
	{ field_name: "system" },
];

//Expense Categories
export const tableHeadersExpenseCategories = [
	{ name: "ID" },
	{ name: "Name" },
	{ name: "Actions" },
];

export const dataFieldsExpenseCategories = [
	{ field_name: "id" },
	{ field_name: "name" },
];

//Adjustment Details
export const tableHeadersAdjustmentDetail = [
	{ name: "Item Id", width: "2%" },
	{ name: "Name Item", width: "10%" },
	{ name: "Sku or Part number", width: "10%" },
	{ name: "Location", width: "10%" },
	{ name: "Adj Quantity", width: "10%" },
	{ name: "Inv Amount", width: "10%" },
	{ name: "Count", width: "10%" },
	{ name: "Posted", width: "10%" },
	{ name: "Change P. status", width: "10%" },
	{ name: "Actions", width: "18%" },
];

export const dataFieldsAdjustmentDetail = [
	{ field_name: "item_id" },
	{ field_name: "name_item" },
	{ field_name: "sku" },
	{ field_name: "location_name" },
	{ field_name: "adj" },
	{ field_name: "inv" },
	{ field_name: "quantity" },
	{ field_name: "posted" },
	{
		field_name: "change_posted_status",
		renderComponent: true,
		renderType: "button",
	},
];

//Adjustment Costumer
export const tableHeadersCostumer = [
	{ name: "Name", width: "17%", showOrderActions: true },
	{ name: "Phone Number", minWidth: '130px', showOrderActions: true },
	{ name: "Email", minWidth: '200px', showOrderActions: true },
	{ name: "Address", showOrderActions: true },
	{ name: "Status", minWidth: '100px', width: '100px' },
	{ name: "Actions", style: { justifyContent: 'center' } },
];

export const dataFieldsCostumer = [
	{ field_name: "name" },
	{ field_name: "business_phone" },
	{ field_name: "email" },
	{ field_name: "address_1" },
	{ field_name: "status" },
];

export const tableHeadersToDataFieldsCustomer = {
	Name: "name",
	"Phone Number": "business_phone",
	Email: "email",
	Address: "address_1",
};

export const dataFieldsToOrderFilterCustomer = {
	name: "name",
	business_phone: "business_phone",
	email: "email",
	address_1: "address_1",
};

export const tableHeadersToOrderFilterCustomer = {
	name: "Name",
	business_phone: "Phone Number",
	email: "Email",
	address_1: "Address",
};

//Labors
export const tableHeadersLabors = [
	{ name: "#", width: "17%" },
	{ name: "Name" },
	{ name: "Sku", width: "17%" },
	{ name: "Cost", width: "17%" },
	{ name: "Price", width: "17%" },
	{ name: "Actions", width: "100px", style: { justifyContent: 'center' } },
];

export const dataFieldsLabors = [
	{ field_name: "id" },
	{ field_name: "name" },
	{ field_name: "sku" },
	{ field_name: "cost" },
	{ field_name: "price" },
];

//Package
export const tableHeadersPackage = [
	// { name: '#', width: '5%' },
	{ name: "Name", width: "17%" },
	{ name: "Annual Price", width: "13%" },
	{ name: "Monthly Price", width: "13%" },
	{ name: "Employees", width: "17%" },
	{ name: "Storage", width: "13%" },
	{ name: "Module", width: "13%", column: "1" },
	{ name: "Actions" },
];

export const dataFieldsPackage = [
	// { field_name: 'id' },
	{ field_name: "name" },
	{ field_name: "annual_price" },
	{ field_name: "monthly_price" },
	{ field_name: "max_employees" },
	{ field_name: "storage_unit" },
	{ field_name: "modules", renderComponent: true, aling: "left" },
];

//Package
export const tableHeadersCompanies = [
	{ name: "Id", width: "12,5%" },
	{ name: "Name", width: "12,5%", showOrderActions: true },
	{ name: "Email", width: "12,5%", showOrderActions: true },
	{ name: "Package", width: "12,5%" },
	{ name: "Status", width: "12,5%" },
	{ name: "Detail", width: "12,5%" },
	{ name: "Last Activity", width: "12,5%", column: "1" },
	{ name: "Actions" },
];

export const dataFieldsCompanies = [
	{ field_name: "id" },
	{ field_name: "name" },
	{ field_name: "contact_email" },
	{ field_name: "package_name", renderComponent: true },
	{ field_name: "status" },
	{ field_name: "detail", renderComponent: true },
	{ field_name: "last_activity" },
];

export const tableHeadersToDataFieldsCompanies = {
	Name: "name",
	Email: "contact_email",
};

export const dataFieldsToOrderFilterCompanies = {
	name: "name",
	contact_email: "contact_email",
};

export const tableHeadersToOrderFilterCompanies = {
	name: "Name",
	contact_email: "Email",
};

//Invoice Detail
export const tableHeadersInvoiceDetail = [
	{ name: "Part number", width: "160px" },
	{ name: "Name", width: "14%" },
	{ name: "Description" },
	{ name: "Qty", width: "100px", column: "3" },
	{ name: "Rate", width: "100px", column: "3" },
	{ name: "Amount", width: "100px", column: "3" },
	{ name: "Actions", width: "100px", style: {justifyContent: 'center' }},
];

export const dataFieldsInvoiceDetail = [
	{ field_name: "part_number", renderComponent: true },
	{ field_name: "name" },
	{ field_name: "description" },
	{ field_name: "quantity", align: "right" },
	{ field_name: "rate", align: "right" },
	{ field_name: "amount", align: "right" },
];

//Invoice Detail
export const tableHeadersWorkOrderPartNumber = [
	{ name: "Part number", width: "17%" },
	{ name: "Name", width: "14%" },
	{ name: "Description", width: "31%" },
	{ name: "Qty", width: "2%", column: "3" },
	{ name: "Rate", width: "3%", column: "3" },
	{ name: "Amount", width: "4%", column: "3" },
	{ name: "", width: "10%" },
];

export const dataFieldsWorkOrderPartNumber = [
	{ field_name: "part_number", renderComponent: true, width: "17%" },
	{ field_name: "name", width: "14%" },
	{ field_name: "description", width: "31%" },
	{ field_name: "quantity", aling: "right", width: "2%" },
	{ field_name: "rate", aling: "right", width: "3%" },
	{ field_name: "amount", aling: "right", width: "4%" },
];

export const tableHeadersWorkOrderLaborCode = [
	{ name: "Labor code", width: "17%" },
	{ name: "Start Time", width: "14%" },
	{ name: "End Time", width: "31%" },
	{ name: "Worker", width: "2%", column: "3" },
	{ name: "Actual Hours", width: "3%", column: "3" },
	{ name: "Rate", width: "4%", column: "3" },
	{ name: "Amount", width: "4%", column: "3" },
	{ name: "", width: "10%" },
];

export const dataFieldsWorkOrderLaborCode = [
	{ field_name: "labor_code", renderComponent: true, width: "17%" },
	{ field_name: "start_time", width: "14%" },
	{ field_name: "end_time", width: "31%" },
	{ field_name: "worker", aling: "right", width: "2%" },
	{ field_name: "actual_hours", aling: "right", width: "3%" },
	{ field_name: "rate", aling: "right", width: "4%" },
	{ field_name: "amount", aling: "right", width: "4%" },
];

//Receive payment Detail
export const tableHeadersReceivePaymentDetail = [
	{ name: "", width: "1%" },
	{ name: "Invoice", width: "1%" },
	{ name: "Description", width: "1%" },
	{ name: "Due Date", width: "1%" },
	{ name: "Origin Amount", width: "1%", column: "3" },
	{ name: "Open Balance", width: "1%", column: "3" },
	{ name: "Payment", width: "1%", column: "3" },
];

export const dataFieldsReceivePaymentDetail = [
	{
		field_name: "part_number",
		renderComponent: true,
		width: "1%",
		renderType: "checkBox",
	},
	{
		field_name: "id",
		width: "1%"
	},
	{ field_name: "message", width: "1%" },
	{ field_name: "date_due", width: "1%" },
	{ field_name: "total", aling: "right", width: "1%" },
	{ field_name: "Balance", aling: "right", width: "1%" },
	{
		field_name: "total_payments",
		renderComponent: true,
		width: "1%",
		renderType: "input",
	},
];

export const filtersCostumer = [
	{
		field_id: "status",
		selectTittle: "Status",
		styles: styles,
		defaultOptionText: "Select a status",
		itemArray: [
			{ id: "true", name: "active" },
			{ id: "false", name: "inactive" },
		],
		defaultValue: "",
	},
];

export const mapListCostumersResponse = (data) => {
	let dataCopy = data.map((dataItem) => ({
		...dataItem,
		status: dataItem.status ? "active" : "inactive",
	}));
	return dataCopy;
};

export const mapListCompaniesList = (data) => {
	let dataCopy = data.map((dataItem) => ({
		...dataItem,
		package_name: dataItem?.package?.name || "",
		last_activity: dataItem?.last_activity
			? formatDate(dataItem?.last_activity?.created_at)
			: "",
	}));
	return dataCopy;
};
export const mapListUserManagment = (data) => {
	let dataCopy = data.map((dataItem) => ({
		...dataItem,
		package_name: dataItem?.package?.name || "",
		warehouse_name: dataItem?.warehouse?.name || "",
		company_name: dataItem?.company?.name || "",
		roles_name: dataItem?.roles.length > 0 ? dataItem?.roles[0]?.name : "",
	}));
	return dataCopy;
};

export const mapListInvoice = (data) => {
	let dataCopy = data.map((dataItem) => ({
		...dataItem,
		client: dataItem?.client?.name || "",
	}));
	return dataCopy;
};

//products - Locations Table
export const tableHeadersWorkOrderLocationTable = [
	{ name: "Warehouse" },
	{ name: "Location" },
	{ name: "Min Stock", width: "150px" },
	{ name: "Max Stock", width: "150px", column: "3" },
	{ name: "Quantity", width: "100px", column: "3", style: { justifyContent: 'center' } },
	{ name: "Remove", width: "100px", style: { justifyContent: 'center' } },
];

export const dataFieldsWorkOrderLocationTable = [
	{
		field_name: "warehouse_id",
		renderComponent: true,
		renderType: "select",
	},
	{
		field_name: "location_id",
		renderComponent: true,
		renderType: "select",
	},
	{
		field_name: "min_stock",
		renderComponent: true,
		width: "150px",
		renderType: "input",
	},
	{
		field_name: "max_stock",
		renderComponent: true,
		width: "150px",
		renderType: "input",
	},
	{
		field_name: "quantity",
		renderComponent: false,
		width: "150px",
		aling: "center"
	},
];

//products - Vendors Table
export const tableHeadersWorkOrderVendorsTable = [
	{ name: "", width: "70px" },
	{ name: "Last price", width: "100px", style: { justifyContent: 'center' } },
	{ name: "Vendor", width: "auto" },
];

export const dataFieldsWorkOrderVendorsTable = [
	{
		field_name: "check",
		renderComponent: true,
		renderType: "checkBox",
	},
	{
		field_name: "last_price",
		renderComponent: false,
		renderType: "input",
		aling: "center",
	},
	{
		field_name: "vendor",
		renderComponent: false,
		renderType: "select",
	}
];
