function validateRegexField(
	regexRule,
	field,
	fields_array_errors,
	text_field,
	text_message,
) {
	if (!regexRule.test(field)) {
		fields_array_errors.push({
			field_id: text_field,
			message: `${text_message} field need to be valid`,
		});
	}
}

function validateMaxLengthField(
	field,
	fields_array_errors,
	text_field,
	text_message,
	maxLength,
) {
	if (field.length > maxLength) {
		fields_array_errors.push({
			field_id: text_field,
			message: `${text_message} field is too long`,
		});
	}
}

export { validateRegexField, validateMaxLengthField };
