import React, { useEffect, useState, useRef } from 'react';
import "./AutoCompleteInputText.css";

function AutoCompleteInputText({ searchSuggestions, dataSuggestions, maxLength, onChange, key_name, value, type, isLoading, ...props }) {
  const [suggestions, setSuggestions] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [showNoSuggestion, setShowNoSuggestion] = useState(false);
  const suggestionsRef = useRef(null);

  useEffect(() => {
    setSuggestions(dataSuggestions);
  }, [dataSuggestions]);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const newTimeout = setTimeout(() => {
      if (inputValue.length > 2) {
        setShowNoSuggestion(true);
        searchSuggestions(inputValue);
      }else{
        setShowNoSuggestion(false);
        setSuggestions([]);
      }
    }, 250);

    setTypingTimeout(newTimeout);
    onChange({ target: { value: inputValue } }, key_name); 
  };

  const handleSelectSuggestion = (suggestion) => {
    onChange({ target: { value: suggestion } }, key_name); 
    setTimeout(() => {
      setSuggestions([]);
      setShowNoSuggestion(false);
    }, 10);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setSuggestions([]);
        setShowNoSuggestion(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    return () => {
      setSuggestions([]);
      clearTimeout(typingTimeout);
    };
  }, [typingTimeout]);

  return (
    <div className='autocomplete-component-container'>
      <input
        className='form-control'
        maxLength={maxLength}
        type={type}
        value={value}
        onChange={handleChange}
        placeholder=""
        style={{
          width: "100%",
          ...props.style
        }}
      />
      <div className='autocomplete-options' ref={suggestionsRef}>
        {isLoading ? (
          <span>Loading ...</span>
        ) : (
          value !== '' && suggestions.length > 0 ? (
            suggestions.map((suggestion, index) => (
              <span key={index} onClick={() => handleSelectSuggestion(suggestion)}>
                {suggestion}
              </span>
            ))
          ) : (
            value.length > 3 && suggestions.length === 0 && showNoSuggestion && (
              <span>No suggestion found</span>
            )
          )
        )}
      </div>
    </div>
  );
}

export {AutoCompleteInputText};
