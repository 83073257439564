import React, { useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
	faEdit,
	faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./adj.css";

//components
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import { CustomFilterComponent } from "../../components/CustomFiltersComponent/CustomFiltersComponent";
import {
	CustomSelect,
	CustomInputFilter,
} from "../../components/customSelect/customSelect";
import MenuItem from "@material-ui/core/MenuItem";

//Custom Hooks
import { useMenuActionHook } from "../../customHooks/useMenuActionHook";
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiRequestAjustment } from "../../customHooks/useApiRequestAjustment";
import { useApiRequestWareHouse } from "../../customHooks/useApiRequestWareHouse";
import { usePaginationHook } from "../../customHooks/usePaginationHook";

import {
	received,
	complete,
	posted,
	styles,
	tableHeadersAjustment as tableHeaders,
	dataFieldsAjustment as dataFields,
	composedFieldsAjustment as composedFields,
	arrayFieldsAjustment as arrayFields,
	ENTRY_STATE,
	COMPLETE_STATE,
	CANCEL_STATE,
	POSTED_STATE,
	tableHeadersToDataFieldsAdjustment as tableHeadersToDataFields,
	dataFieldsToOrderFilterAdjustment as dataFieldsToOrderFilter,
	tableHeadersToOrderFilterAdjustment as tableHeadersToOrderFilter,
} from "../../context/constants";
import { FaCaretDown, FaLongArrowAltRight } from "react-icons/fa";
import { ModalDeleteFromTable } from "../../components/ModalDeleteFromTable";

function Adjustment() {
	const {
		anchorEl,
		setAnchorEl,
		onMenuActions,
		onCloseModal,
		currentElement,
		setCurrentElement
	} = useMenuActionHook();

	const {
		showModal,
		setShowModal,
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
		manageDeleteMessage,
		customTittleText,
		customMessageModal,
		setCustomMessageModal,
	} = useModalHook();

	const {
		isLoading,
		filtered_data,
		setSincronizedItems,
		itemFilters,
		setItemFilters,
		itemPagination,
		deleteItem: deleteAdjustment,
	} = useApiRequestAjustment(
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
	);

	const {
		filterInputChange,
		selectOnChange,
		onChangeNumberSelect,
		handlePagination,
		filtersDefinition,
		setFiltersDefition,
		filtersTextDefinition,
		setFiltersTextDefition,
		onChangeDateFilter,
		onChangeOrderArrow,
	} = usePaginationHook(setSincronizedItems, setItemFilters, itemFilters);

	const { warehouse } = useApiRequestWareHouse();

	const history = useHistory();

	const prepareToUpdate = () => {
		setAnchorEl(null);
		history.push({
			pathname: "/adjustment_form_update",
			search: `?adjustment_id=${currentElement.id}`,
		});
	};

	const prepareToDelete = async () => {
		// setUserSelected(currentElement);
		setAnchorEl(null);
		manageDeleteMessage("Adjustment");
		setCustomMessageModal(
			`Are you sure you want to delete item #${currentElement.id}?`,
		);
		setShowModal(true);
	};

	const prepareToDeleteItemMobileTable = async (currentElement) => {
		setCurrentElement(currentElement);
		setAnchorEl(null);
		manageDeleteMessage("Adjustment");
		setCustomMessageModal(
			`Are you sure you want to delete item #${currentElement.id}?`,
		);
		setShowModal(true);
	};

	const deleteItem = async () => {
		await deleteAdjustment(currentElement.id);
		setShowModal(false);
	};

	const actionsDefinition = [
		{
			name: "Edit",
			func: prepareToUpdate,
			icon: faEdit,
		},
		{
			name: "Delete",
			func: prepareToDelete,
			icon: faTrashAlt,
		},
	];

	const filters = [
		{
			field_id: "warehouse_id",
			selectTittle: "Warehouse",
			styles: styles,
			defaultOptionText: "Select a warehouse",
			itemArray: warehouse,
			defaultValue: "",
		},
		{
			field_id: "status",
			selectTittle: "Status",
			styles: styles,
			defaultOptionText: "Select a status",
			itemArray: [
				{ id: ENTRY_STATE, name: ENTRY_STATE },
				{ id: COMPLETE_STATE, name: COMPLETE_STATE },
				{ id: CANCEL_STATE, name: CANCEL_STATE },
				{ id: POSTED_STATE, name: POSTED_STATE },
			],
			defaultValue: "",
		},
		// {
		//   field_id: 'category_expensive_id',
		//   selectTittle: 'Category Expensive',
		//   styles: styles,
		//   defaultOptionText: 'Select a category expensive',
		//   itemArray: expensiveCategory,
		//   defaultValue: ''
		// },
	];

	useEffect(() => {
		setFiltersDefition([...filters]);
	}, [
		warehouse,
		// expensiveCategory
	]);

	const filtersText = [
		{
			field_id: "date",
			field_name: "date",
			labelText: "Date",
			value: "",
			type: "date",
		},
	];

	useEffect(() => {
		setFiltersTextDefition(filtersText);
	}, []);

	const [optionListIndex, setOptionListIndex] = useState(null); // Estado para almacenar el índice del elemento con lista de opciones abierto

    const handleOptionListToggle = (index) => {
        setOptionListIndex(index === optionListIndex ? null : index); // Alternar la apertura y cierre de la lista de opciones
    };

	return (
		<div className="container-fluid contenedor">
			{/* <CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowModal(false)}
				onHandleSuccess={deleteItem}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
				actionsFooterStyle={true}
				cancelText={cancelButtonText}
				successText={sucessButtonText}
			/> */}
			<ModalDeleteFromTable
				isOpen={showModal}
				handleClose={() => setShowModal(false)}
				title={customTittleText}
				warningMessage={customMessageModal}
				onHandleSuccess={deleteItem}
			/>
			<div className="container-title mb-3">
				<h1 className="container__title mb-0">Adjustments</h1>
			</div>
			<CustomFilterComponent
				filtersTextDefinition={filtersTextDefinition}
				filtersDefinition={filtersDefinition}
				onClickfilter={() => setSincronizedItems(false)}
				filterInputChange={filterInputChange}
				onCreateNewItem={() => history.push("/adjustment_form")}
				showDateFilters={false}
				styles={styles}
				onChangePageEvent={onChangeNumberSelect}
				renderFilter={(filter, index) => (
					<CustomSelect
						key={index}
						field_id={filter.field_id}
						selectTittle={filter.selectTittle}
						styles={filter.styles}
						defaultOptionText={filter.defaultOptionText}
						itemArray={filter.itemArray}
						onChangeEvent={selectOnChange}
						defaultValue={filter.defaultValue}
						selectWidth={"100%"}
					/>
				)}
				renderFilterText={(filter, index) => (
					<CustomInputFilter
						key={index}
						filter={filter}
						onChangeDateFilter={onChangeDateFilter}
					/>
				)}
			/>
			<div className="adj-dataList-desktop-wrapper">
				<CustomDataList
					onChangePageEvent={onChangeNumberSelect}
					itemPagination={itemPagination}
					handlePagination={handlePagination}
					dontShowPerPageSelect={true}
					isLoading={isLoading}
					dataList={filtered_data}
					tableHeaders={tableHeaders}
					onChangeOrderArrow={onChangeOrderArrow}
					showOrderArrows={true}
					tableHeadersToDataFields={tableHeadersToDataFields}
					dataFieldsToOrderFilter={dataFieldsToOrderFilter}
					tableHeadersToOrderFilter={tableHeadersToOrderFilter}
					itemFilters={itemFilters}
					showLinearIndicator={true}
					showDataInLoading={true}
					render={(element, index) => (
						<CustomDataListItem
							key={index}
							dataFields={dataFields}
							imageFields={[]}
							composedFields={composedFields}
							arrayFields={arrayFields}
							element={element}
							index={index}
							no_image={null}
							renderActions={() => (
								<>
									<PositionedMenu
										title={"Actions"}
										anchorEl={anchorEl}
										setAnchorEl={setAnchorEl}
										handleClose={onCloseModal}
										handleClick={(event) =>
											onMenuActions(event, element)
										}
										actionsDefinition={actionsDefinition}
										renderActions={(action, index) => (
											<MenuItem
												key={index}
												onClick={() => action.func()}
											>
												<div
													className="items-option"
													style={{
														padding: "0 5px",
														color: "grey",
													}}
												>
													<FontAwesomeIcon
														style={{
															fontSize: '.8rem',
															margin: "4px",
															marginRight: "10px",
														}}
														className={
															action.name == "Edit" 
																? "text-primary"
																: action.name == "Delete"
																	? 'text-danger'
																	: 'inherit'
														}
														icon={action.icon}
													/>
													<p className="mb-0 fs-small">{action.name}</p>
												</div>
											</MenuItem>
										)}
									></PositionedMenu>
								</>
							)}
							posted={posted}
							complete={complete}
							received={received}
						/>
					)}
				/>
			</div>
			<div className="adj-dataList-mobile-wrapper">
				<div
				style={{ width:"100%", fontSize:"12.8px", border:"1px solid gray"}}
				>
					{/* {console.log(filtered_data)} */}
					{
						filtered_data.length > 0 && filtered_data.map((adjustment, index)=>(
						<div
						key={index}
						style={{
							display: "block",
							padding: "5px 10px",
							backgroundColor: index % 2 !== 0 ? "#f2f2f2" : "transparent",
						}}
					>
						<div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
							<div>
								<strong>Adjustment ID:{adjustment.id}</strong>
								<p>Warehouse: {adjustment?.warehouse?.name}</p>
								<p>Posted: 
									<span style={{color : adjustment.tx_posted ? "black" : "red"}}>
										{adjustment.tx_posted ? "True" : "False"}
									</span>
								</p>
							</div>
							<div
								style={{display:"flex", flexDirection:"column", alignItems:"flex-end"}}
							>
								<strong>User ID:{adjustment?.user?.id}</strong>
								<p>Status: 
									<span style={{color: adjustment.status === "Entry" ? "blue" : (adjustment.status === "Complete" ? "green" : "Red")}}>
										{adjustment.status}
									</span>
								</p>

								<p>Post Date: {moment(adjustment.postdate).format("DD/MM/yy")}</p>
							</div>
						</div>
						<div
						style={{display:"flex", justifyContent:"flex-end", width:"100%", fontWeight:"bold", color:"#2767c4"}}
						>
							<small
							onClick={() => {
								setCurrentElement(adjustment);
								prepareToUpdate();
								// history.push(`/invoice_form_update?invoice_id=${adjustment.id}`)
							}}
							style={{borderRight:"1px solid lightgrey", padding:"0 5px"}}
							>Edit</small>

							<small
							onClick={() => prepareToDeleteItemMobileTable(adjustment)}
							style={{borderRight:"1px solid lightgrey", padding:"0 5px"}}
							>Delete</small>

							<small onClick={() => handleOptionListToggle(index)} style={{ padding: "0 5px", position:"relative" }}>
								<FaCaretDown size={"20px"} />
								{optionListIndex === index &&(
									<div style={{ right:"0", borderRadius:"6px", padding: "5px 15px", position:"absolute" , zIndex:"10", background:"white", color:"black", boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
									<p 
									style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}
									><FaLongArrowAltRight size={"18px"} /> View</p>
									<p 
									style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}
									><FaLongArrowAltRight size={"18px"} /> Copy</p>
									<p 
									style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}
									><FaLongArrowAltRight size={"18px"} /> Print</p>
									</div>
								)}
							</small>
						</div>
					</div>
						))
					}
					
				</div>
			</div>
		</div>
	);
}

export { Adjustment };
