import { useState } from "react";

function useMenuActionHook() {
	const [actionsDefinition, setActionDefinition] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [currentElement, setCurrentElement] = useState(null);
	const [currentId, setCurrentId] = useState(null);

	const onMenuActions = (event, element) => {
		setCurrentElement(element);
		setAnchorEl(event.currentTarget);
	};
	
	const onCloseModal = (closeLocalMenu) => {
		setAnchorEl(null);
		if(closeLocalMenu && closeLocalMenu instanceof Function) {
			closeLocalMenu();
		}
	};

	return {
		actionsDefinition,
		setActionDefinition,
		anchorEl,
		setAnchorEl,
		onMenuActions,
		onCloseModal,
		currentElement,
		currentId,
		setCurrentId,
		setCurrentElement,
	};
}

export { useMenuActionHook };
