import createHttp from "../lib/http";

const PaymentAccountService = {};

PaymentAccountService.fetchAll = async (params) => {
  const { data } = await createHttp.get("/api/payment-account", { params });
  return data;
}

PaymentAccountService.store = async (payload) => {
  try {
    const { data } = await createHttp.post("/api/payment-account", payload);
    return data;
  } catch (error) {}
}

export default PaymentAccountService;