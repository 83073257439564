import { Modal, Button } from "react-bootstrap";
import { ActivityIndidicator } from "../../components/CircularProgres/CircularProgress";

//css
import "./CustomModal.css";
import MaterialUI from "../MaterialUI";

function CustomModal(props) {
	if(!props.show) return null
	return (
		<div style={{ zIndex: "1040" }}>
			<Modal
				size={props.size ? props.size :""}
				show={props.show}
				onHide={() => {
					return false;
				}}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				{ !!props.customTittleText && (
					<Modal.Header style={{ background: '#041731', color: '#fff' }}>
						<Modal.Title>{props.customTittleText}</Modal.Title>
					</Modal.Header>
				)}
				<Modal.Body>
					<div className="children_modal_wrapper">
					{!!props.children && props.children}
					</div>
					{!!props.customMessageModal && !props.children && (
						<>
							<p>{props.customMessageModal}</p>
						</>
					)}
					{!!props.isLoading && !props.children && (
						<ActivityIndidicator />
					)}
				</Modal.Body>
				<Modal.Footer className={props?.actionsFooterStyle && "action_styles"}>
					{!props.isLoading && !props.children && (
						<MaterialUI.Button.Secondary onClick={props.onHandleClose}>
							{!!props.cancelText ? props.cancelText : "Close"}
						</MaterialUI.Button.Secondary>
					)}
					{!!props.showSuccessButton && !props.children && (
						<MaterialUI.Button.Primary onClick={props.onHandleSuccess}>
							{!!props.successText ? props.successText : "Accept"}
						</MaterialUI.Button.Primary>
					)}
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export { CustomModal };
