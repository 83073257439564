import Layout from "../Layout/Layout";
import { useAuthentication } from "../../customHooks/useAuthentication";
import Login from "../../views/login/Login";

function WithAuthenticationListener(WrappedComponent) {
	return function WrappedComponentWithAuthentication(props) {
		const { currentToken } = useAuthentication();

		return (
			<>
				{currentToken ? (
					<WrappedComponent>{props.children}</WrappedComponent>
				) : (
					<Login />
				)}
			</>
		);
	};
}

export { WithAuthenticationListener };
