import { useState } from 'react';
import { LaravelErrorsToObject } from '../../../utils/errors.laravel';
import HttpClient from '../../../lib/http';

export class Adapter {
  static response(data) {
    return data;
  }
}

export const initState = {
  id: 0,
  name: "",
  color: "",
}

const useColors = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [color, setColor] = useState({ ...initState });
  const [errors, setErrors] = useState({});
  
  const store = async (payload) => {
    try {
      setErrors({})
      setIsLoading(true);
      // const { data: resp } = await HttpClient.post(`/api/colors`, payload);
      // const { data, errors, status, message } = resp;
      // if(errors) {
      //   setErrors(LaravelErrorsToObject(errors));
      //   return
      // }
      // if(status) {
      //   setColor(() => Adapter.response(data));
      //   return data;
      // } else {
      //   if(message) {
      //     setErrors((prev) => ({ ...prev, name:  `The name "${payload.name}" is already in use` }));
      //   }
      // }
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    store,

    isLoading,
    setIsLoading,
    color,
    setColor,
    errors,
    setErrors,
  }
}

export default useColors;