import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

// components
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomButton } from "../../components/CustomButton/CustomButton";

// custom Hooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";

// form configuration
import {
	typesAndValues,
	initData as initDataValues,
	typesAndValuesCreditInfo,
	initDataCreditInfo as initDataCreditInfoValues,
} from "./initForm";
import MaterialUI from "../../components/MaterialUI";

function CostumerFormUpdate(props) {
	const location = useLocation();
	const history = useHistory();

	const [goToMainPage, setGoToMainPage] = React.useState(false);

	const {
		showSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		customMessageModal,
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
	} = useModalHook();

	const { isLoading, getItem, updateItem } = useApiCustomRequest(
		manageResponseErrors,
		"clients",
		"Clients",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		setGoToMainPage,
	);

	const [initData, setInitData] = useState(null);
	const [initDataCreditInfo, setInitDataCreditInfo] = useState(null);

	const mapResponse = {
		is_active: "status",
	};

	useEffect(async () => {
		let initDataValuesCopy = { ...initDataValues };
		let initDataCreditInfoValuesCopy = { ...initDataCreditInfoValues };

		if (location?.search?.split("=")[1]) {
			const itemInfo = await getItem(location?.search?.split("=")[1], {});

			let infoFormOne = {};
			Object.keys(initDataValues).map((key) => {
				if (itemInfo.hasOwnProperty(key)) {
					infoFormOne[key] = itemInfo[key];
				}
			});

			let infoFormTwo = {};
			Object.keys(initDataCreditInfoValues).map((key) => {
				if (Object.keys(mapResponse).includes(key)) {
					infoFormTwo[key] = itemInfo[mapResponse[key]];
				}
				if (itemInfo.hasOwnProperty(key)) {
					infoFormTwo[key] = itemInfo[key];
				}
			});

			initDataValuesCopy = { ...infoFormOne };
			initDataCreditInfoValuesCopy = { ...infoFormTwo };
		}

		setInitData({ ...initDataValuesCopy });
		setInitDataCreditInfo({ ...initDataCreditInfoValuesCopy });
	}, []);

	const onChangeField = async (event) => {
		setInitDataCreditInfo({ ...event });
	};

	const onChangeFieldsInitData = async (event) => {
		setInitData({ ...event });
	};

	const childFunc = useRef(null);
	const childFunc2 = useRef(null);
	const onHandleSubmit = async (event) => {
		if (isLoading) return;

		const errorsOne = childFunc.current(initData);
		const errorsTwo = childFunc2.current(initDataCreditInfo);

		if (!(errorsOne.length > 0) && !(errorsTwo.length > 0)) {
			let bodyRequest = {};

			const body = {
				...initData,
				...initDataCreditInfo,
			};

			Object.keys(body).map((key) => {
				if (body[key] != "" && body[key] != null) {
					bodyRequest[key] = body[key];
				}
				if (typeof body[key] == "boolean") {
					bodyRequest[key] = body[key] ? "1" : "0";
				}
				if (Object.keys(mapResponse).includes(key)) {
					bodyRequest[mapResponse[key]] = body[key] ? "1" : "0";
				}
			});

			const response = await updateItem(
				location?.search?.split("=")[1],
				bodyRequest,
				"Client",
				{},
				true,
			);

			if (response && response.hasOwnProperty("id")) {
				history.push("/costumer_list");
			}
		}
	};

	const onHandleResponseModal = () => {
		setShowModal(false);
		if (goToMainPage) {
			history.push("/costumer_list");
		}
	};

	const [typesAndValuesFields, setTypesAndValuesFields] = React.useState([]);

	useEffect(() => {
		const fieldsAndValuesCopy = [...typesAndValues];

		const idFieldIndex = fieldsAndValuesCopy.findIndex(
			(item) => item.field_name === "id",
		);
		let fieldId = fieldsAndValuesCopy[idFieldIndex];
		fieldId.hidden = true;
		fieldsAndValuesCopy[idFieldIndex] = fieldId;

		setTypesAndValuesFields(fieldsAndValuesCopy);
	}, [location?.search?.split("=")[1]]);

	const gotBack = () => history.push('/costumer_list');
	
	return (
		<>
			<div className="contenedor container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="d-flex gap-2 align-items-center">
						<MaterialUI.Button.Dark onClick={gotBack}>
							<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
						</MaterialUI.Button.Dark>
						<div className="container-title w-100">
							<h1 className="container__title mb-0">
								{props.actionTittle + " " + props.model}
							</h1>
						</div>
					</div>
				</div>
			</div>
				<div className="row mt-1 pl-0 ml-0">
					<div className="col-12 pl-0 ml-0">
						{initData && typesAndValuesFields && (
							<CustomForm
								onHandleFormClose={() => {}}
								onHandleSubmit={() => {}}
								typesAndValuesFields={typesAndValuesFields}
								initData={initData}
								formTittle={""}
								dontShowCancelButton={true}
								id={
									(location?.state?.id &&
										location?.state?.id.toString()) ||
									null
								}
								getUrlPath={null}
								inputConf={{
									marginTop: "1",
									marginBottom: "0",
									stringCol:"col-12 col-md-4"
								}}
								dontShowSubmitButton={true}
								onChangeFields={onChangeFieldsInitData}
								initOnChangeFields={onChangeFieldsInitData}
								childFunc={childFunc}
								dontShowErrosinOnChange={true}
							/>
						)}
					</div>
					<div className="col-12 mt-3">
						<h6 className="sub__title">Credit Information</h6>
					</div>
					<div className="col-12">
						{initDataCreditInfo && typesAndValuesCreditInfo && (
							<CustomForm
								onHandleFormClose={() => {}}
								onHandleSubmit={onHandleSubmit}
								typesAndValuesFields={typesAndValuesCreditInfo}
								initData={initDataCreditInfo}
								dontShowCancelButton={true}
								id={
									(location?.state?.id &&
										location?.state?.id.toString()) ||
									null
								}
								getUrlPath={null}
								inputConf={{
									marginTop: "0",
									marginBottom: "0",
									stringCol:"col-12"
								}}
								dontShowSubmitButton={true}
								onChangeFields={onChangeField}
								initOnChangeFields={onChangeField}
								childFunc={childFunc2}
								dontShowErrosinOnChange={true}
							/>
						)}
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<CustomButton
							buttonName={"Save"}
							buttonOnClick={onHandleSubmit}
							type={"success"}
							icon={""}
							disabled={false}
							className={"col-md-1 col-4 btn btn-sm float-end"}
						/>
					</div>
				</div>
			</div>

			{/* loading modal */}
			<CustomModal
				show={isLoading}
				customTittleText={""}
				customMessageModal={""}
				onHandleClose={() => {}}
				isLoading={isLoading}
			/>

			{/* response modal */}
			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={onHandleResponseModal}
				onHandleSuccess={onHandleResponseModal}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
			/>
		</>
	);
}

export { CostumerFormUpdate };
