import Input from './Input';
import Autocomplete from './Autocomplete';
import Button from './Button';
import AutocompleteAsync from './AutocompleteAsync';
import Select from './Select';
import Fieldset from './Fieldset';

export default {
  Input,
  Autocomplete,
  Button,
  AutocompleteAsync,
  Select,
  Fieldset,
}