import { useState, useEffect, useMemo } from "react";
import createHttp from "../lib/http";

var _ = require("lodash");

function useApiRequestItems(
	setIsLoading,
	setPaymentNumber,
	setPartNumberId,
	setPartNumberName,
) {
	const [sincronizedItems, setSincronizedItems] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const [queryParams, setQueryParams] = useState({
		page: "1",
		per_page: "1000",
	});
	const [itemsNotFound, setItemsNotFound] = useState(false);

	const getItems = async (params) => {
		return await createHttp
			.get("/api/items", {
				params,
			})
			.then((response) =>
				response?.data?.status ? response.data : { data: [] },
			)
			.catch((err) => ({ data: [] }));
	};

	const eventHandler = async (e) => {
		let part_item = filteredData.find(
			(element) => element.name === e.target.value,
		);

		if (part_item) {
			setPartNumberId(part_item.id);
			setPartNumberName(part_item.name);
		} else {
			setPartNumberId("");
			setPartNumberName("");

			const params = {
				...queryParams,
				search_text: e.target.value,
			};

			const { data } = await getItems(params);

			if (!data || data.length === 0) {
				setItemsNotFound(true);
			} else {
				setItemsNotFound(false);
			}

			setFilteredData(data);
		}
	};

	const getItemList = useMemo(
		() => _.debounce(eventHandler, 500),
		[queryParams, filteredData],
	);

	const getSkuItemInformation = async (sku) => {
		let params = {
			param: sku,
		};

		return await createHttp
			.get("/api/items-search-skup-upc", {
				params,
			})
			.then((response) =>
				response?.data?.status ? response.data : { data: {} },
			)
			.catch((err) => ({ data: {} }));
	};

	return {
		filteredData,
		setSincronizedItems,
		getItems,
		setFilteredData,
		queryParams,
		setQueryParams,
		getItemList,
		itemsNotFound,
		setItemsNotFound,
		getSkuItemInformation,
	};
}

export { useApiRequestItems };
