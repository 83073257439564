import React from 'react';
import "./styles.css";
import WarningIcon from '../../assets/img/warning-icon.png';

function ModalDeleteFromTable({isOpen, handleClose, title, warningMessage, onHandleSuccess}) {
    if(!isOpen) return null;
    return (
        <div className='modal-fixed-background' onClick={handleClose}>
            <div className='modal-delete-container px-4 px-md-5' onClick={(e) => e.stopPropagation()}>
                <div className="d-flex mb-0">
                    <img src={WarningIcon} alt={"editIcon"} style={{height:"65px", width:"auto", margin:"auto"}}/>
                </div>
                <div>
                    <h4 className='fw-bold h4 mb-0'>{title}</h4>
                    <p className='mb-4 text-muted fw-normal'>{warningMessage}</p>
                    <div className='btn-container mb-1'>
                        <button className='btn btn-danger' onClick={onHandleSuccess}>Yes, Delete!</button>
                        <button className='btn btn-outline-secondary' onClick={handleClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {ModalDeleteFromTable};