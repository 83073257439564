import React from "react";

export { Can } from "../components/Can";

export const PermissionContext = React.createContext();

export function PermissionContextProvider({ children }) {	
	const [permissions, setPermissions] = React.useState(new Set());

	const share = {
		permissions,
		setPermissions
	};

	return (
		<PermissionContext.Provider value={share}>
			{children}
		</PermissionContext.Provider>
	);
}
