import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { ProductForm } from "../../views/ProductForm/ProductForm";
import "./index.css";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '500px',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        '& .modal-dialog': {
            maxInlineSize: '100vw',
            margin: '0',
            minBlockSize: '100vh',
            top: '0 !important',
        }
    }
}));

function ModalAddItem(props) {
    const classes = useStyles();
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (props.show) {
                setActive(true);
        } else {
            setActive(false);
        }
    }, [props.show]);

    return (
        <div>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                show={props.show}
                onHide={props.onHandleClose}
                className={`modal-create-product-wrapper ${active ? 'active' : ''} ${classes.root}`}
            >
                <Modal.Header>
                    <Modal.Title as={'h6'} className="fw-bold"> {props.modal_conf.title} </Modal.Title>
                    <button className="btn-close fs-small" onClick={props.handleCloseAdd}></button>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <ProductForm
                        dontReloadToMainPage={true}
                        showCancelButton={true}
                        onHandleCancelButton={props.onHandleClose}
                        getElementWhenAddNewItem={props.getElementWhenAddNewItem}
                        skuNumber={props.skuNumber}
                        handleCloseAdd={props.handleCloseAdd}
                        dontShowTabs={true}
                        withoutData={true}
                        hideHeader
                        noPadding
                    />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </div>
    );
 
}

export { ModalAddItem };
