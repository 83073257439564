import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { styled } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const BootstrapButton = styled(Button)({
	boxShadow: "none",
	textTransform: "none",
	fontFamily: [
		"Roboto",
		"-apple-system",
		"BlinkMacSystemFont",
		'"Segoe UI"',
		"Roboto",
		'"Helvetica Neue"',
		"Arial",
		"sans-serif",
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(","),
	padding: "0.25rem 0.5rem",
	fontSize: "0.8125rem",
	color: "white",
	background: "#198754",
	border: "1px solid #157347",
	borderRadius: ".25rem",
	"&:hover": {
		background: "#157347",
	},
	"&:focus": {
		background: "#157347",
	},
});

const StyledMenu = withStyles({
	paper: {
		// border: "1px solid black",
		paddingBlock: "8px",
		marginTop: "2px",
		boxShadow: `
			0px 1px 1px 0 rgba(0,0,0,0.05),
			0px 1px 1px 0px rgba(0,0,0,0.05),
			0px 1px 2px 0px rgba(0,0,0,0.05) !important`,
		minInlineSize: '150px',
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "center",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "center",
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
	root: {},
}))(MenuItem);

function ControlledOpenSelect(props) {
	const history = useHistory();

	const { anchorEl, setAnchorEl } = props;

	const open = Boolean(anchorEl);
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<BootstrapButton
				id="demo-positioned-button"
				aria-controls={open ? "demo-positioned-menu" : undefined}
				aria-haspopup="true"
				onClick={props.handleClick}
			>
				{props.title} &nbsp; <i className="fas fa-chevron-down"></i>
			</BootstrapButton>
			<StyledMenu
				elevation={0}
				sx={{ boxShadow: "none" }}
				id="demo-positioned-menu"
				aria-labelledby="demo-positioned-button"
				anchorEl={anchorEl}
				open={open}
				onClose={props.handleClose}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				{props.actionsDefinition.map((item, index) => (
					<StyledMenuItem
						key={index}
						onClick={() =>
							!item.urlRedirect
								? ""
								: history.push(item.urlRedirect)
						}
					>
						<div
							className="items-option"
							style={{ padding: "0 0", color: "grey" }}
						>
							<p>{item.name}</p>
						</div>
					</StyledMenuItem>
				))}
			</StyledMenu>
		</div>
	);
}

export { ControlledOpenSelect };
