const regexValidation = (
	regexRule,
	field,
	text_field,
	text_message,
	typeRule,
	errorsArray,
	complexField = null,
) => {
	let copyFormErrors = [...errorsArray];
	const index = copyFormErrors.findIndex(
		(item) => item.field_id === text_field && item.type === typeRule,
	);

	if (!regexRule.test(field) && index === -1) {
		copyFormErrors = [
			...copyFormErrors,
			{
				field_id: text_field,
				message:
					complexField &&
					complexField.hasOwnProperty("validationMessage")
						? complexField.validationMessage
						: `${text_message} field need to be valid`,
				type: typeRule,
			},
		];
	}

	if (regexRule.test(field) && index != -1) {
		copyFormErrors.splice(index, 1);
	}

	return copyFormErrors;
};

const maxLengthValidation = (
	field,
	text_field,
	text_message,
	typeRule,
	maxLength,
	errorsArray,
) => {
	let copyFormErrors = [...errorsArray];

	const index = copyFormErrors.findIndex(
		(item) => item.field_id === text_field && item.type === typeRule,
	);

	if (field && field.length > maxLength && index === -1) {
		copyFormErrors = [
			...copyFormErrors,
			{
				field_id: text_field,
				message: `${text_message} field must be less than ${maxLength}`,
				type: typeRule,
			},
		];
	}

	if (field && field.length <= maxLength && index != -1) {
		copyFormErrors.splice(index, 1);
	}

	return copyFormErrors;
};

const minLengthValidation = (
	field,
	text_field,
	text_message,
	typeRule,
	minLength,
	errorsArray,
) => {
	let copyFormErrors = [...errorsArray];

	const index = copyFormErrors.findIndex(
		(item) => item.field_id === text_field && item.type === typeRule,
	);

	if ((!field || field.length < minLength) && index === -1) {
		copyFormErrors = [
			...copyFormErrors,
			{
				field_id: text_field,
				message: `${text_message} field must have more than ${
					minLength - 1
				} characters`,
				type: typeRule,
			},
		];
	}

	if (field && field.length >= minLength && index != -1) {
		copyFormErrors.splice(index, 1);
	}

	return copyFormErrors;
};

const dateLessThanDate = (
	value,
	valueToEvaluate,
	errorsArray,
	typeRule,
	current_field_text_label,
	compare_field_text_label,
	current_field_id,
) => {
	let copyFormErrors = [...errorsArray];

	const index = copyFormErrors.findIndex(
		(item) => item.field_id === current_field_id && item.type === typeRule,
	);

	if (value && valueToEvaluate) {
		const date1 = new Date(value);
		const date2 = new Date(valueToEvaluate);

		if (!(date1.getTime() <= date2.getTime()) && index === -1) {
			copyFormErrors = [
				...copyFormErrors,
				{
					field_id: current_field_id,
					message: `${current_field_text_label} cannot be less than ${compare_field_text_label}`,
					type: typeRule,
				},
			];
		}

		if (date1.getTime() <= date2.getTime() && index != -1) {
			copyFormErrors.splice(index, 1);
		}
	}

	if (value && !valueToEvaluate && index === -1) {
		copyFormErrors = [
			...copyFormErrors,
			{
				field_id: current_field_id,
				message: `${current_field_text_label} cannot be less than ${compare_field_text_label}`,
				type: typeRule,
			},
		];
	}

	return copyFormErrors;
};

const isEqualTo = (
	value,
	valueToEvaluate,
	errorsArray,
	typeRule,
	current_field_text_label,
	compare_field_text_label,
	current_field_id,
) => {
	let copyFormErrors = [...errorsArray];

	const index = copyFormErrors.findIndex(
		(item) => item.field_id === current_field_id && item.type === typeRule,
	);

	if (value !== valueToEvaluate) {
		copyFormErrors = [
			...copyFormErrors,
			{
				field_id: current_field_id,
				message: `${current_field_text_label} must be the same as the ${compare_field_text_label}`,
				type: typeRule,
			},
		];
	}

	if (value === valueToEvaluate && index != -1) {
		copyFormErrors.splice(index, 1);
	}

	return copyFormErrors;
};

const formValidations = (values, fields, valueToEvaluate) => {
	let copyFormErrors = [];

	Object.keys(values).map((item) => {
		const field = fields.find((field) => field.field_name === item);

		if (field && field.hasOwnProperty("regexPattern")) {
			copyFormErrors = [
				...regexValidation(
					field.regexPattern,
					valueToEvaluate || values[item],
					field.field_name,
					field.text_label,
					"regexPattern",
					copyFormErrors,
					field,
				),
			];
		}
		if (field && field.hasOwnProperty("maxLength")) {
			copyFormErrors = [
				...maxLengthValidation(
					valueToEvaluate || values[item],
					field.field_name,
					field.text_label,
					"maxLength",
					field.maxLength,
					copyFormErrors,
				),
			];
		}
		if (field && field.hasOwnProperty("minLength")) {
			copyFormErrors = [
				...minLengthValidation(
					valueToEvaluate || values[item],
					field.field_name,
					field.text_label,
					"minLength",
					field.minLength,
					copyFormErrors,
				),
			];
		}

		if (field && field.hasOwnProperty("dateGraterThan")) {
			const dateGraterThanField = fields.find(
				(item) => item.field_name === field.dateGraterThan,
			);
			copyFormErrors = [
				...dateLessThanDate(
					values[field.dateGraterThan],
					valueToEvaluate || values[item],
					copyFormErrors,
					"dateGraterThan",
					field.text_label,
					dateGraterThanField.text_label,
					field.field_name,
				),
			];
		}

		if (field && field.hasOwnProperty("isEqualTo")) {
			const isEqualToField = fields.find(
				(item) => item.field_name === field.isEqualTo,
			);

			copyFormErrors = [
				...isEqualTo(
					values[field.isEqualTo],
					valueToEvaluate || values[item],
					copyFormErrors,
					"isEqualTo",
					field.text_label,
					isEqualToField.text_label,
					field.field_name,
				),
			];
		}
	});

	return copyFormErrors;
};

export { regexValidation, maxLengthValidation, formValidations };
