import { useState } from "react";

function useModalHook() {
	const [showModal, setShowModal] = useState(false);
	const [customTittleText, setCustomTittleText] = useState(false);
	const [customMessageModal, setCustomMessageModal] = useState(false);
	const [customCurrentItem, setCustomCurrentItem] = useState(false);
	const [showSuccessButton, setShowSuccessButton] = useState(true);
	const [isCreateNewItemAction, setIsCreateNewItemAction] = useState(false);
	const [deleteItemModal, setDeleteItemModal] = useState(false);
	const [showCustomFormModal, setShowCustomFormModal] = useState(false);
	const [successfullRequest, setSuccessfullRequest] = useState(false);
	const [cancelButtonText, setCancelButtonText] = useState("No");
	const [sucessButtonText, setSucessButtonText] = useState("Yes");

	const manageSuccessfullMesssage = (
		model,
		verb = "created",
		currentItemId = null,
	) => {
		setCustomCurrentItem(currentItemId);
		setSuccessfullRequest(true);
		setShowCustomFormModal(false);
		setCustomTittleText(`${verb}`);
		setCustomMessageModal(`${model} successfully ${verb}`);
		setShowSuccessButton(false);
		setShowModal(true);
		setCancelButtonText("Close");
		return false;
	};

	const manageResponseErrors = (
		errorMessage,
		model,
		verb = "creating",
		showTitle = false,
		customMessage = "",
	) => {
		setSuccessfullRequest(false);
		setShowCustomFormModal(false);
		if (!showTitle) {
			setCustomTittleText(`Error ${verb} ${model}`);
		} else {
			setCustomTittleText(customMessage);
		}
		setCustomMessageModal(errorMessage);
		setShowSuccessButton(false);
		setShowModal(true);
		setCancelButtonText("Close");
		return false;
	};

	const prepareModalToCreateItem = () => {
		setIsCreateNewItemAction(true);
		setCustomTittleText("Item does not exits");
		setCustomMessageModal("Do you want to create a new item?");
		setShowSuccessButton(true);
		setCancelButtonText("No");
		setSucessButtonText("Yes");
		setShowModal(true);
	};

	const manageDeleteMessage = (model) => {
		setCustomTittleText(`Delete ${model}`);
		setCustomMessageModal(`Are you sure you want to delete this ${model}?`);
		setCancelButtonText("No");
		setSucessButtonText("Yes");
		setShowSuccessButton(true);
	};

	const manageResponse = (response, action, model) => {
		const { data } = response;

		if (data.hasOwnProperty("message")) {
			manageResponseErrors(data.message, model, action);
		}

		if (data.hasOwnProperty("errors")) {
			Object.keys(data.errors).map((errorList) => {
				if (data.errors[errorList].length > 0) {
					manageResponseErrors(
						data.errors[errorList][0],
						model,
						action,
					);
				}
			});
		}
	};

	return {
		showModal,
		setShowModal,
		customTittleText,
		setCustomTittleText,
		customMessageModal,
		setCustomMessageModal,
		customCurrentItem,
		setCustomCurrentItem,
		showSuccessButton,
		setShowSuccessButton,
		manageResponseErrors,
		prepareModalToCreateItem,
		isCreateNewItemAction,
		setIsCreateNewItemAction,
		deleteItemModal,
		setDeleteItemModal,
		manageDeleteMessage,
		showCustomFormModal,
		setShowCustomFormModal,
		manageSuccessfullMesssage,
		successfullRequest,
		setSuccessfullRequest,
		manageResponse,
		cancelButtonText,
		setCancelButtonText,
		sucessButtonText,
		setSucessButtonText,
	};
}

export { useModalHook };
