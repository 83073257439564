import { useState, useEffect } from "react";
import createHttp from "../lib/http";
import { amountPerPagePagination } from "../context/constants";

var _ = require("lodash");
function useApiRequestTax(manageResponseErrors,
	manageResponse,
	manageSuccessfullMesssage,) {
    
        const [isLoading, setIsLoading] = useState(false);
        const [sincronizedItems, setSincronizedItems] = useState(false);
        const [filtered_data, setFilteredData] = useState([]);
        const [dataPagination, setDataPagination] = useState(1);
        const [taxSuggestions, setTaxSuggestions] = useState([]);

        useEffect(async () => {
            setIsLoading(true);
    
            let params = {};
            // itemFilters
            //     .filter((filter) => filter.value)
            //     .map((filter) => (params[filter.field_id] = filter.value));
    
            if (!sincronizedItems) {
                await createHttp
                    .get("api/taxes", { params })
                    .then((response) => {
                        if (response.data.status) {
                            // console.log(response.data.data);
                            // setUser(response.data.data);
                            setFilteredData(response.data.data);
                            const pagination = response.data.pagination;
                            setDataPagination(pagination);

                        } else {
                            //manage error
                            manageResponseErrors(
                                response.data.message,
                                "Tax",
                                "listing",
                            );
                        }
                        setSincronizedItems(true);
                        setIsLoading(false);
                    })
                    .catch((response) => {
                        // console.log("error", response);
                        setIsLoading(false);
                        setSincronizedItems(true);
                        manageResponseErrors(
                            "An error has occurred",
                            "Tax",
                            "listing",
                        );
                    });
            }
            setIsLoading(false);
        }, [sincronizedItems]);

        const addItem = async (newTax) => {
            const {tax, tax_name, only_for_group} = newTax;
            setIsLoading(true);
            let body = {
                tax:tax,
                tax_name:tax_name,
                only_for_group: only_for_group ? 1 : 0
            }
            // console.log(body);
            // return;
            await createHttp
                .post("api/taxes", body)
                .then((res) => {
                    if (!res.data.status) {
                        manageResponse(res, "creating", "Tax");
                    } else {
                        // console.log("tax created")
                        setSincronizedItems(false);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        };

        const updateItem = async (tax_obj) => {
            const {id, tax, tax_name, only_for_group} = tax_obj;
            setIsLoading(true);
            let body = {
                id:id,
                tax:tax,
                tax_name:tax_name,
                only_for_group: only_for_group ? 1 : 0
            }
            await createHttp
                .post(`api/taxes/${id}`, body)
                .then((res) => {
                    if (!res.data.status) {
                        manageResponse(res, "updating", "Tax");
                    } else {
                        // console.log("updated created")
                        setSincronizedItems(false);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        };

        const deleteItem = async (resourseUrl) => {
            setIsLoading(true);
            await createHttp
                .delete(resourseUrl)
                .then((res) => {
                    if (!res.data.status) {
                        manageResponse(res, "deleting", "Tax");
                    } else {
                        setSincronizedItems(false);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        };

        const getSalesTaxesPaginated = async (salesTaxesPaginationConfig) => {
            const {page, search, paginate} = salesTaxesPaginationConfig;
            setIsLoading(true);
                await createHttp
                    .get(`api/taxes?page=${page}&search=${search}&paginate=${paginate}`)
                    .then((response) => {
                        if (response.data.status) {
                            console.log(response.data.data);
                            setFilteredData(response.data.data);
                            const pagination = response.data.pagination;
                            setDataPagination(pagination);
                        } else {
                            //manage error
                            manageResponseErrors(
                                response.data.message,
                                "Tax",
                                "listing",
                            );
                        }
                        setIsLoading(false);
                    })
                    .catch((response) => {
                        // console.log("error", response);
                        setIsLoading(false);
                        setSincronizedItems(true);
                        manageResponseErrors(
                            "An error has occurred",
                            "Tax",
                            "listing",
                        );
                    });
        };

        const getTaxSearchSuggestions = async (search) => {
            setIsLoading(true);
                await createHttp
                    .get(`api/taxes?page=${1}&search=${search}&paginate=${20}`)
                    .then((response) => {
                        if (response.data.status) {
                            // console.log("Tax Group",response.data.data);
                            setTaxSuggestions(response.data.data);
                            
                        } else {
                            //manage error
                            manageResponseErrors(
                                response.data.message,
                                "Tax Group",
                                "listing",
                            );
                        }
                        setIsLoading(false);
                    })
                    .catch((response) => {
                        console.log("error", response);
                        setIsLoading(false);
                        setSincronizedItems(true);
                        manageResponseErrors(
                            "An error has occurred",
                            "Tax Group",
                            "listing",
                        );
                    });
        };

    return {
		isLoading,
		setIsLoading,
		sincronizedItems,
		setSincronizedItems,
		filtered_data,

		// user,
		// setFilteredData,
		// setUser,
		deleteItem,
        addItem,
        updateItem,
        getSalesTaxesPaginated,
        getTaxSearchSuggestions,
        taxSuggestions,
		// filtersDefinition,
		// setFiltersDefinition,
		// selectOnChange,
		// onChangeDateFilter,
		// filterInputChange,
		// itemFilters,
		// setItemFilters,
		dataPagination,
		setDataPagination,
	};
}

export {useApiRequestTax};