import React, { useCallback } from "react";
import AutocompleteMiu from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialUI from ".";
import Http from "../../lib/http";
import _debounce from "lodash/debounce";
import { FormGroup, FormHelperText, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  textError: {
    fontSize: '.7rem',
    marginBlockStart: 0
  }
});

export default function AutocompleteAsync({
  params = [], // ['id', 'name'] fields to search
  uri,
  label,
  state,
  error,
	setState,
  children,
  adapter,
  responseAdaptar,
  adapterOptions,
  keyName,
  initOptions = [],
  InputLabelProps = {},
  getDynamicParams,
	...others
}) {
  const classes = useStyles();

	const handleChange = (_, newValue) => {
    setState(newValue);
    setSearch("");
    setSearchDebounced("");
  }
	const hasError = !!error;
	const [loading, setLoading] = React.useState(false);
	const [key, setKey] = React.useState(0);
	const [search, setSearch] = React.useState("");
	const [searchDebounced, setSearchDebounced] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState(initOptions);

  React.useEffect(() => {
    if(initOptions.length)
      setOptions(initOptions);
  }, [initOptions]);
  
  React.useEffect(() => {
    if(!state) {
      setKey((prev) => prev + 1);
      setOptions([]);
    }
  }, [state]);

  
	const debounceFn = useCallback(_debounce((value) => {
    if(value)
      setSearchDebounced(value);
    else
      setLoading(false);
  }, 750)
  , [])
  
	React.useEffect(() => {
		let active = true;
    if(searchDebounced) {
      (async () => {
        try {
          setOptions([])
          const dynamicParams = !getDynamicParams ? params : (getDynamicParams instanceof Function) && getDynamicParams(searchDebounced)
          const _params = dynamicParams.reduce((acc, curr) => ({ ...acc, [curr]: searchDebounced }), {});
          const resp = await Http.get(uri, { params: _params });
          const { status, data } = resp;
          if (status && active) {
            setOptions(() => {
              let response = data
              if(adapterOptions && adapterOptions instanceof Function) return adapterOptions(response)
              if(responseAdaptar && responseAdaptar instanceof Function) {
                response = responseAdaptar(response)
                if(!adapter) return response
              }
              if(adapter && typeof adapter instanceof Function) response = response.map(adapter);
              return response;
            });
          }
        } catch (error) {
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setOptions([]);
      setLoading(false);
    }
		return () => {
			active = false;
		};
	}, [searchDebounced]);

	const handleSearchChange = (value) => {
		setLoading(true);
		setSearch(value);
		debounceFn(value);
	};

	return (
		<div className="w-100">
      {/* <pre className="text-small text-primary">{JSON.stringify(options, null, 2)}</pre> */}
      { !!children ? (
        <FormGroup className="flex-row flex-nowrap">
          <AutocompleteMiu
            key={key}
            fullWidth
            size="small"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={options}
            loading={loading && open}
            onChange={handleChange}
            value={state}
            renderInput={(params) => (
              <MaterialUI.Input
                {...params}
                label={label}
                state={search}
                setState={handleSearchChange}
                InputLabelProps={InputLabelProps}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading && open ? (<CircularProgress color="inherit" size={20} />) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            { ...others}
          />
          {children}
        </FormGroup>
      ) : (
        <AutocompleteMiu
          key={key}
          fullWidth
          size="small"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          options={options}
          loading={loading && open}
          onChange={handleChange}
          value={state}
          renderInput={(params) => (
            <MaterialUI.Input
              {...params}
              label={label}
              state={search}
              setState={handleSearchChange}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading && open ? (<CircularProgress color="inherit" size={20} />) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          { ...others}
        />
      )}
			{hasError && <FormHelperText className={classes.textError} error>{error}</FormHelperText>}
    </div>
	)
}
