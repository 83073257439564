import React from 'react';
import { TextField, FormHelperText, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#86b7fe',
      },
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#86b7fe !important',
    },
    '& .MuiFormLabel-root': {
      '&.Mui-focused': {
        color: '#86b7fe',
      },
    },
    '& .MuiInputBase-input': {
      backgroundColor: 'var(--bg-body)',
      borderRadius: 'inherit',
    },
  },
  textError: {
    fontSize: '.7rem',
    marginBlockStart: 0
  },
  inputDateContainer: {
    position: 'relative',
    "& label": {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '.7rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1',
      letterSpacing: '0.00938em',
      position: 'absolute',
      padding: '2.4px 6.4px',
      background: '#fff',
      borderRadius: '3px',
      left: '.5rem',
      top: '-.5rem',
    },
    "&:focus-within label": {
      color: "rgba(0, 0, 0, 0.54)",
    }
  },
  inputDate: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '7px 14px',
    borderRadius: '3px',
    inlineSize: '100%',
    background: "#fff",
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif, font-feature-settings',
    '&::-webkit-calendar-picker-indicator': {
      filter: 'contrast(0.3)',
    },
    "&:focus": {
      borderColor: '#86b7fe !important',
      outline: 'none',
    },
    "&:focus ~ label": {
      color: '#86b7fe !important',
    },
    "&:hover": {
      borderColor: '#86b7fe !important',
    }
  },
  labelError: {
    color: "#f44336 !important",
  },
  fieldError: {
    borderColor: "#f44336",
  },
});

const InputDate = ({ state, label, handleChange, hasError, error }) => {
  const classes = useStyles();
  return (<>
    <div className={classes.inputDateContainer}>
      <label className={clsx(hasError && classes.labelError)}>{label}</label>
      <input className={clsx(classes.inputDate, hasError && classes.fieldError)} type="date" value={state} onChange={handleChange} />
    </div>
    {hasError && <FormHelperText className={classes.textError} error>{error}</FormHelperText>}
  </>);
}

const InputField = ({ label, state, setState, error = '', children, className, type = 'text', formatter, size = 'small',  ...others }) => {
  const hasError = !!error;
  const classes = useStyles();

  const handleChange = (ev) => {
    if(setState && typeof setState == 'function') {
      if(formatter && typeof formatter == 'function') {
        setState(formatter(ev.target.value));
      } else {
        setState(ev.target.value);
      }
    }
  }

  if(String(type).toLowerCase() === 'date') return <InputDate state={state} label={label} hasError={hasError} error={error} handleChange={handleChange}  />

  return (
    <>
      <TextField
        fullWidth
        label={label}
        size={size}
        variant="outlined"
        value={state}
        onChange={handleChange}
        error={hasError}
        type={type}
        className={clsx(classes.root, className)}
        {...others}
      />
      {hasError && <FormHelperText className={classes.textError} error>{error}</FormHelperText>}
    </>
  );
}

export default InputField;