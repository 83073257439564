import { useCallback, useState } from 'react';
import Http from '../lib/http'

class Adapter {
  static response(data) {
    return data;
  }
}

const initialState = {
  id: '',
  name: '',
}

const useBrands = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [brand, setBrand] = useState({ ...initialState });
  const [errors, setErrors] = useState({});
  
  const store = useCallback(async(payload) => {
    try {
      setErrors({})
      setIsLoading(true);
      const { data: resp } = await Http.post(`/api/brands`, payload);
      const { status, data } = resp;
      if(status) {
        setBrand(() => Adapter.response(data));
        return data;
      } else {
        setErrors({ name: `The name "${payload?.name}" is already in use` });
        throw new Error("Error");
      }
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    store,

    isLoading,
    setIsLoading,
    brand,
    setBrand,
    errors,
    setErrors,
  }
}

export default useBrands;