import React, { useState,useContext } from "react";
import { Box, Card, CardContent, Divider, FormGroup, Grid, Modal, Backdrop, Fade } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import { FaReceipt, FaPause, FaFileInvoiceDollar } from "react-icons/fa";
import Swal from "sweetalert2";

import { PosDesktopContext } from '../../context/PosDesktopContext';
import { CashCounterContext } from '../../context/CashCounterContext';
import SwalAlerts from '../../utils/alerts.js';

import PosDesktopCart from './PosDesktopCart';
import MaterialUI from "../../components/MaterialUI";
import Panel from "../../components/Panel";
import { CostumerForm } from "../costumer/CostumerForm";
import AlertActions from "../../components/AlertAction";
import PosDesktopVoucherOptions from './PosDesktopVoucherOptions';
import PosDesktopHoldOrders from "./PosDesktopHoldOrders.jsx";
import PosDesktopOrders from "./PosDesktopOrders.jsx";
import ReceiptOptions from "../Receipt/ReceiptOptions.jsx";
import { SweetColors } from "../../utils/constants.js";

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chip: {
    fontSize: '.6rem',
    position: 'absolute',
    top: '-13%',
    left: '-13%',
    borderRadius: '50%',
    background: 'red',
    inlineSize: '18px',
    blockSize: '18px',
    display: 'grid',
    placeContent: 'center',
    fontFamily: 'Inter, sans-serif',
    lineHeight: '1',
  },
}));

const PosDesktopCartDetail = ({ children, ...props }) => {
  const classes = useStyles();
  const {
    setClient, 
    client,
    totalAmount,
    clearCart,
    totalQuantity,
    showVoucherOptions,
    processSale,
    billNumber,
    toggleShowHoldOrders,
    showPanelHoldOrders,
    findReceipt,
    loadingHoldReceipts,
    handleStoreReceiptClick,
    holdReceiptsPagination,
    setIsReceiptRegister,
    initStateClients,
    receiptId,
    showPanelReceipts,
    setShowPanelReceipts,
  } = useContext(PosDesktopContext);
  
  const {counterIsOpen} = useContext(CashCounterContext);
  const [showAlertCleanCart, setAlertCleanCart] = useState(false);
  const [showPanelCustomer, setShowPanelCustomer] = useState(false);

  const toggleShowPanelCustomer = () => setShowPanelCustomer((state) => !state);

  const handleResponseCreateCustomer = (resp) => {
    setClient(resp);
    toggleShowPanelCustomer();
  };

  const handlerResponseAlertCleanCart = (action) => {
    if(action === 'confirm') {
      clearCart();
    }
    setAlertCleanCart(false);
  }
  
  const handleCloseShowVoucherOptions = (...props) => {};

  const preConfirm = async (message) => {
    try {
      const { status, msg } = await handleStoreReceiptClick(true, message);
      if (!status) return Swal.showValidationMessage(JSON.stringify(msg));
      return true;
    } catch (error) {
      Swal.showValidationMessage(`Request failed: ${error}`);
    }
  };

  const handleStoreHoldOrderClick = async () => {
    if(!!receiptId) return;
    setIsReceiptRegister(false);
    const { isConfirmed } = await Swal.fire({
      title: "Hold Order",
      input: "textarea",
      inputLabel: "Enter your order note",
      inputPlaceholder: "Type here!",
      confirmButtonText: "Save and continue",
      confirmButtonColor: SweetColors.confirmButtonColor,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: false,
      },
      inputValidator: (value) => {
        if (!value) return "You need to write order note!";
      },
      preConfirm,
      allowOutsideClick: () => !Swal.isLoading()
    });
    if(isConfirmed) {
      setClient(null);
      clearCart();
      Swal.fire(SwalAlerts.success);
    }
  }

  const [currentReceipt, setCurrentReceipt] = useState({});
  const handleSearchReceiptClick = async () => {
    const { isConfirmed, value } = await Swal.fire({
      title: 'Search Receipt',
      text: "Enter Bill Number",
      input: "text",
      inputAttributes: { autocapitalize: "off", autocomplete: "off", maxLength: '10' },
      showCancelButton: true,
      confirmButtonText: "Search",
      showLoaderOnConfirm: true,
      preConfirm: async (idOrBillNumber) => {
        try {
          const { data } = await findReceipt(idOrBillNumber);
          if(!data.status) return Swal.showValidationMessage(`${String(idOrBillNumber).toUpperCase()}: ${data.msg}`);
          return data.data;
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });

    if(isConfirmed) {
      setCurrentReceipt(value);
      setShowModalReceipt(true);
    }
  }

  const closeModalReceipt = () => {
    setShowModalReceipt(false);
    setCurrentReceipt({});
  }

	const [showModalReceipt, setShowModalReceipt] = useState(false);
  const toggleShowPanelReceipts = () => setShowPanelReceipts((state) => !state);
    
	return (
    <>
      <Modal className={classes.modal} open={showModalReceipt} onClose={() => {}} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 300 }} disableEnforceFocus>
        <Box>
          <Fade in={showModalReceipt}>
            <ReceiptOptions onDone={closeModalReceipt} receipt={currentReceipt}/>
          </Fade>
        </Box>
      </Modal>
      <Panel open={showPanelHoldOrders} anchor="right" headerTitle="Hold orders" togglePanel={toggleShowHoldOrders}>
        <PosDesktopHoldOrders />
      </Panel>
      <Panel open={showPanelReceipts} anchor="right" headerTitle="Orders" togglePanel={toggleShowPanelReceipts}>
        <PosDesktopOrders />
      </Panel>
      <AlertActions  
        icon={'question'}
        title={'Clear cart'}
        message={'Are you sure you want to clear your shopping cart?'}
        buttonTextConfirm="Yes, clean"
        handler={handlerResponseAlertCleanCart}
        render={showAlertCleanCart}
      />
      <Modal className={classes.modal} open={showVoucherOptions} onClose={handleCloseShowVoucherOptions} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 300 }} disableEnforceFocus>
        <Box>
          <Fade in={showVoucherOptions}>
            <PosDesktopVoucherOptions billNumber={billNumber} />
          </Fade>
        </Box>
      </Modal>
			<Panel open={showPanelCustomer} anchor="right" togglePanel={toggleShowPanelCustomer} headerTitle="New Client">
        <CostumerForm handleResponse={handleResponseCreateCustomer} />
      </Panel>
      <Card {...props}>
        {!!children && children}
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} className="d-flex align-items-center gap-2 justify-content-between">
              <Box>
                <LocalMallOutlinedIcon />
                <span className="ps-2">Details</span>
              </Box>
              <Box className="d-flex gap-2">
                <MaterialUI.Button.Dark tooltip="View Orders" className="mw-auto" onClick={toggleShowPanelReceipts}>
                  <FaFileInvoiceDollar fontSize={16} />
                </MaterialUI.Button.Dark>
                <MaterialUI.Button.Primary tooltip="Search Receipt" className="mw-auto" onClick={handleSearchReceiptClick}>
                  <FaReceipt fontSize={16} />
                </MaterialUI.Button.Primary>
                <MaterialUI.Button.Darkblue 
                  tooltip="Check Hold Orders"
                  className="mw-auto position-relative"
                  onClick={toggleShowHoldOrders}
                  disabled={loadingHoldReceipts}
                >
                  <FaPause fontSize={16} />
                  {!!holdReceiptsPagination.total && <span className={classes.chip}>{holdReceiptsPagination.total}</span>}
                </MaterialUI.Button.Darkblue>
                <MaterialUI.Button.Danger 
                  tooltip="Clear cart"
                  className="mw-auto"
                  onClick={() => !!totalAmount ? setAlertCleanCart(true) : {} }
                  disabled={!totalAmount}
                >
                  <ShoppingBasketOutlinedIcon />
                </MaterialUI.Button.Danger>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider className="my-1" />
            </Grid>
            <Grid item xs={12} md={12} className='mb-3'>
              <FormGroup className='flex-row flex-nowrap'>
                <MaterialUI.AutocompleteAsync
                  uri={'/api/clients'}
                  params={['search_text']}
                  label="Search Client"
                  state={client}
                  setState={setClient}
                  className={'roundedSides'}
                  getOptionSelected={(option, value) => option?.id === value?.id}
                  getOptionLabel={(option) => (`${option.city} - ${option.name}`) ?? ''}
                  initOptions={initStateClients}
                >
                  <MaterialUI.Button.Primary type={'button'} className="roundedSides" size='small' onClick={toggleShowPanelCustomer}>
                    <AddCircleOutlineSharpIcon fontSize='small' />
                  </MaterialUI.Button.Primary>
                </MaterialUI.AutocompleteAsync>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={12} className='mb-3'>
              <PosDesktopCart />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container spacing={1}>
                <Grid item xs={10}>
                  <MaterialUI.Button.Success 
                    disabled={!totalQuantity || !counterIsOpen} 
                    onClick={processSale} 
                    tooltip={!!receiptId ? 'Pay Receipt' : 'Pay now'}
                    fullWidth
                    className="fs-5 justify-content-between font-inter py-2"
                  >
                    <span>Pay Now</span>
                    <strong>$ {Number(totalAmount).toFixed(2)}</strong>
                  </MaterialUI.Button.Success>
                </Grid>
                <Grid item xs={2}>
                  <MaterialUI.Button.Darkblue
                    disabled={!totalQuantity || !counterIsOpen || !!receiptId} 
                    onClick={handleStoreHoldOrderClick} 
                    fullWidth 
                    className="fs-5 justify-content-center font-inter py-2 h-100"
                  >
                    <FaPause />
                  </MaterialUI.Button.Darkblue>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
	);
};

export default PosDesktopCartDetail;
