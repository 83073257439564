import { useCallback, useEffect, useState } from 'react';
import StripeService from '../services/StripeService';

const initialState = {
  stripe_key: "",
  stripe_secret: "",
  stripe_mode: "",
  stripe_status: 0,
}

class AdapterStripe {
  static response(data) {
    return {
      ...data,
      stripe_status: +data.stripe_status
    }
  }
}

const useApiRequestSettingPaymentStripe = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [stripe, setStripe] = useState({ ...initialState });
  const [error, setError] = useState({});

  const fetchData = useCallback(async() => {
    try {
      setError({})
      setIsLoading(true);
      const { status, data } = await StripeService.getSetting();
      if(status) setStripe(() => AdapterStripe.response(data));
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const update = useCallback(async(payload) => {
    try {
      setError({})
      setIsLoading(true);
      const resp = await StripeService.updateSetting(payload);
      if(resp.status) setStripe(() => AdapterStripe.response(resp.data));
      else {
        setError(resp.msg);
        throw new Error("Error updating setting payment stripe");
      };
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const removeFromErrorByKey = (key) => {
    setError({
    ...error,
      [key]: ''
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return {
    removeFromErrorByKey,
    update,
    fetchData,
    setStripe,

    isLoading,
    stripe,
    error
  }
}

export default useApiRequestSettingPaymentStripe;