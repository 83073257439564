import React from 'react'
import { Grid } from '@material-ui/core';

export const Reports = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div className="d-flex gap-2 align-items-center">
          <div className="container-title">
            <h1 className="container__title mb-0 py-2">
              <i className="fas fa-file-signature me-2"></i>Reports
            </h1>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}
