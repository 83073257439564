import React from "react";
import { Grid, Box } from "@material-ui/core";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

import useUoms from "../../customHooks/useUoms";

import AlertComponent from '../../components/Alert';
import Material from "../../components/MaterialUI";

const types = [
  { label: "Volume", value: "Volume" },
  { label: "Area", value: "Area" },
  { label: "Quantity", value: "Quantity" },
  { label: "Mass", value: "Mass" },
  { label: "Length", value: "Length" },
];

const systems = [
  { label: "Imperial", value: "Imperial" },
  { label: "Metric", value: "Metric" },
  { label: "Both", value: "Both" },
]

const UomForm = ({ handleBehavior }) => {
	const { isLoading, errors, uom, store, setUom, setErrors } = useUoms();
  const [showAlert, setShowAlert] = React.useState(false);

	const handleClose = (_, reason) => {
		if (reason === 'clickaway') return;
    setShowAlert(false);
	};

	const handleSubmit = async (ev) => {
		ev.preventDefault();
    if(isLoading) return;
    try {
			const resp = await store(uom);
			if(resp) setShowAlert(true);
      if(handleBehavior && typeof handleBehavior === 'function')
        handleBehavior(resp);
		} catch (error) {}
	};

	const handleChange = (key, value) => {
    setErrors((prev) => ({ ...prev, [key]: '' }));
		setUom((state) => ({ ...state, [key]: value }));
	};

	return (
    <Box p={2}>
      <AlertComponent
				open={showAlert} 
        handleClose={handleClose} 
        severity="success" 
        message="It has been successfully created"
			/>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Material.Input 
              label={'Name'}
              state={uom.name}
              setState={(value) => handleChange('name', value)}
              error={errors.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Material.Input 
              label={'Symbol'}
              state={uom.symbol}
              setState={(value) => handleChange('symbol', value)}
              error={errors.symbol}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Material.Select
              label={'Type'}
              options={types}
              state={uom.type}
              setState={(value) => handleChange('type', value)}
              error={errors.type}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Material.Select
              label={'System'}
              options={systems}
              state={uom.system}
              setState={(value) => handleChange('system', value)}
              error={errors.system}
            />
          </Grid>
          <Grid item xs={12}>
            <Material.Button.Success
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
            >
              <SaveOutlinedIcon fontSize="small" className="me-2" />
              { isLoading ? 'Saving...' : 'Save' }
            </Material.Button.Success>
          </Grid>
        </Grid>
      </form>
    </Box>
	);
};

export default UomForm;
