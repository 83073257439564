import createHttp from "../lib/http";

const paypalService = {};

paypalService.getSetting = async () => {
  const { data } = await createHttp.get("/api/get_paypal_configuration");
  return data;
}

paypalService.updateSetting = async (payload) => {
  const { data } = await createHttp.post("/api/save_paypal_configuration", payload);
  return data;
}

export default paypalService;