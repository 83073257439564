import React, { useState } from "react";
import Header from "../Header/Header";
import Aside from "../Aside/Aside";
import { AppContext } from "../../context/AppContext";
import { useMediaQuery } from "@material-ui/core";
import { useLocation } from 'react-router-dom';

function Layout(props) {
  const { changeNavigationVisible } = React.useContext(AppContext);
	const [rtl] = useState(false);
	const location = useLocation();
	const isMobile = useMediaQuery('(max-width:600px)');

	React.useEffect(() => {
    changeNavigationVisible(isMobile);
  }, [isMobile]);

	React.useEffect(() => {
		if(isMobile) {
			changeNavigationVisible(true);
		}
  }, [location]);

	const styles = {
		overflow: "auto",
	};

	return (
		<div className={`app ${rtl ? "rtl" : ""}`}>
			<Aside rtl={rtl} />
			<main style={styles}>
				<Header />
				<div className="app-content">{props.children}</div>
			</main>
		</div>
	);
}

export default Layout;
