import { blockInvalidChar } from "../../context/constants";

export const typesAndValues = [
	{
		field_name: "name",
		type_field: "text_input",
		text_label: "Name",
		disabled: false,
		regexPattern: /^[A-Za-z0-9."', ]*$/,
		maxLength: 50,
		minLength: 1,
		col: "col-12 col-md-3",
		autoFocus: true,
		required: true,
	},
	{
		field_name: "max_employees",
		type_field: "text_input",
		text_label: "Max Employees",
		disabled: false,
		regexPattern: /^$|^[0-9]*$/,
		maxLength: 10,
		col: "col-12 col-md-3",
		type: "number",
		onKeyDown: blockInvalidChar,
	},
	{
		field_name: "max_storage_size",
		type_field: "text_input",
		text_label: "Max Storage Size",
		disabled: false,
		regexPattern: /^$|^[0-9]*$/,
		maxLength: 10,
		col: "col-12 col-md-3",
		type: "number",
		onKeyDown: blockInvalidChar,
	},
	{
		field_name: "storage_unit",
		type_field: "select_input",
		text_label: "Storage Unit",
		placeHolder: "select a storage unit",
		dontDefaultOption: false,
		regexPattern: /^$|^[a-z0-9]+$/i,
		values: [
			{ id: "mb", name: "MB" },
			{ id: "gb", name: "GB" },
		],
		selectCol: "col-12",
		selectWidth: "100%",
		// minLength: 1,
		col: "col-12 col-md-3",
		required: false,
	},
	{
		field_name: "monthly_price",
		type_field: "text_input",
		text_label: "Monthy Price",
		disabled: false,
		regexPattern: /^[0-9]*\.?[0-9]*$/,
		maxLength: 10,
		minLength: 1,
		col: "col-12 col-md-3",
		required: true,
		type: "number",
		onKeyDown: blockInvalidChar,
	},
	{
		field_name: "annual_price",
		type_field: "text_input",
		text_label: "Anual Price",
		disabled: false,
		regexPattern: /^[0-9]*\.?[0-9]*$/,
		maxLength: 10,
		minLength: 1,
		col: "col-12 col-md-3",
		required: true,
		type: "number",
		onKeyDown: blockInvalidChar,
	},
];

export const initData = {
	name: "",
	max_employees: "",
	max_storage_size: "",
	storage_unit: "mb",
	monthly_price: "",
	annual_price: "",
};

export const typesAndValuesRowTwo = [
	{
		field_name: "is_private",
		type_field: "checkbox_input",
		text_label: "",
		col: "col-6 col-md-3",
		dontSeeLabel: true,
		checkBoxValues: {
			is_private: true,
		},
		mapChechBoxNames: {
			is_private: "Make Private",
		},
	},
	{
		field_name: "is_recommended",
		type_field: "switch_input",
		text_label: "Recommended",
		col: "col-6 col-md-4",
		dontSeeLabel: true,
	},
];

export const initDataRowTwo = {
	is_private: true,
	is_recommended: true,
};

export const typesAndValuesMessagePackage = [
	{
		field_name: "description",
		type_field: "textArea",
		text_label: "Description",
		rows: "2",
		regexPattern: /^$|^[A-Za-z0-9."',@; ]*$/,
		col: "col-12 col-md-12",
		maxLength: 150,
		transformText: false,
		disabled: false,
	},
];

export const initDataMessagePackage = {
	description: "",
};
