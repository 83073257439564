import React from 'react';
import {Spinner, Modal, Button } from 'react-bootstrap';

// import { Document, Page } from 'react-pdf';

const PDFModal = ({ isOpen, closeModal, title, pdfUrl, isLoading }) => {
  return (
    <Modal show={isOpen} onHide={closeModal} size="lg">
      <Modal.Header style={{ background: 'var(--color-box)', color: 'var(--white)'}}>
        <Modal.Title as="h5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-0'>
        {isLoading ? 
          <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <Spinner animation="border" role="status" variant="primary"></Spinner>
              <span className="visually-hidden">Loading...</span>
          </div>

        :
          <embed src={pdfUrl} type="application/pdf" width="100%" height="500px"/>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PDFModal;
