const rules = [
  {
    name: 'warehouse_id',
    rules: ['required', 'numeric']
  },
	{
    name: 'vendor_id',
    rules: ['required']
  },
	{
    name: 'number',
    rules: ['required']
  },
	{
    name: 'expense_category_id',
    rules: ['required']
  },
	{
    name: 'amount',
    rules: ['required', 'decimal', 'format:100000,2']
  },
	{
    name: 'date',
    rules: ['required']
  },
  {
    name: 'type_payment_id',
    rules: ['required']
  },
	{
    name: 'payment_account_id',
    rules: ['required']
  },
	{
    name: 'date_paid',
    rules: ['required']
  },
]

export default rules;