export default class Adapter {
  static responsePaymentToAutocomplete(item) {
    return {
      id: item.id,
      label: item.name,
    };
  }
  static responseToForm(resp) {
    return {
      id: resp.id,
      name: resp.name,
      address: resp.address,
      address2: resp.address2,
      city: resp.city,
      state: resp.state,
      zip_code: resp.zip_code,
      country: resp.country,
      phone_number: resp.phone_number,
      fax: resp.fax,
      email: resp.email,
      website: resp.website,
      type_payment_id: resp.type_payment ? this.responsePaymentToAutocomplete(resp.type_payment) : null,
    }
  }
}