import React, { createContext, useEffect, useReducer, useState } from "react";
import reducerDynamic from "./reducers/reducerDynamic";
import { LOCAL_STORAGE_APP_STATE, USER_INITIAL_STATE } from "./constants";
import {
	APP_NAME,
	INITIAL_LOCAL_STORAGE_APP_STATE,
	LOCAL_STORAGE_APP_CONTEXT_DATA_VAR_NAME, LOCAL_STORAGE_NAVIGATION_EXPANDED_VAR_NAME,
	LOCAL_STORAGE_USER
} from "../utils/constants";
import { appLocalStorage } from "../utils/localStorage";
import { getUserFromLocalStorageOrStateLocal } from "./reducers/helpers";

const AppContext = createContext({});

const AppContextProvider = ({ children }) => {

	// API
	const [appStore, dispatch] = useReducer({}, {});
	const API = { appStore, dispatch };

	// APP DATA CONTEXT
	const [appData, setAppData] =
		useReducer(reducerDynamic, LOCAL_STORAGE_APP_STATE || INITIAL_LOCAL_STORAGE_APP_STATE);
	const changeOrAddValueToAddData = value => setAppData(value);
	useEffect(() => {
		appLocalStorage.setValue(LOCAL_STORAGE_APP_CONTEXT_DATA_VAR_NAME, appData);
	}, [appData]);
	const APP_DATA_CONTEXT = { appData, APP_NAME, changeOrAddValueToAddData };

	// SIDE PANEL
	const SIDE_PANEL_INITIAL_VALUE = undefined;
	const [sidePanelContent, setSidePanelContent] = useState(SIDE_PANEL_INITIAL_VALUE);
	const createSidePanel = (children, data = {}) => setSidePanelContent({ children, data });
	const removeSidePanel = () => setSidePanelContent(SIDE_PANEL_INITIAL_VALUE);
	const SIDE_PANEL = { createSidePanel, sidePanelContent, removeSidePanel };

	// UI
	const [navigationExpanded, setNavigationExpanded] = useState(false);
	const changeNavigationVisible = (value) => {
		setAppData({ [LOCAL_STORAGE_NAVIGATION_EXPANDED_VAR_NAME]: value });
		setNavigationExpanded(value);
	};

	const UI = {
		navigationExpanded,
		changeNavigationVisible
	};

	// USER
	const [user, setUser] = useState(getUserFromLocalStorageOrStateLocal(LOCAL_STORAGE_APP_STATE, LOCAL_STORAGE_USER));
	const userReset = () => {
		setUser(USER_INITIAL_STATE);
		setAppData({ [LOCAL_STORAGE_USER]: USER_INITIAL_STATE });
	};

	const userUpdate = (values) => {
		const NEW_USER_DATA = { ...user, ...values };
		setUser(NEW_USER_DATA);
		setAppData({ [LOCAL_STORAGE_USER]: NEW_USER_DATA });
	};

	const USER = {
		user,
		userReset,
		userUpdate
	};

	return (
		<AppContext.Provider value={{ ...APP_DATA_CONTEXT, ...SIDE_PANEL, ...UI, ...USER }}>
			{children}
		</AppContext.Provider>
	);


};

export { AppContext, AppContextProvider };
