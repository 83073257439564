const toObject = (queryString) => {
	if(!queryString) return {};
	queryString = queryString.substring(1);
	const queries = queryString.split("&");
	if (!queries.length) return {};
	const values = queries.reduce((acc, query) => {
		const [key, value] = query.split("=");
		const _key = decodeURIComponent(key);
		if (!acc[_key]) acc[_key] = "";
		acc[_key] = decodeURIComponent(value);
		return acc;
	}, {});
	return values;
};

export default { toObject };
