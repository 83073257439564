//styles
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FaCaretDown, FaLongArrowAltRight } from "react-icons/fa";

//Custom Hooks
import { useMenuActionHook } from "../../customHooks/useMenuActionHook";
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiRequestInvoice } from "../../customHooks/useApiRequestInvoice";
import { useHistory } from "react-router-dom";
import { usePaginationHook } from "../../customHooks/usePaginationHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";

//components
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import { ModalDeleteFromTable } from "../../components/ModalDeleteFromTable";
import { CustomFilterComponent } from "../../components/CustomFiltersComponent/CustomFiltersComponent";
import { CustomSelect, CustomInputFilter } from "../../components/customSelect/customSelect";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsyncInput } from "../../components/AsyncInput/AsyncInput";
import Dialog from '../../components/Dialog'
import { InvoiceEstimate } from './InvoiceEstimate'

import Http from '../../lib/http';

import {
	received,
	complete,
	posted,
	styles,
	tableHeadersInvoice as tableHeaders,
	dataFieldsInvoice as dataFields,
	composedFieldsInvoice as composedFields,
	arrayFieldsAjustment as arrayFields,
	mapListInvoice,
	tableHeadersToDataFields,
	dataFieldsToOrderFilter,
	tableHeadersToOrderFilter,
} from "../../context/constants";
import { PaginationControlled } from "../../components/CustomPagination/CustomPaginacion";
import PDFModal from "../../components/ModalPdf";

import "./invoice.css" ;
import { useMediaQuery } from "@material-ui/core";

const app_conf = {
	title: "Sales",
};

function Invoice() {
	const history = useHistory();
	const isMobile = useMediaQuery('(max-width:600px)');

	const {
		anchorEl,
		setAnchorEl,
		onMenuActions,
		onCloseModal,
		currentElement,
		setCurrentElement,
	} = useMenuActionHook();

	const {
		showModal,
		setShowModal,
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
		manageDeleteMessage,
		customTittleText,
		customMessageModal,
	} = useModalHook();

	const {
		isLoading,
		setIsLoading,
		filtered_data,
		setSincronizedItems,
		itemFilters,
		setItemFilters,
		itemPagination,
	} = useApiRequestInvoice(
		manageResponseErrors,
		manageResponse,
		manageSuccessfullMesssage,
		true,
		mapListInvoice,
	);

	const {
		filterInputChange,
		selectOnChange: interceptorSelectOnChange,
		onChangeNumberSelect,
		handlePagination,
		onChangeDateFilter,
		mixFilter,
		setMixFilter,
		onChangeAsyncFilter,
		onChangeOrderArrow,
	} = usePaginationHook(setSincronizedItems, setItemFilters, itemFilters);

	const { deleteItemByUrl } = useApiCustomRequest(
		manageResponseErrors,
		"invoices",
		"Invoice",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		() => {},
	);

	const [showEstimate, setShowEstimate] = React.useState(false);
	const [loadingEstimate, setLoadingEstimate] = React.useState(false);
	const [currentEstimate, setCurrentEstimate] = React.useState({});
	const fetchEstimate = async ({ id }) => {
		try {
			if(loadingEstimate) return;
			setShowEstimate(true);
			setLoadingEstimate(true);
			const { data } = await Http.get(`/api/estimates/${id}`);
			setCurrentEstimate(data.data)
		} catch (error) {}
		finally {
			setLoadingEstimate(false);
		}
	}

	const _lowercase = (word) => String(word).toLowerCase();
	const prepareToUpdate = async () => {
		if(_lowercase(currentElement?.type) === 'estimate' && _lowercase(currentElement?.status) === 'complete') {
			return fetchEstimate(currentElement);
		}
		if (currentElement?.type === "Invoice") {
			history.push({
				pathname: "/invoice_form_update",
				search: `?invoice_id=${currentElement.id}`,
			});
		} else if (currentElement?.type === "Estimate") {
			history.push({
				pathname: "/estimate_form_update",
				search: `?estimate_id=${currentElement.id}`,
			});
		} else if (currentElement?.type === "WorkOrder") {
			history.push({
				pathname: "/work_order_form_update",
				search: `?work_order_id=${currentElement.id}`,
			});
		}
	};

	const prepareToUpdateFromMobileTable = (element) => {
		setAnchorEl(null);

		if(_lowercase(element?.type) === 'estimate' && _lowercase(element?.status) === 'complete') {
			return fetchEstimate(element);
		}

		if (element?.type === "Invoice") {
			history.push({
				pathname: "/invoice_form_update",
				search: `?invoice_id=${element.id}`,
			});
		}
		if (element?.type === "Estimate") {
			history.push({
				pathname: "/estimate_form_update",
				search: `?estimate_id=${element.id}`,
			});
		}
		if (element?.type === "WorkOrder") {
			history.push({
				pathname: "/work_order_form_update",
				search: `?work_order_id=${element.id}`,
			});
		}
	};

	const prepareToDelete = () => {
		setAnchorEl(null);
		manageDeleteMessage(`${currentElement?.type}`);
		setShowModal(true);
	};

	const prepareToDeleteItemMobileTable = (currentItem) => {
		setCurrentElement(currentItem)
		setAnchorEl(null);
		manageDeleteMessage(`${currentItem?.type}`);
		setShowModal(true);
	};

	const actionsDefinition = [
		{
			name: "Edit",
			func: prepareToUpdate,
			icon: faEdit,
		},
		{
			name: "Delete",
			func: prepareToDelete,
			icon: faTrashAlt,
		},
	];

	//filter Implementation

	const mixFilterValues = [
		{
			field_id: "type_transaction",
			selectTittle: "Type",
			styles: styles,
			defaultOptionText: "All transactions",
			itemArray: [
				{ id: "invoice", name: "invoice" },
				{ id: "payment", name: "payment" },
				{ id: "estimate", name: "estimate" },
				{ id: "work order", name: "work order" },
			],
			defaultValue: "",
			// col: "col-6 col-md-4 col-lg-3",
			typeFilter: "select",
		},
		{
			field_id: "status",
			selectTittle: "Status",
			styles: styles,
			defaultOptionText: "All status",
			itemArray: [
				{ id: "Entry", name: "Entry" },
				{ id: "Complete", name: "Complete" },
			],
			defaultValue: "",
			// col: "col-6 col-md-4 col-lg-3",
			typeFilter: "select",
		},
		{
			field_id: "date_from",
			field_name: "date_from",
			labelText: "Start date",
			value: "",
			type: "date",
			// col: "col-6 col-md-4 col-lg-3",
			typeFilter: "input",
			defaultValue: "",
		},
		{
			field_id: "date_until",
			field_name: "date_until",
			labelText: "End date",
			value: "",
			type: "date",
			// col: "col-6 col-md-4 col-lg-3",
			typeFilter: "input",
			defaultValue: "",
		},
		{
			field_id: "client_id",
			labelText: "Costumer",
			defaultOptionText: "All",
			// col: "col-6 col-md-4 col-lg-3 d-flex align-items-end",
			typeFilter: "searchInput",
			apiRequest: "clients",
			queryParam: "search_text",
			defaultValue: "",
			dontInitData: false,
		},
	];

	useEffect(() => {
		setMixFilter([...mixFilterValues]);
	}, []);

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	useEffect(() => {
			if (searchParams.has('payment') || searchParams.has('workorders')) {
			  const value = searchParams.has('payment') ? 'payment' : 'work order';
		
			  selectOnChange({
				field_id: 'type_transaction',
				value: value,
			  });
			  setSincronizedItems(false);
			}
	}, [location]);

	const deleteModelItem = async () => {
		setShowModal(false);
		let urlParam = "";

		if (currentElement?.type === "Invoice") {
			urlParam = "invoices";
		}
		if (currentElement?.type === "Estimate") {
			urlParam = "estimates";
		}
		if (currentElement?.type === "WorkOrder") {
			urlParam = "work-orders";
		}

		await deleteItemByUrl(currentElement?.id, urlParam, true);

		setSincronizedItems(false);
	};

	const [optionListIndex, setOptionListIndex] = useState(null); // Estado para almacenar el índice del elemento con lista de opciones abierto

    const handleOptionListToggle = (index) => {
        setOptionListIndex(index === optionListIndex ? null : index); // Alternar la apertura y cierre de la lista de opciones
    };


	//generador de pdf
	const [PDFModalIsOpen, setPDFModalIsOpen] = useState(false); // Estado
	const [PDFUrl, setPDFUrl] = useState(false); // Estado

	const printPreview = async (currentElement) => {
		setCurrentElement(currentElement)
		setIsLoading(true);
		setPDFModalIsOpen(true);
		let url = "";
		console.log(currentElement)
		if (currentElement?.type === "Invoice") {
			url = `invoices/${currentElement.id}/generate`;
		}
		if (currentElement?.type === "Estimate") {
			url = `estimates/${currentElement.id}/generate`;
		}
		if (currentElement?.type === "WorkOrder") {
			url = `work-orders/${currentElement.id}/generate`;
		}
		// console.log(url)
		fetch(`${process.env.REACT_APP_API_URL}api/${url}`, {
			method: "GET",
			// mode: "cors",
			headers: new Headers({
				Accept: "application/octet-stream",
				Authorization:
					"Bearer " +
					(localStorage.jwt_token !== undefined
						? JSON.parse(localStorage.jwt_token)
						: ""),
			}),
		})
			.then((response) => response.blob())
			.then((blob) => {
				var file = window.URL.createObjectURL(blob);
				// window.location.assign(file);
				setPDFUrl(file);
			})
			.finally(()=>{
				setIsLoading(false);
			});
	};

	const [currentView, setCurrentView] = React.useState("");
	
	function selectOnChange(newFilter) {
		setCurrentView(newFilter?.value);
		interceptorSelectOnChange(newFilter);
	}

	React.useEffect(() => {
		if(itemFilters.length === 3) {
			const { value } = [...itemFilters].pop();
			setCurrentView(value)
		}
	}, [itemFilters]);
	
	useEffect(() => {
		const storedValue = localStorage.getItem("type_transaction_value");
		if (storedValue) {
			selectOnChange({
				field_id: 'type_transaction',
				value: storedValue,
			});
			setCurrentView(storedValue)
		}
	},[])

	const closeShowEstimate = () => {
		setShowEstimate(false);
		setCurrentEstimate({});
	}
	
	return (
		<>
			<Dialog
				open={showEstimate}
				onClose={closeShowEstimate}
				title={`Estimate ${!!currentEstimate.id ? `#${currentEstimate.id}` : ''}`}
				fullWidth
				children={(onClose) => <InvoiceEstimate estimate={currentEstimate} onClose={onClose} loadingEstimate={loadingEstimate} />}
			/>
			<div className="container-fluid contenedor invoice_container">
				<div className="container-title mb-3">
					<h1 className="container__title mb-0">{app_conf.title} {currentView}</h1>
				</div>

				<CustomFilterComponent
					filtersDefinition={[]}
					filtersTextDefinition={[]}
					mixFilter={mixFilter}
					onClickfilter={() => setSincronizedItems(false)}
					filterInputChange={filterInputChange}
					onCreateNewItem={() => {}}
					showDateFilters={false}
					onChangeDateFilter={onChangeDateFilter}
					styles={styles}
					onChangePageEvent={onChangeNumberSelect}
					dontShowPerPageSelect={false}
					dontShowCreateButton={true}
					showSelectButton={true}
					showFilterButtons={true}
					selectOnChange={selectOnChange}
					setSincronizedItems={setSincronizedItems}
					renderFilter={(filter, index) => (
						<CustomSelect
							selectCol={filter.col}
							key={index}
							field_id={filter.field_id}
							selectTittle={filter.selectTittle}
							styles={filter.styles}
							defaultOptionText={filter.defaultOptionText}
							itemArray={filter.itemArray}
							onChangeEvent={selectOnChange}
							selectWidth={"100%"}
							defaultValue={filter.defaultValue}
						/>
					)}
					renderFilterText={(filter, index) => (
						<CustomInputFilter
							selectCol={filter.col}
							key={index}
							filter={filter}
							onChangeDateFilter={onChangeDateFilter}
						/>
					)}
					renderSearchInputText={(filter, index) => (
						<AsyncInput
							selectCol={filter.col}
							key={index}
							filter={filter}
							onChangeAsyncFilter={onChangeAsyncFilter}
						/>
					)}
				/>
				<hr />
				{!isMobile ? (
					<div className="invoice-dataList-desktop-wrapper">
						<CustomDataList
							onChangePageEvent={onChangeNumberSelect}
							itemPagination={itemPagination}
							handlePagination={handlePagination}
							isLoading={isLoading}
							dataList={filtered_data}
							tableHeaders={tableHeaders}
							dontShowPerPageSelect={true}
							showOrderArrows={true}
							onChangeOrderArrow={onChangeOrderArrow}
							tableHeadersToDataFields={tableHeadersToDataFields}
							dataFieldsToOrderFilter={dataFieldsToOrderFilter}
							tableHeadersToOrderFilter={tableHeadersToOrderFilter}
							itemFilters={itemFilters}
							showLinearIndicator={true}
							showDataInLoading={true}
							render={(element, index) => (
								<CustomDataListItem
									key={index}
									dataFields={dataFields}
									imageFields={[]}
									composedFields={composedFields}
									arrayFields={arrayFields}
									element={element}
									index={index}
									no_image={null}
									renderActions={() => (
										<PositionedMenu
											title={"Actions"}
											anchorEl={anchorEl}
											setAnchorEl={setAnchorEl}
											handleClose={onCloseModal}
											handleClick={(event) => onMenuActions(event, element)}
											actionsDefinition={actionsDefinition}
											renderActions={(action, index) => (
												<MenuItem key={index} onClick={action.func}>
														<div className="items-option" style={{ padding: "0 5px", color: "grey" }}>
															{element.type === 'Estimate' && element.status === 'Complete' && action.name === "Edit" ? (
																<>
																	<i className="fas fa-eye text-primary me-1" style={{ fontSize: '.8rem', margin: "4px", marginRight: "10px" }}></i>
																	<p className="mb-0 fs-small">View</p>
																</>
															) : (
																<>
																	<FontAwesomeIcon
																		style={{ fontSize: '.8rem', margin: "4px", marginRight: "10px" }}
																		icon={action.icon}
																		className={
																			action.name === "Edit" 
																				? "text-primary"
																				: action.name === "Delete"
																					? 'text-danger'
																					: 'inherit'
																		}
																	/>
																	<p className="mb-0 fs-small">{action.name}</p>
																</>
															)}
														</div>
												</MenuItem>
											)}
										/>
									)}
									posted={posted}
									complete={complete}
									received={received}
								/>
							)}
						/>
					</div>
				) : (
					<div className="invoice-dataList-mobile-wrapper">
						<div
						style={{ width:"100%", fontSize:"12.8px", border:"1px solid gray"}}
						>
							{
								filtered_data.length > 0 && filtered_data.map((invoiceItem, index)=>(
								<div
								key={index}
								style={{
									display: "block",
									padding: "5px 10px",
									backgroundColor: index % 2 !== 0 ? "#f2f2f2" : "transparent",
								}}
							>
								<div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
									<div>
										<strong>ID:{invoiceItem.id}</strong>
										<p>Date: {invoiceItem.date_receipt}</p>
										<p>Balance: {invoiceItem.Balance}</p>
										<p>Type: {invoiceItem.type}</p>
									</div>
									<div
										style={{display:"flex", flexDirection:"column", alignItems:"flex-end"}}
									>
										<strong>Client:{invoiceItem.client}</strong>
										<p>Due date: {invoiceItem.date_due}</p>
										<p>Total: {invoiceItem.total}</p>
										<p>Status: {invoiceItem.status}</p>
									</div>
								</div>
								<div
								style={{display:"flex", justifyContent:"flex-end", width:"100%", fontWeight:"bold", color:"#2767c4"}}
								>
									<small
									onClick={() => prepareToUpdateFromMobileTable(invoiceItem)}
									style={{borderRight:"1px solid lightgrey", padding:"0 5px"}}
									>Edit</small>

									<small
									onClick={() => prepareToDeleteItemMobileTable(invoiceItem)}
									style={{borderRight:"1px solid lightgrey", padding:"0 5px"}}
									>Delete</small>

									<small onClick={() => handleOptionListToggle(index)} style={{ padding: "0 5px", position:"relative" }}>
										<FaCaretDown size={"20px"} />
										{optionListIndex === index &&(
											<div style={{ right:"0", borderRadius:"6px", padding: "5px 15px", position:"absolute" , zIndex:"10", background:"white", color:"black", boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
											<p 
												onClick={() => printPreview(invoiceItem)}
												style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}><FaLongArrowAltRight size={"18px"} /> View</p>
											<p style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}><FaLongArrowAltRight size={"18px"} /> Copy</p>
											<p 
												onClick={() => printPreview(invoiceItem)}
												style={{padding:"5px 0", display:"flex", alignItems:"center" , gap:"10px"}}>
												<FaLongArrowAltRight size={"18px"} /> Print</p>
											</div>
										)}
									</small>
								</div>
							</div>
								))
							}
							
						</div>
					</div>
				)}

				<div className="mobile-pagination-wrapper">
					{filtered_data.length > 0 && !isLoading ? (
							<PaginationControlled
								itemPagination={itemPagination}
								handlePagination={handlePagination}
							/>
					): <div style={{height:"85px"}}></div>
					}
				</div>



				{/* <CustomModal
					show={showModal}
					customTittleText={customTittleText}
					customMessageModal={customMessageModal}
					isLoading={false}
					onHandleClose={() => setShowModal(false)}
					showSuccessButton={showSuccessButton}
					onHandleSuccess={deleteModelItem}
				/> */}

				<ModalDeleteFromTable
					isOpen={showModal}
					handleClose={() => setShowModal(false)}
					// title={"Delete Stock Receipt ?"}
					title={customTittleText}
					// warningMessage={"Are you sure you want to delete item with bill number "}
					warningMessage={customMessageModal}
					onHandleSuccess={deleteModelItem}

				/>

				<PDFModal title={
					currentElement?.type+" PDF"
				} 
				isOpen={PDFModalIsOpen} closeModal={() => setPDFModalIsOpen(false)} pdfUrl={PDFUrl} isLoading={isLoading}/>
			</div>
		</>
	);
}

export { Invoice };
