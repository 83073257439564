import React from "react";
import { Modal, Button } from "react-bootstrap";

function ModalErrorMessage(props) {
	return (
		<React.Fragment>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={props.show}
				onHide={props.onHandleClose}
			>
				<Modal.Header>
					<Modal.Title> {props.modal_conf.title} </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="container-fluid">
						<div className="d-flex justify-content-center">
							<span className="text-center">
								{" "}
								{props.modal_conf.message}{" "}
							</span>
						</div>

						<br></br>

						<div className="d-flex justify-content-center">
							<div className="m-2">
								<Button
									onClick={props.onHandleClose}
									variant="outline-danger"
									size="sm"
								>
									Ok
								</Button>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</React.Fragment>
	);
}

export { ModalErrorMessage };
