import { useCallback, useState } from 'react';
import PaymentMethodService from '../services/PaymentMethodService';

class Adapter {
  static response(data) {
    return data;
  }
}

const initialState = {
  id: '',
  name: '',
}

const usePaymentMethod = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({ ...initialState });
  const [errors, setErrors] = useState({});
  
  const store = useCallback(async(payload) => {
    try {
      setErrors({})
      setIsLoading(true);
      const resp = await PaymentMethodService.store(payload);
      if(resp.status) {
        setPaymentMethod(() => Adapter.response(resp.data));
        return resp.data;
      } else {
        setErrors({ name: `The name "${payload.name}" is already in use` });
        throw new Error("Error Store Payment Method");
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    paymentMethod,
    errors,

    store,
    setErrors,
    setPaymentMethod,
  }
}

export default usePaymentMethod;