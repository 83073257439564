import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

const StyledTabs = withStyles({
	indicator: {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
		"& > span": {
			maxWidth: 40,
			width: "100%",
			height: "100%",
			backgroundColor: "transparent",
		},
	},
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
	root: {
		textTransform: "none",
		color: "",
		fontWeight: "700",
		fontSize: theme.typography.pxToRem(16),
		border: '1px solid #ced4da',
		borderBottom: 'none',
		"&:first-of-type": {
			borderRadius: '.4rem 0 0 0'
		},
		"&:last-of-type": {
			borderRadius: '0 0.4rem 0 0'
		},
		"&:not(:last-of-type)": {
			borderRight: 'none'
		}
	},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			style={{ border: '1px solid #ced4da', borderRadius: '0 0.4rem 0.4rem 0.4rem', padding: '1rem .5rem', ...other.style }}
		>
			{value === index && <Box style={{ overflow: 'hidden' }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	padding: {
		padding: theme.spacing(3),
	},
	demo1: {
		backgroundColor: theme.palette.background.paper,
	},
	demo2: {},
}));

function TabsComponent(props) {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		if (props.hasOwnProperty("onChangeTab")) {
			props.onChangeTab(newValue);
		}

		setValue(newValue);
	};

	const selectedStyles = {
		opacity: 1,
		backgroundColor: 'rgb(4 23 49)',
		color: 'white',
		border: '1px solid rgb(4 23 49)',
	};

	return (
		<div className={classes.root}>
			<div className={classes.demo2}>
				<StyledTabs
					value={value}
					onChange={handleChange}
					aria-label="styled tabs example"
				>
					<StyledTab
						key={1}
						label={!props.tabLabelOne ? "Parts" : props.tabLabelOne}
						{...a11yProps(0)}
						style={value === 0 ? selectedStyles : {}}
					/>
					<StyledTab
						key={2}
						label={!props.tabLabelTwo ? "Labor" : props.tabLabelTwo}
						{...a11yProps(1)}
						style={value === 1 ? selectedStyles : {}}
					/>
					{!!props.tabLabelThree && (
						<StyledTab
							key={3}
							label={props.tabLabelThree}
							{...a11yProps(2)}
							style={value === 2 ? selectedStyles : {}}
						/>
					)}
					{props.CustomButton && props.CustomButton}
				</StyledTabs>

				<TabPanel value={value} index={0}>
					{props.renderTabOne()}
				</TabPanel>
				<TabPanel value={value} index={1}>
					{props.renderTabTwo()}
				</TabPanel>
				{!!props.tabLabelThree && (
					<TabPanel value={value} index={1}>
						{props.renderTabThree()}
					</TabPanel>
				)}
			</div>
		</div>
	);
}

export { TabsComponent };
