import MaterialUI from "../MaterialUI";

function CustomSelect(props) {
	const {nextSiblingConfig = {}, hasNextSibling = false, handlerSiblings = () => {}} = props;

	return (
		<>
			<div style={{ textAlign: 'start' }} className={!props.selectCol ? "col-6" : props.selectCol}>
				{!!props.selectTittle && (
					<label className="form-label mb-0" style={props.styles}>
						{props.selectTittle}
					</label>
				)}
				<div className="input-group" style={{ flexWrap: hasNextSibling ? 'nowrap' : 'wrap' }}>
					<select
						disabled={!!props.disabled && props.disabled}
						style={{
							width: props.selectWidth || "auto",
						}}
						className={`${
							!!props.selectClass ? props.selectClass : "form-select"
						}`}
						value={props.defaultValue?.toString() || ""}
						onChange={(event) =>
							props.onChangeEvent({
								field_id: props.field_id,
								value: event.target.value,
							})
						}
					>
						{!props.dontDefaultOption && (
							<option value={""}>{props.defaultOptionText}</option>
						)}
						{props.itemArray.map((item) => (
							<option key={item.id} value={item.id}>
								{item.name}
							</option>
						))}
					</select>
					{hasNextSibling && (
						<MaterialUI.Button.Primary
							disableElevation
							type="button"
							style={{ maxInlineSize: '100px !important', minWidth: 'auto'}}
							tabIndex="-1"
							onClick={() => handlerSiblings({
								field_name: props.field_id,
								...nextSiblingConfig.data
							})}
						>
							{!!nextSiblingConfig.iconStart && <i className={nextSiblingConfig.iconStart}></i>}
							{!!nextSiblingConfig.text && <span className="px-1">{nextSiblingConfig.text}</span>}
							{!!nextSiblingConfig.iconEnd && <i className={nextSiblingConfig.iconEnd}></i>}
						</MaterialUI.Button.Primary>
					)}
				</div>
			</div>
		</>
	);
}

function CustomInputFilter(props) {
	return (
		<>
			<div style={{ textAlign: 'start' }} className={!props.selectCol ? "col-6" : props.selectCol}>
				<label
					htmlFor={props.filter.labelText}
					className="form-label mb-0 col-6"
					style={{
						fontSize: "14px",
						fontWeight: "bold",
					}}
				>
					{props.filter.labelText}
				</label>
				<input
					className="w-100 form-control"
					name={props.filter.name}
					value={props.filter.value}
					onChange={(e) =>
						props.onChangeDateFilter(
							e.target.value,
							props.filter.field_name,
						)
					}
					type={!props.filter.type ? "text" : props.filter.type}
					placeholder={props.filter.labelText}
					disabled={!!props.filter.disabled}
				/>
			</div>
		</>
	);
}

export { CustomSelect, CustomInputFilter };
