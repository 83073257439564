import { Button as  MuiButton, Tooltip, withStyles } from "@material-ui/core";

const ButtonWrapper = (styles) => withStyles(styles)(({ children, tooltip, type = 'button', ...props }) => {
	if(tooltip && !props.disabled) {
		return (
			<Tooltip title={tooltip} placement="top" arrow>
				<MuiButton {...props} type={type} variant={props.variant || 'contained' } style={{ textTransform: 'inherit', ...props.style }}>
					{children}
				</MuiButton>
			</Tooltip>
		)
	}
	return (
		<MuiButton {...props} type={type} variant={props.variant || 'contained' } style={{ textTransform: 'inherit', ...props.style }}>
			{children}
		</MuiButton>
	)
});

const styledDark = () => ({
  root: {
		color: "#fff",
		backgroundColor: "#444444",
		"&:hover": { backgroundColor: "#333333" }
	}
});

const styledSuccess = () => ({
  root: {
		color: "#fff",
		backgroundColor: "#198754",
		"&:hover": { backgroundColor: "#157347" }
	}
});

const styledPrimary = () => ({
  root: {
		color: "#fff",
		backgroundColor: "#0d6efd",
		"&:hover": { backgroundColor: "#0b5ed7" }
	}
});

const styledDanger = () => ({
  root: {
		color: "#fff",
		backgroundColor: "#ff6262",
		"&:hover": { backgroundColor: "#d45151" }
	}
});

const styledWarning = () => ({
  root: {
		color: "#000000de",
		backgroundColor: "#ffcf33",
		"&:hover": { backgroundColor: "#ffc400" }
	}
});

const styledSecondary = () => ({
  root: {
		color: "#fff",
		backgroundColor: "#6c757d",
		"&:hover": { backgroundColor: "#565e64" }
	}
});

const styledDarkblue = () => ({
  root: {
		color: "#fff",
		backgroundColor: "#041731",
		"&:hover": { backgroundColor: "#09274f" }
	}
});

export default {
	Dark: ButtonWrapper(styledDark),
	Success: ButtonWrapper(styledSuccess),
	Primary: ButtonWrapper(styledPrimary),
	Danger: ButtonWrapper(styledDanger),
	Warning: ButtonWrapper(styledWarning),
	Secondary: ButtonWrapper(styledSecondary),
	Darkblue: ButtonWrapper(styledDarkblue),
}