import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocalPrintshopOutlinedIcon from '@material-ui/icons/LocalPrintshopOutlined';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import ReactToPrint from 'react-to-print';
import Swal from "sweetalert2";
import SwalAlerts from '../../utils/alerts'
import { Checkbox, FormControlLabel } from '@material-ui/core';

import Http from '../../lib/http'

import MaterialUI from '../../components/MaterialUI'
import ReceiptPdf from '../Receipt/ReceiptPdf';
import { SweetColors } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: theme.shadows[5],
    background: '#fff',
    padding: '1.5rem 1rem',
    borderRadius: '.25rem',
    inlineSize: '280px',
    textAlign: 'center',
  },
  icon: {
    color: 'var(--bs-teal)',
    fontSize: '5rem'
  },
  toFixed: {
    fontSize: '11px',
    position: 'fixed',
    top: '0',
    right: '0',
    padding: '.5rem',
    blockSize: '100vh'
  }
}));

const ReceiptOptions = ({ onDone, receipt = {}, registered = false }) => {
  const classes = useStyles();
	const $receipt = React.useRef(null);
  const [showPreview, setShowPreview] = React.useState(false);
  
  const handleOnDoneClick = () => {
    if(onDone && onDone instanceof Function) {
      onDone();
    }
  }

  const preConfirmSendEMAIL = async (to) => {
    try {
      const { data } = await Http.post(`/api/send_receipt_pdf`, {
        receipt_id: receipt.id,
        client_email: to,
      });
      const { status, msg } = data;
      if (!status) return Swal.showValidationMessage(msg?.to ?? msg);
      return true;
    } catch (err) {
      Swal.showValidationMessage(`Request failed: ${err}`);
    }
  };

  const handleOnSendMailClick = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Send EMAIL",
      input: "text",
      inputLabel: "Enter email",
      inputValue: receipt.client ? receipt.client?.email === 'Not specified' ? '' : receipt.client?.email : '',
      confirmButtonText: "Send",
      confirmButtonColor: SweetColors.confirmButtonColor,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      html: `<div style="padding-top: .1rem"><i class="far fa-envelope me-2" style="font-size: 1.5rem"></i></div>`,
      inputAttributes: {
        type: 'email',
        autocapitalize: "off",
        autocomplete: "off",
        maxLength: '50',
        styled: 'input-phone-number',
      },
      inputValidator: (value) => {
        if (!value) return "You need to write an email";
      },
      preConfirm: preConfirmSendEMAIL,
      allowOutsideClick: () => !Swal.isLoading()
    });
    if(isConfirmed) Swal.fire(SwalAlerts.success);
  }

  const preConfirmSendSMS = async (phone_number) => {
    try {
      const { data } = await Http.post(`/api/send_receipt_sms`, {
        document_id: receipt.id,
        phone_number
      });
      const { status, msg } = data;
      if (!status) return Swal.showValidationMessage(msg.phone_number ?? msg);
      return true;
    } catch (error) {
      Swal.showValidationMessage(`Request failed: ${error}`);
    }
  };

  const handleOnSendMessageClick = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Send SMS",
      input: "text",
      inputLabel: "Enter phone number",
      inputValue: receipt.client ? receipt.client?.business_phone === 'Not specified' ? '' : receipt.client?.business_phone : '',
      confirmButtonText: "Send",
      confirmButtonColor: SweetColors.confirmButtonColor,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      html: `<div style="padding-top: .1rem"><i class="fas fa-mobile-alt me-2" style="font-size: 1.5rem"></i></div>`,
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off",
        maxLength: '10',
        styled: 'input-phone-number',
      },
      inputValidator: (value) => {
        if (!value) return "You need to write Phone Number";
      },
      preConfirm: preConfirmSendSMS,
      allowOutsideClick: () => !Swal.isLoading()
    });
    if(isConfirmed) Swal.fire(SwalAlerts.success);
  }

  return (
    <div className='position-relative'>
      <div className={classes.paper}>
        <h6 className="fs-small text-muted mb-3">Successful{registered && ' process'}</h6>
        <CheckCircleIcon className={clsx(classes.icon, 'mb-3')} />
        <div className="text-muted mb-5">
          <h6 className="fs-small mb-0">Voucher</h6>
          <h6 className="fs-small mb-3">has been {registered ? 'successfully registered' : 'found'}</h6>
          <h4 className="mb-0">{receipt.receipt_nro ?? '-'}</h4>
          <FormControlLabel
            control={
              <Checkbox
                checked={showPreview}
                onChange={() => setShowPreview((prev) => !prev)}
                size="small"
                name="showPreview"
                color="primary"
              />
            }
            label={<span className="fs-xs">Show Preview</span>}
          />
        </div>
        <div>
          <ReactToPrint
            trigger={() => (
              <MaterialUI.Button.Primary fullWidth className="mb-2 d-flex justify-content-start">
                <LocalPrintshopOutlinedIcon fontSize='small' />
                <span className='ms-2'>Print</span>
              </MaterialUI.Button.Primary>
            )}
            content={() => $receipt.current}
          />
          <MaterialUI.Button.Secondary fullWidth className="mb-2 d-flex justify-content-start" onClick={handleOnSendMailClick}>
            <MailOutlineRoundedIcon fontSize='small' />
            <span className='ms-2'>Send Mail</span>
          </MaterialUI.Button.Secondary>
          <MaterialUI.Button.Warning fullWidth className="mb-2 d-flex justify-content-start" onClick={handleOnSendMessageClick}>
            <i className="fas fa-paper-plane"></i>
            <span className='ms-2'>Send message</span>
          </MaterialUI.Button.Warning>
          {registered && <div className="voucher__options__divider font-inter">OR</div>}
          {registered ? (<>
            <MaterialUI.Button.Success fullWidth className="mb-2 d-flex justify-content-start" onClick={handleOnDoneClick}>
              <i className="fas fa-check-circle"></i>
              <span className='ms-2'>Done</span>
            </MaterialUI.Button.Success>  
          </>) : (<>
            <MaterialUI.Button.Danger fullWidth className="mb-2 d-flex justify-content-start" onClick={handleOnDoneClick}>
              <i className="fas fa-times-circle"></i>
              <span className='ms-2'>Close</span>
            </MaterialUI.Button.Danger>
          </>)}
        </div>
      </div>
      <div className={clsx('bg-white', showPreview ? classes.toFixed : 'd-none')} style={{ fontSize: '11px' }}>
        <ReceiptPdf ref={$receipt} receipt={receipt} />
      </div>
    </div>
  )
}

export default ReceiptOptions