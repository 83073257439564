import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';

import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import MenuItem from "@material-ui/core/MenuItem";
import no_image from "../../assets/img/no_image.png";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//CustomHooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiRequestVendor } from "../../customHooks/useApiRequestVendor";
import { useMenuActionHook } from "../../customHooks/useMenuActionHook";

import {
	styles,
	tableHeadersVendors as tableHeaders,
	dataFieldsVendors as dataFields,
} from "../../context/constants";
import MaterialUI from "../../components/MaterialUI";
import { Box, Grid, useMediaQuery } from "@material-ui/core";

const app_conf = {
	title: "Vendors",
};

function Vendor(props) {
	const history = useHistory();
	const isMobile = useMediaQuery('(max-width:600px)');

	//dataList Implementation
	const { anchorEl, setAnchorEl, onCloseModal, currentId, setCurrentId } =
		useMenuActionHook();

	const {
		showModal,
		setShowModal,
		customTittleText,
		setCustomTittleText,
		customMessageModal,
		showSuccessButton,
		manageResponseErrors,
		manageDeleteMessage,
		setCustomMessageModal,
		cancelButtonText,
		sucessButtonText,
	} = useModalHook();

	const {
		isLoading,
		filtered_data,
		deleteVendor,
		setSincronizedItems,
	} = useApiRequestVendor(manageResponseErrors);

	//handlers dataList
	const deleteItem = async () => {
		setShowModal(false);
		await deleteVendor(currentId);
	};

	const onMenuActions = async (event, id) => {
		setCurrentId(id);
		setAnchorEl(event.currentTarget);
	};

	const onDelete = (_, _id) => {
		setAnchorEl(null);
		manageDeleteMessage("Vendor");
		setCustomMessageModal(
			`Are you sure you want to delete item #${_id || currentId}?`,
		);
		setShowModal(true);
	};

	const onEdit = (_, _id) => {
		setCustomTittleText("Edit Vendor");
		setAnchorEl(null);
		history.push({
			pathname: "/vendor",
			state: { id: _id || currentId },
		});
	};

	const actionsDefinition = [
		{
			name: "Edit",
			func: onEdit,
			icon: faEdit,
		},
		{
			name: "Delete",
			func: onDelete,
			icon: faTrashAlt,
		},
	];

	const onCreateCategory = () => {
		setCustomTittleText("Create Vendor");
		setAnchorEl(null);
		setCurrentId(null);
		history.push("/create_vendor");
	};

	const [vendorsFiltered, setVendorsFiltered] = useState([]);	
	const filterInputChange = (ev) => {
		const value = ev;
		const key = props.keyToFind || "name";

		if (!value) {
			setSincronizedItems(false);
		}

		const updatedList = filtered_data.filter((item) => {
			return item[key].toLowerCase().search(value.toLowerCase()) !== -1;
		});

		setVendorsFiltered(updatedList);
	};

	React.useEffect(() => {
		setVendorsFiltered(filtered_data);
	}, [filtered_data])
	
	return (
		<div className="container-fluid contenedor">
			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={() => setShowModal(false)}
				onHandleSuccess={deleteItem}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
				actionsFooterStyle={true}
				cancelText={cancelButtonText}
				successText={sucessButtonText}
			/>
			<div className="container-title mb-3">
				<h1 className="container__title mb-0">{app_conf.title}</h1>
			</div>
			<div className={`${!!props.showSimpleInputTextFilter &&"d-flex justify-content-between"} ${!props.showSimpleInputTextFilter && "row"}`}>
				{!!props.showSimpleInputTextFilter && (
					<CustomInput
						inputId={"inputPassword6"}
						describedByArea={"passwordHelpInline"}
						spanText={"Enter a letter to filter in items"}
						labelText={"Filter"}
						inputOnChange={filterInputChange}
					/>
				)}
				<div
					className={`
                        ${!!props.showSimpleInputTextFilter && ""}
                        ${!props.showSimpleInputTextFilter && "col-12"}
                    `}
				>
					<MaterialUI.Button.Primary size="small" onClick={onCreateCategory}>
						<AddTwoToneIcon fontSize="small" className="me-1" />Add
					</MaterialUI.Button.Primary>
				</div>
			</div>
			<div>
				{!isMobile ? (
					<CustomDataList
						onChangePageEvent={() => {}}
						style={styles}
						isLoading={isLoading}
						dataList={vendorsFiltered}
						tableHeaders={tableHeaders}
						itemPagination={null}
						handlePagination={() => {}}
						dontShowPerPageSelect={true}
						showLinearIndicator={true}
						showDataInLoading={true}
						render={(element, index) => (
							<CustomDataListItem
								key={index}
								dataFields={dataFields}
								imageFields={[]}
								composedFields={[]}
								arrayFields={[]}
								element={element}
								index={index}
								no_image={no_image}
								renderActions={() => (
									<>
										<PositionedMenu
											title={"Actions"}
											anchorEl={anchorEl}
											setAnchorEl={setAnchorEl}
											handleClose={onCloseModal}
											handleClick={(event) =>
												onMenuActions(event, element.id)
											}
											actionsDefinition={actionsDefinition}
											renderActions={(action, index) => (
												<MenuItem
													sx={{
														boxShadow: "none",
													}}
													key={index}
													onClick={() => action.func()}
												>
													<div
														className="items-option"
														style={{
															padding: "0 5px",
															color: "grey",
														}}
													>
														<FontAwesomeIcon
															style={{
																fontSize: '.8rem',
																margin: "4px",
																marginRight: "10px",
															}}
															icon={action.icon}
															className={
																action.name === "Edit" 
																	? "text-primary"
																	: action.name === "Delete"
																		? 'text-danger'
																		: 'inherit'
															}
														/>
														<p className="mb-0 fs-small">{action.name}</p>
													</div>
												</MenuItem>
											)}
										></PositionedMenu>
									</>
								)}
							/>
						)}
					/>
				) : (
					<Grid container className="mb-3" spacing={2}>
						<Grid item xs={12}>
							{!!vendorsFiltered.length && (
								<Box className="table__mobile">
									{vendorsFiltered.map((row) => (
										<Box className="table__mobile__row" key={row.id}>
											<div className="fs-small">
												<div className="d-flex align-items-center"><strong>ID: </strong><span className="ps-1">{row.id}</span></div>
												<div className="d-flex align-items-center"><strong>Name: </strong><span className="ps-1">{row.name}</span></div>
												<div className="d-flex align-items-center"><strong>Phone: </strong><span className="ps-1">{row.phone_number}</span></div>
												<div className="d-flex align-items-center"><strong>Address: </strong><span className="ps-1">{row.address}</span></div>
												<div className="d-flex align-items-center"><strong>Vendor Since: </strong><span className="ps-1">{row.date_since}</span></div>
											</div>
											<div className="text-end fs-small">
												<div className="d-flex align-items-center justify-content-end"><strong>City: </strong><span className="ps-1 text-capitalize">{String(row.city).toLowerCase()}</span></div>
												<div className="d-flex align-items-center justify-content-end"><strong>Sate: </strong><span className="ps-1">{row.state}</span></div>
												<div className="d-flex align-items-center justify-content-end"><strong>Zip Code: </strong><span className="ps-1">{row.zip_code}</span></div>
												<div className="d-flex align-items-center justify-content-end"><strong>Email: </strong><span className="ps-1">{row.email}</span></div>
												<div className="d-flex align-items-center justify-content-end">
													<strong className="text-primary" role="button" onClick={() => onEdit(null, row.id)}>
														Edit
													</strong><span className="px-1">|</span>
													<strong className="text-primary" role="button" onClick={() => { setCurrentId(row.id); onDelete(null, row.id) }}>
														Delete
													</strong>
												</div>
											</div>
										</Box>
									))}
								</Box>
							)}
						</Grid>
					</Grid>
				)}
			</div>
		</div>
	);
}

export { Vendor };
