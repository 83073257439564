export const typesAndValues = [
	{
		field_name: "id",
		type_field: "text_input",
		text_label: "Id",
		disabled: true,
		regexPattern: /^[A-Za-z0-9]*$/,
		maxLength: 15,
	},
	{
		field_name: "symbol",
		type_field: "text_input",
		text_label: "symbol",
		disabled: false,
		regexPattern: /^[0-9A-Za-z\s\-]+$/,
		maxLength: 50,
		minLength: 1,
	},
	{
		field_name: "name",
		type_field: "text_input",
		text_label: "Name",
		disabled: false,
		regexPattern: /^[0-9A-Za-z\s\-]+$/,
		maxLength: 50,
		minLength: 1,
	},
	{
		field_name: "type",
		type_field: "select_input",
		text_label: "Type",
		placeHolder: "select a type",
		dontDefaultOption: false,
		values: [
			{ id: "Volume", name: "Volume" },
			{ id: "Area", name: "Area" },
			{ id: "Quantity", name: "Quantity" },
			{ id: "Mass", name: "Mass" },
			{ id: "Length", name: "Length" },
		],
		selectCol: "col-12",
		regexPattern: /^[a-z0-9]+$/i,
	},
	{
		field_name: "system",
		type_field: "select_input",
		text_label: "System",
		placeHolder: "select a system",
		dontDefaultOption: false,
		values: [
			{ id: "Imperial", name: "Imperial" },
			{ id: "Metric", name: "Metric" },
			{ id: "Both", name: "Both" },
		],
		selectCol: "col-12",
		regexPattern: /^[a-z0-9]+$/i,
	},
];

export const initData = {
	id: "",
	symbol: "",
	name: "",
	type: "",
	system: "",
};
