import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

// components
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import MenuItem from "@material-ui/core/MenuItem";
import no_image from "../../assets/img/no_image.png";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import editLogo from "../../assets/img/edit.jpg";
import adjunt from "../../assets/img/adjunt.png";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { ModalAddItem } from "../../components/modal_item_add";

//styles
import "./InvoiceForm.css";

// custom Hooks
// import { useApiRequest } from '../../../customHooks/useApiRequest'
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";
import { useMenuActionHook } from "../../customHooks/useMenuActionHook";
import { useApirequestStockReceipt } from "../../customHooks/useApirequestStockReceipt";
import { useApiRequestItems } from "../../customHooks/useApiRequestItems";

// form configuration
import {
	typesAndValues,
	typesAndValuesUpdateDetailInvoice,
	typesAndValuesMessageOnInvoice,
	initData as initDataValues,
	initDataUpdateDetailInvoice,
	initDataMessageOnInvoice as initDataMessageOnInvoiceValues,
	typesAndValuesBillingAddress,
	initDataBillingAddress as initDataBillingAddressValues,
	typesAndValuesPartNumber,
	initDataPartNumber as initDataPartNumberValues,
	PanelNames,
} from "./initFormEstimate";

import {
	styles,
	tableHeadersInvoiceDetail as tableHeaders,
	dataFieldsInvoiceDetail as dataFields,
	blockInvalidChar,
} from "../../context/constants";
import Panel from "../../components/Panel";
import { CostumerForm } from "../costumer/CostumerForm";



function EstimateForm(props) {
	const location = useLocation();
	const history = useHistory();

	const [goToMainPage, setGoToMainPage] = React.useState(false);

	const { anchorEl, setAnchorEl, onCloseModal, currentId, setCurrentId } =
		useMenuActionHook();

	const {
		setCustomMessageModal,
		showSuccessButton,
		setShowSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		setCustomTittleText,
		customMessageModal,
		manageResponseErrors,
		customCurrentItem,
		setCustomCurrentItem,
		manageResponse,
		manageSuccessfullMesssage,
	} = useModalHook();

	const {
		getDocuments,
		deleteDocuments,
		addDocuments,
		isLoading:stock_isLoading,
		setIsLoading:stock_setIsLoading,
		addImages,
		stock_receipt_details,
		setStockReceiptDetails,
		getDataFromUser,
		setStockReceiptId,
		setSincronizedItems: setSincronizedItemsList,
		receipt_stock_number,
		setReceiptStockNumber,
		bill_number,
		setBillNumber,
		vendor_id,
		setVendorId,
		vendor_name,
		setVendorName,
		date,
		setDate,
		date_due,
		setDateDue,
		description,
		setDescription,
		payment,
		setPayment,
		quantity,
		setQuantity,
		cost,
		setCost,
		location_id,
		setLocationId,
		location_name,
		setLocationName,
		notes,
		setNotes,
		atached_document,
		setAtachedDocument,
		total_receipt,
		setTotalReceipt,
		tax_receipt,
		setTaxReceipt,
		vendors,
		setVendors,
		location_list,
		setLocationList,
		insertVendor,
		payment_number,
		setPaymentNumber,
		part_number_id,
		setPartNumberId,
		part_number_name,
		setPartNumberName,
		disabledActions,
		setDisabledActions,
		updateStockReceiptItemPostStatus,
		updateStockReceiptPostStatus,
		stockReceiptStatus,
		skuNumber,
		setSkuNumber,
		saveStockReceiptModel,
		saveStockReceiptItem,
		updateStockReceiptItem,
		currentStockReceiptCost,
	} = useApirequestStockReceipt(
		setCustomTittleText,
		setCustomMessageModal,
		showSuccessButton,
		setShowModal,
	);

	const {
		filteredData: item_list,
		setSincronizedItems,
		getItemList,
		itemsNotFound,
		getSkuItemInformation,
	} = useApiRequestItems(
		stock_setIsLoading,
		setPaymentNumber,
		setPartNumberId,
		setPartNumberName,
		setPartNumberId,
		setPartNumberName,
	);

	const setGoToMainPageRedirect = (redirect) => {
		if (redirect) {
			setTimeout(() => {
				console.log("customCurrentItem", customCurrentItem);
				if (
					customCurrentItem &&
					customCurrentItem.hasOwnProperty("id")
				) {
					history.push({
						pathname: "/invoice_form_update",
						search: `?invoice_id=${customCurrentItem.id}`,
					});
				} else {
					history.push("/invoice");
				}
			}, 500);
		}
	};

	const { isLoading, createItem, getListItem } = useApiCustomRequest(
		manageResponseErrors,
		"estimates",
		"Estimate",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		// setGoToMainPage
		setGoToMainPageRedirect,
		false,
		() => {},
		setCustomCurrentItem,
	);

	const onHandleResponseModal = () => {
		setShowModal(false);
	};

	const onHandleResponseModalSuccess = () => {
		setShowModal(false);
		history.push("/invoice");
	};

	const [formAmountValues, setFormAmountValues] = React.useState({
		discount_percentage: 0.0,
		deposit: 0.0,
		discount_total: 0.0,
	});

	const onChangeFormAmountValues = (ev) => {
		let regex = /^[0-9]*\.?[0-9]*$/;

		if (!regex.test(ev.target.value)) {
			return;
		}

		if (
			Number(ev.target.value) > 200 &&
			ev.target.name === "discount_percentage"
		) {
			return;
		}

		setFormAmountValues({
			...formAmountValues,
			[ev.target.name]: ev.target.value,
		});
	};

	const [typesAndValuesFields, setTypesAndValuesFields] = React.useState([]);
	const [initData, setInitData] = React.useState({});
	const [changeInForm, setChangeInForm] = React.useState(false);

	//->
	const [initDataMessageOnInvoice, setInitDataMessageOnInvoice] =
		React.useState({});

	const onChangeFieldsInitDataMessageOnInvoice = async (event) => {
		setInitDataMessageOnInvoice({ ...event });
	};

	useEffect(() => {
		const initDataMessageOnInvoiceValuesCopy = {
			...initDataMessageOnInvoiceValues,
		};
		setInitDataMessageOnInvoice({ ...initDataMessageOnInvoiceValuesCopy });
	}, []);

	//->

	//->
	const [initDataBillingAddress, setInitDataBillingAddress] = React.useState(
		{},
	);

	const onChangeFieldsInitDatainitDataBillingAddress = async (event) => {
		setInitDataBillingAddress({ ...event });
	};

	useEffect(() => {
		const initDataBillingAddressCopy = { ...initDataBillingAddressValues };
		setInitDataBillingAddress({ ...initDataBillingAddressCopy });
	}, []);

	const onChangeSearchInputCustomer = (ev) => {
		let initDataCopy = { ...initData };
		let fieldsAndValuesCopy = [...typesAndValuesFields];

		setInitDataBillingAddress({});

		const initDataBillingAddressCopy = { ...initDataBillingAddressValues };
		if (ev) {
			setTimeout(() => {
				const {
					name,
					address_1,
					address_2,
					city,
					state,
					postal_code,
					country,
					email,
					id,
				} = ev;

				initDataCopy = {
					...initDataCopy,
					client_send_email: email,
					client_id: id,
				};

				const idFieldIndex = fieldsAndValuesCopy.findIndex(
					(item) => item.field_name === "client_id",
				);
				let fieldId = fieldsAndValuesCopy[idFieldIndex];
				fieldId.inputValue = ``;
				fieldId.doRequest = false;
				fieldId.dontInitData = true;
				fieldId.inputValue = `${name}`;

				const newMessage = `${name},${address_1},${address_2},${city} ${state} ${postal_code},${country}`;
				initDataBillingAddressCopy.comments = newMessage;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
				setInitData({ ...initDataCopy });
				setTypesAndValuesFields([...fieldsAndValuesCopy]);
			}, 0);
		}

		if (!ev) {
			setTimeout(() => {
				initDataBillingAddressCopy.comments =
					initDataBillingAddressValues.comments;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
			}, 1);
		}
	};
	//->

	//->
	const [initDataPartNumber, setInitDataPartNumber] = React.useState({});

	const onChangeFieldsInitDataPartNumber = async (event) => {
		setInitDataPartNumber({ ...event });
	};

	useEffect(() => {
		const initDataPartNumberValuesCopy = { ...initDataPartNumberValues };
		setInitDataPartNumber({ ...initDataPartNumberValuesCopy });
	}, []);
	//->

	const onChangeFieldsInitData = async (event) => {
		console.log("copy event", event);
		setInitData({ ...event });
	};

	const validateLength = (array) => {
		return array.length > 0;
	};

	const childFunc = useRef(null);
	const childFunc2 = useRef(null);
	const childFunc3 = useRef(null);
	const onHandleSubmit = async (event) => {
		if (isLoading) return;

		if (event === "Add" && !initDataPartNumber.item_id) {
			return;
		}

		const errorsOne = childFunc.current(initData);
		const errorsTwo = childFunc2.current(initDataMessageOnInvoice);
		const errorsThree = childFunc3.current(initDataPartNumber);

		if (
			!validateLength(errorsOne) &&
			!validateLength(errorsTwo) &&
			!validateLength(errorsThree)
		) {
			let bodyRequest = {
				...initData,
				...initDataMessageOnInvoice,
				// ...initDataPartNumber,
				...formAmountValues,
				type: "Estimate",
			};

			let finalRequest = {
				...bodyRequest,
			};

			if (initDataPartNumber.item_id) {
				finalRequest = {
					...finalRequest,
					...initDataPartNumber,
				};
			}

			let response = await createItem(finalRequest, "Estimate", {}, true);
			setShowModal(false);
			if (response && response.hasOwnProperty("id")) {
				history.push({
					pathname: "/estimate_form_update",
					search: `?estimate_id=${response.id}`,
				});
			}
		}
	};

	useEffect(() => {
		let initDataValuesCopy = { ...initDataValues };

		if (
			location.hasOwnProperty("data") &&
			location?.data.hasOwnProperty("customerData") &&
			Object.keys(location.data.customerData).length > 0
		) {
			const {
				id,
				name,
				address_1,
				address_2,
				city,
				state,
				postal_code,
				country,
				email,
			} = location.data.customerData;

			initDataValuesCopy = {
				...initDataValuesCopy,
				client_id: id,
				date_receipt: new Date().toLocaleDateString("en-CA", {
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
				}),
				client_send_email: email,
			};

			setInitDataBillingAddress({});

			setTimeout(() => {
				const initDataBillingAddressCopy = {
					...initDataBillingAddressValues,
				};
				const newMessage = `${name},${address_1},${address_2},${city} ${state} ${postal_code},${country}`;
				initDataBillingAddressCopy.comments = newMessage;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
			}, 1);
		}

		initDataValuesCopy = {
			...initDataValuesCopy,
			date_receipt: new Date().toLocaleDateString("en-CA", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}),
		};

		setInitData({ ...initDataValuesCopy });
	}, []);

	useEffect(async () => {
		let fieldsAndValuesCopy = [...typesAndValues];

		const idFieldIndex = fieldsAndValuesCopy.findIndex(
			(item) => item.field_name === "client_id",
		);
		let fieldId = fieldsAndValuesCopy[idFieldIndex];
		fieldId.inputValue = ``;
		fieldId.doRequest = false;

		if (
			location.hasOwnProperty("data") &&
			location?.data.hasOwnProperty("customerData") &&
			Object.keys(location.data.customerData).length > 0
		) {
			const { name } = location.data.customerData;
			fieldId.inputValue = `${name}`;
			fieldId.dontInitData = true;
		}

		setTypesAndValuesFields([...fieldsAndValuesCopy]);
	}, []);

	//redirect module
	const buttonClick = (field_id, typeSave = "Add") => {
		console.log("field_id", field_id);
		if (field_id === "createCustomer") {
			history.push({
				pathname: `/costumer_form`,
				data: { redirectTo: "/estimate_form" },
			});
		}
		if (field_id === "onSubmit") {
			onHandleSubmit(typeSave);
		}
		if (field_id === "Save") {
			onHandleSubmit(typeSave);
		}
	};

	const onDelete = (event) => {};

	const onEdit = () => {};

	const actionsDefinition = [
		{
			name: "Edit",
			func: onEdit,
		},
		{
			name: "Delete",
			func: onDelete,
		},
	];

	//adjustement detail implementation
	const onMenuActions = async (event, element) => {};

	const testData = [];

	const [show, setShow] = React.useState(false);
	const prepareDetailToUpdate = (index) => {
		const item = testData.findIndex(
			(itemData) => itemData.part_number == index,
		);
		setCurrentId(item);
		setShow(true);
	};

	const cancelInvoice = () => {
		if (changeInForm) {
			setShowModal(true);
			setCustomTittleText(`Leave estimate creating`);
			setCustomMessageModal(`Do you want to leave without saving?`);
			setShowSuccessButton(true);
		} else {
			history.push("/invoice");
		}
	};

	const [show_add, setShowAdd] = useState(false);

	const handleShowAdd = () => setShowAdd(true);

	const getElementWhenAddNewItem = () => {
		stock_setIsLoading(true);
		setSincronizedItems(false);
	};

	const [modal_add_conf, setModalAddConf] = useState({
		title: "Add new Item",
		message: "",
	});

	const [template_errors, setTemplateErrors] = useState([]);
	const [template_errors_all, setTemplateErrorsAll] = useState([]);

	function validateFields() {
		setTemplateErrors([]);

		//validamos que existan espacios vacios

		let nedd_to_validate = false;

		if (skuNumber.toString().trim() !== "") {
			nedd_to_validate = true;
		}
		if (location_name.toString().trim() !== "") {
			nedd_to_validate = true;
		}
		if (parseFloat(cost) > 0 || cost.toString().trim() !== "") {
			nedd_to_validate = true;
		}

		if (!nedd_to_validate) {
			return false;
		}

		let falidate_fields_errors = [];

		// if(part_number_id.toString().trim() === '' ){

		// 		falidate_fields_errors.push({
		// 				'field_id' : 'part_number_id',
		// 				'message'  : 'Part Number need to be valid'
		// 		});

		// }

		if (skuNumber.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "part_number_name",
				message: "Part Number need to be valid",
			});
		}

		if (item_list.length > 0) {
			let exists_item = item_list.find(
				(element) => element.name === part_number_name.toString(),
			);

			if (exists_item === undefined) {
				falidate_fields_errors.push({
					field_id: "part_number_name",
					message: "Part number entered not exists",
				});
			}
		}

		if (quantity <= 0) {
			falidate_fields_errors.push({
				field_id: "quantity",
				message: "Quantity need to be greater than 0",
			});
		}

		if (parseFloat(cost) <= 0 || cost.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "cost",
				message: "Cost need to be greater than 0",
			});
		}

		if (falidate_fields_errors.length > 0) {
			setTemplateErrors(falidate_fields_errors);

			return false;
		}

		return true;
	}

	function addNewStockReceiptDetail(
		itemId,
		itemName,
		validateField = true,
		currentCost = null,
	) {
		if (validateField) {
			if (!validateFields()) {
				return false;
			}

			let exists_same_item = stock_receipt_details.find(
				(element) =>
					element?.item_id === itemId &&
					element?.location_id === location_id &&
					element?.cost === cost &&
					element?.quantity === quantity,
			);

			if (exists_same_item) {
				console.log("exists_same_item", exists_same_item);

				manageResponseErrors(
					"The item is already added",
					"Stock Receipt Items",
					"updating",
				);

				return false;
			}
		}
	}

	const createNewStockReceipt = async (
		dataId,
		dataName,
		stockReceiptId,
		errorCreating = false,
		currentCost = null,
		validateField = true,
	) => {
		let newStockReceiptItem = addNewStockReceiptDetail(
			dataId,
			dataName,
			validateField,
			currentCost,
		);
	}

	const handleCloseAdd = async (currentSku, currentCost) => {
		setShowAdd(false);

		if (skuNumber || currentSku) {
			// setIsLoading(true);
			const { data } = await getSkuItemInformation(
				skuNumber ? skuNumber : currentSku,
			);

			if (!(Object.keys(data).length === 0)) {
				await createNewStockReceipt(
					data.id,
					data.name,
					receipt_stock_number,
					false,
					currentCost,
					false, //validate fields
				);
				setSincronizedItemsList(false);
			}
			stock_setIsLoading(false);
		}
	};

	const [showPanelCustomer, setShowPanelCustomer] = useState(false);
  const toggleShowPanelCustomer = () => setShowPanelCustomer((state) => !state);
	const handleResponseCreateCustomer = (customer) => {
		onChangeSearchInputCustomer(customer);
		toggleShowPanelCustomer();
	}
	const handleSiblings = (prop = {}) => {
		switch (prop.panel) {
			case PanelNames.PANEL_CUSTOMER:
				toggleShowPanelCustomer();
				break;
			case PanelNames.PANEL_PRODUCT:
				handleShowAdd()
				break;
			default:
				break;
		}
	}

	return (
		<>
			<Panel open={showPanelCustomer} anchor="right" togglePanel={toggleShowPanelCustomer} headerTitle="New Client">
        <CostumerForm handleResponse={handleResponseCreateCustomer} />
      </Panel>
			<div className="contenedor container-fluid" style={{ position: "relative" }}>
				<div className="container-title mb-2">
					<h1 className="container__title mb-0">
						{props.actionTittle + " " + props.model}
					</h1>
					<div className="action-buttons-container">
						<div className="save-buttton">
							<CustomButton
								buttonName={"Save"}
								buttonOnClick={() => buttonClick("Save", "save")}
								type={"success"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
								style={{ paddingInline: '1.5rem', fontSize: '13px' }}
							/>
						</div>
						<div className="cancel-button">
							<CustomButton
								buttonName={"Cancel"}
								buttonOnClick={cancelInvoice}
								type={"secondary"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
								style={{ paddingInline: '1.5rem', fontSize: '13px' }}
							/>
						</div>
					</div>
				</div>

				<div className="row mt-1">
					{typesAndValuesFields.length > 0 &&
						Object.keys(initData).length > 0 && (
							<>
								<div className="col-12">
									<CustomForm
										onHandleFormClose={() => {}}
										onHandleSubmit={onHandleSubmit}
										typesAndValuesFields={typesAndValuesFields}
										initData={initData}
										formTittle={""}
										dontShowCancelButton={true}
										id={null}
										getUrlPath={""}
										inputConf={{
											marginTop: "1",
											marginBottom: "0",
											stringCol: "col-12 col-md-4 col-lg-2",
										}}
										buttonClick={buttonClick}
										onChangeFields={onChangeFieldsInitData}
										initOnChangeFields={onChangeFieldsInitData}
										dontShowSubmitButton={true}
										childFunc={childFunc}
										dontShowErrosinOnChange={true}
										getObjectInfoOnChange={onChangeSearchInputCustomer}
										notifyChange={() =>setChangeInForm(true)}
										handlerSiblings={handleSiblings}
									/>
								</div>
							</>
						)}
				</div>

				<div className="row justify-content-between">
					<div className="col-12 col-md-4">
						<div className="billing_address_container">
							<label>Billing Address</label>
							<address
								className="address-container border-lightgray"
								style={{
									height:
										Object.keys(initDataBillingAddress)
											.length > 0 &&
										initDataBillingAddress.comments
											.length > 0
											? "auto"
											: "131px",
								}}
							>
								{Object.keys(initDataBillingAddress)
									.length > 0 &&
									initDataBillingAddress.comments.length >
										0 &&
									initDataBillingAddress.comments
										.split(",")
										.map((comment, key) => (
											<React.Fragment key={key}>
												{comment}
												<br />
											</React.Fragment>
										))}
							</address>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="balance-table">
							<label className="balance-table-balance-due">
								Balance Due
							</label>
							<div className="balance-table-balance-total">
								<span>$ 0.00</span>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="customForm-customButton-container">
						<CustomForm
							onHandleFormClose={() => {}}
							onHandleSubmit={onHandleSubmit}
							typesAndValuesFields={typesAndValuesPartNumber}
							initData={initDataPartNumber}
							formTittle={""}
							dontShowCancelButton={true}
							id={null}
							getUrlPath={""}
							inputConf={{
								marginTop: "1",
								marginBottom: "0",
								stringCol:"col-12 col-md-4 col-lg-3"
							}} 
							buttonClick={buttonClick}
							onChangeFields={onChangeFieldsInitDataPartNumber}
							initOnChangeFields={
								onChangeFieldsInitDataPartNumber
							}
							dontShowSubmitButton={true}
							dontShowErrosinOnChange={true}
							childFunc={childFunc3}
							getObjectInfoOnChange={() => {}}
							notifyChange={() => setChangeInForm(true)}
							handlerSiblings={handleSiblings}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-12 col-md-8">
						<div className="mt-1">
							<CustomDataList
								onChangePageEvent={() => {}}
								style={styles}
								isLoading={isLoading}
								dataList={testData}
								tableHeaders={tableHeaders}
								itemPagination={null}
								handlePagination={() => {}}
								dontShowPerPageSelect={true}
								render={(element, index) => (
									<CustomDataListItem
										key={index}
										dataFields={dataFields}
										imageFields={[]}
										composedFields={[]}
										arrayFields={[]}
										element={element}
										index={index}
										no_image={no_image}
										dontShowActions={true}
										showActionsButtons={true}
										deleteRowTable={() => {}}
										renderActions={() => (
											<>
												<PositionedMenu
													title={"Actions"}
													anchorEl={anchorEl}
													setAnchorEl={setAnchorEl}
													handleClose={onCloseModal}
													handleClick={(event) =>
														onMenuActions(event, element)
													}
													actionsDefinition={
														actionsDefinition
													}
													renderActions={(action, index) => (
														<MenuItem
															sx={{
																boxShadow: "none",
															}}
															key={index}
															onClick={() =>
																action.func()
															}
														>
															<div
																className="items-option"
																style={{
																	padding: "0 5px",
																	color: "grey",
																}}
															>
																<FontAwesomeIcon
																	style={{
																		margin: "4px",
																		marginRight:
																			"10px",
																		fontSize:
																			"12px",
																	}}
																	icon={faTrash}
																/>
																<p>{action.name}</p>
																<hr />
															</div>
														</MenuItem>
													)}
												></PositionedMenu>
											</>
										)}
										renderComponentInField={(data) => (
											<>
												{data}
												<img
													className={``}
													onClick={() =>
														prepareDetailToUpdate(data)
													}
													src={editLogo}
													alt=""
												/>
											</>
										)}
									/>
								)}
							/>
						</div>
					</div>
					<div className="col-12 col-md-4">
						{typesAndValuesFields.length > 0 &&
							Object.keys(initData).length > 0 && (<>
								<div className="row">
									<div className="col-12">
										<div style={{ background: "", display: "grid", justifyContent: "flex-end" }}>
											<div className="amount-table">
												<div className="letter amount-table-subtotal fs-small">
													Subtotal
												</div>
												<div className="letter amount-table-item fs-small font-inter amount-table-subtotal-value">
													$ 0.00
												</div>
												<div className="letter amount-table-discount fs-small">
													Discount percent
												</div>
												<input
													style={{ textAlign: "right" }}
													name="discount_percentage"
													onChange={onChangeFormAmountValues}
													value={formAmountValues.discount_percentage}
													disabled={false}
													className="form-control form-control-sm h-75 align-self-center custom-input input-type-number"
													onKeyDown={blockInvalidChar}
												/>
												<div className="letter amount-table-item fs-small font-inter">
													$ 0.00
												</div>
												<div className="letter amount-table-deposit fs-small">
													Deposit
												</div>
												<input
													style={{ textAlign: "right" }}
													name="deposit"
													onChange={onChangeFormAmountValues}
													value={formAmountValues.deposit}
													disabled={false}
													className="form-control form-control-sm h-75 align-self-center custom-input input-type-number amount-table-deposit-value"
													onKeyDown={blockInvalidChar}
												/>
												<div className="letter amount-table-balance-due fs-small">
													Balance due
												</div>
												<div className="letter amount-table-item fs-small font-inter amount-table-balance-due-value">
													$ 0.00
												</div>
											</div>
										</div>
									</div>
									<div className="col-12">
										<CustomForm
											onHandleFormClose={() => {}}
											onHandleSubmit={onHandleSubmit}
											typesAndValuesFields={typesAndValuesMessageOnInvoice}
											initData={initDataMessageOnInvoice}
											formTittle={""}
											dontShowCancelButton={true}
											id={null}
											getUrlPath={""}
											inputConf={{
												marginTop: "1",
												marginBottom: "0",
												stringCol: "col-12 col-sm-11 col-md-12",
											}}
											buttonClick={buttonClick}
											onChangeFields={onChangeFieldsInitDataMessageOnInvoice}
											initOnChangeFields={onChangeFieldsInitDataMessageOnInvoice}
											dontShowSubmitButton={true}
											childFunc={childFunc2}
											dontShowErrosinOnChange={true}
											notifyChange={() => setChangeInForm(true)}
										/>
									</div>
								</div>
							</>)}
					</div>
				</div>
			</div>

			{/* loading modal */}
			<CustomModal
				show={isLoading}
				customTittleText={""}
				customMessageModal={""}
				onHandleClose={() => {}}
				isLoading={isLoading}
			/>

			{/* response modal */}
			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={onHandleResponseModal}
				onHandleSuccess={onHandleResponseModalSuccess}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
				successText={"Yes"}
				cancelText={"No"}
				actionsFooterStyle={"d-flex justify-content-center"}
			/>

			{/* modal form */}
			<CustomModal
				show={show}
				customTittleText={""}
				customMessageModal={""}
				onHandleClose={() => setShow(false)}
				onHandleSuccess={() => {}}
				isLoading={false}
				showSuccessButton={true}
			>
				<CustomForm
					onHandleFormClose={() => setShow(false)}
					location_list={[]}
					onHandleSubmit={() => setShow(false)}
					typesAndValuesFields={typesAndValuesUpdateDetailInvoice}
					initData={testData[currentId]}
					formTittle={""}
				/>
			</CustomModal>

			<ModalAddItem
				handleCloseAdd={() => setShowAdd(false)}
				getElementWhenAddNewItem={getElementWhenAddNewItem}
				modal_conf={modal_add_conf}
				onHandleClose={handleCloseAdd}
				show={show_add}
				skuNumber={skuNumber}
			></ModalAddItem>
		</>
	);
}

export { EstimateForm };
