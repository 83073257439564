import { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";

function PaginationControlled(props) {
	const active = props?.itemPagination?.current_page || 1;
	let items = [];

	const minNumber = props.itemPagination.total_pages > 1 ? 2 : 1;
	const maxNumber = props.itemPagination.total_pages || null;
	const ADD = "add";
	const REST = "rest";
	const numberToIterate = 4; //number to iterate

	const [initPage, setInitPage] = useState(minNumber);
	const [finalPage, setFinalPage] = useState(
		props.itemPagination.total_pages,
	);
	// const [ finalPage, setFinalPage ] = useState(numberToIterate + 1);
	const [itemsPages, setItemsPages] = useState([]);

	useEffect(() => {
		setInitPage(minNumber);
		setFinalPage(props.itemPagination.total_pages);
	}, [props]);

	useEffect(() => {
		const createPages = () => {
			let items = [];
			if (props.itemPagination) {
				for (let number = initPage; number < finalPage; number++) {
					items.push(
						<Pagination.Item
							key={number}
							active={number === active}
							onClick={() => props.handlePagination(number)}
						>
							{number}
						</Pagination.Item>,
					);
				}
				setItemsPages(items);
			}
		};
		createPages();
		return () => {
			// setInitPage(2)
			// setFinalPage(5)
			// setItemsPages([])
		};
	}, [initPage, finalPage]);

	const buildPages = (typeEvent) => {
		let newFinalValue = 0;
		let newInitValue = 0;

		if (typeEvent === ADD) {
			newFinalValue = finalPage + numberToIterate;
			newInitValue = initPage + numberToIterate;
		}
		if (typeEvent === REST) {
			newFinalValue = finalPage - numberToIterate;
			newInitValue = initPage - numberToIterate;
		}

		if (maxNumber && newFinalValue > maxNumber) {
			if (finalPage + 1 < maxNumber) {
				setInitPage(initPage + 1);
				setFinalPage(finalPage + 1);
			}
			return;
		}

		if (newInitValue < minNumber) {
			if (initPage - 1 >= minNumber) {
				setInitPage(initPage - 1);
				setFinalPage(finalPage - 1);
			}

			return;
		}

		setInitPage(newInitValue);
		setFinalPage(newFinalValue);
	};

	return (
		<>
			{!!props.itemPagination && props?.itemPagination.total > 0 && (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						// justifyContent: 'center',
						alignItems: "center",
						marginTop: "15px",
						overflow: "auto",
					}}
				>
					{/* <br /> */}
					<Pagination>
						<Pagination.Item
							key={1}
							active={1 === active}
							onClick={() => props.handlePagination(1)}
							className={props.customClasses}
						>
							{1}
						</Pagination.Item>

						{/* {(props.itemPagination.total_pages > 10 || active >= numberToIterate + 1) && (
                            <Pagination.Ellipsis 
                                onClick={() => buildPages(REST)}/>
                        )} */}

						{itemsPages}

						{/* {props.itemPagination.total_pages > 10 && (
                            <Pagination.Ellipsis 
                                onClick={() => buildPages(ADD)}/>
                        )} */}

						{props.itemPagination.total_pages > 1 && (
							<Pagination.Item
								key={props.itemPagination.total_pages}
								active={
									props.itemPagination.total_pages === active
								}
								onClick={() =>
									props.handlePagination(
										props.itemPagination.total_pages,
									)
								}
								className={props.customClasses}
							>
								{props.itemPagination.total_pages}
							</Pagination.Item>
						)}
					</Pagination>
				</div>
			)}
		</>
	);
}

export { PaginationControlled };
