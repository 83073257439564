import React from "react";
import "./stockReceipt.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Swal from 'sweetalert2'

import createHttp from "../../lib/http";
import photo from "../../assets/img/photo.jpg";
import { validateRegexField, validateMaxLengthField } from "./helpers";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { 
	regexLettersAndNumber,
	regexLettersAndNumberAndEmptySpace,
	ENTRY_STATE,
	COMPLETE_STATE,
	CANCEL_STATE,
	POSTED_STATE,
} from "../../context/constants";
import GlobalAdapter from '../../utils/global.adapter';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { CustomModal } from "../../components/CustomModal/CustomModal";

import { useApirequestStockReceipt } from "../../customHooks/useApirequestStockReceipt";
import { useModalHook } from "../../customHooks/useModalHook";
import MaterialUI from "../../components/MaterialUI";
import { Box, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Radio, RadioGroup, TableContainer, Table, makeStyles, withStyles, TableHead, TableRow, TableCell, TableBody, ButtonGroup, useMediaQuery } from "@material-ui/core";
import { ProductForm } from "../ProductForm/ProductForm";
import Panel from "../../components/Panel";
import { useFileManager } from "../../customHooks/useFileManager";
import CustomImageViewer from "../../components/CustomImageViewer/CustomImageViewer";
import AlertAction from '../../components/AlertAction';
import { AppContext } from "../../context/AppContext";
import { VendorForm } from "../Vendor/vendorForm/Form";
import NumberFormat from "../../utils/number.format";
import Adapter from "../../utils/global.adapter";

var _ = require("lodash");

const useStyles = makeStyles({
  root: {
		'&.Mui-focused': { color: '#0b5ed7' }
  },
	table: {
		padding: 0,
  },
	container: {
		maxBlockSize: '60vh',
	}
});

const PrimaryRadio = withStyles({
  root: {
		borderRadius: '50%',
    color: "#0d6efd",
		scale: .8,
    '&$checked': { color: "#0b5ed7" }
  },
  checked: {},
})((props) => <Radio color="default" disableRipple {...props} />);

function AddNewStyledStockReceipt() {
	const history = useHistory();
	const location = useLocation();
	const classes = useStyles();
	const {changeNavigationVisible} = React.useContext(AppContext);
	const isMobile = useMediaQuery('(max-width:600px)');

	React.useEffect(() => {
		changeNavigationVisible(true);
	}, [isMobile]);
	
	const {
		showModal,
		setShowModal,
		customTittleText,
		setCustomTittleText,
		customMessageModal,
		customCurrentItem,
		setCustomCurrentItem,
		setCustomMessageModal,
		showSuccessButton,
		manageResponseErrors,
		setShowSuccessButton,
	} = useModalHook();

	const {
		setIsLoading,
		isLoading,
		getDocuments,
		deleteDocuments,
		addDocuments,
		addImages,
		stock_receipt_details,
		setStockReceiptDetails,
		getDataFromUser,
		updateStockReceiptItem,
		setSincronizedItems: setSincronizedItemsList,
		receipt_stock_number,
		setReceiptStockNumber,
		bill_number,
		setBillNumber,
		vendor_id,
		setVendorId,
		vendor_name,
		setVendorName,
		date,
		setDate,
		date_due,
		setDateDue,
		description,
		setDescription,
		payment,
		setPayment,
		quantity,
		setQuantity,
		cost,
		setCost,
		location_id,
		location_name,
		notes,
		setNotes,
		setTotalReceipt,
		tax_receipt,
		vendors,
		setVendors,
		payment_number,
		setPaymentNumber,
		part_number_id,
		disabledActions,
		setDisabledActions,
		updateStockReceiptItemPostStatus,
		updateStockReceiptPostStatus,
		stockReceiptStatus,
		setSkuNumber,
		saveStockReceiptModel,
		saveStockReceiptItem,
		details,
		setDetails,
		setStockReceiptStatus,
	} = useApirequestStockReceipt(
		setCustomTittleText,
		setCustomMessageModal,
		showSuccessButton,
		setShowModal,
	);

	const {
		documentsImageArray,
		setDocumentsImageArray,
		imageIdsArray,
		setImageIdsArray,
		itemImageToAdd,
		setItemImageToAdd,
		setFileImage,
		deleteImage,
		onDeleteImage,
		openFileImage,
	} = useFileManager(
		setShowModal,
		customCurrentItem,
		setCustomCurrentItem,
		setCustomTittleText,
		setCustomMessageModal,
		setShowSuccessButton,
	);

	const [template_errors_all, setTemplateErrorsAll] = React.useState([]);
	const [initOptions, setInitOptions] = React.useState([]);
	const [editItemToDetail, setEditItemToDetail] = React.useState(false);
	const quantityRef = React.useRef(null);
	const [isEdit, setIsEdit] = React.useState(false);
	const [loadingInside, setLoadingInside] = React.useState(false);
	const [in_response, setInResponse] = React.useState(false);

	const handleSetErrorsOntype = (key) => {
		const updatedFormErrors = template_errors_all.filter(error => error.field_id !== key);
		setTemplateErrorsAll(updatedFormErrors);
	}

	const [selectedVender, setSelectedVender] = React.useState(null);
	const [selectedProduct, setSelectedProduct] = React.useState(null);
	const hasError = (keyName) => template_errors_all.find((c) => c.field_id === keyName)?.message;

	const insertBillNumber = (newValue) => {
		setBillNumber(newValue);
		handleSetErrorsOntype("bill_number");
	};

	const formattedDate = (currentFormat) => moment(currentFormat, "DD/MM/YYYY").format("YYYY-MM-DD");

	const loadDocs = (id) => {
		getDocuments(id)
			.then(({ data }) => {
				const mapImages = data.map((item) => ({
					id: item.id,
					path: item.url,
				}));
				setDocumentsImageArray(mapImages);
			})
	}
	
	React.useEffect(() => {
		let current = location?.state?.data;
		if(current) {
			const id = current.id;
			getDataFromUser({ id, returnResponse: true })
				.then((resp) => {
					current = resp
					setReceiptStockNumber(current.id);
					insertBillNumber(current.bill_number);
					if(!!current.vendor_data) setSelectedVender(GlobalAdapter.responseVendorToAutocomplete(current.vendor_data));
					insertDate(formattedDate(current.date_receipt));
					insertDateDue(formattedDate(current.date_due));
					insertDescription(current.description);
					setPayment(String(current.type_payment_id));
					setPaymentNumber(current.payment_info)
					setDetails(() => resp.details)
					setDisabledActions(![ENTRY_STATE, COMPLETE_STATE, CANCEL_STATE].includes(current.status));
					setIsEdit(true);
					setStockReceiptStatus(current.status);
					changeNavigationVisible(true)
				})
				.catch((err) => {
					console.log("Error", err)
				})
				.finally(() => setIsLoading(false))
			loadDocs(id)
		}
	}, []);

	const handleChangeStateClick = async (rowId, index) => {
		try {
			setLoadingInside(true);
			const row = details[index];
			row.posted = !row.posted;
			await updateStockReceiptItemPostStatus(rowId, row.posted);
			const newDetails = [...details];
			newDetails.splice(index, 1, row);
			setDetails(newDetails);
		} catch (error) {
		} finally { setLoadingInside(false) }
	}

	React.useEffect(() => {
		if(selectedVender) {
			setVendorId(selectedVender.id);
			setVendorName(selectedVender.label);
		} else {
			setVendorId("");
			setVendorName("");
		}
	}, [selectedVender])
	
	const insertDate = (value) => {
		setDate(value);
		handleSetErrorsOntype("date")
	};

	const insertDateDue = (value) => {
		setDateDue(value);
		handleSetErrorsOntype("date_due")
	};

	const insertDescription = (value) => {
		setDescription(value);
		handleSetErrorsOntype("description");
	};

	const setPaymentMethod = (e) => {
		setPayment(e.target.value);
		handleSetErrorsOntype("payment")
	};

	const setPaymentMethodNumber = (value) => {
		setPaymentNumber(value);
		handleSetErrorsOntype("payment_number");
	};

	const setQuantityByInput = (newValue) => {
		newValue = newValue.replace(/[^0-9]/g, "")
		setQuantity(newValue);
	};

	const sumQuantityByButton = () => {
		let get_value = quantity;
		get_value = parseInt(get_value) + 1;
		setQuantity(get_value);
	};

	const minusQuantityByButton = () => {
		let get_value = quantity;
		if (get_value <= 1) return;
		get_value = parseInt(get_value) - 1;
		setQuantity(get_value);
	};

	const setCostByInput = (newValue) => {
		newValue = replaceFloat(newValue);
		setCost(newValue);
		handleSetErrorsOntype("cost");
	};

	const setPaymentNotes = (e) => {
		// validateStockReceipt();
		setNotes(e.target.value);
	};

	function replaceFloat(_value) {
		let new_value = _value;
		_value = new_value.replace(/[^0-9.-]/g, "");

		let float_value = _value.split(".");

		let get_first_place = float_value[0];
		let get_second_place = float_value[1];

		if (get_first_place !== undefined) {
			if (get_first_place.toString().length >= 8) {
				get_first_place = get_first_place.toString().substr(0, 8);
			}
		}

		let dot = "";
		if (get_second_place !== undefined) {
			dot = ".";
			if (get_second_place.toString().length >= 2) {
				get_second_place = get_second_place.toString().substr(0, 2);
			}
		}

		_value =
			get_second_place !== undefined
				? get_first_place + dot + get_second_place
				: get_first_place;
		return _value;
	}

	function isNumeric(n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	function returnTotalSum(quantity, cost) {
		let total = 0;

		if (isNumeric(quantity)) {
			total = parseInt(quantity) * parseFloat(cost);
			total = parseFloat(total).toFixed(2);

			return total;
		}

		return total;
	}

	const addNewStockReceiptDetail = (itemId, itemName) => {
		let item_total = returnTotalSum(quantity, cost);

		let item = {
			stock_receipt_id: "",
			item_id: itemId,
			item_name: itemName,
			quantity: quantity,
			cost: cost,
			location_id: location_id,
			location_name: location_name,
			total: item_total,
		};

		let stock_receipts = [...stock_receipt_details];

		stock_receipts.push(item);

		setStockReceiptDetails(stock_receipts);

		return stock_receipts;
	};

	function calculateTotal() {
		let total = 0;

		for (let index = 0; index < stock_receipt_details.length; index++) {
			total =
				parseFloat(total) +
				parseFloat(stock_receipt_details[index].total);
		}

		if (isNumeric(tax_receipt)) {
			total = total + parseFloat(tax_receipt);
		} else {
			total = total + parseFloat(0);
		}

		setTotalReceipt(parseFloat(total));
	}

	function isValidDate(d) {
		let date = moment(d.toString());
		let value = date.isValid();
		return value;
	}

	function validateStockReceipt() {
		setTemplateErrorsAll([]);

		let falidate_fields_errors = [];

		if (vendor_id.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "vendor_id",
				message: "Vendor field need to be valid",
			});
		}

		if (vendor_name.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "vendor_name",
				message: "Vendor Name need to be valid",
			});
		}

		if (vendors.length > 0) {
			let exists_selected_vendor = vendors.find(
				(element) => element.name === vendor_name.toString(),
			);

			if (exists_selected_vendor === undefined) {
				falidate_fields_errors.push({
					field_id: "vendor_name",
					message: "Selected vendor does not exists",
				});
			}
		}

		let dates_cont = 0;

		if (date.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "date",
				message: "Date field need to be valid",
			});
		}

		if (!isValidDate(date)) {
			falidate_fields_errors.push({
				field_id: "date",
				message: "Invalid date",
			});
		} else {
			dates_cont++;
		}

		if (date_due.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "date_due",
				message: "Date Due field need to be valid",
			});
		}

		if (!isValidDate(date_due)) {
			falidate_fields_errors.push({
				field_id: "date_due",
				message: "Invalid date",
			});
		} else {
			dates_cont++;
		}

		if (dates_cont >= 2) {
			if (moment(date).isAfter(date_due)) {
				falidate_fields_errors.push({
					field_id: "date_due",
					message: "Date cannot be grater than date due",
				});

				falidate_fields_errors.push({
					field_id: "date",
					message: "Date cannot be grater than date due",
				});
			}
		}

		if (description.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "description",
				message: "Description field need to be valid",
			});
		}
		if (payment.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "payment",
				message: "Select a payment method please",
			});
		}
		if (payment_number.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "payment_number",
				message: "Payment Number field need to be valid",
			});
		}
		if (bill_number.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "bill_number",
				message: "Bill Number field need to be valid",
			});
		}

		if (!part_number_id || part_number_id.length === 0) {
		}

		validateRegexField(
			regexLettersAndNumber,
			bill_number,
			falidate_fields_errors,
			"bill_number",
			"Bill Number",
		);

		validateMaxLengthField(
			bill_number,
			falidate_fields_errors,
			"bill_number",
			"Bill Number",
			20,
		);

		validateRegexField(
			regexLettersAndNumber,
			description,
			falidate_fields_errors,
			"description",
			"Description",
		);

		validateMaxLengthField(
			description,
			falidate_fields_errors,
			"description",
			"Description",
			50,
		);

		validateRegexField(
			regexLettersAndNumber,
			payment_number,
			falidate_fields_errors,
			"payment_number",
			"Payment",
		);

		validateMaxLengthField(
			payment_number,
			falidate_fields_errors,
			"payment_number",
			"Payment",
			25,
		);

		validateRegexField(
			regexLettersAndNumberAndEmptySpace,
			notes,
			falidate_fields_errors,
			"notes",
			"Notes",
		);

		validateMaxLengthField(
			notes,
			falidate_fields_errors,
			"notes",
			"Notes",
			150,
		);

		if (falidate_fields_errors.length > 0) {
			setTemplateErrorsAll(falidate_fields_errors);

			return false;
		}

		return true;
	}

	// DETAILS
	const hasProduct = (productId, itemVariantId) => {
		const index = details.findIndex((c) => c.item_variant_id == itemVariantId && c.item_variant?.item_id == productId);
		return { index, exists: index !== -1};
	}
	
	const handleSubmit = async () => {
		if (!validateStockReceipt() || in_response || isLoading) return;
		if(isEdit) {
			updateDataToDb()
		} else {
			try {
				setIsLoading(true);
				const totalCost = details.reduce((acc, item) => (acc + item.total), 0);
				const { data } = await saveStockReceiptModel(
					description,
					date,
					vendor_id,
					bill_number,
					date_due,
					notes,
					payment_number,
					payment,
					tax_receipt,
					totalCost
				);
				if(data.status) {
					const payloadList = details.map((c) => ({
						stock_receipt_id: data.data.id,
						item_id: c.item.id,
						item_variant_id: c.item_variant.id,
						sku: c.item_variant.sku,
						quantity: c.quantity,
						cost: Number(c.cost).toFixed(2),
						location_id: "",
					}));
					const promises = Promise.all(payloadList.map(saveStockReceiptItem));
					await promises;
				}
				goBack();
			} catch (error) {
				console.log("Catch");
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		}
	}
	
	async function addToDetails(stockReceiptId) {
		if(loadingInside) return;
		try {
			const { exists, index } = hasProduct(selectedProduct.item_id, selectedProduct.item_variant_id);
			if (!exists) {
				const { productInformation: item, ...item_variant } = selectedProduct
				const newItem = {
					posted: true,
					location_id: "",
					item_variant_id: item_variant.id,
					item_variant,
					item,
					cost: cost || 0,
					quantity,
					total: Number(cost) * Number(quantity)
				}
				let error
				if(isEdit) {
					setLoadingInside(true);
					const payload = {
						stock_receipt_id: stockReceiptId ?? null,
						item_id: item.id,
						item_variant_id: item_variant.id,
						sku: item_variant.sku,
						quantity,
						cost,
						location_id: "",
					}
					const { data } = await saveStockReceiptItem(payload);
					if(data.status) {
						newItem.id = data.data.id;
					} else {
						error = data.message
					}
				}
				if(!error) {
					setDetails((prev) => [newItem, ...prev]);
				} else {
					Swal.fire({
						title: "Error!",
						text: error,
						icon: "error"
					});
				}
			} else {
				const { productInformation, ...item_variant } = selectedProduct
				const item = {
					...details[index],
					quantity,
					cost,
					total: Number(cost) * Number(quantity),
					posted: true,
					location_id: "",
				};
				if(isEdit) {
					setLoadingInside(true);
					await updateStockReceiptItem({
						stock_receipt_id: stockReceiptId ?? null,
						item_id: productInformation.id,
						item_variant_id: item_variant.id,
						quantity,
						cost,
						itemId: item.id,
					});
				}
				const newDetails = [...details];
				newDetails.splice(index, 1, item);
				setDetails(newDetails);
			}
			cleanFormDetail();
		} catch (error) {}
		finally { setLoadingInside(false) }
	}

	const cleanFormDetail = () => {
		setEditItemToDetail(false);
		setSelectedProduct(null);
		setInitOptions([]);
		setQuantity(1);
		setCost(0);
	};
	
	const doComplexProcess = async (
		have_stock_receipts,
		location_id,
		location_name,
		currentCost = null,
	) => {
		await saveStockReceiptModel(
			description,
			date,
			vendor_id,
			bill_number,
			date_due,
			notes,
			payment_number,
			payment,
			tax_receipt,
			cost
		)
			.then(async (response) => {
				// correct response
				console.log("stock receipt response", response);
				if (response.data.status) {
					// // create stock receipt item
					let get_data = [...have_stock_receipts];

					if (get_data.length === 0) {
						return history.push({
							pathname: "/edit_stock_receipt",
							state: {
								data: {
									...response.data.data,
									locationId: location_id,
									locationName: location_name,
								},
							},
						});
					}

					for (let index = 0; index < get_data.length; index++) {
						await saveStockReceiptItem(
							get_data[index],
							response.data.data.id,
							currentCost,
						)
							.then((responseItem) => {
								console.log(
									"stock receipt item response",
									responseItem,
								);
								// create stock receipt item (correct response)
								if (responseItem.data.status) {
									setIsLoading(false);
									history.push({
										pathname: "/edit_stock_receipt",
										state: {
											data: {
												...response.data.data,
												locationId: location_id,
												locationName: location_name,
											},
										},
									});
								}
								// create stock receipt item (error response)
								else {
									setStockReceiptDetails([]);
									manageResponseErrors(
										responseItem.data.message,
										"Stock Receipt Item",
									);
								}
							})
							.catch((error) => {
								setStockReceiptDetails([]);
								manageResponseErrors(
									"An error has ocurred",
									"Stock Receipt Item",
								);
							});
					}
				}
				// error response
				else {
					setStockReceiptDetails([]);
					manageResponseErrors(
						response.data.message,
						"Stock Receipt",
					);
				}
			})
			.catch((error) => {
				setStockReceiptDetails([]);
				manageResponseErrors("An error has ocurred", "Stock Receipt");
			});
	};

	React.useEffect(() => {
		calculateTotal();
	}, [stock_receipt_details, tax_receipt]);

	const [showPanelProduct, setShowPanelProduct] = React.useState(false);
  const handleTogglePanelProduct = () => setShowPanelProduct(state => !state);
	const handleOnResponse = async (data) => {
		const { cost } = data;
		setSelectedProduct(data);
		handleTogglePanelProduct();

		let have_stock_receipts = [];
		if (!(Object.keys(data).length === 0)) {
			have_stock_receipts = addNewStockReceiptDetail(data.id, data.name);
			setInResponse(true);
			setIsLoading(true);
			await doComplexProcess(
				have_stock_receipts,
				location_id,
				location_name,
				cost,
			);
			setInResponse(false);
			setIsLoading(false);
		}
	}

	React.useEffect(() => {
		if(selectedProduct) {
			setSkuNumber(selectedProduct.sku);
			quantityRef.current.focus();
		}
		handleSetErrorsOntype("part_number_name");
	}, [selectedProduct, quantityRef]);

	const goBack = () => history.push('/stock_receipt');

	const [itemImageId, setItemImageId] = React.useState([]);
	const [newStockReceiptItemImage, setNewStockReceiptItemImage] = React.useState([]);
	const addImageToStockReceiptItem = (fileEntry) => {
		let preview = URL.createObjectURL(fileEntry.target.files[0]);
		let stockReceiptDetailsCopy = [...stock_receipt_details];
		let currentItem =
			stock_receipt_details[
				stock_receipt_details.findIndex(
					(itemImage) => itemImage.item_id === itemImageId,
				)
			];
		currentItem.mainImage = preview;
		stockReceiptDetailsCopy[
			stock_receipt_details.findIndex(
				(itemImage) => itemImage.item_id === itemImageId,
			)
		] = currentItem;

		setStockReceiptDetails(stockReceiptDetailsCopy);

		setNewStockReceiptItemImage([
			...newStockReceiptItemImage,
			{
				file: fileEntry.target.files[0],
				id: itemImageId,
			},
		]);
	};

	const renderPhoto = (images) => {
		if(!images.length) return '';
		return images[0]?.path;
	}

	const handleEditClick = (record) => {
		const form = Adapter.productDetailToForm(record)
		setEditItemToDetail(true);
		setInitOptions([form]);
		setSelectedProduct(form);
		setQuantity(Number(form.quantity));
		setCost(Number(form.cost));
		quantityRef.current.focus();
	}

	const [recordToDelete, setRecordToDelete] = React.useState(null);
	const handleRemoveClick = async (row) => {
		setRecordToDelete(row);
	}
	
	const setStateAfterRequest = (arrayResponses, verb) => {
		let errorResponse = false;

		arrayResponses.map((addResponse) => {
			if (addResponse.status != 201) {
				errorResponse = true;
			}
		});

		if (errorResponse) {
			setCustomTittleText("Upload error");
			setCustomMessageModal("there was an error uploading the documents");
			setShowSuccessButton(false);
			setShowModal(true);
		} else {
			setItemImageToAdd([]);
			setImageIdsArray([]);
		}
	};
	
	async function updateStockReceipt(otra_func) {
		const totalCost = details.reduce((acc, item) => (acc + item.total), 0);
		createHttp
			.put("/api/stock-receipts/" + receipt_stock_number, {
				description: description,
				date_receipt: date,
				type: "Receipt",
				vendor_id: vendor_id,
				bill_number: bill_number,
				date_due: date_due,
				notes: notes,
				payment_info: payment_number,
				type_payment_id: payment,
				tax1: tax_receipt || 0.0,
				cost: totalCost,
			})
			.then((response) => {
				setInResponse(false);

				otra_func(response.data);

				if (!response.data.status) {
					manageResponseErrors(
						response.data.message,
						"Stock Receipt",
						"updating",
					);

					if (response.data.message === "Stock Receipt exist") {
						let falidate_fields_errors = [];

						falidate_fields_errors.push({
							field_id: "bill_number",
							message: response.data.message,
						});

						setTemplateErrorsAll(falidate_fields_errors);
					}
				}

				setInResponse(false);
			})
			.catch((response) => {
				setInResponse(false);
				manageResponseErrors(
					"an error has occured",
					"Stock Receipt",
					"updating",
				);
			});
	}

	const updateDataToDb = () => {
		if (!validateStockReceipt() || in_response) return;
		setIsLoading(true);
		setInResponse(true);

		updateStockReceipt(async (response) => {
			const upload = async(item) => await addImages(item.file, item.id);
			await Promise.all(newStockReceiptItemImage.map(upload));
			setNewStockReceiptItemImage([]);

			if (response.status) {
				const uploadDocs = async (fileItem) => await addDocuments(response.data.id, fileItem);
				const addResponses = await Promise.all(itemImageToAdd.map(uploadDocs));
				setStateAfterRequest(addResponses, "uploading");
			}

			if (response.status) {
				//DELETE FILES
				console.log("imageIdsArray", imageIdsArray);
				const deleteResponses = await Promise.all(
					imageIdsArray
						.filter((id) => parseInt(id))
						.map(async (id) => {
							return await deleteDocuments(id);
						}),
				);
				setStateAfterRequest(deleteResponses, "deleting");
			}
			loadDocs(response.data.id);
			setIsLoading(false);
			setSincronizedItemsList(false);
		});
	};
	
	const isPostedOrCanceled = (newValue) => [POSTED_STATE, CANCEL_STATE].includes(newValue ?? stockReceiptStatus);
	const handlerAlert = async (action) => {
		if(loadingInside) return;
		try {
			if(action === 'confirm') {
				const row = recordToDelete;
				let success = true;
				if(row.id) {
					setLoadingInside(true);
					const { data } = await createHttp.delete(`/api/stock-receipt-details/${row.id}`);
					success = data.status;
				}
				if(success) {
					setDetails((prev) => prev.filter(item => Number(item.item.id) !== Number(row.item.id)));
					setRecordToDelete(null);
				}
			} else if(action === 'cancel') {
				setRecordToDelete(null);
			}
		}
		catch (error) {}
		finally { setLoadingInside(false) }		
	}
	
	const [openModalVendor, setOpenModalVendor] = React.useState(false);
  const handleTogglePanelVendor = () => setOpenModalVendor(state => !state);

	const captureResponseVendorModal = ({ data }) => {
    const vendor = data.data;
    if(!vendor) return;
    setVendors((prev) => [...prev, vendor]);
    // setExpense((prev) => ({ ...prev, vendor_id: Adapter.responseVendorToAutocomplete(vendor) }))
		const newValue = GlobalAdapter.responseVendorToAutocomplete(vendor);
		setSelectedVender(newValue)
    handleTogglePanelVendor();
  }

	return (
		<>
			<AlertAction
				icon={'alert'}
				title={'Are you sure?'}
				message={'Delete item from the list'}
				handler={handlerAlert}
				buttonTextConfirm="Yes, Delete!"
				render={!!recordToDelete}
				destroy
			/>
			<Panel open={showPanelProduct} anchor="right" togglePanel={handleTogglePanelProduct} headerTitle="Create Product" pSize="panel-lg">
				<ProductForm
					dontReloadToMainPage={true}
					showCancelButton={true}
					onResponse={handleOnResponse}
					dontShowTabs={true}
					withoutData={true}
					hideHeader
				/>
			</Panel>
			<Panel open={openModalVendor} anchor="right" togglePanel={handleTogglePanelVendor} headerTitle="Create Vendor" >
				<VendorForm behavior="NO_REDIRECT" handleBehavior={captureResponseVendorModal} />
			</Panel>
			<div className="content-stockReceipt px-2">
				<div className="container-fluid px-0 pb-4">
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Box display="flex" alignItems="center" className="gap-2 mb-3">
								<MaterialUI.Button.Dark size="small" onClick={goBack}>
									<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} />Back
								</MaterialUI.Button.Dark>
								<div className="container-title d-flex align-items-center justify-content-between" style={{ flex: 1}}>
									<h1 className="container__title mb-0">{ !isEdit ? 'Create' : 'Update' } Stock Receipt {!!receipt_stock_number ? `#${receipt_stock_number}` : ''}</h1>
									<MaterialUI.Button.Success size="small" onClick={handleSubmit} disabled={[POSTED_STATE, CANCEL_STATE].includes(stockReceiptStatus) || isLoading}>
										{!isLoading ? (<><SaveOutlinedIcon fontSize="small" className="me-2" />Save</>) : ('Loading')}
									</MaterialUI.Button.Success>
								</div>
							</Box>
						</Grid>
						{isEdit && (
							<Grid item xs={12} className="text-center mb-4">
								<ButtonGroup>
									<MaterialUI.Button.Primary
										disableElevation
										className="fs-small"
										onClick={() => updateStockReceiptPostStatus(receipt_stock_number, ENTRY_STATE)}
									>
										{ENTRY_STATE}
									</MaterialUI.Button.Primary>
									<MaterialUI.Button.Success
										disableElevation
										className="fs-small"
										disabled={disabledActions}
										onClick={() => updateStockReceiptPostStatus(receipt_stock_number, COMPLETE_STATE)}
									>
										{COMPLETE_STATE}
									</MaterialUI.Button.Success>
									<MaterialUI.Button.Dark
										disableElevation
										className="fs-small"
										disabled={disabledActions}
										onClick={() => updateStockReceiptPostStatus(receipt_stock_number, POSTED_STATE)}
									>
										{POSTED_STATE}
									</MaterialUI.Button.Dark>
									<MaterialUI.Button.Danger
										disableElevation
										className="fs-small"
										disabled={disabledActions}
										onClick={() => updateStockReceiptPostStatus(receipt_stock_number, CANCEL_STATE)}
									>
										Void
									</MaterialUI.Button.Danger>
									<MaterialUI.Button.Secondary
										disableElevation
										className="fs-small"
										onClick={() => history.push("/stock_receipt")}
									>
										Cancel
									</MaterialUI.Button.Secondary>
								</ButtonGroup>
							</Grid>
						)}
						<Grid container spacing={1} className="mb-1">
							{isEdit && (
								<Grid item xs={6} md={4} lg={2}>
									<MaterialUI.Input
										state={stockReceiptStatus}
										disabled
										label="STATUS"
									/>
								</Grid>
							)}
							<Grid item xs={6} md={4} lg={2}>
								<MaterialUI.Input
									state={bill_number}
									setState={insertBillNumber}
									label="Bill Number"
									error={hasError('bill_number')}
								/>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<MaterialUI.Autocomplete
									label="Vendor"
									state={selectedVender}
									setState={(newValue) => {setSelectedVender(newValue); handleSetErrorsOntype("vendor_name")}}
									optionsList={vendors.map(GlobalAdapter.responseVendorToAutocomplete)}
									error={hasError('vendor_name')}
									className="roundedSides"
								>
									<MaterialUI.Button.Primary disableElevation type={'button'} className="roundedSides" size='small' onClick={handleTogglePanelVendor}>
										<AddCircleOutlineSharpIcon fontSize='small' />
									</MaterialUI.Button.Primary>
								</MaterialUI.Autocomplete>
							</Grid>
							<Grid item xs={12} md={4} lg={isEdit ? 2 : 3}>
								<MaterialUI.Input
									type="date"
									InputLabelProps={{ shrink: true }}
									state={date}
									setState={insertDate}
									label="Date"
									error={hasError('date')}
								/>
							</Grid>
							<Grid item xs={12} md={4} lg={isEdit ? 2 : 3}>
								<MaterialUI.Input
									type="date"
									InputLabelProps={{ shrink: true }}
									state={date_due}
									setState={insertDateDue}
									label="Date Due"
									error={hasError('date_due')}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={1} className="mb-1">
							<Grid item xs={12} md={6}>
								<MaterialUI.Input
									state={description}
									setState={insertDescription}
									label="Description"
									multiline
									rows={3}
									error={hasError('description')}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Box className="h-100" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
									<Box display='flex' className="mb-1">
										<h6 className="sub__title mb-0">Payment Method</h6>
										<Box style={{ flex: 1 }}>
											<FormControl component="fieldset">
												<RadioGroup name="payment-method" value={payment} className="flex-row ps-3 gap-0" onChange={setPaymentMethod}>
													<FormControlLabel value="1" control={<PrimaryRadio size="small" color="primary" className="p-0" />} label={<span className="fs-small">Credit</span>} />
													<FormControlLabel value="2" control={<PrimaryRadio size="small" color="primary" className="p-0" />} label={<span className="fs-small">Check</span>} />
													<FormControlLabel value="3" control={<PrimaryRadio size="small" color="primary" className="p-0" />} label={<span className="fs-small">Cash</span>} />
													<FormControlLabel value="4" control={<PrimaryRadio size="small" color="primary" className="p-0" />} label={<span className="fs-small">Account</span>} />
												</RadioGroup>
											</FormControl>
											{!!hasError('payment') && <FormHelperText style={{ fontSize: '.7rem', marginBlockStart: '0' }} error>{hasError('payment')}</FormHelperText>}
										</Box>
									</Box>
									<Box>
										<MaterialUI.Input
											maxLength="25"
											state={payment_number}
											setState={setPaymentMethodNumber}
											label="Payment Number"
											error={hasError('payment_number')}
										/>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} md={8}>
								<h6 className="sub__title">Form Part</h6>
								<Grid container spacing={1} className="pt-1 mb-2">
									<Grid item xs={12} md={6} lg={6}>
										<FormGroup className='flex-row flex-nowrap'>
											<MaterialUI.AutocompleteAsync
												uri={'api/items'}
												getDynamicParams={(value) => [isNaN(value) ? 'search_text' : 'sku']}
												label="Search Product by SKU or Name"
												state={selectedProduct}
												setState={setSelectedProduct}
												error={hasError('part_number_name')}
												className={!editItemToDetail ? 'roundedSides' : ''}
												initOptions={initOptions}
												getOptionSelected={(option, value) => option?.id == value?.id}
												getOptionLabel={(option) => option.label || ''}
												filterSelectedOptions={false}
												disabled={editItemToDetail}
												responseAdaptar={Adapter.responseProductSearchByAutocomplete}
												adapterOptions={Adapter.responseProductWithSkuToOptions}
											>
												{!editItemToDetail && (
													<MaterialUI.Button.Primary disableElevation type={'button'} className="roundedSides mw-auto" size='small' onClick={handleTogglePanelProduct} style={{ inlineSize: '60px' }}>
														<AddCircleOutlineSharpIcon fontSize='small' />
													</MaterialUI.Button.Primary>
												)}
											</MaterialUI.AutocompleteAsync>
										</FormGroup>
									</Grid>
									<Grid item xs={12} md={6} lg={2}>
										<FormGroup className='flex-row flex-nowrap'>
											<MaterialUI.Button.Primary disableElevation tabIndex="-1" type={'button'} onClick={minusQuantityByButton} className="roundedStart mw-auto" size='small' style={{ inlineSize: '50px' }}>
												<FontAwesomeIcon icon={faMinus} />
											</MaterialUI.Button.Primary>
											<MaterialUI.Input
												label="Quantity"
												state={quantity}
												setState={setQuantityByInput}
												error={hasError('quantity')}
												className="roundedNone"
												inputMode="numeric"
												inputRef={quantityRef}
											/>
											<MaterialUI.Button.Primary disableElevation tabIndex="-1" type={'button'} onClick={sumQuantityByButton} className="roundedEnd mw-auto" size='small' style={{ inlineSize: '50px' }}>
												<FontAwesomeIcon icon={faPlus} />
											</MaterialUI.Button.Primary>
										</FormGroup>
									</Grid>
									<Grid item xs={12} md={6} lg={2}>
										<MaterialUI.Input
											state={cost}
											setState={setCostByInput}
											label="Cost"
											error={hasError('cost')}
										/>
									</Grid>
									<Grid item xs={12} md={6} lg={2}>
										<Box display="flex" className="gap-1" justifyContent="flex-end">
											<MaterialUI.Button.Dark disableElevation type={"button"} title={editItemToDetail ? 'Update' : 'Add to list'} fullWidth onClick={() => addToDetails(receipt_stock_number)} disabled={loadingInside}>
												{!!editItemToDetail ? "Update" : <><FontAwesomeIcon icon={faPlus} className="me-2"/>Add</>}
											</MaterialUI.Button.Dark>
											{editItemToDetail && (
												<MaterialUI.Button.Danger disableElevation type={"button"} title="Cancel" fullWidth onClick={cleanFormDetail} disabled={loadingInside}>
													Cancel
												</MaterialUI.Button.Danger>
											)}
										</Box>
									</Grid>
								</Grid>
								<Grid container>
									<TableContainer className={classes.container}>
										<Table stickyHeader className={classes.table} size="small">
											<TableHead>
												<TableRow>
													<TableCell className="fs-small" align="center">Actions</TableCell>
													<TableCell className="fs-small" align="center">SKU</TableCell>
													<TableCell className="fs-small px-1">Name</TableCell>
													<TableCell className="fs-small px-1">Location</TableCell>
													<TableCell className="fs-small" align="center">Quantity</TableCell>
													<TableCell className="fs-small" align="center">Cost</TableCell>
													<TableCell className="fs-small" align="center">Tax</TableCell>
													<TableCell className="fs-small" align="center">Picture</TableCell>
													<TableCell className="fs-small" align="center">Total</TableCell>
													{isEdit && (<>
														<TableCell className="fs-small" align="center">Post Status</TableCell>
														<TableCell className="fs-small" align="center">Change P. Status</TableCell>
													</>)}
												</TableRow>
											</TableHead>
											<TableBody>
												{!!details.length && (<>
													{details.map((row, index)=>(
														<TableRow key={index}>
															<TableCell align="center" scope="row" style={{ inlineSize: '130px', minInlineSize: '130px'}}>
																<MaterialUI.Button.Primary type="button" size="small" disabled={editItemToDetail || loadingInside || (isPostedOrCanceled(row.posted && POSTED_STATE) && !!row.id)} className="mw-auto me-1" onClick={() => handleEditClick(row)}>
																	<EditIcon fontSize="small" />
																</MaterialUI.Button.Primary>
																<MaterialUI.Button.Danger type="button" size="small" disabled={editItemToDetail || loadingInside || (isPostedOrCanceled(row.posted && POSTED_STATE) && !!row.id)} className="mw-auto" onClick={() => handleRemoveClick(row)}>
																	<DeleteIcon fontSize="small" />
																</MaterialUI.Button.Danger>
															</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.item_variant?.sku ?? row.item?.sku ?? '-'}</TableCell>
															<TableCell className="px-1 fs-small">{row.item?.name}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.location?.name}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.quantity}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{NumberFormat.currency(row?.cost)}</TableCell>
															<TableCell className="px-1 fs-small" align="center">-</TableCell>
															<TableCell className="px-1 fs-small" align="center">
																<img
																	src={renderPhoto(row.item?.images) || photo}
																	alt="icon"
																	style={{ width: "35px", height: "35px" }}
																	className="rounded-circle"
																/>
															</TableCell>
															<TableCell className="px-1 fs-small" align="center">{NumberFormat.currency(row.total)}</TableCell>
															{isEdit && (<>
																<TableCell className="px-1 fs-small" align="center">{row.posted ? 'Posted' : 'Unposted'}</TableCell>
																<TableCell className="px-1 fs-small" align="center">
																	{row?.posted ? (
																		<MaterialUI.Button.Danger type="button" disabled={editItemToDetail || loadingInside || isPostedOrCanceled(row.sta)} onClick={() => handleChangeStateClick(row.id, index)} size="small" style={{ minWidth: '100px' }}>
																			<RemoveCircleIcon fontSize="small" className="pe-1"/> Unposted
																		</MaterialUI.Button.Danger>
																	) : (
																		<MaterialUI.Button.Success type="button" disabled={editItemToDetail || loadingInside || isPostedOrCanceled(row.sta)} onClick={() => handleChangeStateClick(row.id, index)} size="small" style={{ minWidth: '100px' }}>
																			<CheckCircleIcon fontSize="small" className="pe-1"/> Posted
																		</MaterialUI.Button.Success>
																	)}
																</TableCell>
															</>)}
														</TableRow>
													))}
												</>)}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4}>
								<Grid container spacing={1}>
									<Grid item xs={4} className="text-center">
										<p className="text-muted fs-small mb-0">Total Stock Receipt</p>
										<h4 className="font-inter">{NumberFormat.currency(details.reduce((acc, item) => acc + Number(item.total), 0))}</h4>
									</Grid>
									<Grid item xs={2} className="text-center">
										<p className="text-muted fs-small mb-0">Qty Total</p>
										<h4 className="font-inter">{Number(details.reduce((acc, item) => acc + Number(!!item.quantity ? item.quantity : 0), 0))}</h4>
									</Grid>
									<Grid item xs={2} className="text-center">
										<p className="text-muted fs-small mb-0">Tax</p>
										<h4 className="font-inter">-</h4>
									</Grid>
									<Grid item xs={4} className="text-center">
										<p className="text-muted fs-small mb-0">Import total</p>
										<h4 className="font-inter">{NumberFormat.currency(details.reduce((acc, item) => acc + Number(item.total), 0))}</h4>
									</Grid>
									<Grid item xs={12}>
										<div className="w-100">
											<label className="form-label mb-0">Notes</label>
											<textarea className="form-control w-100" style={{ blockSize: '80px' }} onChange={(e) => { setPaymentNotes(e); handleSetErrorsOntype("notes")}}></textarea>
											{!!hasError('notes') && <span className="text-danger">{hasError('notes')}</span>}
										</div>
									</Grid>
									{isEdit && (
										<Grid item xs={12}>
											<div className="content-notes mt-0 col-12"><label className="form-label mb-0">Files</label></div>
											<Box sx={{ overflow: "auto" }} className="p-1 border scroll_customized">
												<CustomImageViewer
													itemData={documentsImageArray}
													onClickImage={() => {}}
													onDeleteImage={onDeleteImage}
													showDefaultPdfIcon={true}
													showDowloadAction={true}
												>
													<div className="user-select-none p-3 rounded-2 d-flex flex-column align-items-center justify-content-center" style={{ width: '95px', height: 'auto', border: '2px dashed #3f51b5', backgroundColor: 'rgb(63 81 181 / 15%)', color: '#3f51b5', cursor: 'pointer' }} onClick={() => openFileImage()}>
														<AddRoundedIcon className='mb-2' />
														<p className="mb-0" style={{ fontSize: '12px', lineHeight: '1', textAlign: 'center' }}>Attach document</p>
														<input
															hidden
															id="file_input_doc_input"
															type="file"
															onChange={(e) => setFileImage(e)}
															accept="application/pdf"
															multiple
														/>
													</div>
												</CustomImageViewer>
											</Box>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
				<input
					onChange={(e) => addImageToStockReceiptItem(e)}
					accept="image/png, image/gif, image/jpeg"
					type="file"
					id={`imgupload`}
					name={`imgupload`}
					style={{ display: "none" }}
				/>
			</div>
			<CustomModal
        show={showModal}
        customTittleText={customTittleText}
        customMessageModal={customMessageModal}
        onHandleClose={() => setShowModal(false) }
        onHandleSuccess={() => deleteImage()}
        showSuccessButton={showSuccessButton}
      />
		</>
	);
}

export { AddNewStyledStockReceipt };
