import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { FaMoneyCheckAlt } from "react-icons/fa";

import { PosDesktopContext, InitState } from '../../context/PosDesktopContext';
import InputFormat from '../../utils/input.format'

import Calculator from '../../components/Calculator';
import MaterialUI from '../../components/MaterialUI';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: theme.shadows[5],
    background: '#fff',
    padding: '1.5rem 1rem',
    borderRadius: '.25rem',
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      inlineSize: '900px',
    },
    [theme.breakpoints.down('sm')]: {
      inlineSize: '95vw',
    },
    '--bg-body': "#fff",
  },
  icon: {
    color: 'var(--bs-teal)',
    fontSize: '5rem'
  },
  process: {
    position: 'absolute',
    top: '0',
    left: '0',
    inlineSize: '100%',
    blockSize: '100%',
    borderRadius: 'inherit',
    background: 'rgb(255 255 255 / 90%)',
    zIndex: '10',
    display: 'grid',
    placeContent: 'center',
  }
}));

const PosDesktopPaymentForm = () => {
  const classes = useStyles();
  const {
    closeProcessSale,
    handleStoreReceiptClick,
    payments,
    totalAmount,
    sendingForm,
    errors,
    note,
    setNote,
    setErrors,
    dynamicForm,
    setDynamicForm,
    showFormPayment,
  } = React.useContext(PosDesktopContext);
  
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const addAnotherPaymentMethod = () => {
    if(disabledAddNewPayment) return;
    setDynamicForm((prev) => {
      const newItem = InitState.formPayment();
      const hasCashMethod = prev.some(c => c.type_payment_id === 3);
      if(payments.length) newItem.type_payment_id = hasCashMethod ? payments[0]['id'] : newItem.type_payment_id;
      const restAmount = totalAmount - prev.reduce((acc, item) => acc + Number(item.total_payment), 0);
      if(restAmount >= 0) newItem.total_payment = restAmount.toFixed(2);
      return [...prev, newItem];
    });
  }

  const setInputFocus = (index) => {
    const $input = dynamicForm[index];
    $input.ref?.current?.focus()
  }
  
  useEffect(() => {
    if(showFormPayment) {
      const values = [...dynamicForm];
      values[0]['total_payment'] = Number(totalAmount).toFixed(2);
      setDynamicForm(values);
      setInputFocus(0);
    }
  }, [showFormPayment]);
  
  const receivedAmount = React.useMemo(() => dynamicForm.reduce((acc, item) => acc + Number(item.total_payment || 0), 0), [dynamicForm]);
  const changeAmount = React.useMemo(() => totalAmount - receivedAmount, [totalAmount, receivedAmount]);
  
  React.useEffect(() => {
    const _index = dynamicForm.length - 1;
    setCurrentIndex(_index);
    setInputFocus(_index);
  }, [dynamicForm.length])
  
  const changeValueForm = (prop, index) => (newValue) => {
    const values = [...dynamicForm];
    if(prop === 'type_payment_id' && values.some(c => c.type_payment_id === InitState.cashId && newValue == InitState.cashId)) return;
    if(prop === 'total_payment') {
      const cashMethodIndex = values.findIndex(c => c.type_payment_id === InitState.cashId);
      const creditMethodIndex = values.findIndex(c => c.type_payment_id === InitState.creditId);
      const currentMethod = values[index]['type_payment_id'];
      if(cashMethodIndex !== -1 && creditMethodIndex !== -1) {
        if(currentMethod === InitState.cashId) {
          // console.log({
            // setCredit: totalAmount - Number(newValue),
            // currentValue: values[cashMethodIndex]['total_payment']
          // });
        } else if(currentMethod === InitState.creditId) {
          // console.log({
          //   setCash: totalAmount - Number(newValue),
          //   currentValue: values[creditMethodIndex]['total_payment']
          // });
        }
      }
    }
    values[index][prop] = newValue;
    setDynamicForm(values);
  }

  const removeItem = (index) => {
    const values = [...dynamicForm];
    values.splice(index, 1);
    setCurrentIndex(values.length - 1);
    setDynamicForm(values);
  }

  const onCancel = () => {
    setCurrentIndex(0);
    setDynamicForm([InitState.formPayment()]);
    closeProcessSale();
  }

  const disabledAddNewPayment = React.useMemo(() => {
    const _lastIndex = dynamicForm.length - 1;
    const totalReceived = dynamicForm.reduce((acc, item) => acc + Number(item.total_payment), 0);
    const totalPayment = dynamicForm[_lastIndex]['total_payment'];
    if(isNaN(totalPayment)) return true;
    return Number(totalPayment) === 0 || (totalReceived >= totalAmount);
  }, [dynamicForm])

  const handlerKeyUp = (ev) => {
    if (ev.key === 'Enter') {
      if(disabledAddNewPayment) return;
      addAnotherPaymentMethod();
    }
  }

  return (
    <div className={classes.paper}>
      {sendingForm && (
        <div className={classes.process}>
          <div className='text-primary'>
            <i className="fas fa-cog fa-pulse d-block mb-2 fa-3x"></i>
            <p className="mb-0 fs-small">Processing</p>
          </div>
        </div>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <h5 className="text-muted text-start mb-2"><FaMoneyCheckAlt style={{ fontSize: '1.6rem' }}/> Payment form</h5>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12}><h6 className="text-muted text-start mb-3 fs-semi-small">Payment method(s)</h6></Grid>
            <Grid item xs={12} className="mb-1">
              <Grid container spacing={1}>
                {dynamicForm.map((form, index) => (
                  <Grid container item spacing={1} key={form.id}>
                    <Grid item xs={6}>
                      <MaterialUI.Select
                        label="Method"
                        className="text-start"
                        options={payments.map((c) => ({ label: c.name, value: c.id }))}
                        state={form.type_payment_id}
                        setState={changeValueForm('type_payment_id', index)}
                        disabled={index !== dynamicForm.length - 1}
                      />
                    </Grid>
                    <Grid item xs={index === 0 ? 6 : 4}>
                      <MaterialUI.Input
                        inputMode="numeric"
                        maxLength="7"
                        label="Amount"
                        placeholder="0.00"
                        InputLabelProps={{ shrink: true }}
                        formatter={InputFormat.limitDecimalPlaces()}
                        state={form.total_payment}
                        setState={changeValueForm('total_payment', index)}
                        onClick={() => setCurrentIndex(index)}
                        inputRef={form.ref}
                        onKeyUp={handlerKeyUp}
                      />
                    </Grid>
                    {index !== 0 && (
                      <Grid item xs={2}>
                        <MaterialUI.Button.Danger tooltip="Delete" onClick={() => removeItem(index)} fullWidth className="fs-small mw-auto h-100">
                          <i className="fas fa-trash"></i>
                        </MaterialUI.Button.Danger>
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} className="mb-3">
              <MaterialUI.Button.Primary fullWidth className="fs-small" disabled={disabledAddNewPayment} onClick={addAnotherPaymentMethod}>
                <i className="fas fa-plus me-2"></i>Add another payment method
              </MaterialUI.Button.Primary>
            </Grid>
            <Grid item xs={12}>
              <MaterialUI.Input
                label="Note"
                rows={3}
                multiline
                state={note}
                setState={(value) => { setNote(value); setErrors({}) }}
                error={errors.note}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <p className="mb-0 fs-small text-success">Import</p>
              <h6 className='font-inter fw-bold text-success'>$ {Number(totalAmount).toFixed(2)}</h6>
            </Grid>
            <Grid item xs={4}>
              <p className="mb-0 fs-small text-primary">Receive</p>
              <h6 className='font-inter fw-bold text-primary'>{!!receivedAmount ? `$ ${receivedAmount.toFixed(2)}` : '-'}</h6>
            </Grid>
            <Grid item xs={4}>
              <p className="mb-0 fs-small text-muted">Change</p>
              <h6 className='font-inter fw-bold text-muted'>
                { receivedAmount && changeAmount <= 0 ? `$ ${(Math.abs(changeAmount)).toFixed(2)}` : '-'}
              </h6>
            </Grid>
            <Grid item xs={12}>
              <Calculator
                state={dynamicForm[currentIndex]['total_payment']}
                setState={changeValueForm('total_payment', currentIndex)}
                onCancel={onCancel}
                // onSubmit={() => { console.log('Pay') }}
                onSubmit={handleStoreReceiptClick}
                disabledSubmit={changeAmount > 0}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default PosDesktopPaymentForm