import createHttp from "../lib/http";
import { useState, useEffect, useCallback } from "react";

function usePayTerms({ requestAutomatically = true }) { 
	const [isLoading, setIsLoading] = useState(false);
	const [synchronized, setSynchronized] = useState(false);
	const [payTerms, setPayTerms] = useState([]);

	const fetchPayTerms = useCallback(() => {
		if(synchronized) return;
		setIsLoading(true);
		let params = {};
		createHttp
			.get("api/pay-terms", { params })
			.then((response) => {
				if (response.data.status) {
					setPayTerms(response.data.data);
				}
				setSynchronized(true);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	}, [synchronized]);
	
	useEffect(() => {
		if(requestAutomatically) fetchPayTerms()
	}, []);

	return {
		isLoading,
		setIsLoading,

		synchronized,
		setSynchronized,

		payTerms,
		setPayTerms,
		fetchPayTerms,
	};
}

export { usePayTerms };
