import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import useApiRequestPayment from "../../../customHooks/useApiRequestSettingPaymentPaypal";
import LogoPaypal from "../../../assets/img/logo-paypal.svg";
import AlertComponent from '../components/AlertComponent';
import MaterialUI from "../../../components/MaterialUI";

const messageError = "This field is required.";

const modes = [
	{ value: 'live', label: 'Live' },
  { value: 'sandbox', label: 'Sandbox'}
]
const status = [
	{ value: "1", label: "Enabled" },
	{ value: "0", label: "Disabled" },
]
const FormPaypal = () => {
	const {
		paypal,
		isLoading,
		error,
		setPaypal,
		updatePaypal,
		removeFromErrorByKey,
	} = useApiRequestPayment();
	const [showAlert, setShowAlert] = React.useState(false);

	const handleSubmit = async (ev) => {
		ev.preventDefault();
		try {
			await updatePaypal(paypal);
			setShowAlert(true);
		} catch (error) {}
	};

	const handleClose = (_, reason) => {
		if (reason === 'clickaway') return;
    setShowAlert(false);
	};

	const handleTypingChange = (key, value) => {
		removeFromErrorByKey(key);
		setPaypal((state) => ({ ...state, [key]: value }));
	};

	if (isLoading) return <CircularProgress />;

	return (
		<div>
			<AlertComponent 
				open={showAlert} 
        handleClose={handleClose} 
        severity="success" 
        message="It has been successfully updated"
			/>
			<div className="text-center">
				<figure className="py-5">
					<img src={LogoPaypal} />{" "}
				</figure>
			</div>
			<form noValidate autoComplete="off" onSubmit={handleSubmit}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={4}>
						<MaterialUI.Input 
							state={paypal.paypal_app_id}
							setState={(value) => handleTypingChange("paypal_app_id", value)}
							error={ error.paypal_app_id && messageError}
							label="Paypal App ID:"
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<MaterialUI.Input 
							state={paypal.paypal_client_id}
							setState={(value) => handleTypingChange("paypal_client_id", value)}
							error={ error.paypal_client_id && messageError}
							label="Paypal Client ID:"
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<MaterialUI.Input
							state={paypal.paypal_client_secret}
							setState={(value) => handleTypingChange("paypal_client_secret", value)}
							error={ error.paypal_client_secret && messageError}
							label="Paypal Client Secret:"
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<MaterialUI.Select 
							options={modes}
							state={paypal.paypal_mode}
							setState={(value) => handleTypingChange("paypal_mode", value)}
							label="Paypal MODE"
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<MaterialUI.Select 
							options={status}
							state={paypal.paypal_status}
							setState={(value) => handleTypingChange("paypal_status", value)}
							label="Paypal Status:"
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<MaterialUI.Button.Success type="submit" fullWidth>
							Save
						</MaterialUI.Button.Success>
					</Grid>
				</Grid>
			</form>
		</div>
	);
};

export default FormPaypal;
