import React from "react";
import clsx from 'clsx'
import PaginationMui from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	paginationContainer: {
		"& .MuiPaginationItem-page.Mui-selected": {
			color: "rgb(13 110 253)",
			backgroundColor: "rgb(13 110 253 / 20%)",
			borderColor: "rgb(13 110 253)",
		},
		"& .MuiPaginationItem-page": {
			borderRadius: "50%",
			minWidth: "28px",
			height: "28px",
			fontSize: "12px",
			display: "inline-grid",
			placeContent: "center",
			padding: "0",
		},
		"& .MuiPaginationItem-page .MuiPaginationItem-icon": {
			fontSize: "1rem",
		},
	},
});

const Pagination = ({ pagination = {}, changePage }) => {
	const classes = useStyles();

	if (!pagination.total_pages || pagination.total_pages === 1) return null;

	const handleChangePageClick = (_, value) => {
		if (Number(pagination.current_page) !== Number(value)) {
			if (changePage && changePage instanceof Function) {
				changePage(value);
			}
		}
	};

	return (
		<div className="mb-2">
			<div className={clsx("position-relative",classes.paginationContainer )}>
				<PaginationMui
					count={pagination.total_pages}
					page={pagination.current_page}
					onChange={handleChangePageClick}
					variant="outlined"
					shape="rounded"
					color="primary"
				/>
			</div>
		</div>
	);
};

export default Pagination;
