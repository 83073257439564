function toDate(fechaString) {
  let fecha = new Date(fechaString);
  let año = fecha.getFullYear();
  let mes = fecha.getMonth() + 1;
  let dia = fecha.getDate();

  if (mes < 10) mes = '0' + mes;
  if (dia < 10) dia = '0' + dia;

  return `${año}-${mes}-${dia}`;
}

export default class Adapter {
  static responseWarehouseToAutocomplete(item) {
    return {
			id: item.id,
      label: item.name,
		};
  }
  static responseVendorToAutocomplete(item) {
    return {
			id: item.id,
      label: item.name,
		};
  }
  static responseCategoryToAutocomplete(item) {
    return {
			id: item.id,
      label: item.name,
		};
  }
  static responsePaymentToAutocomplete(item) {
    return {
			id: item.id,
      label: item.name,
		};
  }
  static responsePaymentAccountToAutocomplete(item) {
    return {
			id: item.id,
      label: item.account,
		};
  }
  static preparePayloadExpenseToSend(data) {
    return {
      ...data,
      amount: String(data.amount),
      warehouse_id: String(data.warehouse_id?.id ?? ''),
      vendor_id: String(data.vendor_id?.id ?? ''),
      expense_category_id: String(data.expense_category_id?.id ?? ''),
    }
  }
  static preparePayloadPaymentToSend(data) {
    return {
      ...data,
      type_payment_id: String(data.type_payment_id?.id ?? ''),
      payment_account_id: String(data.payment_account_id?.id ?? ''),
    }
  }
  static requestPayloadExpense(data) {
    return {
      id: data.id,
      number: data.number,
      amount: data.amount,
      date: data.date,
      description: data.description,
      warehouse_id: {
        id: data.warehouse.id,
        label: data.warehouse.name,
      },
      vendor_id: {
        id: data.vendor.id,
        label: data.vendor.name,
      },
      expense_category_id: {
        id: data.expense_category.id,
        label: data.expense_category.name,
      },
    }
  }

  static requestPayloadPayment(data) {
    return {
      id: data.id,
      type_payment_id: {
        id: data.type_payment.id,
        label: data.type_payment.name,
      },
      payment_account_id: {
        id: data.payment_account.id,
        label: data.payment_account.account,
      },
      date_paid: toDate(data.date_paid),
      payment_info: data.payment_info,
    }
  }
}