import React from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';

import MaterialUI from "../../components/MaterialUI";
import './style.css';

const BarcodeGenerator = () => {
	const [product, setProduct] = React.useState(null);
	const [showPreview, setShowPreview] = React.useState(false);
	const $container = React.useRef(null);

	const [paperConfiguration, setPaperConfiguration] = React.useState({
		columns: 3,
		perPage: 21
	});
	const changePaperConfiguration = (prop) => (value) => setPaperConfiguration((prev) => ({ ...prev, [prop]: value }));

	const [options] = React.useState({
		width: 2,
		height: 60,
		format: "CODE39",
		font: "monospace",
		textAlign: "center",
		fontSize: 14,
	});

	React.useEffect(() => {
		if(!product) {
			setShowPreview(false);
		}
	}, [product])

	
	return (<>
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<div className="container-title mb-2">
					<h1 className="container__title mb-0">
						<i className="fas fa-barcode me-2"></i>Barcode Generator
					</h1>
				</div>
			</Grid>
			<Grid item xs={12} md={5} lg={4} xl={3}>
				<Grid container spacing={1} style={{ position: 'sticky', top: '1rem' }}>
					<Grid item xs={12}>
						<MaterialUI.AutocompleteAsync
							uri={'api/items'}
							params={['search_text']}
							label="Search Product by SKU or Name"
							state={product}
							setState={setProduct}
							getOptionSelected={(option, value) => option?.id == value?.id}
							getOptionLabel={(option) => (`${option.sku} - ${option.name}`) ?? ''}
						/>
					</Grid>
					<Grid item xs={12}>
						<p className="sub__title fs-small">Paper configuration</p>
					</Grid>
					<Grid item xs={12} md={6} lg={5} xl={4}>
						<MaterialUI.Input
							type="number"
							label="Columns"
							state={paperConfiguration.columns}
							setState={changePaperConfiguration('columns')}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={5} xl={4}>
						<MaterialUI.Input
							type="number"
							label="Quantity"
							state={paperConfiguration.perPage}
							setState={changePaperConfiguration('perPage')}
						/>
					</Grid>
					<Grid item xs={12}>
						<Box className="d-flex flex-wrap gap-1 h-100">
							<MaterialUI.Button.Dark className="fs-small h-100"
								disabled={!product || !paperConfiguration.columns || !paperConfiguration.perPage} onClick={() => setShowPreview((prev) => !prev)}
							>
								{showPreview ? <i className="fas fa-eye-slash me-2"></i> : <i className="fas fa-eye me-2"></i>}Preview
							</MaterialUI.Button.Dark>
							<ReactToPrint
								trigger={() => (
									<MaterialUI.Button.Primary className="fs-small h-100" disabled={!product || !paperConfiguration.columns || !paperConfiguration.perPage || !showPreview}>
										<i className="fas fa-print me-2"></i>Print
									</MaterialUI.Button.Primary>
								)}
								content={() => $container.current}
							/>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			{(showPreview && product && paperConfiguration.columns && paperConfiguration.perPage) && (
				<Grid item xs={12} md={7} lg={8} xl={9}>
					<Paper variant="outlined" className="p-2">
						<Grid ref={$container} container spacing={2} className="d-grid p-2" justifyContent="space-around" style={{ gridTemplateColumns: `repeat(${paperConfiguration.columns}, 1fr)` }}>
							{Array(Number(paperConfiguration.perPage) || 0).fill({}).map((_, index) => (
								<div key={`barcode-${index}`}>
									<Barcode className="w-100" value={product.sku} {...options} />
								</div>
							))}
						</Grid>
					</Paper>
				</Grid>
			)}
		</Grid>
	</>);
};

export default BarcodeGenerator;
