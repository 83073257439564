import createHttp from "../lib/http";

const AdjustmentService = {};

AdjustmentService.findById = async (id) => {
  try {
    const { data } = await createHttp.get(`/api/adjustments/${id}`);
    return data;
  } catch (error) {}
}

AdjustmentService.store = async (payload) => {
  try {
    const { data } = await createHttp.post("/api/adjustments", payload);
    return data;
  } catch (error) {}
}

AdjustmentService.update = async (id, payload) => {
  try {
    const { data } = await createHttp.put(`/api/adjustments/${id}`, payload);
    return data;
  } catch (error) {}
}

AdjustmentService.storeDetail = async (payload) => {
  try {
    const { data } = await createHttp.post("/api/adjustment-details", payload);
    return data;
  } catch (error) {}
}

AdjustmentService.updateDetail = async (id, payload) => {
  try {
    const { data } = await createHttp.put(`/api/adjustment-details/${id}`, payload);
    return data;
  } catch (error) {}
}

AdjustmentService.updateStateDetail = async (payload) => {
  try {
    const { data } = await createHttp.post(`/api/adjustment-details_posted`, payload);
    return data;
  } catch (error) {}
}

AdjustmentService.destroyDetail = async (id) => {
  try {
    const { data } = await createHttp.delete(`/api/adjustment-details/${id}`);
    return data;
  } catch (error) {}
}


export default AdjustmentService;