import { useCallback, useEffect, useState } from 'react';
import paypalService from '../services/PaypalService';

const initialState = {
  paypal_app_id: "",
  paypal_client_id: "",
  paypal_client_secret: "",
  paypal_mode: "",
  paypal_status: 0,
}

class AdapterPayment {
  static response(data) {
    return {
      ...data,
      paypal_status: +data.paypal_status
    }
  }
}

const useApiRequestPayment = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [paypal, setPaypal] = useState({ ...initialState });
  const [error, setError] = useState({});

  const fetchPaypal = useCallback(async() => {
    try {
      setError({})
      setIsLoading(true);
      const { status, data } = await paypalService.getSetting();
      if(status) setPaypal(() => AdapterPayment.response(data));
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  });

  const updatePaypal = useCallback(async(payload) => {
    try {
      setError({})
      setIsLoading(true);
      const resp = await paypalService.updateSetting(payload);
      if(resp.status) setPaypal(() => AdapterPayment.response(resp.data));
      else {
        setError(resp.msg);
        throw new Error("Error updating setting payment paypal");
      };
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  });

  const removeFromErrorByKey = (key) => {
    setError({
    ...error,
      [key]: ''
    });
  }

  useEffect(() => {
    fetchPaypal();
  }, []);

  return {
    removeFromErrorByKey,
    updatePaypal,
    fetchPaypal,
    setPaypal,

    isLoading,
    paypal,
    error
  }
}

export default useApiRequestPayment;