import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import MaterialUI from '../MaterialUI';

function ModalEditTax({show, onHandleClose, title, handleAction, tax, isLoading}) {
    const [tax_form, setTax_form] = useState({
        tax:0.1, 
        tax_name:"",
        only_for_group:true,
    });

    useEffect(() => {
        if(tax && Object.keys(tax).length) {
            setTax_form((prev) => ({
                ...prev,
                id: tax.id, 
                tax: tax.tax, 
                tax_name: tax.tax_name,
                only_for_group: tax.only_for_group,
            }));
        }
    },[tax]);

    const [errors, setErrors] = useState({
        tax: "",
        tax_name: ""
    });

    const validateInputs = () => {
        let isValid = true;
        const newErrors = {};

        // Validar el campo de tax
        if (isNaN(tax_form.tax) || tax_form.tax === "" || parseFloat(tax_form.tax) < 0.1 || parseFloat(tax_form.tax) > 99.9) {
            newErrors.tax = "Tax rate must be a number between 0.1 and 99.9";
            isValid = false;
        }

        // Validar el campo de tax_name
        const tax_name_without_space = tax_form.tax_name.trim();
        if (tax_name_without_space.length < 5 || tax_name_without_space.length > 30 ) {
            newErrors.tax_name = "Tax name must be between 5 and 30 characters long";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e, field) => {
        const { value } = e.target;
        const newErrors = { ...errors }; 

        if (field === "tax_name") {
            if (value.trim().length < 5 || value.trim().length > 30) {
                newErrors.tax_name = "Tax name must be between 5 and 30 characters long";
            } else {
                delete newErrors.tax_name; 
            }
        }

        if (field === "tax") {
            if (isNaN(value) || parseFloat(value) < 0.1 || parseFloat(value) > 99.9) {
                newErrors.tax = "Tax rate must be a number between 0.1 and 99.9";
            } else {
                delete newErrors.tax; 
            }
        }

        setErrors(newErrors);
    
        setTax_form({ ...tax_form, [field]: value });
    };

    const handleSubmit = () => {
        if (validateInputs()) {
            handleAction(tax_form);
        }
    };

    //close modal
    useEffect(() => {
        //clear form
        if(!isLoading){
            onHandleClose();
        }
    },[isLoading])

    //reset values when open modal
    useEffect(() => {
        if(show){
            setTax_form({
                id: tax.id, 
                tax: tax.tax, 
                tax_name: tax.tax_name,
                only_for_group: tax.only_for_group,
            })
            setErrors({});
        }
    },[show])

    return (
		<React.Fragment>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
                backdrop="static"
                keyboard={false}
				show={show}
				onHide={onHandleClose}
			>
                <Modal.Header style={{ background: 'var(--login-blue)', color: '#fff' }}>
                    <Modal.Title as="h6" className='fw-bold'>{title}</Modal.Title>
                </Modal.Header>  
				<Modal.Body>
					<Form autoComplete='off'>
                        <Form.Group className='mb-2' controlId='tax-name'>
                            <Form.Label className='mb-0'>Name</Form.Label>
                            <Form.Control 
                                maxLength={30}
                                onChange={(e) => handleChange(e, "tax_name")}
                                value={tax_form.tax_name}
                                type="text"
                            />
                            {errors.tax_name && <Form.Text className='text-danger'>{errors.tax_name}</Form.Text>}
                        </Form.Group>
                        <Form.Group className='mb-2' controlId='tax-rate'>
                            <Form.Label className='mb-0'>Tax Rate %</Form.Label>
                            <Form.Control 
                                max={99.9}
                                onChange={(e) => handleChange(e, "tax")}
                                value={tax_form.tax}
                                type="number"
                            />
                            {errors.tax && <Form.Text className='text-danger'>{errors.tax}</Form.Text>}
                        </Form.Group>
                        <Form.Group className='mb-4' controlId='tax-confirm'>
                            <Form.Check 
                                type="checkbox"
                                checked={tax_form.only_for_group}
                                onChange={() => setTax_form((prev) => ({ ...prev, only_for_group: !prev.only_for_group}))}
                                label="For tax group only"
                            />
                        </Form.Group>
                        <Form.Group className='mb-1 text-end'>
                            <MaterialUI.Button.Success className="me-1" onClick={handleSubmit}>
                                {isLoading ? "Loading ..." : "Update"}
                            </MaterialUI.Button.Success>
                            <MaterialUI.Button.Secondary onClick={onHandleClose}>
                                Cancel
                            </MaterialUI.Button.Secondary>
                        </Form.Group>
                    </Form>

				</Modal.Body>
			</Modal>
		</React.Fragment>
    );
}

export {ModalEditTax};