import React from 'react';
import { Box, Divider, Drawer, IconButton, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const useStyles = makeStyles(({
 drawerDesktop: {
  inlineSize: '500px',
 },
 drawerDesktopLarge: {
  inlineSize: '700px',
 },
 drawerMobile: {
  inlineSize: '100vw',
 },
 headerTitle: {
  fontWeight: 'bold',
  color: 'var(--bs-gray-800)'
 }
}));

const Panel = ({ children, open, togglePanel, headerTitle, anchor = 'right', pSize = 'default', ...others }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const classesModify = pSize === 'default' ? classes.drawerDesktop : classes.drawerDesktopLarge
  const mainClasses = isMobile ? classes.drawerMobile : classesModify
  return (
    <Drawer open={open} onClose={togglePanel} anchor={anchor} {...others}>
      <Box p={1} className='d-flex justify-content-between align-items-center'>
        <Typography className={classes.headerTitle}>{headerTitle ?? '-'}</Typography>
        <IconButton size='small' aria-label="Close" onClick={togglePanel}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box p={1} paddingTop={3} className={mainClasses}>
        {children}
      </Box>
    </Drawer>
  );
};

export default Panel;