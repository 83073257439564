export const validateEmail = (value) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
  return regex.test(value);
};

const regexEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexURL = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?/;

export const validateFields = (props) => {
  let errors = {};
  const { fields, rules } = props;
  for (const item of rules) {
    const value = String(fields[item.name]);
    if (item.rules.includes("required") && !(/\S/.test(value))) { // ['required'] @ Does not accept empty
      errors = {
        ...errors,
        [item.name]: "This field is required.",
      };
    } else if (item.rules.includes("string")) { // ['string'] | ['string', 'min:10'] | ['string', 'max:10']]
      if(item.rules.includes("nullable") && !value) continue;
      if (!value.match(/^[a-zA-Z]+$/)) {
        errors = {
          ...errors,
          [item.name]: "This field must be a string.",
        };
      } else {
        const hasLimit = item.rules.find((c) => c.startsWith("min") || c.startsWith("max"));
        if (!!hasLimit) {
          let [mode, limit] = hasLimit.split(":");
          if (mode === "min" && value.length < Number(limit)) {
            errors = {
              ...errors,
              [item.name]: `This field must be minimum ${limit} characters.`,
            };
          } else if (mode === "max" && value.length > Number(limit)) {
            errors = {
              ...errors,
              [item.name]: `This field must be maximum ${limit} characters.`,
            };
          }
        }
      }
    } else if (item.rules.includes("decimal")) { // ['decimal', 'format:10000,2']
      if(item.rules.includes("nullable") && !value) continue;
      if(value.match(/^(\d+(\.\d*)?|\.\d+)$/)) {
        let hasFormat = item.rules.find((c) => c.startsWith("format"));
        if(!hasFormat) continue;
        hasFormat = hasFormat.replace("format", "").replace(':', '');
        let [maxValue, maxDecimals] = hasFormat.split(",");
        if(Number(value) > Number(maxValue)) {
          errors = {
            ...errors,
            [item.name]: `This field must be less than ${Number(maxValue).toFixed(2)}`,
          }
          continue;
        }
        const [_, decimals] = value.split(".");
        if(!decimals) continue;
        if(decimals.length > Number(maxDecimals)) {
          errors = {
            ...errors,
            [item.name]: `this field must have ${maxDecimals} decimal places.`,
          }
        }
      } else {
        errors = {
        ...errors,
          [item.name]: "This field must be a decimal.",
        };
      }
    } else if (item.rules.includes("numeric")) { // ['numeric'] | ['numeric' , 'digits:10'] | ['numeric', 'min:10'] | ['numeric', 'max:10']
      if(item.rules.includes("nullable") && !value) continue;
      if (!value.match(/^[0-9]+$/)) {
        errors = {
          ...errors,
          [item.name]: "This field must be a numeric.",
        };
      } else {
        const hasDigits = item.rules.find((c) => c.startsWith("digits"));
        if (hasDigits) {
          let [mode, digits] = hasDigits.split(":");
          if (mode === "digits" && value.length !== Number(digits)) {
            errors = {
              ...errors,
              [item.name]: `This field must have ${digits} digits.`,
            };
          }
        } else {
          const hasLimit = item.rules.find((c) => c.startsWith("min") || c.startsWith("max"));
          if (hasLimit) {
            let [mode, limit] = hasLimit.split(":");
            if (mode === "min" && Number(value) < Number(limit)) {
              errors = {
                ...errors,
                [item.name]: `This field must be less than ${limit}.`,
              };
            } else if (mode === "max" && Number(value) > Number(limit)) {
              errors = {
                ...errors,
                [item.name]: `This field must be greater than ${limit}.`,
              };
            }
          }
        }
      }
    } else if (item.rules.includes("email") && !value.match(regexEmail)) { // ['email']
      if(item.rules.includes("nullable") && !value) continue;
      errors = {
        ...errors,
        [item.name]: "This field must be a valid email.",
      };
    } else if (item.rules.includes("url")) { // ['url']
      if(item.rules.includes("nullable") && !value) continue;
      if(!value.match(regexURL)) {
        errors = {
          ...errors,
          [item.name]: "This field must be a valid URL.",
        };
      }
    }
  }
  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};
