import React from "react";

import { Grid } from "@material-ui/core";
import MaterialUI from "../../components/MaterialUI";
import { CashCounterContext } from "../../context/CashCounterContext";
import InputFormat from "../../utils/input.format";

const CashCounterForm = ({ onSuccess, onClose }) => {
  const {
		onChangeCashCounter,
		cashCounterErrors,
		cashCounterLastOpen,
		storeCashCounter,
		closeCashCounter,
		cashCounterForm,
		counterIsOpen,
		setLoading,
		loading,
		cleanFormCashCounter
	} = React.useContext(CashCounterContext);
	
	const handleCloseClick = () => {
    if(onClose && onClose instanceof Function) {
			cleanFormCashCounter();
      onClose();
		}
  }

  const handleSubmit = async () => {
		if(loading) return;
    try {
			setLoading(true);
      const callback = counterIsOpen ? closeCashCounter : storeCashCounter;
      const resp = await callback(cashCounterForm);
      if(resp.status && onSuccess && onSuccess instanceof Function) {
				cashCounterLastOpen();
        onSuccess(resp.msg);
      }
    }
		catch (error) {}
		finally {
			setLoading(false);
		}
  }

	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<MaterialUI.Input
						label="Amount"
						type="number"
						state={cashCounterForm.amount}
						setState={onChangeCashCounter("amount")}
						formatter={InputFormat.limitDecimalPlaces()}
						error={cashCounterErrors.opening_amount || cashCounterErrors.close_amount}
					/>
				</Grid>
				<Grid item xs={12}>
					<MaterialUI.Input
						label="Note"
						rows={3}
						multiline
						state={cashCounterForm.note}
						setState={onChangeCashCounter("note")}
						error={cashCounterErrors.opening_note || cashCounterErrors.close_note}
					/>
				</Grid>
				<Grid item xs={12} className="text-end">
					<MaterialUI.Button.Secondary className="fs-small me-1" onClick={handleCloseClick} style={{ inlineSize: '100px' }}>
						Cancel
					</MaterialUI.Button.Secondary>
					<MaterialUI.Button.Success className="fs-small" onClick={handleSubmit} disabled={loading} style={{ inlineSize: '100px' }}>
						{counterIsOpen ? 'Close' : 'Open'}
					</MaterialUI.Button.Success>
				</Grid>
			</Grid>
		</>
	);
};

export default CashCounterForm;
