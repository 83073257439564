import React from "react";
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, Card, CardContent, Paper, DialogActions } from "@material-ui/core";
import { FaCashRegister, FaCog, FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { EditOutlined, DeleteOutlined } from '@material-ui/icons';
import { useParams, useHistory } from 'react-router-dom';
import clsx from "clsx";
import Swal from "sweetalert2";

import { useCashCounter, initState } from '../../customHooks/useCashCounter';
import { LaravelErrorsToObject } from '../../utils/errors.laravel';
import InputFormat from '../../utils/input.format';

import MaterialUI from "../../components/MaterialUI";
import Dialog from '../../components/Dialog';

import CashMachineImg from '../../assets/img/cash-machine.png';
import SwalAlerts from '../../utils/alerts';
import CashCounterFormTransaction from "./CashCounterFormTransaction";
import { CashCounterContext } from "../../context/CashCounterContext";

const useStyles = makeStyles({
	table: { padding: 0 }
});

const CashCounter = () => {
	const classes = useStyles();
	const params = useParams();
	const history = useHistory();
	const [errors, setErrors] = React.useState({});
	const {
		cashCounterLastOpen,
		storeCashCounter,
		closeCashCounter,
		findById,
		setCashCounter,
		setCounterIsOpen,
		getCashCounterIsOpen,
		restoreState,
		counterIsOpen,
		cashCounter,
	} = useCashCounter();
	const {
		fetchTransactions,
		handleDestroyTransactionClick,
		editTransaction,
		clearFormTransaction,
		transactions,
		transaction,
		setLoading,
		loading,
		setErrorsTransactions,
	} = React.useContext(CashCounterContext);
	const [cashCounterForm, setCashCounterForm] = React.useState(initState.cashCounterForm);
	const inputAmount = React.useRef(null);
	
	const onChange = (prop) => (value) => {
		setErrors({});
		setCashCounterForm((prev) => ({ ...prev, [prop]: value }))
	}

	const getCashCounterLastOpen = () => {
		cashCounterLastOpen()
			.then((resp) => {
				if(resp) {
					if(resp.date_close) {
						restoreState();
					} else {
						setCounterIsOpen(getCashCounterIsOpen(resp));
						fetchTransactions(resp.id);
					}
				}
			})
	}

	React.useEffect(() => {
		restoreState();
		if(params?.id) {
			fetchTransactions(params?.id);
			findById(params?.id)
				.then(({ status, data }) => {
					if(status) {
						setCashCounter(data);
						setCounterIsOpen(getCashCounterIsOpen(data));
					} else {
						history.replace('/cash-counter');
					}
				});
		} else {
			getCashCounterLastOpen();
		}
	}, [params]);

	const handleSubmit = (onClose) => async () => {
		if(loading) return;
		try {
			setErrors({});
			let resp = {};
			setLoading(true);
			if(!counterIsOpen) resp = await storeCashCounter(cashCounterForm);
			else resp = await closeCashCounter(cashCounterForm);
			if(resp.status) {
				getCashCounterLastOpen();
				restoreState();
				onClose();
				Swal.fire(SwalAlerts.success);
			} else {
				setErrors(LaravelErrorsToObject(resp.msg));
			}
		} catch (error) {}
		finally {
			setLoading(false);
		}
	}
	
	const FormCashCounterModal = (onClose) => {
		return <>
			<div>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<MaterialUI.Input
							label="Amount"
							state={cashCounterForm.amount}
							setState={onChange('amount')}
							inputRef={inputAmount}
							formatter={InputFormat.limitDecimalPlaces()}
							type="number"
							error={errors.opening_amount || errors.close_amount}
						/>
					</Grid>
					<Grid item xs={12}>
						<MaterialUI.Input
							label="Note"
							rows={3}
							multiline
							state={cashCounterForm.note}
							setState={onChange('note')}
							error={errors.opening_note || errors.close_note}
						/>
					</Grid>
				</Grid>
			</div>
			<DialogActions className="px-0">
				<MaterialUI.Button.Secondary className="fs-small" onClick={onClose} style={{ inlineSize: '100px' }}>
					Cancel
				</MaterialUI.Button.Secondary>
				<MaterialUI.Button.Success className="fs-small" disabled={loading} onClick={handleSubmit(onClose)} style={{ inlineSize: '100px' }}>
					{counterIsOpen ? 'Close' : 'Open'}
				</MaterialUI.Button.Success>
			</DialogActions>
		</>
	}

	const handleFormMovementSubmit = (form) => {
		console.log("handleFormMovementSubmit");
		console.log(form);
	}
	
	const [showModal, setShowModal] = React.useState({ movement: false, cashCounter: false });
	const handleToggleModalClick = (currentModal) => () => {
		setShowModal((prev) => ({
			...prev,
			[currentModal]: !prev[currentModal],
		}));
	}

	const toggleModalCashCounter = () => {
		setErrors({});
		setCashCounterForm((prev) => ({...prev, ...initState.cashCounterForm }));
		handleToggleModalClick('cashCounter')();
	}

	function formatWithSign(num) {
    const format = new Intl.NumberFormat('en-US', {
        signDisplay: 'always',
        minimumFractionDigits: 2
    });
    return format.format(num);
	}

	const handleCreateClick = () => {
		clearFormTransaction();
    handleToggleModalClick('movement')();
  }
	
	const handleEditClick = (row) => {
		editTransaction(row);
		setErrorsTransactions({})
		handleToggleModalClick('movement')();
	}

	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Box className="mb-4 d-flex align-items-center gap-4 text-muted">
						<Box className="d-flex align-items-center gap-2">
							<FaCashRegister style={{ fontSize: '1.35rem' }} />
							<h5 className="container__title mb-0">Cash Counter</h5>
						</Box>
						<Box className="d-flex align-items-center gap-1">
							<MaterialUI.Button.Warning className="fs-small" onClick={() => history.push('/pos')}>
								Go POS
							</MaterialUI.Button.Warning>
							<MaterialUI.Button.Dark className="fs-small" onClick={() => history.push('/cash-counters')}>
								Go Cash counter list
							</MaterialUI.Button.Dark>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} md={4}>
					<Paper variant="outlined">
						<Box className="text-center px-1 py-4 d-flex flex-column align-items-center">
							<img src={CashMachineImg} className={clsx(!counterIsOpen && 'grayscale', 'mb-4')} alt="Cash Machine" width={165} />
							{!!Object.keys(cashCounter).length && (
								<TableContainer className="mb-2">
									<Table className={classes.table} size="small">
										<TableHead>
											<TableRow>
												<TableCell className="px-1 fs-small" colSpan={2} align="center" scope="row">
													General Information Open - 
													{cashCounter.date_close ? <strong className="ms-1 text-danger">Close</strong> : <strong className="ms-1 text-primary">Open</strong>}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell style={{ inlineSize: 90 }} className="px-1 fs-small text-muted" align="right" scope="row">Responsible:</TableCell>
												<TableCell className="px-1 fs-small" scope="row">{cashCounter?.opening_user?.name}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell className="px-1 fs-small text-muted" align="right" scope="row">Opened:</TableCell>
												<TableCell className="px-1 fs-small" scope="row">{cashCounter.date_open}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell className="px-1 fs-small text-muted" align="right" scope="row">Amount:</TableCell>
												<TableCell className="px-1 fs-small" scope="row">$ {cashCounter.opening_amount}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell className="px-1 fs-small text-muted" align="right" scope="row">Observation:</TableCell>
												<TableCell className="px-1 fs-small" scope="row">{cashCounter.opening_note}</TableCell>
											</TableRow>
											{!!cashCounter.date_close && (<>
												<TableRow>
													<TableCell className="px-1 fs-small" colSpan={2} align="center" scope="row">General Information Close</TableCell>
												</TableRow>
												<TableRow>
													<TableCell style={{ inlineSize: 90 }} className="px-1 fs-small text-muted" align="right" scope="row">Responsible:</TableCell>
													<TableCell className="px-1 fs-small" scope="row">{cashCounter?.close_user?.name}</TableCell>
												</TableRow>
												<TableRow>
													<TableCell className="px-1 fs-small text-muted" align="right" scope="row">Opened:</TableCell>
													<TableCell className="px-1 fs-small" scope="row">{cashCounter.date_close}</TableCell>
												</TableRow>
												<TableRow>
													<TableCell className="px-1 fs-small text-muted" align="right" scope="row">Amount:</TableCell>
													<TableCell className="px-1 fs-small" scope="row">$ {cashCounter.close_amount}</TableCell>
												</TableRow>
												<TableRow>
													<TableCell className="px-1 fs-small text-muted" align="right" scope="row">Observation:</TableCell>
													<TableCell className="px-1 fs-small" scope="row">{cashCounter.close_note}</TableCell>
												</TableRow>
											</>)}
										</TableBody>
									</Table>
								</TableContainer>
							)}
							{!cashCounter.date_close && (<>
								<MaterialUI.Button.Primary className="w-75 fs-small" onClick={toggleModalCashCounter}>
									{counterIsOpen ? 'Close' : 'Open'} cash counter
								</MaterialUI.Button.Primary>
							</>)}
						</Box>
					</Paper>
				</Grid>
				{/* TODO */}
				{(!!Object.keys(cashCounter).length && counterIsOpen) && (
					<Grid item xs={12} md={8}>
						<Paper variant="outlined">
							<Box className="p-2">
								<Box display="flex" justifyContent='space-between' alignItems="center" className="mb-2">
									<h4 className="text-muted mb-0">Transactions</h4>
									{!cashCounter.date_close && (
										<MaterialUI.Button.Success tooltip="Add new transaction" disabled={!!cashCounter.date_close || loading} onClick={handleCreateClick}>
											<i className="fas fa-plus me-2"></i>New transaction
										</MaterialUI.Button.Success>
									)}
								</Box>
								<TableContainer>
									<Table className={classes.table} size="small">
										<TableHead>
											<TableRow>
												{!cashCounter.date_close && <TableCell className="px-1 fs-small" align="center" style={{ minInlineSize: '100px', inlineSize: '100px' }}><FaCog fontSize={16} className="pe-1" />Actions</TableCell>}
												<TableCell className="px-1 fs-small" align="center" style={{ minInlineSize: '50px', inlineSize: '50px' }}>Type</TableCell>
												<TableCell className="px-1 fs-small">Description</TableCell>
												<TableCell className="px-1 fs-small" align="right" style={{ minInlineSize: '125px', inlineSize: '125px' }}>Amount</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{!!transactions.length ? <>
												{transactions.map((row) => (
													<TableRow key={row.id}>
														{!cashCounter.date_close && (
															<TableCell className="px-1 fs-small" align="center">
																<MaterialUI.Button.Primary tooltip="Edit" size="small" onClick={() => handleEditClick(row)} className="mw-auto me-1">
																	<EditOutlined fontSize="small" />
																</MaterialUI.Button.Primary>
																<MaterialUI.Button.Danger tooltip="Delete" size="small" onClick={() => handleDestroyTransactionClick(row)} className="mw-auto">
																	<DeleteOutlined fontSize="small" />
																</MaterialUI.Button.Danger>
															</TableCell>
														)}
														<TableCell className="px-1 fs-small" align="center">
															{row.positive_transaction ? <FaLongArrowAltUp className="text-success" /> : <FaLongArrowAltDown className="text-danger" />}
														</TableCell>
														<TableCell className="px-1 fs-small">{row.note}</TableCell>
														<TableCell className={`px-1 fs-small fw-bold font-inter ${row.positive_transaction ? 'text-success' : 'text-danger'}`} align="right">
															{formatWithSign(row.amount)}
														</TableCell>
													</TableRow>
												))}
												<TableRow className="bg-white">
													<TableCell className="px-1 fs-small" colSpan={!cashCounter.date_close ? 3 : 4}></TableCell>
												</TableRow>
												<TableRow className="bg__light__gray">
													<TableCell className="px-1 fs-small" align="right" colSpan={!cashCounter.date_close ? 3 : 2}>Total</TableCell>
													<TableCell className={`px-1 fs-small fw-bold font-inter ${(transactions.reduce((acc, item) => acc + Number(item.amount || 0), 0) >= 0) ? 'text-success' : 'text-danger'}`} align="right">
														{formatWithSign(transactions.reduce((acc, item) => acc + Number(item.amount || 0), 0))}
													</TableCell>
												</TableRow>
											</> : (
												<TableRow className="bg-white">
													<TableCell className="px-1 text-muted py-4" colSpan={!cashCounter.date_close ? 3 : 4} align="center">
														<i className="fas fa-list-ol fa-2x mb-2"></i>
														<p className="mb-0 fs-small">Empty</p>
													</TableCell>
												</TableRow>
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						</Paper>
					</Grid>
				)}
			</Grid>
			<Dialog
				open={showModal.cashCounter}
				title={`${!!Object.keys(cashCounter).length ? 'Close' : 'Open'} Cash Counter`}
				onClose={handleToggleModalClick('cashCounter')}
				onActive={() => inputAmount.current?.focus()}
				fullWidth={true}
				maxWidth="xs"
				disableEscapeKeyDown={true}
				children={FormCashCounterModal}
			/>
			<Dialog
				open={showModal.movement}
				title={`${transaction.cash_count_transaction_id ? 'Update' : 'Create'} Movement`}
				onClose={handleToggleModalClick('movement')}
				fullWidth={true}
				maxWidth="xs"
				disableEscapeKeyDown={true}
				children={(onClose) => (showModal.movement &&
					<CashCounterFormTransaction 
						onSuccess={(text) => { handleToggleModalClick('movement')(); Swal.fire({ ...SwalAlerts.success, text }) }}
						onClose={onClose}
						onSubmit={handleFormMovementSubmit}
					/>
				)}
			/>
		</>
	);
};

export default CashCounter;
