import axios from "axios";

const createHttp = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/json, application/x-www-form-urlencoded",
		Accept: "application/json",
		// 'Authorization': 'Bearer ' + (localStorage.jwt_token !== undefined ? JSON.parse(localStorage.jwt_token) : ''),
		Authorization:
			"Bearer " +
			(localStorage.jwt_token !== undefined
				? JSON.parse(localStorage.jwt_token)
				: ""),
	},
});

const isHandlerEnabled = (config = {}) => {
	return !(config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled);
};

const errorHandler = (error) => {
	if (isHandlerEnabled(error.config)) {
		if (error.response) {
			/*
			 * The request was made and the server responded with a
			 * status code that falls out of the range of 2xx
			 */
			// console.group("Error Http request");
			// console.debug("Error Http data:", error.response.data);
			// console.debug("Error http status", error.response.status);
			// console.debug("Error http headers", error.response.headers);
			// console.groupEnd();
		} else if (error.request) {
			/*
			 * The request was made but no response was received, `error.request`
			 * is an instance of XMLHttpRequest in the browser and an instance
			 * of http.ClientRequest in Node.js
			 */
			// console.debug("Error Http response", error.request);
		} else {
			// Something happened in setting up the request and triggered an Error
			// console.debug("Error Http", error.message);
		}
	}

	return error.response;
	// return Promise.reject(error.response)
};

const successHandler = (response) => {
	if (isHandlerEnabled(response.config)) {
		// console.log("response Http", response);
	}
	if (response.status === 401) {
		// console.log("response 401:", response);
	}
	return response;
};

createHttp.interceptors.response.use(
	(response) => successHandler(response),
	(error) => errorHandler(error),
);

export default createHttp;
