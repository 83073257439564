import { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';

import TabPanel from '../../../components/TabPanel';
import FormPaypal from './FormPaypal';
import FormStripe from './FormStripe';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    padding: 0,
  },
  tabs: {
    borderBottom: '1px solid var(--bs-gray-300)',
    padding: 0,
  },
  tabPanel: {
    minBlockSize: '500px'
  }
}));

export default function Payment() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className={classes.tabs}
      >
        <Tab label="Paypal" {...a11yProps(0)} />
        <Tab label="Stripe" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabPanel} dir={theme.direction}>
        <FormPaypal />
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabPanel} dir={theme.direction}>
        <FormStripe />
      </TabPanel>
    </div>
  );
}
