import { useState, useEffect } from "react";
import createHttp from "../lib/http";
import { amountPerPagePagination } from "../context/constants";

var _ = require("lodash");

function useApiRequestAjustment(
	manageResponseErrors,
	manageResponse,
	manageSuccessfullMesssage,
) {
	const [isLoading, setIsLoading] = useState(false);
	const [sincronizedItems, setSincronizedItems] = useState(false);
	const [filtered_data, setFilteredData] = useState([]);
	const [user, setUser] = useState([]);
	const [itemFilters, setItemFilters] = useState([
		{ field_id: "page", value: "1" },
		{ field_id: "per_page", value: amountPerPagePagination },
	]);
	const [filtersDefinition, setFiltersDefinition] = useState([]);
	const [itemPagination, setItemPagination] = useState([]);

	const selectOnChange = (filter) => {
		console.log("filter", filter);
		const copyFilters = [...itemFilters];
		const hasFilter = copyFilters.findIndex(
			(filterItem) => filterItem.field_id === filter.field_id,
		);
		if (hasFilter !== -1) {
			copyFilters.splice(hasFilter, 1);
		}
		setItemFilters([...copyFilters, filter]);
	};

	const onChangeDateFilter = (value, field_id) => {
		selectOnChange({
			field_id: field_id,
			value: value,
		});
	};

	const requestBySearchText = _.debounce(function () {
		setSincronizedItems(false);
	}, 2500);

	const filterInputChange = (value) => {
		const copyFilters = [...itemFilters];
		setItemFilters([
			...copyFilters,
			{
				field_id: "search_text",
				value: value,
			},
		]);
		requestBySearchText();
	};

	useEffect(async () => {
		setIsLoading(true);

		let params = {};
		itemFilters
			.filter((filter) => filter.value)
			.map((filter) => (params[filter.field_id] = filter.value));

		if (!sincronizedItems) {
			await createHttp
				.get("api/adjustments", { params })
				.then((response) => {
					if (response.data.status) {
						setUser(response.data.data);
						setFilteredData(response.data.data);
						setItemPagination(response.data.pagination);
					} else {
						//manage error
						manageResponseErrors(
							response.data.message,
							"Adjustment",
							"listing",
						);
					}
					setSincronizedItems(true);
					setIsLoading(false);
				})
				.catch((response) => {
					console.log("error", response);
					setIsLoading(false);
					setSincronizedItems(true);
					manageResponseErrors(
						"An error has occurred",
						"Adjustment",
						"listing",
					);
				});
		}
		setIsLoading(false);
	}, [sincronizedItems]);

	const deleteItem = async (id) => {
		setIsLoading(true);
		await createHttp
			.delete("api/adjustments/" + id)
			.then((res) => {
				if (!res.data.status) {
					manageResponse(res, "deleting", "Adjustment");
				} else {
					setSincronizedItems(false);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
			});
	};

	return {
		isLoading,
		setIsLoading,
		sincronizedItems,
		setSincronizedItems,
		filtered_data,
		user,
		setFilteredData,
		setUser,
		deleteItem,
		filtersDefinition,
		setFiltersDefinition,
		selectOnChange,
		onChangeDateFilter,
		filterInputChange,
		itemFilters,
		setItemFilters,
		itemPagination,
		setItemPagination,
	};
}

export { useApiRequestAjustment };
