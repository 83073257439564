import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Http from '../../lib/http';
import { Grid, Box, Button, Table, TableBody ,TableCell ,TableContainer ,TableHead ,TableRow ,Paper, Toolbar, Typography, Tooltip, useMediaQuery, Fade, Modal, Backdrop } from '@material-ui/core';
import MaterialUI from '../../components/MaterialUI';
import "./dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdjust, faBuilding, faChartLine, faClipboardCheck, faDollarSign, faMoneyBillAlt, faReceipt, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import moment from 'moment';
import { CustomModal } from "../../components/CustomModal/CustomModal";
import ReceiptOptions from '../Receipt/ReceiptOptions'

import { calculate, formatDate2 } from "../../context/constants";
import { AppContext } from "../../context/AppContext";
import Adapter from './dashboard.adapter';

//custom hooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";
import { useAuthentication } from "../../customHooks/useAuthentication";

const superAdminRoles = ["Super Admin"];

const useStyles = makeStyles({
  table: {
		padding: 0,
    minWidth: 650,
  },
	container: {
		maxHeight: 450
	},
	modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const rowHeadersRecentSales = ["Id", "Date", "Type", "Client", "Due Date", "Balance", "Total", "Status", "Edit"];
const rowHeadersRecentStockReceipt = ["Bill Number", "Date Due", "Dat Receipt", "Description", "Status", "Type", "Total", "Edit"];
const rowHeadersRecentRegisteredCompanies = ["#", "Name", "Email", "Packages", "Date"]

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
		display: 'grid',
		gridTemplateColumns: '1fr auto',
		background: '#D9D9D9'
  },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = ({ title, handleClick }) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">{title}</Typography>
			{ (!!handleClick && typeof handleClick == 'function') && (
				<Tooltip title="Go" onClick={handleClick}><Typography className="text-muted fw-bod">View More</Typography></Tooltip>
			)}
    </Toolbar>
  );
};

function Dashboard() {
	const classes = useStyles();
	const { manageResponseErrors, manageResponse, manageSuccessfullMesssage } = useModalHook();
	const { changeNavigationVisible } = useContext(AppContext);
	const isMobile = useMediaQuery('(max-width:600px)');
	const [rowsRecentSales, setRowsRecentSales] = useState([]);
	const [rowsRecentStockReceipt, setRowsRecentStockReceipt] = useState([]);
	const [startOfDate, setStartOfDate] = useState(moment().subtract(3, 'days').format('YYYY-MM-DD'));
	const [endOfDate, setEndOfDate] = useState(moment().format('YYYY-MM-DD'));
	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const [errors, setErrors] = useState({});
	
	const [dashboardInfo, setDashboardInfo] = useState({
		adjustmens: {
			total_adjustment: 0,
			total_sum_adjustments: Number(0).toFixed(2),
		},
		expense: {
			total_expense: 0,
			total_sum_expense: Number(0).toFixed(2),
		},
		invoices: {
			total_invoices: 0,
			total_sum_invoices: Number(0).toFixed(2),
		},
		stock_receipts: {
			total_stock_receipt: 0,
			total_sum_stock_receipt: Number(0).toFixed(2),
		},
		workorders: {
			total_workorders: 0,
			total_pendient: 0,
			total_finish: 0,
		},
	});

	const [dashboardInfoAdminIndicators, setDashboardInfoAdminIndicators] = useState({
		active_companies: {
			active_companies_text: "Active Companies",
			active_companies: 0,
		},
		inactive_companies: {
			inactive_companies_text: "Inactive Companies",
			inactive_companies: 0,
		},
		licence_expired_companies: {
			licence_expired_companies_text: "License Expired",
			licence_expired_companies: 0,
		},
		total_companies: {
			total_companies_text: "Total Companies",
			total_companies: 0,
		},
	});

	const [tableData, setTableData] = useState([]);

	const { getListItem, isLoading } = useApiCustomRequest(
		manageResponseErrors,
		"work-orders",
		"Work Order",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		() => {},
	);

	const history = useHistory();
	const { userInfo } = useAuthentication();	

	const keyPrefix = {
		total: "total_",
		total_sum: "total_sum_",
	};

	const mapKeysTotal = {
		invoices: "invoices",
		stock_receipts: "stock_receipt",
		adjustmens: "adjustment",
		expense: "expense",
		pendient: "pendient",
		finish: "finish",
		workorders: "workorders",
	};

	const mapKeysSum = {
		invoices: "invoices",
		stock_receipts: "stock_receipt",
		adjustmens: "adjustments",
		expense: "expense",
		workorders: "workorders",
	};

	const fetchSales = useCallback(() => {
		getListItem(
			"dashboard/last_sales",
			"Dashboard Info",
			{
				start_date: startOfDate,
				end_date: endOfDate,
			},
			true,
		).then(({ data, data_stock_receipt }) => {
			setRowsRecentSales(() => data.map(Adapter.responseSales));
			setRowsRecentStockReceipt(() => data_stock_receipt.map(Adapter.responseStockRequest));
		});
	}, [startOfDate, endOfDate]);

	const fetchAllData = useCallback((isSuperAdmin) => {
		getListItem(
			"dashboard/dashboard-info",
			"Dashboard Info",
			{
				start_date: startOfDate,
				end_date: endOfDate,
			},
			isSuperAdmin,
		).then((response) => {			
			const { cache_end_time, ...exResponse } = response;
			if (!isSuperAdmin) {
				let mapResponse = Object.keys(exResponse)
					.map((item) => ({
						[`${item}`]: {
							[`${keyPrefix.total}${mapKeysTotal[item]}`]:
								response[item][
									`${keyPrefix.total}${mapKeysTotal[item]}`
								],
							[`${keyPrefix.total}${mapKeysTotal["finish"]}`]:
								response[item][
									`${keyPrefix.total}${mapKeysTotal["finish"]}`
								],
							[`${keyPrefix.total}${mapKeysTotal["pendient"]}`]:
								response[item][
									`${keyPrefix.total}${mapKeysTotal["pendient"]}`
								],
							[`${keyPrefix.total_sum}${mapKeysSum[item]}`]:
								response[item][
									`${keyPrefix.total_sum}${mapKeysSum[item]}`
								]
									? calculate(
											response[item][
												`${keyPrefix.total_sum}${mapKeysSum[item]}`
											],
										)
									: 0,
						},
					}))
					.reduce((accumulator, value) => ({ ...accumulator, ...value }), {});
				setDashboardInfo(mapResponse);
			} else {
				const {
					active_companies,
					inactive_companies,
					licence_expired_companies,
					total_companies,
					data,
				} = response;

				setDashboardInfoAdminIndicators({
					active_companies: {
						active_companies_text: "Active Companies",
						active_companies: active_companies,
					},
					inactive_companies: {
						inactive_companies_text: "Inactive Companies",
						inactive_companies: inactive_companies,
					},
					licence_expired_companies: {
						licence_expired_companies_text: "License Expired",
						licence_expired_companies:
							licence_expired_companies,
					},
					total_companies: {
						total_companies_text: "Total Companies",
						total_companies: total_companies,
					},
				});

				const dataMap = data.map((company) => ({
					...company,
					package_name: company?.package?.name,
					date_registration: formatDate2(
						company?.date_registration,
					),
				}));
				setTableData(dataMap);
			}
		});
	}, [isSuperAdmin, startOfDate, endOfDate]);
	
	const validateDates = () => {
		const _start = moment(startOfDate);
		const _end = moment(endOfDate);
		const _errors = {};

		if(!_start.isValid()) {
			_errors.startOfDate = "This field is invalid";
		} else if(_start.isAfter(_end)) {
			_errors.startOfDate = `This field must be less than or equal to ${_end.format('YYYY-MM-DD')}`;
		}

		if(!_end.isValid()) {
			_errors.endOfDate = "This field is invalid";
		} else if(_end.isBefore(_start)) {
			_errors.endOfDate = `This field must be greater than or equal to ${_start.format('YYYY-MM-DD')}`;
		}
		
		const isValid = Object.keys(_errors).length === 0;
		return { isValid, _errors }
	}
	
	useEffect(() => {
		const { isValid, _errors } = validateDates();
		setErrors(_errors);
		if(isValid) {
			let _isSuperAdmin = false;
			const roles = userInfo.hasOwnProperty("roles") ? userInfo.roles : [];
			roles.forEach((rol) => {
				if (superAdminRoles.includes(rol.name)) {
					setIsSuperAdmin(true);
					_isSuperAdmin = true;
				}
			});
			if(!_isSuperAdmin) fetchSales();
			fetchAllData(_isSuperAdmin);
		}
		setTimeout(() => changeNavigationVisible(true), 350);
	}, [startOfDate, endOfDate]);

	const redirectTo = (path) => history.push(path);

  const [loadingSearchReceipt, setLoadingSearchReceipt] = useState(false);
  const [currentReceipt, setCurrentReceipt] = useState({});

	const handleRedirectTo = (row) => {
		const type = String(row.type).toLowerCase();
		switch (type) {
			case "workorder":
					redirectTo(`/work_order_form_update?work_order_id=${row.id}`)
				break;
			case "invoice":
					redirectTo(`/invoice_form_update?invoice_id=${row.id}`)
				break;
			case "estimate":
				redirectTo(`/estimate_form_update?estimate_id=${row.id}`)
				break;
			case "receipt":
				if(!loadingSearchReceipt) {
					setLoadingSearchReceipt(true)
					setCurrentReceipt({});
					Http.get(`/api/pos_receipt/${row.id}`)
						.then(({ data }) => {
							if(data.status) {
								setShowModalReceipt(true);
								setCurrentReceipt(data.data);
							}
						})
						.finally(() => setLoadingSearchReceipt(false));
				}
				break;
			default:
				redirectTo(`/payment_form_update?payment_id=${row.id}`)
			break;
		}
	};
	
	const [showModalReceipt, setShowModalReceipt] = useState(false);

	return (
		<>
			{!isSuperAdmin && (<>
				<Modal className={classes.modal} open={showModalReceipt} onClose={() => {}} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 300 }} disableEnforceFocus>
					<Box><Fade in={showModalReceipt}>
						<ReceiptOptions onDone={() => { setShowModalReceipt(false); setCurrentReceipt() }} receipt={currentReceipt} />
					</Fade></Box>
				</Modal>
				<div className="container-fluid template-dashboard">
					<div className="container-title mb-4">
            <h1 className="container__title mb-0 py-2">
              Dashboard
            </h1>
          </div>
					<Grid container spacing={2} className="justify-content-between mb-2">
						<Grid item xs={5} lg={4}>
							<Box className="d-flex gap-2 flex-column flex-md-row">
								<div className="mb-2 w-100">
									<MaterialUI.Input
										type="date"
										style={{ '--bg-body': '#ffffff' }}
										label="Start Date"
										InputLabelProps={{ shrink: true }}
										state={startOfDate}
										setState={(newValue) => setStartOfDate(newValue)}
										error={errors.startOfDate}
									/>
								</div>
								<div className="w-100">
									<MaterialUI.Input 
										type="date"
										style={{ '--bg-body': '#ffffff' }}
										label="End Date"
										InputLabelProps={{ shrink: true }}
										state={endOfDate}
										setState={(newValue) => setEndOfDate(newValue)}
										error={errors.endOfDate}
									/>
								</div>
							</Box>
						</Grid>
						<Grid item xs={7} lg={7} className="new__dashboard__header__top__right">
							<Box className="section__icons">
								<Box>
									<Button variant="text" style={{ color: '#3E51B5'}} className="h-100" onClick={() => redirectTo('/invoice_form')}>
										<Box className="flex-column justify-content-between align-items-center" >
											<FontAwesomeIcon icon={faChartLine} className="mb-0 mb-lg-2 shortcut__icon" />
											<span className="shortcut__description">Add Sales</span>
										</Box>
									</Button>
									<Button variant="text" style={{ color: '#3E51B5'}} className="h-100" onClick={() => redirectTo('/add_stock_receipt')}>
										<Box className="flex-column justify-content-between align-items-center" >
											<FontAwesomeIcon icon={faShoppingCart} className="mb-0 mb-lg-2 shortcut__icon" />
											<span className="shortcut__description">Add Stock Receipt</span>
										</Box>
									</Button>
									<Button variant="text" style={{ color: '#3E51B5'}} className="h-100" onClick={() => redirectTo('/adjustment_form')}>
										<Box className="flex-column justify-content-between align-items-center" >
											<FontAwesomeIcon icon={faBuilding} className="mb-0 mb-lg-2 shortcut__icon" />
											<span className="shortcut__description">Add Adjustment</span>
										</Box>
									</Button>
								</Box>
								<Box style={{ color: '#3E51B5'}} className="w-100 d-flex gap-1 align-items-center justify-content-center shortcut__count">
									<div className="d-flex gap-2 py-1 px-2 align-items-center" style={{ border: '1px solid #B0B0B0', borderRadius: '.3rem', backgroundColor: '#D9D9D9' }}>
										<FontAwesomeIcon icon={faChartLine} />
										<strong className="counter text-dark">{dashboardInfo.invoices.total_invoices}</strong>
									</div>
									<div className="d-flex gap-2 py-1 px-2 align-items-center" style={{ border: '1px solid #B0B0B0', borderRadius: '.3rem', backgroundColor: '#D9D9D9' }}>
										<FontAwesomeIcon icon={faShoppingCart} />
										<strong className="counter text-dark">{dashboardInfo.stock_receipts.total_stock_receipt}</strong>
									</div>
									<div className="d-flex gap-2 py-1 px-2 align-items-center" style={{ border: '1px solid #B0B0B0', borderRadius: '.3rem', backgroundColor: '#D9D9D9' }}>
										<FontAwesomeIcon icon={faBuilding} />
										<strong className="counter text-dark">{dashboardInfo.adjustmens.total_adjustment}</strong>
									</div>
								</Box>
							</Box>
						</Grid>
					</Grid>
					<Grid container className="mb-3" spacing={2} justifyContent="center">
						<Grid item xs={6} sm={4} lg={2}>
							<div className="card__info"  onClick={() => history.push("/invoice")}>
								<div className="card__icon">
									<FontAwesomeIcon className="text__purple card__icon" icon={faDollarSign} />
								</div>
								<div className="card__value">
									<h6 className="text__purple mb-0 fs-semi-small">$ {dashboardInfo.invoices.total_sum_invoices}</h6>
									<strong className="text-muted fs-small">Sales</strong>
								</div>
							</div>
						</Grid>
						<Grid item xs={6} sm={4} lg={2}>
							<div className="card__info"  onClick={() => history.push("/invoice?payment")}>
								<div className="card__icon">
									<FontAwesomeIcon className="text__purple card__icon" icon={faReceipt} />
								</div>
								<div className="card__value">
									<h6 className="text__purple mb-0 fs-semi-small">$ {dashboardInfo.invoices.total_sum_invoices}</h6>
									<strong className="text-muted fs-small">Payment Received</strong>
								</div>
							</div>
						</Grid>
						<Grid item xs={6} sm={4} lg={2}>
							<div className="card__info" onClick={() => history.push("/invoice?workorders")}>
								<div className="card__icon">
									<FontAwesomeIcon className="text__purple card__icon" icon={faShoppingCart} />
								</div>
								<div className="card__value">
									<h6 className="text__purple mb-0 fs-semi-small">$ {dashboardInfo.workorders.total_sum_workorders || "0.00"}</h6>
									<strong className="text-muted fs-small">WorkOrders</strong>
								</div>
							</div>
						</Grid>
						<Grid item xs={6} sm={4} lg={2}>
							<div className="card__info" onClick={() => history.push("/adjustment")}>
								<div className="card__icon">
									<FontAwesomeIcon className="text__purple card__icon" icon={faAdjust} />
								</div>
								<div className="card__value">
									<h6 className="text__purple mb-0 fs-semi-small">$ {dashboardInfo.adjustmens.total_sum_adjustments}</h6>
									<strong className="text-muted fs-small">Adjustments</strong>
								</div>
							</div>
						</Grid>
						<Grid item xs={6} sm={4} lg={2}>
							<div className="card__info" onClick={() => history.push("/expensive")}>
								<div className="card__icon">
									<FontAwesomeIcon className="text__purple card__icon" icon={faMoneyBillAlt} />
								</div>
								<div className="card__value">
									<h6 className="text__purple mb-0 fs-semi-small">$ {dashboardInfo.expense.total_sum_expense}</h6>
									<strong className="text-muted fs-small">Expenses</strong>
								</div>
							</div>
						</Grid>
						<Grid item xs={6} sm={4} lg={2}>
							<div className="card__info" onClick={() => history.push("/stock_receipt")}>
								<div className="card__icon">
									<FontAwesomeIcon className="text__purple card__icon" icon={faClipboardCheck} />
								</div>
								<div className="card__value">
									<h6 className="text__purple mb-0 fs-semi-small">$ {dashboardInfo.stock_receipts.total_sum_stock_receipt}</h6>
									<strong className="text-muted fs-small">Stock Receipt</strong>
								</div>
							</div>
						</Grid>
					</Grid>
					{
						!isMobile ? (
							<Grid container className="mb-3" spacing={2}>
								<Grid item xs={12} md={6}>
									<Paper>
										<EnhancedTableToolbar title={`Recent Sales`} handleClick={() => redirectTo('/invoice')}/>
										<TableContainer className={classes.container}>
											<Table stickyHeader className={classes.table} aria-label="simple table" size="small">
												<TableHead>
													<TableRow style={{ fontSize: '14px' }}>
														{rowHeadersRecentSales.map((row, index) => <TableCell key={index} className="fs-small" align="center">{row}</TableCell>)}
													</TableRow>
												</TableHead>
												<TableBody>
													{rowsRecentSales.map((row, index) => (
														<TableRow key={index} style={{ fontSize: '14px' }}>
															<TableCell className="px-1 fs-small" align="center" component="th" scope="row" style={{ inlineSize: '50px' }}>{row.id}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.date}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.type}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.client}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.dueDate}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.balance}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.total}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.status}</TableCell>
															<TableCell align="center" style={{ inlineSize: '50px' }}>
																{row.type === 'Receipt' ? (
																	<MaterialUI.Button.Primary className="mw-auto" tooltip="View" style={{ inlineSize: '40px', blockSize: '27px' }} onClick={() => handleRedirectTo(row)} disabled={loadingSearchReceipt}>
																		<i className="fas fa-eye"></i>
																	</MaterialUI.Button.Primary>
																) : (
																	<MaterialUI.Button.Danger className="mw-auto" tooltip="Edit" style={{ inlineSize: '40px', blockSize: '27px' }} onClick={() => handleRedirectTo(row)}>
																		<EditRoundedIcon fontSize="small"/>
																	</MaterialUI.Button.Danger>
																)}
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</Paper>
								</Grid>
								<Grid item xs={12} md={6}>
									<Paper>
										<EnhancedTableToolbar title={'Recent Stock Receipt'} handleClick={() => redirectTo('/stock_receipt')}/>
										<TableContainer className={classes.container}>
											<Table stickyHeader className={classes.table} aria-label="simple table" size="small">
												<TableHead>
													<TableRow style={{ fontSize: '14px' }}>
														{rowHeadersRecentStockReceipt.map((row, index) => <TableCell key={index} className="fs-small" align="center">{row}</TableCell>)}
													</TableRow>
												</TableHead>
												<TableBody>
													{rowsRecentStockReceipt.map((row) => (
														<TableRow key={row.billNumber} style={{ fontSize: '14px' }}>
															<TableCell className="px-1 fs-small" align="center" component="th" scope="row">{row.billNumber}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.dateDue}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.dateReceipt}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.description}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.status}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.type}</TableCell>
															<TableCell className="px-1 fs-small" align="center">{row.total}</TableCell>
															<TableCell align="center" style={{ inlineSize: '50px' }}>
																<MaterialUI.Button.Danger className="mw-auto" size="small" onClick={() => history.push({
																	pathname: '/edit_stock_receipt',
																	state: { data: row }
																})}><EditRoundedIcon fontSize="small"/></MaterialUI.Button.Danger>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</Paper>
								</Grid>
							</Grid>
						) : (
							<Grid container className="mb-3" spacing={2}>
								<Grid item xs={12}>
									<Box className="d-flex justify-content-between align-items-center mb-1">
										<h5 className="mb-0 fw-bold fs-semi-small">Recent Sales</h5>
										<span className="fs-small" onClick={() => redirectTo('/invoice')}>View More</span>
									</Box>
									<Box className="table__mobile">
										{rowsRecentSales.map((row, index) => (
											<Box className="table__mobile__row" key={index}>
												<div className="fs-small">
													<div className="d-flex align-items-center"><strong>ID: </strong><strong className="ps-1">{row.id}</strong></div>
													<div className="d-flex align-items-center"><span>Date: </span><span className="ps-1">{row.date}</span></div>
													<div className="d-flex align-items-center"><span>Balance: </span><span className="ps-1">{row.balance}</span></div>
													<div className="d-flex align-items-center"><span>Type: </span><span className="ps-1">{row.type}</span></div>
												</div>
												<div className="text-end fs-small">
													<div className="d-flex align-items-center justify-content-end"><strong>Client: </strong><strong className="ps-1">{row.client}</strong></div>
													<div className="d-flex align-items-center justify-content-end"><span>Due Date: </span><span className="ps-1">{row.dueDate}</span></div>
													<div className="d-flex align-items-center justify-content-end"><span>Total: </span><span className="ps-1">{row.total}</span></div>
													<div className="d-flex align-items-center justify-content-end"><span>Status: </span><span className="ps-1 text-primary">{row.status}</span></div>
												</div>
											</Box>
										))}
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box className="d-flex justify-content-between align-items-center">
										<h5 className="mb-0 fw-bold fs-semi-small">Recent Stock Receipt</h5>
										<span className="fs-small">View More</span>
									</Box>
									<Box className="table__mobile">
										{rowsRecentStockReceipt.map((row) => (
											<Box className="table__mobile__row" key={row.billNumber}>
												<div className="fs-small">
													<div className="d-flex align-items-center"><strong>Description: </strong><strong className="ps-1">{row.id}</strong></div>
													<div className="d-flex align-items-center"><span>Date due: </span><span className="ps-1">{row.dateDue}</span></div>
													<div className="d-flex align-items-center"><span>Status: </span><span className="ps-1">{row.status}</span></div>
													<div className="d-flex align-items-center"><strong>Total: </strong><strong className="ps-1">{row.total}</strong></div>
												</div>
												<div className="text-end fs-small">
													<div className="d-flex align-items-center justify-content-end"><strong>Bill Number: </strong><strong className="ps-1">{row.billNumber}</strong></div>
													<div className="d-flex align-items-center justify-content-end"><span>Date Receipt: </span><span className="ps-1">{row.dateReceipt}</span></div>
													<div className="d-flex align-items-center justify-content-end"><span>Type: </span><span className="ps-1">{row.total}</span></div>
												</div>
											</Box>
										))}
									</Box>
								</Grid>
							</Grid>
						)
					}
				</div>
			</>
			)}
			
			{isSuperAdmin && (
				<>
					<div className="contenedor container-fluid template-dashboard">
						<div className="info-labels info-labels-admin mb-3">
							{Object.keys(dashboardInfoAdminIndicators).map(
								(infoItem, index) => (
									<button
										className="card admin-card"
										style={{ width: "100%" }}
										key={index}
									>
										<div className="card-body admin-card-body">
											<p>
												{dashboardInfoAdminIndicators[infoItem][`${infoItem}_text`]}
												<br />
											</p>
											<span>
												{
													dashboardInfoAdminIndicators[
														infoItem
													][infoItem]
												}
											</span>
										</div>
									</button>
								),
							)}
						</div>

						<Grid container className="mb-3" spacing={2}>
							<Grid item xs={12}>
								<Paper>
									<EnhancedTableToolbar title={`Recent Registered Companies`}/>
									<TableContainer className={classes.container}>
										<Table stickyHeader className={classes.table} aria-label="simple table" size="small">
											<TableHead>
												<TableRow>
													{rowHeadersRecentRegisteredCompanies.map((row, index) => (
														<TableCell key={index} className="fs-small" align="center">{row}</TableCell>
													))}
												</TableRow>
											</TableHead>
											<TableBody>
												{tableData.map((row, index) => (
													<TableRow key={index}>
														<TableCell className="px-1 fs-small" align="center" component="th" scope="row" style={{ inlineSize: '60px' }}>{row.id}</TableCell>
														<TableCell className="px-1 fs-small text-capitalize" align="left">{row.name}</TableCell>
														<TableCell className="px-1 fs-small" align="center">{row.contact_email || '-'}</TableCell>
														<TableCell className="px-1 fs-small" align="center">{row.package_name}</TableCell>
														<TableCell className="px-1 fs-small" align="center">{row.date_registration}</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Paper>
							</Grid>
						</Grid>
					</div>
					<CustomModal
						show={isLoading}
						customTittleText={""}
						customMessageModal={""}
						onHandleClose={() => {}}
						isLoading={isLoading}
					/>
				</>
			)}
		</>
	);
}

export { Dashboard };
