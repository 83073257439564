export const appLocalStorage = {
	getValue(key) {
		if (key) {
			const VALUE = window.localStorage.getItem(key);
			if (VALUE !== null && VALUE !== "") {
				try {
					return JSON.parse(VALUE);
				} catch (e) {
					return VALUE;
				}
			}
		}
		return "";
	},
	removeItem(key) {
		if (key) {
			window.localStorage.removeItem(key);
		}
	},
	setValue(key, value) {
		if (key && value) {
			window.localStorage.setItem(key, JSON.stringify(value));
		}
	},
};
