import { useState } from "react";
import Service from '../services/CashCounterService';
import { LaravelErrorsToObject } from '../utils/errors.laravel'

const initState = {}

initState.cashCounterForm = {
  amount: "",
  note: "",
}

function useCashCounter() {
	const [isLoading, setIsLoading] = useState(false);
	const [cashCounters, setCashCounters] = useState([]);
	const [cashCounterForm, setCashCounterForm] = useState(initState.cashCounterForm);
	const [cashCounter, setCashCounter] = useState({});
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [counterIsOpen, setCounterIsOpen] = useState(false);
	const [errors, setErrors] = useState({});

  const onChangeCashCounter = (prop) => (value) => {
    setErrors({});
		setCashCounterForm((prev) => ({ ...prev, [prop]: value }));
	};
  
	const fetchAllCashCounter = async (params) => {
		try {
      setIsLoading(true);
      const data = await Service.fetchAll({
        ...params,
        per_page: 15,
        page,
      });
      if(data.status) {
        setPagination(data.pagination);
        setCashCounters(data.data);
      }
      return data;
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
	};

  const lastCashCounter = async () => {
		try {
      setIsLoading(true);
      const data = await Service.lastRecord();
      return data;
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
	};

  const storeCashCounter = async (payload) => {
    try {
      setErrors({});
      const resp = await Service.create({
        opening_amount: payload.amount,
        opening_note: payload.note,
      });
      if(!resp.status) {
        setErrors(LaravelErrorsToObject(resp.msg));
      }
      return resp;
    } catch (error) {}
  }

  const closeCashCounter = async (payload) => {
    try {
      setErrors({});
      const resp = await Service.close({
        close_amount: payload.amount,
        close_note: payload.note,
      });
      if(!resp.status) {
        setErrors(LaravelErrorsToObject(resp.msg));
      }
      return resp;
    } catch (error) {}
  }

  const updateCashCounter = async (payload) => {
    try {
      const resp = await Service.create({
        opening_amount: payload.amount,
        opening_note: payload.note,
        close_amount: payload.amount,
        close_note: payload.note,
      });
      return resp;
    } catch (error) {}
  }

  const destroyCashCounter = async (cash_counter_id) => {
    try {
      const resp = await Service.destroy({ cash_counter_id });
      return resp;
    } catch (error) {}
  }

  const cashCounterLastOpen = async () => {
    try {
      setIsLoading(true);
      setCounterIsOpen(false);
      const { data, status } = await Service.lastCreate();
      if(!status) return;
      const isOpen = getCashCounterIsOpen(data);
      setCashCounter(data);
      setCounterIsOpen(isOpen);
      return data;
    } catch (error) {}
    finally {
      setIsLoading(false);
    }
  }

  const restoreState = () => {
    setCashCounter({});
    setCounterIsOpen(false);
  }

  const getCashCounterIsOpen = (record) => {
    const Auth = JSON.parse(localStorage.user_data);
    return Auth?.warehouse?.id == record?.warehouse?.id &&
      Auth?.id == record?.opening_user?.id &&
      !record.date_close;
  }

  const findById = async (cashCounterId) => {
    try {
      const resp = await Service.findOne(cashCounterId);
      return resp;
    } catch (error) {}
  }
  
	return {
    isLoading,
		setIsLoading,
    cashCounters,
    setCashCounters,
    cashCounter,
    setCashCounter,
    getCashCounterIsOpen,
    
    fetchAllCashCounter,
    destroyCashCounter,
    storeCashCounter,
    closeCashCounter,
    updateCashCounter,
    cashCounterLastOpen,
    lastCashCounter,
    findById,
    
    page,
    setPage,
    pagination,
    setPagination,
    setCounterIsOpen,

    restoreState,
    counterIsOpen,

    errors,
    setErrors,
    onChangeCashCounter,
    cashCounterForm,
    setCashCounterForm,
	};
}

export { useCashCounter, initState };
