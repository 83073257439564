import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

// components
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { CustomDataList } from "../../components/CustomDataList/CustomDataList";
import { CustomDataListItem } from "../../components/CustomDataListItem/CustomDataListItem";
import { PositionedMenu } from "../../components/CustomMenu/CustomMenu";
import MenuItem from "@material-ui/core/MenuItem";
import no_image from "../../assets/img/no_image.png";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import "./InvoiceForm.css";

// custom Hooks
import { useModalHook } from "../../customHooks/useModalHook";
import { useApiCustomRequest } from "../../customHooks/useApiCustomRequest";
import { useMenuActionHook } from "../../customHooks/useMenuActionHook";
// form configuration
import {
	typesAndValues,
	typesAndValuesUpdateDetailInvoice,
	typesAndValuesMessageOnInvoice,
	initData as initDataValues,
	initDataMessageOnInvoice as initDataMessageOnInvoiceValues,
	initDataBillingAddress as initDataBillingAddressValues,
	typesAndValuesPartNumber as typesAndValuesPartNumberValues,
	initDataPartNumber as initDataPartNumberValues,
	PanelNames
} from "./initFormEstimate";

import {
	styles,
	tableHeadersInvoiceDetail as tableHeaders,
	dataFieldsInvoiceDetail as dataFields,
	formatDate,
	blockInvalidChar,
} from "../../context/constants";
import PDFModal from "../../components/ModalPdf";
import { ModalAddItem } from "../../components/modal_item_add";
import { useApirequestStockReceipt } from "../../customHooks/useApirequestStockReceipt";
import { useApiRequestItems } from "../../customHooks/useApiRequestItems";
import Panel from "../../components/Panel";
import { CostumerForm } from "../costumer/CostumerForm";
import MaterialUI from "../../components/MaterialUI";
import { AppContext } from "../../context/AppContext";

var _ = require("lodash");

const convertValue = (str) => {
	return str.replace(",", "");
};

const calculate = (str) => {
	return parseFloat(convertValue(str)).toFixed(2);
};

function EstimateFormUpdate(props) {
	const location = useLocation();
	const history = useHistory();
  const { changeNavigationVisible } = React.useContext(AppContext);
	React.useEffect(() => {
    setTimeout(() => {
      changeNavigationVisible(true);
    }, 250);
  }, []);

	const [goToMainPage, setGoToMainPage] = React.useState(false);

	const { anchorEl, setAnchorEl, onCloseModal, currentId, setCurrentId } =
		useMenuActionHook();

	const {
		setCustomMessageModal,
		showSuccessButton,
		setShowSuccessButton,
		showModal,
		setShowModal,
		customTittleText,
		setCustomTittleText,
		customMessageModal,
		manageResponseErrors,
		customCurrentItem,
		setCustomCurrentItem,
		manageResponse,
		manageSuccessfullMesssage,
		manageDeleteMessage,
	} = useModalHook();

	const {
		setIsLoading:stock_setIsLoading,
		stock_receipt_details,
		setSincronizedItems: setSincronizedItemsList,
		receipt_stock_number,
		quantity,
		cost,
		location_id,
		location_name,
		setPaymentNumber,
		setPartNumberId,
		part_number_name,
		setPartNumberName,
		skuNumber,
	} = useApirequestStockReceipt(
		setCustomTittleText,
		setCustomMessageModal,
		showSuccessButton,
		setShowModal,
	);

	const {
		isLoading,
		setIsLoading,
		getItem,
		updateItem,
		updateModel,
		deleteItemByUrl,
		createItemDetail,
	} = useApiCustomRequest(
		manageResponseErrors,
		"estimates",
		"Estimate",
		manageResponse,
		manageSuccessfullMesssage,
		true,
		setGoToMainPage,
	);

	const {
		filteredData: item_list,
		setSincronizedItems,
		getSkuItemInformation,
	} = useApiRequestItems(
		stock_setIsLoading,
		setPaymentNumber,
		setPartNumberId,
		setPartNumberName,
		setPartNumberId,
		setPartNumberName,
	);

	const [modal_add_conf] = React.useState({ title: "Add new Item", message: "" });

	const onHandleResponseModal = () => {
		quitNewStylesInModal();
		setShowModal(false);
	};

	const onHandleDelete = async () => {
		quitNewStylesInModal();
		setShowModal(false);
		await deleteItemByUrl(customCurrentItem, "invoice-details", true);
		setDoRequest(true);
		setCustomCurrentItem(false);
		setSincronizeData(false);
	};

	const [formAmountValues, setFormAmountValues] = React.useState({
		discount_percentage: 0.0,
		deposit: 0.0,
		discount_total: 0.0,
		balance: 0.0,
		total: 0.0,
	});
	const [typesAndValuesFields, setTypesAndValuesFields] = React.useState([]);
	const [typesAndValuesPartNumber, setTypesAndValuesPartNumber] = React.useState([]);
	const [initData, setInitData] = React.useState({});
	const [initDataMessageOnInvoice, setInitDataMessageOnInvoice] = React.useState({});
	const [initDataBillingAddress, setInitDataBillingAddress] = React.useState({});
	const [initDataPartNumber, setInitDataPartNumber] = React.useState({});
	const [invoiceItems, setInvoiceItems] = React.useState([]);
	const [sincronizeData, setSincronizeData] = React.useState(false);
	const [changeInForm, setChangeInForm] = React.useState(false);
	const [actionsFooterStyle, setActionsFooterStyle] = React.useState(false);
	const [cancelText, setCancelText] = React.useState(false);
	const [successText, setSuccessText] = React.useState(false);
	const [invoiceId, setInvoiceId] = React.useState(null);

	useEffect(async () => {
		let fieldsAndValuesCopy = [...typesAndValues];
		let initDataValuesCopy = { ...initDataValues };
		let initDataMessageOnInvoiceValuesCopy = { ...initDataMessageOnInvoiceValues };
		let typesAndValuesPartNumberValuesCopy = [ ...typesAndValuesPartNumberValues ];

		if (location?.search?.split("=")[1]) {
			const infoItem = await getItem(
				location?.search?.split("=")[1],
				{},
				true,
				true,
			);

			if (infoItem.hasOwnProperty("error") && infoItem.error) {
				history.push("/404");
			}

			const {
				client_id,
				client_send_email,
				date_receipt,
				date_due,
				message,
				discount_percentage,
				discount_total,
				deposit,
				Balance,
				total,
				details,
			} = infoItem;

			if (infoItem.hasOwnProperty("invoice_id_reference")) {
				const { invoice_id_reference } = infoItem;
				setInvoiceId(invoice_id_reference);
			}

			const {
				name,
				city,
				state,
				postal_code,
				country,
				address_1,
				address_2,
			} = infoItem.client;
			setTimeout(() => {
				const initDataBillingAddressCopy = {
					...initDataBillingAddressValues,
				};
				const newMessage = `${name},${address_1},${address_2},${city} ${state} ${postal_code},${country}`;
				initDataBillingAddressCopy.comments = newMessage;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
			}, 0);

			const idFieldIndex = fieldsAndValuesCopy.findIndex(
				(item) => item.field_name === "client_id",
			);
			let fieldId = fieldsAndValuesCopy[idFieldIndex];
			fieldId.inputValue = `${name}`;
			fieldId.doRequest = false;
			fieldId.dontInitData = true;
			//

			initDataValuesCopy = {
				...initDataValuesCopy,
				client_id: client_id || "",
				client_send_email: client_send_email || "",
				date_receipt: formatDate(
					`${date_receipt.split("/")[2]}-${
						date_receipt.split("/")[1]
					}-${date_receipt.split("/")[0]}`,
				),
				due_date: formatDate(
					`${date_due.split("/")[2]}-${date_due.split("/")[1]}-${
						date_due.split("/")[0]
					}`,
				),
			};

			initDataMessageOnInvoiceValuesCopy = {
				...initDataMessageOnInvoiceValuesCopy,
				message: message || "",
			};

			setFormAmountValues({
				discount_percentage: parseFloat(discount_percentage).toFixed(2),
				discount_total: parseFloat(discount_total).toFixed(2),
				deposit: calculate(deposit.split(" ")[1]),
				balance: calculate(Balance.split(" ")[1]),
				total: calculate(total.split(" ")[1]),
			});

			const detailsMap = details.map((item) => ({
				id: item.id,
				part_number: item.item.sku,
				item_id: item.item.id,
				name: item.item.name,
				description: item.comment,
				quantity: item.quantity,
				rate: item.price,
				amount: item.quantity * item.price,
			}));

			setInvoiceItems([...detailsMap]);

			//get customer info from redirect
			if (
				location.hasOwnProperty("data") &&
				location?.data.hasOwnProperty("customerData") &&
				Object.keys(location.data.customerData).length > 0
			) {
				const {
					id,
					name,
					address_1,
					address_2,
					city,
					state,
					postal_code,
					country,
					email,
				} = location.data.customerData;

				initDataValuesCopy = {
					...initDataValuesCopy,
					client_id: id,
					client_send_email: email,
				};

				const idFieldIndex = fieldsAndValuesCopy.findIndex(
					(item) => item.field_name === "client_id",
				);
				let fieldId = fieldsAndValuesCopy[idFieldIndex];
				fieldId.inputValue = `${name}`;
				fieldId.doRequest = false;
				fieldId.dontInitData = true;

				setTimeout(() => {
					const initDataBillingAddressCopy = {
						...initDataBillingAddressValues,
					};
					const newMessage = `${name},${address_1},${address_2},${city} ${state} ${postal_code},${country}`;
					initDataBillingAddressCopy.comments = newMessage;
					setInitDataBillingAddress({
						...initDataBillingAddressCopy,
					});
				}, 0);
			}

			typesAndValuesPartNumberValuesCopy[
				typesAndValuesPartNumberValuesCopy.findIndex(
					(item) => item.field_name === "item_id",
				)
			].regexPattern = /^$|^\d+$/;
		}

		setInitData({ ...initDataValuesCopy });
		setTypesAndValuesFields(fieldsAndValuesCopy);
		setInitDataMessageOnInvoice({ ...initDataMessageOnInvoiceValuesCopy });
		setTypesAndValuesPartNumber([...typesAndValuesPartNumberValuesCopy]);
		setSincronizeData(true);
	}, [location?.search?.split("=")[1]]);

	const [doRequest, setDoRequest] = React.useState(false);
	useEffect(async () => {
		if (location?.search?.split("=")[1] && doRequest && !sincronizeData) {
			const infoItem = await getItem(
				location?.search?.split("=")[1],
				{},
				true,
			);

			const {
				details,
				discount_percentage,
				discount_total,
				deposit,
				Balance,
				total,
			} = infoItem;

			let recalculateFormValues = {
				...formAmountValues,
				discount_total: parseFloat(discount_total).toFixed(2),
				balance: calculate(Balance.split(" ")[1]),
				total: calculate(total.split(" ")[1]),
			};

			recalculateAfterChange(recalculateFormValues);

			const detailsMap = details.map((item) => ({
				id: item.id,
				part_number: item.item.sku,
				item_id: item.item.id,
				name: item.item.name,
				description: item.comment,
				quantity: item.quantity,
				rate: item.price,
				amount: item.quantity * item.price,
			}));

			setInvoiceItems([...detailsMap]);
			setSincronizeData(true);
		}
	}, [sincronizeData]);

	const onChangeFormAmountValues = (ev) => {
		let regex = /^[0-9]*\.?[0-9]*$/;

		if (!regex.test(ev.target.value)) {
			return;
		}

		if (
			Number(ev.target.value) > 200 &&
			ev.target.name === "discount_percentage"
		) {
			return;
		}

		const { discount_percentage, deposit, discount_total, balance, total } =
			formAmountValues;

		let newData = {
			...formAmountValues,
		};

		let currentDiscountPercentage = discount_percentage;

		if (ev.target.name === "discount_percentage") {
			currentDiscountPercentage = calculate(ev.target.value);
			if (parseFloat(total) > 0 && parseFloat(ev.target.value) > 0) {
				newData.discount_total = (
					parseFloat(total / 100) * parseFloat(ev.target.value)
				).toFixed(2);
			}
			if (!parseFloat(total) > 0 || !parseFloat(ev.target.value) > 0) {
				newData.discount_total = parseFloat("0.00").toFixed(2);
			}
		}

		let currentDeposit = deposit;
		if (ev.target.name === "deposit") {
			currentDeposit = calculate(ev.target.value);
		}

		if (
			parseFloat(currentDiscountPercentage) &&
			parseFloat(currentDeposit)
		) {
			const calc1 = parseFloat(currentDiscountPercentage * total).toFixed(
				2,
			);
			const calc2 = parseFloat(calc1 / 100).toFixed(2);
			const calc3 = parseFloat(calc2) + parseFloat(currentDeposit);
			newData.balance = total - calc3;
		}

		if (
			parseFloat(currentDiscountPercentage) &&
			!parseFloat(currentDeposit)
		) {
			const calc1 = parseFloat(currentDiscountPercentage * total).toFixed(
				2,
			);
			const calc2 = parseFloat(calc1 / 100).toFixed(2);
			const calc3 = parseFloat(calc2).toFixed(2);
			newData.balance = total - calc3;
		}

		if (
			!parseFloat(currentDiscountPercentage) &&
			parseFloat(currentDeposit)
		) {
			const calc3 = parseFloat(currentDeposit).toFixed(2);
			newData.balance = total - calc3;
		}

		if (
			!parseFloat(currentDiscountPercentage) &&
			!parseFloat(currentDeposit)
		) {
			newData.balance = total;
		}

		newData = {
			...newData,
			balance: Number(newData.balance).toFixed(2),
		};

		setFormAmountValues({
			...newData,
			[ev.target.name]: ev.target.value,
		});
	};

	const recalculateAfterChange = (values) => {
		let newData = {
			...formAmountValues,
		};

		const { discount_percentage, discount_total, deposit, balance, total } =
			values;

		if (parseFloat(total) > 0 && parseFloat(discount_percentage) > 0) {
			newData.discount_total = (
				parseFloat(total / 100) * parseFloat(discount_percentage)
			).toFixed(2);
		}

		if (parseFloat(discount_percentage) && parseFloat(deposit)) {
			const calc1 = parseFloat(discount_percentage * total).toFixed(2);
			const calc2 = parseFloat(calc1 / 100).toFixed(2);
			const calc3 = parseFloat(calc2) + parseFloat(deposit);
			newData.balance = total - calc3;
		}

		if (parseFloat(discount_percentage) && !parseFloat(deposit)) {
			const calc1 = parseFloat(discount_percentage * total).toFixed(2);
			const calc2 = parseFloat(calc1 / 100).toFixed(2);
			const calc3 = parseFloat(calc2).toFixed(2);
			newData.balance = total - calc3;
		}

		if (!parseFloat(discount_percentage) && parseFloat(deposit)) {
			const calc3 = parseFloat(deposit).toFixed(2);
			newData.balance = total - calc3;
		}

		if (!parseFloat(discount_percentage) && !parseFloat(deposit)) {
			newData.balance = total;
		}

		newData = {
			...newData,
			balance: Number(newData.balance).toFixed(2),
			total: Number(total).toFixed(2),
		};

		setFormAmountValues(newData);
	};

	const onChangeFieldsInitDataMessageOnInvoice = async (event) => {
		setInitDataMessageOnInvoice({ ...event });
	};

	const onChangeFieldsInitDatainitDataBillingAddress = async (event) => {
		setInitDataBillingAddress({ ...event });
	};

	useEffect(() => {
		const initDataBillingAddressCopy = { ...initDataBillingAddressValues };
		setInitDataBillingAddress({ ...initDataBillingAddressCopy });
	}, []);

	const onChangeSearchInputCustomer = (ev) => {
		let initDataCopy = { ...initData };
		let fieldsAndValuesCopy = [...typesAndValuesFields];

		setInitDataBillingAddress({});
		const initDataBillingAddressCopy = { ...initDataBillingAddressValues };
		if (ev) {
			setTimeout(() => {
				const {
					name,
					address_1,
					address_2,
					city,
					state,
					postal_code,
					country,
					email,
					id,
				} = ev;

				initDataCopy = {
					...initDataCopy,
					client_send_email: email,
					client_id: id,
				};

				const idFieldIndex = fieldsAndValuesCopy.findIndex(
					(item) => item.field_name === "client_id",
				);
				let fieldId = fieldsAndValuesCopy[idFieldIndex];
				fieldId.inputValue = ``;
				fieldId.doRequest = false;
				fieldId.dontInitData = true;
				fieldId.inputValue = `${name}`;

				const newMessage = `${name},${address_1},${address_2},${city} ${state} ${postal_code},${country}`;
				initDataBillingAddressCopy.comments = newMessage;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
				setInitData({ ...initDataCopy });
				setTypesAndValuesFields([...fieldsAndValuesCopy]);
			}, 0);
		}

		if (!ev) {
			setTimeout(() => {
				initDataBillingAddressCopy.comments =
					initDataBillingAddressValues.comments;
				setInitDataBillingAddress({ ...initDataBillingAddressCopy });
			}, 1);
		}
	};

	const onChangeFieldsInitDataPartNumber = async (event) => {
		setInitDataPartNumber({ ...event });
	};

	useEffect(() => {
		const initDataPartNumberValuesCopy = { ...initDataPartNumberValues };
		setInitDataPartNumber({ ...initDataPartNumberValuesCopy });
	}, []);

	const onChangeFieldsInitData = async (event) => {
		setInitData({ ...event });
	};

	const validateLength = (array) => {
		return array.length > 0;
	};

	const childFunc = useRef(null);
	const childFunc2 = useRef(null);
	const childFunc3 = useRef(null);
	const onHandleSubmit = async (event) => {
		if (isLoading) return;

		if (event === "Add" && !initDataPartNumber.item_id) {
			return;
		}

		const initDataPartNumberCopy = { ...initDataPartNumber };
		const errorsOne = childFunc.current(initData);
		const errorsTwo = childFunc2.current(initDataMessageOnInvoice);
		const errorsThree = childFunc3.current(initDataPartNumber);

		if (
			!validateLength(errorsOne) &&
			!validateLength(errorsTwo) &&
			!validateLength(errorsThree)
		) {
			let formAmountValuesCopy = {
				discount_percentage:
					formAmountValues.discount_percentage || 0.0,
				deposit: formAmountValues.deposit || 0.0,
				discount_total: formAmountValues.discount_total || 0.0,
				balance: formAmountValues.balance || 0.0,
				total: formAmountValues.total || 0.0,
			};

			let bodyRequest = {
				id: location?.search?.split("=")[1],
				...initData,
				...initDataMessageOnInvoice,
				...formAmountValuesCopy,
				type: "Estimate",
			};

			let finalRequest = {
				...bodyRequest,
			};

			if (initDataPartNumber.item_id) {
				finalRequest = {
					...finalRequest,
					...initDataPartNumber,
				};
			}

			if (event === "saveAndSend") {
				finalRequest = {
					...finalRequest,
					save_and_send: 1,
				};
			}

			let response = await updateItem(
				location?.search?.split("=")[1],
				finalRequest,
				"Estimate",
				{},
				true,
				true,
			);

			if (response && response.hasOwnProperty("id")) {
				setTimeout(() => {
					let typesAndValuesPartNumberValuesCopy = _.cloneDeep(
						typesAndValuesPartNumberValues,
					);
					let item =
						typesAndValuesPartNumberValuesCopy[
							typesAndValuesPartNumberValuesCopy.findIndex(
								(item) => item.field_name === "item_id",
							)
						];

					item.regexPattern = /^$|^\d+$/;
					item.inputValue = "";
					item.doRequest = false;
					item.dontInitData = true;
					typesAndValuesPartNumberValuesCopy[
						typesAndValuesPartNumberValuesCopy.findIndex(
							(item) => item.field_name === "item_id",
						)
					] = item;

					setTypesAndValuesPartNumber([]);
					setTypesAndValuesPartNumber(
						typesAndValuesPartNumberValuesCopy,
					);

					initDataPartNumberCopy.item_id = "";
					setInitDataPartNumber({ ...initDataPartNumberCopy });
				}, 0);

				setDoRequest(true);
				setSincronizeData(false);
			}
		}
	};

	//redirect module
	const buttonClick = (field_id, typeSave = "Add") => {
		// /costumer_form
		if (field_id === "createCustomer") {
			history.push({
				pathname: `/costumer_form`,
				data: {
					redirectTo: `/estimate_form_update`,
					invoice_id: location?.search?.split("=")[1],
				},
			});
		}
		if (field_id === "onSubmit") {
			onHandleSubmit(typeSave);
		}
		if (field_id === "Save") {
			onHandleSubmit(typeSave);
		}
	};

	const onDelete = (event) => {
		setNewStylesInModal();
		setCustomCurrentItem(event.id);
		manageDeleteMessage("Invoice Detail");
		setCustomMessageModal(
			`Are you sure you want to delete the item ${event.part_number} ?`,
		);
		setShowModal(true);
	};

	const onEdit = () => {};

	const actionsDefinition = [
		{
			name: "Edit",
			func: onEdit,
		},
		{
			name: "Delete",
			func: onDelete,
		},
	];

	//adjustement detail implementation
	const onMenuActions = async (event, element) => {};

	const [show, setShow] = React.useState(false);
	const prepareDetailToUpdate = (index) => {
		const item = invoiceItems.findIndex(
			(itemData) => itemData.part_number == index,
		);
		setCurrentId(item);
		setShow(true);
	};

	const updateInvoiceDetail = async (ev) => {
		setShow(false);

		let mapRequest = {
			item_id: ev.item_id,
			price: ev.rate,
			quantity: ev.quantity,
			comment: ev.description,
		};

		let response = await updateModel(
			ev.id,
			mapRequest,
			"Invoice Detail",
			{},
			"invoice-details",
			true,
		);

		if (response && response.hasOwnProperty("id")) {
			setDoRequest(true);
			setSincronizeData(false);
		}
	};

	const setNewStylesInModal = () => {
		setActionsFooterStyle(true);
		setCancelText("no");
		setSuccessText("yes");
	};

	const quitNewStylesInModal = () => {
		setActionsFooterStyle(false);
		setCancelText("");
		setSuccessText("");
	};

	const cancelInvoice = () => {
		setNewStylesInModal();
		if (changeInForm) {
			setShowModal(true);
			setCustomTittleText(`Leave estimate creating`);
			setCustomMessageModal(`Do you want to leave without saving?`);
			setShowSuccessButton(true);
		} else {
			history.push("/invoice");
		}
	};

	//generador de pdf
	const [PDFModalIsOpen, setPDFModalIsOpen] = useState(false); 
	const [PDFUrl, setPDFUrl] = useState(false); 

	const printPreview = async () => {
		setIsLoading(true);
		setPDFModalIsOpen(true);
		const url = `estimates/${location?.search?.split("=")[1]}/generate`;
		fetch(`${process.env.REACT_APP_API_URL}api/${url}`, {
			method: "GET",
			mode: "cors",
			headers: new Headers({
				Accept: "application/octet-stream",
				Authorization:
					"Bearer " +
					(localStorage.jwt_token !== undefined
						? JSON.parse(localStorage.jwt_token)
						: ""),
			}),
		})
			.then((response) => response.blob())
			.then((blob) => {
				var fileURL = window.URL.createObjectURL(blob);
				setPDFUrl(fileURL);
			}).finally(()=>{
				setIsLoading(false);
			});
	};

	const createAndRedirectInvoice = async () => {
		let request = {
			estimate_id: location?.search?.split("=")[1],
		};

		let response = await createItemDetail(
			request,
			"Invoice from estimate",
			"generate_invoice_from_estimated",
			true,
		);

		if (response && response.hasOwnProperty("id")) {
			history.push({
				pathname: "/invoice_form_update",
				search: `?invoice_id=${response.id}`,
			});
		}
	};

	const [show_add, setShowAdd] = useState(false);

	const handleShowAdd = () => setShowAdd(true);

		const [template_errors, setTemplateErrors] = React.useState([]);
	const [template_errors_all, setTemplateErrorsAll] = React.useState([]);

	function validateFields() {
		setTemplateErrors([]);

		//validamos que existan espacios vacios

		let nedd_to_validate = false;

		if (skuNumber.toString().trim() !== "") {
			nedd_to_validate = true;
		}
		if (location_name.toString().trim() !== "") {
			nedd_to_validate = true;
		}
		if (parseFloat(cost) > 0 || cost.toString().trim() !== "") {
			nedd_to_validate = true;
		}

		if (!nedd_to_validate) {
			return false;
		}

		let falidate_fields_errors = [];

		// if(part_number_id.toString().trim() === '' ){

		// 		falidate_fields_errors.push({
		// 				'field_id' : 'part_number_id',
		// 				'message'  : 'Part Number need to be valid'
		// 		});

		// }

		if (skuNumber.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "part_number_name",
				message: "Part Number need to be valid",
			});
		}

		if (item_list.length > 0) {
			let exists_item = item_list.find(
				(element) => element.name === part_number_name.toString(),
			);

			if (exists_item === undefined) {
				falidate_fields_errors.push({
					field_id: "part_number_name",
					message: "Part number entered not exists",
				});
			}
		}

		if (quantity <= 0) {
			falidate_fields_errors.push({
				field_id: "quantity",
				message: "Quantity need to be greater than 0",
			});
		}

		if (parseFloat(cost) <= 0 || cost.toString().trim() === "") {
			falidate_fields_errors.push({
				field_id: "cost",
				message: "Cost need to be greater than 0",
			});
		}

		if (falidate_fields_errors.length > 0) {
			setTemplateErrors(falidate_fields_errors);

			return false;
		}

		return true;
	}

	function addNewStockReceiptDetail(
		itemId,
		itemName,
		validateField = true,
		currentCost = null,
	) {
		if (validateField) {
			if (!validateFields()) {
				return false;
			}

			let exists_same_item = stock_receipt_details.find(
				(element) =>
					element?.item_id === itemId &&
					element?.location_id === location_id &&
					element?.cost === cost &&
					element?.quantity === quantity,
			);

			if (exists_same_item) {
				console.log("exists_same_item", exists_same_item);

				manageResponseErrors(
					"The item is already added",
					"Stock Receipt Items",
					"updating",
				);

				return false;
			}
		}
	}

	const createNewStockReceipt = async (
		dataId,
		dataName,
		stockReceiptId,
		errorCreating = false,
		currentCost = null,
		validateField = true,
	) => {
		let newStockReceiptItem = addNewStockReceiptDetail(
			dataId,
			dataName,
			validateField,
			currentCost,
		);
	}

	const handleCloseAdd = async (currentSku, currentCost) => {
		setShowAdd(false);

		if (skuNumber || currentSku) {
			// setIsLoading(true);
			const { data } = await getSkuItemInformation(
				skuNumber ? skuNumber : currentSku,
			);

			if (!(Object.keys(data).length === 0)) {
				await createNewStockReceipt(
					data.id,
					data.name,
					receipt_stock_number,
					false,
					currentCost,
					false, //validate fields
				);
				setSincronizedItemsList(false);
			}
			stock_setIsLoading(false);
		}
	};

	const getElementWhenAddNewItem = () => {
		stock_setIsLoading(true);
		setSincronizedItems(false);
	};

	const [showPanel, setShowPanel] = useState(false);
  const toggleShowPanel = () => setShowPanel((state) => !state);
	const handleResponseCreateCustomer = (customer) => {
		onChangeSearchInputCustomer(customer);
		toggleShowPanel();
	}
	const handleSiblings = (prop = {}) => {
		switch (prop.panel) {
			case PanelNames.PANEL_CUSTOMER:
				toggleShowPanel();
				break;
			case PanelNames.PANEL_PRODUCT:
				handleShowAdd()
				break;
			default:
				break;
		}
	}

	return (
		<>
			<Panel open={showPanel} anchor="right" togglePanel={toggleShowPanel} headerTitle="New Client">
        <CostumerForm handleResponse={handleResponseCreateCustomer} />
      </Panel>
			<div className="contenedor container-fluid" style={{ position: "relative" }}>
				<div className="container-title mb-2">
					<h1 className="container__title mb-0">
						{props.actionTittle + " " + props.model} #{" "}
						{location?.search?.split("=")[1]}
					</h1>
					<div className="action-buttons-container">
						<div className="save-buttton">
							<div className="save-buttton">
								<CustomButton
									style={{ paddingInline: '1rem', fontSize: '13px' }}
									buttonName={"Save"}
									buttonOnClick={() =>
										buttonClick("Save", "save")
									}
									type={"success"}
									icon={""}
									disabled={false}
									margins={"mt-0 mb-0"}
								/>
							</div>
						</div>
						<div className="send-button">
							<CustomButton
								style={{ paddingInline: '1rem', fontSize: '13px' }}
								buttonName={"Save and send"}
								buttonOnClick={() =>
									buttonClick("Save", "saveAndSend")
								}
								type={"success"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
							/>
						</div>
						<div className="print-button">
							<CustomButton
								style={{ paddingInline: '1rem', fontSize: '13px' }}
								buttonName={"Print or Preview"}
								buttonOnClick={printPreview}
								type={"secondary"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
							/>
						</div>
						<div className="cancel-button">
							<CustomButton
								style={{ paddingInline: '1rem', fontSize: '13px' }}
								buttonName={"Cancel"}
								buttonOnClick={cancelInvoice}
								type={"secondary"}
								icon={""}
								disabled={false}
								margins={"mt-0 mb-0"}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					{typesAndValuesFields.length > 0 &&
						Object.keys(initData).length > 0 && (
							<div className="col-12">
								<CustomForm
									onHandleFormClose={() => {}}
									onHandleSubmit={onHandleSubmit}
									typesAndValuesFields={typesAndValuesFields}
									initData={initData}
									formTittle={""}
									dontShowCancelButton={true}
									id={null}
									getUrlPath={""}
									inputConf={{
										marginTop: "1",
										marginBottom: "0",
										stringCol: "col-12 col-md-4 col-lg-2",
									}}
									buttonClick={buttonClick}
									onChangeFields={onChangeFieldsInitData}
									initOnChangeFields={onChangeFieldsInitData}
									dontShowSubmitButton={true}
									childFunc={childFunc}
									dontShowErrosinOnChange={true}
									getObjectInfoOnChange={onChangeSearchInputCustomer}
									notifyChange={() => setChangeInForm(true)}
									handlerSiblings={handleSiblings}
								/>
							</div>
						)}
				</div>
				<div className="row mt-1">
					<div className="col-12">
						<div className="row justify-content-between">
							<div className="billing_address_container col-12 col-md-4">
								<label>Billing Address</label>
								<address
									className="address-container"
									style={{
										height:
											Object.keys(initDataBillingAddress)
												.length > 0 &&
											initDataBillingAddress.comments
												.length > 0
												? "auto"
												: "131px",
									}}
								>
									{Object.keys(initDataBillingAddress)
										.length > 0 &&
										initDataBillingAddress.comments.length >
											0 &&
										initDataBillingAddress.comments
											.split(",")
											.map((comment, key) => (
												<React.Fragment key={key}>
													{comment}
													<br />
												</React.Fragment>
											))}
								</address>
							</div>
							<div className="balance-table col-12 col-md-6">
								<div className="balance-table-balance-due">
									<label>Balance Due</label>
								</div>
								<div className="balance-table-balance-total">
									<span>$ {formAmountValues.balance}</span>
								</div>
								{!invoiceId && (
									<div className="balance-button">
										<CustomButton
											className={"balance-button-pay rounded-1 p-1 px-3"}
											buttonName={"Create Invoice"}
											buttonOnClick={createAndRedirectInvoice}
											type={"-"}
											icon={""}
											disabled={false}
											margins={"mt-0 mb-0"}
										/>
									</div>
								)}
								{invoiceId > 0 && (
									<div
										style={{ cursor: "pointer" }}
										className="balance-info-text"
										onClick={() => {
											history.push({
												pathname: "/invoice_form_update",
												search: `?invoice_id=${invoiceId}`,
											});
										}}
									>
										Go to invoice
									</div>
								)}
							</div>
						</div>
					</div>
					{typesAndValuesPartNumber.length > 0 &&
						Object.keys(initDataPartNumber).length > 0 && (
							<div className="customForm-customButton-container">
								<CustomForm
									onHandleFormClose={() => {}}
									onHandleSubmit={onHandleSubmit}
									typesAndValuesFields={typesAndValuesPartNumber}
									initData={initDataPartNumber}
									formTittle={""}
									dontShowCancelButton={true}
									id={null}
									getUrlPath={""}
									inputConf={{
										marginTop: "1",
										marginBottom: "0",
										stringCol:"col-12 col-md-3"
									}}
									buttonClick={buttonClick}
									onChangeFields={onChangeFieldsInitDataPartNumber}
									initOnChangeFields={onChangeFieldsInitDataPartNumber}
									dontShowSubmitButton={true}
									dontShowErrosinOnChange={true}
									childFunc={childFunc3}
									getObjectInfoOnChange={() => {}}
									notifyChange={() => setChangeInForm(true)}
									handlerSiblings={handleSiblings}
								/>
							</div>
						)}
				</div>
				
				<div className="row">
					<div className="col-12 col-md-9">
						<CustomDataList
							onChangePageEvent={() => {}}
							style={styles}
							isLoading={isLoading}
							dataList={invoiceItems}
							tableHeaders={tableHeaders}
							itemPagination={null}
							handlePagination={() => {}}
							dontShowPerPageSelect={true}
							showLinearIndicator={true}
							showDataInLoading={true}
							render={(element, index) => (
								<CustomDataListItem
									key={index}
									dataFields={dataFields}
									imageFields={[]}
									composedFields={[]}
									arrayFields={[]}
									element={element}
									index={index}
									no_image={no_image}
									dontShowActions={true}
									showActionsButtons={true}
									deleteRowTable={onDelete}
									renderActions={() => (
										<>
											<PositionedMenu
												title={"Actions"}
												anchorEl={anchorEl}
												setAnchorEl={setAnchorEl}
												handleClose={onCloseModal}
												handleClick={(event) =>
													onMenuActions(event, element)
												}
												actionsDefinition={
													actionsDefinition
												}
												renderActions={(action, index) => (
													<MenuItem
														sx={{
															boxShadow: "none",
														}}
														key={index}
														onClick={() =>
															action.func()
														}
													>
														<div
															className="items-option"
															style={{
																padding: "0 5px",
																color: "grey",
															}}
														>
															<FontAwesomeIcon
																style={{
																	margin: "4px",
																	marginRight:
																		"10px",
																	fontSize:
																		"12px",
																}}
																icon={faTrash}
															/>
															<p>{action.name}</p>
															<hr />
														</div>
													</MenuItem>
												)}
											></PositionedMenu>
										</>
									)}
									renderComponentInField={(data) => (
										<>
											<MaterialUI.Button.Primary  className="mw-auto px-2 me-1" onClick={() => prepareDetailToUpdate(data)}>
												<FontAwesomeIcon fontSize={14} icon={faEdit}/>
											</MaterialUI.Button.Primary>
											{data}
										</>
									)}
								/>
							)}
						/>
					</div>
					<div className="col-12 col-md-3">
						{typesAndValuesMessageOnInvoice.length > 0 &&
							Object.keys(initDataMessageOnInvoice).length > 0 && (
								<div className="row">
									<div className="col-12">
										<div style={{ background: "", display: "grid", justifyContent: "flex-end" }}>
											<div className="amount-table">
												<div className="letter amount-table-subtotal fs-small">Subtotal</div>
												<div className="letter amount-table-item fs-small font-inter amount-table-subtotal-value">
													$ {formAmountValues.total}
												</div>
												<div className="letter amount-table-discount fs-small">Discount percent</div>
												<input
													style={{ textAlign: "right" }}
													name="discount_percentage"
													onChange={onChangeFormAmountValues}
													value={formAmountValues.discount_percentage}
													disabled={false}
													className="form-control form-control-sm h-25 align-self-center custom-input input-type-number"
													onKeyDown={blockInvalidChar}
												/>
												<div className="letter amount-table-item fs-small font-inter">
													$ {formAmountValues.discount_total || 0}
												</div>
												<div className="letter amount-table-deposit fs-small">Deposit</div>
												<input
													style={{ textAlign: "right" }}
													name="deposit"
													onChange={onChangeFormAmountValues}
													value={formAmountValues.deposit}
													disabled={false}
													className="form-control form-control-sm h-25 align-self-center custom-input input-type-number amount-table-deposit-value"
													onKeyDown={blockInvalidChar}
												/>
												<div className="letter amount-table-balance-due fs-small">Balance due</div>
												<div className="letter amount-table-item fs-small font-inter amount-table-balance-due-value">
													$ {formAmountValues.balance}
												</div>
											</div>
										</div>
									</div>
									{/* <div className="col-12">
										<div className="attach_estimate_form_update-container">
											<div className="content-attach__documents">
												<img
													onClick={() => {}}
													className="add-documents__icon"
													src={adjunt}
													alt="Icon img"
													width={"28px"}
												/>
												<p
													onClick={() => {}}
													className="add-documents__text"
												>
													Attach documents
												</p>
												<input
													hidden
													id="file_input_doc_input"
													type="file"
													onChange={(e) => {}}
													accept="application/pdf"
													multiple
												/>
											</div>
										</div>
									</div> */}
									<div className="col-12">
										<CustomForm
											onHandleFormClose={() => {}}
											onHandleSubmit={onHandleSubmit}
											typesAndValuesFields={typesAndValuesMessageOnInvoice}
											initData={initDataMessageOnInvoice}
											formTittle={""}
											dontShowCancelButton={true}
											id={null}
											getUrlPath={""}
											inputConf={{
												marginTop: "1",
												marginBottom: "0",
												stringCol: "col-12 ",
											}}
											buttonClick={buttonClick}
											onChangeFields={onChangeFieldsInitDataMessageOnInvoice}
											initOnChangeFields={onChangeFieldsInitDataMessageOnInvoice}
											dontShowSubmitButton={true}
											childFunc={childFunc2}
											dontShowErrosinOnChange={true}
											notifyChange={() => setChangeInForm(true)}
										/>
									</div>
								</div>
							)}
					</div>
				</div>

			</div>

			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={onHandleResponseModal}
				onHandleSuccess={() => {
					!customCurrentItem
						? history.push("/invoice")
						: onHandleDelete();
				}}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
				actionsFooterStyle={actionsFooterStyle}
				cancelText={cancelText}
				successText={successText}
			/>

			{/* modal form */}
			<CustomModal
				show={show}
				customTittleText={""}
				customMessageModal={""}
				onHandleClose={() => setShow(false)}
				onHandleSuccess={() => {}}
				isLoading={false}
				showSuccessButton={true}
			>
				<CustomForm
					onHandleFormClose={() => setShow(false)}
					location_list={[]}
					onHandleSubmit={updateInvoiceDetail}
					typesAndValuesFields={typesAndValuesUpdateDetailInvoice}
					initData={invoiceItems[currentId]}
					formTittle={""}
				/>
			</CustomModal>

			<ModalAddItem
				handleCloseAdd={() => setShowAdd(false)}
				getElementWhenAddNewItem={getElementWhenAddNewItem}
				modal_conf={modal_add_conf}
				onHandleClose={handleCloseAdd}
				show={show_add}
				skuNumber={skuNumber}
			></ModalAddItem>

			<PDFModal title={"Estimate PDF"} isOpen={PDFModalIsOpen} closeModal={() => setPDFModalIsOpen(false)} pdfUrl={PDFUrl} isLoading={isLoading}/>
		</>
	);
}

export { EstimateFormUpdate };
