export const APP_NAME = "Tech4Inventory";
export const LOCAL_STORAGE_APP_CONTEXT_DATA_VAR_NAME = "tech4inventoryContextData";
export const LOCAL_STORAGE_NAVIGATION_EXPANDED_VAR_NAME = "navigationExpanded";
export const LOCAL_STORAGE_USER = "user";
export const S3_URL_PATH = "https://go2storage.s3.us-east-2.amazonaws.com/";
export const DEMO_USER = "test@test.com";

export const SweetColors = {
	confirmButtonColor: "#0C6EFC",
	cancelButtonColor: "#FF6262",
}

export const INITIAL_LOCAL_STORAGE_APP_STATE = {
	[LOCAL_STORAGE_NAVIGATION_EXPANDED_VAR_NAME]: false,
};
