import { useState, useEffect } from "react";
import createHttp from "../lib/http";
import { amountPerPagePagination } from "../context/constants";

var _ = require("lodash");
function useApiRequestTaxGroup(manageResponseErrors,
	manageResponse,
	manageSuccessfullMesssage,) {
    
        const [isLoading, setIsLoading] = useState(false);
        const [sincronizedItems, setSincronizedItems] = useState(false);
        const [filtered_data, setFilteredData] = useState([]);
        const [responseStatus, setResponseStatus] = useState(false);
        const [responseMsg, setResponseMsg] = useState({});
        const [dataPagination, setDataPagination] = useState(1);

        useEffect(async () => {
            setIsLoading(true);
    
            let params = {};
            // itemFilters
            //     .filter((filter) => filter.value)
            //     .map((filter) => (params[filter.field_id] = filter.value));
    
            if (!sincronizedItems) {
                await createHttp
                    .get(`api/taxes_group?page=${1}&paginate=${25}&search=`)
                    .then((response) => {
                        if (response.data.status) {
                            // console.log(response.data);
                            // setUser(response.data.data);
                            setFilteredData(response.data.data);
                            const pagination = response.data.pagination;
                            setDataPagination(pagination);
                        } else {
                            //manage error
                            manageResponseErrors(
                                response.data.message,
                                "Tax Group",
                                "listing",
                            );
                        }
                        setSincronizedItems(true);
                        setIsLoading(false);
                    })
                    .catch((response) => {
                        // console.log("error", response);
                        setIsLoading(false);
                        setSincronizedItems(true);
                        manageResponseErrors(
                            "An error has occurred",
                            "Tax Group",
                            "listing",
                        );
                    });
            }
            setIsLoading(false);
        }, [sincronizedItems]);

        const addItem = async (newTaxGroup) => {
            // Method : post
            // URL : api/taxes_group
            // Params : 
            // tax_group_name - string – nombre del grupo
            // taxes_ids – array – ids de los taxes sales ejemplo – [ 1 , 2 , 3]

            const {tax_group_name, taxes_ids} = newTaxGroup;
            // const tax_group_name = "test tax group";
            // const taxes_ids = [27, 28, 29];
            setIsLoading(true);
            let body = {
                tax_group_name:tax_group_name,
                taxes_ids:taxes_ids,
            }
            // console.log(body);
            // return;
            await createHttp
                .post("api/taxes_group", body)
                .then((res) => {
                    if (!res.data.status) {
                        setResponseStatus(false);
                        setResponseMsg(res.data.msg);
                        manageResponse(res, "creating", "Tax Group");
                    } else {
                        // console.log("tax created")
                        setResponseStatus(true);
                        setResponseMsg({});
                        setSincronizedItems(false);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        };

        const updateItem = async (newTaxGroup) => {
            const {id, tax_group_name, taxes_ids} = newTaxGroup;

            setIsLoading(true);
            let body = {
                id:id,
                tax_group_name:tax_group_name,
                taxes_ids:taxes_ids,
            }
            await createHttp
                .post(`api/taxes_group/${id}`, body)
                .then((res) => {
                    if (!res.data.status) {
                        setResponseStatus(false);
                        setResponseMsg(res.data.msg);
                        manageResponse(res, "updating", "Tax Group");
                    } else {
                        // console.log("updated created")
                        setResponseStatus(true);
                        setResponseMsg(null);
                        setSincronizedItems(false);
                    }
                    setIsLoading(false);
                    
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        };

        const deleteItem = async (resourseUrl) => {
            setIsLoading(true);
            await createHttp
                .delete(resourseUrl)
                .then((res) => {
                    if (!res.data.status) {
                        manageResponse(res, "deleting", "Tax Group");
                    } else {
                        setSincronizedItems(false);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        };

        const getTaxGroupPaginated = async (salesCustomerTaxesPaginationConfig) => {
            const {page, search, paginate} = salesCustomerTaxesPaginationConfig;
            setIsLoading(true);
                await createHttp
                    .get(`api/taxes_group?page=${page}&search=${search}&paginate=${paginate}`)
                    .then((response) => {
                        if (response.data.status) {
                            // console.log(response.data);
                            setFilteredData(response.data.data);
                            const pagination = response.data.pagination;
                            setDataPagination(pagination);
                        } else {
                            //manage error
                            manageResponseErrors(
                                response.data.message,
                                "Tax Group",
                                "listing",
                            );
                        }
                        setIsLoading(false);
                    })
                    .catch((response) => {
                        // console.log("error", response);
                        setIsLoading(false);
                        setSincronizedItems(true);
                        manageResponseErrors(
                            "An error has occurred",
                            "Tax Group",
                            "listing",
                        );
                    });
        };

       

    return {
		isLoading,
		setIsLoading,
		sincronizedItems,
		setSincronizedItems,
		filtered_data,
        responseStatus,
        responseMsg,
		// user,
		// setFilteredData,
		// setUser,
        addItem,
        updateItem,
        deleteItem,
        getTaxGroupPaginated,
        
		// filtersDefinition,
		// setFiltersDefinition,
		// selectOnChange,
		// onChangeDateFilter,
		// filterInputChange,
		// itemFilters,
		// setItemFilters,
		dataPagination,
		setDataPagination,
	};
}

export {useApiRequestTaxGroup};