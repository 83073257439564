import React from "react";
import Http from "../lib/http";
import { LaravelErrorsToObject } from "../utils/errors.laravel";

export const useReceipts = () => {
  // 👉 Hold Orders
	const [holdReceipts, setHoldReceipts] = React.useState([]);
	const [holdReceiptsPagination, setHoldReceiptsPagination] = React.useState({});
	const [holdReceiptErrors, setHoldReceiptErrors] = React.useState({});
	const [loadingHoldReceipts, setLoadingHoldReceipts] = React.useState(false);
	const [loadingReceipt, setLoadingReceipt] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  // 👉 Receipts
	const [loadingReceipts, setLoadingReceipts] = React.useState(false);
	const [receiptsPagination, setReceiptsPagination] = React.useState({});
  const [receipts, setReceipts] = React.useState([]);
  
	const fetchHoldReceipts = async (page = 1) => {
    if(loadingHoldReceipts) return;
		try {
      setLoadingHoldReceipts(true);
			const { data } = await Http.get(`/api/pos_hold_receipts`, { params: { page } });
      if(data.status) {
        setHoldReceipts(data.data);
        setHoldReceiptsPagination(data.pagination);
      }
			return data;
		} catch (err) {
			throw err;
		} finally {
      setLoadingHoldReceipts(false);
    }
	};
  
	const fetchReceipts = async (page = 1) => {
    if(loadingReceipts) return;
		try {
      setLoadingReceipts(true);
			const { data } = await Http.get(`/api/pos_receipts`, { params: { page } });
      if(data.status) {
        setReceipts(data.data);
        setReceiptsPagination(data.pagination);
      }
			return data;
		} catch (err) {
			throw err;
		} finally {
      setLoadingReceipts(false);
    }
	};

  const storeReceipt = async (payload) => {
    try {
      setHoldReceiptErrors({});
      setErrors({});
      setLoadingReceipt(true);

      const uri = payload.receipt_id ? '/api/pay_hold_receipt' : `/api/create_pos_receipt`;
      const { data } = await Http.post(uri, payload);
      if(payload.receipt_id) incrementHoldReceiptsTotal(-1);
      if(data.status) {
        if(payload.is_on_hold) {
          incrementHoldReceiptsTotal();
          setHoldReceipts((prev) => {
            if(holdReceipts.length >= 10) {
              prev.splice(-1);
              if(holdReceiptsPagination.current_page === holdReceiptsPagination.total_pages) {
                setHoldReceiptsPagination((prev) => ({
                  ...prev,
                  total_pages: ++prev.total_pages
                }));
              }
            }
            return [data.data, ...prev];
          });
        }
      } else {
        if(payload.is_on_hold) {
          setHoldReceiptErrors(LaravelErrorsToObject(data.msg));
        } else {
          setErrors(LaravelErrorsToObject(data.msg));
        }
      }
      return data;
    } catch (err) {
      throw err;
    } finally {
      setLoadingReceipt(false);
    }
  };

  const refundItems = async (payload) => {
    try {
      const uri = `api/refound_items`;
      const { data } = await Http.post(uri, payload);
      return data;
    } catch (err) {
      throw err;
    } finally {}
  };

  const destroyReceipt = async (receiptId) => {
    try {
      const { data } = await Http.delete(`/api/pos_hold_receipt/${receiptId}`);
      return data;
    } catch (error) {}
  }

  const incrementHoldReceiptsTotal = (count = 1) => {
    setHoldReceiptsPagination((prev) => {
      const newCount = prev.total + count;
      return {
        ...prev,
        total: newCount < 0 ? 0 : newCount
      }
    });
  }

	return {
		fetchHoldReceipts,
    storeReceipt,
    incrementHoldReceiptsTotal,
    destroyReceipt,

    holdReceipts,
    setHoldReceipts,
    holdReceiptErrors,
    setHoldReceiptErrors,
    loadingReceipt,
    setLoadingReceipt,
    loadingHoldReceipts,
    setLoadingHoldReceipts,
    holdReceiptsPagination,
    setHoldReceiptsPagination,
    errors,
    setErrors,
    refundItems,
    
    // 👉 Receipts
    fetchReceipts,
    loadingReceipts,
    setLoadingReceipts,
    receiptsPagination,
    setReceiptsPagination,
    receipts,
    setReceipts,
	};
};

export class Adapter {
  static holdOrderDetailsToCart(row) {
    return {
      ...row.item,
      price: row.price,
      quantity: row.quantity,
      subTotal: Number(row.price) * Number(row.quantity),
    };
  }
}