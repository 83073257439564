import { Grid, CircularProgress } from '@material-ui/core'
import React from 'react'

import useApiRequestSettingPaymentStripe from "../../../customHooks/useApiRequestSettingPaymentStripe";
import LogoStripe from '../../../assets/img/logo-stripe.svg';
import AlertComponent from '../components/AlertComponent';
import MaterialUI from '../../../components/MaterialUI';

const modes = [
	{ value: 'live', label: 'Live' },
  { value: 'sandbox', label: 'Sandbox'}
]
const status = [
	{ value: "1", label: "Enabled" },
	{ value: "0", label: "Disabled" },
]

const FormPaypal = () => {
  const { stripe, isLoading, error, update, removeFromErrorByKey, setStripe } = useApiRequestSettingPaymentStripe();
	const [showAlert, setShowAlert] = React.useState(false);
	
  const handleSubmit = async (ev) => {
		ev.preventDefault();
		try {
			await update(stripe);
			setShowAlert(true);
		} catch (error) {}
	};

	const handleTypingChange = (key, value) => {
		removeFromErrorByKey(key);
		setStripe((state) => ({ ...state, [key]: value }));
	};

	const handleClose = (_, reason) => {
		if (reason === 'clickaway') return;
    setShowAlert(false);
	};
	
	if (isLoading) return <CircularProgress />;
  return (
    <div>
			<AlertComponent 
				open={showAlert} 
        handleClose={handleClose} 
        severity="success" 
        message="It has been successfully updated"
			/>
      <div className='text-center'>
        <figure className='py-5'><img src={LogoStripe} style={{ blockSize: '60px' }}/> </figure>
      </div>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
						<MaterialUI.Input 
							state={stripe.stripe_key}
							setState={(value) => handleTypingChange("stripe_key", value)}
							error={ error.stripe_key && error.stripe_key}
							label="Stripe KEY"
						/>
					</Grid>
          <Grid item xs={12} md={4}>
						<MaterialUI.Input 
							state={stripe.stripe_secret}
							setState={(value) => handleTypingChange("stripe_secret", value)}
							error={ error.stripe_secret && error.stripe_secret}
							label="Stripe SECRET"
						/>
					</Grid>
          <Grid item xs={12} md={4}>
						<MaterialUI.Select 
							options={modes}
							state={stripe.stripe_mode}
							setState={(value) => handleTypingChange("stripe_mode", value)}
							label="Stripe MODE"
						/>
					</Grid>
          <Grid item xs={12} md={4}>
						<MaterialUI.Select 
							options={status}
							state={stripe.stripe_status}
							setState={(value) => handleTypingChange("stripe_status", value)}
							label="Stripe Status"
						/>
					</Grid>
          <Grid item xs={12} md={4}>
						<MaterialUI.Button.Success type="submit" fullWidth>
							Save
						</MaterialUI.Button.Success>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default FormPaypal