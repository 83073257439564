import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, FormGroup, FormLabel, InputGroup, } from 'react-bootstrap';
import { AutoCompleteInputText } from '../AutoCompleteInputText/AutoCompleteInputText';

import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';

import { ModalDeleteTax } from '../ModalDeleteTax';
import MaterialUI from '../MaterialUI';

function ModalEditTaxGroup({show, onHandleClose, title, handleAction, taxGroup, isLoading, resStatus, resMsg, searchSuggestions, dataSuggestions}) {
    useEffect(()=>{
        if(taxGroup && Object.keys(taxGroup).length) {
            setTaxGroup_form({
                id: taxGroup.id,
                tax_group_name: taxGroup.tax_name,
                taxes_search:"",
                taxes:taxGroup.taxes,
            });
        }        
    },[taxGroup, show]);
    
    const [taxGroup_form, setTaxGroup_form] = useState({
        tax_group_name:"",
        taxes_search:"",
        taxes:[],
    });

    const [errors, setErrors] = useState({
        tax_group_name: "",
        taxes_search:"",
        taxes:[],
    });

    const validateInputs = () => {
        let isValid = true;
        const newErrors = {};

        // Validar el campo de tax_group_name
        const tax_group_name_without_space = taxGroup_form.tax_group_name.trim();
        if (tax_group_name_without_space.length < 5 || tax_group_name_without_space.length > 30 || tax_group_name_without_space.trim() === "") {
            newErrors.tax_group_name = "Tax name must be between 5 and 30 characters long";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e, field) => {
        const { value } = e.target;
        const newErrors = { ...errors }; 
    
        if (field === "tax_group_name") {
            if (value.trim().length < 5 || value.trim().length > 30) {
                newErrors.tax_group_name = "Tax name must be between 5 and 30 characters long";
            } else {
                delete newErrors.tax_group_name; 
            }
        }
    
        if (field === "taxes_search") {
                delete newErrors.taxes_search; 
        }
    
        setErrors(newErrors);
    
        setTaxGroup_form({ ...taxGroup_form, [field]: value });
    };
    

    const handleSubmit = () => {
        if (validateInputs()) {
            const finalBodyNewTaxGroup = {
                id: taxGroup_form.id,
                tax_group_name: taxGroup_form.tax_group_name,
                taxes_ids:taxGroup_form.taxes.map(tax => tax.id),
            }
            handleAction(finalBodyNewTaxGroup);
        }
    };

    const addTaxToGroup = () => {
        const tax = dataSuggestions.find(tax => tax.tax_name === taxGroup_form.taxes_search);
        if (tax) {
            setTaxGroup_form({
             ...taxGroup_form,
                taxes: [...taxGroup_form.taxes, tax],
                taxes_search: "",
            });
        }else{
            setErrors({...errors, ["taxes_search"]: "This tax name does´not exist"});
        }
    }

    const [taxIdIsGoingToDelete, setTaxIdIsGoingToDelete] = useState(null);
    const deleteTaxFromGroup = (taxId) => {
        setTaxIdIsGoingToDelete(taxId);
        setShowConfirmDeleteTaxModal(true);
        
    }

    const confirmDeleteTax = () => {
        setTaxGroup_form({
            ...taxGroup_form,
               taxes: taxGroup_form.taxes.filter(tax => tax.id !== taxIdIsGoingToDelete),
        });
        setShowConfirmDeleteTaxModal(false);
    }

    useEffect(() => {
        //clear form
            
        if(!show){
            setTaxGroup_form({
                ...taxGroup_form,
                tax_group_name:"",
                taxes_search:"",
            });
            setErrors({
                tax_group_name:"",
                taxes_search:"",
                taxes:[],
            });
        }
    },[show])

    //close modal
    useEffect(() => {
        if(!isLoading){
            if(!resStatus && resMsg){
                setErrors({...errors, ["tax_group_name"]: resMsg.tax_group_name});
                setErrors({...errors, ["taxes_search"]: resMsg.taxes_ids});
            }else{
                onHandleClose();
            }
        }
    },[isLoading])

   
    // Obtener los IDs de los impuestos en taxGroup_form
    const taxIds = taxGroup_form.taxes.map(tax => tax.id);

    // Filtrar dataSuggestions para excluir aquellos con los mismos IDs
    const filteredSuggestions = dataSuggestions.filter(item => !taxIds.includes(item.id));

    // Mapear los nombres de impuestos filtrados
    const mappedSuggestions = filteredSuggestions.map(item => item.tax_name);
    

    const [showConfirmDeleteTaxModal, setShowConfirmDeleteTaxModal] = useState(false);
    return (
		<React.Fragment>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={show}
				onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
			>
                <Modal.Header style={{ background: 'var(--login-blue)', color: '#fff' }}>
                    <Modal.Title as="h6" className='fw-bold'>{title}</Modal.Title>
                </Modal.Header>
				<Modal.Body>
                    <ModalDeleteTax
                        title={"Delete Tax from this tax group"}
                        bodyMessage={"Are you sure you want to delete this tax from this tax group?"}
                        onHandleClose={()=>setShowConfirmDeleteTaxModal(false)}
                        show={showConfirmDeleteTaxModal}
                        handleAction={()=>confirmDeleteTax()}
                        isLoading={isLoading}
                    />
					<Form autoComplete='off'>
                        <Form.Group className='mb-2' controlId='tax-group-name'>
                            <Form.Label className='mb-0'>Name</Form.Label>
                            <Form.Control
                                maxLength={30}
                                onChange={(e) => handleChange(e, "tax_group_name")}
                                value={taxGroup_form.tax_group_name}
                                type="text"
                            />
                            {errors.tax_group_name && <Form.Text className='text-danger'>{errors.tax_group_name}</Form.Text>}
                        </Form.Group>

                        <label htmlFor="tax-group-tax">Tax</label>
                        <InputGroup style={{ display: 'grid', gridTemplateColumns: '1fr 80px' }}>
                            <AutoCompleteInputText 
                                searchSuggestions={searchSuggestions}
                                dataSuggestions={mappedSuggestions}
                                maxLength={30}
                                onChange={handleChange}
                                key_name={"taxes_search"}
                                value={taxGroup_form.taxes_search}
                                type={"text"}
                                isLoading={isLoading}
                                style={{ borderRadius: '.25rem 0 0 .25rem' }}
                            />
                            <InputGroup.Append>
                                <MaterialUI.Button.Primary className="h-100" fullWidth style={{ borderRadius: '0 .25rem .25rem 0' }} onClick={addTaxToGroup}>
                                    Add
                                </MaterialUI.Button.Primary>
                            </InputGroup.Append>
                        </InputGroup>
                        {errors.taxes_search && <Form.Text className='text-danger'>{errors.taxes_search}</Form.Text>}
                        <FormGroup className='mt-3 mb-3'>
                            {taxGroup_form?.taxes.length > 0 && 
                            <div 
                                style={{
                                    background: 'white',
                                    maxHeight: '100px',
                                    overflow: 'auto',
                                    padding: '10px',
                                    borderRadius: '.25rem',
                                    border: '1px solid #ced4da',
                                    display: 'flex',
                                    gap: '.5rem',
                                    flexWrap: 'wrap'
                                }}
                                className='taxes-added-container'
                            >
                               {taxGroup_form?.taxes.map((taxItem, index)=>{
                                    return (
                                        <div key={index} style={{
                                            padding: '.25rem',
                                            background: 'var(--bs-gray-300)',
                                            borderRadius: '.3rem',
                                            display: 'flex',
                                            gap: '.25rem',
                                            alignItems: 'center',
                                        }}>
                                            {taxItem.tax_name}
                                            <HighlightOffTwoToneIcon 
                                                fontSize='small'
                                                style={{ cursor:"pointer" }}
                                                onClick={() => deleteTaxFromGroup(taxItem.id)}
                                                className='text-danger'
                                            />
                                        </div>
                                    )
                                })
                                }
                            </div>
                            }
                        </FormGroup>
                        <Form.Group className='mb-1 text-end'>
                            <MaterialUI.Button.Success disabled={isLoading} onClick={handleSubmit} className='me-1'>
                                {isLoading ? "Loading ..." : "Create"}
                            </MaterialUI.Button.Success>
                            <MaterialUI.Button.Secondary onClick={onHandleClose}>
                                Cancel
                            </MaterialUI.Button.Secondary>
                        </Form.Group>
                    </Form>                   
				</Modal.Body>
			</Modal>
		</React.Fragment>
    );
}

export {ModalEditTaxGroup};