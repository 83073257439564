import React from "react";

function ArrayInput({ element, element_index, onElementChange, className }) {
	/*
	field_id: "contact_phone"
	field_name: "CONTACT PHONE"
	length: 100
	type: "text"
	value: ""*/

	return (
		<div
			className={
				className !== undefined ? className : "form-group mt-2 mb-2"
			}
		>
			<label htmlFor={element.field_id}>
				{" "}
				{element.field_name}{" "}
				<strong> {element.required && "*"} </strong>{" "}
			</label>

			<select
				onChange={(e) => onElementChange(e, element_index)}
				type="text"
				value={element.value}
				className={
					"form-control " +
					(element.required && element.have_error && "is-invalid")
				}
				id={element.field_id}
			>
				<option value={""}> Select a Option</option>

				{element.array_values.map((element_2, index) => (
					<option key={index} value={element_2.id}>
						{element_2.name}
					</option>
				))}
			</select>

			<span className="text-danger float-end">
				{" "}
				{element.required &&
					element.have_error &&
					"Required Field"}{" "}
				&nbsp;{" "}
			</span>
		</div>
	);
}

export { ArrayInput };
