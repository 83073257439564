import createHttp from "../lib/http";

const ProductService = {};

ProductService.fetchAll = async (params) => {
  try {
    const { data } = await createHttp.get(`/api/items`, { params });
    return data;
  } catch (error) {}
}

export default ProductService;