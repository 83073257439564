import React from "react";
import { useHistory } from "react-router-dom";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

// components
import { CustomForm } from "../../../components/CustomForm/CustomForm";
import { CustomModal } from "../../../components/CustomModal/CustomModal";

// custom Hooks
import { useApiRequest } from "../../../customHooks/useApiRequest";
import { useModalHook } from "../../../customHooks/useModalHook";

// form configuration
import { typesAndValuesFields, initData } from "./initForm";
import MaterialUI from "../../../components/MaterialUI";

function NewWareHouse({ handleBehavior = false }) {
	const [goToMainPage, setGoToMainPage] = React.useState(false);
	const { isLoading, setIsLoading, createItem } = useApiRequest();

	const {
		showModal,
		setShowModal,
		customTittleText,
		setCustomTittleText,
		customMessageModal,
		setCustomMessageModal,
		showSuccessButton,
		setShowSuccessButton,
	} = useModalHook();

	const history = useHistory();

	const onHandleSubmit = async (event) => {
		setIsLoading(true);
		const response = await createItem("api/warehouse/", event);
		manageResponseErrors(response);
		setIsLoading(false);
	};

	const manageResponseErrors = (response) => {
		if (response.hasOwnProperty("status")) {
			if(
				response.status &&
				handleBehavior &&
				typeof handleBehavior === 'function'
			) {
				return handleBehavior(response);
			}
		}

		setShowSuccessButton(false);
		setShowModal(true);
		if (response.hasOwnProperty("errors")) {
			setCustomTittleText("Error creating warehouse");
			Object.keys(response.errors).map((errorList) => {
				if (response.errors[errorList].length > 0) {
					setCustomMessageModal(response.errors[errorList][0]);
				}
			});
			setGoToMainPage(false);
		}

		if (response.hasOwnProperty("status")) {
			setCustomTittleText("Warehouse successfully created");
			setCustomMessageModal("");
			setGoToMainPage(true);
		}
	};

	const onHandleResponseModal = () => {
		setShowModal(false);
		if (goToMainPage) {
			history.push("/warehouse");
		}
	};

	const goBack = () => history.push('/warehouse');

	return (
		<>
			<div className="contenedor container-fluid">
				{ !handleBehavior && (
					<div className="d-flex gap-2 align-items-center">
						<MaterialUI.Button.Dark onClick={goBack}>
							<ArrowBackIosRoundedIcon className="me-1" style={{ fontSize: '1rem' }} /> Back
						</MaterialUI.Button.Dark>
						<div className="container-title">
							<h1 className="container__title mb-0">Create Warehouse</h1>
						</div>
					</div>
				)}
				<div className="row mt-1">
					<CustomForm
						onHandleFormClose={() => {}}
						onHandleSubmit={onHandleSubmit}
						typesAndValuesFields={typesAndValuesFields}
						initData={initData}
						formTittle={""}
						dontShowCancelButton={true}
						inputConf={{
							marginTop: "1",
							marginBottom: "1",
							stringCol:"col-12 col-md-6"
						}}
						successStyleButton={"btn btn-success"}
						actionButonsContainerWidth={"flex-end"}
					/>
				</div>
			</div>

			{/* loading modal */}
			<CustomModal
				show={isLoading}
				customTittleText={""}
				customMessageModal={""}
				onHandleClose={() => {}}
				isLoading={isLoading}
			/>

			{/* response modal */}
			<CustomModal
				show={showModal}
				customTittleText={customTittleText}
				customMessageModal={customMessageModal}
				onHandleClose={onHandleResponseModal}
				onHandleSuccess={onHandleResponseModal}
				isLoading={isLoading}
				showSuccessButton={showSuccessButton}
			/>
		</>
	);
}

export { NewWareHouse };
