import createHttp from "../lib/http";

const PaymentMethodService = {};

PaymentMethodService.fetchAll = async (params) => {
  const { data } = await createHttp.get("/api/type-payment", { params });
  return data;
}

PaymentMethodService.store = async (payload) => {
  try {
    const { data } = await createHttp.post("/api/type-payment", payload);
    return data;
  } catch (error) {}
}

export default PaymentMethodService;