import { useState } from "react";

function useFileManager(
	setShowModal,
	customCurrentItem,
	setCustomCurrentItem,
	setCustomTittleText,
	setCustomMessageModal,
	setShowSuccessButton,
) {
	const [documentsImageArray, setDocumentsImageArray] = useState([]);
	const [imageIdsArray, setImageIdsArray] = useState([]);
	const [itemImageToAdd, setItemImageToAdd] = useState([]);

	const setFileImage = (e) => {
		if (e.target.files.length > 0) {
			// setFile(e.target.files[0]);

			const fileArray = Object.keys(e.target.files)
				.filter((item) => parseInt(item) >= 0)
				.map((index) => e.target.files[index]);

			setItemImageToAdd([...itemImageToAdd, ...fileArray]);

			let documentsImageArrayCopy = [...documentsImageArray];

			fileArray.map((file) => {
				let preview = URL.createObjectURL(file);
				documentsImageArrayCopy = [
					...documentsImageArrayCopy,
					{
						id: file.name,
						path: preview,
					},
				];
			});

			setDocumentsImageArray([...documentsImageArrayCopy]);
		}
	};

	const deleteImage = () => {
		const documentsImageArrayCopy = [...documentsImageArray];

		const itemImageToAddCopy = [...itemImageToAdd];

		const currentImage = documentsImageArrayCopy.find(
			(image) => image.path === customCurrentItem,
		);

		if (currentImage) {
			const currentImageFile = itemImageToAddCopy.find(
				(file) => file.name === currentImage.id,
			);

			if (currentImageFile) {
				const currentIndexFile =
					itemImageToAddCopy.indexOf(currentImageFile);
				itemImageToAddCopy.splice(currentIndexFile, 1);
				setItemImageToAdd([...itemImageToAddCopy]);
			}

			const currentIndex = documentsImageArrayCopy.indexOf(currentImage);
			documentsImageArrayCopy.splice(currentIndex, 1);

			setImageIdsArray([...imageIdsArray, currentImage.id]);
			setDocumentsImageArray([...documentsImageArrayCopy]);
		}
		setShowModal(false);
	};

	const [isImage, setIsImage] = useState(false);

	const onDeleteImage = (imagePath) => {
		console.log(imagePath);
		setCustomCurrentItem(imagePath);
		setCustomTittleText("Delete document");
		setCustomMessageModal("Are you sure you want to delete this document?");
		setShowSuccessButton(true);
		setIsImage(true);
		setShowModal(true);
	};

	const openFileImage = () => {
		let element = document.getElementById("file_input_doc_input");
		element.click();
	};

	return {
		documentsImageArray,
		setDocumentsImageArray,
		imageIdsArray,
		setImageIdsArray,
		itemImageToAdd,
		setItemImageToAdd,
		setFileImage,
		deleteImage,
		onDeleteImage,
		openFileImage,
		isImage,
		setIsImage,
	};
}

export { useFileManager };
