import { CustomButton } from "../../components/CustomButton/CustomButton";
import DeleteIcon from '@material-ui/icons/Delete';

import {
	ENTRY_STATE,
	COMPLETE_STATE,
	CANCEL_STATE,
	POSTED_STATE,
	defineColorStatus,
	INVOICE_PAID_STATE,
	ESTIMATE_PENDING_STATE,
	WORK_ORDER_CLOSE_STATE,
	WORK_ORDER_OPEN_STATE,
} from "../../context/constants";
import MaterialUI from "../MaterialUI";

const partTypesMap = {
	PT: "Part Type",
	LB: "Labor Type",
	TR: "Tire Fee",
};

function CustomDataListItem(props) {
	const { index, element } = props;
	const active = "Active";
	const inactive = "Inactive";
	return (
		<>
			<tr key={index} >
				{props.imageFields.map((dataField) => (
					<td className={`align-middle`} key={dataField.field_name}>
						<img
							className="item-img"
							alt="No image"
							src={
								element[dataField.field_name].length > 0
									? element[dataField.field_name][0].path
									: props.no_image
							}
						></img>
						<br />
					</td>
				))}
				{props.dataFields.map((dataField) => (
					<td
						style={{
							width: `${dataField?.width || ""}`,
							minWidth: `${"fit-content"}`,
							...dataField.style
						}}
						align={dataField.aling ? dataField.aling : ""}
						key={dataField.field_name}
						className={`p-1 ${
							!element[dataField.field_name] &&
							parseInt(element[dataField.field_name]) != 0 &&
							"text-danger"
						} align-middle`}
					>
						{dataField.field_name !== "active" &&
							dataField.field_name !== "status" &&
							dataField.field_name !== "partype" &&
							!dataField.renderComponent &&
							element[dataField.field_name]}
						{dataField.field_name !== "active" &&
							dataField.field_name !== "status" &&
							dataField.field_name !== "partype" &&
							!!dataField.renderComponent && (
								<>
									{props.renderComponentInField(
										element[dataField.field_name],
										dataField,
										element,
									)}
								</>
							)}
						{dataField.field_name === "active" &&
							element[dataField.field_name] &&
							active}
						{dataField.field_name === "active" &&
							!element[dataField.field_name] &&
							inactive}
						{dataField.field_name === "status" &&
							element[dataField.field_name] === ENTRY_STATE && (
								<label
									style={{
										color: `${defineColorStatus(
											element[dataField.field_name],
										)}`,
									}}
								>
									{element[dataField.field_name]}
								</label>
							)}
						{dataField.field_name === "status" &&
							element[dataField.field_name] ===
								COMPLETE_STATE && (
								<label
									style={{
										color: `${defineColorStatus(
											element[dataField.field_name],
										)}`,
									}}
								>
									{element[dataField.field_name]}
								</label>
							)}
						{dataField.field_name === "status" &&
							element[dataField.field_name] === POSTED_STATE && (
								<label
									style={{
										color: `${defineColorStatus(
											element[dataField.field_name],
										)}`,
									}}
								>
									{element[dataField.field_name]}
								</label>
							)}
						{dataField.field_name === "status" &&
							element[dataField.field_name] === CANCEL_STATE && (
								<label
									style={{
										color: `${defineColorStatus(
											element[dataField.field_name],
										)}`,
									}}
								>
									{element[dataField.field_name]}
								</label>
							)}
						{dataField.field_name === "status" &&
							element[dataField.field_name] ===
								INVOICE_PAID_STATE && (
								<label
									style={{
										color: `${defineColorStatus(
											element[dataField.field_name],
										)}`,
									}}
								>
									{element[dataField.field_name]}
								</label>
							)}
						{dataField.field_name === "status" &&
							element[dataField.field_name] ===
								ESTIMATE_PENDING_STATE && (
								<label
									style={{
										color: `${defineColorStatus(
											element[dataField.field_name],
										)}`,
									}}
								>
									{element[dataField.field_name]}
								</label>
							)}
						{dataField.field_name === "status" &&
							element[dataField.field_name] ===
								WORK_ORDER_CLOSE_STATE && (
								<label
									style={{
										color: `${defineColorStatus(
											element[dataField.field_name],
										)}`,
									}}
								>
									{element[dataField.field_name]}
								</label>
							)}
						{dataField.field_name === "status" &&
							element[dataField.field_name] ===
								WORK_ORDER_OPEN_STATE && (
								<label
									style={{
										color: `${defineColorStatus(
											element[dataField.field_name],
										)}`,
									}}
								>
									{element[dataField.field_name]}
								</label>
							)}
						{dataField.field_name === "status" &&
							element[dataField.field_name] === "active" &&
							active}
						{dataField.field_name === "status" &&
							element[dataField.field_name] === "inactive" &&
							inactive}
						{dataField.field_name === "tx_posted" &&
							element[dataField.field_name].toString()}
						{dataField.field_name === "serial_number" &&
							element[dataField.field_name].toString()}
						{dataField.field_name === "partype" &&
							partTypesMap[element[dataField.field_name]]}
					</td>
				))}
				{props.composedFields.map((dataField) => (
					<td className={`p-1 align-middle`} key={dataField.field_name}>
						{" "}
						{element[dataField.field_name]?.name}{" "}
					</td>
				))}
				{props.arrayFields.map((dataField) => (
					<td className={`p-1 align-middle`} key={dataField.field_name}>
						{" "}
						{
							element[dataField.field_name][
								dataField.position_array
							]?.name
						}{" "}
					</td>
				))}
				{!props.dontShowActions && (
					<td className={`p-1 align-middle`}>{props.renderActions()}</td>
				)}
				{!!props.showActionsButtons && (
					<td
						className="p-1 align-middle"
						style={{
							// display: 'grid',
							justifyContent: "flex-end",
							textAlign: "center",
							gridTemplateColumns: "1fr 1fr 1fr",
							alignItems: "center",
							// height: "60px",
							gridColumnGap: "0",
						}}
					>
						<div style={{ gridColumn: "2" }}>
							<MaterialUI.Button.Danger className="mw-auto px-2" onClick={() => props.deleteRowTable(element)}>
								<DeleteIcon fontSize="small" />
							</MaterialUI.Button.Danger>
						</div>
					</td>
				)}
			</tr>
		</>
	);
}

export { CustomDataListItem };
