import moment from "moment";

const today = moment().format("YYYY-MM-DD");

export const adjustmentForm = {
	id: "",
	warehouse: null,
	postdate: today,
};

export const adjustmentDetailForm = {
	id: "",
	item_id: null,
	quantity: "",
	location_id: null,
};

export default {
	adjustmentForm,
	adjustmentDetailForm,
}